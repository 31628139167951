import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'EvoCam',
      href: '/en/Software/macOS/EvoCam/',
    },
    {
      text: 'SecuritySpy',
      href: '/en/Software/macOS/SecuritySpy/',
    },
    {
      text: 'Sighthound',
      href: '/en/Software/macOS/Sighthound/',
    },
    {
      text: 'VLC Player',
      href: '/en/Software/macOS/VLC_Player/',
    },
    {
      text: '◄ macOS',
      href: '/en/Software/macOS/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Software Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}