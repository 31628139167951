import React from 'react'
import {Link} from 'gatsby'

import { EuiAvatar, EuiButtonIcon, EuiText, EuiBadge, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import {
  EuiCommentList,
} from '@elastic/eui'

import homeKitImage from 'images/Products/Homekit/Homekit_1080p_Smartphone_01.webp'
import alexaImage from 'images/Products/Alexa/INSTAR_Cloud_Alexa_Skill_00.webp'

const alexaRelease = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>Alexa Camera Skill</EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="primary">Firmware Update</EuiBadge>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="warning">Full HD Series</EuiBadge>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="default">INSTAR Cloud</EuiBadge>
    </EuiFlexItem>
  </EuiFlexGroup>
);

const annotation = (
  <EuiButtonIcon
    title="Annotation"
    aria-label="Annotation"
    color="subdued"
    iconType="annotation"
  />
);

const timeLine = (
  <EuiButtonIcon
    title="Git Commit"
    aria-label="Git Commit"
    color="subdued"
    iconType="timeline"
  />
);

const alexaAd = (
  <EuiText size="s">
    <h2>Control your camera with Alexa terminal</h2>
    <img src={alexaImage} alt="INSTAR Amazon Alexa" style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}} />
    <p>
    We offer both a <Link to="/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/">INSTAR Cloud Skill</Link> and a <Link to="/Web_User_Interface/1080p_Series/Smarthome/Alexa/">INSTAR Camera Skill</Link> in the Alexa Skill Store. For the INSTAR Cloud Skill, all you need is an INSTAR Cloud account and an Alexa device with a display. Add the Skill on your Alexa device and use it directly.
    </p>
  </EuiText>
);

const mPol = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>
      <EuiAvatar
        imageUrl="/en/images/Assistants/agentsmith.webp"
        size="m"
        name="Mike Polinowski"
      />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>Mike Polinowski</EuiFlexItem>
  </EuiFlexGroup>
);

const homekitRelease = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>Apple Homekit</EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="primary">Firmware Update</EuiBadge>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="warning">Full HD Series</EuiBadge>
    </EuiFlexItem>
  </EuiFlexGroup>
);

const homekitAd = (
  <EuiText size="s">
    <h2>Control your camera with your iPhone or iPad</h2>
    <img src={homeKitImage} alt="INSTAR Homekit" style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}} />
    <p>
    The connection to the <Link to="/Web_User_Interface/1080p_Series/Smarthome/Homekit/">Homekit service</Link> is available from firmware version <code>3.2(361)</code> (<em>June 2021</em>) for all Full HD models. You can upgrade the firmware of your camera directly via the <Link to="/Quick_Installation/WebUI_And_Firmware_Upgrade/">Web interface</Link>.
    </p>
  </EuiText>
);

const instarAvatar = (
  <EuiAvatar
    imageUrl="/en/images/Assistants/INSTAR-Ico.webp"
    size="l"
    name="INSTAR Deutschland GmbH"
  />
);

const comments = [
  {
    username: mPol,
    type: 'update',
    actions: timeLine,
    event: alexaRelease,
    timestamp: 'September 9th, 2020',
    timelineIcon: 'tag',
  },
  {
    username: 'INSTAR Deutschland GmbH',
    event: 'INSTAR Alexa Camera Skill',
    children: alexaAd,
    actions: annotation,
    timestamp: 'September 10th, 2020',
    timelineIcon: instarAvatar,
  },
  {
    username: mPol,
    type: 'update',
    actions: timeLine,
    event: homekitRelease,
    timestamp: 'May 10th, 2021',
    timelineIcon: 'tag',
  },
  {
    username: 'INSTAR Deutschland GmbH',
    event: 'Apple Homekit',
    children: homekitAd,
    actions: annotation,
    timestamp: 'May 10th, 2021',
    timelineIcon: instarAvatar,
  },
];

const CommentList = () => <EuiCommentList comments={comments} />;

export default CommentList