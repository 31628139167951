import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Software-based Motion Detection">
        <EuiText>
          <p>
            Adjust the cameras behaviour in case of a motion alert trigger event. You can set the camera to send you an email and to save snapshots or videos on your SD card. Please make sure you set your SMTP Server settings before activating the email notification and that you have a SD card in your cameras SDHC card slot (max. 32 GB) to use the video recording and send-to-FTP function.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

