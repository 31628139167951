import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Excursus">
        <EuiText>
            <p>Please be reminded, that the first 3 numbers of the IP address define the IP subnet of your LAN (local area network). In our sample we used an AVM Fritz!Box router, which uses the 192.168.178.X subnet, and the Telekom Speedport from T-Mobile, which uses the 192.168.2.X subnet by default. Most other common routers such as TP-Link or others are using the 192.168.1.x subnet.</p>
            <p>The "x" can be a number between 1 and 254. Routers, usually, use the number "1". In our sample above, we used 201 as the IP address for the camera. This avoids conflicts induced by the router´s DHCP Server. If the address is too low (for example 100), then there is chance that the same IP is already assigned to a different device - which would lead to an IP address conflict. If you are experiencing troubles accessing the IP address of your camera, you should try changing the IP Address - using our <Link to="/Quick_Installation/Alternative_IP_Scanner/">Camera Tool</Link> - and see if it works then.</p>
            <p>After you have set the IP address of the camera, you can then access it - from a computer or phone/tablet inside the same network - in any web browser and the web interface of the camera should open. If you don´t know the IP address of your router, you can check it in the MS-Dos Command window, by typing in <em>ipconfig</em>, this will show you your computer´s and the router´s IP address.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

