import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="System Reboot">
        <EuiText>
          <p>
            Use this function to reboot or reset your camera safely. It is not recommended to simply unplug/replug your camera or use the hardware reset unnecessarily (those actions can be harmful to running operations as well as causing hardware defects on the internal SD card). Please always use the soft- restart and reset via the web user interface.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

