import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Network_Accessories/IN-LAN_500/">
                  <StaticImage
                    src='../../../images/Search/D_Downloads_SearchThumb.webp'
                    alt="IN-LAN 500"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-LAN 500"
              description="IN-LAN 500 and IN-LAN 500p powerline communication.Connect your camera to your local network via your power grid.No LAN cable needed."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Network_Accessories/IN-LAN_500/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Network_Accessories/IN-PoE_1000/">
                  <StaticImage
                    src='../../../images/Search/D_Downloads_SearchThumb.webp'
                    alt="IN-PoE 1000"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-PoE 1000"
              description="Power your PoE camera over a LAN cable using our IN - PoE 1000 injector for single cameras.Or utilize our IN - PoE 4000 / IN - PoE 8000 switches."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Network_Accessories/IN-PoE_1000/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Network_Accessories/IN-Route_P52/">
                  <StaticImage
                    src='../../../images/Search/D_Downloads_SearchThumb.webp'
                    alt="IN-Route P52"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-Route P52"
              description="The INSTAR IN-Route line offers mobile wireless solution to share information between mobile devices.NAS solution, as well as a power bank."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Network_Accessories/IN-Route_P52/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
