import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import AppleDownloads from 'components/Downloads/Mobile_Apps/AppleDownloads';
import PhoneChangelogSearch from "components/Search/IViPhoneclSearch";
import PadChangelogSearch from "components/Search/IViPadclSearch";
import NavButtons from 'components/Downloads/Mobile_Apps/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  OutboundLink,
  EuiButton,
  EuiSpacer,
  AppleDownloads,
  PhoneChangelogSearch,
  PadChangelogSearch,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};