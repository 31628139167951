import { EuiSpacer } from '@elastic/eui';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import FloatingGallery from "components/Web_User_Interface/1440p_Series/Features/Wizard/WizardGallery";
import * as React from 'react';
export default {
  EuiSpacer,
  PermissionBox,
  BreadCrumbs,
  SEOHelmet,
  FloatingGallery,
  React
};