import React from 'react'

import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButton
} from '@elastic/eui'


import ivImage01 from '../../../../../images/Software/Windows/InstarVision/Advanced/Image003_small.webp'
import ivImage02 from '../../../../../images/Software/Windows/InstarVision/Advanced/Image050-Log_small.webp'
import ivImage03 from '../../../../../images/Software/Windows/InstarVision/Advanced/Image049_Export_small.webp'


export default function ImageCard() {

  return (
    <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage01}
                alt="Enter your License Key"
              />
            </div>
          }
          title="License"
          description="Enter your License Key"
          content={
            <EuiText>
              <p>Enter your License Key.</p>
            </EuiText>
          }
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Advanced/License/">
                  <EuiButton size="s" fill>
                    More
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
        </EuiFlexItem>
        <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage02}
                alt="Check the System Log"
              />
            </div>
          }
          title="Log Info"
          description="Check the System Log"
          content={
            <EuiText>
              <p>Check the System Log.</p>
            </EuiText>
          }
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Advanced/Log/">
                  <EuiButton size="s" fill>
                    More
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
        </EuiFlexItem>
        <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage03}
                alt="Export/Import System Settings"
              />
            </div>
          }
          title="Import / Export"
          description="Export/Import System Settings"
          content={
            <EuiText>
              <p>Export/Import System Settings.</p>
            </EuiText>
          }
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Advanced/Export/">
                  <EuiButton size="s" fill>
                    More
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
        </EuiFlexItem>
    </EuiFlexGroup>
  );
}
