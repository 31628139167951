import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Excursus">
        <EuiText>
          <p>To access your camera, you can always use the same DDNS address. So if you have more than one camera you only need to remember one address. Most important is that you change the port behind the DDNS number. The reason behind it is that your internet service provider is assigning you a new IP address every 24 hours. Once the address is changed the camera notices this and sends it to the server in order to connect it to our DDNS address. This way you can always access your camera with one address even so the IP behind it changes. So even if you are using different DDNS addresses they all link to one address. So with every address you will access your router. And because of the port forwarding your router then knows to which device you want to be forwarded to. So for example we type the following to access the cameras:</p>
          <ul>
            <li><strong>Camera 1</strong>http://d2908.ddns-instar.com:80</li>
            <li><strong>Camera 2</strong>http://d2908.ddns-instar.com:8081</li>
            <li><strong>Camera 3</strong>http://d2908.ddns-instar.com:8082 and so on...</li>
          </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

