import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_WebUI_1440p.webp'
                    alt="INSTAR 1440p Web User Interface"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="1440p Web User Interface"
              description="The Web User Interface allows you to configure and control your INSTAR IP camera from your favourite web browser."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Series/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Series/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_WebUI_1080p.webp'
                    alt="INSTAR 1080p Web User Interface"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="1080p Web User Interface"
              description="The Web User Interface allows you to configure and control your INSTAR IP camera from your favourite web browser."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Series/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Series/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_WebUI_720p.webp'
                    alt="INSTAR 720p Web User Interface"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="720p Web User Interface"
              description="The Web User Interface allows you to configure and control your INSTAR IP camera from your favourite web browser."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Series/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_WebUI_VGA.webp'
                    alt="INSTAR VGA Web User Interface"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </div>
              }
              title="VGA Web User Interface"
              description="The Web User Interface allows you to configure and control your INSTAR IP camera from your favourite web browser."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <EuiFlexItem grow={false}>
                    <EuiButton size="s" fill>
                      Read
                    </EuiButton>
                    <EuiSpacer size="s" />
                  </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
