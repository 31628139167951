import React from 'react'

import {EuiFlexGroup, EuiFlexItem, EuiText} from '@elastic/eui'

import IN9408RadarIN9008 from "./in9408-radargraph-9008"
import IN9408RadarIN5907 from "./in9408-radargraph-5907"

function IN9408RadarGrid() {

  return (
    <div style={{ padding: '15px',maxWidth: '1115px', margin: '2.5rem 0 2.5rem 0', boxShadow: '0 2px 3px 0 rgb(0 0 0 / 36%' }}>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiText size="s">
            IN-9408WQHD vs IN-9008FHD
          </EuiText>
          <div style={{height: 275}}>
            <IN9408RadarIN9008 />
          </div>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText size="s">
            IN-9408WQHD vs IN-5907HD
          </EuiText>
          <div style={{height: 275}}>
            <IN9408RadarIN5907 />
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}

export default IN9408RadarGrid