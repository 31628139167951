import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/styles'
import {
  SortingState, SelectionState, FilteringState, GroupingState,
  IntegratedFiltering, IntegratedGrouping, IntegratedSelection,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table, TableHeaderRow, TableFilterRow, TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class TechSpecGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
        columns: [
          { name: "category", title: "Category" },
          { name: "feature", title: "Feature" },
          { name: "property", title: "Property" }
        ],
        rows: [
          { category: 'Categories', feature: 'Kamera Modell', property: 'INSTAR IN-4010 V2 Outdoor PTZ Dome - Colour' },
          { category: 'Categories', feature: 'Gehäuse', property: 'Aluminium (Weiß, Schwarz)' },
          { category: 'Categories', feature: 'IP Schutzklasse', property: 'IP65 (staubdicht / wasserabweisend)' },
          { category: 'Categories', feature: 'Bildsensor', property: 'OV7740 VGA 1/5" CMOS' },
          { category: 'Categories', feature: 'Unterstützte Auflösungen', property: '640x480, 320x240, 160x120' },
          { category: 'Categories', feature: 'Video Kompression', property: 'MJPEG, JPG' },
          { category: 'Categories', feature: 'Framerate', property: 'bis zu 30fps' },
          { category: 'Categories', feature: 'Sensor Sensibilität', property: '6800 mV/lux*s' },
          { category: 'Categories', feature: 'Dynamikbereich', property: '60 dB' },
          { category: 'Categories', feature: 'Signal/Geräusch', property: '41 dB' },
          { category: 'Categories', feature: 'Tag / Nacht Modus', property: 'Ja' },
          { category: 'Categories', feature: 'Min. Beleuchtung (Farbe)', property: '0.05 Lux @ f/2.0' },
          { category: 'Categories', feature: 'Min. Beleuchtung', property: '(IR) 0.5 lux @ f/2.0' },
          { category: 'Categories', feature: '# von parallelen Streams', property: '3' },
          { category: 'Categories', feature: 'Weißabgleich', property: 'Automatisch' },
          { category: 'Categories', feature: 'Elektronische Blende', property: 'Automatisch' },
          { category: 'Categories', feature: 'Video Drehen / Spiegeln', property: 'Ja / Ja' },
          { category: 'Categories', feature: 'Video Overlays', property: 'Datum, Zeit, Kameraname' },
          { category: 'Categories', feature: 'Video Parameter', property: 'Helligkeit, Kontrast' },
          { category: 'Categories', feature: 'Linse', property: '4 - 9 mm (Manueller Fokus)' },
          { category: 'Categories', feature: 'Blickwinkel', property: '29° - 51° Horizontal' },
          { category: 'Categories', feature: 'Infrarot LEDs', property: '-' },
          { category: 'Categories', feature: 'Effektive Reichweite', property: '-' },
          { category: 'Categories', feature: 'intgr. IRcut Filter', property: 'Ja' },
          { category: 'Categories', feature: 'Optischer Zoom', property: '3x' },
          { category: 'Categories', feature: 'Digitaler Zoom', property: 'Ja' },
          { category: 'Categories', feature: 'Audio', property: 'Audio Out (3.5mm Kopfhörer)' },
          { category: 'Categories', feature: 'Mikrofon', property: 'Audio In für externe Mikrofone (z.B. IN-Mikro 380)' },
          { category: 'Categories', feature: 'Alarm I/O', property: 'Ja (potential-free alarm-out / max. 60VDC/125VAC @ 1A)' },
          { category: 'Categories', feature: 'Alarm Benachrichtigung', property: 'Email/FTP/HTTP/Alarm-Out' },
          { category: 'Categories', feature: 'Unterstützte Browser', property: 'Chrome, Safari, Firefox, Internet Explorer' },
          { category: 'Categories', feature: 'Unterstützte Windows Software', property: 'InstarVision, Sighthound, WebcamXP, iSpy, VLC Player, etc.' },
          { category: 'Categories', feature: 'Unterstützte MacOSX Software', property: 'Sighthound, SecuritySpy, VLC Player, etc.' },
          { category: 'Categories', feature: 'Unterstützte Android Apps', property: 'InstarVision, IP Cam Viewer, Tiny Cam Monitor etc.' },
          { category: 'Categories', feature: 'Unterstützte iOS Apps', property: 'InstarVision, LiveCams' },
          { category: 'Categories', feature: 'Unterstützte Windows Phone Apps', property: 'InstarVision' },
          { category: 'Categories', feature: 'Netzwerk Schnittstelle', property: '10Base-T/100Base-TX Ethernet Port / WLAN IEEE 802.11b/g (nicht bei PoE Version) / PoE IEEE 802.3af (nicht bei WiFi Version)' },
          { category: 'Categories', feature: 'Netzwerk Protokolle', property: 'TCP/IP, DHCP, PPPoE, ARP, FTP, SMTP, DNS, NTP, UPnP, HTTP, TCP, UDP' },
          { category: 'Categories', feature: 'Verschlüsselung', property: 'WEP (64/128bit), WPA/WPA2-PSK (TKIP, AES, CCMP)' },
          { category: 'Categories', feature: 'Wifi Frequenzen', property: '2.4GHz 802.11b/g (nicht bei PoE Version)' },
          { category: 'Categories', feature: 'Wifi Antenne', property: '5 dBi WLAN RP-SMA Antenna (nicht bei PoE Version)' },
          { category: 'Categories', feature: 'Zugangsbeschränkung', property: 'Username/Password - 3 Level Authentifizierung' },
          { category: 'Categories', feature: 'Netzteil', property: '110-240V ac @ 50-60Hz to 5V dc and 2000mA' },
          { category: 'Categories', feature: 'Netzstecker', property: '2.1mm In / 5.5mm Out' },
          { category: 'Categories', feature: 'Stromverbrauch', property: 'Max. 8 W' },
          { category: 'Categories', feature: 'Integr. DDNS Service', property: 'Ja' },
          { category: 'Categories', feature: 'Schwenken & Neigen', property: '355° / 90°' },
          { category: 'Categories', feature: 'Maßangaben in [cm]', property: '18.5 / 13 / 13' },
          { category: 'Categories', feature: 'Gewicht', property: '930 g' },
          { category: 'Categories', feature: 'Temperatur', property: '-5°C - 55°C' },
          { category: 'Categories', feature: 'Zertifizierungen', property: 'CE, FCC, RoHS' }

        ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns} >

          <FilteringState />

          <SortingState
            defaultSorting={[
              { columnName: 'category', direction: 'asc' },
            ]}
          />
          <GroupingState
            defaultGrouping={[{ columnName: 'category' }]}
            defaultExpandedGroups={['Categories']}
          />
          <SelectionState />

          <IntegratedFiltering />
          <IntegratedGrouping />
          <IntegratedSelection />
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
          <TableFilterRow />
          <TableGroupRow />
        </Grid>
      </Paper>
    );
  }
}

TechSpecGrid.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(TechSpecGrid)






// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'

// const featureRow = [
//   'Kamera Modell',
//   'Gehäuse',
//   'IP Schutzklasse',
//   'Bildsensor',
//   'Unterstützte Auflösungen',
//   'Video Kompression',
//   'Framerate',
//   'Sensor Sensibilität',
//   'Dynamikbereich',
//   'Signal/Geräusch',
//   'Tag / Nacht Modus',
//   'Min. Beleuchtung (Farbe)',
//   'Min. Beleuchtung',
//   '# von parallelen Streams',
//   'Weißabgleich',
//   'Elektronische Blende',
//   'Video Drehen / Spiegeln',
//   'Video Overlays',
//   'Video Parameter',
//   'Linse',
//   'Blickwinkel',
//   'Infrarot LEDs',
//   'Effektive Reichweite',
//   'intgr. IRcut Filter',
//   'Optischer Zoom',
//   'Digitaler Zoom',
//   'Audio',
//   'Mikrofon',
//   'Alarm I/O',
//   'Alarm Benachrichtigung',
//   'Unterstützte Browser',
//   'Unterstützte Windows Software',
//   'Unterstützte MacOSX Software',
//   'Unterstützte Android Apps',
//   'Unterstützte iOS Apps',
//   'Unterstützte Windows Phone Apps',
//   'Netzwerk Schnittstelle',
//   'Netzwerk Protokolle',
//   'Verschlüsselung',
//   'Wifi Frequenzen',
//   'Wifi Antenne',
//   'Zugangsbeschränkung',
//   'Netzteil',
//   'Netzstecker',
//   'Stromverbrauch',
//   'Integr. DDNS Service',
//   'Schwenken & Neigen',
//   'Maßangaben in [cm]',
//   'Gewicht',
//   'Temperatur',
//   'Zertifizierungen',
// ]


// const propertyRow = [
//   'INSTAR IN-4010 V2 Outdoor PTZ Dome - Colour',
//   'Aluminium (Weiß, Schwarz)',
//   'IP65 (staubdicht / wasserabweisend)',
//   'OV7740 VGA 1/5" CMOS',
//   '640x480, 320x240, 160x120',
//   'MJPEG, JPG',
//   'bis zu 30fps',
//   '6800 mV/lux*s',
//   '60 dB',
//   '41 dB',
//   'Ja',
// 	'0.05 Lux @ f/2.0',
//   '(IR) 0.5 lux @ f/2.0',
// 	'3',
// 	'Automatisch',
// 	'Automatisch',
// 	'Ja / Ja',
// 	'Datum, Zeit, Kameraname',
// 	'Helligkeit, Kontrast',
// 	'4 - 9 mm (Manueller Fokus)',
// 	'29° - 51° Horizontal',
// 	'-',
//   '-',
// 	'Ja',
// 	'3x',
// 	'Ja',
// 	'Audio Out (3.5mm Kopfhörer)',
// 	'Audio In für externe Mikrofone (z.B. IN-Mikro 380)',
// 	'Ja (potential-free alarm-out / max. 60VDC/125VAC @ 1A)',
// 	'Email/FTP/HTTP/Alarm-Out',
// 	'Chrome, Safari, Firefox, Internet Explorer',
// 	'InstarVision, Sighthound, WebcamXP, iSpy, VLC Player, etc.',
// 	'Sighthound, SecuritySpy, VLC Player, etc.',
// 	'InstarVision, IP Cam Viewer, Tiny Cam Monitor etc.',
// 	'InstarVision, LiveCams',
// 	'InstarVision',
// 	'10Base-T/100Base-TX Ethernet Port / WLAN IEEE 802.11b/g (nicht bei PoE Version) / PoE IEEE 802.3af (nicht bei WiFi Version)',
//   'TCP/IP, DHCP, PPPoE, ARP, FTP, SMTP, DNS, NTP, UPnP, HTTP, TCP, UDP',
//   'WEP (64/128bit), WPA/WPA2-PSK (TKIP, AES, CCMP)',
//   '2.4GHz 802.11b/g (nicht bei PoE Version)',
//   '5 dBi WLAN RP-SMA Antenna (nicht bei PoE Version)',
//   'Username/Password - 3 Level Authentifizierung',
//   '110-240V ac @ 50-60Hz to 5V dc and 2000mA',
//   '2.1mm In / 5.5mm Out',
//   'Max. 8 W',
//   'Ja',
//   '355° / 90°',
//   '18.5 / 13 / 13',
//   '930 g',
//   '-5°C - 55°C',
//   'CE, FCC, RoHS',
// ]

// // Render your table
// const ConnectTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={featureRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Category</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {featureRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={420}
//         />
//         <Column
//           header={<Cell>Connection</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {propertyRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={425}
//         />
//       </Table>
//     )
// }

// export default ConnectTable