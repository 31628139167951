import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import YoutubeVideoFTP from "components/Motion_Detection/Router_as_a_FTP_Server/Synology_as_FTP_Server/YoutubeVideoCardSynoNAS";
import YoutubeVideoWQHDCam from 'components/Software/Other_Platforms/Synology/VideoCamWQHDSynology';
import NavButtons from 'components/Motion_Detection/NavButtons';
import ForumBox from 'components/Motion_Detection/Router_as_a_FTP_Server/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  YoutubeVideoFTP,
  YoutubeVideoWQHDCam,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};