import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import { OutboundLink } from 'gatsby-plugin-gtag';
import PrimaryBox from 'components/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/How_to_add_your_camera/PrimaryBox';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  OutboundLink,
  PrimaryBox,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};