import LiveCamsCards01 from 'components/Software/iOS/LiveCams/liveCamsCard01';
import LiveCamsCards02 from 'components/Software/iOS/LiveCams/liveCamsCard02';
import LiveCamsCards03 from 'components/Software/iOS/LiveCams/liveCamsCard03';
import NavButtons from 'components/Software/iOS/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  LiveCamsCards01,
  LiveCamsCards02,
  LiveCamsCards03,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};