import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import ConfigTable01 from "components/Internet_Access/Port_Forwarding/Zyxel_Speedlink/ConfigTable01";
import DangerBox from 'components/Internet_Access/Port_Forwarding/Unitymedia_Connectbox/DangerBox';
import NavButtons from 'components/Internet_Access/Port_Forwarding/NavButtons';
import ForumBox from 'components/Internet_Access/Port_Forwarding/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiButton,
  EuiSpacer,
  ConfigTable01,
  DangerBox,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};