import { Link } from 'gatsby';
import WebcamArticles from 'components/Products/USB-Webcams/IntroWebcam';
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  WebcamArticles,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};