import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class VideoTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Video Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Video resolution', descriptionColumn: 'Your camera can send it´s h.264 video in form of three different streams, with 1080p, 320p and 160p resolution. You can access all 3 of them independently with Thirdparty Software. Selecting a stream here defines which stream is displayed inside the web user interface. But all streams stay active and can be utilized, independent of the stream that is selected here.' },
        { actionColumn: 'Image Refresh Rate (fps)', descriptionColumn: 'This value represents the frames per seconds in your camera´s video stream. Please be aware that this is only a theoretical maximum that you don´t want your camera to exceed. The actual framerate might be bottleneck`ed by your camera´s network connection and the exposure time of the image sensor (the darker the image becomes, the longer the image sensor has to collect light and the longer it takes to create a frame). The framerate is suitable to set an upper limit to the bandwidth requirement of your camera, to prevent a network overload. The default value of 15 fps is usually a workable compromise for most surveillance tasks.' },
        { actionColumn: 'Video Compression', descriptionColumn: 'The video compression lowers the bandwidth requirements, when accessing your cameras video stream over the internet or an unstable WiFi signal. The stronger the video compression, the less bandwidth is required. But you will see more compression artefacts in your camera´s video stream.' },
        { actionColumn: 'Video Bitrate', descriptionColumn: 'The bitrate determines how many information can be packed into the video stream. Increasing the bitrate will massively increase the Bandwidth Requirements for accessing the stream over the local network and will also increase the File Size of recorded videos. A significant advantage in image quality will only be achieved, if the video contains a lot of fine details. In most cases it is recommended to leave this value at its default of 2560kbps.' },
        { actionColumn: 'Video Codec', descriptionColumn: 'Select the video codec that is used for the live video stream and video recording for each of the 3 video sources of your camera.' },
        { actionColumn: 'Variable Bitrate', descriptionColumn: 'You can adjust if you want to hold the set bitrate, or if you want your camera to automatically use a lower bitrate if the video is low in details (e.g. your camera can often work with a much lower bitrate during the night). This will affect the file size of recorded videos, the processor load of your camera, as well as the bandwidth requirements of the live stream. We recommend that you use the variable Bitrate.' },
        { actionColumn: 'Keyframe Interval', descriptionColumn: 'A key frame (I-Frame) is a frame encoded in its entirety, much like a JPEG image. P-frames are "predicted", i.e., they contain only the changes from frame to frame. Hence I frames are typically 10x larger than P frames, so the fewer you have, the more compression you can get. As a compromise between image quality and bandwidth requirement, it is recommended to create about 1 keyframe every second. With the default interval of 20 frames and a framerate of 15fps, your camera will send an I-Frame every (20 Frames/15 Frames/s) = 1.33s. If you use an external software to record alarm videos, you might observe, that those videos are sometimes missing a second in the beginning - you just see a black or green/distorted frame. This happens because the recording can only start once the first keyframe is send by your camera. You can prevent this, by using your camera´s Internal Alarm Recording on SD card instead.' },
        { actionColumn: 'h.264 Video Plugin', descriptionColumn: 'Here you can choose the Video Plugin the web user interface uses to display the h.264 video stream. The default plugin is HTML5 Video.' },
        { actionColumn: 'MotionJPEG Video Mode', descriptionColumn: 'Your camera is able to send the video, instead of an h.264 encoded stream, in form of a sequence of single snapshots. This Motion-JPEG Stream has the advantage, that it can be displayed in every web browser without the need for a video plugin. This is necessary if you want to see the live video on a computer where you don´t have the rights to install a browser plugin, e.g. your company PC. The disadvantage of this mode is that the compression is a lot weaker and you will need a lot more bandwidth.' },
        { actionColumn: 'HTML5 & MJPEG Resolution', descriptionColumn: 'Set the resolution that the MJPEG and HTML5 video stream should use for display in the web interface. This setting does not affect the resolution of the recorded alarm videos or the video streams in the mobile or desktop apps' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

VideoTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(VideoTable)