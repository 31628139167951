import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class DownloadTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "changeColumn", title: "Changes" }
      ],
      rows: [
        { changeColumn: 'The software sometimes crashed after an unavailable channel was restored.'},
        { changeColumn: 'Removed the wrong "IN-4010" entry at the camera model selection box and added the entry "IN-9008 Full HD PoE"'},
        { changeColumn: 'Fixed PTZ issues for camera models "IN-4010", "IN-4010 V2" and "IN-4011". In addition, the control of the "IN-8015 Full HD" was changed from step-by-step control to control with stop command, resulting in a smoother movement when controlling with the directional pad.'},
        { changeColumn: 'An update kept the old Start Menu and Desktop Shortcuts entries. These old entries now will be deleted during an update.'},
        { changeColumn: 'Fixed a bug in the configuration file converter that could cause the backup file to be named incorrectly.'},
        { changeColumn: 'In case of missing write permissions to the installation directory, despite of a valid license codes, it was still queried again and again and so the InstarVision could not be started or used. This can no longer happen now because the location where the configuration and log file are saved has been changed to a user-specific folder "C:&#92;Users&#92;&lt;username&gt;&#92;AppData&#92;Roaming&#92;InstarVision“ on which write access exists by default. Running the software as an administrator during a standard installation under "Program Files (x86)" is therefore no longer necessary.'},
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

DownloadTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(DownloadTable)






// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'

// const cameraRow = [
//   'The software sometimes crashed after an unavailable channel was restored.',
//   'Removed the wrong "IN-4010" entry at the camera model selection box and added the entry "IN-9008 Full HD PoE"',
//   'Fixed PTZ issues for camera models "IN-4010", "IN-4010 V2" and "IN-4011". In addition, the control of the "IN-8015 Full HD" was changed from step-by-step control to control with stop command, resulting in a smoother movement when controlling with the directional pad.',
//   'An update kept the old Start Menu and Desktop Shortcuts entries. These old entries now will be deleted during an update.',
//   'Fixed a bug in the configuration file converter that could cause the backup file to be named incorrectly.',
//   'In case of missing write permissions to the installation directory, despite of a valid license codes, it was still queried again and again and so the InstarVision could not be started or used. This can no longer happen now because the location where the configuration and log file are saved has been changed to a user-specific folder "C:&#92;Users&#92;&lt;username&gt;&#92;AppData&#92;Roaming&#92;InstarVision“ on which write access exists by default. Running the software as an administrator during a standard installation under "Program Files (x86)" is therefore no longer necessary.',
// ]

// // Render your table
// const ConnectTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={cameraRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Fixed bugs</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {cameraRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={850}
//         />
//       </Table>
//     )
// }

// export default ConnectTable