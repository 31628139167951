import NavButtons from 'components/Software/Windows/NavButtons';
import ForumBox from 'components/Software/Windows/Blue_Iris/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};