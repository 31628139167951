import React from 'react'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'

import ivImage from '../../../../../../images/Software/Windows/InstarVision/Advanced/Explorer/Image049_Explorer.webp'

export default function ImageCard() {

  return (
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem grow={false}>
            <EuiImage
              hasShadow
              allowFullScreen
              alt="InstarVision v2 for Windows"
              src={ivImage}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
              <p><strong>Manage your video recordings manually.</strong></p>
              <p>This button opens the active recording directory in the Windows Explorer, giving you access to all your video recordings. The folder is organized by the Surveillance Center - a new folder is created for each day and a subfolder for every active camera.</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
  );
}
