import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Powerline vs Power-over-Ethernet">
        <EuiText>
        <p><strong>Powerline</strong> allows you to connect your camera to your local network over the power grid. The camera still needs to be powered by the included power supply. <strong>Power-over-Ethernet</strong> combines both the network as well as the power supply in a regular Ethernet cable. You only need a <Link to="/Quick_Installation/Power_over_Ethernet/">PoE Injector</Link> or <Link to="/Quick_Installation/Power_over_Ethernet/">PoE SWITCH</Link> to add the voltage to the Ethernet cable.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

