import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Series_CGI_List/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_CGICommands_1440p.webp'
                    alt="1440p Series CGI List"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="2K+ Series CGI List"
              description="Control your 1440p WQHD 2K+ camera via the HTTP interface"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/1440p_Series_CGI_List/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Series_CGI_List/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_CGICommands_1080p.webp'
                    alt="1080p Series CGI List"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Full HD Series CGI List"
              description="Control your 1080p FullHD camera via the HTTP interface"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/1080p_Series_CGI_List/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Series_CGI_List/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_CGICommands_720p.webp'
                    alt="720p Series CGI List"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="HD Series CGI List"
              description="Control your 720p HD camera via the HTTP interface"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/720p_Series_CGI_List/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/CGI_Commands/VGA_Series_CGI_List/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_CGICommands_VGA.webp'
                    alt="VGA Series CGI List"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="VGA Series CGI List"
              description="Control your 480p VGA camera via the HTTP interface"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/CGI_Commands/VGA_Series_CGI_List/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
