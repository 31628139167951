
import React, { useState } from 'react'
import {Link} from 'gatsby'

import {
  EuiCallOut,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiButton,
  EuiTitle,
  EuiText,
  EuiSpacer
} from '@elastic/eui'

import homekitBanner01 from "images/Products/Homekit/Homekit_1080p_Smartphone_01.webp"
import homekitBanner02 from "images/Products/Homekit/Homekit_1080p_Smartphone_02.webp"

const FlyOut = () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

  const closeFlyout = () => setIsFlyoutVisible(false);

  const showFlyout = () => setIsFlyoutVisible(true);

  let flyout;

  const callOut = (
    <EuiCallOut iconType="help">
      <p>
        The connection to the Homekit service is available from firmware version <code>3.2(361)</code> (<em>June 2021</em>) for all Full HD models. You can upgrade the firmware of your camera directly via the <Link to="/Quick_Installation/WebUI_And_Firmware_Upgrade/">Web interface</Link>.
      </p>
    </EuiCallOut>
  );

  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={closeFlyout}
        size="l"
        aria-labelledby="flyoutWithBannerTitle">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="flyoutWithBannerTitle">Apple Homekit</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody banner={callOut}>
          <EuiText>
            <h4>Adding your camera</h4>
            <p>Use your INSTAR Full HD IP camera in Apple Homekit. HomeKit is a software framework from Apple provided in iOS/iPadOS that allows you to communicate with and control your camera via Apple devices. It provides you with a way to automatically detect your camera and access its live video.</p>
          </EuiText>
          <EuiSpacer />
          <img src={homekitBanner01} alt="INSTAR Homekit" style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}} />
          <EuiSpacer />
          <EuiText>
            <p><Link to="/Web_User_Interface/1080p_Serie/Smarthome/Homekit/">Activate the Homekit service</Link> and open the Homekit app on your iOS device and either scan the QR code here in the WebUI of your cameras or use the automatic network scan by selecting that you do not have a QR code to scan. Scanning the QR code will automatically add your camera. If you used the network scan, you need to select the camera you want to add from the list of devices detected on your network.</p>
          </EuiText>
          <EuiSpacer />
          <img src={homekitBanner02} alt="INSTAR Homekit" style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}} />
          <EuiSpacer />
          <EuiText>
            <p>If you have not used the QR scanner, you will now be prompted to enter the pin code of your camera, which is displayed above the QR code within the webUI. You can then assign a location and a name to your camera and save the configuration.</p>
          </EuiText>
          <EuiSpacer />
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }
  return (
    <div>
      <EuiSpacer />
      <EuiButton onClick={showFlyout} color="text">Homekit</EuiButton>
      {flyout}
    </div>
  );
};

export default FlyOut;