import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ONVIFTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "ONVIF Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Activate ONVIF Protocol', descriptionColumn: 'Activate or deactivate the ONVIF Service of your camera.' },
        { actionColumn: 'ONVIF Authentication', descriptionColumn: 'If you third-party ONVIF device cannot connect with your camera, you can try to deactivate the authentication for the ONVIF Protokoll. Be aware that everyone might be able to login your cameras live video stream, if authentication is deactivated. It is not recommended to forward the ONVIF port to the internet in this case.' },
        { actionColumn: 'Use ONVIF Device Time Setting', descriptionColumn: 'The ONVIF protocol allows you to adjust your cameras date & time setting. For example, if you are using a ONVIF NVR video recorder or a Recording Software that connects to your camera via ONVIF, you can allow this device or software to set the time inside your camera. This way you can make sure, that all your cameras are in sync.' },
        { actionColumn: 'ONVIF Port', descriptionColumn: 'This is the port that external devices have to use to communicate with your camera over the ONVIF Protokoll. It is recommended to leave it at its default 8080. Make sure that you don´t use the same port as LAN, RTMP or RTSP Port!' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ONVIFTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ONVIFTable)