import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Remote Access">
        <EuiText>
          <p>
            You have two ways to access your camera over the internet - the easy way is called. The only way to get complete access to all your camera´s functions is called DDNS. The DDNS or Internet Address gives you access to your cameras web user interface.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

