import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Software/Home_Automation/PrimaryBoxMQTT';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
import NavButtons from 'components/Software/Home_Automation/NavButtons';
import ForumBox from 'components/Software/Home_Automation/IOBroker/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  PrimaryBox,
  TimeLine,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};