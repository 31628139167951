import React from 'react'

import {
  EuiCard,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import icCam1 from "../../../../images/Software/iOS/ICCAM/ICCAM_01.webp"
import icCam2 from "../../../../images/Software/iOS/ICCAM/ICCAM_02.webp"
import icCam3 from "../../../../images/Software/iOS/ICCAM/ICCAM_03.webp"
import icCam4 from "../../../../images/Software/iOS/ICCAM/ICCAM_04.webp"




export default function softwareArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={icCam1}
                    alt="ICCAM for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p><strong>Fig1.</strong> Press Add a new camera to add your INSTAR P2P camera.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={icCam2}
                    alt="ICCAM for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p><strong>Fig2.</strong> Press Search in LAN to let the app find your P2P camera inside your local network.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={icCam3}
                    alt="ICCAM for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                      <p><strong>Fig3.</strong> Select your camera from the list.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={icCam4}
                    alt="ICCAM for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p><strong>Fig4.</strong> And add your camera name and administrator login.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
