import React from 'react'

import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Note:">
        <EuiText>
          <p>This is an updated guide using Docker to install Home Assistant instead of installing the software directly through it's Python packages as <Link to="/Software/Linux/Home_Assistant/Deprecated/">described in our now deprecated guide</Link>. This Guide is also updated to be used with INSTAR cameras newer than the Full HD series. There are also a list of <Link to="/Advanced_User/All_Home_Assistant_Tutorials/">FAQs</Link> available that are already updated.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

