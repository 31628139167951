import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import OverviewGrid from 'components/Frequently_Asked_Question/1080p_Forbidden_Character/forbiddenCharacterTable';
import * as React from 'react';
export default {
  EuiSpacer,
  BreadCrumbs,
  SEOHelmet,
  OverviewGrid,
  React
};