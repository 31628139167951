import { Link } from 'gatsby';
import OverviewGrid from "../../../../src/components/1080p_Series_CGI_List/Complete_CGI_List/1080pOverviewGrid";
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import NavButtons from 'components/1080p_Series_CGI_List/NavButtons';
import * as React from 'react';
export default {
  Link,
  OverviewGrid,
  BreadCrumbs,
  SEOHelmet,
  NavButtons,
  React
};