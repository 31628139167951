import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Administration',
      href: '/en/Motion_Detection/INSTAR_Cloud/Administration/',
    },
    {
      text: 'Recordings',
      href: '/en/Motion_Detection/INSTAR_Cloud/Alarm_Recordings/',
    },
    {
      text: 'Sets',
      href: '/en/Motion_Detection/INSTAR_Cloud/Camera_Sets/',
    },
    {
      text: 'Account',
      href: '/en/Motion_Detection/INSTAR_Cloud/Create_User_Account/',
    },
    {
      text: 'Storage',
      href: '/en/Motion_Detection/INSTAR_Cloud/Storage/',
    },
    {
      text: 'User',
      href: '/en/Motion_Detection/INSTAR_Cloud/User/',
    },
    {
      text: 'Extensions',
      href: '/en/Motion_Detection/INSTAR_Cloud/Cloud_Extensions/',
    },
    {
      text: '◄ Cloud',
      href: '/en/Motion_Detection/INSTAR_Cloud/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Cloud Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}