import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class AreasTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Alarm Areas" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Areas', descriptionColumn: 'Select the Area that you want to adjust (1-4) by clicking the Select radio button. Now move your mouse cursor over the image and draw the detection area onto the canvas by holding your left mouse button. To add another area, select the corresponding radio button and repeat the steps above. Those areas tell your camera where to search for motion. Everything that is not covered by an area will be ignored by the motion detection function.' },
        { actionColumn: 'Active', descriptionColumn: 'Click the Activate button to activate the motion detection for the corresponding area. Inactive areas will be displayed in white. Active areas will be displayed in their corresponding colour - Red, Blue, Green or Yellow. A motion detection inside an active area will trigger all activated Alarm Actions. You can quickly activate / deactivate the motion detection of your camera using our iPhone, iPad, Android or Windows Phone App - just click the button with the burglar symbol (red = active).' },
        { actionColumn: 'Sensitivity', descriptionColumn: 'Set a threshold for the motion detection function. With a higher sensitivity less pixel inside the video frame have to change to cause an alert and trigger your programmed Alarm Actions. But be aware that the sensitivity also depends on the size of your detection area. A small area is always more sensitive than a big area. Adjust your sensitivity and area size accordingly to prevent false or missed alerts.' },
        { actionColumn: 'All', descriptionColumn: 'Click on the All button to cover the whole image with your selected area.' },
        { actionColumn: 'Reset', descriptionColumn: 'Move all areas back to their default corner position.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

AreasTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(AreasTable)