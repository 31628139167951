import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/All_Node-RED_Tutorials/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Node-RED.webp'
                    alt="Node-RED Tutorials"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Node-RED Tutorials"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/All_Node-RED_Tutorials/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/All_ioBroker_Tutorials/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_ioBroker.webp'
                    alt="ioBroker Tutorials"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="ioBroker Tutorials"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/All_ioBroker_Tutorials/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/All_OpenHAB_Tutorials/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_OpenHAB.webp'
                    alt="OpenHAB Tutorials"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="OpenHAB Tutorials"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/All_OpenHAB_Tutorials/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Alarmserver_Queries_for_your_Smarthome/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Node-RED.webp'
                    alt="Alarmserver Queries für Ihr Smarthome"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarmserver Queries for your Smarthome"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Alarmserver_Queries_for_your_Smarthome/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Alexa_Echo5_and_Monocle_Gateway/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Alexa.webp'
                    alt="Alexa Echo5 & Monocle Gateway"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alexa Echo5 & Monocle Gateway"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Alexa_Echo5_and_Monocle_Gateway/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Alexa_Voice_Control_without_Cloud/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Alexa.webp'
                    alt="Alexa Cloud-freie Sprachekontrolle"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alexa Cloud-free Voice Control"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Alexa_Voice_Control_without_Cloud/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/All_FHEM_Tutorials/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_FHEM.webp'
                    alt="All FHEM Tutorials"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="All FHEM Tutorials"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/All_FHEM_Tutorials/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/All_Home_Assistant_Tutorials/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_HomeAssistant.webp'
                    alt="All Home Assistant Tutorials"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="All Home Assistant Tutorials"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/All_Home_Assistant_Tutorials/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Homebridge_INSTAR_MQTT_to_Homekit/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Homebridge.webp'
                    alt="Homebridge INSTAR MQTT & Homekit"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Homebridge INSTAR MQTT & Homekit"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Homebridge_INSTAR_MQTT_to_Homekit/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Homematic_CCU3_and_RedMatic/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Homematic.webp'
                    alt="Homematic CCU3 & RedMatic"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Homematic CCU3 & RedMatic"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Homematic_CCU3_and_RedMatic/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/IFTTT_and_INSTAR_FHD_Cameras/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_IFTTT.webp'
                    alt="IFTTT & INSTAR FHD Cameras"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IFTTT & INSTAR FHD Cameras"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/IFTTT_and_INSTAR_FHD_Cameras/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/IFTTT_as_Alarmserver/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_IFTTT.webp'
                    alt="IFTTT als Alarmserver"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IFTTT as Alarmserver"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/IFTTT_as_Alarmserver/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/XiaoMi_Home_Zigbee2MQTT/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Zigbee.webp'
                    alt="XiaoMi Home Zigbee2MQTT"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="XiaoMi Home Zigbee2MQTT"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/XiaoMi_Home_Zigbee2MQTT/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/ZoneMinder_inside_a_Docker_Container/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_ZoneMinder.webp'
                    alt="ZoneMinder in einem Docker Container"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="ZoneMinder in a Docker Container"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/ZoneMinder_inside_a_Docker_Container/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Kerberos_with_Docker/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Lupus_XT2.webp'
                    alt="Kerberos.io Video Surveillance"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Kerberos.io video surveillance and INSTAR cameras"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Kerberos_with_Docker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

<EuiSpacer />

<EuiFlexItem>
  <EuiFlexGroup gutterSize="l" direction="row" >
    <EuiFlexItem>
      <EuiCard
        textAlign="left"
        image={
          <Link to="/Advanced_User/All_BlueIris_Tutorials/">
            <StaticImage
              src='../../../images/Search/P_SearchThumb_Blue_Iris.png'
              alt="BlueIris Surveillance Software"
              placeholder="dominantColor"
              formats={["auto", "webp", "avif"]}
              loading="lazy"
              />
          </Link>
        }
        title="BlueIris Surveillance Software"
        footer={
          <EuiFlexGroup justifyContent="flexEnd">
            <Link to="/Advanced_User/All_BlueIris_Tutorials/">
              <EuiFlexItem grow={false}>
                <EuiButton size="s" fill>
                  Read
                </EuiButton>
                <EuiSpacer size="s" />
              </EuiFlexItem>
            </Link>
          </EuiFlexGroup>
        }
      />
    </EuiFlexItem>
    <EuiFlexItem>
    </EuiFlexItem>
    <EuiFlexItem>
    </EuiFlexItem>
  </EuiFlexGroup>
</EuiFlexItem>
    </React.Fragment>
  );
}
