import React from 'react'
import { Link, useStaticQuery, graphql } from "gatsby"

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiTitle,
  EuiText
} from '@elastic/eui'

const PANEL_STYLE = { boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' }


const FAQBox = () => {
    const data = useStaticQuery(graphql`
        query {
         allMdx(filter: {frontmatter: {faq: {regex: "/WQHD & UHD Cameras/"}}}, sort: {fields: frontmatter___dateChanged, order: DESC}) {
                edges {
                    node {
                        id
                        frontmatter {
                            title
                            path
                            toc
                            dateChanged(fromNow: true)
                        }
                        excerpt(pruneLength: 200, truncate: true)
                    }
                }
            }
        }
      `)
      return (
        <EuiFlexGroup gutterSize="l" direction="column" style={{margin: '0 7px'}} >
          {data.allMdx.edges.map(({ node }) => (
            <EuiFlexItem key={node.id}>
                <Link to={node.frontmatter.path}>
                    <EuiPanel hasBorder={false} hasShadow={false} style={PANEL_STYLE}>
                    <EuiFlexGroup wrap>
                        <EuiFlexItem style={{ maxWidth: 56 }}>
                            <img src={node.frontmatter.toc} alt="INSTAR IN-8415 2k+ (WQHD) Frequently Asked Questions" />
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiTitle size="s">
                                <h4>{node.frontmatter.title}</h4>
                            </EuiTitle>
                            <EuiText grow={false} size="s" color="default">
                                <p>{node.excerpt}</p>
                            </EuiText>
                            <EuiText grow={false} size="s" color="subdued">
                                <p>{node.frontmatter.dateChanged}</p>
                            </EuiText>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    </EuiPanel>
                </Link>
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      )
  }
  
  export default FAQBox