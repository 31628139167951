import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
};

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
);

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0,
    }}
    {...restProps}
  />
);

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PhotoScheduleTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Snapshot Schedule" },
        { name: "descriptionColumn", title: "Description" },
      ],
      rows: [
        {
          actionColumn: "SD Card",
          descriptionColumn:
            "Define schedules for snapshot recording for days of the week and time of the day onto the local SD card of your camera.",
        },
        {
          actionColumn: "EMail",
          descriptionColumn:
            "Define schedules for snapshot recording for days of the week and time of the day by Email. Make sure that you defined a SMTP server and email recipient under Features / EMail.",
        },
        {
          actionColumn: "FTP",
          descriptionColumn:
            "Define schedules for snapshot recording for days of the week and time of the day to your FTP server. Make sure you defined the FTP server you want to use under Features / FTP.",
        },
        {
          actionColumn: "Fixed Filename",
          descriptionColumn:
            "Only the FTP Upload allows you to define a fixed name for the uploaded image file. Use this option e.g. if you want to embedd the image in your website and don`t want the hardcoded link to this file to change.",
        },
        {
          actionColumn: "Save snapshots on SD card / Email / FTP",
          descriptionColumn:
            "To add a schedule click the green plus icon, tick the days from Mon - Sun you want the snapshot to be taken and set a time from 0:00 to 23:59.",
        },
      ],
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{ display: "inline-block", maxWidth: "915px" }}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PhotoScheduleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PhotoScheduleTable);
