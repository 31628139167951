
import React, { useState, Fragment } from 'react'
import { Link } from 'gatsby'

import {
  EuiButton,
  EuiButtonIcon,
  EuiToolTip,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiHideFor
} from '@elastic/eui'

import SimpleSearch from './SimpleSearch'

const FlyOut = () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

  const closeFlyout = () => setIsFlyoutVisible(false);
  const showFlyout = () => setIsFlyoutVisible(true);

  const flyoutContent = (
    <Fragment>
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
              <h2 id="searchFlyout">INSTAR Search</h2>
          </EuiTitle>
          <EuiSpacer size="s" />
          <EuiText color="subdued">
              <p>
                  Welcome to the INSTAR Search Assistant - type in suitable keywords to find all related Wiki articles.
              </p>
          </EuiText>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
            <EuiSpacer />
            <EuiText>
                <SimpleSearch closeFlyout={closeFlyout} />
            </EuiText>
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                iconType="cross"
                onClick={closeFlyout}
                flush="left">
                Close
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <Link to="/Search/">
                <EuiButton
                  iconType="search"
                  fill>
                  Advanced Search
                </EuiButton>
              </Link>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
    </Fragment>
  );

  let flyout;

  if (isFlyoutVisible) {
    flyout = (
      <Fragment>
        <EuiHideFor sizes={['xs', 's', 'm']}>
          <EuiFlyout
            onClose={closeFlyout}
            hideCloseButton
            size="m"
            type="overlay"
            ownFocus={true}
            className="eui-yScrollWithShadows eui-xScroll"
            aria-labelledby="searchFlyout">
            {flyoutContent}
          </EuiFlyout>
        </EuiHideFor>
        <EuiHideFor sizes={['l', 'xl']}>
          <EuiFlyout
            onClose={closeFlyout}
            hideCloseButton
            size="l"
            type="push"
            ownFocus={false}
            className="eui-yScrollWithShadows eui-xScroll"
            aria-labelledby="searchFlyout">
            {flyoutContent}
          </EuiFlyout>
        </EuiHideFor>
      </Fragment>
    );
  }

  const buttonStyle = {
    backgroundColor: "orange"
 }

  return (
    <div style={{ position: 'fixed', right: 50, bottom: 55, zIndex: 1 }}>
      <EuiFlexItem grow={false}>
        <EuiToolTip
        position="top"
        content={
          <p>
            INSTAR Search
          </p>
        }
        >
          <EuiButtonIcon
            display="fill"
            iconType="search"
            style={buttonStyle}
            onClick={showFlyout}
            aria-label="Next"
            iconSize="l"
            size="m" />
        </EuiToolTip>
      </EuiFlexItem>
      {/* <EuiButton
        style={buttonStyle}
        iconType="search"
        color="danger"
        onClick={showFlyout}
        fill size="s">
            Search
        </EuiButton> */}
      {flyout}
    </div>
  );
};

export default FlyOut;