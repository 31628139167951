import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import IN8015DayGallery from "components/Products/Lenses/IN-8015/in8015-day-gallery";
import IN8015NightGallery from "components/Products/Lenses/IN-8015/in8015-night-gallery";
import IN8015ResolutionGallery from "components/Products/Lenses/IN-8015/in8015-rescompare-gallery";
import IN8015CardGrid01 from "components/Products/Lenses/IN-8015/Card_Grid/in8015-lenses-cards";
import IN8015CardGrid02 from "components/Products/Lenses/IN-8015/Card_Grid/in8015-imageQ-cards";
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  IN8015DayGallery,
  IN8015NightGallery,
  IN8015ResolutionGallery,
  IN8015CardGrid01,
  IN8015CardGrid02,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};