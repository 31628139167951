import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import CameraCards from 'components/Frequently_Asked_Question/Heating_Element_Installation/CameraCards';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  CameraCards,
  BreadCrumbs,
  SEOHelmet,
  React
};