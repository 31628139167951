import React from 'react'

import { Player } from 'video-react'

import {
    EuiTitle,
    EuiText,
    EuiIcon,
    EuiPanel
  } from '@elastic/eui'

import { OutboundLink } from 'gatsby-plugin-gtag'

require('../../Layout/chrome/assets/video.css')

function VideoCard() {
  return (
    <EuiPanel paddingSize="m">
        <EuiTitle size="s"><h4>Motion Detection Setup</h4></EuiTitle>
        <Player>
            <source src="https://wiki.instar.com/videos/Serielle_Verbindung_für_INSTAR_HD_Kameras_Windows.mp4" />
        </Player>
        <EuiText>
            <p>In diesem Video erklären wir Ihnen wie Sie eine serielle Verbindung zu Ihrer INSTAR HD IP Kamera herstellen können unter Windows 7 / 8 / 10. Dazu benötigen Sie das Programm Tera Term, dieses finden Sie in der aktuellsten Version hier: <OutboundLink href="https://osdn.net/projects/ttssh2/releases/" target="_blank" rel="noopener noreferrer">Tera Term <EuiIcon type="popout" /></OutboundLink>. Auf <OutboundLink href="https://www.youtube.com/watch?v=fYrV-vr8zbs" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
        </EuiText>
    </EuiPanel>
  );
}

export default VideoCard