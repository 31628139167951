import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class DownloadTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "changeColumn", title: "Changes" }
      ],
      rows: [
        { changeColumn: 'The audio of a continuous recording was not stored properly for some time, so that after a few recordings the audio sounds only very distorted. This bug has been fixed now.' },
        { changeColumn: 'Already fixed but no longer working after the changes to the 2.3 beta. The InstarPlayer does not start by default with the selected recording path if it was started from the InstarVision.' },
        { changeColumn: 'The robber icon, which appears above the video display of a channel and allows the InstarVision`s motion detection to be turned on and off, did not work. This bug has now been fixed.' },
        { changeColumn: 'Fixed a bug where if a wrong password was entered when logging on a user, it could happen that the resulting error message was displayed twice in succession.' },
        { changeColumn: 'Under the alarm recording settings, the text "Starte Programm bei Alarm" has not been fully displayed.' },
        { changeColumn: 'The IR LED status of a channel, which can be displayed above the video display and switched with a click on the camera, does not work. This bug has now been fixed.' },
        { changeColumn: 'It has been possible for a logged in user who did not have the right to watch a video channel to watch it through stopping and starting. This is no longer possible.' },
        { changeColumn: 'The user interface was not updated after changing the logged in user or only after a restart of the software.' },
        { changeColumn: 'Previously, it was possible for a user who was not an administrator but had the system right to disable the software login. After deactivation and restart it could then change all user rights. This is no longer possible because the software login setting now is greyed out in this case.' },
        { changeColumn: 'The identifiers of the buttons under "User" did not have a German translations.' },
        { changeColumn: 'The default user did not appear under "About InstarVision."' },
        { changeColumn: 'Fixed a bug that could cause cameras from other manufacturers to not be able to integrate them via the JPEG mode.' },
        { changeColumn: 'It has been possible to delete the last remaining administrator user. This is now prevented by a safty check dialog.' },
        { changeColumn: 'Fixed a bug that caused a crash in a P2P connection if switching the IR LEDs.' },
        { changeColumn: 'The camera focus was not synchronized with the camera list.' },
        { changeColumn: 'After deleting a channel, the view was not changed to the last remaining channel.' },
        { changeColumn: 'The camera search did not always provide all the results.' },
        { changeColumn: 'An error has been fixed that certain special characters were not displayed correctly in the result list of a camera search.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

DownloadTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(DownloadTable)


// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'

// const cameraRow = [
//   'The audio of a continuous recording was not stored properly for some time, so that after a few recordings the audio sounds only very distorted. This bug has been fixed now.',
//   'Already fixed but no longer working after the changes to the 2.3 beta. The InstarPlayer does not start by default with the selected recording path if it was started from the InstarVision.',
//   'The robber icon, which appears above the video display of a channel and allows the InstarVision`s motion detection to be turned on and off, did not work. This bug has now been fixed.',
//   'Fixed a bug where if a wrong password was entered when logging on a user, it could happen that the resulting error message was displayed twice in succession.',
//   'Under the alarm recording settings, the text "Starte Programm bei Alarm" has not been fully displayed.',
//   'The IR LED status of a channel, which can be displayed above the video display and switched with a click on the camera, does not work. This bug has now been fixed.',
//   'It has been possible for a logged in user who did not have the right to watch a video channel to watch it through stopping and starting. This is no longer possible.',
//   'The user interface was not updated after changing the logged in user or only after a restart of the software.',
//   'Previously, it was possible for a user who was not an administrator but had the system right to disable the software login. After deactivation and restart it could then change all user rights. This is no longer possible because the software login setting now is greyed out in this case.',
//   'The identifiers of the buttons under "User" did not have a German translations.',
//   'The default user did not appear under "About InstarVision."',
//   'Fixed a bug that could cause cameras from other manufacturers to not be able to integrate them via the JPEG mode.',
//   'It has been possible to delete the last remaining administrator user. This is now prevented by a safty check dialog.',
//   'Fixed a bug that caused a crash in a P2P connection if switching the IR LEDs.',
//   'The camera focus was not synchronized with the camera list.',
//   'After deleting a channel, the view was not changed to the last remaining channel.',
//   'The camera search did not always provide all the results.',
//   'An error has been fixed that certain special characters were not displayed correctly in the result list of a camera search.',
// ]

// // Render your table
// const ConnectTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={cameraRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Fixed bugs</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {cameraRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={850}
//         />
//       </Table>
//     )
// }

// export default ConnectTable