import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import qsQuickInstall01 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_01.webp"
import qsQuickInstall02 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_02.webp"
import qsQuickInstall03 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_03.webp"
import qsQuickInstall04 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_04.webp"
import qsQuickInstall05 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_05.webp"
import qsQuickInstall06 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_06.webp"
import qsQuickInstall07 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_07.webp"
import qsQuickInstall08 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_08.webp"
import qsQuickInstall09 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_09.webp"
import qsQuickInstall10 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_10.webp"
import qsQuickInstall11 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_11.webp"
import qsQuickInstall12 from "../../../../images/Indoor_Cameras/IN-8015_HD/Quick_Installation/Quickinstallation_Tile_8015_Step_12.webp"




export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall01}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 1'
              description={
                <p>Attach the WiFi antenna to the cameras antenna connector as shown here (<Link to="/Indoor_Cameras/IN-8015_HD/Product_Features/">#11</Link>). (only for the non-PoE Version!)</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall02}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 2'
              description={
                <p>Plug in the power supply (<Link to="/Indoor_Cameras/IN-8015_HD/Product_Features/">#7</Link>). to start the camera. In case you are installing the PoE model, plug in the LAN cable (<Link to="/Indoor_Cameras/IN-8015_HD/Product_Features/">#12</Link>), connect it to your router and skip the WiFi section below.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall03}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 3'
              description={
                <p>Connect the network cable to the back of the camera (s. <Link to="/Indoor_Cameras/IN-8015_HD/Product_Features/">#12</Link>) to setup a LAN connection.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall04}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 4'
              description={
                <p>Connect the cameras network cable to a free LAN port on your internet router.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall05}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 5'
              description={
                <p>Start our <OutboundLink href="https://install.instar.com/WQHDv2/IN-9408_WQHD_Installation/01_LAN_Integration/03_Find_your_Camera" target="_blank" rel="noopener noreferrer">IP Camera Tool</OutboundLink> from the Software CD and double click on the name of the camera.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall06}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 6'
              description={
                <p>Default Logins User/Password: <strong>admin / instar</strong> for the administrator, <strong>user / instar</strong> for normal users and <strong>guest / instar</strong> for restricted users. Our <OutboundLink href="https://install.instar.com" target="_blank" rel="noopener noreferrer">Installation Wizard</OutboundLink> will lead you through the rest of the installation process.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall07}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 7'
              description={
                <p>You need to install the <Link to="/Quick_Installation/Live_Video/">Adobe Flash Plugin</Link> to display your cameras h.264 video stream. You can use to <Link to="/Web_User_Interface/1080p_Series/Multimedia/Video/">MJPEG Stream</Link> in case you are not allowed to install Browser plugins on your machine.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall08}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 8'
              description={
                <p>It is recommended to set your WiFi router to a free WiFi band (802.11bgn) and activate the WPA2/AES (or WPA2/CCMP) encryption, before connecting your camera to your local wireless LAN.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall09}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 9'
              description={
                <p>Access your camera´s <Link to="/Web_User_Interface/1080p_Series/Network/WiFi/">WiFi Settings</Link> and enter your WiFi information. Save your credentials, unplug the network cable and restart your camera, to connect it to your WiFi network.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall10}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 10'
              description={
                <p>You need to <Link to="/Internet_Access/Port_Forwarding/">Forward a Port</Link> inside your router, if you want to access your camera over its <Link to="/Internet_Access/The_DDNS_Service/">DDNS Address</Link> from the internet. Alternatively, you can use the P2P USER ID to access your camera with our smartphone app (see Step 12 below).</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall11}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 11'
              description={
                <p>Now you can enter your camera´s DDNS information to our free <Link to="/Software/Windows/InstarVision/Windows_Phone/DDNS/">Windows Phone</Link>, <Link to="/Software/Windows/InstarVision/Metro/DDNS/">Windows Metro</Link>, <Link to="/Software/Android/InstarVision/DDNS/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/DDNS/">iPhone</Link>, <Link to="/Software/iOS/InstarVision/iPad/DDNS/">iPad</Link> or <Link to="/Software/Other_Platforms/InstarVision_Blackberry/">Blackberry</Link> App.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsQuickInstall12}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 12'
              description={
                <p>If you cannot open a port for camera´s DDNS service, you can still use the <Link to="/Web_User_Interface/1080p_Series/Network/Internet_Access/">Point-2-Point Service</Link> instead with our <Link to="/Software/Windows/InstarVision/Windows_Phone/P2P/">Windows Phone</Link>, <Link to="/Software/Windows/InstarVision/Metro/P2P/">Windows Metro</Link>, <Link to="/Software/Android/InstarVision/P2P/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/P2P/">iPhone</Link>, <Link to="/Software/iOS/InstarVision/iPad/P2P/">iPad</Link> or <Link to="/Software/Other_Platforms/InstarVision_Blackberry/">Blackberry</Link> App.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
