import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import productsWebCam1SearchIcon from '../../../images/Search/P_SearchThumb_IN-W1.webp'
import productsWebCam2SearchIcon from '../../../images/Search/P_SearchThumb_IN-W2.webp'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Products/USB-Webcams/IN-W1/">
                  <img
                    src={productsWebCam1SearchIcon}
                    alt="IN-W1 USB Webcam"
                  />
                </Link>
              }
              title="IN-W1 USB Webcam"
              description="Die IN-W1 ist eine USB-Webcam mit einer 1080p Full HD-Auflösung. Sie kann an Ihren PC oder Laptop angeschlossen werden und mit Chat- und Team-Programmen wie Skype, Zoom, Facetime und anderen verwendet werden."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Products/USB-Webcams/IN-W1/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Products/USB-Webcams/IN-W2/">
                  <img
                    src={productsWebCam2SearchIcon}
                    alt="IN-W2 USB Webcam"
                  />
                </Link>
              }
              title="IN-W2 USB Webcam"
              description="Die IN-W2 ist eine 1080p-Full-HD-Webcam mit Objektivabdeckung, die in einen USB 2.0-Anschluss an Ihrem PC oder Laptop eingesteckt und mit Chat- und Team-Programmen wie Skype, Zoom, Facetime und anderen verwendet werden kann."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Products/USB-Webcams/IN-W2/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
