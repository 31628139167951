import React from 'react'

import {
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  EuiImage,
  EuiSpacer
} from '@elastic/eui'

import relaySpecsImage from '../../../../images/Indoor_Cameras/IN-8015_HD/Technical_Specifications/HD_Camera_Alarm_Relay.webp'
import powerSpecsImage from '../../../../images/Outdoor_Cameras/IN-7011_HD/Technical_Specifications/5907-7011-Netzteil.webp'
import domeSpecsImage from '../../../../images/Outdoor_Cameras/IN-7011_HD/Technical_Specifications/Kuppel_IN7011HD.webp'

export default function FooterCards() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiPanel paddingSize="m">
              <EuiTitle size="m">
                <h3>[3] Alarm Relay Specifications</h3>
              </EuiTitle>
              <EuiImage
                allowFullScreen
                alt='HD Cameras Alarm Relay Specifications'
                src={relaySpecsImage}
              />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel paddingSize="m">
              <EuiTitle size="m">
                <h3>[6] Power Supply</h3>
              </EuiTitle>
              <EuiImage
                allowFullScreen
                alt='IN-7011 HD Power Supply'
                src={powerSpecsImage}
              />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiPanel paddingSize="m">
              <EuiTitle size="m">
                <h3>[7] Camera Dome</h3>
              </EuiTitle>
              <EuiImage
                allowFullScreen
                alt='IN-7011 HD Camera Dome'
                src={domeSpecsImage}
              />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
