import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class UserTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "User Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Add User', descriptionColumn: 'Click on the green + button to add a new camera user.' },
        { actionColumn: 'Name', descriptionColumn: 'Select a name for the user login.' },
        { actionColumn: 'Password', descriptionColumn: 'Select a password for the user login.' },
        { actionColumn: 'User Level', descriptionColumn: 'Select how much rights the user should have - guest, operator or administrator' },
        { actionColumn: 'Administrator', descriptionColumn: 'A administrator user has access to all functions of the camera. The default is admin/instar. The login will be set to a personal username and password during the initial setup wizard steps.' },
        { actionColumn: 'Operator', descriptionColumn: 'This is a regular user login. The user has access to the videostream and can also pan&tilt your camera (only PTZ cameras). The configuration menu will not be available. Deactivated by default.' },
        { actionColumn: 'Visitor', descriptionColumn: 'This is a guest login. The guest only has access to the videostream. Deactivated by default.' },
        { actionColumn: 'Session validity', descriptionColumn: 'When you log in to the web user interface your browser will store an access token. You will only have to log in again if you delete your browser history or the access token expires. Here you can set the expiration time.' },
        { actionColumn: 'Language', descriptionColumn: 'Select the interface language used for the user.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

UserTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(UserTable)