import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/System/Info/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import InfoTable01 from 'components/Web_User_Interface/1440p_Series/System/Info/infoTable01';
import InfoTable02 from 'components/Web_User_Interface/1440p_Series/System/Info/infoTable02';
import InfoTable03 from 'components/Web_User_Interface/1440p_Series/System/Info/infoTable03';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  SuccessBox,
  PermissionBox,
  InfoTable01,
  InfoTable02,
  InfoTable03,
  BreadCrumbs,
  SEOHelmet,
  React
};