import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Home Assistant',
      href: '/en/Software/Linux/Home_Assistant/',
    },
    {
      text: 'MotionEye',
      href: '/en/Software/Linux/MotionEye/',
    },
    {
      text: 'Node-RED',
      href: '/en/Software/Linux/Node-RED/',
    },
    {
      text: 'OpenHAB 2',
      href: '/en/Software/Linux/OpenHAB2/',
    },
    {
      text: 'Shinobi',
      href: '/en/Software/Linux/Shinobi_Open_Source_CCTV/',
    },
    {
      text: '◄ Linux',
      href: '/en/Software/Linux/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Software Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}