import React, { useState } from 'react'
import {Link} from 'gatsby'

import {
  EuiPopover,
  EuiSpacer,
  EuiButton,
  EuiButtonEmpty,
  EuiText,
  EuiPopoverTitle,
  EuiPopoverFooter,
  EuiFlexGroup,
  EuiFlexItem,
  EuiShowFor,
} from '@elastic/eui'

export const SideNavItems = () => {
  const [isProductMenuOpen, setisProductMenuOpen] = useState(false);
  const [isInstallationMenuOpen, setisInstallationMenuOpen] = useState(false);
  const [isMotionMenuOpen, setisMotionMenuOpen] = useState(false);
  const [isRemoteMenuOpen, setisRemoteMenuOpen] = useState(false);
  const [isAdvancedMenuOpen, setisAdvancedMenuOpen] = useState(false);

  return (
      <EuiShowFor sizes={['l', 'xl']}>
          <EuiFlexGroup wrap gutterSize="s" alignItems="center">
            <EuiFlexItem grow={true}>
              <EuiPopover
                ownFocus
                button={
                  <EuiButtonEmpty
                    color="ghost"
                    aria-label="INSTAR Products Menu"
                    aria-controls="guideHeaderProductsMenu"
                    aria-haspopup="true"
                    onClick={() => setisProductMenuOpen(!isProductMenuOpen)}>
                    Products
                  </EuiButtonEmpty>
                }
                isOpen={isProductMenuOpen}
                anchorPosition="downRight"
                closePopover={() => setisProductMenuOpen(false)}>
                <EuiPopoverTitle><Link to="/Products/">INSTAR Produkte</Link></EuiPopoverTitle>
                <div style={{ width: '300px' }}>
                  <EuiText size="s">
                    <p>
                      INSTAR IP Cameras, Network Security and Security Software Products and related third-party Software and Home Automation Solutions for Camera Surveillance.
                    </p>
                  </EuiText>
                </div>
                <EuiPopoverFooter paddingSize="l">
                  <Link to="/Indoor_Cameras/">
                    <EuiButton fullWidth size="s" fill>
                      Indoor Cameras
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Outdoor_Cameras/">
                    <EuiButton fullWidth size="s" fill>
                      Outdoor Cameras
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Products/Power_over_Ethernet/">
                    <EuiButton fullWidth size="s" fill>
                      Power over Ethernet
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Products/Lenses/">
                    <EuiButton fullWidth size="s" fill>
                      Lenses
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Software/">
                    <EuiButton fullWidth size="s" fill>
                      Software
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Web_User_Interface/">
                    <EuiButton fullWidth size="s" fill>
                    Web User Interface
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Motion_Detection/INSTAR_Cloud/">
                    <EuiButton fullWidth size="s" fill>
                      Cloud Service
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Products/Others/">
                    <EuiButton fullWidth size="s" fill>
                      Other Products
                    </EuiButton>
                  </Link>
              </EuiPopoverFooter>
            </EuiPopover>
          </EuiFlexItem>
            <EuiFlexItem grow={true}>
              <EuiPopover
                ownFocus
                button={
                  <EuiButtonEmpty
                    color="ghost"
                    aria-label="INSTAR Installation Menu"
                    aria-controls="guideHeaderInstallationMenu"
                    aria-haspopup="true"
                    onClick={() => setisInstallationMenuOpen(!isInstallationMenuOpen)}>
                    Installation
                  </EuiButtonEmpty>
                }
                isOpen={isInstallationMenuOpen}
                anchorPosition="downRight"
                closePopover={() => setisInstallationMenuOpen(false)}>
                <EuiPopoverTitle><Link to="/Quick_Installation/">Quick Installation</Link></EuiPopoverTitle>
                <div style={{ width: '300px' }}>
                  <EuiText size="s">
                    <p>
                    First steps to install your INSTAR IP Camera.
                    </p>
                  </EuiText>
                </div>
                <EuiPopoverFooter paddingSize="l">
                  <Link to="/Quick_Installation/How_does_an_IP_Camera_Work/">
                    <EuiButton fullWidth size="s" fill>
                      Getting Started
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Quick_Installation/After_Unpacking/">
                    <EuiButton fullWidth size="s" fill>
                      After Unpacking
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Quick_Installation/First_Steps/">
                    <EuiButton fullWidth size="s" fill>
                      First Steps
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/">
                    <EuiButton fullWidth size="s" fill>
                      Wireless Connection
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Quick_Installation/Power_over_Ethernet/">
                    <EuiButton fullWidth size="s" fill>
                    Power over Ethernet
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Quick_Installation/Powerline/">
                    <EuiButton fullWidth size="s" fill>
                      Powerline
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Quick_Installation/Direct_LAN_Connection/">
                    <EuiButton fullWidth size="s" fill>
                      Direct LAN Connection
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Quick_Installation/Alternative_IP_Scanner/">
                    <EuiButton fullWidth size="s" fill>
                      Finding your Camera
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Quick_Installation/Language_Selection/">
                    <EuiButton fullWidth size="s" fill>
                      Language Selection
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Quick_Installation/Live_Video/">
                    <EuiButton fullWidth size="s" fill>
                      Live Video
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Quick_Installation/Creating_User_Accounts/">
                    <EuiButton fullWidth size="s" fill>
                      User Management
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Quick_Installation/WebUI_And_Firmware_Upgrade/">
                    <EuiButton fullWidth size="s" fill>
                      Software Update
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/">
                    <EuiButton fullWidth size="s" fill>
                      Browser History
                    </EuiButton>
                  </Link>
                  <EuiSpacer size="m" />
                  <Link to="/Quick_Installation/ONVIF/">
                    <EuiButton fullWidth size="s" fill>
                      ONVIF
                    </EuiButton>
                  </Link>
                </EuiPopoverFooter>
              </EuiPopover>
            </EuiFlexItem>
            <EuiFlexItem grow={true}>
              <EuiPopover
                ownFocus
                button={
                  <EuiButtonEmpty
                    color="ghost"
                    aria-label="INSTAR Motion Detection Menu"
                    aria-controls="guideHeaderMotionMenu"
                    aria-haspopup="true"
                    onClick={() => setisMotionMenuOpen(!isMotionMenuOpen)}>
                    Motion Detection
                  </EuiButtonEmpty>
                }
                isOpen={isMotionMenuOpen}
                anchorPosition="downRight"
                closePopover={() => setisMotionMenuOpen(false)}>
                <EuiPopoverTitle><Link to="/Motion_Detection/">Motion Detection</Link></EuiPopoverTitle>
                <div style={{ width: '300px' }}>
                  <EuiText size="s">
                    <p>
                      Use your cameras motion detection feature to detect uninvited visitors and send alarm notifications.
                    </p>
                  </EuiText>
                </div>
                <EuiPopoverFooter paddingSize="l">
                    <Link to="/Motion_Detection/Setup/">
                      <EuiButton fullWidth size="s" fill>
                        Detection Setup
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Motion_Detection/Passive_Infrared_Detection/">
                      <EuiButton fullWidth size="s" fill>
                        PIR Motion Sensor
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Motion_Detection/Alarm_Notification/">
                      <EuiButton fullWidth size="s" fill>
                        Alarm Notification
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Motion_Detection/SD_Card_Access/">
                      <EuiButton fullWidth size="s" fill>
                        SD Card Access
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Motion_Detection/Alarm_FTP_Upload/">
                      <EuiButton fullWidth size="s" fill>
                        Alarm FTP Upload
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Motion_Detection/Router_as_a_FTP_Server/">
                      <EuiButton fullWidth size="s" fill>
                        Router as a FTP Server
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Motion_Detection/FTP_Server_Setup/">
                      <EuiButton fullWidth size="s" fill>
                        FTP Server Setup
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Motion_Detection/Alarm_Server/">
                      <EuiButton fullWidth size="s" fill>
                        Alarm Server
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Motion_Detection/INSTAR_Cloud/">
                      <EuiButton fullWidth size="s" fill>
                        INSTAR Cloud
                      </EuiButton>
                    </Link>
                </EuiPopoverFooter>
              </EuiPopover>
            </EuiFlexItem>
            <EuiFlexItem grow={true}>
              <EuiPopover
                ownFocus
                button={
                  <EuiButtonEmpty
                    color="ghost"
                    aria-label="INSTAR Remote Access Menu"
                    aria-controls="guideHeaderRemoteMenu"
                    aria-haspopup="true"
                    onClick={() => setisRemoteMenuOpen(!isRemoteMenuOpen)}>
                    Remote Access
                  </EuiButtonEmpty>
                }
                isOpen={isRemoteMenuOpen}
                anchorPosition="downRight"
                closePopover={() => setisRemoteMenuOpen(false)}>
                <EuiPopoverTitle><Link to="/Internet_Access/">Remote Access</Link></EuiPopoverTitle>
                <div style={{ width: '300px' }}>
                  <EuiText size="s">
                    <p>
                      Access your INSTAR IP Camera over the Internet using the integrated Point2Point or DDNS Service. Setup your Router to give you Full Access to your Camera when you are not at Home.
                    </p>
                  </EuiText>
                </div>
                <EuiPopoverFooter paddingSize="l">
                    <Link to="/Internet_Access/Point_to_Point/">
                      <EuiButton fullWidth size="s" fill>
                        Point to Point
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Internet_Access/The_DDNS_Service/">
                      <EuiButton fullWidth size="s" fill>
                        DDNS Service
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Internet_Access/Port_Forwarding/">
                      <EuiButton fullWidth size="s" fill>
                        Port Forwarding
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Internet_Access/Mobile_Access/">
                      <EuiButton fullWidth size="s" fill>
                        Mobile Access
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Internet_Access/DDNS_Provider/">
                      <EuiButton fullWidth size="s" fill>
                        DDNS Provider
                      </EuiButton>
                    </Link>
                </EuiPopoverFooter>
              </EuiPopover>
            </EuiFlexItem>
            <EuiFlexItem grow={true}>
              <EuiPopover
                ownFocus
                button={
                  <EuiButtonEmpty
                    color="ghost"
                    aria-label="INSTAR Advanced Menu"
                    aria-controls="guideHeaderAdvancedMenu"
                    aria-haspopup="true"
                    onClick={() => setisAdvancedMenuOpen(!isAdvancedMenuOpen)}>
                    Developer
                  </EuiButtonEmpty>
                }
                isOpen={isAdvancedMenuOpen}
                anchorPosition="downRight"
                closePopover={() => setisAdvancedMenuOpen(false)}>
                <EuiPopoverTitle><Link to="/Advanced_User/">For Devolopers</Link></EuiPopoverTitle>
                <div style={{ width: '300px' }}>
                  <EuiText size="s">
                    <p>
                      Topics for Advanced Users and Developers. API Description, Home Automation Integration and Advanced Network Topics.
                    </p>
                  </EuiText>
                </div>
                <EuiPopoverFooter paddingSize="l">
                    <Link to="/Advanced_User/CGI_Commands/">
                      <EuiButton fullWidth size="s" fill>
                        CGI Commands
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Advanced_User/INSTAR_MQTT_Broker/">
                      <EuiButton fullWidth size="s" fill>
                        INSTAR MQTT Broker
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Advanced_User/VPN_AVM_Fritzbox/">
                      <EuiButton fullWidth size="s" fill>
                        VPN AVM Fritzbox
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Advanced_User/Website_Integration/">
                      <EuiButton fullWidth size="s" fill>
                        Website Integration
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Advanced_User/Youtube_Videostreaming_from_your_Camera/">
                      <EuiButton fullWidth size="s" fill>
                        Youtube Videostreaming
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Advanced_User/All_Home_Automation_Tutorials/">
                      <EuiButton fullWidth size="s" fill>
                        All Home Automation Tutorials
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Advanced_User/Tasker/">
                      <EuiButton fullWidth size="s" fill>
                        Tasker
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Advanced_User/Restore_WebUI/">
                      <EuiButton fullWidth size="s" fill>
                        Restore WebUI
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Advanced_User/Restore_Firmware/">
                      <EuiButton fullWidth size="s" fill>
                        Restore Firmware
                      </EuiButton>
                    </Link>
                    <EuiSpacer size="m" />
                    <Link to="/Advanced_User/CCTV_vs_IP/">
                      <EuiButton fullWidth size="s" fill>
                        CCTV vs IP
                      </EuiButton>
                    </Link>
                </EuiPopoverFooter>
              </EuiPopover>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiShowFor>
  )
}