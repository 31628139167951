import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Other_Platforms/InstarVision_Blackberry/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_InstarVision_Android.webp'
                    alt="InstarVision für Blackberry"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="InstarVision for Blackberry"
              description="Our InstarVision app for Blackberry. Control your camera from your Blackberry smartphone when you don´t have access to your PC."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Other_Platforms/InstarVision_Blackberry/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Other_Platforms/Synology/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Synology.webp'
                    alt="Synology Surveillance Station"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Synology Surveillance Station"
              description="All INSTAR IP cameras are supported by the Synology Surveillance Station. Manage all your cameras and video recordings."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Other_Platforms/Synology/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Other_Platforms/AVM_Fritzphone/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_AVM.webp'
                    alt="AVM Fritzphone"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="AVM Fritzphone"
              description="Use your AVM Fritzphone to check your camera´s live video - use your AVM Fritzbox user interface to set it up."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Other_Platforms/AVM_Fritzphone/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Other_Platforms/Athom_Homey/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Homey.webp'
                    alt="Athom Homey"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Athom Homey"
              description="Using the Athom Homey base station to let your INSTAR camera interact with wireless Zigbee, Z-Wave or IR sensors, signalling devices and smart buttons. Automate your cameras operation in a smarthome environment."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Other_Platforms/Athom_Homey/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Other_Platforms/Homematic_IP/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Homematic.webp'
                    alt="Homematic CCU3"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Homematic CCU3"
              description="In order to be able to control and configure your Homematic or Homematic IP devices such as your INSTAR IP camera and to use them in central programs, you must integrate the devices in the system via the WebUI."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Other_Platforms/Homematic_IP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Other_Platforms/Loxone_Miniserver/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Loxone.webp'
                    alt="Loxone Miniserver"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Loxone Miniserver"
              description="The Homeautomation Miniserver from Loxone connects all smart components in your home or apartment. All strands run together to the Miniserver to enable communication between the individual components: light, shading, heating, buttons as well as your INSTAR IP Surveillance Camera."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Other_Platforms/Loxone_Miniserver/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Other_Platforms/Lupus_XT2_Plus/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Loxone.webp'
                    alt="Lupus XT2 Plus"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Lupus XT2 Plus"
              description="Integrate video surveillance systems such as IP cameras from LUPUS and lots of other manufacturers simply into the LUPUSEC user interface. Easy! And safe – guaranteed."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Other_Platforms/Lupus_XT2_Plus/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Other_Platforms/Homee/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_homee.webp'
                    alt="homee"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="homee"
              description="Using the homee base station to let your INSTAR camera interact with wireless Zigbee, Z-Wave or EnOcean sensors, signalling devices and smart buttons. Automate your cameras operation in a smarthome environment."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Other_Platforms/Homee/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Other_Platforms/Homee_and_Node-RED/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_homee.webp'
                    alt="homee und Node-RED"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="homee and Node-RED"
              description="This time we want to use Node-RED to create a virtual homee that directly connects to the MQTT interface. This virtual device is then controlled by our real homee."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Other_Platforms/Homee_and_Node-RED/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Other_Platforms/QNAP/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_QNAP.webp'
                    alt="QNAP Surveillance Station"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="QNAP Surveillance Station"
              description="All INSTAR IP cameras are supported by the QNAP Surveillance Station. Manage all your cameras and video recordings."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Other_Platforms/QNAP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Home_Automation/Logitech_Harmony/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Harmony.webp'
                    alt="Logitech Harmony"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Logitech Harmony"
              description="Logitech Harmony is a universal remote control that can be programmed to communicate not only with your television, but also with your smarthome components."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Home_Automation/Logitech_Harmony/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Other_Platforms/Vivre_Motion_Stream_Deck/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Stream_Deck.webp'
                    alt="Stream Deck Vivre Motion"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Stream Deck Vivre Motion"
              description="With the VivreMotion Stream Deck IP-CAMERA Plugin you can place the live image from your INSTAR IP Camera directly on a button."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Other_Platforms/Vivre_Motion_Stream_Deck/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Other_Platforms/biOs_MQTT_Stream_Deck/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Stream_Deck.webp'
                    alt="Stream Deck biOs MQTT"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Stream Deck biOs MQTT"
              description="With the biOs Stream Deck MQTT Plugin you can take control over your INSTAR IP Camera directly with a press of a button."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Other_Platforms/biOs_MQTT_Stream_Deck/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Other_Platforms/Amazon_Alexa_Skill_via_Monocle/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Alexa.webp'
                    alt="Alexa Skill (Monocle)"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Amazon Alexa Skill (Monocle)"
              description="Embed your camera in Amazon's Alexa and use voice commands to show the current video on your Alexa Echo Spot or Show."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Other_Platforms/Amazon_Alexa_Skill_via_Monocle/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Other_Platforms/IP_Symcon/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Symcon.webp'
                    alt="IP Symcon"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP Symcon Smarthome"
              description="The IP-Symcon software is the complete solution in the field of home automation. Combine products of all renowned manufacturers compatible with all INSTAR HD and Full HD IP Cameras."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Other_Platforms/IP_Symcon/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Other_Platforms/Domovea/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Hager.webp'
                    alt="Domovea"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Domovea"
              description="Add your INSTAR IP camera to your Domovea home automation system. Just add our EXCAM files to add support for your camera."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Other_Platforms/Domovea/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Other_Platforms/Hager_Domovea/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Hager.webp'
                    alt="Domovea"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Hager Domovea v2"
              description="With domovea by Hager, this is very easy and only with a single component! domovea is the simple, versatile and efficient smart home solution for residential and commercial buildings."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Other_Platforms/Hager_Domovea/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Alexa_Echo5_and_Monocle_Gateway/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Alexa.webp'
                    alt="Alexa and Monocle Gateway"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alexa and Monocle Gateway for the Echo 5"
              description="To connect to the cameras (HD and FullHD series) e.g. With an Echo Show 5 it is necessary to use Monocle's Monocle Gateway. The gateway creates an interface so that Alexa devices can access the cameras."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Alexa_Echo5_and_Monocle_Gateway/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Other_Platforms/ASUSTOR_Surveillance_Center/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Hager.webp'
                    alt="ASUSTOR"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="ASUSTOR Surveillance Center"
              description="Add your INSTAR IP camera to your ASUSTOR Surveillance Center. Manage all your cameras and video recordings."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Other_Platforms/ASUSTOR_Surveillance_Center/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
