import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Attention">
        <EuiText>
            <p>The username and password may not contain more then 30 characters. The following special characters are currently supported: <code>&#33; &#35; &#40; &#41; &#42; &#45; &#46; &#47; &#63; &#64; &#91; &#93; &#94; &#95; &#123; &#124; &#125; &#60;</code>. The following special characters are not supported: <code>ä ö ü ´ " ' , {' > '} ; ~ $ § % +</code></p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

