import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="WPA3 Ecryption">
        <EuiText>
            <p>If the WLAN encryption <strong>WPA2/WPA3</strong> is set in your router and the camera was added into your WiFi via WPS, the camera will first connect via WPA2. Only after rebooting the camera will it switch to WPA3 mode.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

