import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="NO paid INSTAR Cloud Account is required!">
        <EuiText>
          <p>
            To enable your camera to connect with the Amazon Cloud, you need to create an INSTAR Cloud Account. Creating a test account is free of charge and gives you full access to all INSTAR Cloud functions for 30 days. After this trial period you will no longer be able to upload alarm videos - the account, and thus also the possibility to use the <strong>Smart-Home Skill</strong>, however, will remain active (and free of charge).
          </p>
          <p>
            The <strong>Cloud Skill</strong> is used to manage your alarm recordings in the INSTAR Cloud and can be used to the full extent during this trial phase.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

