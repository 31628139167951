import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ActionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Object Detection" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Object', descriptionColumn: 'Select the object - Person, Vehicle or Animal - you want to adjust the detection threshold (certainty of detection) and bounding box for.' },
        { actionColumn: 'Show bounding box', descriptionColumn: 'Show the bounding boxes - minimum & maximum size - for the selected object. Use your mouse to pull or push the outer and inner box into the desired size. In the example above an object - detected as a person - has to be bigger than the inner box and smaller than the outer box to trigger an alert.' },
        { actionColumn: 'Threshold', descriptionColumn: 'The object detection algorithm returns a percentage value about how certain it is that the detected object actually is a person, vehicle or animal. Set the threshold to define how certain the algorithm has to be to trigger an alert.' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ActionTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ActionTable)