import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Installation Guidelines">
        <EuiText>
         <h4>What do I have to pay attention to during installation?</h4>
         <ul>
            <li>The ideal mounting height is between 1.80 and 2.50 metres (upper graphic on the right).</li>
            <li>Make sure that you install motion detectors at right angles to the direction of travel if possible (upper graphic on the right). Movements that go directly towards a motion detector are detected rather late (lower graphic on the right).</li>
            <li>Motion detectors do not detect movement behind glass.</li>
            <li>Motion detectors must not be exposed to direct sunlight.</li>
            <li>If possible, do not point motion detectors at heat sources and at glass panes.</li>
            <li>There should be no objects in the detector's field of vision that change temperature quickly (electric stove, refrigerators etc.) Or moving objects like curtains blowing over the radiator or fax machines.</li>
            <li>It is not recommended to install the detector in places where air flows (ventilation, air ducts, leaky windows, etc.). Large, sudden circulations of warm air, e.g. by air heaters or escaping heat accumulation, can also activate motion detectors under unfavourable circumstances. </li>
            <li>In the case of windows, there is a risk that sudden incidence of sunlight will cause an alarm. In general, the sensor will always work more reliable at night and reaches the best range in the cold of winter!</li>
            <li>It should also be ensured that there are no moving objects in the effective range of the IR detector (aquarium, flowers, etc).</li>
            <li>Plants tend to "soak" up the heat from the sun during the day. Leaves tend to be warmer on top than from below. This differential can turn a tree or a hedge into a <em>Disco Ball</em> in combination with wind. Especially at the end of the day when the surround air starts to cool down rapidly.</li>
            <li>Hedges, high grasses as well as painted walls from the building you attached your sensor to release heat slowly. Uprising warm air in front of a much colder background can trigger false alerts.</li>
         </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox