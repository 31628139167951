import React from 'react'

import 'react-vis/dist/style.css'

import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
  Crosshair
} from 'react-vis'

const DATA = [
  [
    { x: 'IN-3011', y: 2.20 },
    { x: 'IN-6012 HD', y: 1.94 },
    { x: 'IN-8003 FHD', y: 2.00 },
    { x: 'IN-8015 FHD', y: 2.04 }
  ],
  [
    { x: 'IN-3011', y: 6.80 },
    { x: 'IN-6012 HD', y: 6.42 },
    { x: 'IN-8003 FHD', y: 6.60 },
    { x: 'IN-8015 FHD', y: 6.80 }
  ],
  [
    { x: 'IN-3011', y: 7.20 },
    { x: 'IN-6012 HD', y: 7.49 },
    { x: 'IN-8003 FHD', y: 7.50 },
    { x: 'IN-8015 FHD', y: 7.30 }
  ],
  [
    { x: 'IN-3011', y: 3.30 },
    { x: 'IN-6012 HD', y: 2.94 },
    { x: 'IN-8003 FHD', y: 3.90 },
    { x: 'IN-8015 FHD', y: 3.52 }
  ],
  [
    { x: 'IN-3011', y: 3.80 },
    { x: 'IN-6012 HD', y: 4.29 },
    { x: 'IN-8003 FHD', y: 4.60 },
    { x: 'IN-8015 FHD', y: 4.63 }
  ],
  [
    { x: 'IN-3011', y: 4.50 },
    { x: 'IN-6012 HD', y: 5.09 },
    { x: 'IN-8003 FHD', y: 0 },
    { x: 'IN-8015 FHD', y: 5.32 }
  ],
  [
    { x: 'IN-3011', y: 5.94 },
    { x: 'IN-6012 HD', y: 5.67 },
    { x: 'IN-8003 FHD', y: 0 },
    { x: 'IN-8015 FHD', y: 5.47 }
  ],
  [
    { x: 'IN-3011', y: 5.10 },
    { x: 'IN-6012 HD', y: 4.74 },
    { x: 'IN-8003 FHD', y: 0 },
    { x: 'IN-8015 FHD', y: 4.32 }
  ],
  [
    { x: 'IN-3011', y: 4.50 },
    { x: 'IN-6012 HD', y: 2.97 },
    { x: 'IN-8003 FHD', y: 4.00 },
    { x: 'IN-8015 FHD', y: 3.65 }
  ],
  [
    { x: 'IN-3011', y: 4.00 },
    { x: 'IN-6012 HD', y: 4.54 },
    { x: 'IN-8003 FHD', y: 4.70 },
    { x: 'IN-8015 FHD', y: 4.70 }
  ]
];

export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: []
    };
  }
  render() {
    return (
        <React.Fragment>

          <div className="chart">
            <FlexibleWidthXYPlot
              onMouseLeave={() => this.setState({ crosshairValues: [] })}
              height={300}
              xType="ordinal"
              animation={true}
              stackBy="y">
              
              <VerticalGridLines />
              <HorizontalGridLines />
              <XAxis />
              <YAxis tickFormat={v => `${v} W`} />

              <Crosshair values={this.state.crosshairValues} />

              <VerticalBarSeries
                onNearestX={(value, { index }) =>
                  this.setState({ crosshairValues: DATA.map(d => d[index]) })}
                cluster="Booting min [W]"
                color="#673AB7"
                data={DATA[0]}
              />

              <VerticalBarSeries
                cluster="Booting max [W]"
                color="#0077cc"
                data={DATA[1]}
              />

              <VerticalBarSeries
                cluster="Booting with IR active [W]"
                color="#03A9F4"
                data={DATA[2]}
              />

              <VerticalBarSeries
                cluster="Idle [W]"
                color="#009688"
                data={DATA[3]}
              />

              <VerticalBarSeries
                cluster="Idle with IR active [W]"
                color="#388E3C"
                data={DATA[4]}
              />

              <VerticalBarSeries
                cluster="Idle with IR & WLAN active [W]"
                color="#8BC34A"
                data={DATA[5]}
              />

              <VerticalBarSeries
                cluster="Pan or Tilt with IR active [W]"
                color="#FFEB3B"
                data={DATA[6]}
              />

              <VerticalBarSeries
                cluster="Pan or Tilt [W]"
                color="#FFC107"
                data={DATA[7]}
              />

              <VerticalBarSeries
                cluster="Video Recording active [W]"
                color="#FF9800"
                data={DATA[8]}
              />

              <VerticalBarSeries
                cluster="Video Recording with IR active [W]"
                color="#F44336"
                data={DATA[9]}
              />

            </FlexibleWidthXYPlot>
          </div>

          <div className="legend">
            <DiscreteColorLegend
              orientation="horizontal"
              items={[
                {
                  title: 'Dataset[0] Booting min [W]',
                  color: '#673AB7'
                },
                {
                  title: 'Dataset[1] Booting max [W]',
                  color: '#0077cc'
                },
                {
                  title: 'Dataset[2] Booting with IR active [W]',
                  color: '#03A9F4'
                },
                {
                  title: 'Dataset[3] Idle [W]',
                  color: '#009688'
                },
                {
                  title: 'Dataset[4] Idle with IR active [W]',
                  color: '#388E3C'
                },
                {
                  title: 'Dataset[5] Idle with IR & WLAN active [W]',
                  color: '#8BC34A'
                },
                {
                  title: 'Dataset[6] Pan or Tilt with IR active [W]',
                  color: '#FFEB3B'
                },
                {
                  title: 'Dataset[7] Pan or Tilt [W]',
                  color: '#FFC107'
                },
                {
                  title: 'Dataset[8] Video Recording active [W]',
                  color: '#FF9800'
                },
                {
                  title: 'Dataset[9] Video Recording with IR active [W]',
                  color: '#F44336'
                }
              ]}
            />
          </div>

        </React.Fragment>
    );
  }
}