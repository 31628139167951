import React from 'react'

import {
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import IntroCards from './IntroMQTTTopicsToC'

export default function IntroHome() {

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiFlexGroup gutterSize="l" direction="column" style={{margin: '0 7px'}} >
        <EuiFlexItem>
            <IntroCards />
        </EuiFlexItem>
    </EuiFlexGroup>
  </React.Fragment>
  );
}
