import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="c136CT0SypU"
                    title="IN-5907 HD Objektivjustage"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="IN-5907 HD Objektivjustage"
              footer={
                <EuiText>
                  <p>Wir zeigen Ihnen in diesem Video wie Sie bei einer INSTAR IN-5907HD Überwachungskamera das Objektiv scharfstellen können. Jenachdem welchen Bereich man überwachen möchte, ist es ggf. nötig das Objektiv auf den gewünschten Bereich einzustellen.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}




// import React from 'react'

// import { Player } from 'video-react'

// import {
//     EuiText,
//     EuiTitle,
//     EuiIcon,
//     EuiPanel
//   } from '@elastic/eui'

// import { OutboundLink } from 'gatsby-plugin-gtag'

// require('../../../Layout/chrome/assets/video.css')

// function VideoCard() {
//   return (
//     <EuiPanel paddingSize="m">
//         <EuiTitle size="s"><h4>Lense Adjustment</h4></EuiTitle>
//         <Player>
//         <source src="https://wiki.instar.com/videos/Objektivjustage_IN_5907HD.mp4" />
//         </Player>
//         <EuiText>
//             <p>Objektivjustage IN 5907HD. Auf <OutboundLink href="https://www.youtube.com/watch?v=c136CT0SypU" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
//         </EuiText>
//     </EuiPanel>
//   );
// }

// export default VideoCard