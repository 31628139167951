import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Additional Remark">
        <EuiText>
          <p>Your camera supports other encryption standards - we recommend WPA3/WPA2 mixed mode for your security and a reliable connection. If you use an older router, choose the WPA2 standard with AES/CCMP. Avoid WPA (PSK) mode and WPA2/WPA with TKIP! If your WiFi remains unstable, please choose a fixed WiFi channel for your router.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

