import { Link } from 'gatsby';
import WebUI720Articles from 'components/Web_User_Interface/720p_Series/Multimedia/IntroWebUIMultimedia720';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  WebUI720Articles,
  BreadCrumbs,
  SEOHelmet,
  React
};