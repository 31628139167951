import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="f8_yPDpo5hE"
                    title="GDATA Antivirus Software"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="GDATA Antivirus Software"
              footer={
                <EuiText>
                <p>Sollten Sie z.B. über die Weboberfläche, die Software InstarVision 2.0 oder die Windows App kein Bild erhalten bzw. kein Video, dann kann dies damit zu tuen haben das dieses durch GData blockiert wird.</p>
                <p>Mit großer Wahrscheinlichkeit müssten Sie in diesem Fall eine Ausnahmeregel anlegen für den Webschutz.</p>
                <p>Wir zeigen Ihnen hier wie dies geht.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}



// import React from 'react'

// import { Player } from 'video-react'

// import {
//     EuiText,
//     EuiTitle,
//     EuiIcon,
//     EuiPanel
//   } from '@elastic/eui'

// import { OutboundLink } from 'gatsby-plugin-gtag'

// require('../../../../Layout/chrome/assets/video.css')

// function VideoCard() {
//   return (
//     <EuiPanel paddingSize="m">
//         <EuiTitle size="s"><h4>GDATA Antivirus Software</h4></EuiTitle>
//         <Player>
//         <source src="https://wiki.instar.com/videos/GData_Ausnahmeregel_beim_Webschutz_hinzufügen_sofern_Sie_kein_Video_erhalten.mp4" />
//         </Player>
//         <EuiText>
//             <p>Sollten Sie z.B. über die Weboberfläche, die Software InstarVision 2.0 oder die Windows App kein Bild erhalten bzw. kein Video, dann kann dies damit zu tuen haben das dieses durch GData blockiert wird.</p>
//             <p>Mit großer Wahrscheinlichkeit müssten Sie in diesem Fall eine Ausnahmeregel anlegen für den Webschutz.</p>
//             <p>Wir zeigen Ihnen hier wie dies geht. Auf <OutboundLink href="https://www.youtube.com/watch?v=f8_yPDpo5hE" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
//         </EuiText>
//     </EuiPanel>
//   );
// }

// export default VideoCard