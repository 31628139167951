import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import DownloadTable12 from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable12';
import DownloadTable13 from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable13';
import DownloadTable20 from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable20';
import DownloadTable21 from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable21';
import DownloadTable22a from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable22a';
import DownloadTable22b from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable22b';
import DownloadTable23a from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable23a';
import DownloadTable23b from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable23b';
import DownloadTable24a from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable24a';
import DownloadTable24b from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable24b';
import DownloadTable25 from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable25';
import DownloadTable26 from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable26';
import DownloadTable27 from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable27';
import DownloadTable30 from 'components/Downloads/Desktop_Software/InstarVision/DownloadTable30';
import NavButtons from 'components/Downloads/Desktop_Software/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiButton,
  EuiSpacer,
  DownloadTable12,
  DownloadTable13,
  DownloadTable20,
  DownloadTable21,
  DownloadTable22a,
  DownloadTable22b,
  DownloadTable23a,
  DownloadTable23b,
  DownloadTable24a,
  DownloadTable24b,
  DownloadTable25,
  DownloadTable26,
  DownloadTable27,
  DownloadTable30,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};