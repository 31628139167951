import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class InfoTable02 extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Camera Overview" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Camera ID', descriptionColumn: 'Model number of your camera.' },
        { actionColumn: 'WAN IP Address', descriptionColumn: 'Wide Area Network Address of your internet connection. This is the address that your DDNS Address will be translated to, to make your camera available from the internet (needs a Port Forwarding!).' },
        { actionColumn: 'Internet', descriptionColumn: 'Is your camera able to access the internet? The Internet Access is not mandatory to operate your camera. But it is needed for a few functions, like Email & Push Notifications, FTP Uploads, automatic Date & Time Synchronisation, Remote Access over DDNS & P2P, automatic Update Notification, etc.' },
        { actionColumn: 'DDNS Address', descriptionColumn: 'This function checks if your DDNS Address was successfully updated with your actual WAN IP Address. This is a requirement to Access your Camera from the Internet.' },
        { actionColumn: 'DDNS State', descriptionColumn: 'This function checks if your DDNS Address] was successfully updated with your actual WAN IP Address. This is a requirement to Access your Camera from the Internet.' },
        { actionColumn: 'P2P UID', descriptionColumn: 'Your personal Point-to-Point user identification number. To be used with our Android, iPhone, iPad, Windows Phone or Windows Metro app to add your camera.' },
        { actionColumn: 'P2P State', descriptionColumn: 'Activity status for the Point-to-Point remote access service.' },
        { actionColumn: 'UPnP State', descriptionColumn: 'The UPnP service automatically tries to set a Port Forwarding for the DDNS access to your camera. We recommend to Deactivate this Service and create a manual forwarding instead.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

InfoTable02.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(InfoTable02)