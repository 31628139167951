import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="INSTAR Alexa Skill">
        <EuiText>
          <p>
            We provide both an INSTAR Cloud Skill and an INSTAR Camera Skill for you in the Alexa Skill Store. For <Link to="/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/">INSTAR Cloud Skill</Link> you only need an INSTAR Cloud account and an Alexa device with a display. Add the skill on your Alexa device and use it directly.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

