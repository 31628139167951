import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import lenseAdjust01 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5905_01.webp"
import lenseAdjust02 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5905_02.webp"
import lenseAdjust03 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5905_03.webp"
import lenseAdjust04 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5905_04.webp"
import lenseAdjust05 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5905_05.webp"
import lenseAdjust06 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5905_06.webp"



export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust01}
                    alt="I. Please remove the top cover of your IN-5905 HD. Make sure not to lose the plastic ring under the white screw which is for waterproof purpose!"
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>I</strong>. Please remove the top cover of your IN-5905 HD. Make sure not to lose the plastic ring under the white screw which is for waterproof purpose!
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust02}
                    alt="II. Loosen two screws of the back casing and open the casing carefully and make sure not to damage any cables."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>II.</strong> Loosen two screws of the back casing and open the casing carefully and make sure not to damage any cables.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust03}
                    alt="III. Remove the three screws (marked in red) which fixes the electronics to the front casing. Attention! Don’t touch the screws with a hexagon mark underneath."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>III.</strong> Remove the three screws (marked in red) which fixes the electronics to the front casing. Attention! Don’t touch the screws with a hexagon mark underneath.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust04}
                    alt="IV. Carefully take out the inner electronics. This part is still wired to the LED board on the front. Please be careful not to damage the cables or the connectors."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>IV.</strong> Carefully take out the inner electronics. This part is still wired to the LED board on the front. Please be careful not to damage the cables or the connectors.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust05}
                    alt="V. Loosen the fixing screw on the outer ring of the lens (marked in red color). The bottom screw (marked in blue) belongs to the lens mount and does not need to be loosen."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>V.</strong> Loosen the fixing screw on the outer ring of the lens (marked in red color). The bottom screw (marked in blue) belongs to the lens mount and does not need to be loosen.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust06}
                    alt="VI. Now please connect the camera to your network and access the WebUI. Then turn the front of the lens while keeping an eye on the camera feed until it is sharp and clear. Tighten the fixing screw. While checking for the right focal, please be careful not to accidently screw the whole lens out of the mount."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>VI.</strong> Now please connect the camera to your network and access the WebUI. Then turn the front of the lens while keeping an eye on the camera feed until the image is sharp and clear. Tighten the fixing screw. While checking for the right focal, please be careful not to accidently screw the whole lens out of the mount.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
