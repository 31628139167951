import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Wireless Connection">
        <EuiText>
          <p>
            Your WiFi connection should already be set up by WPS or by the <OutboundLink href="https://install.instar.com/" target="_blank" rel="noopener noreferrer">Installation Wizard</OutboundLink>. Use the WiFi scanner to connect your camera to a different WiFi network. Or use the scan results to check the signal strength at the camera´s installation spot.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

