import React from 'react'

import { Player } from 'video-react'

import {
    EuiText,
    EuiIcon,
    EuiPanel
  } from '@elastic/eui'

import { OutboundLink } from 'gatsby-plugin-gtag'

require('../../Layout/chrome/assets/video.css')

function VideoCard() {
  return (
    <EuiPanel paddingSize="m">
        <Player>
            <source src="https://wiki.instar.com/videos/Aufnahme_per_FTP_auf_einen_USB_Speicher_an_einem_Telekom_Speedport_Router.mp4" />
        </Player>
        <EuiText>
            <p>In diesem Video erklären wir Ihnen wie Sie mit einer INSTAR IP Kamera die Aufnahmen bei einem Alarm auf einen USB Speicher übertragen können der sich an einem Telekom Speedport Router befindet. Auf <OutboundLink href="https://www.youtube.com/watch?v=6jtS4X0L86M" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
        </EuiText>
    </EuiPanel>
  );
}

export default VideoCard