import React, {Suspense} from 'react'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

import LanguageToggle from './LanguageToggle'
import '../../assets/styles/gatsby-plugin-breadcrumb.css'

// export const BreadCrumbs = (props) => {

//   return(
//     <Fragment>
//       <LanguageToggle locationEN={props.locationEN} locationDE={props.locationDE} locationFR={props.locationFR} />
//       <Breadcrumb location={props.locationBC} crumbLabel={props.crumbLabel} />
//     </Fragment>
//   )
// }

// export const LangTog = (locationEN, locationDE, locationFR) => {
//   const [show, setShow] = React.useState(false)

//   React.useEffect(() => {
//     const timeout = setTimeout(() => {
//       setShow(true)
//     }, 100)

//     return () => clearTimeout(timeout)

//   }, [show])

//   if (!show) return null

//   return(
//     <>
//       <LanguageToggle locationEN={locationEN} locationDE={locationDE} locationFR={locationFR} />
//     </>
//   )
// }

// export const BreadCrumbs = (props) => {

//   return(
//     <>
//       {/* <Suspense> */}
//         <LangTog locationEN={props.locationEN} locationDE={props.locationDE} locationFR={props.locationFR} />
//         <Breadcrumb location={props.locationBC} crumbLabel={props.crumbLabel} />
//       {/* </Suspense> */}
//     </>
//   )
// }

export const BreadCrumbs = (props) => {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true)
    }, 1)

    return () => clearTimeout(timeout)

  }, [show])

  if (!show) return null

  return(
    <>
       <LanguageToggle locationEN={props.locationEN} locationDE={props.locationDE} locationFR={props.locationFR} />
       <Breadcrumb location={props.locationBC} crumbLabel={props.crumbLabel} />
    </>
  )
}

export default BreadCrumbs
