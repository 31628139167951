import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import SelectCards01 from 'components/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/PoE_or_WiFi/WPS/SelectCards01';
import SelectCards02 from 'components/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/PoE_or_WiFi/WPS/SelectCards02';
import NavButtons from 'components/Outdoor_Cameras/IN-9408_WQHD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  OutboundLink,
  EuiSpacer,
  EuiButton,
  SelectCards01,
  SelectCards02,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};