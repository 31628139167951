import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
} from '@elastic/eui'


import Image300 from '../../../images/Products/IN-Motion/IN-Motion300_00.webp'
import Image500 from '../../../images/Products/IN-Motion/IN-Motion500_02.webp'



export default function ImageCard() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={Image300}
                    alt="InstarVision for iPad INSTAR IP CameraINSTAR IN-Motion PIR Motion Detector"
                  />
              }
              content="External infrared motion detector for indoor use"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Products/IN-Motion/300/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        IN-Motion 300
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={Image500}
                    alt="InstarVision for iPad INSTAR IP CameraINSTAR IN-Motion PIR Motion Detector"
                  />
              }
              content="Weather proof infrared & microwave motion detector"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Products/IN-Motion/500/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        IN-Motion 500
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
