import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Overview',
      href: '/en/Outdoor_Cameras/IN-2905_V2/',
    },
    {
      text: 'Specifications',
      href: '/en/Outdoor_Cameras/IN-2905_V2/Technical_Specifications/',
    },
    {
      text: 'Usermanual',
      href: '/en/Outdoor_Cameras/IN-2905_V2/Usermanual/',
    },
    {
      text: '◄ Outdoor Cameras',
      href: '/en/Outdoor_Cameras/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        aria-label="INSTAR IN-2905 V2 Online Usermanual Chapters"
      />
      <EuiSpacer />
    </Fragment>
  );
}