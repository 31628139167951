import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import domoImage01 from "../../../../images/Software/Other_Platforms/Domovea/Domovea_01.webp"
import domoImage02 from "../../../../images/Software/Other_Platforms/Domovea/Domovea_02.webp"
import domoImage03 from "../../../../images/Software/Other_Platforms/Domovea/Domovea_03.webp"
import domoImage04 from "../../../../images/Software/Other_Platforms/Domovea/Domovea_04.webp"
import domoImage05 from "../../../../images/Software/Other_Platforms/Domovea/Domovea_05.webp"
import domoImage06 from "../../../../images/Software/Other_Platforms/Domovea/Domovea_06.webp"


export default function PoECards() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={domoImage01}
                    alt="Domovea Home Automation for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig1.</strong> Start the Domovea configuration tool and open the camera configuration menu.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={domoImage02}
                    alt="Domovea Home Automation for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig2.</strong> Now we have to import our camera settings. Please click on DOWNLOAD to download the Excamfiles for the models IN-5905 HD, IN-5907 HD, IN-6001 HD, IN-6012 HD, IN-6014 HD.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={domoImage03}
                    alt="Domovea Home Automation for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig3.</strong> Start the import dialogue and choose the Excamfile for your camera model. Please be aware that Domovea does not support the pan & tilt function of our PT camera models.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={domoImage04}
                    alt="Domovea Home Automation for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig4.</strong> Wait for the confirmation prompt.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={domoImage05}
                    alt="Domovea Home Automation for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig5.</strong> The Add menu should now contain a camera preset for your INSTAR camera model. Select your model from the drop-down menu.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={domoImage06}
                    alt="Domovea Home Automation for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig6.</strong> Add your camera´s IP address and your login credentials.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
