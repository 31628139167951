import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Network/Remote_Access/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import RemoteTable from 'components/Web_User_Interface/1440p_Series/Network/Remote_Access/remoteTable';
import DangerBox from 'components/Web_User_Interface/1440p_Series/Network/Remote_Access/DangerBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  SuccessBox,
  PermissionBox,
  RemoteTable,
  DangerBox,
  BreadCrumbs,
  SEOHelmet,
  React
};