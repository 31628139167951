import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="hiWKczBCbSI"
                    title="INSTAR Camera Tool macOS Domain Name"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="INSTAR Camera Tool macOS Domain Name"
              footer={
                <EuiText>
                  <p>Wir zeigen in diesem Video wie Sie Ihrer INSTAR Kamera in Ihrem Netzwerk einen Namen vergeben können wie z.B. eingang.cam. So können Sie dann im Webbrowser, anstatt der IP Adresse den Namen eingeben und gelangen so auf Ihre Kamera. </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
