import React from 'react'

import { Player } from 'video-react'

import {
    EuiText,
    EuiIcon,
    EuiPanel
  } from '@elastic/eui'

import { OutboundLink } from 'gatsby-plugin-gtag'

require('../../Layout/chrome/assets/video.css')

function VideoCard() {
  return (
    <EuiPanel paddingSize="m">
        <Player>
            <source src="https://wiki.instar.com/videos/Hinzufuegen_einer_INSTAR_IP_Kamera_zur_Synology_Surveillance_Station_7.mp4" />
        </Player>
        <EuiText>
            <p>In diesem Video zeigen wir Ihnen wie Sie schnell und einfach eine INSTAR IP Kamera in Ihrer Synology Surveillance Station hinzufügen können. Schauen Sie auch unsere anderen Videos wo wir zeigen Sie die Synology konfigurieren damit diese das Video der Kamera durchgehend bzw. nur bei Bewegungen aufnimmt. Zudem zeigen wir auch den mobilen Zugriff auf die Synology um so z.B. mit Ihrem Smartphone auf die Synology zuzugreifen von unterwegs um sich die Aufnahmen anzuschauen. Auf <OutboundLink href="https://www.youtube.com/watch?v=034bXd-DYgU" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
        </EuiText>
    </EuiPanel>
  );
}

export default VideoCard