import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Network/WiFi/SuccessBox';
import DangerBox1 from 'components/Web_User_Interface/1080p_Series/Network/WiFi/DangerBox1';
import DangerBox2 from 'components/Web_User_Interface/1080p_Series/Network/WiFi/DangerBox2';
import PrimaryBox from 'components/Web_User_Interface/1080p_Series/Network/WiFi/PrimaryBox';
import WiFiTable from 'components/Web_User_Interface/1080p_Series/Network/WiFi/wifiTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  SuccessBox,
  DangerBox1,
  DangerBox2,
  PrimaryBox,
  WiFiTable,
  BreadCrumbs,
  SEOHelmet,
  React
};