import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="* Eclipse Mosquitto">
        <EuiText>
          <p>
            The INSTAR MQTT Interface is build upon the <OutboundLink href="https://mosquitto.org" target="_blank">Eclipse Mosquitto</OutboundLink> open source MQTT broker. The source code can be <OutboundLink href="https://github.com/eclipse/mosquitto" target="_blank">downloaded from Github</OutboundLink> and is available under the <OutboundLink target="_blank" href="http://www.eclipse.org/legal/epl-v20.html">Eclipse Public License 2.0</OutboundLink>.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

