import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class WiFiTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Push Service" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Search / Manual', descriptionColumn: 'Please click on _Search_ to let your camera search for wireless networks. Select your WiFi from the list below, add your WiFi key and _Submit_. After confirmation, simply unplug the Ethernet cable and your camera will connect to the WiFi network. Alternatively, type in the network parameter manually.' },
        { actionColumn: 'Network Type', descriptionColumn: 'Choose if you want to connect your camera directly to another network device (ad hoc). Or if you want to add it to the local WiFi network of your wireless router (infra).' },
        { actionColumn: 'Authentication/Encryption', descriptionColumn: 'Choose the encryption standard that your router is using. __We recommend__ that you set your router to use pure WPA2 (AES or CCMP) and not WPA+WPA2 (TKIP).' },
        { actionColumn: 'Type of Encryption', descriptionColumn: 'Set the type of encryption to the same encryption that your router is using. __We recommend__ to set your WiFi router to use the AES standard. In case of an AVM Fritzbox, select CCMP and couple it with AES inside your camera.' },
        { actionColumn: 'WiFi Key', descriptionColumn: 'Here you need to add the WiFi key that you use to connect devices to your local wireless network. The WiFi can sometimes be found on a sticker directly on the router itself.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

WiFiTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(WiFiTable)