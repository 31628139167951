import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class LogTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Camera Log" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'startup', descriptionColumn: 'E: Startup system' },
        { actionColumn: 'shutdown', descriptionColumn: 'E: Shutdown system' },
        { actionColumn: 'wifiEstablished', descriptionColumn: 'E: Wifi established' },
        { actionColumn: 'wifiDisconnected', descriptionColumn: 'E: Wifi disconnected' },
        { actionColumn: 'LanOnWifiOff', descriptionColumn: 'E: Wifi disconnected due to LAN' },
        { actionColumn: 'switchToDay', descriptionColumn: 'E: Switching to day mode' },
        { actionColumn: 'switchToNight', descriptionColumn: 'E: Switching to night mode' },
        { actionColumn: 'pirTriggered', descriptionColumn: 'A: PIR triggered' },
        { actionColumn: 'soundDetected', descriptionColumn: 'A: Sound detected' },
        { actionColumn: 'manualAlarm', descriptionColumn: 'A: Manual alarm triggered' },
        { actionColumn: 'sdDeleteOldest', descriptionColumn: 'N: Storage full, delete %s' },
        { actionColumn: 'sdTimedDelete', descriptionColumn: 'N: Storage time exceeded, delete %s' },
        { actionColumn: 'userLogin', descriptionColumn: 'E: %s has logged in' },
        { actionColumn: 'userLoginIp', descriptionColumn: 'E: %s has logged in from %s' },
        { actionColumn: 'userLogout', descriptionColumn: 'E: %s has logged out' },
        { actionColumn: 'push', descriptionColumn: 'A: Push has been sent' },
        { actionColumn: 'alarmserver', descriptionColumn: 'A: HTTP request has been sent' },
        { actionColumn: 'cloudRecord', descriptionColumn: 'A: Record to INSTAR CLOUD (%s)' },
        { actionColumn: 'sdRecord', descriptionColumn: 'A: Record to SD (%s)' },
        { actionColumn: 'sdSnap', descriptionColumn: 'A: Snapshot to SD (%s)' },
        { actionColumn: 'ftpRecord', descriptionColumn: 'A: Record to FTP (%s)' },
        { actionColumn: 'ftpSnap', descriptionColumn: 'A: Snapshot to FTP (%s)' },
        { actionColumn: 'ftpFail', descriptionColumn: 'Err: FTP upload failed (%s)' },
        { actionColumn: 'ftpFailTimeout', descriptionColumn: 'Err: FTP timeout (%s)' },
        { actionColumn: 'ftpFailTooMany', descriptionColumn: 'Err: FTP too many con. (%s)' },
        { actionColumn: 'ftpSuccess', descriptionColumn: 'A: FTP upload successful (%s)' },
        { actionColumn: 'emailPrepare', descriptionColumn: 'A: Collecting snapshots for E-mail (%d)' },
        { actionColumn: 'emailSuccess', descriptionColumn: 'A: E-mail successful' },
        { actionColumn: 'emailFail', descriptionColumn: 'Err: E-mail failed' },
        { actionColumn: 'emailFailTimeout', descriptionColumn: 'Err: E-mail timeout' },
        { actionColumn: 'recKeyWarning', descriptionColumn: 'N: No Keyframe in prerecording' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

LogTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(LogTable)



