import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../../../images/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/PoE_or_WiFi/Ethernet/Quickinstallation_Tile_9008_Step_09.webp"
import qiSelect02 from "../../../../../../images/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/PoE_or_WiFi/Ethernet/Quickinstallation_Tile_9008_Step_10.webp"
import qiSelect03 from "../../../../../../images/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/PoE_or_WiFi/Ethernet/Quickinstallation_Tile_9008_Step_11.webp"
import qiSelect04 from "../../../../../../images/Outdoor_Cameras/IN-9408_WQHD/Quick_Installation/PoE_or_WiFi/Ethernet/Quickinstallation_Tile_9008_Step_12.webp"




export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='II-d-2 Router Connect'
              image={
                  <img
                    src={qiSelect01}
                    alt="Connect the cameras network cable to a free LAN port on your internet router. Plug in the power supply to boot-up your camera."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Connect the cameras network cable to a free LAN port on your internet router. Plug in the power supply to boot-up your camera.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='II-d-3 Camera Tool'
              image={
                  <img
                    src={qiSelect02}
                    alt="Start our IP Camera Tool from the Software CD and double click on the name of the camera."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Start our <OutboundLink href="https://install.instar.com/WQHDv2/IN-9408_WQHD_Installation/01_LAN_Integration/03_Find_your_Camera">IP Camera Tool</OutboundLink> from the Software CD and double click on the name of the camera.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='II-d-4 User Login'
              image={
                  <img
                    src={qiSelect03}
                    alt="The default administrator login is User/Password: admin / instar. Our Installation Wizard will lead you through the rest of the installation process."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">The default administrator login is User/Password: admin / instar. Our <OutboundLink href="http://install.instar.de/">Installation Wizard</OutboundLink> will lead you through the rest of the installation process.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='II-d-5 Video Plugin'
              image={
                  <img
                    src={qiSelect04}
                    alt="You need to install the Adobe Flash Plugin to display your cameras h.265 video stream. You can use to HTML5 Stream without the need to install a Browser plugins on your machine."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">You no longer <Link to="/Quick_Installation/Live_Video/">need to install the Adobe Flash Plugin</Link> to display your cameras h.265 video stream.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
