import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import browserExplorer from "../../../images/Quick_Installation/How_To_Clear_Your_Browsing_History/Icon_Internet-Explorer-10.webp"
import browserFirefox from "../../../images/Quick_Installation/How_To_Clear_Your_Browsing_History/Icon_Firefox.webp"
import browserChromium from "../../../images/Quick_Installation/How_To_Clear_Your_Browsing_History/Icon_Chromium.webp"
import browserOpera from "../../../images/Quick_Installation/How_To_Clear_Your_Browsing_History/Icon_Opera.webp"
import browserEdge from "../../../images/Quick_Installation/How_To_Clear_Your_Browsing_History/Icon_Edge.webp"
import browserVivaldi from "../../../images/Quick_Installation/How_To_Clear_Your_Browsing_History/Icon_Vivaldi.webp"


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/Internet_Explorer/">
                  <img
                    src={browserExplorer}
                    alt="Clear your Browser History in Internet Explorer"
                  />
                </Link>
              }
              title='Löschen des Browservelraufs in Internet Explorer'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/Internet_Explorer/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/Mozilla_Firefox/">
                  <img
                    src={browserFirefox}
                    alt="Clear Browsing History in Mozilla Firefox"
                  />
                </Link>
              }
              title='Clear Browsing History in Mozilla Firefox'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/Mozilla_Firefox/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/Google_Chromium/">
                  <img
                    src={browserChromium}
                    alt="Clear Browsing History in Chrome"
                  />
                </Link>
              }
              title='Clear Browsing History in Chrome'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/Google_Chromium/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/Opera/">
                  <img
                    src={browserOpera}
                    alt="Clear Browsing History in Opera"
                  />
                </Link>
              }
              title='Clear Browsing History in Opera'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/Opera/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/Vivaldi/">
                  <img
                    src={browserVivaldi}
                    alt="Clear Browsing History in Vivaldi"
                  />
                </Link>
              }
              title='Clear Browsing History in Vivaldi'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/Vivaldi/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/Microsoft_Edge/">
                  <img
                    src={browserEdge}
                    alt="Clear Browsing History in Edge"
                  />
                </Link>
              }
              title='Clear Browsing History in Edge'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/Microsoft_Edge/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
