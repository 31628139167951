import { Link } from 'gatsby';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import DangerBoxURL from 'components/1440p_Series_CGI_List/DangerBoxURL';
import IntroCards from 'components/1440p_Series_CGI_List/Smarthome_Menu/smarthomeCGI';
import NavButtons from 'components/1440p_Series_CGI_List/NavButtons';
import * as React from 'react';
export default {
  Link,
  BreadCrumbs,
  SEOHelmet,
  DangerBoxURL,
  IntroCards,
  NavButtons,
  React
};