import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class DateTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Date & Time Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Current time from your camera', descriptionColumn: 'Internal time set inside your camera´s operating system. This time will be used for all alarm and recording schedules, as well as the time stamp used for file names.' },
        { actionColumn: 'Time zone', descriptionColumn: 'Here you can choose the time zone in which the camera is situated - in case of Germany, this would be GMT+1.' },
        { actionColumn: 'Synchronize with PC', descriptionColumn: 'If your camera does not have access to the internet, you can use this function to synchronize your computer time setting with your camera. This is not recommended, since you will have to repeat this process regularly to keep the internal clock accurate.' },
        { actionColumn: 'Automatic NTP/NTS synch', descriptionColumn: 'This function synchronizes the internal clock of your camera with a internet time server (NTP). It is recommended to use this to keep the camera time accurate.' },
        { actionColumn: 'Secure NTP (NTS)', descriptionColumn: 'Activate to use secure NTP TLS encryption.' },
        { actionColumn: 'NTP/S Server', descriptionColumn: 'Select an NTP or NTS server to synchronize your camera`s system time with.' },
        { actionColumn: 'Custom Server', descriptionColumn: 'Closed company networks might need to set a custom internal NTP/NTS server.' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

DateTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(DateTable)