import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Alarm Emails">
        <EuiText>
          <p>
            Here you need to input the sender, the receivers, the subject as well as optional a short email text with a maximum 64 characters. The sender address should be in accordance with the SMTP server settings, though some email provider let you choose this field freely. If you want to send the email to more than one address, simply separate the addresses with a semicolon.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

