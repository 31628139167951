import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ActionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Alarm Action" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Send Email', descriptionColumn: 'All alarm actions can be triggered by the Motion Detection Areas, the alarm input as well as the audio alarm. Here you can activate the alarm notification by email in case of a trigger event. Please be aware, that you first have to set your outgoing mail server (SMTP) and Email Receiver before using this function.' },
        { actionColumn: 'Save recordings on SD Card', descriptionColumn: 'Activate to save alarm videos on the internal SD Card in case of an alarm. An alarm video file name always starts with an __A__ and continuous with a time stamp. Your camera always records 3 seconds before the alarm event and 15 seconds after the triggered. A new 15s video will be started directly afterwards, if the motion continues.' },
        { actionColumn: 'Save recordings to FTP', descriptionColumn: 'Activate to save alarm videos on your personal FTP server. Please add your FTP Server configuration before activating this function.' },
        { actionColumn: 'Save recordings to the INSTAR Cloud', descriptionColumn: 'Activate to send alarm recordings to the INSTAR Cloud service.' },
        { actionColumn: 'Transfer Recordings via FTP', descriptionColumn: 'Activate sending a video to your FTP server after an alarm trigger. Please be aware, that you first have to set your FTP Server Information.' },
        { actionColumn: 'Video resolution', descriptionColumn: 'Set the resolution for your camera`s alarm videos.' },
        { actionColumn: 'Alarm recording length', descriptionColumn: 'Set the length of your alarm recording (your camera will add a 3s pre-recording)' },
        { actionColumn: 'Save Snapshot on SD', descriptionColumn: 'Activate saving snapshots to the internal SD Card in case of an alarm.' },
        { actionColumn: 'Transfer Snapshot via FTP', descriptionColumn: 'Activate sending snapshots to your FTP server in case of an alarm. Please be aware, that you first have to set your FTP information in the FTP Menu.' },
        { actionColumn: 'Snapshot Resolution', descriptionColumn: 'Set the resolution used for the snapshots uploaded to FTP, Email or saved to SD card in case of an alarm.' },
        { actionColumn: 'Number of Snapshots (email)', descriptionColumn: 'Set how many snapshots you want to be attached to an alarm email. Please be aware, that the email might be delayed if you attach too many images. If you want to be notified without a delay, please use the Push Service.' },
        { actionColumn: 'Number of Snapshots (SD)', descriptionColumn: 'Set how many images you want to be saved to SD Card in case of an alarm trigger.' },
        { actionColumn: 'Number of Snapshots (FTP)', descriptionColumn: 'Set how many images you want your camera to send to your FTP Server in case of an alarm.' },
        { actionColumn: 'Play Alarm Signal', descriptionColumn: 'Set if you want your camera to play an alarm signal in case of an alarm. And please don`t use it in combination with the Audio Alarm (below) :).' },
        { actionColumn: 'Activate Audio Alarm', descriptionColumn: 'Let your camera listen to noises. This is the ideal function to use your camera as a baby phone.' },
        { actionColumn: 'Sensitivity', descriptionColumn: 'Adjust how sensitive the audio detection should be.' },
        { actionColumn: 'Activate Alarm Output', descriptionColumn: 'Activate your cameras alarm output (not all camera models), and let your camera close the relay in case of an alarm.' },
        { actionColumn: 'Output Duration', descriptionColumn: 'Set for how long the alarm output relay should be closed in case of an alarm.' },
        { actionColumn: 'Activate Alarm Input', descriptionColumn: 'Activate your camera´s alarm input (not all camera models). Information about connecting an external sensor can be found here. You can connect every sensor to your camera that is operated via a potential free contact.' },
        { actionColumn: 'Electric circuit during alarm / Alarm device set to', descriptionColumn: 'Set if your external sensor is configured to close the circuit in case of a detection or if it will open the contact (see also IN-MOTION 500). Please be aware that your camera will be in a constant state of alarm, if you set the alarm input to N.C. and you don´t connect an external sensor. (This can be used to test your Alarm Settings)' },
        { actionColumn: 'Link alarm input with alarm areas', descriptionColumn: 'It is recommended to couple the Internal Motion Detection with the external sensor. Please be aware, that you have to have at least one detection area active, when using the coupled state.' },
        { actionColumn: 'Activate internal PIR sensor', descriptionColumn: 'Activate the internal passive infrared sensor (PIR Motion Detector) to trigger an alert once a person enters the surveillance area (not available in all models). You can deactivate the Alarm Areas once the PIR sensor is active. But in most cases it is recommended to couple the Internal Motion Detection with the PIR sensor.' },
        { actionColumn: 'Link PIR and motion detect areas', descriptionColumn: 'You can also couple the Alarm Areas with the internal PIR sensor. Use this function to effectively screen out false alerts. Please be aware, that you have to at least activate one of the alarm areas to use this function! You also have to press both the Eye Symbol as well as the Burglar Symbol in our smartphone app to activate/deactivate the alarm.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ActionTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ActionTable)