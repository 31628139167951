import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Remark">
        <EuiText>
          <p>When you are using an external sensor (or the internal PIR sensor in case of the IN-8015 HD), then you have to either deactivate the internal motion detection (Alarm Areas) or have to couple it with the Alarm Input! The alarm cannot be triggered otherwise.</p>
          <p>Once you linked both detection methods, you always have to deactivate both to deactivate the motion detection!</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

