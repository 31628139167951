import { Link } from 'gatsby';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import PrimaryBox from 'components/Internet_Access/The_DDNS_Service/Test_DDNS_Address/PrimaryBox';
import ConfigTable from "components/Internet_Access/The_DDNS_Service/Test_DDNS_Address/ConfigTable";
import NavButtons from 'components/Internet_Access/NavButtons';
import ForumBox from 'components/Internet_Access/The_DDNS_Service/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  EuiButton,
  PrimaryBox,
  ConfigTable,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};