import React from 'react'

import {
  EuiCollapsibleNavGroup,
  EuiText,
  EuiListGroup,
  EuiListGroupProps,
  EuiPinnableListGroup,
  EuiPinnableListGroupItemProps,
  EuiSpacer,
  EuiButton,
  EuiButtonIcon,
} from '@elastic/eui';

import instarLogo from '../../../images/Layout/cam_logo_white.svg';

const deploymentsList: EuiListGroupProps['listItems'] = [
  {
    label: 'IP Indoor Cameras',
    iconType: 'empty',
    size: 's',
    color: 'ghost'
  },
  {
    label: 'IP Outdoor Cameras',
    iconType: 'empty',
    size: 's',
    color: 'ghost'
  },
  {
    label: 'Camera Surveillance Software',
    iconType: 'empty',
    size: 's',
    color: 'ghost'
  },
  {
    label: 'Camera Surveillance Accessories',
    iconType: 'empty',
    size: 's',
    color: 'ghost'
  },
];

export const TopNavLinks: EuiPinnableListGroupItemProps[] = [
  {
    label: 'Home',
    iconType: 'home',
    isActive: true,
    pinnable: false,
  }
];

export const SupportNavLinks: EuiPinnableListGroupItemProps[] = [
  { label: 'FAQs' },
  { label: 'Usermanuals' },
  { label: 'Downloads' },
  { label: 'Reviews' },
  { label: 'Privacy' },
];

export const ShopIntroduction = (
  <EuiCollapsibleNavGroup
    title={
      <span>
        <strong style={{ fontWeight: 'normal' }}>INSTAR Web Shop</strong> <br />
        <small>IP Surveillance & Network Security</small>
      </span>
    }
    iconType={instarLogo}
    iconSize="xl"
    isCollapsible={true}
    initialIsOpen={false}
    background="dark">
    <div role="group" className="SupportNavDeployment__content">
      <EuiListGroup listItems={deploymentsList} flush />
      <EuiSpacer size="s" />
      <a href="https://www.instar.com/" target="_blank" rel="nofollow">
        <EuiButton color="ghost" fullWidth>
          Shopping
        </EuiButton>
      </a>
    </div>
  </EuiCollapsibleNavGroup>
);

export const ForumIntroduction = (
  <EuiCollapsibleNavGroup
    background="light"
    iconType="graphApp"
    title="INSTAR Forum"
    isCollapsible={true}
    initialIsOpen={true}
    arrowDisplay="none"
    extraAction={
      <EuiButtonIcon
        aria-label="Hide and never show again"
        title="Hide and never show again"
        iconType="cross"
      />
    }>
    <EuiText size="s" color="subdued" style={{ padding: '0 8px 8px', marginBottom: '5px' }}>
      <p>
        Our customer forum - ask your questions about INSTAR IP cameras, INSTAR software and general smarthome and security solutions.
        <br />
        <a href="https://forum.instar.de" target="_blank" rel="nofollow" style={{float: 'right', marginTop: '-12px'}}>
          <EuiButtonIcon
          title='INSTAR Forum'
          aria-label='Visit the INSTAR Forum'
          iconType='popout'
          />
        </a>
        {/* <a href="https://forum.instar.de" target="_blank" rel="nofollow">Visit now</a> */}
      </p>
    </EuiText>
  </EuiCollapsibleNavGroup>
);

const Support = () => (
  <>
    {ShopIntroduction}
    <EuiCollapsibleNavGroup background="light">
      <EuiPinnableListGroup
        listItems={TopNavLinks}
        onPinClick={() => {
          alert('hello');
        }}
        maxWidth="none"
        color="text"
        gutterSize="none"
        size="s"
      />
    </EuiCollapsibleNavGroup>
    <EuiCollapsibleNavGroup
      title="Support"
      iconType="discoverApp"
      isCollapsible={true}
      initialIsOpen={true}>
      <EuiPinnableListGroup
        listItems={SupportNavLinks}
        onPinClick={() => {
          alert('hello');
        }}
        maxWidth="none"
        color="subdued"
        gutterSize="none"
        size="s"
      />
    </EuiCollapsibleNavGroup>
    {ForumIntroduction}
  </>
);

export default Support