import React from 'react'

import { Player } from 'video-react'

import {
    EuiText,
    EuiIcon,
    EuiPanel
  } from '@elastic/eui'

import { OutboundLink } from 'gatsby-plugin-gtag'

require('../../Layout/chrome/assets/video.css')

function VideoCard() {
  return (
    <EuiPanel paddingSize="m">
        <Player>
            <source src="https://wiki.instar.com/videos/INSTAR_Cloud_Speicherplatz_kaufen_und_hinzubuchen.mp4" />
        </Player>
        <EuiText>
            <p>In diesem Video erklären wir wie Sie neuen Speicherplatz in Ihre Cloud Account hinzufügen können bzw. wie Sie diesen erweitern können.</p>
            <p>Der Preis für die INSTAR Cloud, ist im Gegensatz zu vielen anderen Anbietern, nicht an die Anzahl der Kameras gebunden sondern Sie können soviele Kameras wie Sie möchten hinzufügen und zahlen nur für die Größe des Speicherplatzes den Sie verwenden möchten. Auf <OutboundLink href="https://www.youtube.com/watch?v=spHKmZRmn4E" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
        </EuiText>
    </EuiPanel>
  );
}

export default VideoCard