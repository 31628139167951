import ImageCards from 'components/Software/Other_Platforms/InstarVision_Blackberry/instarVisionCards';
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  ImageCards,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};