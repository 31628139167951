import React from "react"
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import IN9020RadarIN7011 from "./in9020-radargraph-7011"
import IN9020RadarIN4011 from "./in9020-radargraph-4011"

const styles = {
  cardmedia: {
    height: 275,
    display: 'flex',
    justifyContent: 'center'
  }
}

function IN6014RadarGrid(props) {
  const { classes } = props;

  return (
    <Grid container spacing={10}>
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle1">
          IN-9010FHD vs IN-7011HD
        </Typography>
        <div className={classes.cardmedia}>
          <IN9020RadarIN7011 />
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle1">
          IN-9010FHD vs IN-4011
        </Typography>
        <div className={classes.cardmedia}>
          <IN9020RadarIN4011 />
        </div>
      </Grid>
    </Grid>
  );
}

IN6014RadarGrid.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(IN6014RadarGrid)