import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Software Update">
        <EuiText>
          <p>
            The INSTAR software department is constantly working to improve your experience with our product. You can check the update section from time to time to make sure your system is still up-to-date. You can also check our <OutboundLink href="https://www.instar.com/support/downloads" target="_blank" rel="noopener noreferrer">Download Area</OutboundLink> to find our newest software and corresponding change log's.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

