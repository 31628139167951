import React from 'react'

import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Note:">
        <EuiText>
          <p>This is an updated guide using Docker to install ioBroker instead of installing the software directly. This Guide is also updated to be used with INSTAR cameras newer than the Full HD series. There are also a list of <Link to="/Advanced_User/All_ioBroker_Tutorials/">FAQs</Link> available that are already updated.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

