import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class MQTTTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "MQTT Settings" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'MQTT Topic Prefix', descriptionColumn: 'Specify a prefix that will be prepended to each camera topic. This is used to group MQTT devices in your network. For example, use "cameras" to put all cameras in one group. Or "garden/cams" to create further subgroups.' },
        { actionColumn: 'MQTT Client ID', descriptionColumn: 'The ID follows the prefix in the topic. For example, if you selected "garden/cams" above and the client ID here is 115 (the last number of the camera`s IP), then all MQTT topics for the camera will start with "garden/cams/115".' },
        { actionColumn: 'MQTT LWT', descriptionColumn: 'The Last Will and Testament of the camera. Under this topic, the camera stores both an "I am online" and an "I have no connection to the MQTT broker" value. If the camera`s "heartbeat" is missing for 60 seconds, the broker automatically changes the topic to the latter value.' },
        { actionColumn: 'MQTT LWT Online', descriptionColumn: 'Value of the LWT when the camera has a connection to the broker.' },
        { actionColumn: 'MQTT LWT Offline', descriptionColumn: 'Value of the LWT when the camera has no connection to the broker.' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

MQTTTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(MQTTTable)