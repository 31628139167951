import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import videoActiveX from "../../../images/Quick_Installation/Live_Video/Live-Video_ActiveX.webp"
import videoQuicktime from "../../../images/Quick_Installation/Live_Video/Live-Video_Quicktime.webp"
import videoMJPEG from "../../../images/Quick_Installation/Live_Video/Live-Video_MJPEG.webp"


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Live_Video/ActiveX/">
                  <img
                    src={videoActiveX}
                    alt="ActiveX Plugin"
                  />
                </Link>
              }
              title='ActiveX Plugin'
              description={
                <p>Windows video plugin (IE and FF)</p>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Live_Video/ActiveX/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Live_Video/QuickTime/">
                  <img
                    src={videoQuicktime}
                    alt="QuickTime Plugin"
                  />
                </Link>
              }
              title='QuickTime Plugin'
              description={
                <p>Plugin for Windows and macOS</p>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Live_Video/QuickTime/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Live_Video/MJPEG/">
                  <img
                    src={videoMJPEG}
                    alt="MJPEG Live-Video"
                  />
                </Link>
              }
              title='MJPEG Live-Video'
              description={
                <p>Use this mode in Microsoft Edge</p>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Live_Video/MJPEG/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
