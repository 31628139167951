import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Alarm Areas">
        <EuiText>
          <p>
            The alarm areas detect motion by a picture analysis that is sensitive to every change inside the video frame instead of employing an infrared or microwave sensor. You can setup up to 4 detection areas - just activate an area and draw its shape onto the image - and adjusts it´s sensitivity to set a threshold for trigger events. Once the motion detection in an area is activated, its sensitivity can be adjusted from very insensitive (value=0) to very sensitive (value=100).
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

