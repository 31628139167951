import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/System/User/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import DangerBox from 'components/Web_User_Interface/1440p_Series/System/User/DangerBox';
import UserTable from 'components/Web_User_Interface/1440p_Series/System/User/userTable';
import UserPermissionTable from 'components/Web_User_Interface/1440p_Series/System/User/userPermissionTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  SuccessBox,
  PermissionBox,
  DangerBox,
  UserTable,
  UserPermissionTable,
  BreadCrumbs,
  SEOHelmet,
  React
};