import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Attention">
        <EuiText>
            <p>The username and password must be 8-30 characters long. For username and password all <a href="https://www.asciitable.com/" target="_blank" rel="noopener noreferrer">ASCII characters</a>, except extended characters, may be used. Note that "Umlauts", for example, are not part of this supported character set. Passwords must contain upper and lower case letters as well as at least one number and one special character.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

