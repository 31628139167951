import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import camera5905 from "../../../images/Frequently_Asked_Question/Heating_Element_Installation/IN-5905HD_Heater-Installation.png"
import camera5907 from "../../../images/Frequently_Asked_Question/Heating_Element_Installation/IN-5907HD_Heater-Installation.png"
import camera7011 from "../../../images/Frequently_Asked_Question/Heating_Element_Installation/IN-7011HD_Heater-Installation.png"


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Outdoor_Cameras/IN-5905_HD/Usermanual/">
                  <img
                    src={camera5907}
                    alt="IN-5905 HD Heizung Installieren"
                  />
                 </Link>
              }
              title='IN-5905 HD Heizung Installieren'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Outdoor_Cameras/IN-5905_HD/Usermanual/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Outdoor_Cameras/IN-5907_HD/Usermanual/">
                  <img
                    src={camera5905}
                    alt="IN-5907 HD Heizung Installieren"
                  />
                 </Link>
              }
              title='IN-5907 HD Heizung Installieren'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Outdoor_Cameras/IN-5907_HD/Usermanual/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Outdoor_Cameras/IN-7011_HD/Usermanual/">
                  <img
                    src={camera7011}
                    alt="IN-7011 HD Heizung Installieren"
                  />
                 </Link>
              }
              title='IN-7011 HD Heizung Installieren'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Outdoor_Cameras/IN-7011_HD/Usermanual/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
