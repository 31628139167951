import { EuiSpacer } from '@elastic/eui';
import ConfigTable from 'components/Software/Mobile_Access_Over_HTTPS/configTable';
import NavButtons from 'components/Software/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  ConfigTable,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};