import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Websocket Support">
        <EuiText>
        <p>The <strong>biOS MQTT Plugin</strong> connects to your MQTT Broker using a Websocket (WS) Tunnel. WS are not supported by INSTAR Full HD MQTTv3 cameras. But you can still control them through your Stream Deck by connecting them to WS supporting MQTT Broker, e.g. an INSTAR 2k+ WQHD MQTTv5 camera or an external Mosquitto Broker. Having them connected as an MQTT client allows you to send commands to your MQTT (WS) Broker that are then forwarded to them using regular MQTT (without a Websocket).</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

