import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Overview',
      href: '/en/Indoor_Cameras/IN-3011/',
    },
    {
      text: 'Specifications',
      href: '/en/Indoor_Cameras/IN-3011/Technical_Specifications/',
    },
    {
      text: 'Usermanual',
      href: '/en/Indoor_Cameras/IN-3011/Usermanual/',
    },
    {
      text: '◄ Indoor Cameras',
      href: '/en/Indoor_Cameras/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        aria-label="INSTAR IN-3011 Online Usermanual Chapters"
      />
      <EuiSpacer />
    </Fragment>
  );
}