import React from 'react'

import { Player } from 'video-react'

import {
    EuiText,
    EuiIcon,
    EuiPanel
  } from '@elastic/eui'

import { OutboundLink } from 'gatsby-plugin-gtag'

require('../../Layout/chrome/assets/video.css')

function VideoCard() {
  return (
    <EuiPanel paddingSize="m">
        <Player>
            <source src="https://wiki.instar.com/videos/Filezilla_FTP_Server_erstellen_Aufnahmen_per_FTP_uebertragen_und_nach_X_Tagen_automatisch_löschen.mp4.mp4" />
        </Player>
        <EuiText>
            <p>Dieses Video beschreibt Ihnen mehrere Punkte.</p>
            <ul>
              <li>1) Wir zeigen Ihnen wie Sie unter Windows Filezilla Server installieren, also einen eigenen FTP Server erstellen auf den die Kamera dann Ihre Aufnahmen übertragen kann.</li>
              <li>2) Wir zeigen Sie wie und wo Sie die Daten in der Kamera eintragen müssen</li>
              <li>3) Mit der automatischen Aufgabenplanung von Windows lassen wir Dateien automatisch löschen die älter als X Tage sind</li>
            </ul>
            <p>Auf <OutboundLink href="https://www.youtube.com/watch?v=xnzk7EidhxU-8" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
        </EuiText>
    </EuiPanel>
  );
}

export default VideoCard