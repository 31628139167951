import React from 'react'

import {
  EuiTitle,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
} from '@elastic/eui'

import IntroCards from './IntroAccessoriesToC'

export default function IntroHome() {

  return (
    <React.Fragment>
      <EuiSpacer />
        <EuiTitle size="m">
            <h2>Downloads für INSTAR Zubehör</h2>
        </EuiTitle>
        <EuiTitle size="s">
          <h4>Software, Apps, Zubehör und Handbücher zum Download.</h4>
        </EuiTitle>
        <EuiHorizontalRule margin="xs" />
      <EuiFlexGroup gutterSize="l" direction="column" style={{margin: '0 7px'}} >
        <EuiFlexItem>
          <IntroCards />
        </EuiFlexItem>
    </EuiFlexGroup>
  </React.Fragment>
  );
}