import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class DownloadTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "changeColumn", title: "Changes" }
      ],
      rows: [
        { changeColumn: 'Folder structure adjustable by date/channel or channel/date' },
        { changeColumn: 'Recording files get new file names with A (alarm), P (scheduled recording), T (test) and N (manual recording) just as local recordings on your camera`s SD card' },
        { changeColumn: 'Camera name can be added as a prefix to the recording file to keep recordings sorted' },
        { changeColumn: 'FTP upload of videos is now possible (images and/or videos can be selected separately)' },
        { changeColumn: 'Camera name as folder instead of CH01, CH02...' },
        { changeColumn: 'Solved problem with Multi-Monitor function' },
        { changeColumn: 'Triggering of an external script or program now also possible without having to start a recording' },
        { changeColumn: 'User and password can now be up to 63 characters long' },
        { changeColumn: 'Many more minor bugfixes' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

DownloadTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(DownloadTable)



// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'



// const cameraRow = [
//   'Folder structure adjustable by date/channel or channel/date',
//   'Recording files get new file names with A (alarm), P (scheduled recording), T (test) and N (manual recording) just as local recordings on your camera`s SD card',
//   'Camera name can be added as a prefix to the recording file to keep recordings sorted',
//   'FTP upload of videos is now possible (images and/or videos can be selected separately)',
//   'Camera name as folder instead of CH01, CH02...',
//   'Solved problem with Multi-Monitor function',
//   'Triggering of an external script or program now also possible without having to start a recording',
//   'User and password can now be up to 63 characters long',
//   'Many more minor bugfixes',
// ]

// // Render your table
// const ConnectTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={cameraRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Release Notes</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {cameraRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={850}
//         />
//       </Table>
//     )
// }

// export default ConnectTable