import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/Setup/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_MDSetup.webp'
                    alt="Motion Detection"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Motion Detection"
              description="1080p Series 720p Series VGA Series INSTAR 1080p Camera Series To activate the motion detection, open the web interface of your INSTAR HD IP camera and click on the menu Alarm / Areas. Now that we s…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/Setup/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/Passive_Infrared_Detection/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_PIR_Sensor.webp'
                    alt="PIR Sensor"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="PIR Sensor"
              description="Use your camera's PIR Sensor to minimize the risks of false alerts by light changes. The PIR body heat detector can be coupled with the regular software based motion detection."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/Passive_Infrared_Detection/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/Alarm_Notification/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_AlarmNotification.webp'
                    alt="Alarm Notification"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Notification"
              description="1080p Camera Series Setting up and activating the email alarm. Step 01 First, open the Email Settings. INSTAR offers you a forwarding Email Server, that allows …"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/Alarm_Notification/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/SD_Card_Access/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_SDCardAccess.webp'
                    alt="SD Card Access"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="SD Card Access"
              description="Table of Content Smartphone Apps INSTAR Camera Tool Web User Interface FTP Software (Windows) FTP Software (macOS) The HD camera models use an internal (removable) SD card to record videos, alarm sna…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/SD_Card_Access/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/Alarm_FTP_Upload/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_FTPSettingsAlarmUpload.webp'
                    alt="Alarm FTP Upload - 1080p Series Cameras"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm FTP Upload"
              description="1080p Series 720p Series VGA Series Alarm Upload / FTP Settings for 1080p Cameras Set the FTP upload during alarm and photo series Your IP camera can upload pictures and videos either during a motion…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/Alarm_FTP_Upload/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/Router_as_a_FTP_Server/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_RouterAsFTPServer.webp'
                    alt="Router als FTP Server"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Router or NAS as FTP Server"
              description="Local FTP Server Most modern routers now support USB mass storage devices (USB sticks, hard drives, etc. ...) to be plugged in - some even come with a large amount of on-board storage for you to use …"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/Router_as_a_FTP_Server/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/FTP_Server_Setup/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_FTPServerSetup.webp'
                    alt="FTP Server Setup"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="FTP Server Setup"
              description="Setting up a Filezilla server on your Windows Computer Your camera can upload pictures during an alarm event or you can also upload pictures in a defined time period to any kind of FTP server. Beside…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/FTP_Server_Setup/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/">
                  <StaticImage
                    src='../../images/Search/MD_SearchThumb_Cloud.webp'
                    alt="INSTAR Cloud"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="INSTAR Cloud"
              description="False-Alarm Detection The new feature adds an additional server-side alarm-event validation which is able to detect possible false alarms and marks them accordingly in the Tab Alarms. The platform pr…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
