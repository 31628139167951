import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/styles'
import {
  SortingState, SelectionState, FilteringState, GroupingState,
  IntegratedFiltering, IntegratedGrouping, IntegratedSelection,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table, TableHeaderRow, TableFilterRow, TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ThirdpartyCamsGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
        columns: [
            { name: "brand", title: "Hersteller" },
            { name: "model", title: "Kamera Model" }
        ],
        rows: [
            { brand: 'ABUS', model: 'Digi-Lan TV7204' },
            { brand: 'ABUS', model: 'Digi-Lan TV7206' },
            { brand: 'ABUS', model: 'Digi-Lan TV7230 v2' },
            { brand: 'ABUS', model: 'TVIP10000 Series' },
            { brand: 'ABUS', model: 'TVIP20000 Series' },
            { brand: 'ABUS', model: 'TVIP41550' },
            { brand: 'ABUS', model: 'TVIP51550' },
            { brand: 'Allnet', model: 'ALL2200' },
            { brand: 'Allnet', model: 'ALL2205' },
            { brand: 'Allnet', model: 'ALL2250' },
            { brand: 'Allnet', model: 'ALL2272' },
            { brand: 'Allnet', model: 'ALL2281/82' },
            { brand: 'Allnet', model: 'ALL2288' },
            { brand: 'Allnet', model: 'ALL2295' },
            { brand: 'Allnet', model: 'ALL2296/98' },
            { brand: 'Allnet', model: 'ALL2297' },
            { brand: 'Amcrest', model: 'IP4M-1053EW' },
            { brand: 'Amcrest', model: 'IP8M-2454EW' },
            { brand: 'Apexis', model: 'Apexis H Series' },
            { brand: 'Apexis', model: 'Apexis J Series' },
            { brand: 'Axis', model: 'Axis 200 Series' },
            { brand: 'Axis', model: 'Axis M Series' },
            { brand: 'Axis', model: 'Axis P Series' },
            { brand: 'Axis', model: 'Axis P3707' },
            { brand: 'Axis', model: 'Axis Q Series' },
            { brand: 'Bosch', model: 'Flexidome' },
            { brand: 'Cisco', model: 'WVC210' },
            { brand: 'Cisco', model: 'VC220' },
            { brand: 'Dericam', model: 'Dericam H Series' },
            { brand: 'Dericam', model: 'Dericam M Series' },
            { brand: 'Dericam', model: 'P2' },
            { brand: 'Dericam', model: 'S2C' },
            { brand: 'Digitus', model: 'DN-16025' },
            { brand: 'Digitus', model: 'DN-16026' },
            { brand: 'Digitus', model: 'DN-16027 (Rev1)' },
            { brand: 'Digitus', model: 'DN-16027/28' },
            { brand: 'Digitus', model: 'DN-16029' },
            { brand: 'Digitus', model: 'DN-16036' },
            { brand: 'Digitus', model: 'DN-16036 (Rev1)' },
            { brand: 'Digitus', model: 'DN-16037 (Rev1)' },
            { brand: 'Digitus', model: 'DN-16038' },
            { brand: 'Digitus', model: 'DN-16039' },
            { brand: 'Digitus', model: 'DN-16040' },
            { brand: 'Digitus', model: 'DN-16040 (Rev1)' },
            { brand: 'Digitus', model: 'DN-16043' },
            { brand: 'Digitus', model: 'DN-16044' },
            { brand: 'Digitus', model: 'DN-16046' },
            { brand: 'D-Link', model: 'DCS-930 ' },
            { brand: 'D-Link', model: 'DCS-942L ' },
            { brand: 'D-Link', model: 'DCS-960L' },
            { brand: 'D-Link', model: 'DCS-2330L' },
            { brand: 'D-Link', model: 'DCS-5020L' },
            { brand: 'D-Link', model: 'DCS-2000 Series ' },
            { brand: 'D-Link', model: 'DCS-3000 Series ' },
            { brand: 'D-Link', model: 'DCS-5000 Series ' },
            { brand: 'D-Link', model: 'DCS-6000 Series' },
            { brand: 'EasyN', model: 'EasyN F Series' },
            { brand: 'EasyN', model: 'EasyN H Series' },
            { brand: 'Edimax', model: 'Generic profiles' },
            { brand: 'Elro', model: 'C700/C900 Series' },
            { brand: 'Elro', model: 'C800IP' },
            { brand: 'Elro', model: 'C803IP' },
            { brand: 'Elro', model: 'C903IP' },
            { brand: 'Foscam', model: 'FI9800P' },
            { brand: 'Foscam', model: 'FI8904W' },
            { brand: 'Foscam', model: 'FI8905W' },
            { brand: 'Foscam', model: 'FI9803P' },
            { brand: 'Foscam', model: 'FI9804P' },
            { brand: 'Foscam', model: 'FI9804W' },
            { brand: 'Foscam', model: 'FI9805P' },
            { brand: 'Foscam', model: 'FI9805W' },
            { brand: 'Foscam', model: 'FI9816P' },
            { brand: 'Foscam', model: 'FI9821P' },
            { brand: 'Foscam', model: 'FI9821W' },
            { brand: 'Foscam', model: 'FI9821W v2' },
            { brand: 'Foscam', model: 'FI9826P' },
            { brand: 'Foscam', model: 'FI9826W' },
            { brand: 'Foscam', model: 'FI9828P' },
            { brand: 'Foscam', model: 'FI9831P' },
            { brand: 'Foscam', model: 'FI9831W' },
            { brand: 'Foscam', model: 'FI89xxW' },
            { brand: 'Foscam', model: 'FI98xxW' },
            { brand: 'Foscam', model: 'FI9900P' },
            { brand: 'Foscam', model: 'FI9928P' },
            { brand: 'Foscam', model: 'FI9961EP' },
            { brand: 'Hama', model: 'M360' },
            { brand: 'HeroSpeed', model: 'IPC' },
            { brand: 'Hikvision', model: 'DS-2CD2032F-I' },
            { brand: 'Inkovideo', model: 'V-117HD' },
            { brand: 'JVC', model: 'VN Series' },
            { brand: 'Linksys', model: 'PVC2300 ' },
            { brand: 'Linksys', model: 'WVC Series ' },
            { brand: 'Linksys', model: 'WVC54GCA' },
            { brand: 'Lupusnet', model: 'LE200' },
            { brand: 'Lupusnet', model: 'LE202' },
            { brand: 'Lupusnet', model: 'LE936' },
            { brand: 'Maginon', model: 'IPC-100 AC' },
            { brand: 'Mobotix', model: 'M Series' },
            { brand: 'Mobotix', model: 'Q Series' },
            { brand: 'Mobotix', model: 'Move' },
            { brand: 'Panasonic', model: 'BB Series' },
            { brand: 'Panasonic', model: 'BL Series' },
            { brand: 'Panasonic', model: 'KX Series' },
            { brand: 'Panasonic', model: 'WV Series' },
            { brand: 'Panasonic', model: 'WV-SF336E' },
            { brand: 'Panasonic', model: 'WV-SW155' },
            { brand: 'Reolink', model: 'C2' },
            { brand: 'Reolink', model: 'RLC-410' },
            { brand: 'Reolink', model: 'RLC-420' },
            { brand: 'Reolink', model: 'RLC-422' },
            { brand: 'Reolink', model: 'RLC-422W' },
            { brand: 'Reolink', model: 'RLC-423' },
            { brand: 'Reolink', model: 'RLC-511' },
            { brand: 'Reolink', model: 'RLC-520' },
            { brand: 'Reolink', model: 'RLC-810A' },
            { brand: 'Samsung', model: 'SN Series' },
            { brand: 'Siemens', model: 'Generic profiles' },
            { brand: 'Sony', model: 'SNC-CS50' },
            { brand: 'Swann', model: 'ADS-440IPC' },
            { brand: 'Tenvis', model: 'TZ100' },
            { brand: 'TRENDnet', model: 'TV-IP110WN' },
            { brand: 'TRENDnet', model: 'TV-IP310PI' },
            { brand: 'Trivision', model: 'NC-230WF' },
            { brand: 'Trivision', model: 'NC-240WF HD' },
            { brand: 'Ubiquiti', model: 'Unifi Micro' },
            { brand: 'Vilar', model: 'VS-IPC1002' },
            { brand: 'Vivotek', model: 'FD816C-HF2' },
            { brand: 'Vivotek', model: 'IP8362' },
            { brand: 'Wanscam', model: 'Geenric profiles' },
            { brand: 'Wansview', model: 'NC530 Series ' },
            { brand: 'Wansview', model: 'NC540 Series ' },
            { brand: 'Wansview', model: 'NCM 621W ' },
            { brand: 'Wansview', model: ' 625GB' },
            { brand: 'Wansview', model: 'W2' },
            { brand: 'Wansview', model: 'Q3S' },
            { brand: '7Links', model: 'IPC-430' },
            { brand: '7Links', model: 'IPC-710IR' },
            { brand: '7Links', model: 'IPC-720HD' },
            { brand: '7Links', model: 'IPC-760HD' },
            { brand: '7Links', model: 'IPC-765VGA' },
            { brand: '7Links', model: 'IPC-770HD' },
            { brand: '7Links', model: 'IPC-780HD' },
            { brand: '7Links', model: 'IPC-850FHD' },
            { brand: '7Links', model: 'Robocam II' },
            { brand: '7Links', model: 'Robocam III' },
            { brand: '7Links', model: 'Robocam IV' },
        ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns} >

          <FilteringState />

          <SortingState
            defaultSorting={[
              { columnName: 'brand', direction: 'asc' },
            ]}
          />
          <GroupingState
            defaultGrouping={[{ columnName: 'brand' }]}
            defaultExpandedGroups={['ABUS']}
          />
          <SelectionState />

          <IntegratedFiltering />
          <IntegratedGrouping />
          <IntegratedSelection />
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
          <TableFilterRow />
          <TableGroupRow />
        </Grid>
      </Paper>
    );
  }
}

ThirdpartyCamsGrid.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ThirdpartyCamsGrid)