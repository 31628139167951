import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import { EuiSpacer } from '@elastic/eui';
import PTZTable from 'components/Frequently_Asked_Question/WQHD_Advanced_PTZ_Commands/PTZTable';
import * as React from 'react';
export default {
  BreadCrumbs,
  SEOHelmet,
  EuiSpacer,
  PTZTable,
  React
};