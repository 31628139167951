import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import qsP2PWPS01 from "../../../../images/Indoor_Cameras/IN-8015_HD/Point2Point/IN-8015HD_Quick-Installation_P2P-WPS_01.webp"
import qsP2PWPS02 from "../../../../images/Indoor_Cameras/IN-8015_HD/Point2Point/IN-8015HD_Quick-Installation_P2P-WPS_02.webp"
import qsP2PWPS03 from "../../../../images/Indoor_Cameras/IN-8015_HD/Point2Point/IN-8015HD_Quick-Installation_P2P-WPS_03.webp"
import qsP2PWPS04 from "../../../../images/Indoor_Cameras/IN-8015_HD/Point2Point/IN-8015HD_Quick-Installation_P2P-WPS_04.webp"
import qsP2PWPS05 from "../../../../images/Indoor_Cameras/IN-8015_HD/Point2Point/IN-8015HD_Quick-Installation_P2P-WPS_05.webp"
import qsP2PWPS06 from "../../../../images/Indoor_Cameras/IN-8015_HD/Point2Point/IN-8015HD_Quick-Installation_P2P-WPS_06.webp"
import qsP2PWPS07 from "../../../../images/Indoor_Cameras/IN-8015_HD/Point2Point/IN-8015HD_Quick-Installation_P2P-WPS_07.webp"
import qsP2PWPS08 from "../../../../images/Indoor_Cameras/IN-8015_HD/Point2Point/IN-8015HD_Quick-Installation_P2P-WPS_08.webp"




export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsP2PWPS01}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 1'
              description={
                <p>Attach the WiFi antenna to the cameras antenna connector as shown here (<Link to="/Indoor_Cameras/IN-8015_HD/Product_Features/">#11</Link>). (only for the non-PoE Version!)</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsP2PWPS02}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 2'
              description={
                <p>Plug in the power supply (<Link to="/Indoor_Cameras/IN-8015_HD/Product_Features/">#7</Link>). to start the camera. In case you are installing the PoE model, plug in the LAN cable (<Link to="/Indoor_Cameras/IN-8015_HD/Product_Features/">#12</Link>), connect it to your router and skip the WiFi section below.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsP2PWPS03}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 3'
              description={
                <p>Plug in the power supply (<Link to="/Indoor_Cameras/IN-8015_HD/Product_Features/">#7</Link>) to start the camera.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsP2PWPS04}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 4'
              description={
                <p>If your router supports the WPS (Wireless Protected Setup) function, please press the WPS button on the router, which looks similar to the example above. (only for the non-PoE Version!)</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsP2PWPS05}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 5'
              description={
                <p>Press the cameras reset button for 3 seconds to activate the cameras WPS service (<Link to="/Indoor_Cameras/IN-8015_HD/Product_Features/">#9</Link>). Wait for max. 5min for the camera to connect to your WiFi network. If you press it for 10s, the camera will be reset to its factory defaults!</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsP2PWPS06}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 6'
              description={
                <p>Start our <Link to="/Software/Android/InstarVision/P2P/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/P2P/">iOS</Link> or <Link to="/Software/Windows/InstarVision/Windows_Phone/P2P/">Windows Phone</Link> app Instar Vision. Choose to add a new p2p camera and start the QR code scanner. Your QR code is located on a label on the camera case (s. above).</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsP2PWPS07}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 7'
              description={
                <p>The QR code contains all the necessary information to add your camera. Please be aware that the default login credentials will be used. If you want to change the default password, please check the <Link to="/Indoor_Cameras/IN-8015_HD/Quick_Installation/">Quick Installation Guide</Link>. The password can only be changed in the web user interface.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qsP2PWPS08}
                    alt="IN-8015 Full HD Quick Installation"
                  />
              }
              title='Step 8'
              description={
                <p>The app will now connect to your cameras live stream and you will be able to pan & tilt the device, as well as to activate the audio stream of the internal microphone and to take snapshots.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
