import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import ceConform from "../../../../images/Outdoor_Cameras/IN-9408_WQHD/Safety_Warnings/CE-Conformity.webp"
import fccConform from "../../../../images/Outdoor_Cameras/IN-9408_WQHD/Safety_Warnings/FCC-Conformity.webp"
import rohsConform from "../../../../images/Outdoor_Cameras/IN-9408_WQHD/Safety_Warnings/RoHS-Conformity.webp"




export default function ConformityCard() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ceConform}
                    alt="CE Conformity"
                  />
              }
              description={
                <p>This device suites the basic requirements of the European regulations for electromagnetic compliance (2004/108/EC) and the low voltage regulations (2006/95/EC). If installed properly according to this manual, the product complies with CE regularities. To avoid the risk of electromagnetic interferences (e.g. with radios or radio traffic), it is highly recommended to use shielded cables only.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={fccConform}
                    alt="FCC Conformity"
                  />
              }
              description={
                <p>This product complies with the regularities stated under FCC part 15. These limits were specified to provide reasonable protection against harmful exposure when operating the device. This product emits and uses radio energy. It may in addition interfere with other radio communication systems, if it is not used according to this manual.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={rohsConform}
                    alt="RoHS Conformity"
                  />
              }
              description={
                <p>All INSTAR products are lead-free and meet the requirements stated under the European Directive on the Restriction of Hazardous Substances (RoHS). This guarantees that the entire production process and the product itself are free of lead and of all listed hazardous substances.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
