import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Amendment:">
        <EuiText>
          <p>Please note that <OutboundLink href="https://nodejs.org/download/package-manager/">Node.js 8 or 9</OutboundLink> must be present on your system before installation.</p>
          <p>If Node.js 8/9 is missing, it will be installed by the installation script, but the installation of FFMPEG will fail and the program will not start at the end.</p>
          <p>If this happens to you, you can <OutboundLink href="https://www.npmjs.com/package/ffmpeg">reinstall FFMPEG via npm</OutboundLink>. Go to the installation directory of Shinobi <code>cd /home/Shinobi</code> and start the installation via <code>npm install ffmpeg</code>. After that you should be able to start Shinobi via PM2 <code>pm2 restart all</code>, <code>pm2 list</code>.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

