import { EuiSpacer } from '@elastic/eui';
import BackupTable from 'components/Web_User_Interface/720p_Series/Software/Backup/backupTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  BackupTable,
  BreadCrumbs,
  SEOHelmet,
  React
};