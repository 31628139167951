import React from 'react'

import { Player } from 'video-react'

import {
    EuiText,
    EuiIcon,
    EuiPanel
  } from '@elastic/eui'

import { OutboundLink } from 'gatsby-plugin-gtag'

require('../../Layout/chrome/assets/video.css')

function VideoCard() {
  return (
    <EuiPanel paddingSize="m">
        <Player>
            <source src="https://wiki.instar.com/videos/Update_auf_die_neue_Firmware_3_1_2_56_für_die_INSTAR_HD_Serie.mp4" />
        </Player>
        <EuiText>
            <p>In diesem Video erklären wir Ihnen wie Sie die neueste Firmware auf Ihre INSTAR HD Kamera aufspielen können und wir stellen auch die neuesten Funktionen vor die Ihnen die neue Firmware bietet. Auf <OutboundLink href="https://www.youtube.com/watch?v=9s3P1TTj0Lc" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
        </EuiText>
    </EuiPanel>
  );
}

export default VideoCard