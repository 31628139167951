import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class P2PTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Network Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Activate P2P Connection', descriptionColumn: 'By activating the Point-2-Point connection, you enable your camera to directly connect with our InstarVision app on your smartphone or tablet. This way __you do not need__ to set up a PORT FORWARDING inside your router to access your camera, when you are on the road. But the connection is - in most cases - slower than the speed that you will get by accessing your camera over your DDNS ADDRESS.' },
        { actionColumn: 'P2P User ID', descriptionColumn: 'This is your camera´s identification number, that is used for the P2P connection. You can manual type it into Third-party Software like P2P Client. When you are using our Smartphone app, just scan the QR Code below to transmit all information necessary for the integration of your camera.' },
        { actionColumn: 'QR Code', descriptionColumn: 'To add your camera to our iPhone or Android app, you simply have to scan the QR code.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

P2PTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(P2PTable)