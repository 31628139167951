import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Features/SD_Card/SuccessBox';
import SDTable from 'components/Web_User_Interface/1080p_Series/Features/SD_Card/sdTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  SuccessBox,
  SDTable,
  BreadCrumbs,
  SEOHelmet,
  React
};