import React from 'react'

import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Communicate with your Camera">
        <EuiText>
          <p>When you are interested in using the MQTT interface to add your camera to a smarthome system, you are probably already familiar with our <Link to="/Advanced_User/CGI_Commands/">HTTP/S CGI interface</Link> that you can use to send commands to your camera through your web browser.</p>
          <p>MQTT works much the same way. Every CGI command corresponds to an MQTT topic and every topic can carry specific payloads. For example to activate the first Motion Detection Area on your camera, you can send the following <Link to="/Advanced_User/CGI_Commands/">CGI command</Link>:</p>
          <ul>
            <li><code>param.cgi?cmd=setmdattr&-name=1&-enable=[val]</code></li>
          </ul>
          <p>Where the value (<code>val</code>) can be <code>0</code> or <code>1</code> - depending on whether you want to deactivate or activate the detection area. In <Link to="/Advanced_User/INSTAR_MQTT_Broker/">MQTT</Link> doing the same thing looks like this:</p>
          <ul>
            <li><code>alarm/area1/enable &#123;"val":"0"&#125;, &#123;"val":"1"&#125;</code></li>
          </ul>
          <p>The topic to switch the area on or off is <code>alarm/area1/enable</code> and you have to send either a value of <code>1</code> or <code>0</code> to switch it.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

