import React from 'react'
import {Link} from 'gatsby'

import { Player } from 'video-react'

import {
    EuiText,
    EuiIcon,
    EuiPanel
  } from '@elastic/eui'

import { OutboundLink } from 'gatsby-plugin-gtag'

require('../../Layout/chrome/assets/video.css')

function VideoCard() {
  return (
    <EuiPanel paddingSize="m">
        <Player>
            <source src="https://wiki.instar.com/videos/INSTAR_Kamera_Tool_MacOS_Aufnahmen_der_SD_Karte_bequem_herunterladen.mp4" />
        </Player>
        <EuiText>
            <p>Wir zeigen Ihnen in diesem Video das neue INSTAR Kamera Tool welches Sie ab sofort im App Store von MacOS finden. Sie finden das neue Tool auf der INSTAR Webseite oder direkt hier: <Link to="/Downloads/Desktop_Software/Instar_Camera_Tool/">Download</Link>. Auf <OutboundLink href="https://www.youtube.com/watch?v=CG0h7wD0spM" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
        </EuiText>
    </EuiPanel>
  );
}

export default VideoCard