import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "numberColumn", title: "#" },
        { name: "componentColumn", title: "Component" },
        { name: "functionColumn", title: "Function" },
      ],
      rows: [
        { numberColumn: '01', componentColumn: 'Infrared LEDs', functionColumn: '5x High-power infrared LED´s for night vision @850nm' },
        { numberColumn: '02', componentColumn: 'Lense', functionColumn: 'Wide Angle (focal length 4.2mm)' },
        { numberColumn: '03', componentColumn: 'Photo sensor', functionColumn: 'Twilight Switch for IR LEDs' },
        { numberColumn: '04', componentColumn: 'PIR Detector', functionColumn: 'Integrated Panasonic PIR Motion Detector' },
        { numberColumn: '05', componentColumn: 'Microphone', functionColumn: 'For Noise Detection / Audio Recording / Two-way Audio Communication' },
        { numberColumn: '06', componentColumn: 'Audio Output', functionColumn: '3.5mm Jack for Earphone / Active Speaker' },
        { numberColumn: '07', componentColumn: 'Network Port', functionColumn: 'RJ45 LAN Port for the provided CAT5e Ethernet Cable (or higher)' },
        { numberColumn: '08', componentColumn: 'Power Connector', functionColumn: 'For included 12V / 2A dc Power supply' },
        { numberColumn: '09', componentColumn: 'Micro SD Card', functionColumn: 'Slot for MicroSD/SDXC Cards up to 256 GB (32 GB included)' },
        { numberColumn: '10', componentColumn: 'WPS / Reset', functionColumn: '3s for WiFi Setup / 15s for Reset to Factory Defaults' },
        { numberColumn: '11', componentColumn: 'Status LEDs', functionColumn: 'Red: Power, Blue: Network Status' },
        { numberColumn: '12', componentColumn: 'Antenna Connector', functionColumn: 'RP-SMA WiFi Antenna Connector' },
        { numberColumn: '13', componentColumn: 'WiFi Antenna', functionColumn: '3 dBi Antenna (RP-SMA)' },
        { numberColumn: '14', componentColumn: 'Cable Feedthrough', functionColumn: 'IP66 Weatherproof with Rubber Sealing for 3, 2 or 1 Cables. Please check out our Quick Installation Guide on how to attach your Camera Cables' },
        { numberColumn: '15', componentColumn: 'Sealing Ring', functionColumn: 'Rubber Sealing Rings for 3, 2 or 1 Cables. Please check out our Quick Installation Guide on how to attach your Camera Cables' },
        { numberColumn: '16', componentColumn: 'Nut', functionColumn: 'To close the Cable Feedthrough and Weather Seal it.' },
        { numberColumn: '17', componentColumn: 'Bracket', functionColumn: 'Wall- and Ceiling-Mount.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)