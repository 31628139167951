import { Link } from 'gatsby';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import IntroCards from 'components/720p_Series_CGI_List/IntroCGI';
import * as React from 'react';
export default {
  Link,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  IntroCards,
  React
};