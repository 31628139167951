import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import webcam01 from "../../../../images/Products/USB-Webcams/IN-W1/Web-camera-amazon-main-diagram-DE-1.webp"
import webcam02 from "../../../../images/Products/USB-Webcams/IN-W1/Web-camera-amazon-main-diagram-DE-4.webp"
import webcam03 from "../../../../images/Products/USB-Webcams/IN-W1/Web-camera-amazon-main-diagram-EN-2.webp"
import webcam04 from "../../../../images/Products/USB-Webcams/IN-W1/Web-camera-amazon-main-diagram-EN-5.webp"
import webcam05 from "../../../../images/Products/USB-Webcams/IN-W1/Web-camera-amazon-main-diagram-DE-3.webp"
import webcam06 from "../../../../images/Products/USB-Webcams/IN-W1/Web-camera-amazon-main-diagram-EN-7.webp"




export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              image={
                  <img
                    src={webcam01}
                    alt="IN-W1 Full-HD 1080P Webcam"
                  />
              }
              title="IN-W1 1080p"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              image={
                  <img
                    src={webcam02}
                    alt="IN-W1 Full-HD 1080P Webcam"
                  />
              }
              title="IN-W1 1080p"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              image={
                  <img
                    src={webcam03}
                    alt="IN-W1 Full-HD 1080P Webcam"
                  />
              }
              title="IN-W1 1080p"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              image={
                  <img
                    src={webcam04}
                    alt="IN-W1 Full-HD 1080P Webcam"
                  />
              }
              title="IN-W1 1080p"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              image={
                  <img
                    src={webcam05}
                    alt="IN-W1 Full-HD 1080P Webcam"
                  />
              }
              title="IN-W1 1080p"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              image={
                  <img
                    src={webcam06}
                    alt="IN-W1 Full-HD 1080P Webcam"
                  />
              }
              title="IN-W1 1080p"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
