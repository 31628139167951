import React from 'react'

import 'react-vis/dist/style.css'

import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
  Crosshair
} from 'react-vis'

const DATA = [
  [
    { x: 'base profile', y: 0.667557935484 },
    { x: 'main profile', y: 0.63193365625 },
    { x: 'high profile', y: 0.673509869565 },
  ]
];

export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: []
    };
  }
  render() {
    return (
        <React.Fragment>

          <div className="chart">
            <FlexibleWidthXYPlot
              onMouseLeave={() => this.setState({ crosshairValues: [] })}
              height={300}
              xType="ordinal"
              animation={true}
              stackBy="y">
              
              <VerticalGridLines />
              <HorizontalGridLines />
              <XAxis />
              <YAxis tickFormat={v => `${v}`} />

              <Crosshair values={this.state.crosshairValues} />

              <VerticalBarSeries
                onNearestX={(value, { index }) =>
                  this.setState({ crosshairValues: DATA.map(d => d[index]) })}
                cluster="h.264 Profile"
                color="#0077cc"
                data={DATA[0]}
              />

            </FlexibleWidthXYPlot>
          </div>

          <div className="legend">
            <DiscreteColorLegend
              orientation="horizontal"
              items={[
                {
                  title: 'h.264 Profile',
                  color: '#0077cc'
                }
              ]}
            />
          </div>

        </React.Fragment>
    );
  }
}