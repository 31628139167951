import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Alarm',
      href: '/en/1080p_Series_CGI_List/Alarm_Menu/',
    },
    {
      text: 'Features',
      href: '/en/1080p_Series_CGI_List/Features_Menu/',
    },
    {
      text: 'Multimedia',
      href: '/en/1080p_Series_CGI_List/Multimedia_Menu/',
    },
    {
      text: 'Network',
      href: '/en/1080p_Series_CGI_List/Network_Menu/',
    },
    {
      text: 'System',
      href: '/en/1080p_Series_CGI_List/System_Menu/',
    },
    {
      text: 'Complete List',
      href: '/en/1080p_Series_CGI_List/Complete_CGI_List/',
    },
    {
      text: '1080p CGIs',
      href: '/en/1080p_Series_CGI_List/',
    },
    {
      text: 'CGIs Commands',
      href: '/en/Advanced_User/CGI_Commands/',
    },
    {
      text: '◄ Developer',
      href: '/en/Advanced_User/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Topics for Developers"
      />
      <EuiSpacer />
    </Fragment>
  );
}