import React from 'react'

import {EuiFlexGroup, EuiFlexItem, EuiText} from '@elastic/eui'

import IN9020RadarIN7011 from "./in9020-radargraph-7011"
import IN9020RadarIN4011 from "./in9020-radargraph-4011"

function IN9020RadarGrid() {

  return (
    <div style={{ padding: '15px',maxWidth: '1115px', margin: '2.5rem 0 2.5rem 0', boxShadow: '0 2px 3px 0 rgb(0 0 0 / 36%' }}>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiText size="s">
            IN-9020FHD vs IN-7011HD
          </EuiText>
          <div style={{height: 275}}>
            <IN9020RadarIN7011 />
          </div>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText size="s">
            IN-9020FHD vs IN-4011
          </EuiText>
          <div style={{height: 275}}>
            <IN9020RadarIN4011 />
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}

export default IN9020RadarGrid