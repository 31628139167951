import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Desktop_Software/InstarVision/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_InstarVision_Windows.webp'
                    alt="InstarVision for Windows"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="InstarVision für Windows"
              description="InstarVision (1.x / 2.0) IP Surveillance Station for Windows. Manage up 64 IP cameras and their recordings with your Computer."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Desktop_Software/InstarVision/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Desktop_Software/InstarVision_for_macOS/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_InstarVision_Windows.webp'
                    alt="InstarVision for macOS"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="InstarVision für macOS"
              description="InstarVision (1.x / 2.0) IP Surveillance Station for macOS. Manage up 64 IP cameras and their recordings with your Computer."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Desktop_Software/InstarVision_for_macOS/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Desktop_Software/Instar_Camera_Tool/">
                  <StaticImage
                    src='../../../images/Search/D_Downloads_SearchThumb.webp'
                    alt="Instar Camera Tool"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Instar Camera Tool"
              description="The INSTAR Camera Tool to search for your camera inside your network. Change the IP configuration, update software and access it´s SD card."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Desktop_Software/Instar_Camera_Tool/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Desktop_Software/InstarVision_Metro/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_InstarVision_Metro.webp'
                    alt="InstarVision (Metro)"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="InstarVision (Metro)"
              description="Unsere kostenlose Windows Metro App für Windows 8/8.1 und Windows 10. Nutzen Sie die Leistungsfähigkeit unserer Windows Phone App auf Ihrem Windows Desktop Computer."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Desktop_Software/InstarVision_Metro/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
