import React from 'react'

import {
  EuiSpacer
} from '@elastic/eui'

import MemoryGraph from "./memory-load-graph"
import MemoryGraphMulti from "./memory-load-graph-multi"

function ProcessorCard() {
  return (
    <div>
      <h3>Memory Load</h3>
        <MemoryGraph />
        <EuiSpacer />
        <MemoryGraphMulti />
        <EuiSpacer />
        <div>
          <p><strong>Setup</strong>: For the 4-camera test, only HD cameras were used. For the 8 cameras we added 1 VGA and 1 FHD camera. The 16-camera setup used 12 HD, 2 VGA and 2 FHD cameras.</p>
        </div>
    </div>
  );
}

export default ProcessorCard