import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="ckKuHQFU-Pc"
                    title="IN-9008 FHD Unboxing"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="IN-9008 FHD Unboxing"
              footer={
                <EuiText>
                  <p>In diesem Video zeigen wir Ihnen die neue INSTAR FUll HD Kamera (Modell IN-9008). Die einzigartige neue Kamera kommt ohne Anschlusskabel und man hat so die Möglichkeit nur die Kabel ins innere der Kamera zu führen die man auch wirklich benötigt. Zudem kommt die Kamera mit einem sehr sehr hochwertigen Panasonic WDR Bildsensor als auch einem eingebauten Panasonic PIR Wärmesensor. Es ist zudem ein Mikrofon bereits eingebaut. Diese hardwareseitigen Features machen die IN-9008 Full HD zu einer der besten Überwachungskameras die es aktuell auf dem Markt gibt. Überzeugen Sie sich jetzt selbst und bestellen Sie Ihre IN-9008 Full HD direkt bei INSTAR.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
