import React from 'react'

import {
  EuiCard,
  EuiIcon,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


export default function APIGlossar() {

  return (
    <div style={{ padding: 15 }}>
      <EuiFlexGroup gutterSize="m">
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon size="xxl" type="function" />}
            title="Camera Parameter"
            description=""
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon size="xxl" type="filebeatApp" />}
            title="Parameter Description"
            description=""
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon size="xxl" type="consoleApp" />}
            title="CGI Command"
            description=""
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon size="xxl" type="gisApp" />}
            title="Value Range"
            description=""
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon size="xxl" type="graphApp" />}
            title="MQTT Topic"
            description=""
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}