import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="xnzk7EidhxU"
                    title="Filezilla FTP server"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="Filezilla FTP server"
              footer={
                <EuiText>
                  <p>Dieses Video beschreibt Ihnen mehrere Punkte:</p>
                  <ol>
                    <li>Wir zeigen Ihnen wie Sie unter Windows Filezilla Server installieren, also einen eigenen FTP Server erstellen auf den die Kamera dann Ihre Aufnahmen übertragen kann.</li>
                    <li>Wir zeigen Sie wie und wo Sie die Daten in der Kamera eintragen müssen</li>
                    <li>Mit der automatischen Aufgabenplanung von Windows lassen wir Dateien automatisch löschen die älter als X Tage sind</li>
                  </ol>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
