import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="fYrV-vr8zbs"
                    title="Firmware Restore - Windows 7, 8, 10 Preparation"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="Windows 7, 8, 10 Preparation"
              footer={
                <EuiText>
                  <p>In diesem Video erklären wir Ihnen wie Sie eine serielle Verbindung zu Ihrer INSTAR HD IP Kamera herstellen können unter Windows 7 / 8 / 10. Dazu benötigen Sie das Programm Tera Term, dieses finden Sie in der aktuellsten Version hier: [Tera Term](https://osdn.net/projects/ttssh2/releases/). </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}