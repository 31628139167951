import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "numberColumn", title: "#" },
        { name: "componentColumn", title: "Component" },
        { name: "functionColumn", title: "Function" },
      ],
      rows: [
        { numberColumn: '01', componentColumn: 'Wall Bracket', functionColumn: 'Wall Bracket' },
        { numberColumn: '02', componentColumn: 'Ceiling Bracket', functionColumn: 'Ceiling Bracket' },
        { numberColumn: '03', componentColumn: 'Antenna Connector', functionColumn: 'Antenna Connector' },
        { numberColumn: '04', componentColumn: 'Infrared LED', functionColumn: 'Infrared LED' },
        { numberColumn: '05', componentColumn: 'Autofocus Lense', functionColumn: 'Autofocus Lense' },
        { numberColumn: '06', componentColumn: 'Photo Sensor', functionColumn: 'Photo Sensor' },
        { numberColumn: '07', componentColumn: 'Microphone', functionColumn: 'Microphone' },
        { numberColumn: '08', componentColumn: 'MicroSD card slot', functionColumn: 'MicroSD card slot' },
        { numberColumn: '09', componentColumn: 'Network LED', functionColumn: 'Network LED' },
        { numberColumn: '10', componentColumn: 'Alarm Input', functionColumn: 'Alarm Input' },
        { numberColumn: '11', componentColumn: 'Network Port', functionColumn: 'Network Port' },
        { numberColumn: '12', componentColumn: 'Power Connector', functionColumn: 'Power Connector' },
        { numberColumn: '13', componentColumn: 'Extension Cable', functionColumn: 'Extension Cable' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)



// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'

// const numberRow = [
//   '01',
//   '02',
//   '03',
//   '04',
//   '05',
//   '06',
//   '07',
//   '08',
//   '09',
//   '10',
//   '11',
//   '12',
//   '13'
// ];

// const componentRow = [
//   'Wall Bracket',
//   'Ceiling Bracket',
//   'Antenna Connector',
//   'Infrared LED',
//   'Autofocus Lense',
//   'Photo Sensor',
//   'Microphone',
//   'MicroSD card slot',
//   'Network LED',
//   'Alarm Input',
//   'Network Port',
//   'Power Connector',
//   'Extension Cable'
// ];

// const functionRow = [
//   'Wall mounting',
//   'Overhead installation',
//   'RP-SMA WiFi antenna connector (not for PoE version)',
//   '6 high-powered IR LEDs (nightvision @ 850nm)',
//   'Varied focus with autofocus optical lens (2.8mm ~ 12mm)',
//   '12V / 2A dc - Plug Ø 2.1mm in / 5.5mm (out)',
//   'Twilight switch for IR LEDs',
//   'For noise detection / audio rec.',
//   'Slot for MicroSD/SDXC cards (max.128GB - 16GB included)',
//   'Indicator for network status - On: connected to network / Off: No power or no network',
//   'Supports alarm output for external sensors',
//   'RJ45 LAN port / connector for Cat5e LAN cable or higher',
//   'For included 12V / 2A power supply',
//   'RP-SMA WiFi Antenna Connector (not for PoE version)'
// ];

// // Render your table
// const FeatureTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={numberRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>#</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {numberRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={50}
//         />
//         <Column
//           header={<Cell>Component</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {componentRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={2}
//           width={250}
//         />
//         <Column
//           header={<Cell>Function</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {functionRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={3}
//           width={535}
//         />
//       </Table>
//     )
// }

// export default FeatureTable