import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="82qRXMxHf4c"
                    title="Synology Disk Station als FTP Server"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="Synology Disk Station als FTP Server"
              footer={
                <EuiText>
                <p>In diesem Video zeigen wir Ihnen wie Sie die Synology Disk Station als FTP Server einrichten können um so die Videoaufnahmen Ihrer INSTAR HD Kamera auf diese übertragen zu lassen.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}



// import React from 'react'

// import { Player } from 'video-react'

// import {
//     EuiText,
//     EuiIcon,
//     EuiPanel
//   } from '@elastic/eui'

// import { OutboundLink } from 'gatsby-plugin-gtag'

// require('../../../Layout/chrome/assets/video.css')

// function VideoCard() {
//   return (
//     <EuiPanel paddingSize="m">
//         <Player>
//         <source src="https://wiki.instar.com/videos/Synology_Disk_Station_als_FTP_Server_einrichten.mp4" />
//         </Player>
//         <EuiText>
//             <p>In diesem Video zeigen wir Ihnen wie Sie die Synology Disk Station als FTP Server einrichten können um so die Videoaufnahmen Ihrer INSTAR HD Kamera auf diese übertragen zu lassen. Auf <OutboundLink href="https://www.youtube.com/watch?v=82qRXMxHf4c" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
//         </EuiText>
//     </EuiPanel>
//   );
// }

// export default VideoCard