import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/How_does_an_IP_Camera_Work/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_HowDoesAnIPCameraWork.webp'
                    alt="How does an IP Camera Work?"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="How does an IP-Camera work?"
              description="An IP camera is a complex product. However, it is not complicated to operate a INSTAR product. In the following we want to give you an introduction to the basic functions of an IP camera. For more in…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/How_does_an_IP_Camera_Work/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/After_Unpacking/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_AfterUnpackingYourIPCamera.webp'
                    alt="After Unpacking"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="After Unpacking"
              description="Table of Content Installation Wizard Unboxing Lense Adjustment Heater Installation Connection Cable Removal or Extension SD Card Upgrade Unboxing Lense Adjustment Further information can be found in …"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/After_Unpacking/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/First_Steps/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_FirstStepsWithYourIPCamera.webp'
                    alt="First Steps"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="First Steps Installing your Camera"
              description="In the following, we will give you a short introduction to the basic functions of our IP-cameras and the first steps of installation. We will discuss common problems and answer frequently asked quest…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/First_Steps/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Power_over_Ethernet/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_PowerOverEthernet.webp'
                    alt="Power over Ethernet"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Power over Ethernet"
              description="PoE Requirements Power over Ethernet or PoE describes systems which pass electric power along with data on twisted pair Ethernet cabling. This allows a single cable to provide both data connection an…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Power_over_Ethernet/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Powerline/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_Powerline.webp'
                    alt="IN-LAN Powerline"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-LAN Powerline"
              description="Network over your Power Grid Installation IN-LAN is an intelligent and secure technology that lets you set up a home network easily via your household power grid - without the need of complex and exp…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Powerline/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Direct_LAN_Connection/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_DirectConnectionWithYourPC.webp'
                    alt="Direct LAN Connection"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Direct LAN Connection"
              description="How to connect your camera directly with your computer via an Ethernet cable? The following instructions will explain to you step by step how you can create a direct LAN connection between your compu…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Direct_LAN_Connection/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Alternative_IP_Scanner/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_AlternativeIPScanner_eng.webp'
                    alt="IP Camera Search"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP Camera Search Tools"
              description="INSTAR Camera Tool The easiest way to search our camera inside your local network is our INSTAR Camera Tool for Windows, macOS and LINUX. Accessing your Camera´s SD Card Alternative IP Scanner Assig…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Alternative_IP_Scanner/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Language_Selection/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_LanguageSettings.webp'
                    alt="Language Selection"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Language Selection"
              description="How to set the Language in the Web User Interface? The new INSTAR web interface for your IP camera detects automatically what default language your web browser is using. By default, the web interface…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Language_Selection/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Live_Video/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_LiveVideo.webp'
                    alt="Zeigen Sie das Video Ihrer Kameras in Ihrem Browser an"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Display your Cameras Video inside your Browser"
              description="HTML5 Video Starting with version 2.6 of the web interface, all Full HD camera models support HTML5 Video for plugin-free playback of the video stream. This feature is enabled by default and can be s…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Live_Video/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Creating_User_Accounts/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_CreatingUserAccounts.webp'
                    alt="Creating User Accounts"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="User Management on your Camera"
              description="Secure the Access to your IP Camera In order to access the user menu please click System in the web interface of your IP camera and then choose the sub menu Users as shown below. We always recommend …"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Creating_User_Accounts/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/WebUI_And_Firmware_Upgrade/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_WebUIFirmwareUpgrade.webp'
                    alt="Software Update"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Software Update"
              description="WebUI and Firmware Upgrade 1080p Camera Series Install the newest Firmware and Web User Interface on your IP Camera When a new update becomes available go to System / Update and you will be prompte…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/WebUI_And_Firmware_Upgrade/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_ClearBrowsingHistory.webp'
                    alt="How To Clear Your Browsing History"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Erase your Browsing History"
              description="Sometimes your camera´s WebUI might not behave the way you want. You updated your camera´s software, but it still shows you the old version? An update should bring new functions to the user interface…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          {/* <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/ONVIF/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_ONVIF.webp'
                    alt="ONVIF"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="ONVIF"
              description="All current INSTAR 1080p IP cameras are compliant with the ONVIF Profile S Version 2.4 Software Integration The 720p Series now also supports the ONVIF Profile S Version 2.4 The surveillance industry…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/ONVIF/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem> */}
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_WLAN.webp'
                    alt="Wireless Protected Setup"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Wireless Protected Setup"
              description="Connect your IP Camera directly to your local WiFi If your WLAN router has the WPS function, you can simply connect your camera to your WLAN at the push of a button. The following explains the proces…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Internet_Protocol_IPv6/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_DirectConnectionWithYourPC.webp'
                    alt="Internet Protokoll Version 6 (IPv6)"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Internet Protokoll Version 6 (IPv6)"
              description="In this article, we would like to explain IPv6, the latest version of the Internet protocol, and how SLAAC (Stateless Address Autoconfiguration) works, which we have integrated into our INSTAR 2K+ cameras..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Internet_Protocol_IPv6/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
