import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import IPv6ForwardingVideo from 'components/Frequently_Asked_Question/WQHD_IPv6_Portforwarding/IPv6ForwardingVideo';
import * as React from 'react';
export default {
  EuiSpacer,
  BreadCrumbs,
  SEOHelmet,
  IPv6ForwardingVideo,
  React
};