import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PTZTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Pan, Tilt & Zoom" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Activate adjustment movement', descriptionColumn: 'Activate or deactivate the camera PTZ calibration run at boot-up. Please be aware, that the [Saved Positions](/Web_User_Interface/1080p_Series/Live_Video/) for your camera will no longer be available, when the calibration is deactivated.' },
        { actionColumn: 'Start position 1', descriptionColumn: 'The camera moves to the centre position after the calibration by default. Check this box, if you want it to move to the Saved Position 1 instead. Only available when the camera calibration is active!' },
        { actionColumn: 'Activate alarm position', descriptionColumn: 'Activate the alarm position, if you want your camera to move to a specific saved position once the alarm was triggered. The is especially interesting, if you use an external sensor, like the IN-MOTION 500, or the internal Audio Alarm. Let your camera keep an eye on one side and secondary sensor on the other side. Only available when the camera calibration is active!' },
        { actionColumn: 'Select alarm position', descriptionColumn: 'Set the alarm position here (see above).' },
        { actionColumn: 'Move to park position automatically', descriptionColumn: 'Do you want your camera to always point in a specific direction by default? Activate the park position to let your camera go back to this position automatically after it was moved.' },
        { actionColumn: 'Select park position', descriptionColumn: 'Set the park position here (see above).' },
        { actionColumn: 'Move after X Seconds', descriptionColumn: 'Your camera will wait for x seconds before returning to its park position (see above).' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PTZTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(PTZTable)