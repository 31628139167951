import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class WebUITable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Account Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Username', descriptionColumn: 'Username for your active account.' },
        { actionColumn: 'Password', descriptionColumn: 'Password for your active account.' },
        { actionColumn: 'Confirm Password', descriptionColumn: 'Repeat the password for your active account.' },
        { actionColumn: 'Login Expiration', descriptionColumn: 'When logging in to your account a login web token is generated. As long as you do not delete your browser history this token will be valid until it`s expiration set here.' },
        { actionColumn: 'Language', descriptionColumn: 'Your preferred language used by the user interface.' },
        { actionColumn: 'Email Address', descriptionColumn: 'Email address you want to use as a second factor for authentication. When trying to authenticate yourself with the web user interface an email will be send to this address to verify that it is you that is trying to gain access. This email address is also used for the "Forgot Password" function and allows you to recover access to your camera.' },
        { actionColumn: 'Two-factor authentication (2FA)', descriptionColumn: 'Enable the two-factor authentication.' },
        { actionColumn: 'Security question has been set', descriptionColumn: 'Here you can set a question and the secret answer. Your camera will require this answer for you to recover your password.' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

WebUITable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(WebUITable)