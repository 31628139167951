import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/System/Overview/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_System_Overview.png'
                    alt="INSTAR 1440p Web User Interface System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Info"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Series/System/Overview/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/System/User/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_System_User.png'
                    alt="INSTAR 1440p Web User Interface System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="User"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Series/System/User/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/System/WebUI/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_System_WebUI.jpg.png'
                    alt="INSTAR 1440p Web User Interface System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="WebUI"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Series/System/WebUI/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/System/Date_Time/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_System_Date_Time.png'
                    alt="INSTAR 1440p Web User Interface System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Date & Time"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Series/System/Date_Time/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/System/Language/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_System_Language.png'
                    alt="INSTAR 1440p Web User Interface System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Language"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Series/System/Language/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/System/Log/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_System_Log.webp'
                    alt="INSTAR 1440p Web User Interface System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="System Log"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Series/System/Log/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/System/Update/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_System_Update.png'
                    alt="INSTAR 1440p Web User Interface System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Software Update"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Series/System/Update/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/System/Reboot/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_System_Reboot.png'
                    alt="INSTAR 1440p Web User Interface System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Reboot"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Series/System/Reboot/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/System/Reset/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_System_Reset.png'
                    alt="INSTAR 1440p Web User Interface System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Reset"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Series/System/Reset/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/System/My_Account/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_System_User.png'
                    alt="INSTAR 1440p Web User Interface System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Reset"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Series/System/My_Account/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
