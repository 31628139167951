import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/System/Reset/SuccessBox';
import ResetTable from 'components/Web_User_Interface/1080p_Series/System/Reset/resetTable';
import PrimaryBox from 'components/Web_User_Interface/1080p_Series/System/Reset/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  SuccessBox,
  ResetTable,
  PrimaryBox,
  BreadCrumbs,
  SEOHelmet,
  React
};