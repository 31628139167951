import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/System/Language/SuccessBox';
import LangTable from 'components/Web_User_Interface/1080p_Series/System/Language/langTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  SuccessBox,
  LangTable,
  BreadCrumbs,
  SEOHelmet,
  React
};