import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Date & Time">
        <EuiText>
          <p>
            Your camera will automatically contact a so-called NTP Server to set the internal clock. It is recommended to leave this feature on at all times. But if your camera cannot connect to the internet, you can synchronize the clock with your computer. But be aware that this has to be done regularly to keep your camera on-time.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

