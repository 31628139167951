import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Triggers & Actions',
      href: '/en/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/',
    },
    {
      text: 'Applets',
      href: '/en/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Introduction/',
    },
    {
      text: 'Create Applets',
      href: '/en/Motion_Detection/INSTAR_Cloud/IFTTT/User_Guide/How_to_create_own_Applets/',
    },
    {
      text: 'INSTAR Applets',
      href: '/en/Motion_Detection/INSTAR_Cloud/IFTTT/User_Guide/How_to_use_INSTAR_Applets/',
    },
    {
      text: 'P2P Login',
      href: '/en/Motion_Detection/INSTAR_Cloud/IFTTT/User_Guide/How_to_add_P2P_Credentials/',
    },
    {
      text: '◄ IFTTT',
      href: '/en/Motion_Detection/INSTAR_Cloud/IFTTT/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={6}
        aria-label="INSTAR IFTTT"
      />
      <EuiSpacer />
    </Fragment>
  );
}