import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="VERY IMPORTANT!">
        <EuiText>
            <p>For VGA Cameras! Please make sure that you are selecting the correct firmware version. You can confirm your firmware version via the web user interface (system/info) or with the INSTAR camera tool. If you are currently using the firmware version starting with xx.25 or xx.22 please make sure you are only downloading updated versions that also start with xx.25 or xx.22. Never install the versions xx.35. or xx.37.!!! If your firmware is currently starting with xx.35 or xx.37 please make sure that you are using the firmware updates starting with xx.35 or xx.37. Always update the Firmware first before updating the web user interface.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

