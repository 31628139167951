import React from 'react'

import 'react-vis/dist/style.css'

import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
  Crosshair
} from 'react-vis'

const DATA = [
  [
    // Bitrate: 5120 kbps
    { x: 'Nat. Licht (Aussen)', y: 0.624378333333 },
    { x: 'Nat. Licht (Innen)', y: 0.57303585 },
    { x: 'Kunstlicht (Aussen)', y: 0.65888944 },
    { x: 'Kunstlicht (Innen)', y: 0.1715098 },
    { x: 'IR Nacht (Aussen)', y: 0.638223481481 },
    { x: 'IR Nacht (Innen)', y: 0.647481571429 },
  ],
  [
    // Bitrate: 2560 kbps
    { x: 'Nat. Licht (Aussen)', y: 0.33135724 },
    { x: 'Nat. Licht (Innen)', y: 0.30666668 },
    { x: 'Kunstlicht (Aussen)', y: 0.332033521739 },
    { x: 'Kunstlicht (Innen)', y: 0.18679756 },
    { x: 'IR Nacht (Aussen)', y: 0.323479322581 },
    { x: 'IR Nacht (Innen)', y: 0.31767915625 },
  ],
  [
    // Bitrate: 2240 kbps
    { x: 'Nat. Licht (Aussen)', y: 0.29135724 },
    { x: 'Nat. Licht (Innen)', y: 0.25520752 },
    { x: 'Kunstlicht (Aussen)', y: 0.29299624 },
    { x: 'Kunstlicht (Innen)', y: 0.1703016 },
    { x: 'IR Nacht (Aussen)', y: 0.289525965517 },
    { x: 'IR Nacht (Innen)', y: 0.270867461539 },
  ],
  [
    // Bitrate: 1920 kbps
    { x: 'Nat. Licht (Aussen)', y: 0.25135724 },
    { x: 'Nat. Licht (Innen)', y: 0.253080448276 },
    { x: 'Kunstlicht (Aussen)', y: 0.253024826087 },
    { x: 'Kunstlicht (Innen)', y: 0.17903336 },
    { x: 'IR Nacht (Aussen)', y: 0.252927 },
    { x: 'IR Nacht (Innen)', y: 0.24398316129 },
  ],
  [
    // Bitrate: 1600 kbps
    { x: 'Nat. Licht (Aussen)', y: 0.20135724 },
    { x: 'Nat. Licht (Innen)', y: 0.217444875 },
    { x: 'Kunstlicht (Aussen)', y: 0.20606084 },
    { x: 'Kunstlicht (Innen)', y: 0.1802152 },
    { x: 'IR Nacht (Aussen)', y: 0.199995911765 },
    { x: 'IR Nacht (Innen)', y: 0.210260074074 },
  ],
  [
    // Bitrate: 1280 kbps
    { x: 'Nat. Licht (Aussen)', y: 0.160060238095 },
    { x: 'Nat. Licht (Innen)', y: 0.168938275862 },
    { x: 'Kunstlicht (Aussen)', y: 0.16003204 },
    { x: 'Kunstlicht (Innen)', y: 0.15439832 },
    { x: 'IR Nacht (Aussen)', y: 0.172109233333 },
    { x: 'IR Nacht (Innen)', y: 0.166018037037 },
  ],
  [
    // Bitrate: 640 kbps
    { x: 'Nat. Licht (Aussen)', y: 0.0872715384616 },
    { x: 'Nat. Licht (Innen)', y: 0.0853984615385 },
    { x: 'Kunstlicht (Aussen)', y: 0.0855082758621 },
    { x: 'Kunstlicht (Innen)', y: 0.0839728275862 },
    { x: 'IR Nacht (Aussen)', y: 0.0885503636364 },
    { x: 'IR Nacht (Innen)', y: 0.0846445862069 },
  ],
  [
    // Bitrate: 320 kbps
    { x: 'Nat. Licht (Aussen)', y: 0.0505638571429 },
    { x: 'Nat. Licht (Innen)', y: 0.0491648 },
    { x: 'Kunstlicht (Aussen)', y: 0.049716037037 },
    { x: 'Kunstlicht (Innen)', y: 0.0440745185185 },
    { x: 'IR Nacht (Aussen)', y: 0.0481415333333 },
    { x: 'IR Nacht (Innen)', y: 0.049289 },
  ]
];

export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: []
    };
  }
  render() {
    return (
        <React.Fragment>

          <div className="chart">
            <FlexibleWidthXYPlot
              onMouseLeave={() => this.setState({ crosshairValues: [] })}
              height={300}
              xType="ordinal"
              animation={true}
              stackBy="y">
              
              <VerticalGridLines />
              <HorizontalGridLines />
              <XAxis />
              {/* <YAxis tickFormat={v => `${v} [MB/s]`} /> */}
              <YAxis tickFormat={v => `${v}`} />

              <Crosshair values={this.state.crosshairValues} />

              <VerticalBarSeries
                onNearestX={(value, { index }) =>
                  this.setState({ crosshairValues: DATA.map(d => d[index]) })}
                cluster="Bitrate: 5120 kbps"
                color="#FF0000"
                data={DATA[0]}
              />

              <VerticalBarSeries
                cluster="Bitrate: 2560 kbps"
                color="#FF6600"
                data={DATA[1]}
              />

              <VerticalBarSeries
                cluster="Bitrate: 2240 kbps"
                color="#FF9900"
                data={DATA[2]}
              />

              <VerticalBarSeries
                cluster="Bitrate: 1920 kbps"
                color="#FFFF00"
                data={DATA[3]}
              />

              <VerticalBarSeries
                cluster="Bitrate: 1600 kbps"
                color="#66FF00"
                data={DATA[4]}
              />

              <VerticalBarSeries
                cluster="Bitrate: 1280 kbps"
                color="#00FFCC"
                data={DATA[5]}
              />

              <VerticalBarSeries
                cluster="Bitrate: 640 kbps"
                color="#0099FF"
                data={DATA[6]}
              />

              <VerticalBarSeries
                cluster="Bitrate: 320 kbps"
                color="#0033FF"
                data={DATA[7]}
              />

            </FlexibleWidthXYPlot>
          </div>

          <div className="legend">
            <DiscreteColorLegend
              orientation="horizontal"
              items={[
                {
                  title: '[0] 5120 kbps',
                  color: '#FF0000'
                },
                {
                  title: '[1] 2560 kbps',
                  color: '#FF6600'
                },
                {
                  title: '[2] 2240 kbps',
                  color: '#FF9900'
                },
                {
                  title: '[3] 1920 kbps',
                  color: '#FFFF00'
                },
                {
                  title: '[4] 1600 kbps',
                  color: '#66FF00'
                },
                {
                  title: '[5] 1280 kbps',
                  color: '#00FFCC'
                },
                {
                  title: '[6] 640 kbps',
                  color: '#0099FF'
                },
                {
                  title: '[7] 320 kbps',
                  color: '#0033FF'
                }
              ]}
            />
          </div>

        </React.Fragment>
    );
  }
}