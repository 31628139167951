import React from 'react'

import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="INSTAR MQTT Broker">
        <EuiText>
          <p><strong>Only for Full HD Camera Models</strong></p>
          <p>If you are using an INSTAR Full HD camera also check out our <strong><Link to="/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/">MQTT Broker Integration into Home Assistant</Link></strong>. The MQTT Interface enables you to access all camera functions through the native MQTT support from the Home Assistant Lovelace UI!</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

