import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PTZTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Pan, Tilt & Zoom" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Avoid alarms while moving', descriptionColumn: 'In most cases it is unwanted, that the pan & tilt movement of your camera causes a [Motion Detection](/Web_User_Interface/720p_Series/Alarm/Areas/). But if you want to be notified whenever your camera is moved, just check this box.' },
        { actionColumn: 'Activate step-by-step movement', descriptionColumn: 'With the default settings, your camera will move in one direction as long as you press the arrow button on the PTZ Control Pad. When your connection to the camera is plagued with high latencies, it might happen, that the stop command is lost and your camera moves all the way to the end of range. You can use the one-step motion to prevent this from happening. Your camera will only move one step with every click on the control pad - giving you the highest amount of precision.' },
        { actionColumn: 'Horizontal speed', descriptionColumn: 'Set the velocity of the horizontal motion of your camera.' },
        { actionColumn: 'Tilt speed', descriptionColumn: 'Set the velocity of the vertical motion of your camera.' },
        { actionColumn: 'Number of VSCAN rounds', descriptionColumn: 'Set the amount of vertical runs your camera should perform once you start the Function VSCAN.' },
        { actionColumn: 'Number of HSCAN rounds', descriptionColumn: 'Set the amount of horizontal runs your camera should perform once you start the HSCAN Function.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PTZTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(PTZTable)