import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import angryIP from "../../../images/Quick_Installation/Alternative_IP_Scanner/angryIP.webp"
import fingCLI from "../../../images/Quick_Installation/Alternative_IP_Scanner/fingCLI.webp"
import fingAndroid from "../../../images/Quick_Installation/Alternative_IP_Scanner/fingAndroid.webp"


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Alternative_IP_Scanner/Angry_IP_Scanner/">
                  <img
                    src={angryIP}
                    alt="Angry IP Scanner"
                  />
                </Link>
              }
              title='Angry IP Scanner'
              description={
                <p>Angry IP scanner is a very fast open-source IP address and port scanner. It is available for Windows, macOSX as well as Linux. <Link to="/Quick_Installation/Alternative_IP_Scanner/Angry_IP_Scanner/">more...</Link></p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Alternative_IP_Scanner/Fing_CLI/">
                  <img
                    src={fingCLI}
                    alt="Fing CLI"
                  />
                </Link>
              }
              title='Fing CLI'
              description={
                <p>Fing CLI is an easy-to-use command line tool to keep an eye on your local IP network. The software is available for Windows, macOS and LINUX. <Link to="/Quick_Installation/Alternative_IP_Scanner/Fing_CLI/">more...</Link></p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Alternative_IP_Scanner/Fing_Mobile/">
                  <img
                    src={fingAndroid}
                    alt="Android Fing IP Scanner"
                  />
                </Link>
              }
              title='Android Fing IP Scanner'
              description={
                <p>Angry IP scanner is a very fast open-source IP address and port scanner. It is available for Windows, macOSX as well as Linux. <Link to="/Quick_Installation/Alternative_IP_Scanner/Fing_Mobile/">more...</Link></p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
