import { Link } from "gatsby";
import { EuiButton, EuiSpacer } from "@elastic/eui";
import Flyout from "components/Software/macOS/InstarVision/System_Requirements/sys-req_flyout";
import Table01 from "components/Software/macOS/InstarVision/Table01";
import ImageCard01 from "components/Software/macOS/InstarVision/instarVisionHorizontalCard01";
import ImageCard02 from "components/Software/macOS/InstarVision/instarVisionHorizontalCard02";
import ImageCard03 from "components/Software/macOS/InstarVision/instarVisionHorizontalCard03";
import MenuCard01 from "components/Software/macOS/InstarVision/instarVisionMenuCards01";
import MenuCard02 from "components/Software/macOS/InstarVision/instarVisionMenuCards02";
import MenuCard03 from "components/Software/macOS/InstarVision/instarVisionMenuCards03";
import NavButtons from "components/Software/Windows/NavButtons";
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
import * as React from 'react';
export default {
  Link,
  EuiButton,
  EuiSpacer,
  Flyout,
  Table01,
  ImageCard01,
  ImageCard02,
  ImageCard03,
  MenuCard01,
  MenuCard02,
  MenuCard03,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};