import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="h.265 Video Playback in Microsoft Edge">
        <EuiText>
            <p>The HEVC/h.256 support is currently still in beta in Microsoft Edge (Chromium) and needs to be activated manually. To access the configuration page type the following into your browsers address bar <code>edge://flags</code> and set the flag <code>PlayReady Experimental HEVC Decoding</code> to <code>true</code>. Additionally, you might have to install the <Link to="https://apps.microsoft.com/store/detail/hevc-video-extensions-from-device-manufacturer/9N4WGH0Z6VHQ?hl=en-us&gl=US">Microsoft HEVC Video Extension</Link> through the Microsoft Store.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox
