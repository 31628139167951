import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


import ivImage01 from '../../../../../../images/Software/Windows/InstarVision/Record/General/Corrupted_video_file.webp'
import ivImage02 from '../../../../../../images/Software/Windows/InstarVision/Record/General/Corrupted_video_file_01.webp'


export default function ImageCard() {

  return (
    <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage01}
                alt="InstarVision v2 for Windows"
              />
            </div>
          }
        />
        </EuiFlexItem>
        <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage02}
                alt="InstarVision v2 for Windows"
              />
            </div>
          }
        />
        </EuiFlexItem>
    </EuiFlexGroup>
  );
}
