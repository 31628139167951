import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Achtung!">
        <EuiText>
          <p>Bei Internetanschlüssen in Verbindung mit Unitymedia kann es vorkommen, dass keine öffentliche IPv4-Adresse vergeben wird. Diese ist allerdigns nötig, um per DDNS auf die Kamera zugreifen zu können. Um dies zu überprüfen, gehen Sie im Router zu <strong>Erweiterte Einstellungen → Tools → Netzwerkstatus</strong>. Dort können Sie ablesen, ob Sie eine Ipv4-Adresse haben oder nur eine IPv6-Adresse, bzw. DS-Lite-Tunnel. In der Regel erhalten Sie eine öffentliche IPv4-Adresse vom Anbieter, wenn Sie nachfragen.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

