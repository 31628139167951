import React from 'react'
import 'react-vis/dist/style.css'
import {
  RadarChart,
  makeWidthFlexible
} from 'react-vis'

const DATA = [
  { name: 'IN5907', nightvision: 25, megapixel: 1, power: 13, focal: 8, irleds: 3 },
  { name: 'IN2905', nightvision: 15, megapixel: 0.3, power: 5.8, focal: 6, irleds: 24 }
]

const FlexibleRadarChart = makeWidthFlexible(RadarChart)

export default class IN8015RadarIN6012 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: []
    };
  }

  render() {
    return (
      <React.Fragment>

        <div className="chart">
          <FlexibleRadarChart
            data={DATA}
            startingAngle={0}
            domains={[
              { name: 'focal', domain: [5, 8] },
              { name: 'megapixel', domain: [0, 1] },
              { name: 'power', domain: [5, 13] },
              { name: 'nightvision', domain: [14, 25] },
              { name: 'irleds', domain: [2, 24] }
            ]}
            margin={{
              left: 50,
              right: 50
            }}
            width={400}
            height={275} />
        </div>

      </React.Fragment>
    );
  }
}