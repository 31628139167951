import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Updates:">
        <EuiText>
          <ul>
            <li><a href="/Frequently_Asked_Question/ioBroker_as_MQTT_Broker_for_your_Camera/">Verwenden Sie ioBroker als Ihren MQTT-Broker anstelle eines MQTT-Clients</a></li>
            <li><a href="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_ioBroker/">Verwenden Sie den INSTAR MQTT Alarmserver mit ioBroker</a></li>
            <li><a href="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_Motion_Tracking/">Verwenden Sie den INSTAR MQTT Alarmserver zum Erstellen einer einfachen Objektverfolgung</a></li>
            <li><a href="/Frequently_Asked_Question/ioBroker_MQTT_Overwrites_Camera_Settings/">Aktivieren Sie NICHT! das Aktualisieren von Topics, wenn sich Clients mit Ihrem Broker verbinden</a></li>
            <li><a href="/Frequently_Asked_Question/ioBroker_without_MQTT/">Hinzufügen von Kameras, die MQTT nicht unterstützen</a></li>
            <li><a href="/Frequently_Asked_Question/ioBroker_and_INSTAR_Alarmserver/">Verwendung des HTTP-Alarmservers anstelle des MQTT-Alarmservers</a></li>
            <li><a href="/Frequently_Asked_Question/ioBroker_INSTAR_Camera_Vis_with_Node-RED/">Verwendung des Node-RED Adapters zur Erstellung einer Visualisierung</a></li>
            <li><a href="/Frequently_Asked_Question/Live_Video_and_ioBroker_VIS/">Eine intelligentere Möglichkeit, der ioBroker Vis das Live-Video Ihrer Kameras hinzuzufügen</a></li>
          </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

