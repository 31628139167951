import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class DateTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Date & Time Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Date & Time from your Camera', descriptionColumn: 'Internal time set inside your camera´s operating system. This time will be used for all alarm and recording schedules, as well as the time stamp used for file names.' },
        { actionColumn: 'Synchronize with Computer Time', descriptionColumn: 'If your camera does not have access to the internet, you can use this function to synchronize your computer time setting with your camera. This is not recommended, since you will have to repeat this process regularly to keep the internal clock accurate.' },
        { actionColumn: 'Date & Time from Computer', descriptionColumn: 'This is the time, that your PC is set to. The function above will synchronize this with the internal clock of your camera.' },
        { actionColumn: 'Synchronize with NTP Server', descriptionColumn: 'This function synchronizes the internal clock of your camera with an internet time server. It is recommended to use this to keep the camera time accurate.' },
        { actionColumn: 'Time zone', descriptionColumn: 'Here you can choose the time zone in which the camera is situated - in case of Germany, this would be GMT+1.' },
        { actionColumn: 'Activate Summer Time', descriptionColumn: 'Activate or deactivate Daylight-Saving Time - please be aware, that switched on, this setting will not automatically be deactivated in winter!' },
        { actionColumn: 'NTP Server', descriptionColumn: 'If you run into problems with the automatic synchronization, you can try a different internet time server from this list. Closed company networks might need to set an internal NTP server - this can be done with a CGI Command.' },
        { actionColumn: 'NTP Update Interval', descriptionColumn: 'Set the interval you want your camera to use for the time synchronization.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

DateTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(DateTable)