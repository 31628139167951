import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class NetTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Network Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'IP Address', descriptionColumn: 'This is the IPv4 address of your camera inside your local network. To access your camera´s web user interface, simply type in the IP address into the address bar of web browser. If your camera´s LAN port is not "80", please add the port to the IP address separated by a : , like 192.168.1.125:8081.' },
        { actionColumn: 'Subnetmask', descriptionColumn: 'The subnet mask defines the subnet your camera is connected to. It is 255.255.255.0 in most small networks.' },
        { actionColumn: 'Gateway', descriptionColumn: 'The gateway is the connection between your local network and the internet. In most small networks it is the IP address of your router - e.g. 192.168.1.1, 192.168.2.1 or 192.168.178.' },
        { actionColumn: 'DNS Server', descriptionColumn: 'The DNS server is needed by your camera to resolve internet addresses. In most small networks it is the IP address of your router - e.g. 192.168.1.1, 192.168.2.1 or 192.168.178.1.' },
        { actionColumn: 'LAN Port', descriptionColumn: 'The LAN Port is the HTTP Port of your camera. Add this port to your camera´s IP address, if you want to access the web user interface with your web browser. This is also the port that you need to forward inside your router, to access your camera via it´s DDNS Address. We recommend that you use a HTTP port that is higher than 8080.' },
        { actionColumn: 'RTMP (Flash) Port', descriptionColumn: 'The RTMP Port is the video streaming port for the Flash Video Plugin. Please forward this port if you want to access the web user interface Over the Internet. You __do not need__ to forward the port to access your camera over our smartphone app!' },
        { actionColumn: 'RTSP Authentication active', descriptionColumn: 'If you want to use a streaming software like VLC Player to access the RTSP Stream,  you have the option to deactivate the need to authenticate. The stream will become freely available on your network. Please make sure that authentication is active before forwarding the RTSP port!' },
        { actionColumn: 'RTSP Port', descriptionColumn: 'The RTSP STREAM of your camera runs over the RTSP Port. Make sure that the port is forwarded inside your router, if you want to access it over your cameras DDNS Address.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

NetTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(NetTable)