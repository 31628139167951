import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="ONVIF integration into InstarVision v2.1 Beta">
        <EuiText>
            <p>
                The feature is currently only available in the beta version of InstarVision v2.1. These can be downloaded at <a href="/dl/InstarVisionWindows/InstarVision_BETA-2016-06-27.exe" target="_blank" rel="noopener noreferrer">This Link</a>. As soon as a new version of the software is available, it will be linked immediately in our <Link to="/Downloads/Desktop_Software/InstarVision/">Download Area</Link>.
            </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

