import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Alarmauslöser anhängen">
        <EuiText>
          <ul>
              <li>Alarmbereich 1 ausgelöst: <code>1</code></li>
              <li>Alarmbereich 2 ausgelöst: <code>2</code></li>
              <li>Alarmbereich 3 ausgelöst: <code>3</code></li>
              <li>Alarmbereich 4 ausgelöst: <code>4</code></li>
              <li>Alarm-In / PIR ausgelöst: <code>5</code></li>
              <li>Audio Alarm ausgelöst: <code>6</code></li>
              <li>Bewegungserkennung & ausgelöst (Bereich 1): <code>7</code></li>
              <li>Bewegungserkennung & ausgelöst (Bereich 2): <code>8</code></li>
              <li>Bewegungserkennung & ausgelöst (Bereich 3): <code>9</code></li>
              <li>Bewegungserkennung & ausgelöst (Bereich 4): <code>10</code></li>
          </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

