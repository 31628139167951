import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import DownloadTable22 from 'components/Downloads/Desktop_Software/InstarVision_for_macOS/DownloadTable22';
import DownloadTable21 from 'components/Downloads/Desktop_Software/InstarVision_for_macOS/DownloadTable21';
import NavButtons from 'components/Downloads/Desktop_Software/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  DownloadTable22,
  DownloadTable21,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};