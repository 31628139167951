import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Alarm Server">
        <EuiText>
          <p>
            You can use the Alarm Server function to send an http request to a connected server. The server needs a compatible software to recognize this request as an alarm event and trigger further actions. This function is often supported by home alarm systems. You can add custom commands to the request to trigger specific actions in the receiving server software.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

