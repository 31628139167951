import { Link } from 'gatsby';
import NavButtons from 'components/720p_Series_CGI_List/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import OverviewGrid from 'components/720p_Series_CGI_List/Complete_CGI_List/720pOverviewGrid';
import * as React from 'react';
export default {
  Link,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  OverviewGrid,
  React
};