import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer, EuiButton, EuiHideFor, EuiShowFor } from '@elastic/eui';
import TableTechSpecs from 'components/Indoor_Cameras/IN-8401_WQHD/Technical_Specifications/Table';
import FooterCards from 'components/Indoor_Cameras/IN-8401_WQHD/Technical_Specifications/FooterCards';
import NavButtons from 'components/Indoor_Cameras/IN-8401_WQHD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  OutboundLink,
  EuiSpacer,
  EuiButton,
  EuiHideFor,
  EuiShowFor,
  TableTechSpecs,
  FooterCards,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};