import React from 'react'

import {EuiText, EuiSpacer} from '@elastic/eui'

import NightvisionGraph from "./nightvision-min-max-graph"

function NightvisionCard() {
  return (
    <div style={{ padding: '15px',maxWidth: '1115px', margin: '2.5rem 0 2.5rem 0', boxShadow: '0 2px 3px 0 rgb(0 0 0 / 36%' }}>
      <div style={{paddingTop: 20, paddingBottom: 10, paddingLeft: 10, paddingRight: 35}}>
        <EuiText grow={false} size="m" color="default" >
        Night vision range
        </EuiText>
        <EuiSpacer />
        <NightvisionGraph />
        <EuiSpacer />
      </div>
    </div>
  );
}

export default NightvisionCard