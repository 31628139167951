import React from 'react'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'

import ivImage from '../../../../../../images/Software/Windows/InstarVision/Advanced/License/Image003.webp'


export default function ImageCard() {

  return (
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem grow={false}>
            <EuiImage
              hasShadow
              allowFullScreen
              alt="InstarVision v2 for Windows"
              src={ivImage}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
              <p><strong>Validate your License Code online or by Email</strong></p>
              <p>You will be asked to register your software once you open InstarVision for the first time. You can click on Trial Version to start a 14 days unlimited trial run. The license can be bought in our web shop at <a href="https://www.instar.com/Products/software.html" target="_blank" rel="noopener noreferrer">www.instar.de</a>.</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
  );
}
