import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Overview',
      href: '/en/Outdoor_Cameras/IN-9420_WQHD/',
    },
    {
      text: 'Specifications',
      href: '/en/Outdoor_Cameras/IN-9420_WQHD/Technical_Specifications/',
    },
    {
      text: 'Features',
      href: '/en/Outdoor_Cameras/IN-9420_WQHD/Product_Features/',
    },
    {
      text: 'Installation',
      href: '/en/Outdoor_Cameras/IN-9420_WQHD/Quick_Installation/',
    },
    {
      text: 'Lense Adjustment',
      href: '/en/Outdoor_Cameras/IN-9420_WQHD/Lense_Adjustment/',
    },
    {
      text: 'Usermanual',
      href: '/en/Outdoor_Cameras/IN-9420_WQHD/Usermanual/',
    },
    {
      text: '◄ Outdoor Cameras',
      href: '/en/Outdoor_Cameras/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={10}
        aria-label="INSTAR IN-9420 2k+ WQHD Online Usermanual Chapters"
      />
      <EuiSpacer />
    </Fragment>
  );
}