import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Indoor_Cameras/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Indoor_Cameras.webp'
                    alt="Indoor Cameras"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Indoor Cameras"
              description="INSTAR offers a range of IP cameras for every indoor surveillance task. Do you need something compact and inconspicuous? Or a Pan & Tilt model?"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Indoor_Cameras/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Outdoor_Cameras/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Outdoor_Cameras.webp'
                    alt="Outdoor Cameras"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Outdoor Cameras"
              description="INSTAR offers a range of IP cameras for every outdoor surveillance task. Do you need something compact and inconspicuous? Or a Pan&Tilt model?"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Outdoor_Cameras/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Products/Usermanuals/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Products.webp'
                    alt="Usermanuals"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Usermanuals"
              description="We prepare an - always up-to-date - online user manual for all our HD IP camera models. This is the place to start your journey!"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Products/Usermanuals/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Products.webp'
                    alt="Software"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Camera Software"
              description="Use our free mobile apps for Android, iOS, Windows Phone or Blackberry. Or use a thirdparty system from Homematic, Loxone, AVM, Synology, QNAP and Co."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_WebUI_1080p.webp'
                    alt="Web User Interface"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Web User Interface"
              description="The Web User Interface allows you to configure and control your INSTAR IP camera from your favourite web browser."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/">
                  <StaticImage
                    src='../../images/Search/D_Downloads_SearchThumb.webp'
                    alt="Downloads"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Downloads"
              description="You can download the newest version of your camera's firmware and web user interface from the webUI. Come here for older versions and user manuals."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Products/Lenses/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Products.webp'
                    alt="Lenses"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Camera Lenses"
              description="INSTAR offers a range of camera lenses for every outdoor surveillance task. Do you need a wide-angle? Or maybe a tele lense?"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Products/Lenses/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Products/USB-Webcams/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-W1.webp'
                    alt="IN-Wx USB Webcams"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-Wx USB Webcam"
              description="The IN-Wx is a USB Webcam with a 1080p Full HD resolution. It can be connected to your PC or Laptop and be used with chat and team programs like Skype, Zoom, Facetime and others."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Products/USB-Webcams/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Products/Others/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Products.webp'
                    alt="Others Products"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Other Products"
              description="IP Camera Accessories and Network Security Products by INSTAR."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Products/Others/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
