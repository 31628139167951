import { EuiSpacer } from '@elastic/eui';
import LenseCards from 'components/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/LenseCards';
import Video5905Lense from 'components/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/Video5905Lense';
import NavButtons from 'components/Outdoor_Cameras/IN-5905_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  LenseCards,
  Video5905Lense,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};