import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link  to="/Web_User_Interface/720p_Series/Software/">
                  <StaticImage
                    src='../../../images/Search/WebUI_720p_SearchThumb_Software_Update.webp'
                    alt="Software Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Software Menu"
              description="Change the language of the web user interface, reboot or reset or upload a firmware and web UI update to your camera."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/Software/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link  to="/Web_User_Interface/720p_Series/Network/">
                  <StaticImage
                    src='../../../images/Search/WebUI_720p_SearchThumb_Network_IP_Configuration.webp'
                    alt="720p Series CGI List - Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Network Menu"
              description="Change your camera´s IP configuration or WiFi settings or connect it to your smartphone by scanning the DDNS, P2P or Push Service QR code with our smartphone app."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/Network/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Series/System/">
                  <StaticImage
                    src='../../../images/Search/WebUI_720p_SearchThumb_System_Info.webp'
                    alt="720p Series CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="System Menu"
              description="Change your camera´s System settings, adjust the status and IR LEDs or the Pan, Tilt and Zoom behaviour of your camera."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/System/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Series/Multimedia/">
                  <StaticImage
                    src='../../../images/Search/WebUI_720p_SearchThumb_Multimedia_Video.webp'
                    alt="720p Series CGI List - Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Multimedia Menu"
              description="Adjust your video quality and the audio volume of your camera. The image settings allow you to adjust contrast, saturation and brightness of your camera."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/Multimedia/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Series/Alarm/">
                  <StaticImage
                    src='../../../images/Search/WebUI_720p_SearchThumb_Alarm_Alarmserver.webp'
                    alt="720p Series CGI List - Alarm Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Menu"
              description="Activate your camera´s motion or audio detection and actions to be taken in case of an alarm - like video recordings, email alerts or FTP uploads."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/Alarm/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Series/Cloud/">
                  <StaticImage
                    src='../../../images/Search/WebUI_720p_SearchThumb_Cloud_Introduction.webp'
                    alt="720p Series CGI List - Cloud Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Cloud Menu"
              description="An overview over our online recording platform for your INSTAR IP camera."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1080p_Series/Cloud/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
