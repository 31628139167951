import React from 'react'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiTitle
} from '@elastic/eui'

import ivImage from '../../../../../images/Software/Windows/InstarVision/Image_Adjust/Image034_cut.webp'


export default function ImageCard() {

  return (
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem grow={false}>
            <EuiImage
              hasShadow
              allowFullScreen
              alt="InstarVision v2 for Windows"
              src={ivImage}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiTitle>
              <h4>Change your IP Camera´s Video Settings</h4>
            </EuiTitle>
            <EuiText>
              <p>The slider <strong>Brightness</strong> adjusts your video Brightness. <strong>Contrast</strong> allows you to adjust your camera´s video Contrast. Adjust your camera´s video Saturation with the last slider <strong>Saturation</strong>. The default button resets all values for the selected camera back to their defaults. Please note that not all cameras support all 3 settings! E.g. the saturation slider won´t be available in case of a VGA camera.</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
  );
}
