import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Image Settings">
        <EuiText>
          <p>
            Here you can adjust the image parameters brightness, saturation, contrast, vibrancy and sharpness. You can flip and mirror the video image and dig in deep into the advanced image settings.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

