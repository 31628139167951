import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'About',
      href: '/en/Software/Windows/InstarVision/About/'
    },
    {
      text: 'Advanced',
      href: '/en/Software/Windows/InstarVision/Advanced/',
    },
    {
      text: 'Camera List',
      href: '/en/Software/Windows/InstarVision/Camera_List/'
    },
    {
      text: 'Image Adjust',
      href: '/en/Software/Windows/InstarVision/Image_Adjust/'
    },
    {
      text: 'Layout',
      href: '/en/Software/Windows/InstarVision/Layout/'
    },
    {
      text: 'PTZ',
      href: '/en/Software/Windows/InstarVision/PTZ/'
    },
    {
      text: 'System',
      href: '/en/Software/Windows/InstarVision/System/'
    },
    {
      text: 'Users',
      href: '/en/Software/Windows/InstarVision/Users/'
    },
    {
      text: '◄ InstarVision',
      href: '/en/Software/Windows/InstarVision/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Software Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}