import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="HTML5 Video:">
        <EuiText>
          <p>
            Using the HTML5 video allows you to display the live video inside the webUI without any external plugins, e.g. ActiveX, Flash, etc. But this means that the work, that was done by those plugins is now handled by your browser. Older Desktop computers as well as mobile devices can have a hard time decoding all this data and the video stream might start to stutter.
          </p>
          <p>
            To prevent this we have limited the video stream to it's medium resolution * . <strong>Note</strong>: that the video displayed inside our mobile or desktop apps is not affected by this. Also the alarm videos will always be recorded in full resolution!
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

