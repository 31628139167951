import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PhotoTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Photoseries Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Photo series to SD card', descriptionColumn: ' Activate the planned snapshot recording on the internal SD card. Please be aware, that the recording only starts when the Recording Schedule (see below) is active.' },
        { actionColumn: 'Time interval in seconds', descriptionColumn: 'Set the time interval in between to snapshots. The longest supported interval is 86400 seconds - this would create a photo series with 24hrs between two recorded images.' },
        { actionColumn: 'Photo series to email', descriptionColumn: 'Activate the planned snapshot recording and send those snapshots by Email. Please be aware, that the recording only starts when the Recording Schedule (see below) is active and you set an SMTP Server first.' },
        { actionColumn: 'Time interval in minutes', descriptionColumn: 'Set the time interval in between to snapshots. The longest supported interval is 1440 minutes - this would create a photo series with 24hrs between two recorded images.' },
        { actionColumn: 'Photo series to FTP server', descriptionColumn: 'Activate the planned snapshot recording and upload those snapshots to an FTP Server. Please be aware, that the recording only starts when the Recording Schedule (see below) is active and you set an FTP Server first.' },
        { actionColumn: 'Time interval in seconds', descriptionColumn: 'Set the time interval in between to snapshots. The longest supported interval is 86400 seconds - this would create a photo series with 24hrs between two recorded images.' },
        { actionColumn: 'Timestamp in filename', descriptionColumn: 'Create a filename for each snapshot from a time stamp.' },
        { actionColumn: 'Fixed filename', descriptionColumn: 'Set a fixed file name and overwrite the file with each upload. This way you will only have one currently uploaded file on your FTP server - which is ideal, if you want to embed this file in your website.' },
        { actionColumn: 'Filename', descriptionColumn: 'Choose a fixed filename for the snapshot uploaded by FTP (see above).' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PhotoTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(PhotoTable)