import React from 'react'

import {EuiFlexGroup, EuiFlexItem, EuiText} from '@elastic/eui'

import IN6014RadarIN8015 from "./in6014-radargraph-8015"
import IN6014RadarIN3011 from "./in6014-radargraph-3011"


function IN6014RadarGrid() {

  return (
    <div style={{ padding: '15px',maxWidth: '1115px', margin: '2.5rem 0 2.5rem 0', boxShadow: '0 2px 3px 0 rgb(0 0 0 / 36%' }}>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiText size="s">
            IN-6014HD vs IN-6012HD
          </EuiText>
          <div style={{height: 275}}>
            <IN6014RadarIN8015 />
          </div>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText size="s">
            IN-6014HD vs IN-3011
          </EuiText>
          <div style={{height: 275}}>
            <IN6014RadarIN3011 />
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}

export default IN6014RadarGrid
