import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'InstarVision',
      href: '/en/Software/iOS/InstarVision/',
    },
    {
      text: 'ICCAM',
      href: '/en/Software/iOS/ICCAM/',
    },
    {
      text: 'IP Cam Viewer',
      href: '/en/Software/iOS/IP_Cam_Viewer/',
    },
    {
      text: 'IP Vision Pro',
      href: '/en/Software/iOS/IP_Vision_Pro/',
    },
    {
      text: 'LiveCams',
      href: '/en/Software/iOS/LiveCams/',
    },
    {
      text: 'P2P Cam',
      href: '/en/Software/iOS/P2P_Cam_Live/',
    },
    {
      text: '◄ iOS',
      href: '/en/Software/iOS/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Software Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}