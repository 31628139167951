import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PTZTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "indexColumn", title: "Index" },
        { name: "pttimeoutColumn", title: "pan/tilt & timeout" },
        { name: "zftimeoutColumn", title: "zoom/focus & timeout" },
        { name: "ptColumn", title: "pan/tilt" },
        { name: "zfColumn", title: "zoom/focus" },
        { name: "ptRelColumn", title: "pan/tilt" },
        { name: "zfRelColumn", title: "zoom/focus" }
      ],
      rows: [
        { 
          indexColumn: 'CGI Command',
          pttimeoutColumn: 'cmd=ptzmove',
          zftimeoutColumn: 'cmd=ptzmove',
          ptColumn: 'cmd=ptzmove',
          zfColumn: 'cmd=ptzmove',
          ptRelColumn: 'cmd=gotorelposition',
          zfRelColumn: 'cmd=gotorelposition'
        },
        { 
          indexColumn: 'e.g. IN-8415',
          pttimeoutColumn: 'supported',
          zftimeoutColumn: 'not supported',
          ptColumn: 'supported',
          zfColumn: 'not supported',
          ptRelColumn: 'supported',
          zfRelColumn: 'not supported'
        },
        {
          indexColumn: 'e.g. IN-9420',
          pttimeoutColumn: 'supported',
          zftimeoutColumn: 'supported',
          ptColumn: 'move to hard stop',
          zfColumn: 'supported',
          ptRelColumn: 'not supported',
          zfRelColumn: 'supported'
        },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PTZTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(PTZTable)