import React from 'react'

import { Player } from 'video-react'

import {
    EuiText,
    EuiIcon,
    EuiPanel
  } from '@elastic/eui'

import { OutboundLink } from 'gatsby-plugin-gtag'

require('../../Layout/chrome/assets/video.css')

function VideoCard() {
  return (
    <EuiPanel paddingSize="m">
        <Player>
            <source src="https://wiki.instar.com/videos/Ihrer_INSTAR_Kamera_einen_Namen_in_der_Windows_Hosts_Datei_vergeben.mp4" />
        </Player>
        <EuiText>
            <p>Wir zeigen in diesem Video wie Sie Ihrer INSTAR Kamera in Ihrem Netzwerk einen Namen vergeben können wie z.B. eingang.cam. So können Sie dann im Webbrowser, anstatt der IP Adresse den Namen eingeben und gelangen so auf Ihre Kamera. Auf <OutboundLink href="https://www.youtube.com/watch?v=hiWKczBCbSI" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
        </EuiText>
    </EuiPanel>
  );
}

export default VideoCard