import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
};

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
);

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0,
    }}
    {...restProps}
  />
);

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ActionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "triggerColumn", title: "Trigger" },
        { name: "httpColumn", title: "HTTP Query" },
        {
          name: "mqttColumn",
          title: "MQTT Payload status/alarm/triggered/triggers",
        },
      ],
      rows: [
        {
          triggerColumn: "Alarm Area 1 & PIR",
          httpColumn: "&trigger=17",
          mqttColumn: '{ "val" : "17" }',
        },
        {
          triggerColumn: "Alarm Area 2 & PIR",
          httpColumn: "&trigger=18",
          mqttColumn: '{ "val" : "18" }',
        },
        {
          triggerColumn: "Alarm Area 3 & PIR",
          httpColumn: "&trigger=20",
          mqttColumn: '{ "val" : "20" }',
        },
        {
          triggerColumn: "Alarm Area 4 & PIR",
          httpColumn: "&trigger=24",
          mqttColumn: '{ "val" : "24" }',
        },
        {
          triggerColumn: "Alarm Area 5 & PIR",
          httpColumn: "&trigger=272",
          mqttColumn: '{ "val" : "272" }',
        },
        {
          triggerColumn: "Alarm Area 6 & PIR",
          httpColumn: "&trigger=528",
          mqttColumn: '{ "val" : "528" }',
        },
        {
          triggerColumn: "Alarm Area 7 & PIR",
          httpColumn: "&trigger=1040",
          mqttColumn: '{ "val" : "1040" }',
        },
        {
          triggerColumn: "Alarm Area 8 & PIR",
          httpColumn: "&trigger=2064",
          mqttColumn: '{ "val" : "2064" }',
        },
        {
          triggerColumn: "Alarm Area 1 & Alarm Input",
          httpColumn: "&trigger=33",
          mqttColumn: '{ "val" : "33" }',
        },
        {
          triggerColumn: "Alarm Area 2 & Alarm Input",
          httpColumn: "&trigger=34",
          mqttColumn: '{ "val" : "34" }',
        },
        {
          triggerColumn: "Alarm Area 3 & Alarm Input",
          httpColumn: "&trigger=36",
          mqttColumn: '{ "val" : "36" }',
        },
        {
          triggerColumn: "Alarm Area 4 & Alarm Input",
          httpColumn: "&trigger=40",
          mqttColumn: '{ "val" : "40" }',
        },
        {
          triggerColumn: "Alarm Area 5 & Alarm Input",
          httpColumn: "&trigger=288",
          mqttColumn: '{ "val" : "288" }',
        },
        {
          triggerColumn: "Alarm Area 6 & Alarm Input",
          httpColumn: "&trigger=544",
          mqttColumn: '{ "val" : "544" }',
        },
        {
          triggerColumn: "Alarm Area 7 & Alarm Input",
          httpColumn: "&trigger=1056",
          mqttColumn: '{ "val" : "1056" }',
        },
        {
          triggerColumn: "Alarm Area 8 & Alarm Input",
          httpColumn: "&trigger=2080",
          mqttColumn: '{ "val" : "2080" }',
        },
        {
          triggerColumn: "Alarm Area 1 & Audio Detection",
          httpColumn: "&trigger=65",
          mqttColumn: '{ "val" : "65" }',
        },
        {
          triggerColumn: "Alarm Area 2 & Audio Detection",
          httpColumn: "&trigger=66",
          mqttColumn: '{ "val" : "66" }',
        },
        {
          triggerColumn: "Alarm Area 3 & Audio Detection",
          httpColumn: "&trigger=68",
          mqttColumn: '{ "val" : "68" }',
        },
        {
          triggerColumn: "Alarm Area 4 & Audio Detection",
          httpColumn: "&trigger=72",
          mqttColumn: '{ "val" : "72" }',
        },
        {
          triggerColumn: "Alarm Area 5 & Audio Detection",
          httpColumn: "&trigger=320",
          mqttColumn: '{ "val" : "320" }',
        },
        {
          triggerColumn: "Alarm Area 6 & Audio Detection",
          httpColumn: "&trigger=567",
          mqttColumn: '{ "val" : "567" }',
        },
        {
          triggerColumn: "Alarm Area 7 & Audio Detection",
          httpColumn: "&trigger=1088",
          mqttColumn: '{ "val" : "1088" }',
        },
        {
          triggerColumn: "Alarm Area 8 & Audio Detection",
          httpColumn: "&trigger=2112",
          mqttColumn: '{ "val" : "2112" }',
        },
        {
          triggerColumn: "Alarm Area 1 & 2",
          httpColumn: "&trigger=3",
          mqttColumn: '{ "val" : "3" }',
        },
        {
          triggerColumn: "Alarm Area 1 & 3",
          httpColumn: "&trigger=5",
          mqttColumn: '{ "val" : "5" }',
        },
        {
          triggerColumn: "Alarm Area 1 & 4",
          httpColumn: "&trigger=9",
          mqttColumn: '{ "val" : "9" }',
        },
        {
          triggerColumn: "Alarm Area 1 & 5",
          httpColumn: "&trigger=257",
          mqttColumn: '{ "val" : "257" }',
        },
        {
          triggerColumn: "Alarm Area 1 & 6",
          httpColumn: "&trigger=513",
          mqttColumn: '{ "val" : "513" }',
        },
        {
          triggerColumn: "Alarm Area 1 & 7",
          httpColumn: "&trigger=1025",
          mqttColumn: '{ "val" : "1025" }',
        },
        {
          triggerColumn: "Alarm Area 1 & 8",
          httpColumn: "&trigger=2049",
          mqttColumn: '{ "val" : "2049" }',
        },
        {
          triggerColumn: "Alarm Area 2 & 3",
          httpColumn: "&trigger=6",
          mqttColumn: '{ "val" : "6" }',
        },
        {
          triggerColumn: "Alarm Area 2 & 4",
          httpColumn: "&trigger=10",
          mqttColumn: '{ "val" : "10" }',
        },
        {
          triggerColumn: "Alarm Area 2 & 5",
          httpColumn: "&trigger=258",
          mqttColumn: '{ "val" : "258" }',
        },
        {
          triggerColumn: "Alarm Area 2 & 6",
          httpColumn: "&trigger=514",
          mqttColumn: '{ "val" : "514" }',
        },
        {
          triggerColumn: "Alarm Area 2 & 7",
          httpColumn: "&trigger=1026",
          mqttColumn: '{ "val" : "1026" }',
        },
        {
          triggerColumn: "Alarm Area 2 & 8",
          httpColumn: "&trigger=2050",
          mqttColumn: '{ "val" : "2050" }',
        },
        {
          triggerColumn: "Alarm Area 3 & 4",
          httpColumn: "&trigger=12",
          mqttColumn: '{ "val" : "12" }',
        },
        {
          triggerColumn: "Alarm Area 3 & 5",
          httpColumn: "&trigger=260",
          mqttColumn: '{ "val" : "260" }',
        },
        {
          triggerColumn: "Alarm Area 3 & 6",
          httpColumn: "&trigger=516",
          mqttColumn: '{ "val" : "516" }',
        },
        {
          triggerColumn: "Alarm Area 3 & 7",
          httpColumn: "&trigger=1028",
          mqttColumn: '{ "val" : "1028" }',
        },
        {
          triggerColumn: "Alarm Area 3 & 8",
          httpColumn: "&trigger=2052",
          mqttColumn: '{ "val" : "2052" }',
        },
        {
          triggerColumn: "Alarm Area 4 & 5",
          httpColumn: "&trigger=264",
          mqttColumn: '{ "val" : "264" }',
        },
        {
          triggerColumn: "Alarm Area 4 & 6",
          httpColumn: "&trigger=520",
          mqttColumn: '{ "val" : "520" }',
        },
        {
          triggerColumn: "Alarm Area 4 & 7",
          httpColumn: "&trigger=1032",
          mqttColumn: '{ "val" : "1032" }',
        },
        {
          triggerColumn: "Alarm Area 4 & 8",
          httpColumn: "&trigger=2056",
          mqttColumn: '{ "val" : "2056" }',
        },
        {
          triggerColumn: "Alarm Area 5 & 6",
          httpColumn: "&trigger=768",
          mqttColumn: '{ "val" : "768" }',
        },
        {
          triggerColumn: "Alarm Area 5 & 7",
          httpColumn: "&trigger=1280",
          mqttColumn: '{ "val" : "1280" }',
        },
        {
          triggerColumn: "Alarm Area 5 & 8",
          httpColumn: "&trigger=2304",
          mqttColumn: '{ "val" : "2304" }',
        },
        {
          triggerColumn: "Alarm Area 6 & 7",
          httpColumn: "&trigger=1536",
          mqttColumn: '{ "val" : "1536" }',
        },
        {
          triggerColumn: "Alarm Area 6 & 8",
          httpColumn: "&trigger=2560",
          mqttColumn: '{ "val" : "2560" }',
        },
        {
          triggerColumn: "Alarm Area 7 & 8",
          httpColumn: "&trigger=3072",
          mqttColumn: '{ "val" : "3072" }',
        },
        {
          triggerColumn: "Alarm Area 1 & 2 & 3 & 4",
          httpColumn: "&trigger=15",
          mqttColumn: '{ "val" : "15" }',
        },
        {
          triggerColumn: "Alarm Area 5 & 6 & 7 & 8",
          httpColumn: "&trigger=3840",
          mqttColumn: '{ "val" : "3840" }',
        },
        {
          triggerColumn: "Alarm Area 1 & 2 & 7 & 8",
          httpColumn: "&trigger=3075",
          mqttColumn: '{ "val" : "3075" }',
        },
        {
          triggerColumn: "Alarm Area 3 & 4 & 5 & 6",
          httpColumn: "&trigger=780",
          mqttColumn: '{ "val" : "780" }',
        },
      ],
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{ display: "inline-block", maxWidth: "915px" }}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ActionTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActionTable);
