import React from 'react'

import {
  EuiCard,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import icCam5 from "../../../../images/Software/iOS/ICCAM/ICCAM_05.webp"
import icCam6 from "../../../../images/Software/iOS/ICCAM/ICCAM_06.webp"




export default function softwareArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={icCam5}
                    alt="ICCAM for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                   <p><strong>Fig5.</strong> First select your camera from the list view.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={icCam6}
                    alt="ICCAM for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p><strong>Fig6.</strong> You will see your camera´s live stream. Use the buttons below to access advanced functions like video recordings.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
