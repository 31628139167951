import { Link } from 'gatsby';
import CloudArticles from 'components/Motion_Detection/INSTAR_Cloud/IntroCloud';
import YoutubeVideo from "components/Motion_Detection/INSTAR_Cloud/YoutubeVideoCardCloud";
import NavButtons from 'components/Motion_Detection/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  CloudArticles,
  YoutubeVideo,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};