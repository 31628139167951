import React from 'react'

import {
  EuiCard,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import p2pCamsImage03 from "../../../../images/Software/iOS/P2P_Cam_Live/P2P_Cam_Live_03.webp"
import p2pCamsImage04 from "../../../../images/Software/iOS/P2P_Cam_Live/P2P_Cam_Live_04.webp"


export default function softwareArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={p2pCamsImage03}
                    alt="P2P Cam Live for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                   <p><strong>Fig3.</strong> Click on the Camera button to go back to the camera list. Select your camera to open its live stream.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={p2pCamsImage04}
                    alt="P2P Cam Live for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p><strong>Fig4.</strong> Click on the media buttons in the lower left corner to take snapshots, record a video or activate the audio stream.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
