import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../images/Indoor_Cameras/IN-6014_HD/Quick_Installation/Quickinstallation_01.webp"
import qiSelect02 from "../../../../images/Indoor_Cameras/IN-6014_HD/Quick_Installation/Quickinstallation_02.webp"
import qiSelect03 from "../../../../images/Indoor_Cameras/IN-6014_HD/Quick_Installation/Quickinstallation_03.webp"
import qiSelect04 from "../../../../images/Indoor_Cameras/IN-6014_HD/Quick_Installation/Quickinstallation_04.webp"
import qiSelect05 from "../../../../images/Indoor_Cameras/IN-6014_HD/Quick_Installation/Quickinstallation_05.webp"
import qiSelect06 from "../../../../images/Indoor_Cameras/IN-6014_HD/Quick_Installation/Quickinstallation_06.webp"
import qiSelect07 from "../../../../images/Indoor_Cameras/IN-6014_HD/Quick_Installation/Quickinstallation_07.webp"
import qiSelect08 from "../../../../images/Indoor_Cameras/IN-6014_HD/Quick_Installation/Quickinstallation_08.webp"
import qiSelect09 from "../../../../images/Indoor_Cameras/IN-6014_HD/Quick_Installation/Quickinstallation_09.webp"
import qiSelect10 from "../../../../images/Indoor_Cameras/IN-6014_HD/Quick_Installation/Quickinstallation_10.webp"
import qiSelect11 from "../../../../images/Indoor_Cameras/IN-6014_HD/Quick_Installation/Quickinstallation_11.webp"
import qiSelect12 from "../../../../images/Indoor_Cameras/IN-6014_HD/Quick_Installation/Quickinstallation_12.webp"
import qiSelect13 from "../../../../images/Indoor_Cameras/IN-6014_HD/Quick_Installation/Quickinstallation_13.webp"
import qiSelect14 from "../../../../images/Indoor_Cameras/IN-6014_HD/Quick_Installation/Quickinstallation_14.webp"



export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect01}
                    alt="Attach the WiFi antenna to the cameras antenna connector as shown here (#11). (only for the non-PoE Version!)"
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Attach the WiFi antenna to the cameras antenna connector as shown here (#11). (only for the non-PoE Version!)
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect02}
                    alt="Plug in the power supply (#7) to start the camera."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Plug in the power supply (#7) to start the camera.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect03}
                    alt="It is recommended to set your WiFi router to a band in the range from 1-6 (802.11bgn). Also WPA2/AES (CCMP) encryption should be used."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    It is recommended to set your WiFi router to a band in the range from 1-6 (802.11bgn). Also WPA2/AES (CCMP) encryption should be used.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect04}
                    alt="A Press the WPS button (s. above) on your router. B Press the camera´s reset button for 3s. Be aware that holding it for longer than 10s will result in a camera reset. C The camera will now automatically connect to your WiFi network. Please skip to step 7 (for PC or Mac) or 14 (for smartphone)."
                  />
              }
              footer={
                <EuiText>
                    <ul>
                      <li>A Press the WPS button (s. above) on your router.</li>
                      <li>B Press the camera´s reset button for 3s (image on the left). Be aware that holding it for longer than 10s will result in a camera reset.</li>
                      <li>C The camera will now automatically connect to your WiFi network. Please skip to step 7 (for PC or Mac) or 14 (for smartphone).</li>
                    </ul>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect05}
                    alt="Connect the network cable to the back of the camera (s. #12) to setup a LAN connection (also necessary for WiFi if WPS is not available s. #4 above)."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Connect the network cable to the back of the camera (s. #12) to setup a LAN connection (also necessary for WiFi if WPS is not available s. #4 above).
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect06}
                    alt="Connect the cameras network cable to a free LAN port on your internet router. You can skip this step if you just need a WiFi connection."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Connect the cameras network cable to a free LAN port on your internet router. You can skip this step if you just need a WiFi connection.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect07}
                    alt="Start our IP Camera Tool from the Software CD and double click on the name of the camera."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Start our IP Camera Tool from the Software CD and double click on the name of the camera.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect08}
                    alt="Default Logins User/Password: admin / instar for the administrator, user / instar for normal users and guest / instar for restricted users."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Default Logins User/Password: admin / instar for the administrator, user / instar for normal users and guest / instar for restricted users.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect09}
                    alt="Activate the cameras WiFi by using the WiFi scanner in the camera user interface. After typing in your WiFi password and submitting - please unplug the LAN cable and restart the camera."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Activate the cameras WiFi by using the WiFi scanner in the camera user interface. After typing in your WiFi password and submitting - please unplug the LAN cable and restart the camera.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect10}
                    alt="In case you are not able to see a video when accessing the camera via Firefox, Chrome, etc. under Windows, open the Quicktime Player preferences and set the protocol to HTTP and the port to 80."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    In case you are not able to see a video when accessing the camera via Firefox, Chrome, etc. under Windows, open the Quicktime Player preferences and set the protocol to HTTP and the port to 80.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect11}
                    alt="To set the Quicktime HTTP port in MacOSX please open the Terminal in Applications/Utilities and type in the command qtdefaults write TransportSettings HTTP 80 to set the port to 80."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    To set the Quicktime HTTP port in MacOSX please open the Terminal in Applications/Utilities and type in the command qtdefaults write TransportSettings "HTTP" "80" to set the port to 80.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect12}
                    alt="To access your camera over the internet, you have to forward the HTTP Port inside your router (default “80”). To access the Flash Videostream inside the WebUI, you also have to forward the RTMP Port (default 1935) -> see also 14 below"
                  />
              }
              footer={
                <EuiText>
                    <p>
                    To access your camera over the internet, you have to forward the HTTP Port inside your router (default “80”). To access the Flash Videostream inside the WebUI, you also have to forward the RTMP Port (default “1935”) see also 14 below
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect13}
                    alt="Now you can enter your camera´s DDNS information to our free Windows Phone, Windows Metro, Android, iPhone, iPad or Blackberry App."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Now you can enter your camera´s DDNS information to our free Windows Phone, Windows Metro, Android, iPhone, iPad or Blackberry App.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect14}
                    alt="If you cannot open a port for camera´s DDNS service, you can still use the Point-2-Point Service instead with our Windows Phone, Windows Metro, Android, iPhone, iPad or Blackberry App."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    If you cannot open a port for camera´s DDNS service, you can still use the Point-2-Point Service instead with our Windows Phone, Windows Metro, Android, iPhone, iPad or Blackberry App.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
