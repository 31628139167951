import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class IPConfigTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Network Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'IP address', descriptionColumn: 'This is the IPv4 address of your camera inside your local network. To access your camera´s web user interface, simply type in the IP address into the address bar of web browser. If your camera´s HTTP port is not "80", please add the port to the IP address separated by a : , like 192.168.1.125:8081.' },
        { actionColumn: 'Subnetmask', descriptionColumn: 'The subnet mask defines the subnet your camera is connected to. It is 255.255.255.0 in most small networks.' },
        { actionColumn: 'Gateway', descriptionColumn: 'The gateway is the connection between your local network and the internet. In most small networks it is the IP address of your router - e.g. 192.168.1.1, 192.168.2.1 or 192.168.178.1. The first 3 numbers are the same, as the IP address of your camera - just add the number 1 in last position.' },
        { actionColumn: 'Primary DNS Server', descriptionColumn: 'The DNS server is needed by your camera to resolve internet addresses. In most small networks it is the IP address of your router - e.g. 192.168.1.1, 192.168.2.1 or 192.168.178.1.' },
        { actionColumn: 'Secondary DNS Server', descriptionColumn: 'Can be left empty if there is only one DNS server in your local network. Or it can be set to a public online DNS server like 1.1.1.1, 208.67.222.222 or 8.8.8.8 to provide a backup service.' },
        { actionColumn: 'HTTP Port', descriptionColumn: 'The HTTP Port is the LAN Port of your camera. Add this port to your camera´s IP address, if you want to access the web user interface with your web browser. This is also the port that you need to Forward inside your router, to access your camera with our smartphone app (Android, iPhone, iPad, Windows Phone, Windows Metro) via its DDNS Address. We recommend that you use a HTTP port that is 8081 or higher. If you have more than 1 camera, always add 1 to the port for the next camera 8081, 8082, 8083, etc... Only necessary if you want to access your camera with our smartphone app over its DDNS address!.' },
        { actionColumn: 'HTTPS Port', descriptionColumn: 'The HTTPS Port is the secure LAN Port of your camera. Add this port to your camera´s IP address and add the https:// prefix, if you want to access the web user interface with your web browser e.g. https://192.168.1.200:443. This is also the port that you need to Forward inside your router, to access your camera with your Browser via it´s DDNS Address. If you have more then 1 camera, always add 1 to the port for the next camera 443, 444, 445, etc.. Only necessary if your want to access your camera with your web browser over it´s DDNS address!' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

IPConfigTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(IPConfigTable)