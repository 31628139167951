
import React, { useState } from 'react'

import {
  EuiCallOut,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiButton,
  EuiTitle,
  EuiText,
  EuiSpacer
} from '@elastic/eui'

import BandWidthCard from './bandwidth-min-max-card'
import NaturalLightOutdoorGallery from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/Galleries/NaturalLightOutdoorGallery'
import NaturalLightOutdoorTable from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/Tables/NaturalLightOutdoorTable'
import NaturalLightIndoorGallery from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/Galleries/NaturalLightIndoorGallery'
import NaturalLightIndoorTable from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/Tables/NaturalLightIndoorTable'
import ArtificialLightIndoorGallery from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/Galleries/ArtificialLightIndoorGallery'
import ArtificialLightIndoorTable from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/Tables/ArtificialLightIndoorTable'
import ArtificialLightOutdoorGallery from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/Galleries/ArtificialLightOutdoorGallery'
import ArtificialLightOutdoorTable from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/Tables/ArtificialLightOutdoorTable'
import NightvisionIndoorGallery from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/Galleries/NightvisionIndoorGallery'
import IRIndoorTable from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/Tables/IRIndoorTable'
import NightvisionOutdoorGallery from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/Galleries/NightvisionOutdoorGallery'
import IROutdoorTable from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/Tables/IROutdoorTable'

export default () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

  const closeFlyout = () => setIsFlyoutVisible(false);

  const showFlyout = () => setIsFlyoutVisible(true);

  let flyout;

  const callOut = (
    <EuiCallOut iconType="help">
      <p>
        Dies is eine Übersicht über die Bandbreiten bzw. Speicherplatz Anforderung für den Livevideo Stream und die Videoaufnahmen. Beachten Sie, dass die Einstellung der Auflösung und Bitrate einen sehr großen Einfluß auf beide Größen haben.
      </p>
    </EuiCallOut>
  );

  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={closeFlyout}
        size="l"
        aria-labelledby="flyoutWithBannerTitle">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="flyoutWithBannerTitle">Videoparameter und Dateigröße</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody banner={callOut}>
          <EuiTitle size="m">
            <h2 id="flyoutWithBannerTitle">Video Bitrate *</h2>
          </EuiTitle>
          <EuiSpacer />
          <EuiText>
            <p>Je mehr Details Ihre Kamera abbilden muss, desto weniger effektiv wird die Videokomprimierung arbeiten und die Dateigröße wird entsprechend nach oben gehen. Die Bitrate ermöglicht es Ihnen eine maximale Menge an Daten zu definieren, die Ihre Kamera verwenden darf, um das Videobild zu erstellen. Wenn Sie ein sehr "unruhiges" Bild mit vielen Details haben, kann ein Anheben der Bitrate zu einer besseren Bildqualität führen - Sie bekommen weniger digitale Bildartefakte und feine Details werden natürlicher abgebildet.</p>
            <p>Die Bandbreitenanforderung beim Videostreamen und die Dateigröße bei der Videoaufnahme skalieren jedoch stark mit der Bitrate. Es ist daher ratsam die <strong>kleinstmögliche Bitrate</strong> zu wählen, mit der Sie noch ein akzeptables Bild erhalten!</p>
          </EuiText>
          <BandWidthCard/>
          <EuiText>
            <p>Alle Messungen oben wurden mit einem Videostream mit <strong>variabler Bitrate</strong> vorgenommen. D.h. der Stream "nimmt sich nur soviel Platz" wie er braucht um das gesehene Bild einzufangen. Im Beispiel <em>Kunstlicht (Innen)</em> kann man sehen, dass hier nur sehr wenig Daten benötigt werden, um das kontrast- und detailarme Bild darzustellen. Die Datenrate ist ~ 0.2 MB/s, d.h. bei einem 15 sekündigen Alarmvideo würde man eine 3 MB Videodatei erhalten.</p>
            <p>Bei den anderen Beispielen hat man entweder sehr viele Details und/oder Bewegung im Bild - hierzu zählt auch das Hintergrundrauschen, das sichtbar wird, wenn die Kamera bei schlechten Lichtverhältnissen eingesetzt wird. Entsprechend kann der Stream bis zu 3x die Menge an Daten verschlingen und entsprechend zu grossen Aufnahmedateien und Netzwerk Anforderungen beim Live Streaming führen.</p>
          </EuiText>
          <EuiSpacer />
          <EuiText>
            <h3>Natürliches Licht (Aussen)</h3>
            <NaturalLightOutdoorGallery />
            <NaturalLightOutdoorTable />
            <h3>Natürliches Licht (Innen)</h3>
            <NaturalLightIndoorGallery />
            <NaturalLightIndoorTable />
            <h3>Kunstlicht (Aussen)</h3>
            <ArtificialLightOutdoorGallery />
            <ArtificialLightOutdoorTable />
            <h3>Kunstlicht (Innen)</h3>
            <ArtificialLightIndoorGallery />
            <ArtificialLightIndoorTable />
            <h3>Infrarot Nachtischt (Aussen)</h3>
            <NightvisionOutdoorGallery />
            <IROutdoorTable />
            <h3>Infrarot Nachtischt (Innen)</h3>
            <NightvisionIndoorGallery />
            <IRIndoorTable />
          </EuiText>
          <EuiSpacer />
          <EuiText><em>* für alle Messungen wurde der 2560x1440 Pixel Hauptstream der IN-9408 2k+ WQHD mit variabler Bitrate verwendet.</em></EuiText>
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }
  return (
    <div>
      <EuiSpacer />
      <EuiButton onClick={showFlyout} fill >Video Bitraten</EuiButton>
      {flyout}
      <EuiSpacer />
    </div>
  );
}; 