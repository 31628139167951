import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Series_CGI_List/System_Menu/Overview/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/System_Menu/1080pCGIs-WebUI_System_Overview.webp'
                    alt="1080p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Overview"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Series_CGI_List/System_Menu/User/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/System_Menu/1080pCGIs-WebUI_System_User.webp'
                    alt="1080p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="User"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Series_CGI_List/System_Menu/Date_Time/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/System_Menu/1080pCGIs-WebUI_System_Date.webp'
                    alt="1080p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Date & Time"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Series_CGI_List/System_Menu/Language/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/System_Menu/1080pCGIs-WebUI_System_Language.webp'
                    alt="1080p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Language"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Series_CGI_List/System_Menu/System_Log/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/System_Menu/1080pCGIs-WebUI_System_Log.webp'
                    alt="1080p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="System Log"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Series_CGI_List/System_Menu/Update/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/System_Menu/1080pCGIs-WebUI_System_Update.webp'
                    alt="1080p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Update"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Series_CGI_List/System_Menu/Reboot/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/System_Menu/1080pCGIs-WebUI_System_Reboot.webp'
                    alt="1080p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Reboot"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1080p_Series_CGI_List/System_Menu/Reset/">
                  <StaticImage
                    src='../../../images/1080p_Series_CGI_List/System_Menu/1080pCGIs-WebUI_System_Reboot.webp'
                    alt="1080p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Reset"
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
