import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'InstarVision',
      href: '/en/Software/Windows/InstarVision/'
    },
    {
      text: 'InstarVision (Phone)',
      href: '/en/Software/Windows/InstarVision/Windows_Phone/',
    },
    {
      text: 'InstarVision (Metro)',
      href: '/en/Software/Windows/InstarVision/Metro/',
    },
    {
      text: 'BlueIris',
      href: '/en/Software/Windows/Blue_Iris_v5',
    },
    {
      text: 'go1984',
      href: '/en/Software/Windows/go1984/',
    },
    {
      text: 'iSpy',
      href: '/en/Software/Windows/iSpy/',
    },
    {
      text: 'Sighthound',
      href: '/en/Software/Windows/Sighthound/',
    },
    {
      text: 'VLC',
      href: '/en/Software/Windows/VLC_Player/',
    },
    {
      text: '◄ Windows',
      href: '/en/Software/Windows/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Software Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}