import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Caution">
        <EuiText>
          <p>Whereas it is possible to test the DDNS access in a FritzBox of the same network, there are routers such as the Telekom Speedports which can deny the test. This means you would have to use a computer in another network, or alternatively test the DDNS-Access by using the mobile network on your smartphone.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

