import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/IFTTT/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import AppletAccording from 'components/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Instruction/applet_accordion';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  NavButtons,
  ForumBox,
  AppletAccording,
  BreadCrumbs,
  SEOHelmet,
  React
};