import React from 'react'
import { EuiImage, EuiText } from '@elastic/eui'

import ivImage from "../../../../images/Software/Windows/InstarVision/Image003.webp"




export default () => (
  <EuiText>
    <EuiImage
      size="l"
      float="left"
      margin="l"
      hasShadow
      caption="InstarVision v3 for Windows"
      allowFullScreen
      alt="InstarVision v3 for Windows"
      src={ivImage}
    />
    <p>Once the software is registered this dialog will no longer be displayed, but can be accessed from the <em>Advanced menu</em> in the Side Navigation Bar in the software´s main window. In case you want to upgrade the software, just delete your old license key, copy & paste the new key into the input field and click on <em>Register Online</em></p>
  </EuiText>
);
