import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Accessories/IN-Motion_500/">
                  <StaticImage
                    src='../../../images/Search/D_Downloads_SearchThumb.webp'
                    alt="IN-Motion 500"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-Motion 500"
              description="This product integrates microwave and passive infrared motion detection. And is the best choice for outdoor intrusion detection."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Accessories/IN-Motion_500/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Accessories/IN-Motion_300/">
                  <StaticImage
                    src='../../../images/Search/D_Downloads_SearchThumb.webp'
                    alt="IN-Motion 300"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-Motion 300"
              description="IN-Motion is a passive(PIR) motion detector.This sensor is the perfect extension for your ip camera in order to minimize false alarms."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Accessories/IN-Motion_300/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Accessories/IN-Mikro_380/">
                  <StaticImage
                    src='../../../images/Search/D_Downloads_SearchThumb.webp'
                    alt="IN-Mikro 380"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-Mikro 380"
              description="The IN-MIKRO 380 is a high quality active microphone which can be easily connected to your camera. All you need is camera which has a built-in audio input."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Accessories/IN-Mikro_380/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Accessories/IN-90x/">
                  <StaticImage
                    src='../../../images/Search/D_Downloads_SearchThumb.webp'
                    alt="IN-Frared"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-Frared"
              description="Use the IN-907, IN-906, IN-905 or IN-903 IR Spotlight with your night vision compatible cameras in order to improve the night view. (Also available in 940nm)"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Accessories/IN-90x/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Accessories/IN-DV1215/">
                  <StaticImage
                    src='../../../images/Search/D_Downloads_SearchThumb.webp'
                    alt="IN-DV1215"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-DV1215"
              description="Action camera with a 120 degree lense, waterproof housing and high-speed video recording capability. Ideal for sport recordings, like snowboarding, skiing, diving..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Accessories/IN-DV1215/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
