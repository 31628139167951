import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Be Aware">
        <EuiText>
            <p>Your camera will be accessible for everyone after you forwarded it inside your router. To prevent unwanted access, please make sure to change the user logins before you start forwarding the camera! The user logins can be adjusted in the <Link to="/Web_User_Interface/1080p_Series/System/User/">User Menu</Link>.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

