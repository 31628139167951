import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Please be advised">
        <EuiText>
          <p>The accuracy of the positions will degrade over time. Please restart your camera once in a while to enforce a re-calibration.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

