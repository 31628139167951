import React from 'react'
import Helmet from 'react-helmet'


export default function SeoHelmet(props) {

  return (
    <Helmet defaultTitle={`INSTAR Wiki 2.5`} titleTemplate={`%s | INSTAR Wiki 2.5 | INSTAR Deutschland GmbH`}>
    
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="icon" href="/en/favicon.ico" />
  
      {/* General tags */}
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta name="image" content={`https://wiki.instar.com/en${props.image}`} />
  
      {/* OpenGraph tags */}
      <meta property="og:url" content={`https://wiki.instar.com${props.location}`} />
      <meta name="og:type" content="website" />
      <meta name="og:site_name" content="INSTAR Wiki 2.5" />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content={`https://wiki.instar.com/en${props.image}`} />

      {/* OpenGraph Article tas */}
      <meta property="og:article:published_time" content={props.dateChanged} />
      <meta property="og:article:modified_time" content={props.dateChanged} />
      <meta property="og:article:author" content={props.author} />
      <meta property="og:article:section" content="Technology" />
      <meta property="og:article:tag" content={props.tag} />
  
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@INSTAR_DE" />
      <meta name="twitter:creator" content="@INSTAR_DE" />
      <meta name="twitter:title" content={props.title} />
      <meta name="twitter:description" content={props.description} />
      <meta name="twitter:image" content={`https://wiki.instar.com/en${props.twitter}`} />
  
      <link
        rel="canonical"
        href={`https://wiki.instar.com${props.location}`}
      />
      <html lang="en" />
    </Helmet>
  );
}

SeoHelmet.defaultProps = {
  dateChanged: "2021-07-22",
  tag: "INSTAR IP Camera",
  author: "Mike Polinowski",
  image: "/images/Search/QI_SearchThumb_SetupWizard.png",
  twitter: "/images/Search/QI_SearchThumb_SetupWizard.webp",
  title: "INSTAR Wiki :: Knowledgebase for IP Cameras and Network Surveillance",
  description: 'INSTAR is one of the best-known brands of high quality security technology developed in Germany. INSTAR offers indoor / outdoor IP cameras / surveillance cameras and also offers a unique cloud capture platform including false alarms and object detection. All INSTAR cameras are & quot; not & quot; Cloud-bound and can be integrated easily without Internet in the local network via LAN, WLAN or PoE. No matter which surveillance camera of INSTAR you use, all cameras are compatible with third-party systems such as. Synology, QNAP, Asustor, Netgear, Loxone, KNX, Homeatic and many more. As a supplier of WLAN network cameras, HD IP cameras, controllable IP cameras, IP cameras with infrared night vision as well as PoE injectors, Gigabit PoE injectors, Powerline adapters, routers, switches, motion detectors with PIR and microwaves, microphones, lenses, a patented heater, INSTAR also offers additional accessories for your IP surveillance camera.',
}