import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Photoseries">
        <EuiText>
          <p>
            Take snapshots at an adjustable time interval and merge them in third-party software to create a TimeLapse video. You can also upload the snapshots with a fixed file name. This will always overwrite the last file and allow you to display a live image from your camera on your website.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

