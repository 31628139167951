import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="SSL Certificate">
        <EuiText>
          <p>
            You want to use your own SSL certificate for your own DDNS domain? No problem!
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

