import { EuiSpacer } from '@elastic/eui';
import AudioAlarmTable from 'components/Web_User_Interface/720p_Series/Alarm/Audio_Alarm/audioAlarmTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  AudioAlarmTable,
  BreadCrumbs,
  SEOHelmet,
  React
};