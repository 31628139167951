import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


import softwareAndroidIVSearchIcon from '../../../images/Search/P_SearchThumb_InstarVision_Android.webp'
import softwareAndroidTinyCamSearchIcon from '../../../images/Search/P_SearchThumb_TinyCam.webp'
import softwareAndroidCamViewerSearchIcon from '../../../images/Search/P_SearchThumb_IP_Cam_Viewer.webp'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Android/InstarVision/">
                  <img
                    src={softwareAndroidIVSearchIcon}
                    alt="InstarVision"
                  />
                </Link>
              }
              title="InstarVision"
              description="The Android app InstarVision for your Android tablet or smart phone. Control your camera when you don´t have access to your computer."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Android/InstarVision/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Android/TinyCam_Monitor/">
                  <img
                    src={softwareAndroidTinyCamSearchIcon}
                    alt="TinyCam"
                  />
                </Link>
              }
              title="TinyCam"
              description="The Android app TinyCam for your Android tablet or smart phone. Control your camera when you don´t have access to your computer."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Android/TinyCam_Monitor/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Android/IP_Cam_Viewer/">
                  <img
                    src={softwareAndroidCamViewerSearchIcon}
                    alt="IP Cam Viewer"
                  />
                </Link>
              }
              title="IP Cam Viewer"
              description="The Android app IP CamViewer for your Android tablet or smart phone. Control your camera when you don´t have access to your computer."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Android/IP_Cam_Viewer/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
