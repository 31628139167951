import FAQBox from "components/FAQs/IN-5907HD/faqBox";
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  FAQBox,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};