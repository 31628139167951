import { EuiSpacer } from '@elastic/eui';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemGetRtspPerm';
import RTSPConfigTable from 'components/Web_User_Interface/1440p_Series/Network/RTSP/rtspConfigTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  PermissionBox,
  RTSPConfigTable,
  BreadCrumbs,
  SEOHelmet,
  React
};