import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Network/WiFi/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import DangerBox1 from 'components/Web_User_Interface/1440p_Series/Network/WiFi/DangerBox1';
import DangerBox2 from 'components/Web_User_Interface/1440p_Series/Network/WiFi/DangerBox2';
import DangerBox3 from 'components/Web_User_Interface/1440p_Series/Network/WiFi/DangerBox3';
import PrimaryBox from 'components/Web_User_Interface/1440p_Series/Network/WiFi/PrimaryBox';
import WiFiTable from 'components/Web_User_Interface/1440p_Series/Network/WiFi/wifiTable';
import WiFiAdHocTable from 'components/Web_User_Interface/1440p_Series/Network/WiFi/wifiAdHocTable';
import YoutubeVideoCard from 'components/Web_User_Interface/1440p_Series/Network/WiFi/YoutubeVideoCard';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  SuccessBox,
  PermissionBox,
  DangerBox1,
  DangerBox2,
  DangerBox3,
  PrimaryBox,
  WiFiTable,
  WiFiAdHocTable,
  YoutubeVideoCard,
  BreadCrumbs,
  SEOHelmet,
  React
};