import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class specTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "hardwareColumn", title: "Hardware" },
        { name: "specColumn", title: "Specification" }
      ],
      rows: [
        { hardwareColumn: 'Sensor', specColumn: '1/3” Full HD  CMOS' },
        { hardwareColumn: 'Lense', specColumn: 'f = 3.6mm; 1080P' },
        { hardwareColumn: 'Angle of View', specColumn: '~110° diagonal' },
        { hardwareColumn: 'Video Framerate', specColumn: '30fps' },
        { hardwareColumn: 'Supported Resolutions', specColumn: '1920 x 1080' },
        { hardwareColumn: 'Image processing', specColumn: 'Support 3D denoising, automatic white balance, automatic improve image quality' },
        { hardwareColumn: 'Audio', specColumn: 'Built-in microphone' },
        { hardwareColumn: 'IR LEDs', specColumn: 'no' },
        { hardwareColumn: 'IRcut Filter', specColumn: 'no' },
        { hardwareColumn: 'USB Port', specColumn: 'USB 2.0' },
        { hardwareColumn: 'Power Supply', specColumn: '91mm x 80mm x 33mm' },
        { hardwareColumn: 'Power Supply', specColumn: 'powered by USB' },
        { hardwareColumn: 'Operating Temperature', specColumn: 'minus 5°C to 55°C' },
        { hardwareColumn: 'Working humidity', specColumn: '10% – 90%' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

specTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(specTable)


// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'

// const detectorRow = [
//   'Sensor',
//   'Lense',
//   'Angle of View',
//   'Video Framerate',
//   'Supported Resolutions',
//   'Image processing',
//   'Audio',
//   'IR LEDs',
//   'IRcut Filter',
//   'USB Port',
//   'Power Supply',
//   'Power Supply',
//   'Operating Temperature',
//   'Working humidity'
// ]

// const optionRow = [
//   '1/3” Full HD  CMOS',
//   'f = 3.6mm; 1080P',
//   '~110° diagonal',
//   '30fps',
//   '1920 x 1080',
//   'Support 3D denoising, automatic white balance, automatic improve image quality',
//   'Built-in microphone',
//   'no',
//   'no',
//   'USB 2.0',
//   '91mm x 80mm x 33mm',
//   'powered by USB',
//   'minus 5°C to 55°C',
//   '10% – 90%'
// ]

// // Render your table
// const SpecTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={detectorRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Hardware</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {detectorRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={250}
//         />
//         <Column
//           header={<Cell>Specification</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {optionRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={2}
//           width={600}
//         />
//       </Table>
//     )
// }

// export default SpecTable