import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Attention">
        <EuiText>
          <p>If you have problems by receiving to many alarms you can solve that by changing your settings. Always set the sensitivity so that the motion you want to detect is detected by the camera - simply test it by walking in front of your camera. If this does not trigger an alert, you can either increase the sensitivity of the detection, or decrease the size of the detection area - the smaller the area, the higher, percentage, is the change of pixels inside the area by the event. On the other hand, if you have to increase the detection area, you also have to increase the sensitivity of the detection.</p>
          <p>To avoid unwanted motion detection please avoid covering areas like the sky with moving clouds, busy streets in the background or big reflecting areas. To find the right camera position you can change the viewing angle a little bit or mount the camera on a different area which suits better to protects you home.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

