import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Series_CGI_List/Multimedia_Menu/Audio/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Multimedia_Menu/1440pCGIs-WebUI_Multimedia_Audio.png'
                    alt="1440p Series CGI List - Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Audio"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Series_CGI_List/Multimedia_Menu/Video/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Multimedia_Menu/1440pCGIs-WebUI_Multimedia_Video.png'
                    alt="1440p Series CGI List - Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Video"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Series_CGI_List/Multimedia_Menu/Image/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Multimedia_Menu/1440pCGIs-WebUI_Multimedia_Image.png'
                    alt="1440p Series CGI List - Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Image"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Series_CGI_List/Multimedia_Menu/Overlays/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Multimedia_Menu/1440pCGIs-WebUI_Multimedia_Overlays.png'
                    alt="1440p Series CGI List - Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Overlays"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Series_CGI_List/Multimedia_Menu/Privacy_Mask/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Multimedia_Menu/1440pCGIs-WebUI_Multimedia_Privacy.png'
                    alt="1440p Series CGI List - Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Privacy Mask"
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
