import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/styles'
import {
  SortingState, SelectionState, FilteringState, GroupingState,
  IntegratedFiltering, IntegratedGrouping, IntegratedSelection,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table, TableHeaderRow, TableFilterRow, TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class MQTTGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
        columns: [
          { name: "category", title: "Category" },
          { name: "feature", title: "MQTT Topic" },
          { name: "property", title: "MQTT Payload" }
        ],
        rows: [
          { category: 'Network', feature: 'network/config/dhcp', property: '{"val":"on"}, {"val":"off"}' },
          { category: 'Network', feature: 'network/config/ipaddr', property: '{"val":"192.168.178.23"}' },
          { category: 'Network', feature: 'network/config/netmask', property: '{"val":"255.255.255.0"}' },
          { category: 'Network', feature: 'network/config/gateway', property: '{"val":"192.168.178.1"}' },
          { category: 'Network', feature: 'network/config/dns', property: '{"val":"192.168.178.1"}' },
          { category: 'Network', feature: 'network/config/http', property: '{"val":"80"}' },
          { category: 'Network', feature: 'network/config/https', property: '{"val":"443"}' },
          { category: 'Network', feature: 'network/config/rtspauth', property: '{"val":"1"}, {"val":"0"}' },
          { category: 'Network', feature: 'network/config/rtspport', property: '{"val":"554"}' },
          { category: 'Network', feature: 'network/config/rtmpport', property: '{"val":"1935"}' },
          { category: 'Network', feature: 'network/wifi/enable', property: '{"val":"1"}, {"val":"0"}' },
          { category: 'Network', feature: 'network/wifi/ssid', property: '{"val":"My-Network"}' },
          { category: 'Network', feature: 'network/wifi/encryption', property: '{"val":"0"} - {"val":"3"}' },
          { category: 'Network', feature: 'network/wifi/key', property: '{"val":"secret-password"}' },
          { category: 'Network', feature: 'network/wifi/encryptiontype', property: '{"val":"1"}, {"val":"0"}' },
          { category: 'Network', feature: 'network/remote/instarddns', property: '{"val":"1"}, {"val":"0"}' },
          { category: 'Network', feature: 'network/remote/otherddns', property: '{"val":"1"}, {"val":"0"}' },
          { category: 'Network', feature: 'network/remote/instarp2p', property: '{"val":"1"}, {"val":"0"}' },
          { category: 'Network', feature: 'network/upnp/enable', property: '{"val":"1"}, {"val":"0"}' },
          { category: 'Network', feature: 'network/onvif/enable', property: '{"val":"1"}, {"val":"0"}' },
          { category: 'Network', feature: 'network/onvif/auth', property: '{"val":"1"}, {"val":"0"}' },
          { category: 'Network', feature: 'network/onvif/timeset', property: '{"val":"1"}, {"val":"0"}' },
          { category: 'Network', feature: 'network/onvif/port', property: '{"val":"8080"}' },
          { category: 'Network', feature: 'network/mqtt', property: '{"val":"1;0;127.0.0.1;1883;8883;1;admin;instar"}' },
          { category: 'Multimedia', feature: 'multimedia/audio/outvolume', property: '{"val":"1"} - {"val":"100"}' },
          { category: 'Multimedia', feature: 'multimedia/audio/involume', property: '{"val":"1"} - {"val":"100"}' },
          { category: 'Multimedia', feature: 'multimedia/audio/enable/high', property: '{"val":"1"}, {"val":"0"}' },
          { category: 'Multimedia', feature: 'multimedia/audio/enable/mid', property: '{"val":"1"}, {"val":"0"}' },
          { category: 'Multimedia', feature: 'multimedia/audio/enable/low', property: '{"val":"1"}, {"val":"0"}' },
          { category: 'Multimedia', feature: 'multimedia/video/high/bitrate', property: '{"val":"512"}, {"val":"1024"}, {"val":"2048"}, {"val":"3072"}, {"val":"4069"}' },
          { category: 'Multimedia', feature: 'multimedia/video/mid/bitrate', property: '{"val":"256"}, {"val":"512"}, {"val":"768"}, {"val":"1024"}, {"val":"2048"}' },
          { category: 'Multimedia', feature: 'multimedia/video/low/bitrate', property: '{"val":"90"}, {"val":"128"}, {"val":"256"}, {"val":"512"}' },
          { category: 'Multimedia', feature: 'multimedia/video/high/compression', property: '{"val":"1"} - {"val":"6"}' },
          { category: 'Multimedia', feature: 'multimedia/video/mid/compression', property: '{"val":"1"} - {"val":"6"}' },
          { category: 'Multimedia', feature: 'multimedia/video/low/compression', property: '{"val":"1"} - {"val":"6"}' },
          { category: 'Multimedia', feature: 'multimedia/video/high/vbr', property: '{"val":"1"}, {"val":"0"}' },
          { category: 'Multimedia', feature: 'multimedia/video/mid/vbr', property: '{"val":"1"}, {"val":"0"}' },
          { category: 'Multimedia', feature: 'multimedia/video/low/vbr', property: '{"val":"1"}, {"val":"0"}' },
          { category: 'Multimedia', feature: 'multimedia/video/high/fps', property: '{"val":"1"} - {"val":"25"}' },
          { category: 'Multimedia', feature: 'multimedia/video/mid/fps', property: '{"val":"1"} - {"val":"25"}' },
          { category: 'Multimedia', feature: 'multimedia/video/low/fps', property: '{"val":"1"} - {"val":"25"}' },
          { category: 'Multimedia', feature: 'multimedia/video/high/gop', property: '{"val":"10"} - {"val":"150"}' },
          { category: 'Multimedia', feature: 'multimedia/video/mid/gop', property: '{"val":"10"} - {"val":"150"}' },
          { category: 'Multimedia', feature: 'multimedia/video/low/gop', property: '{"val":"10"} - {"val":"150"}' },
          { category: 'Multimedia', feature: 'multimedia/video/webui', property: '{"val":"11"}, {"val":"12"}, {"val":"13"}' },
          { category: 'Multimedia', feature: 'multimedia/video/mjpeg', property: '{"val":"true"}, {"val":"false"}' },
          { category: 'Multimedia', feature: 'multimedia/image/brightness', property: '{"val":"1"} - {"val":"100"}' },
          { category: 'Multimedia', feature: 'multimedia/image/brightness/night', property: '{"val":"1"} - {"val":"100"}' },
          { category: 'Multimedia', feature: 'multimedia/image/saturation', property: '{"val":"1"} - {"val":"225"}' },
          { category: 'Multimedia', feature: 'multimedia/image/saturation/night', property: '{"val":"1"} - {"val":"225"}' },
          { category: 'Multimedia', feature: 'multimedia/image/hue', property: '{"val":"1"} - {"val":"100"}' },
          { category: 'Multimedia', feature: 'multimedia/image/hue/night', property: '{"val":"1"} - {"val":"100"}' },
          { category: 'Multimedia', feature: 'multimedia/image/contrast', property: '{"val":"1"} - {"val":"100"}' },
          { category: 'Multimedia', feature: 'multimedia/image/contrast/night', property: '{"val":"1"} - {"val":"100"}' },
          { category: 'Multimedia', feature: 'multimedia/image/sharpness', property: '{"val":"1"} - {"val":"100"}' },
          { category: 'Multimedia', feature: 'multimedia/image/sharpness/night', property: '{"val":"1"} - {"val":"100"}' },
          { category: 'Multimedia', feature: 'multimedia/image/gamma', property: '{"val":"0"} - {"val":"3"}' },
          { category: 'Multimedia', feature: 'multimedia/image/gamma/night', property: '{"val":"0"} - {"val":"3"}' },
          { category: 'Multimedia', feature: 'multimedia/image/exposure', property: '{"val":"1"} - {"val":"255"}' },
          { category: 'Multimedia', feature: 'multimedia/image/exposure/night', property: '{"val":"1"} - {"val":"255"}' },
          { category: 'Multimedia', feature: 'multimedia/image/flip', property: '{"val":"on"}, {"val":"off"}' },
          { category: 'Multimedia', feature: 'multimedia/image/flip/night', property: '{"val":"on"}, {"val":"off"}' },
          { category: 'Multimedia', feature: 'multimedia/image/mirror', property: '{"val":"on"}, {"val":"off"}' },
          { category: 'Multimedia', feature: 'multimedia/image/mirror/night', property: '{"val":"on"}, {"val":"off"}' },
          { category: 'Multimedia', feature: 'multimedia/image/autodenoise', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Multimedia', feature: 'multimedia/image/manualdenoise', property: '{"val":"0"} - {"val":"255"}' },
          { category: 'Multimedia', feature: 'multimedia/image/autowdr', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Multimedia', feature: 'multimedia/image/autowdrval', property: '{"val":"0"} - {"val":"2"}' },
          { category: 'Multimedia', feature: 'multimedia/image/manualwdrval', property: '{"val":"0"} - {"val":"255"}' },
          { category: 'Multimedia', feature: 'multimedia/image/hardwarewdr', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Multimedia', feature: 'multimedia/image/lenserectify', property: '{"val":"0"} - {"val":"399"}' },
          { category: 'Multimedia', feature: 'multimedia/overlay/showname', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Multimedia', feature: 'multimedia/overlay/cameraname', property: '{"val":"Office Cam"}' },
          { category: 'Multimedia', feature: 'multimedia/overlay/showtimestamp', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region1/enable', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region1/color', property: '{"val":"000000"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region1/xorigin', property: '{"val":"0"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region1/yorigin', property: '{"val":"840"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region1/height', property: '{"val":"240"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region1/width', property: '{"val":"240"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region2/enable', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region2/color', property: '{"val":"000000"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region2/xorigin', property: '{"val":"0"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region2/yorigin', property: '{"val":"840"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region2/height', property: '{"val":"240"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region2/width', property: '{"val":"240"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region3/enable', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region3/color', property: '{"val":"000000"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region3/xorigin', property: '{"val":"0"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region3/yorigin', property: '{"val":"840"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region3/height', property: '{"val":"240"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region3/width', property: '{"val":"240"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region4/enable', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region4/color', property: '{"val":"000000"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region4/xorigin', property: '{"val":"0"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region4/yorigin', property: '{"val":"840"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region4/height', property: '{"val":"240"}' },
          { category: 'Multimedia', feature: 'multimedia/privacy/region4/width', property: '{"val":"240"}' },
          { category: 'Features', feature: 'features/email/sender', property: '{"val":"sender@email.com"}' },
          { category: 'Features', feature: 'features/email/receiver', property: '{"val":"receiver@email.com"}' },
          { category: 'Features', feature: 'features/email/subject', property: '{"val":"Alarm Email from my Office Cam"}' },
          { category: 'Features', feature: 'features/email/text', property: '{"val":"Email body text"}' },
          { category: 'Features', feature: 'features/email/server', property: '{"val":"mx.instar.email"}' },
          { category: 'Features', feature: 'features/email/ssl', property: '{"val":"0"} - {"val":"3"}' },
          { category: 'Features', feature: 'features/email/port', property: '{"val":"587"}' },
          { category: 'Features', feature: 'features/email/authentication', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Features', feature: 'features/email/username', property: '{"val":"emailuser@email.com"}' },
          { category: 'Features', feature: 'features/email/password', property: '{"val":"password"}' },
          { category: 'Features', feature: 'features/ftp/server', property: '{"val":"192.168.178.1"}' },
          { category: 'Features', feature: 'features/ftp/port', property: '{"val":"21"}' },
          { category: 'Features', feature: 'features/ftp/username', property: '{"val":"ftpuser"}' },
          { category: 'Features', feature: 'features/ftp/password', property: '{"val":"password"}' },
          { category: 'Features', feature: 'features/ftp/pasvmode', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Features', feature: 'features/ftp/dirname', property: '{"val":".%2Fdirectory"}' },
          { category: 'Features', feature: 'features/ftp/dirmode', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Features', feature: 'features/ftp/ssl', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Features', feature: 'features/nightvision/autoled', property: '{"val":"auto"}, {"val":"close"}' },
          { category: 'Features', feature: 'features/nightvision/autoircut', property: '{"val":"auto"}, {"val":"open"}, {"val":"close"}' },
          { category: 'Features', feature: 'features/nightvision/manualswitchon', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Features', feature: 'features/nightvision/manualswitchontime', property: '{"val":"75605"}' },
          { category: 'Features', feature: 'features/nightvision/manualswitchoff', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Features', feature: 'features/nightvision/manualswitchofftime', property: '{"val":"75605"}' },
          { category: 'Features', feature: 'features/nightvision/upperirthreshold', property: '{"val":"1"},  {"val":"270"}' },
          { category: 'Features', feature: 'features/nightvision/lowerirthreshold', property: '{"val":"1"},  {"val":"270"}' },
          { category: 'Features', feature: 'features/nightvision/currentbrightness', property: '{"val":"1"},  {"val":"270"}' },
          { category: 'Features', feature: 'features/ptz/move', property: '{"val":"left"}, {"val":"right"}, {"val":"down"}, {"val":"up"}, {"val":"stop"}, {"val":"focusin"}, {"val":"focusout"}, {"val":"zoomout"}, {"val":"zoomin"}, {"val":"tour"}, {"val":"hscan"}, {"val":"vscan"}' },
          { category: 'Features', feature: 'features/ptz/movestep', property: '{"val":"left"}, {"val":"right"}, {"val":"down"}, {"val":"up"}, {"val":"stop"}, {"val":"focusin"}, {"val":"focusout"}, {"val":"zoomout"}, {"val":"zoomin"}' },
          { category: 'Features', feature: 'features/ptz/preset', property: '{"val":"0"} - {"val":"7"} & {"val":"88"} (Start Scan)' },
          { category: 'Features', feature: 'features/ptz/savepreset', property: '{"val":"0"} - {"val":"7"}' },
          { category: 'Features', feature: 'features/ptz/scan', property: '{"val":"hscan"}, {"val":"vscan"}, {"val":"tour"}' },
          { category: 'Features', feature: 'features/ptz/calibration/enable', property: '{"val":"off"}, {"val":"on"}' },
          { category: 'Features', feature: 'features/ptz/startpreset/enable', property: '{"val":"off"}, {"val":"on"}' },
          { category: 'Features', feature: 'features/ptz/startpreset/position', property: '{"val":"1"} - {"val":"8"}' },
          { category: 'Features', feature: 'features/ptz/parkpreset/interval', property: '{"val":"30"}, {"val":"900"}' },
          { category: 'Features', feature: 'features/ptz/alarmpreset/enable', property: '{"val":"off"}, {"val":"on"}' },
          { category: 'Features', feature: 'features/ptz/alarmpreset/position', property: '{"val":"1"}, {"val":"8"}' },
          { category: 'Features', feature: 'features/ptz/ptzalarmmask', property: '{"val":"0"} - {"val":"1"}' },
          { category: 'Features', feature: 'features/ptz/panspeed', property: '{"val":"0"} - {"val":"2"}' },
          { category: 'Features', feature: 'features/ptz/tiltspeed', property: '{"val":"0"} - {"val":"2"}' },
          { category: 'Features', feature: 'features/ptz/panscan', property: '{"val":"1"} - {"val":"50"}' },
          { category: 'Features', feature: 'features/ptz/tiltscan', property: '{"val":"1"} - {"val":"50"}' },
          { category: 'Features', feature: 'features/ptz/parkpreset/enable', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Features', feature: 'features/ptz/parkpreset/position', property: '{"val":"1"}, {"val":"8"}' },
          { category: 'Features', feature: 'features/ptz/parkinterval', property: '{"val":"600"}' },
          { category: 'Features', feature: 'features/ptz/tourpresets', property: '{"val":"0;1;2;-1;-1;-1;-1;-1"}' },
          { category: 'Features', feature: 'features/ptz/tourinterval', property: '{"val":"60;120;300;300;300;300;300;300"}' },
          { category: 'Features', feature: 'features/ptz/tourrepeats', property: '{"val":"1"} - {"val":"50"}' },
          { category: 'Features', feature: 'features/manualrec/duration', property: '{"val":"0"} - {"val":"600"}' },
          { category: 'Features', feature: 'features/manualrec/start', property: '{"val":"60"} - {"val":"900"}' },
          { category: 'Features', feature: 'features/manualrec/stop', property: '{"val":"off"}' },
          { category: 'Features', feature: 'features/indicator/power', property: '{"val":"off"}, {"val":"on"}' },
          { category: 'Features', feature: 'features/indicator/wifi', property: '{"val":"off"}, {"val":"on"}' },
          { category: 'Alarm', feature: 'alarm/pushalarm', property: '{"val":"1"}' },
          { category: 'Alarm', feature: 'alarm/actions/email', property: '{"val":"off"}, {"val":"on"}' },
          { category: 'Alarm', feature: 'alarm/actions/snapshot2sd', property: '{"val":"off"}, {"val":"on"}' },
          { category: 'Alarm', feature: 'alarm/actions/snapshot2ftp', property: '{"val":"off"}, {"val":"on"}' },
          { category: 'Alarm', feature: 'alarm/actions/alarmsnapshots/sd', property: '{"val":"0"}, {"val":"15"}' },
          { category: 'Alarm', feature: 'alarm/actions/alarmsnapshots/email', property: '{"val":"0"}, {"val":"15"}' },
          { category: 'Alarm', feature: 'alarm/actions/alarmsnapshots/ftp', property: '{"val":"0"}, {"val":"15"}' },
          { category: 'Alarm', feature: 'alarm/actions/video2sd', property: '{"val":"off"}, {"val":"on"}' },
          { category: 'Alarm', feature: 'alarm/actions/video2ftp', property: '{"val":"off"}, {"val":"on"}' },
          { category: 'Alarm', feature: 'alarm/actions/videolength', property: '{"val":"15"}, {"val":"30"}, {"val":"45"}, {"val":"60"}' },
          { category: 'Alarm', feature: 'alarm/actions/pir/enable', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Alarm', feature: 'alarm/actions/pir/flag', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Alarm', feature: 'alarm/actions/linkareas', property: '{"val":"off"}, {"val":"on"}' },
          { category: 'Alarm', feature: 'alarm/actions/alarmout', property: '{"val":"off"}, {"val":"on"}' },
          { category: 'Alarm', feature: 'alarm/actions/alarmin', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Alarm', feature: 'alarm/actions/alarminmode', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Alarm', feature: 'alarm/actions/alarmsignal', property: '{"val":"off"}, {"val":"on"}' },
          { category: 'Alarm', feature: 'alarm/actions/audioalarm', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Alarm', feature: 'alarm/actions/audioalarmsensitivity', property: '{"val":"10"} - {"val":"100"}' },
          { category: 'Alarm', feature: 'alarm/area1/enable', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Alarm', feature: 'alarm/area1/sensitivity', property: '{"val":"1"} - {"val":"100"}' },
          { category: 'Alarm', feature: 'alarm/area1/xorigin', property: '{"val":"0"} - {"val":"1920"}' },
          { category: 'Alarm', feature: 'alarm/area1/yorigin', property: '{"val":"0"} - {"val":"1080"}' },
          { category: 'Alarm', feature: 'alarm/area1/height', property: '{"val":"1"} - {"val":"1080"}' },
          { category: 'Alarm', feature: 'alarm/area1/width', property: '{"val":"1"} - {"val":"1920"}' },
          { category: 'Alarm', feature: 'alarm/area2/enable', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Alarm', feature: 'alarm/area2/sensitivity', property: '{"val":"1"} - {"val":"100"}' },
          { category: 'Alarm', feature: 'alarm/area2/xorigin', property: '{"val":"0"} - {"val":"1920"}' },
          { category: 'Alarm', feature: 'alarm/area2/yorigin', property: '{"val":"0"} - {"val":"1080"}' },
          { category: 'Alarm', feature: 'alarm/area2/height', property: '{"val":"1"} - {"val":"1080"}' },
          { category: 'Alarm', feature: 'alarm/area2/width', property: '{"val":"1"} - {"val":"1920"}' },
          { category: 'Alarm', feature: 'alarm/area3/enable', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Alarm', feature: 'alarm/area3/sensitivity', property: '{"val":"1"} - {"val":"100"}' },
          { category: 'Alarm', feature: 'alarm/area3/xorigin', property: '{"val":"0"} - {"val":"1920"}' },
          { category: 'Alarm', feature: 'alarm/area3/yorigin', property: '{"val":"0"} - {"val":"1080"}' },
          { category: 'Alarm', feature: 'alarm/area3/height', property: '{"val":"1"} - {"val":"1080"}' },
          { category: 'Alarm', feature: 'alarm/area3/width', property: '{"val":"1"} - {"val":"1920"}' },
          { category: 'Alarm', feature: 'alarm/area4/enable', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Alarm', feature: 'alarm/area4/sensitivity', property: '{"val":"1"} - {"val":"100"}' },
          { category: 'Alarm', feature: 'alarm/area4/xorigin', property: '{"val":"0"} - {"val":"1920"}' },
          { category: 'Alarm', feature: 'alarm/area4/yorigin', property: '{"val":"0"} - {"val":"1080"}' },
          { category: 'Alarm', feature: 'alarm/area4/height', property: '{"val":"1"} - {"val":"1080"}' },
          { category: 'Alarm', feature: 'alarm/area4/width', property: '{"val":"1"} - {"val":"1920"}' },
          { category: 'Alarm', feature: 'alarm/schedule/sunday', property: '{"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Alarm', feature: 'alarm/schedule/monday', property: '{"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Alarm', feature: 'alarm/schedule/tuesday', property: '{"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Alarm', feature: 'alarm/schedule/wednesday', property: '{"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Alarm', feature: 'alarm/schedule/thursday', property: '{"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Alarm', feature: 'alarm/schedule/friday', property: '{"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Alarm', feature: 'alarm/schedule/saturday', property: '{"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Alarm', feature: 'alarm/schedule/sunday/webui', property: '{"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Alarm', feature: 'alarm/schedule/monday/webui', property: '{"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Alarm', feature: 'alarm/schedule/tuesday/webui', property: '{"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Alarm', feature: 'alarm/schedule/wednesday/webui', property: '{"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Alarm', feature: 'alarm/schedule/thursday/webui', property: '{"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Alarm', feature: 'alarm/schedule/friday/webui', property: '{"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Alarm', feature: 'alarm/schedule/saturday/webui', property: '{"val":"NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Alarm', feature: 'alarm/schedule/areagroup12/enable', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Alarm', feature: 'alarm/schedule/areagroup12/time', property: '{"val":"21610"}' },
          { category: 'Alarm', feature: 'alarm/schedule/areagroup34/enable', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Alarm', feature: 'alarm/schedule/areagroup34/time', property: '{"val":"64810"}' },
          { category: 'Alarm', feature: 'alarm/push/enable', property: '{"val":"off"}, {"val":"on"}' },
          { category: 'Alarm', feature: 'alarm/alarmserver/enable', property: '{"val":"off"}, {"val":"on"}' },
          { category: 'Alarm', feature: 'alarm/alarmserver/address', property: '{"val":"192.168.2.88"}' },
          { category: 'Alarm', feature: 'alarm/alarmserver/port', property: '{"val":"80"}' },
          { category: 'Alarm', feature: 'alarm/alarmserver/path', property: '{"val":"/dev/sps/io/alarmserver/Pulse"}' },
          { category: 'Alarm', feature: 'alarm/alarmserver/query1', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Alarm', feature: 'alarm/alarmserver/queryattr1', property: '{"val":"command name"}' },
          { category: 'Alarm', feature: 'alarm/alarmserver/queryval1', property: '{"val":"command value"}' },
          { category: 'Alarm', feature: 'alarm/alarmserver/query2', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Alarm', feature: 'alarm/alarmserver/queryattr2', property: '{"val":"command name"}' },
          { category: 'Alarm', feature: 'alarm/alarmserver/queryval2', property: '{"val":"command value"}' },
          { category: 'Alarm', feature: 'alarm/alarmserver/query3', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Alarm', feature: 'alarm/alarmserver/queryattr3', property: '{"val":"command name"}' },
          { category: 'Alarm', feature: 'alarm/alarmserver/queryval3', property: '{"val":"command value"}' },
          { category: 'Alarm', feature: 'alarm/alarmserver/appendtrigger', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Alarm', feature: 'alarm/alarmserver/authentication', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Alarm', feature: 'alarm/alarmserver/username', property: '{"val":"admin"}' },
          { category: 'Alarm', feature: 'alarm/alarmserver/password', property: '{"val":"password"}' },
          { category: 'Tasks', feature: 'task/photoseries/sdcard/enable', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Tasks', feature: 'task/photoseries/sdcard/interval', property: '{"val":"1"}, {"val":"86400"}' },
          { category: 'Tasks', feature: 'task/photoseries/email/enable', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Tasks', feature: 'task/photoseries/email/interval', property: '{"val":"1"}, {"val":"1440"}' },
          { category: 'Tasks', feature: 'task/photoseries/ftp/enable', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Tasks', feature: 'task/photoseries/ftp/interval', property: '{"val":"1"}, {"val":"86400"}' },
          { category: 'Tasks', feature: 'task/photoseries/ftp/fixedname', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Tasks', feature: 'task/photoseries/ftp/filename', property: '{"val":"filename"}' },
          { category: 'Tasks', feature: 'task/photoseries/schedule/sunday', property: '{"val":"-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Tasks', feature: 'task/videorecording/enable', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'Tasks', feature: 'task/videorecording/resolution', property: '{"val":"11"}, {"val":"12"}, {"val":"13"}' },
          { category: 'Tasks', feature: 'task/videorecording/length', property: '{"val":"60"} - {"val":"900"}' },
          { category: 'Tasks', feature: 'task/videorecording/schedule/sunday', property: '{"val":"-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Tasks', feature: 'task/videorecording/schedule/monday', property: '{"val":"-week1=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week1=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Tasks', feature: 'task/videorecording/schedule/tuesday', property: '{"val":"-week2=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week2=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Tasks', feature: 'task/videorecording/schedule/wednesday', property: '{"val":"-week3=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week3=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Tasks', feature: 'task/videorecording/schedule/thursday', property: '{"val":"-week4=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week4=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Tasks', feature: 'task/videorecording/schedule/friday', property: '{"val":"-week5=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week5=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'Tasks', feature: 'task/videorecording/schedule/saturday', property: '{"val":"-week6=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN"} - {"val":"-week6=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"}' },
          { category: 'System', feature: 'system/user/admin/name', property: '{"val":"admin"}' },
          { category: 'System', feature: 'system/user/admin/password', property: '{"val":"instar"}' },
          { category: 'System', feature: 'system/user/user/enable', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'System', feature: 'system/user/user/name', property: '{"val":"user"}' },
          { category: 'System', feature: 'system/user/user/password', property: '{"val":"instar"}' },
          { category: 'System', feature: 'system/user/guest/enable', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'System', feature: 'system/user/guest/name', property: '{"val":"guest"}' },
          { category: 'System', feature: 'system/user/guest/password', property: '{"val":"instar"}' },
          { category: 'System', feature: 'system/user/iplogging', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'System', feature: 'system/reboot', property: '{"val":"1"}' },
          { category: 'System', feature: 'system/reboot/planned', property: '{"val":"0"}, {"val":"1"}' },
          { category: 'System', feature: 'system/reboot/time', property: '{"val":"0"} - {"val":"86400‬"}' },
          { category: 'System', feature: 'system/reset', property: '{"val":"1"}' }
        ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns} >

          <FilteringState />

          <SortingState
            defaultSorting={[
              { columnName: 'category', direction: 'asc' },
            ]}
          />
          <GroupingState
            defaultGrouping={[{ columnName: 'category' }]}
            defaultExpandedGroups={['Network']}
          />
          <SelectionState />

          <IntegratedFiltering />
          <IntegratedGrouping />
          <IntegratedSelection />
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
          <TableFilterRow />
          <TableGroupRow />
        </Grid>
      </Paper>
    );
  }
}

MQTTGrid.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(MQTTGrid)