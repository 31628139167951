import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PTZTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Pan, Tilt & Zoom" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Enable Start-Up Position / Action', descriptionColumn: 'Activate a position / action the camera should move to / do after a the start-up procedure.' },
        { actionColumn: 'Select Start-Up Position / Action', descriptionColumn: 'Select a saved position your camera should move to after boot up or activate the vertical scan or Tour Function' },
        { actionColumn: 'Enable Alarm Position', descriptionColumn: 'Do you want your camera to point in a specific direction when an alarm is triggered? Activate this function here.' },
        { actionColumn: 'Select Alarm Position', descriptionColumn: 'Set the position you want your camera to move to in case of an alarm here (see above).' },
        { actionColumn: 'Move to Park Position', descriptionColumn: 'Do you want your camera to always point in a specific direction by default? Activate the park position to let your camera go back to this position automatically after it was moved.' },
        { actionColumn: 'Select Park Position', descriptionColumn: 'Set the park position here (see above).' },
        { actionColumn: 'Move after X Seconds', descriptionColumn: 'How long should the camera wait before returning to the Park Positions?' },
        { actionColumn: 'PTZ Reset', descriptionColumn: 'In the Live Video area you can set a Start and End Point for the Scan function. Click the PTZ Reset to reset those limits' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PTZTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(PTZTable)