import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Indoor_Cameras/IN-8415_WQHD/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-8415WQHD.webp'
                    alt="IN-8415 WQHD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8415 WQHD"
              description="INSTAR IN-8415 2k+ WQHD Pan&Tilt Night Vision Surveillance Camera (LAN/WLAN) Small but indoor camera with 850nm infrared LEDs, PIR sensor and SD card."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Indoor_Cameras/IN-8415_WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Indoor_Cameras/IN-8403_WQHD/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-8403WQHD.webp'
                    alt="IN-8403 WQHD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8403 WQHD"
              description="INSTAR IN-8403 2k+ WQHD Night Vision Surveillance Camera (LAN/WLAN) indoor and (IP67) outdoor camera with white light and IR LED night vision, PIR sensor and SD card."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Indoor_Cameras/IN-8403_WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Indoor_Cameras/IN-8401_WQHD/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-8401WQHD.webp'
                    alt="IN-8401 WQHD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8401 WQHD"
              description="The IN-8401 2k+ WQHD is the new INSTAR indoor camera with the new 1440p chipset. And the first camera offering both 850nm and 'invisible' 940nm IR night vision."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Indoor_Cameras/IN-8401_WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Indoor_Cameras/IN-8015_HD/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-8015HD.webp'
                    alt="IN-8015 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8015 Full HD"
              description="The IN-8015 FHD is the first INSTAR camera with the new 1080p chipset. It offers all the advantages of the IN-6014 HD with a revised software and much more."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Indoor_Cameras/IN-8015_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Indoor_Cameras/IN-8003_HD/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-8003HD.webp'
                    alt="IN-8003 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8003 Full HD"
              description="The IN-8003 FHD is the first INSTAR camera with the new 1080p chipset. The weather proof housing allows you to install it both indoor and outdoor."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Indoor_Cameras/IN-8003_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Indoor_Cameras/IN-8001_HD/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-8001HD.webp'
                    alt="IN-8001 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8001 Full HD"
              description="The IN-8001 FHD is the IN-6001 HD successor with the new 1080p chipset. With it's invisible IR LEDs it is ideal for use as a baby monitor."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Indoor_Cameras/IN-8001_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Indoor_Cameras/IN-6014_HD/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-6014HD.webp'
                    alt="IN-6014 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-6014 HD"
              description="The IN-6014 HD offers all the advantages of the IN-6012 HD. But also has a 80 ° wide-angle lens and an integrated PIR motion detector."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Indoor_Cameras/IN-6014_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Indoor_Cameras/IN-6012_HD/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-6012HD.webp'
                    alt="IN-6012 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-6012 HD"
              description="The IN-6012HD is the standard HD PTZ camera for indoor surveillance. It has a standard lens with 40 ° viewing angle and IR night vision."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Indoor_Cameras/IN-6012_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Indoor_Cameras/IN-6001_HD/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-6001HD.webp'
                    alt="IN-6001 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-6001 HD"
              description="The successor of the IN-3001 VGA camera - compact, inconspicuous, IR night vision capable with a video resolution of 720p HD and integrated SD card."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Indoor_Cameras/IN-6001_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Indoor_Cameras/IN-3011/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-3011.webp'
                    alt="IN-3011"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-3011"
              description="The IN-3011 is the standard indoor camera for VGA surveillance. It has IR night vision and a, via our app, remotely controllable camera head."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Indoor_Cameras/IN-3011/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Indoor_Cameras/IN-3010/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-3011.webp'
                    alt="IN-3010"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-3010"
              description="The IN-3010 is an allround solution for indoor surveillance. The camera head is remotely controllable and equipped with nightvision diodes."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Indoor_Cameras/IN-3010/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Indoor_Cameras/IN-3003/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-3011.webp'
                    alt="IN-3003"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-3003"
              description="The IN-3003 is an compact solution for indoor surveillance. The camera head is equipped with IR diodes for infrared nightvision."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Indoor_Cameras/IN-3003/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Indoor_Cameras/IN-3001/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-3011.webp'
                    alt="IN-3001"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-3001"
              description="The IN-3001 is an compact solution for indoor surveillance. The camera head is equipped with IR diodes for infrared nightvision."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Indoor_Cameras/IN-3001/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Indoor_Cameras/IN-3003/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-3011.webp'
                    alt="IN-3003"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-3003"
              description="The IN-3003 is an compact solution for indoor surveillance. The camera head is equipped with IR diodes for infrared nightvision."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Indoor_Cameras/IN-3003/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Indoor_Cameras/IN-3001/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-3011.webp'
                    alt="IN-3001"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-3001"
              description="The IN-3001 is an compact solution for indoor surveillance. The camera head is equipped with IR diodes for infrared nightvision."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Indoor_Cameras/IN-3001/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
