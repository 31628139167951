import React from 'react'

import { Player } from 'video-react'

import {
    EuiText,
    EuiIcon,
    EuiPanel
  } from '@elastic/eui'

import { OutboundLink } from 'gatsby-plugin-gtag'

require('../../Layout/chrome/assets/video.css')

function VideoCard() {
  return (
    <EuiPanel paddingSize="m">
        <Player>
            <source src="https://wiki.instar.com/videos/Die_INSTAR_Cloud_Fehlalarmerkennung_inkl_Regenerkennung.mp4" />
        </Player>
        <EuiText>
            <p>In diesem Video zeigen wir Ihnen die neue Fehlalarmerkennung welche es exklusiv in der INSTAR Cloud Plattform gibt. Die INSTAR Cloud ist derzeit nutzbar mit allen INSTAR HD und Full HD Kameramodellen. Auf <OutboundLink href="https://www.youtube.com/watch?v=YXNnRFeySkk" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
        </EuiText>
    </EuiPanel>
  );
}

export default VideoCard