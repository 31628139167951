import React from 'react'
import 'react-vis/dist/style.css'
import {
  FlexibleWidthXYPlot,
  XAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
  Crosshair
} from 'react-vis'

const DATA = [
  [
    { x: '4 Cameras', y: 157 },
    { x: '8 Cameras', y: 250 },
    { x: '16 Cameras', y: 371 }
  ]
];

export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: []
    };
  }

  render() {
    return (
      <React.Fragment>

        <div className="chart">
          <FlexibleWidthXYPlot
            onMouseLeave={() => this.setState({ crosshairValues: [] })}
            height={300}
            xType="ordinal">

            <VerticalGridLines />

            <HorizontalGridLines />

            <XAxis />

            <VerticalBarSeries
              onNearestX={(value, { index }) =>
                this.setState({ crosshairValues: DATA.map(d => d[index]) })}
              cluster="MemoryLoad"
              color="#dd706e"
              data={DATA[0]} />

            <Crosshair values={this.state.crosshairValues} />

          </FlexibleWidthXYPlot>
        </div>

        <div className="legend">
          <DiscreteColorLegend
            orientation="horizontal"
            items={[
              {
                title: '[0] Memory Load [MB]',
                color: '#dd706e'
              }
            ]}
          />
        </div>

      </React.Fragment>
    );
  }
}