import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/CGI_Commands/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_CGICommands.webp'
                    alt="CGI Commands"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="CGI Commands"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/CGI_Commands/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/INSTAR_MQTT_Broker/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_MQTT.webp'
                    alt="INSTAR MQTT Interface"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="INSTAR MQTT"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/INSTAR_MQTT_Broker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/VPN_AVM_Fritzbox/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_VPN.webp'
                    alt="VPN Tunnel"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="VPN Tunnel"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/VPN_AVM_Fritzbox/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Website_Integration/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_WebsiteIntegration.webp'
                    alt="Website Integration"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Website Integration"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Website_Integration/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Youtube_Videostreaming_from_your_Camera/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_Youtube.webp'
                    alt="Youtube Videostreaming mit Ihrer Kamera"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Youtube Videostreaming"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Youtube_Videostreaming_from_your_Camera/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/All_Home_Automation_Tutorials/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Smarthome.webp'
                    alt="Smarthome"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Smarthome"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/All_Home_Automation_Tutorials/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Tasker/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_Tasker.webp'
                    alt="Tasker Android App"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Tasker Android App"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Tasker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Restore_WebUI/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_ResetAfterWebUIError.webp'
                    alt="Wiederherstellung der Kamera nach einem fehlerhaften WebUI-Upgrade"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Restore WebUI"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Restore_WebUI/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Restore_Firmware/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_ResetAfterFirmwareError.webp'
                    alt="Wiederherstellung Ihrer HD-Kamera nach einem fehlerhaften Firmware-Upgrade"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Restore Firmware"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Restore_Firmware/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/CCTV_vs_IP/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_IPvsCCTV.webp'
                    alt="IP vs CCTV"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP vs CCTV"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/CCTV_vs_IP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Kerberos_with_Docker/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Lupus_XT2.webp'
                    alt="Kerberos.io video surveillance and INSTAR cameras"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Kerberos.io video surveillance and INSTAR cameras"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Kerberos_with_Docker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}