import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="q3yBixX9UmI"
                    title="INSTAR IN-LAN Powerline Adapter"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="INSTAR IN-LAN Powerline Adapter"
              footer={
                <EuiText>
                    <p>Der IN-Lan 500 Powerline Adapter ist ideal zur Erstellung eines Netzwerkes über das Stromnetz, einfach einstecken und fertig. Ihre Vorteile:</p>
                    <ul>
                    <li>Nutzen Sie bestehende Stromleitungen um mit IN-LAN ein Netzwerk zu realisieren</li>
                    <li>Sehr einfache Plug n Play Technologie. Einfach in die Steckdose stecken und fertig</li>
                    <li>Super schneller Datentransfer bis zu 500Mbps</li>
                    <li>Erweitern Sie Ihr Netzwerk um so z.B. Ihre IP Kamera oder andere Netzwerkgeräte    zu verkabeln ohne Netzwerkkabel zu verlegen</li>
                    <li>Eine sehr detallierte Anleitung mach Ihnen die Installation sehr leicht</li>
                    </ul>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
