import React from 'react'
import {StaticQuery, graphql} from 'gatsby'

import {
  EuiButton,
  EuiToolTip,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  // EuiPageContentHeader,
  // EuiPageContentHeaderSection,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiText,
  // EuiFieldSearch,
  EuiTitle,
  EuiSpacer
} from '@elastic/eui'

import '../../assets/styles/gatsby-image.css'

import SearchFlyout from '../Search/SearchFlyout'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteMetaQuery {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `}
    render={data => (
      <EuiPage restrictWidth={1000}>
        <EuiPageBody style={{width: "100%", height: "100%", display: 'inline-block', overflow: "hidden" }}>
          {/* <EuiButton
            iconType="quote"
            href={data.site.siteMetadata.siteUrl}
            fill
            style={{float: 'right'}}>
            Test
          </EuiButton> */}
          <EuiTitle size="m">
            <h4>INSTAR Wiki 2.5</h4>
          </EuiTitle>
          <EuiText size="s" color="subdued">
            <p>Knowledgebase for everything IP Camera Surveillance and Smart Living</p>
          </EuiText>
          <EuiSpacer />
          <EuiPageHeader>
            <EuiPageHeaderSection>
                <EuiToolTip
                position="top"
                content={
                  <p>
                    INSTAR Forum
                  </p>
                }
                >
                  <EuiButton
                    iconType="quote"
                    href="https://forum.instar.de"
                    color="primary" size="s" fill >
                    Discuss on our Forum
                  </EuiButton>
                </EuiToolTip>
              {/* <EuiButton
                iconType="quote"
                href="https://forum.instar.de"
                color="primary" size="s" fill >
                Discuss on our Forum
              </EuiButton> */}
            </EuiPageHeaderSection>
            <EuiPageHeaderSection>
              <SearchFlyout style={{float: 'right'}}/>
            </EuiPageHeaderSection>
          </EuiPageHeader>
          <EuiPageContent>
            {/* <EuiPageContentHeader>
              <EuiPageContentHeaderSection>
              </EuiPageContentHeaderSection>
            </EuiPageContentHeader> */}
            <EuiPageContentBody style={{width: "100%", height: "100%", boxSizing: "content-box", whiteSpace: "normal", wordWrap: "break-word", overflowY: "hidden", overflowX: "hidden"}}>
              <EuiText>
                {children}
              </EuiText>
            </EuiPageContentBody>
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
    )}
  />
)

export default Layout