import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="zbHY600IcUk"
                    title="Netgear Router WPS"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="Netgear WPS"
              footer={
                <EuiText>
                <p>WPS / WLAN Schnellverbindung mit einem Netgear Router.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}



// import React from 'react'

// import { Player } from 'video-react'

// import {
//     EuiTitle,
//     EuiText,
//     EuiIcon,
//     EuiPanel
//   } from '@elastic/eui'

// import { OutboundLink } from 'gatsby-plugin-gtag'

// require('../../../Layout/chrome/assets/video.css')

// function VideoCard() {
//   return (
//     <EuiPanel paddingSize="m">
//         <EuiTitle size="s"><h4>Asus Router WPS</h4></EuiTitle>
//         <Player>
//         <source src="https://wiki.instar.com/videos/Netgear_WPS.mp4" />
//         </Player>
//         <EuiText>
//             <p>WPS / WLAN Schnellverbindung mit einem Netgear Router. Auf <OutboundLink href="https://www.youtube.com/watch?v=zbHY600IcUk" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
//         </EuiText>
//     </EuiPanel>
//   );
// }

// export default VideoCard