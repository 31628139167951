import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ActionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Alarm Action" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Save recordings on SD Card', descriptionColumn: 'Activate to save a video on the internal SD CARD in case of an alarm. An alarm video file name always starts with an A and continuous with a time stamp. Your camera always records 3 seconds before the alarm event and 15 seconds after the trigger. A new 15s video will be started directly afterwards, if the motion continues.' },
        { actionColumn: 'Transfer recordings via FTP(S)/sFTP', descriptionColumn: 'Activate sending a video to your FTP server after an alarm trigger. Please be aware, that you first have to set your FTP SERVER INFORMATION.' },
        { actionColumn: 'Transfer recordings to INSTAR Cloud', descriptionColumn: 'Our optional Cloud service allows you to store alarm recordings on a secure cloud server. In case of damage or a stolen camera you will still have access to previously recorded data.' },
        { actionColumn: 'Video resolution', descriptionColumn: 'Set the resolution for your camera`s alarm videos.' },
        { actionColumn: 'Alarm recording length', descriptionColumn: 'Set the length of your alarm recording (your camera will add 3s of pre-recording)' },
        { actionColumn: 'Send Email', descriptionColumn: 'All alarm actions can be triggerd by the MOTION DETECTION AREAS, the alarm input as well as the audio alarm. Here you can activate the alarm notification by email in case of a trigger event. Please be aware, that you first have to set your outgoing mail server (SMTP) and EMAIL RECEIVER before using this function.' },
        { actionColumn: 'Play Alarm Signal', descriptionColumn: 'Set if you want your camera to play an alarm signal in case of an alarm. And please don`t use it in combination with the Audio Alarm  :).' },
        { actionColumn: 'Alarm signal duration', descriptionColumn: 'How long the alarm sound should be emitted. With a value of 0, the sound file is output once completely.' },
        { actionColumn: 'Activate alarm output relais', descriptionColumn: 'Have your camera open or close the alarm relay when an alarm event occurs (if available on your camera model).' },
        { actionColumn: 'Relais idle mode', descriptionColumn: 'Specify whether the relay should be open (N.O.) or closed (N.C.) in the normal state.' },
        { actionColumn: 'Relais switch duration', descriptionColumn: 'Specify how long the relay should remain in the active state after an alarm.' },
        { actionColumn: 'Save Snapshot on SD', descriptionColumn: 'Activate saving snapshots to the internal SD Card in case of an alarm.' },
        { actionColumn: 'Transfer Snapshot via FTP(S)/sFTP', descriptionColumn: 'Activate sending snapshots to your FTP server in case of an alarm. Please be aware, that you first have to set your FTP information in the FTP Menu.' },
        { actionColumn: 'Snapshot Resolution', descriptionColumn: 'Set the resolution used for the snapshots uploaded to FTP, Email or saved to SD card in case of an alarm.' },
        { actionColumn: 'Number of Snapshots (Email)', descriptionColumn: 'Set how many snapshots you want to be attached to an alarm email. Please be aware, that the email might be delayed if you attach too many images. If you want to be notified without a delay, please use the Push Service.' },
        { actionColumn: 'Number of Snapshots (SD)', descriptionColumn: 'Set how many images you want to be saved to SD Card in case of an alarm trigger.' },
        { actionColumn: 'Number of Snapshots (FTP)', descriptionColumn: 'Set how many images you want your camera to send to your FTP Server in case of an alarm.' },
        { actionColumn: 'Transfer Interval (Email)', descriptionColumn: 'When an alarm is triggered your camera will prepare the above set number of images with an capture interval of 1s. Your camera will then enter the cooldown interval set here before sending a new set of images when an alarm is triggered.' },
        { actionColumn: 'Transfer Interval (SD)', descriptionColumn: 'When an alarm is triggered your camera will prepare the above set number of images with an capture interval of 1s. Your camera will then enter the cooldown interval set here before saving a new set of images when an alarm is triggered.' },
        { actionColumn: 'Transfer Interval (FTP)', descriptionColumn: 'When an alarm is triggered your camera will prepare the above set number of images with an capture interval of 1s. Your camera will then enter the cooldown interval set here before sending a new set of images when an alarm is triggered.' },
        { actionColumn: 'Additional LEDs on Alarm', descriptionColumn: 'If your camera model comes equipped with an additional set of LEDs of a different wavelength (940nm, or white light) you can activate them when an alarm is triggered. Also set the interval you want these LEDs to be active after an alarm trigger.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ActionTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ActionTable)