import React from 'react'
import { Link } from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="BlueIris">
        <EuiText>
         <h2>All BlueIris Tutorials</h2>
         <ul>
            <li><Link to="/Software/Windows/Blue_Iris_v5/">BlueIris Version 5</Link></li>
            <ul>
              <li><Link to="/Software/Windows/Blue_Iris_v5/INSTAR_MQTT/">BlueIris MQTT with your INSTAR Camera</Link></li>
              <li><Link to="/Software/Windows/Blue_Iris_v5/Home_Assistant/">BlueIris MQTT with Home Assistant</Link></li>
              <li><Link to="/Software/Windows/Blue_Iris_v5/ioBroker/">BlueIris MQTT with ioBroker</Link></li>
              <li><Link to="/Software/Windows/Blue_Iris_v5/Node-RED/">BlueIris MQTT with Node-RED</Link></li>
            </ul>
            <li><Link to="/Software/Windows/Blue_Iris/">BlueIris v3 Windows Software for your INSTAR IP Camera</Link></li>
         </ul>
         
         <h2>All BlueIris FAQs</h2>
         <ul>
           <li><Link to="/Frequently_Asked_Question/WQHD_with_BlueIris_v5/">Using BlueIris v5 with your 2K+ WQHD Camera</Link></li>
           <li><Link to="/Frequently_Asked_Question/BlueIris_v5_http_alarmserver/">BlueIris v5 as HTTP Alarmserver</Link></li>
           <li><Link to="/Frequently_Asked_Question/BlueIris_v5_preset_positions/">BlueIris v5 Configure Preset Positions</Link></li>
         </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox