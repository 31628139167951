import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="h.265 Video Codec">
        <EuiText>
          <h3>MIME type not found!</h3>
          <p>Please note that your 2k+ WQHD camera uses the h.265 video codec for the primary video stream. This codec is currently only supported by Safari (on macOS) and the Edge Browser (on Windows). If you want to use the Fragmented MP4 Stream to embed the video stream in a web page it is recommended to change the video codec to h.264 to maximize accessibility to your website. The encoding can be changed inside the <Link to='/Web_User_Interface/1440p_Series/Multimedia/Video/'>Multimedia/Video Settings</Link>.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

