import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Infrared Nightvision">
        <EuiText>
          <p>
            Your camera´s IR LEDs are switched on and off automatically by the internal light sensor. If you want to use your camera behind a highly reflective window or you are using your camera e.g. as a weather webcam and don´t need nightvision, simply deactivate the LEDs here.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

