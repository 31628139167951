import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/styles'
import {
  SortingState, SelectionState, FilteringState, GroupingState,
  IntegratedFiltering, IntegratedGrouping, IntegratedSelection,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table, TableHeaderRow, TableFilterRow, TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class OverviewGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
        columns: [
          { name: "character", title: "Character" },
          { name: "entity", title: "Entity" },
          { name: "https", title: "HTTP(S)" },
          { name: "rtsp", title: "RTSP" },
          { name: "rtmp", title: "RTMP" },
          { name: "onvif", title: "ONVIF" },
          { name: "p2p", title: "P2P*" }
        ],
        rows: [
          { character: 'space', entity: 'space (+)', https: 'OK', rtsp: 'OK', rtmp: 'NO**', onvif: 'NO', p2p: 'NO' },
          { character: 'space', entity: 'enc. %20', https: 'OK', rtsp: 'OK', rtmp: 'NO**', onvif: 'NO', p2p: 'OK' },
          { character: '!', entity: '!', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: '!', entity: 'enc. %21', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'NO', p2p: 'OK' },
          { character: '#', entity: '#', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: '#', entity: 'enc. %23', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'NO', p2p: 'OK' },
          { character: '$', entity: '$', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: '$', entity: 'enc. %24', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'NO', p2p: 'OK' },
          { character: '%', entity: '%', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'NO' },
          { character: '%', entity: 'enc. %25', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'NO', p2p: 'OK' },
          { character: '(', entity: '(', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: '(', entity: 'enc. %28', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'NO', p2p: 'OK' },
          { character: ')', entity: ')', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: ')', entity: 'enc. %29', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'NO', p2p: 'OK' },
          { character: '*', entity: '*', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: '*', entity: 'enc. %2A', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'NO', p2p: 'OK' },
          { character: '+', entity: '+', https: 'OK', rtsp: 'OK', rtmp: 'NO**', onvif: 'NO', p2p: 'NO' },
          { character: '+', entity: 'enc. %2B', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'NO', p2p: 'OK' },
          { character: '-', entity: '-', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: '.', entity: '.', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: '/', entity: '/', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: '/', entity: 'enc. %2F', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'NO', p2p: 'OK' },
          { character: '<', entity: '<', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: '<', entity: 'enc. %3C', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'NO', p2p: 'OK' },
          { character: '?', entity: '?', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: '?', entity: 'enc. %3F', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'NO', p2p: 'OK' },
          { character: '@', entity: '@', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: '@', entity: 'enc. %40', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'NO', p2p: 'OK' },
          { character: '[', entity: '[', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: '[', entity: 'enc. %7B', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'NO', p2p: 'OK' },
          { character: ']', entity: ']', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: ']', entity: 'enc. %5D', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'NO', p2p: 'OK' },
          { character: '^', entity: '^', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: '^', entity: 'enc. %5E', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'NO', p2p: 'OK' },
          { character: '_', entity: '_', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: '{', entity: '{', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: '{', entity: 'enc. %7B', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'NO', p2p: 'OK' },
          { character: '}', entity: '}', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: '}', entity: 'enc. %7D', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'NO', p2p: 'OK' },
          { character: '|', entity: '|', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'OK', p2p: 'OK' },
          { character: '|', entity: 'enc. %7C', https: 'OK', rtsp: 'OK', rtmp: 'OK', onvif: 'NO', p2p: 'OK' }
        ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns} >

          <FilteringState />

          <SortingState
            defaultSorting={[
              { columnName: 'character', direction: 'asc' },
            ]}
          />
          <GroupingState
            defaultGrouping={[{ columnName: 'character' }]}
            defaultExpandedGroups={[]}
          />
          <SelectionState />

          <IntegratedFiltering />
          <IntegratedGrouping />
          <IntegratedSelection />
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
          <TableFilterRow />
          <TableGroupRow />
        </Grid>
      </Paper>
    );
  }
}

OverviewGrid.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(OverviewGrid)