import React from "react"

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import ImageGallery from "react-image-gallery"

import galleryImage01 from "../../../../images/Products/Lenses/4,2mm_lense_8015-6014.webp"
import galleryImage01Thumb from "../../../../images/Products/Lenses/4,2mm_lense_8015-6014_thumb.webp"
import galleryImage02 from "../../../../images/Products/Lenses/4,2mm_lense_8015-6014_night.webp"
import galleryImage02Thumb from "../../../../images/Products/Lenses/4,2mm_lense_8015-6014_night_thumb.webp"

import "react-image-gallery/styles/css/image-gallery.css"

export class IN8015ImageQGallery extends React.Component {
  render() {
    const images = [
      { original: galleryImage01, thumbnail: galleryImage01Thumb },
      { original: galleryImage02, thumbnail: galleryImage02Thumb }
    ];

    return (
      <Card>
        <CardContent>
          <ImageGallery items={images} lazyLoad={true} showPlayButton={false} />
        </CardContent>
      </Card>
    );
  }
}

export default IN8015ImageQGallery
