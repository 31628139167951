import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import VideoNewCameraTool from 'components/Quick_Installation/Alternative_IP_Scanner/YoutubeVideoCardNewCameraTool';
import VideoWindowsSD from 'components/Quick_Installation/Alternative_IP_Scanner/YoutubeVideoCardSDCardWin';
import VideoMacSD from 'components/Quick_Installation/Alternative_IP_Scanner/YoutubeVideoCardSDCardMacOS';
import VideoWindowsDNS from 'components/Quick_Installation/Alternative_IP_Scanner/YoutubeVideoCardDomainWin';
import VideoMacDNS from 'components/Quick_Installation/Alternative_IP_Scanner/YoutubeVideoCardDomainMacOS';
import ScannerCards from 'components/Quick_Installation/Alternative_IP_Scanner/ScannerCards';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Alternative_IP_Scanner/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  OutboundLink,
  EuiSpacer,
  VideoNewCameraTool,
  VideoWindowsSD,
  VideoMacSD,
  VideoWindowsDNS,
  VideoMacDNS,
  ScannerCards,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};