import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import DangerBox from 'components/Web_User_Interface/720p_Series/Network/UPnP/DangerBox';
import UPnPTable from 'components/Web_User_Interface/720p_Series/Network/UPnP/upnpTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  DangerBox,
  UPnPTable,
  BreadCrumbs,
  SEOHelmet,
  React
};