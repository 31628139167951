import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Note:">
        <EuiText>
          <p>The camera offers you 3 video streams with different bit rates and resolutions. The second or third stream is preferable if you only have a limited bandwidth available. Access via the Internet is unfortunately often affected by bottlenecks, as the upload rates of a DSL connection (at the installation site of the camera) are usually far below the download rate available there.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

