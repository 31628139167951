import { EuiSpacer } from "@elastic/eui";
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Recording/Photoseries/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/AlarmPerm';
import PhotoTable from 'components/Web_User_Interface/1440p_Series/Recording/Photoseries/photoTable';
import PhotoScheduleTable from 'components/Web_User_Interface/1440p_Series/Recording/Photoseries/photoSheduleTable';
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
import * as React from 'react';
export default {
  EuiSpacer,
  SuccessBox,
  PermissionBox,
  PhotoTable,
  PhotoScheduleTable,
  BreadCrumbs,
  SEOHelmet,
  React
};