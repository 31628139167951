import React from 'react'
import 'react-vis/dist/style.css'
import {
  RadarChart,
  makeWidthFlexible
} from 'react-vis'

const DATA = [
  { name: 'IN5905', nightvision: 20, megapixel: 1, power: 7, focal: 4, irleds: 5 },
  { name: 'IN9008', nightvision: 20, megapixel: 2, power: 9, focal: 4.2, irleds: 5 }
]

const FlexibleRadarChart = makeWidthFlexible(RadarChart)

export default class IN8015RadarIN6012 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: []
    };
  }

  render() {
    return (
      <React.Fragment>

        <div className="chart">
          <FlexibleRadarChart
            data={DATA}
            startingAngle={0}
            domains={[
              { name: 'focal', domain: [4, 5] },
              { name: 'megapixel', domain: [1, 2] },
              { name: 'power', domain: [6, 9] },
              { name: 'nightvision', domain: [19, 20] },
              { name: 'irleds', domain: [4, 5] }
            ]}
            margin={{
              left: 50,
              right: 50
            }}
            width={400}
            height={275} />
        </div>

      </React.Fragment>
    );
  }
}