import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/InstarVision/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_InstarVision_Windows.webp'
                    alt="InstarVision 3.0"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="InstarVision 3.0"
              description="The Windows app InstarVision for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/InstarVision/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/InstarVision/Windows_Phone/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_InstarVision_WP.webp'
                    alt="InstarVision für Windows Phone"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="InstarVision für Windows Phone"
              description="The Windows app InstarVision for your Windows Phone. Control your camera when you don´t have access to your PC."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/InstarVision/Windows_Phone/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/InstarVision/Metro/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_InstarVision_Metro.webp'
                    alt="InstarVision für Windows Metro"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="InstarVision Metro App"
              description="The Windows Metro App InstarVision for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/InstarVision/Metro/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/iSpy/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_iSpy_Connect.webp'
                    alt="iSpy Connect"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="iSpy Connect"
              description="The Windows app iSpy for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/iSpy/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/Sighthound/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Sighthound.webp'
                    alt="Sighthound"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Sighthound"
              description="The Windows app Sighthound for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Sighthound/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/P2P_Client/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_P2P_Client.webp'
                    alt="P2P Client"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="P2P Client"
              description="The Windows app P2P Client for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/P2P_Client/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/go1984/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_go1984.webp'
                    alt="go1984"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="go1984"
              description="The Windows app go1984 for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/go1984/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/VLC_Player/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_VLC.webp'
                    alt="VLC Player"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="VLC Player"
              description="The Windows app VLC Player for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/VLC_Player/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/CCTV_Chrome_Plugin/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_CCTV.webp'
                    alt="CCTV für Chrome"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="CCTV für Chrome"
              description="Use the CCTV Chrome Browser Plugin to control your camera from your favourite web browser."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/CCTV_Chrome_Plugin/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/Blue_Iris_v5/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Blue_Iris.webp'
                    alt="Blue Iris v5"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Blue Iris v5"
              description="The Windows app Blue Iris for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Blue_Iris_v5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/Blue_Iris/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Blue_Iris.webp'
                    alt="Blue Iris v3"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Blue Iris v3"
              description="The Windows app Blue Iris for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Blue_Iris/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/YAW_Cam/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_YAW_Cam.webp'
                    alt="YAW Cam"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="YAW Cam"
              description="The Windows app YAW Cam for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/YAW_Cam/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/Zone_Trigger/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Zone_Trigger.webp'
                    alt="Zone Trigger"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Zone Trigger"
              description="The Windows app Zone Trigger for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Zone_Trigger/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/WebCam_XP/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_WebcamXP.webp'
                    alt="WebCam XP"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="WebCam XP"
              description="The Windows app WebCam XP for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/WebCam_XP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/Webcam_Motion_Detector/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Webcam_MD.webp'
                    alt="Webcam Motion Detector"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Webcam Motion Detector"
              description="The Windows app Webcam Motion for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Webcam_Motion_Detector/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/Zebra_Surveillance/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Zebra_Surveillance.webp'
                    alt="Zebra Surveillance"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Zebra Surveillance"
              description="The Windows app Zebra for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Zebra_Surveillance/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
