import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PTZTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Pan, Tilt & Zoom" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Camera Calibration & Positions', descriptionColumn: 'Activate or deactivate the camera PTZ calibration run at boot-up. Please be aware, that the Saved Positions for your camera will no longer be available, when the calibration is deactivated.' },
        { actionColumn: 'Position 1 after reboot', descriptionColumn: 'The camera moves to the centre position after the calibration by default. Check this box, if you want it to move to the Saved Positions 1 instead. Only available when the camera calibration is active!' },
        { actionColumn: 'Pan & Tilt does not cause an alarm', descriptionColumn: 'In most cases it is unwanted, that the pan & tilt movement of your camera causes a Motion Detection. But if you want to be notified whenever your camera is moved, just check this box.' },
        { actionColumn: 'Activate step-by-step pan & tilt', descriptionColumn: 'With the default settings, your camera will move in one direction as long as you press the arrow button on the Control Pad. When your connection to the camera is plagued with high latencies, it might happen, that the stop command is lost and your camera moves all the way to the end of range. You can use the one-step movement to prevent this from happening. Your camera will only move one step with every click on the control pad - giving you the highest amount of precision.' },
        { actionColumn: 'Pan speed', descriptionColumn: 'Set the velocity of the horizontal movement of your camera.' },
        { actionColumn: 'Tilt speed', descriptionColumn: 'Set the velocity of the vertical movement of your camera.' },
        { actionColumn: 'vscan count', descriptionColumn: 'Set the amount of vertical runs your camera should perform once you start the VSCAN Function' },
        { actionColumn: 'hscan count', descriptionColumn: 'Set the amount of horizontal runs your camera should perform once you start the HSCAN Function' },
        { actionColumn: 'Show pan/tilt limits menu', descriptionColumn: 'The P&T Limits allows you to reduce the range that your camera can move vertically and horizontally. The limits menu will be displayed at the P&T control pad once you activated the function here. Only available when the camera calibration is active!' },
        { actionColumn: 'Activate alarm position', descriptionColumn: 'Activate the alarm position, if you want your camera to move to a specific saved position once the alarm was triggered. The is especially interesting, if you use an external sensor, like the IN-MOTION 500, or the Audio Alarm. Let your camera keep an eye on one side and your external sensor on the other side. __Only available when the camera calibration is active!' },
        { actionColumn: 'Alarm Position', descriptionColumn: 'Set the alarm position here.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PTZTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(PTZTable)