import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import routerFritz from "../../../images/Internet_Access/Port_Forwarding/Forwarding_AVM_Fritzbox.webp"
import routerDLink from "../../../images/Internet_Access/Port_Forwarding/Forwarding_D-Link.webp"
import routerNetgear from "../../../images/Internet_Access/Port_Forwarding/Forwarding_Netgear.webp"
import routerPirelli from "../../../images/Internet_Access/Port_Forwarding/Forwarding_Pirelli.webp"
import routerSpeedport from "../../../images/Internet_Access/Port_Forwarding/Forwarding_Telekom_Speedport.webp"
import routerTPLink from "../../../images/Internet_Access/Port_Forwarding/Forwarding_TP-Link.webp"
import routerVodafone from "../../../images/Internet_Access/Port_Forwarding/Forwarding_Vodafone_Easybox.webp"
import routerAsus from "../../../images/Internet_Access/Port_Forwarding/Forwarding_Asus.webp"
import routerDigi from "../../../images/Internet_Access/Port_Forwarding/Forwarding_Digitalisierungsbox.webp"
import routerLink from "../../../images/Internet_Access/Port_Forwarding/Forwarding_Linksys.webp"




export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/Port_Forwarding/AVM_Fritzbox/">
                  <img
                    src={routerFritz}
                    alt="AVM Fritzbox"
                  />
                </Link>
              }
              title='AVM Fritzbox'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/Port_Forwarding/AVM_Fritzbox/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/Port_Forwarding/Telekom_Speedport/">
                  <img
                    src={routerSpeedport}
                    alt="Telekom Speedport"
                  />
                </Link>
              }
              title='Telekom Speedport'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/Port_Forwarding/Telekom_Speedport/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/Port_Forwarding/D-Link/">
                  <img
                    src={routerDLink}
                    alt="D-Link"
                  />
                 </Link>
              }
              title='D-Link'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/Port_Forwarding/D-Link/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/Port_Forwarding/Netgear/">
                  <img
                    src={routerNetgear}
                    alt="Netgear"
                  />
                </Link>
              }
              title='Netgear'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/Port_Forwarding/Netgear/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/Port_Forwarding/Vodafone_Easybox/">
                  <img
                    src={routerVodafone}
                    alt="Vodafone Easybox"
                  />
                </Link>
              }
              title='Vodafone Easybox'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/Port_Forwarding/Vodafone_Easybox/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/Port_Forwarding/TP-Link/">
                  <img
                    src={routerTPLink}
                    alt="TP-Link"
                  />
                </Link>
              }
              title='TP-Link'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/Port_Forwarding/TP-Link/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/Port_Forwarding/Pirelli/">
                  <img
                    src={routerPirelli}
                    alt="Pirelli"
                  />
                </Link>
              }
              title='Pirelli'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/Port_Forwarding/Pirelli/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/Port_Forwarding/Asus/">
                  <img
                    src={routerAsus}
                    alt="Asus Router"
                  />
                </Link>
              }
              title='Asus'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/Port_Forwarding/Asus/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/Port_Forwarding/Linksys/">
                  <img
                    src={routerLink}
                    alt="Linksys"
                  />
                </Link>
              }
              title='Linksys'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/Port_Forwarding/Linksys/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/Port_Forwarding/Digitalisierungsbox_Smart/">
                  <img
                    src={routerDigi}
                    alt="Digitalisierungsbox Smart"
                  />
                </Link>
              }
              title='Digitalisierungsbox Smart'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/Port_Forwarding/Digitalisierungsbox_Smart/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
