import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class MQTTTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "MQTT Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Activate MQTT', descriptionColumn: 'The MQTT service is deactivated by default. Use this switch to start the broker.' },
        { actionColumn: 'Use external MQTT Broker', descriptionColumn: 'By default the internal `127.0.0.1` broker is used. If you want to connect your camera to a different broker activate the option here and type in it`s address below.' },
        { actionColumn: 'MQTT Server address', descriptionColumn: 'Leave this value at `127.0.0.1` to use the internal broker. When you activated the option to use an external broker type in it`s address here.' },
        { actionColumn: 'MQTT Server Port', descriptionColumn: 'This is the port the MQTT broker uses to connect to clients without TLS encryption. The default value is `1883`' },
        { actionColumn: 'MQTT Server Port (SSL)', descriptionColumn: 'This is the port the MQTT broker uses to connect to clients with TLS encryption. The default value is `8883`' },
        { actionColumn: 'Authentication', descriptionColumn: 'Authentication has to be active, if you are using your camera as broker. If you already use an external broker set up without user authentication you can deactivate it here.' },
        { actionColumn: 'Username', descriptionColumn: 'Type in the username you want to use to access the MQTT broker.' },
        { actionColumn: 'Password', descriptionColumn: 'Add the corresponding password here.' },
        { actionColumn: 'Download Client Certificate', descriptionColumn: 'When you are using the TLS encryption for your MQTT network each client needs to have a client key to be allowed to connect to your broker. This key can be downloaded here.' },
        { actionColumn: 'Upload Server Certificate', descriptionColumn: 'The MQTT broker uses an official CA certificate for the TLS encryption - the same one that is used to connect with your camera using HTTPS. If you require a you can upload it here.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

MQTTTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(MQTTTable)