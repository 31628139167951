import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
};

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
);

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0,
    }}
    {...restProps}
  />
);

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PhotoScheduleTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Relay Schedule" },
        { name: "descriptionColumn", title: "Description" },
      ],
      rows: [
        {
          actionColumn: "Enable daily scheduled relay actions",
          descriptionColumn:
            "To add a schedule click the green plus icon, tick the days from Mon - Sun you want the relay to switch and set a time from 0:00 to 23:59.",
        },
      ],
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{ display: "inline-block", maxWidth: "915px" }}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PhotoScheduleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PhotoScheduleTable);
