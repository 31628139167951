import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class VideoTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Video Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Activate MJPEG Stream', descriptionColumn: 'Your camera is able to send the video, instead of an h.264 encoded stream, in form of a sequence of single snapshots. This Motion-JPEG Stream has the advantage, that it can be displayed in every web browser without the need for a video plugin. This is necessary if you want to see the live video on a computer where you don´t have the rights to install a browser plugin, e.g. your company PC. The disadvantage of this mode is that the compression is a lot weaker and you will need a lot more bandwidth. You can quickly activate/deactivate the mode with the quick toggle in the Video Menu Bar' },
        { actionColumn: 'Video Channel', descriptionColumn: 'Your camera can send it´s h.264 video in form of three different streams, with different resolution. You can access all three of them independently with External Software. Selecting a stream here defines which stream is displayed inside the web user interface.' },
        { actionColumn: 'Video Resolution', descriptionColumn: 'Displays the resolution of the selected video stream. The video resolution is defined by the Video Channel that you choose above. E.g. the first channel is always streamed in 720p HD resolution.' },
        { actionColumn: 'Video Framerate', descriptionColumn: 'This value represents the frames per seconds in your camera´s video stream. Please be aware that this is only a theoretical maximum that you don´t want your camera to exceed. The actual framerate might be bottlenecked by your camera´s network connection and the exposure time of the image sensor (the darker the image becomes, the longer the image sensor has to collect light and the longer it takes to create a frame). The framerate is suitable to set an upper limit to the bandwidth requirement of your camera, to prevent a network overload. The default value of __15 fps__ is usually a workable compromise for most surveillance tasks.' },
        { actionColumn: 'Video Bitrate', descriptionColumn: 'The bitrate determines how many information can be packed into the video stream. Increasing the bitrate will massively increase the bandwidth requirements for accessing the stream over the local network and will also increase the file size of recorded videos. A significant advantage in image quality will only be achieved, if the video contains a lot of fine details. In most cases it is recommended to leave this value at its default of 1024-2048kbps.' },
        { actionColumn: 'Video Encoding', descriptionColumn: 'You can adjust if you want to hold the set bitrate, or if you want your camera to automatically use a lower bitrate if the video is low in details (e.g. your camera can often work with a much lower bitrate during the night). This will affect the file size of recorded videos, the processor load of your camera, as well as the bandwidth requirements of the live stream. We recommend that you use the variable Bitrate.' },
        { actionColumn: 'Image Quality', descriptionColumn: 'Set the JPG quality of each frame that will be used for the h.264 video. You will get the best results using a value of 3.' },
        { actionColumn: 'Keyframe Interval', descriptionColumn: 'A key frame (I-Frame) is a frame encoded in its entirety, much like a JPEG image. P-frames are "predicted", i.e., they contain only the changes from frame to frame. Hence I frames are typically 10x larger than P frames, so the fewer you have, the more compression you can get. As a compromise between image quality and bandwidth requirement, it is recommended to create about 1 keyframe every second. With the default interval of 20 frames and a framerate of 15fps, your camera will send an I-Frame every (20 Frames/15 Frames/s) = 1.33s.' },
        { actionColumn: 'h.264 Video Plugin', descriptionColumn: 'Here you can choose the video plugin the web user interface uses to display the h.264 video stream. The default plugin is Flash. The Quicktime Player or our ActiveX Player can be chosen alternatively. The ActiveX Plugin can only be used under Windows with the Internet Explorer or Firefox Browser, but offers few advantages over the other plugins. In general, we recommend the use of the Flash Plugin.' },
        { actionColumn: 'MotionJPEG Video Mode', descriptionColumn: 'If you are using the MJPEG stream, you can choose between two modes - a "real"" MJPEG Stream (recommended) and a "simulated" Stream that is created by letting your browser request a new snapshot for each frame. I am not sure what the latter is for - but it is always nice to have it :)' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

VideoTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(VideoTable)