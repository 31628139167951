import React from 'react'

import {EuiFlexGroup, EuiFlexItem, EuiText} from '@elastic/eui'

import IN8015RadarIN6012 from "./in8015-radargraph-6012"
import IN8015RadarIN3011 from "./in8015-radargraph-3011"

function IN8015RadarGrid() {

  return (
    <div style={{ padding: '15px',maxWidth: '1115px', margin: '2.5rem 0 2.5rem 0', boxShadow: '0 2px 3px 0 rgb(0 0 0 / 36%' }}>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiText size="s">
            IN-8015FHD vs IN-6012HD
          </EuiText>
          <div style={{height: 275}}>
            <IN8015RadarIN6012 />
          </div>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText size="s">
            IN-8015FHD vs IN-3011
          </EuiText>
          <div style={{height: 275}}>
            <IN8015RadarIN3011 />
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}

export default IN8015RadarGrid