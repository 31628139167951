import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class RemoteTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "DDNS Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'P2P User ID', descriptionColumn: 'The P2P Access does not give you full access to all your cameras functions. But you don´t need to forward a port inside your router and P2P also works when DDNS might fail - e.g. on 3G/LTE or IPv6 access without a public IPv4 address. Simply scan the QR Code (below) with our Android, iPhone, iPad, Windows Phone or Windows Metro app to add your camera.' },
        { actionColumn: 'INSTAR DDNS Address', descriptionColumn: 'To access your camera´s WebUI over the internet you have to activate your INSTAR DDNS Address. Please be aware that your camera might still be available over the DDNS address for a while, after you deactivated it. The access will be blocked once your internet service provider changes your WAN IP address - this usually happens once every 24hrs (and in some cases once you restarted your internet router). This is your personal INSTAR DDNS Address. You will be able to access your camera over this address once you Forwarded your Camera`s HTTP Port. Simply scan the QR Code (below) with our Android, iPhone, iPad, Windows Phone or WINDOWS METRO app to add your camera.' },
        { actionColumn: 'QR Code', descriptionColumn: 'To add your camera to our Android, iPhone, iPad, Windows Phone or Windows Metro app, you simply have to scan the QR code.' },
        { actionColumn: 'Use third-party DDNS', descriptionColumn: 'If you don´t want to use your INSTAR DDNS Address, just add a Third-party DDNS Address. Please contact your Third-party provider for details. If you want to deactivate your INSTAR DDNS Address, simply activate the third-party address (remember that you have to wait until your internet provider changes your WAN IP address to see the effect - usually happens every 24h).' },
        { actionColumn: 'Check Port', descriptionColumn: 'The port checker tool can contact an INSTAR server to: 1.) See if your camera contacted our DDNS server and your DDNS address is active. 2:) Try to contact your camera over the internet to see if your Port Forwarding Rule is set and active. Troubleshooting: A failing DDNS check shows that your camera was not yet able to contact our DDNS server. Just wait for half an hour and try again. Be aware that your PC or your router can cache your initial failed attempt and continue throwing errors - jus restart your computer and router and repeat the test.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

RemoteTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(RemoteTable)