import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Only available from software version Firmware 4.1.2.42 WebUI 2.1 (192)">
        <EuiText>
          <p>The download can be found in our: <strong><Link to="/Downloads/Indoor_Cameras/IN-8015_HD/">Download Area</Link></strong>.</p>
          <p>It can also be downloaded directly from the web interface of your camera: <strong><Link to="/Web_User_Interface/1080p_Series/System/Update/">System/Update Menu</Link></strong>.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

