import { Link } from 'gatsby';
import InstarVisionCards from 'components/Software/macOS/InstarVision/Record/General/instarVisionCards';
import NavButtons from 'components/Software/macOS/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  InstarVisionCards,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};