import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class VersionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "featureColumn", title: "Feature" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { featureColumn: 'Software Version', descriptionColumn: 'Version number of your software installation.' },
        { featureColumn: 'Available Channels', descriptionColumn: 'The amount of available channels (cameras you are allowed to add to the software) will vary, depending on your license. You can add a maximum of 64 cameras the InstarVision Surveillance Center.' },
        { featureColumn: 'Active User', descriptionColumn: 'Shows you the active / logged in user profile.' },
        { featureColumn: 'Support Information', descriptionColumn: 'Please use the official INSTAR website to get into contact with us if you have any question about the software - our support team will be happy to assist.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

VersionTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(VersionTable)