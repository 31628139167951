import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Installation Warnings">
        <EuiText>
        <ul>
            <li>Powerline communication will fail. if both adaptors (one at your router / the other for your camera) are not connected to the same phase on your power grid.</li>
            <li>The Powerline network can suffer quality issues, if the cables, used in your power grid are old.</li>
            <li>Always directly plug in your IN-LAN adaptors into a wall socket. Don't use extensions cords.</li>
        </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

