import React from 'react'

import {
  EuiCard,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import ipvImage1 from "../../../../images/Software/iOS/IP_Vision_Pro/IP_Vision_Pro_01.webp"
import ipvImage2 from "../../../../images/Software/iOS/IP_Vision_Pro/IP_Vision_Pro_02.webp"
import ipvImage3 from "../../../../images/Software/iOS/IP_Vision_Pro/IP_Vision_Pro_03.webp"
import ipvImage4 from "../../../../images/Software/iOS/IP_Vision_Pro/IP_Vision_Pro_04.webp"
import ipvImage5 from "../../../../images/Software/iOS/IP_Vision_Pro/IP_Vision_Pro_05.webp"
import ipvImage6 from "../../../../images/Software/iOS/IP_Vision_Pro/IP_Vision_Pro_06.webp"
import ipvImage7 from "../../../../images/Software/iOS/IP_Vision_Pro/IP_Vision_Pro_07.webp"




export default function softwareArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ipvImage1}
                    alt="IP Vision Pro for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p><strong>Fig1.</strong> Press the Camera button to open the add-camera dialogue.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ipvImage2}
                    alt="IP Vision Pro for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p><strong>Fig2.</strong> Press the Edit button to add your camera to the default camera group.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ipvImage3}
                    alt="IP Vision Pro for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p><strong>Fig3.</strong> Press the Add Camera button to add your camera information.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ipvImage4}
                    alt="IP Vision Pro for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p><strong>Fig4.</strong> Just choose the corresponding INSTAR preset for your VGA camera. For HD cameras choose the generic model and add the MJPEG CGI command from above.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ipvImage5}
                    alt="IP Vision Pro for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                      <p><strong>Fig5.</strong> Add your camera´s IP address, port and login. Then press Save.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ipvImage6}
                    alt="IP Vision Pro for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p><strong>Fig6.</strong> Continue adding more cameras or press Done and Back to return to the camera view.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ipvImage7}
                    alt="IP Vision Pro for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p><strong>Fig7.</strong> Press Back to return to the camera view.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
