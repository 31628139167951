import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="ONVIF Camera Service">
        <EuiText>
          <p>
            The surveillance industry has created an open standard protocol called ONVIF that allows cameras to communicate with each other and with network recording devices. You can activate or deactivate this service and change the port that it is using - as needed by your receiving ONVIF device.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

