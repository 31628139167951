import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Push Service">
        <EuiText>
          <p>
            Your camera can notify you by Email every time a motion detection is triggered. A push notification serves the same purpose but is a lot faster! Activate Push if you want to be notified right away when something is happening in front of your camera. The push notification only works with our InstarVision app for <Link to="/Software/iOS/InstarVision/iPhone/Multiview/">iPhone</Link> (with Youtube Tutorial), <Link to="/Software/iOS/InstarVision/iPad/Multiview/">iPad</Link>, <Link to="/Software/Windows/InstarVision/Windows_Phone/Multiview/">Windows Phone</Link>, <Link to="/Software/Windows/InstarVision/Metro/Multiview/">Windows (Metro)</Link> or <Link to="/Software/Android/InstarVision/Multiview/">Android</Link> devices.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox