import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'AVM Fritzbox',
      href: '/en/Internet_Access/Port_Forwarding/AVM_Fritzbox/',
    },
    {
      text: 'D-Link',
      href: '/en/Internet_Access/Port_Forwarding/D-Link/',
    },
    {
      text: 'Digitalisierungsbox Smart',
      href: '/en/Internet_Access/Port_Forwarding/Digitalisierungsbox_Smart/',
    },
    {
      text: 'Linksys',
      href: '/en/Internet_Access/Port_Forwarding/Linksys/',
    },
    {
      text: 'Netgear',
      href: '/en/Internet_Access/Port_Forwarding/Netgear/',
    },
    {
      text: 'Pirelli',
      href: '/en/Internet_Access/Port_Forwarding/Pirelli/',
    },
    {
      text: 'Telekom Speedport',
      href: '/en/Internet_Access/Port_Forwarding/Telekom_Speedport/',
    },
    {
      text: 'TP-Link',
      href: '/en/Internet_Access/Port_Forwarding/TP-Link/',
    },
    {
      text: 'Vodafone Easybox',
      href: '/en/Internet_Access/Port_Forwarding/Vodafone_Easybox/',
    },
    {
      text: 'Zyxel Speedlink',
      href: '/en/Internet_Access/Port_Forwarding/Zyxel_Speedlink/',
    },
    {
      text: '◄ Port Forwarding',
      href: '/en/Internet_Access/Port_Forwarding/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Use a Third Party DDNS services with your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}