import React from 'react'
import {Link} from 'gatsby'

import { EuiAvatar, EuiButtonIcon, EuiText, EuiBadge, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import {
  EuiCommentList,
} from '@elastic/eui'

const alarmSchedule = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>MQTT Topic: Alarm Schedule</EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="primary">Firmware Update</EuiBadge>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="warning">Full HD Series</EuiBadge>
    </EuiFlexItem>
  </EuiFlexGroup>
);

const annotation = (
  <EuiButtonIcon
    title="Annotation"
    aria-label="Annotation"
    color="subdued"
    iconType="annotation"
  />
);

const timeLine = (
  <EuiButtonIcon
    title="Git Commit"
    aria-label="Git Commit"
    color="subdued"
    iconType="timeline"
  />
);

const alarmScheduleDescription = (
  <EuiText size="s">
    <h3>Added missing topic for the alarm schedule:</h3>
    <p>
      Your camera has 2 alarm schedules - one that determines whether the alarm detection is armed or not and a back-up schedule of the setting you set. This way it is possible to manipulate the alarm schedule for a short time ("Snooze" function) and then restore it from the BackUp. The value displayed in the <Link to="/Web_User_Interface/1080p_Series/Alarm/Schedule/">WebUI is always the BackUp schedule</Link>. If you make changes to the schedule via the MQTT API, you should accordingly always switch both schedules at the same time. This is now possible with the following topics:
    </p>
    <br/>
    <ul>
      <li><code>alarm/schedule/dayoftheweek</code></li>
      <li><code>alarm/schedule/dayoftheweek/webui</code></li>
    </ul>
    <br/>
    <p>
    The latter is intended for the back-up schedule, which is used, among other things, for the display in the WebUI. And the value <code>dayoftheweek</code> is for the day of the week you want to adjust: <Link to="/Advanced_User/INSTAR_MQTT_Broker/MQTT_API/">monday, tuesday, wednesday, usw.</Link>.
    </p>
  </EuiText>
);

const mPol = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>
      <EuiAvatar
        imageUrl="/en/images/Assistants/agentsmith.webp"
        size="m"
        name="Mike Polinowski"
      />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>Mike Polinowski</EuiFlexItem>
  </EuiFlexGroup>
);

const comments = [
  {
    username: mPol,
    type: 'update',
    actions: timeLine,
    event: alarmSchedule,
    timestamp: 'June 4th, 2021',
    timelineIcon: 'tag',
  },
  {
    username: mPol,
    event: 'INSTAR MQTT: Topic Added',
    children: alarmScheduleDescription,
    actions: annotation,
    timestamp: 'June 4th, 2021',
    timelineIcon: 'tag',
  }
];

const CommentList = () => <EuiCommentList comments={comments} />;

export default CommentList;