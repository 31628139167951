import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class PushTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Push Service" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Activate Push Service', descriptionColumn: 'Activate or deactivate the Push Notification for our InstarVision Smartphone App. Open the Multiview Menu in our iPhone (with Youtube Tutorial), iPad, Windows Phone, Windows (Metro) or Android App, activate the Push Button and scan the QR Code displayed above.' },
        { actionColumn: 'Cooldown Interval', descriptionColumn: 'How long should the server wait to re-notify you if the alarm persists (1s - 60s)' },
        { actionColumn: 'Test', descriptionColumn: 'After scanning the QR code with our smartphone app use the Test button to have your camera send you a test notification.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

PushTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(PushTable)