import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/styles'
import {
  SortingState, SelectionState, FilteringState, GroupingState,
  IntegratedFiltering, IntegratedGrouping, IntegratedSelection,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table, TableHeaderRow, TableFilterRow, TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class TechSpecGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
        columns: [
          { name: "category", title: "Category" },
          { name: "feature", title: "Feature" },
          { name: "property", title: "Property" }
        ],
        rows: [
          { category: 'Optics & Imaging', feature: 'Standard Lense', property: '4.3 ±5% mm (Manual Focus) f/1.6 IR /M12 - S-Mount [1]' },
          { category: 'Optics & Imaging', feature: 'Viewing Angle', property: 'approx. 90° (diag.)' },
          { category: 'Optics & Imaging', feature: 'CMOS Sensor', property: 'Sony STARVIS IMX335 CMOS Sensor [2]' }, 
          { category: 'Optics & Imaging', feature: 'Sensor Format', property: '1/2.8"' },
          { category: 'Optics & Imaging', feature: 'Dimensions', property: '38 x 38 (mm) PCB, 26.5 x 26.5 (mm)' },
          { category: 'Optics & Imaging', feature: 'Pixel Size', property: '2.0 µm (H) × 2.0 µm (V)' },
          { category: 'Optics & Imaging', feature: 'Dynamic Range', property: '72dB' },
          { category: 'Optics & Imaging', feature: 'Sensitivity', property: '>=2000mV' },
          { category: 'Optics & Imaging', feature: 'Max. Resolution', property: '5.0 Megapixel' },
          { category: 'Optics & Imaging', feature: 'Total number of pixels', property: '2704 (H) × 2104 (V) approx. 5.69 M pixels' },
          { category: 'Optics & Imaging', feature: 'Number of effective pixels', property: '2616 (H) × 1964 (V) approx. 5.14 M pixels' },
          { category: 'Optics & Imaging', feature: 'Number of active pixels', property: '2616 (H) × 1960 (V) approx. 5.13 M pixels' },
          { category: 'Optics & Imaging', feature: 'Number of recommended recording pixels', property: '2592 (H) × 1944 (V) approx. 5.04 M pixels' },
          { category: 'Optics & Imaging', feature: 'Supported Resolutions', property: '1440p (2560x1440 Pixel ~ 3.7MP), 1080p (1920x1080 Pixel ~ 2.1MP), 720p (1280x720 Pixel ~ 1MP)' },
          { category: 'Optics & Imaging', feature: 'Bit Depth(s)', property: '10 / 12 bit' },
          { category: 'Optics & Imaging', feature: 'High dynamic range (HDR)', property: 'Multiple exposure HDR / Digital overlap HDR' },
          { category: 'Optics & Imaging', feature: 'Video Compression', property: 'h.265, h.264, MJPEG, JPG' },
          { category: 'Optics & Imaging', feature: 'Bitrate (h.265)', property: 'up to 5.120 kbps' },
          { category: 'Optics & Imaging', feature: 'Framerate', property: '5.0MP 2592 x 1944 about 14fps /  4.0MP 2592 x 1520 about 19fps / 1080P @25fps' },
          { category: 'Optics & Imaging', feature: 'Parallel Streams', property: '3' },
          { category: 'Optics & Imaging', feature: 'Video Overlay', property: 'Date, Time, Camera Name' },
          { category: 'Optics & Imaging', feature: 'Video Parameter', property: 'Brightness, Contrast, Saturation, Sharpness, Gamma, Illumination' },
          { category: 'Optics & Imaging', feature: 'Day / Night Switch', property: 'Yes' },
          { category: 'Optics & Imaging', feature: 'White balance', property: 'Automatic' },
          { category: 'Optics & Imaging', feature: 'Electronic Shutter', property: 'Automatic' },
          { category: 'Optics & Imaging', feature: 'Flip / Mirror Video', property: 'Yes / Yes' },
          { category: 'Night Vision', feature: 'White Light LEDs', property: '2 High Power LEDs' },
          { category: 'Night Vision', feature: 'Infrared LEDs', property: '2 High Power IR LEDs @ 850 nm' },
          { category: 'Night Vision', feature: 'IR Modes', property: 'on/off/automatic  whitelight/850nm' },
          { category: 'Night Vision', feature: 'max. Range', property: '5-10 m' },
          { category: 'Night Vision', feature: 'intgr. IRcut Filter', property: 'Yes (automatic)' },
          { category: 'Connection', feature: 'Network Interface', property: 'GMAC+ 10Base-T/100Base-TX PHY Ethernet Port / WLAN IEEE 802.11b/g/n (not in PoE Model)' },
          { category: 'Connection', feature: 'Network Protocols', property: 'TCP/IP, DHCP, PPPoE, ARP, FTP, SMTP, DNS, NTP, UPnP, HTTP, TCP, UDP, MQTTv.5' },
          { category: 'Connection', feature: 'Transfer Encryption', property: 'TLSv1.3 - HTTPS. MQTTSv.5, FTPS, sFTP' },
          { category: 'Connection', feature: 'WiFi Encryption (not in PoE Model)', property: 'No' },
          { category: 'Connection', feature: 'WiFi Frequency (not in PoE Model)', property: 'No' },
          { category: 'Connection', feature: 'WiFi Frequency Bands (not in PoE Model)', property: 'No' },
          { category: 'Connection', feature: 'WiFi Frequency Transmission', property: 'No' },
          { category: 'Connection', feature: 'WiFi Distance (not in PoE Model)', property: 'No' },
          { category: 'Connection', feature: 'Free DDNS Address', property: 'Yes' },
          { category: 'Connection', feature: 'Free P2P UID', property: 'Yes' },
          { category: 'Chipset', feature: 'AI IPCAM SoC', property: 'Novatek NT98528' },
          { category: 'Chipset', feature: 'CPU', property: 'Dual Cortex A9' },
          { category: 'Chipset', feature: 'Memory', property: '16-bit DDR3' },
          { category: 'Chipset', feature: 'Codec', property: '8Mp20+sub stream' },
          { category: 'Chipset', feature: 'Fast Boot', property: 'Linux application < 1.2/1.5s' },
          { category: 'Chipset', feature: 'Platform AI', property: 'HW-CNN 1.5T' },
          { category: 'Chipset', feature: 'ISP', property: 'TMNR2 3DNR, SHDR, Defog, RGBIR' },
          { category: 'Chipset', feature: 'Serial Bus', property: 'USB2.0' },
          { category: 'Features', feature: 'Access Protection', property: 'Username/Password - 3 Level Authorization' },
          { category: 'Features', feature: 'Motion Detection', property: 'Yes (software Analysis + PIR)' },
          { category: 'Features', feature: 'Alarm I/O', property: 'Yes/Yes' },
          { category: 'Features', feature: 'Alarm Notifications', property: 'Email/FTP/HTTP/MQTTv.5 (Alarmserver)/Push' },
          { category: 'Features', feature: 'Alarm Pre-Recording', property: 'Yes (3s)' },
          { category: 'Features', feature: 'Photoseries', property: 'Yes (to SD Card and FTP server)' },
          { category: 'Features', feature: 'SD Card', property: 'Removable microSDHC card up to 256GB (32GB included) [3]' },
          { category: 'Features', feature: 'Cloud Storage', property: 'Yes (optional)' },
          { category: 'Features', feature: 'Pan & Tilt', property: 'No' },
          { category: 'Features', feature: 'Audio', property: 'No' },
          { category: 'Features', feature: 'Microphone', property: 'Integrated Microphone' },
          { category: 'Features', feature: 'Scheduled & Alarm Recording', property: 'Yes' },
          { category: 'Physical Details', feature: 'Casing', property: 'Metal (White)' },
          { category: 'Physical Details', feature: 'Mount', property: 'Wall & Ceiling Mount' },
          { category: 'Physical Details', feature: 'Operating Temperature', property: '-5°C to 55°C' },
          { category: 'Physical Details', feature: 'Power Socket', property: '110V - 230V' },
          { category: 'Physical Details', feature: 'Network Cable', property: '3m Cat5e' },
          { category: 'Physical Details', feature: 'Power Supply', property: '110-240V ac @ 50-60Hz to 12V dc and 2000mA - cable length: 3m' },
          { category: 'Physical Details', feature: 'Power Plug', property: '2.1mm In / 5.5mm Out' },
          { category: 'Physical Details', feature: 'Power Consumption', property: 'max. 8W (4W in normal operation)' },
          { category: 'Physical Details', feature: 'Net Weight', property: '550g' },
          { category: 'Physical Details', feature: 'Height/Width/Depth', property: '10cm/11.8cm/11.8cm' },
          { category: 'Physical Details', feature: 'Certification', property: 'CE, FCC, RoHS' },
        ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns} >

          <FilteringState />

          <SortingState
            defaultSorting={[
              { columnName: 'category', direction: 'asc' },
            ]}
          />
          <GroupingState
            defaultGrouping={[{ columnName: 'category' }]}
            defaultExpandedGroups={['Optics & Imaging']}
          />
          <SelectionState />

          <IntegratedFiltering />
          <IntegratedGrouping />
          <IntegratedSelection />
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
          <TableFilterRow />
          <TableGroupRow />
        </Grid>
      </Paper>
    );
  }
}

TechSpecGrid.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(TechSpecGrid)





















































  












































































































  































































































































