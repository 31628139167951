import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Mobile_Apps/Android_BlackBerry/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_InstarVision_Android.webp'
                    alt="InstarVision Android/Blackberry"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="InstarVision Android/Blackberry"
              description="Our free InstarVision App for your Android phone. Keep an eye on your camera´s live video when you are not at home and be notified in case of an alarm."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Mobile_Apps/Android_BlackBerry/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Mobile_Apps/iOS/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_InstarVision_iOS.webp'
                    alt="iOS & iPad"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="iOS & iPad"
              description="Our free InstarVision App for your iPhone or iPad. Keep an eye on your camera´s live video when you are not at home and be notified in case of an alarm."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Mobile_Apps/iOS/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Mobile_Apps/Windows_Phone/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_InstarVision_WP.webp'
                    alt="Windows Phone"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Windows Phone"
              description="Our free InstarVision App for your Windows phone. Keep an eye on your camera´s live video when you are not at home and be notified in case of an alarm."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Mobile_Apps/Windows_Phone/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
