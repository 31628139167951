
import React, { useState } from 'react'

import {
  EuiCallOut,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiButton,
  EuiTitle,
  EuiText,
  EuiSpacer
} from '@elastic/eui'

import CompressionCard from './compression-h264-5-card'
import SubstreamsCard from './compression-h264-card'
import SubstreamsGallery from '../Galleries/SubstreamGallery'
import CompressionProfileCard from './compression-profiles-card'

export default () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

  const closeFlyout = () => setIsFlyoutVisible(false);

  const showFlyout = () => setIsFlyoutVisible(true);

  let flyout;

  const callOut = (
    <EuiCallOut iconType="help">
      <p>
        Dies is eine Übersicht über die Bandbreiten bzw. Speicherplatz Anforderung für den Livevideo Stream und die Videoaufnahmen. Beachten Sie, dass die Einstellung der Auflösung, Bitrate sowie des Komprimierungstandards einen sehr großen Einfluß auf beide Größen haben.
      </p>
    </EuiCallOut>
  );

  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={closeFlyout}
        size="l"
        aria-labelledby="flyoutWithBannerTitle">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="flyoutWithBannerTitle">Videokomprimierung, Bandbreite und Dateigröße</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody banner={callOut}>
          <EuiTitle>
            <h2>h.265 vs h.264</h2>
          </EuiTitle>
          <EuiSpacer />
          <EuiText size="m">
            <p>Ihre INSTAR Kamera verwendet den <em>h.265</em> Codec für den primären (WQHD) Stream, während die kleineren Streams auf den <em>h.264</em> Codec zurückgreifen. Letzterer bietet zwar eine schlechtere Komprimierung, bringt aber eine höhere Kompatibilität zu Drittanbieter Anwendungen.</p>
          </EuiText>
          <CompressionCard />
          <EuiTitle size="m">
            <h2>h.264 Profile</h2>
          </EuiTitle>
          <EuiSpacer />
          <EuiText size="m">
            <p>Für den <em>h.264</em> Codec unsterstützt Ihre Kamera alle drei zuvor genannten Profile. Wobei - wie unten zu sehen - die produzierte Datenmengen sich für diese drei nicht immer stark unterscheidet.</p>
          </EuiText>
          <CompressionProfileCard />
          <EuiTitle size="m">
            <h2>h.264 Substreams</h2>
          </EuiTitle>
          <EuiText size="m">
            <em>Full HD 1920x1080 vs WXGA 640x320 Stream</em>
          </EuiText>
          <EuiSpacer />
          <EuiText size="m">
            <p>Für die Videodarstellung in der Weboberfläche der Kamera wird der zweite Substream mit Full HD Auflösung und der <em>h.264 high profile</em> Komprimierung verwendet. Für die Streamvorschau greift die InstarVision App den dritten Substream in WXGA Auflösung und <em>h.264 high profile</em> Komprimierung ab. Im folgenden Diagramm sehen Sie die Bandbreiten-Anforderung dieser Streams abhängig von der eingestellten Bitrate.</p>
          </EuiText>
          <SubstreamsCard />
          <EuiTitle size="m">
            <h2>h.264 Full HD Stream</h2>
          </EuiTitle>
          <SubstreamsGallery />
          <EuiSpacer />
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }
  return (
    <div>
      <EuiSpacer />
      <EuiButton onClick={showFlyout} fill >Video Komprimierung</EuiButton>
      {flyout}
      <EuiSpacer />
    </div>
  );
}; 