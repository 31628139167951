import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class RebootTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Reboot & Reset Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Camera Model', descriptionColumn: 'Displays your camera model number - For more information on your camera model check our complete list' },
        { actionColumn: 'Firmware Version', descriptionColumn: ' Version of the operating system of your camera. Information about a software update can be found here' },
        { actionColumn: 'WebUI Version', descriptionColumn: 'Version of the web user interface of your camera. Information about a software update can be found here.' },
        { actionColumn: 'Reboot Camera', descriptionColumn: 'Check the reboot box and submit to restart your camera.' },
        { actionColumn: 'Reset Camera', descriptionColumn: 'Check the reset box and submit to reset your camera to its factory defaults. Please be aware that you will have to set up your camera again via an Ethernet cable after a factory reset.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

RebootTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(RebootTable)