import { EuiSpacer } from '@elastic/eui';
import InstarVisionCard01 from 'components/Software/Windows/InstarVision/Users/instarVisionCard01';
import InstarVisionCard02 from 'components/Software/Windows/InstarVision/Users/instarVisionCard02';
import UserTable from 'components/Software/Windows/InstarVision/Users/userTable';
import NavButtons from 'components/Software/Windows/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  InstarVisionCard01,
  InstarVisionCard02,
  UserTable,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};