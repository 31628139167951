import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Series_CGI_List/Software_Menu/Backup-Restore/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Software_Menu/WebUI-Software_Backup_square.webp'
                    alt="720p Serie CGI List - Software Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Backup / Restore"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Series_CGI_List/Software_Menu/Language/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Software_Menu/WebUI-Software_Language_square.webp'
                    alt="720p Serie CGI List - Software Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Language"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Series_CGI_List/Software_Menu/Reboot-Reset/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Software_Menu/WebUI-Software_Reboot&Reset_square.webp'
                    alt="720p Serie CGI List - Software Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Reboot / Reset"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Series_CGI_List/Software_Menu/Update/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/Software_Menu/WebUI-Software_Update_square.webp'
                    alt="720p Serie CGI List - Software Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Update"
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
