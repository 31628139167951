import React from 'react'

import {
  EuiTitle,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiHideFor
} from '@elastic/eui'

import IntroCards from './IntroCGIToC'
import IntroCardsMobile from './IntroCGIToC_mobile'

export default function IntroHome() {

  return (
    <React.Fragment>
      <EuiSpacer />
        <EuiTitle size="m">
            <h1>1080p Serie CGI List</h1>
        </EuiTitle>
        <EuiHorizontalRule margin="xs" />
      <EuiFlexGroup gutterSize="l" direction="column" style={{margin: '0 7px'}} >
        <EuiFlexItem>
            <EuiHideFor sizes={['xs', 's', 'm', 'l']}>
              <IntroCards />
            </EuiHideFor>
            <EuiHideFor sizes={['xl']}>
              <IntroCardsMobile />
            </EuiHideFor>
        </EuiFlexItem>
    </EuiFlexGroup>
  </React.Fragment>
  );
}
