import { Link } from 'gatsby';
import SelectCard from 'components/Products/IN-Motion/selectCard';
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  SelectCard,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};