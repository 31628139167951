import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Note:">
        <EuiText>
          <p>To access the camera&apos;s RTSP stream over the Internet, you need set a port forwarding rule for the RTSP port (default <em>554</em>) to the local IP address of the camera in your router, as described for the <Link to="/Internet_Access/Port_Forwarding/">LAN (HTTP) port (default 80)</Link>.</p>
        </EuiText >
    </EuiCallOut>
  );
}

export default DangerBox

