import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="1OAeFNuz7XM"
                    title="IP Kamera als benutzerdefinierte Loxone Intercom hinzufügen"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="IP Kamera als benutzerdefinierte Loxone Intercom hinzufügen"
              footer={
                <EuiText>
                  <p>In diesem Video beschreiben wir wie Sie am Beispiel einer INSTAR 2K+ Kamera diese in Loxone als benutzerdefierte Intercom hinzufügen können.</p>
                  <p>Hier die Pfade zum abgreifen eines Bildes der Kamera (2K+ Serie):</p>
                  <ul>
                    <li>2K+ Auflösung: <code>http://192.168.178.21/snap.cgi?chn=11</code></li>
                    <li>FullHD Auflösung: <code>http://192.168.178.21/snap.cgi?chn=12</code></li>
                    <li>HD Auflösung: <code>http://192.168.178.21/snap.cgi?chn=13</code></li>
                  </ul>
                  <p>Bei HD bzw. FullHD Kameras wäre der Pfad:</p>
                  <ul>
                    <li>FullHD/HD Auflösung: <code>http://192.168.178.21/tmpfs/snap.jpg</code></li>
                    <li>320p Auflösung: <code>http://192.168.178.21/tmpfs/auto.jpg</code></li>
                    <li>160p Auflösung: <code>http://192.168.178.21/tmpfs/auto2.jpg</code></li>
                  </ul>
                  <p>Das Video als MJPEG würden Sie wie folgt abgreifen bei der (2K+ Serie):</p>
                  <ul>
                    <li>2K+ Auflösung: <code>http://192.168.178.21/livestream/11?action=play&media=mjpeg</code></li>
                    <li>FullHD Auflösung: <code>http://192.168.178.21/livestream/12?action=play&media=mjpeg/</code></li>
                    <li>HD Auflösung: <code>http://192.168.178.21/livestream/13?action=play&media=mjpeg</code></li>
                  </ul>
                  <p>Bei den HD/FullHD Kameras wäre es:</p>
                  <ul>
                    <li>FullHD/HD Auflösung:  <code>http://192.168.178.21/mjpegstream.cgi?-chn=11</code></li>
                    <li>320p Auflösung: <code>http://192.168.178.21/mjpegstream.cgi?-chn=12</code></li>
                    <li>160p Auflösung: <code>http://192.168.178.21/mjpegstream.cgi?-chn=13</code></li>
                  </ul>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}