import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut,
    EuiButton,
    EuiSpacer
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Troubleshooting">
        <EuiText>
          <p>If the DDNS Status in your camera is being displayed as "Not successful", reboot your camera and check again, also check the settings applied in step 1. Especially if you receive an Error that there is something wrong with your network configuration.</p>
          <p>Please keep in mind, that it takes a while to register your camera. Also the status might be unsuccessful but accessing your camera is still possible. Also, some routers - especially Telekom Speedports - don't allow you to access your DDNS address from inside your local network. You will have to deactivate WiFi on your phone and try accessing the DDNS address through your phone providers Data network!</p>
          <p>If after one day you are still unable to connect to your camera via it's DDNS address, it might be possible that your internet connection doesn´t have a public IPv4 Address. In that case you would have to use the <Link to="/Internet_Access/Point_to_Point/">P2P Function</Link> for remote access.</p>
          <p>If the entry of the Domain is missing, please <a href="https://www.instar.de/contact_us/" target="_blank" rel="noopener noreferrer">Contact Our Support</a> so that we can give you a new Domain.</p>
        </EuiText>
        <EuiSpacer />
        <Link to="/Internet_Access/The_DDNS_Service/Testen_der_DDNS_Adresse/"><EuiButton color="danger" fill>DDNS Test</EuiButton></Link>
    </EuiCallOut>
  );
}

export default DangerBox

