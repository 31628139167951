import React from 'react'
import { Link } from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="FHEM">
        <EuiText>
         <h2>Alle ioBroker Tutorials</h2>
         <ul>
            <li><Link to="/Advanced_User/FHEM_on_a_Raspberry_Pi/">FHEM on a Raspberry Pi</Link></li>
         </ul>
         
         <h2>All ioBroker FAQs</h2>
         <ul>
           <li><Link to="/Frequently_Asked_Question/FHEM_with_INSTAR_WQHD_Cameras/">FHEM with INSTAR WQHD Cameras</Link></li>
         </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox