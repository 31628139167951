import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/styles'
import {
  SortingState, SelectionState, FilteringState, GroupingState,
  IntegratedFiltering, IntegratedGrouping, IntegratedSelection,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table, TableHeaderRow, TableFilterRow, TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class TechSpecGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
        columns: [
          { name: "category", title: "Category" },
          { name: "feature", title: "Feature" },
          { name: "property", title: "Property" }
        ],
        rows: [
          { category: 'Optics & Imaging', feature: 'Standard Lense', property: '4.2 ±5% mm (Manual Focus) f/2.1 [1]' },
          { category: 'Optics & Imaging', feature: 'Viewing Angle', property: 'approx. 90° (diag.)' },
          { category: 'Optics & Imaging', feature: 'CMOS Sensor', property: 'Panasonic MN34229 1080p 1/3" CMOS Wide Dynamic Range [2]' },
          { category: 'Optics & Imaging', feature: 'Active Pixel Area', property: '5,346 (H) µm x 3,003 (V) µm' },
          { category: 'Optics & Imaging', feature: 'Resolution', property: '2.12 Megapixel' },
          { category: 'Optics & Imaging', feature: 'Supported Resolutions', property: '1080p, 320p, 160p' },
          { category: 'Optics & Imaging', feature: 'Illumination', property: '0.05 Lux @ f/2.1' },
          { category: 'Optics & Imaging', feature: 'Video Compression', property: 'h.264, MJPEG, JPG' },
          { category: 'Optics & Imaging', feature: 'h.264 Bitrate', property: '90 kbps - 4.096 kbps' },
          { category: 'Optics & Imaging', feature: 'Framerate', property: 'up to 30fps' },
          { category: 'Optics & Imaging', feature: '# of Parallel Streams', property: '3' },
          { category: 'Optics & Imaging', feature: 'Video Overlay', property: 'Date, Time, Camera Name' },
          { category: 'Optics & Imaging', feature: 'Video Parameter', property: 'Brightness, Contrast, Saturation, Sharpness, Gamma, Illumination' },
          { category: 'Optics & Imaging', feature: 'Day / Night Switch', property: 'Yes' },
          { category: 'Optics & Imaging', feature: 'White balance', property: 'Automatic' },
          { category: 'Optics & Imaging', feature: 'Electronic Shutter', property: 'Automatic' },
          { category: 'Optics & Imaging', feature: 'Flip / Mirror Video', property: 'Yes / Yes' },
          { category: 'Night Vision', feature: 'Infrared LEDs', property: '5 high-power Diodes @850nm' },
          { category: 'Night Vision', feature: 'IR Modes', property: 'off/automatic' },
          { category: 'Night Vision', feature: 'max. Range', property: '15-20m' },
          { category: 'Night Vision', feature: 'intgr. IRcut Filter', property: 'Yes (automatic)' },
          { category: 'Connection', feature: 'Network Interface', property: '10Base-T/100Base-TX Ethernet Port / WLAN IEEE 802.11b/g/n' },
          { category: 'Connection', feature: 'Network Protocols', property: 'TCP/IP, DHCP, PPPoE, ARP, FTP, SMTP, DNS, NTP, UPnP, HTTP, TCP, UDP, MQTT' },
          { category: 'Connection', feature: 'Transfer Encryption', property: 'HTTPS & FTPS' },
          { category: 'Connection', feature: 'WiFi Encryption', property: 'WPA/WPA2-PSK (TKIP, AES, CCMP)' },
          { category: 'Connection', feature: 'WiFi Frequency Band', property: '2.4GHz, IEEE 802.11b/g/n up to 150Mbps' },
          { category: 'Connection', feature: 'WiFi Antenna', property: '3 dBi WLAN RP-SMA Antenna [3]' },
          { category: 'Connection', feature: 'WiFi Distance', property: 'approx. 8-12m' },
          { category: 'Connection', feature: 'Free DDNS Address', property: 'Yes' },
          { category: 'Connection', feature: 'Free P2P UID', property: 'Yes' },
          { category: 'Features', feature: 'Access Protection', property: 'Username/Password - 3 Level Authorization' },
          { category: 'Features', feature: 'Motion Detection', property: 'Yes (software Analysis + PIR)' },
          { category: 'Features', feature: 'Alarm I/O', property: 'No' },
          { category: 'Features', feature: 'Alarm Notifications', property: 'Email/FTP/HTTP (Alarmserver)/Push' },
          { category: 'Features', feature: 'Alarm Pre-Recording', property: 'Yes (3s)' },
          { category: 'Features', feature: 'Photoseries', property: 'Yes (to SD Card and FTP server)' },
          { category: 'Features', feature: 'SD Card', property: 'Removable microSDHC card up to 128GB (16GB included) [4]' },
          { category: 'Features', feature: 'Cloud Storage', property: 'Yes (optional)' },
          { category: 'Features', feature: 'Pan & Tilt', property: 'No' },
          { category: 'Features', feature: 'Audio', property: 'Audio Out (3.5mm jack for Headphones/external Speakers)' },
          { category: 'Features', feature: 'Microphone', property: 'Integrated Microphone' },
          { category: 'Features', feature: 'Scheduled & Alarm Recording', property: 'Yes' },
          { category: 'Physical Details', feature: 'Casing', property: 'IP65 Aluminium (white/black)'},
          { category: 'Physical Details', feature: 'Mount', property: 'Wall & Ceiling Mount'},
          { category: 'Physical Details', feature: 'Operating Temperature', property: '-5°C to 55°C'},
          { category: 'Physical Details', feature: 'Power Socket', property: '110V - 230V'},
          { category: 'Physical Details', feature: 'Network Cable', property: '3m Cat5e'},
          { category: 'Physical Details', feature: 'Power over Ethernet', property: 'IEEE 802.3af (+ Pin 4 and 5 / - Pin 7 and 8)'},
          { category: 'Physical Details', feature: 'Power Supply', property: '110-240V ac @ 50-60Hz to 12V dc and 2000mA - cable length: 3m'},
          { category: 'Physical Details', feature: 'Power Plug', property: '2-pin female JST XH 2.54 mm plug [width: 6mm, length: 8mm, 2.54mm between wires]'},
          { category: 'Physical Details', feature: 'Power Consumption', property: 'max. 9 W'},
          { category: 'Physical Details', feature: 'Net Weight', property: '900g'},
          { category: 'Physical Details', feature: 'Height/Width/Depth', property: '9cm/7cm/22.5cm'},
          { category: 'Physical Details', feature: 'Package', property: '11cm/28.5cm/19cm'},
          { category: 'Physical Details', feature: 'Pyroelectric Motion Sensor', property: 'Panasonic PaPIR EKMC16 Long Distance Pyroelectric Sensor [5]'},
          { category: 'Physical Details', feature: 'PIR Field of View', property: '102° x 92° (108° x 99°)'},
          { category: 'Physical Details', feature: 'PIR Detector Range', property: 'up to 12m [6]'},
          { category: 'Physical Details', feature: 'PIR Detection Zone', property: '92 beams'},
          { category: 'Physical Details', feature: 'PIR Detection Condition', property: 'Temp difference must be > 4°C, Movement speed: 1.0m/s, with an approx. size of 700×250mm crossing the detection beam'},
          { category: 'Physical Details', feature: 'Certification', property: 'CE, FCC, RoHS'},
        ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns} >

          <FilteringState />

          <SortingState
            defaultSorting={[
              { columnName: 'category', direction: 'asc' },
            ]}
          />
          <GroupingState
            defaultGrouping={[{ columnName: 'category' }]}
            defaultExpandedGroups={['Optics & Imaging']}
          />
          <SelectionState />

          <IntegratedFiltering />
          <IntegratedGrouping />
          <IntegratedSelection />
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
          <TableFilterRow />
          <TableGroupRow />
        </Grid>
      </Paper>
    );
  }
}

TechSpecGrid.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(TechSpecGrid)

