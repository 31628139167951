import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class AudioTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Audio Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Activate Audio', descriptionColumn: 'Activate or deactivate the audio of your camera. This deactivates both, the audio input and output.' },
        { actionColumn: 'Audio Output Volume', descriptionColumn: 'Here you can adjust the audio volume for the internal or optional external loudspeaker of your camera.' },
        { actionColumn: 'Audio Input Volume', descriptionColumn: 'The audio input volume controls the internal or (optional) external microphone of your camera. Please be aware, that the volume indirectly influences the sensitivity of the Audio Detection' },
        { actionColumn: 'Activate Acoustic Signal', descriptionColumn: 'You can activate an acoustic signal that is played in case of an alarm event. Alarm events can be caused by the Internal Motion Detection and the Audio Detection. Please be aware that the audio detection might be triggered by the acoustic signal!' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

AudioTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(AudioTable)