import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class LogTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Camera Log" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'ipc_server start', descriptionColumn: 'Time of the last system boot process. Be aware, that the system log will be deleted with every restart.' },
        { actionColumn: '-- empty entyry --', descriptionColumn: 'Login to the live video via the Flash Video Plugin' },
        { actionColumn: 'user (admin, user or guest) login for live stream', descriptionColumn: 'Login of the admin, user or guest to the live video stream (unless logged in over the Flash Plugin s. above).' },
        { actionColumn: 'motion detection (area= 1,2,3 or 4) alarm', descriptionColumn: 'Time of a motion detection event in ALARM AREA 1, 2, 3 or 4.' },
        { actionColumn: 'input alarm', descriptionColumn: 'Time of the trigger alert by the Alarm Input - e.g. by an External Sensor or the internal PIR sensor (in case of the [IN-6014 HD' },
        { actionColumn: 'audio alarm', descriptionColumn: 'Time of an alarm event by the Audio Alarm of your camera.' },
        { actionColumn: 'alarm event: alarm to email (snap)', descriptionColumn: 'The alarm event triggered an alarm notification by email, according to your settings in the Alarm Actions Menu' },
        { actionColumn: 'alarm event: alarm to ftp (snap or video)', descriptionColumn: 'The alarm event triggered a video or snapshot upload by FTP, according to your settings in the Alarm Actions Menu, to your FTP Server.' },
        { actionColumn: 'alarm event: alarm to SD (snap or video)', descriptionColumn: 'The alarm event triggered a video or snapshot recording on your SD Card, according to your settings in the Alarm Actions Menu' },
        { actionColumn: 'Delete', descriptionColumn: 'Delete the system log. Be aware, that the system log will be deleted with every restart.' },
        { actionColumn: 'Refresh', descriptionColumn: 'The log does not refresh as long as the menu is kept open. Click refresh to do it manually.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

LogTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(LogTable)