import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="System Log">
        <EuiText>
          <p>
            Something doesn´t work anymore? Check the system log file for information. You don´t receive Alarm Emails anymore but your SMTP settings look fine? Check the log if there are still motion trigger events. Or you get spammed with Alarm Emails but motion detection is already deactivated? Check your log - maybe the events are triggered by the Audio Alarm or an external trigger.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

