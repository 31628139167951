import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Please be aware">
        <EuiText>
          <p>It is not possible to permanently activate the IR LEDs. If you deactivate the IR LEDs by night you might get false alerts from the internal motion detection. In this case, you will have to deactivate the motion detection during the night using the alarm schedule.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

