import { EuiSpacer } from '@elastic/eui';
import YoutubeVideo from 'components/Quick_Installation/WebUI_And_Firmware_Upgrade/YoutubeVideoCardUpdate';
import UpdateTable from 'components/Web_User_Interface/720p_Series/Software/Update/updateTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  YoutubeVideo,
  UpdateTable,
  BreadCrumbs,
  SEOHelmet,
  React
};