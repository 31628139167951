import { EuiSpacer } from '@elastic/eui';
import DangerBox1 from 'components/Software/Linux/Shinobi_Open_Source_CCTV/DangerBox1';
import DangerBox2 from 'components/Software/Linux/Shinobi_Open_Source_CCTV/DangerBox2';
import DetectorTable from 'components/Software/Linux/Shinobi_Open_Source_CCTV/DetectorTable';
import MotionTable from 'components/Software/Linux/Shinobi_Open_Source_CCTV/MotionTable';
import RecTable from 'components/Software/Linux/Shinobi_Open_Source_CCTV/RecTable';
import NavButtons from 'components/Software/Linux/NavButtons';
import ForumBox from 'components/Software/Linux/Shinobi_Open_Source_CCTV/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  DangerBox1,
  DangerBox2,
  DetectorTable,
  MotionTable,
  RecTable,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};