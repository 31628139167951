import React, { useState } from 'react'

import './App.css';
import Card from './Card'
import CompareTable from './CompareTable'

import IN5907 from '../../../images/Products/Compare/Wiki_Tiles_P-IN-5907HD_white.webp'
import IN8003 from '../../../images/Products/Compare/Wiki_Tiles_P-IN-8003HD_white.webp'
import IN9008 from '../../../images/Products/Compare/Wiki_Tiles_P-IN-9008HD_white.webp'
import IN9010 from '../../../images/Products/Compare/Wiki_Tiles_P-IN-9010HD_white.webp'
import IN9020 from '../../../images/Products/Compare/Wiki_Tiles_P-IN-9020HD_white.webp'
import IN8403 from '../../../images/Products/Compare/Wiki_Tiles_P-IN-8403WQHD_white.webp'
import IN9408 from '../../../images/Products/Compare/Wiki_Tiles_P-IN-9408WQHD_white.webp'
import IN9420 from '../../../images/Products/Compare/Wiki_Tiles_P-IN-9420WQHD_white.webp'

import data from "./outdoor_data"

const cameras = data

function App() {

  const [compareArray, setCompareArray] = useState([])

  let setCompareState = (camera) => {

    for(let i = 0; i < compareArray.length; i++){
      if(camera.name === compareArray[i].name)
        return null;
    }
    let newArray = [...compareArray, camera]
    setCompareArray(newArray)

  }

  let removeFromArray = (camera) => {

    let newArray = [...compareArray]

    for(let i = 0; i < compareArray.length; i++){
      if(camera.name === compareArray[i].name)
        newArray.splice(i, 1)
    }

    setCompareArray(newArray)

  }

  return (
    <div className="App">
      <h1>Outdoor Cameras</h1>
      <div className="cards">
        <Card specifications={cameras[0]} image={IN5907} compare={setCompareState} remove={removeFromArray}/>
        <Card specifications={cameras[1]} image={IN8003} compare={setCompareState} remove={removeFromArray}/>
        <Card specifications={cameras[2]} image={IN9008} compare={setCompareState} remove={removeFromArray}/>
        <Card specifications={cameras[3]} image={IN9010} compare={setCompareState} remove={removeFromArray}/>
        <Card specifications={cameras[4]} image={IN9020} compare={setCompareState} remove={removeFromArray}/>
        <Card specifications={cameras[5]} image={IN8403} compare={setCompareState} remove={removeFromArray}/>
        <Card specifications={cameras[6]} image={IN9408} compare={setCompareState} remove={removeFromArray}/>
        <Card specifications={cameras[7]} image={IN9420} compare={setCompareState} remove={removeFromArray}/>
      </div>
      <CompareTable compareItems={compareArray}/>
    </div>
  );
}

export default App;
