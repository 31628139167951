import React from 'react'

import {
  EuiAccordion,
  EuiText,
  EuiSpacer,
  EuiPanel
} from '@elastic/eui'


const Accordion = () => (
  <React.Fragment>
    <EuiPanel hasBorder>
      <EuiAccordion
        id="accordion1"
        buttonContent="Live video"
        paddingSize="l"
        style={{height: "fit-content"}}>
          <EuiPanel color="subdued">
            <EuiText style={{height: 200}}>
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Alexa, show the [camera name].</li>
                <li>Alexa, hide [camera name]</li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

    
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion2"
          buttonContent="Turn on/off alarm of camera"
          buttonContentClassName="eui-textTruncate"
          paddingSize="l">
          <EuiPanel color="subdued">
            <EuiText style={{height: 200}}>
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Alexa, arm the [camera name].</li>
                <li>Alexa, disarm the [camera name].</li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  </React.Fragment>
);

export default Accordion