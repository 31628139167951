import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Hinweis:">
        <EuiText>
          <p>Bis die Kamera die Gesichter und Kennzeichen automatisch erkennt, können bis zu 20 Aufnahmen notwendig sein.</p>
        </EuiText >
    </EuiCallOut>
  );
}

export default DangerBox

