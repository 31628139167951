import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Home_Automation/Loxone/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Loxone.webp'
                    alt="Loxone"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Loxone"
              description="The Homeautomation Miniserver from Loxone connects all smart components in your home or apartment. All strands run together to the Miniserver to enable communication between the individual components: light, shading, heating, buttons as well as your INSTAR IP Surveillance Camera."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Home_Automation/Loxone/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Home_Automation/Homee/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_homee.webp'
                    alt="homee"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="homee"
              description="Using the homee base station to let your INSTAR camera interact with wireless Zigbee, Z-Wave or EnOcean sensors, signalling devices and smart buttons. Automate your cameras operation in a smarthome environment."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Home_Automation/Homee/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Home_Automation/Homematic/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Homematic.webp'
                    alt="Homematic"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Homematic"
              description="In order to be able to control and configure your Homematic or Homematic IP devices such as your INSTAR IP camera and to use them in central programs, you must integrate the devices in the system via the WebUI."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Home_Automation/Homematic/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Home_Automation/Athom_Homey/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Homey.webp'
                    alt="Athom Homey"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Athom Homey"
              description="Using the Athom Homey base station to let your INSTAR camera interact with wireless Zigbee, Z-Wave or IR sensors, signalling devices and smart buttons. Automate your cameras operation in a smarthome environment."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Home_Automation/Athom_Homey/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Home_Automation/IFTTT/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_IFTTT.webp'
                    alt="IFTTT"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IFTTT"
              description="If This Then That, also known as IFTTT is a free web-based service to create chains of simple conditional statements, called applets for your Full HD camera..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Home_Automation/IFTTT/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Home_Automation/IP_Symcon/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Symcon.webp'
                    alt="IP Symcon"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP Symcon"
              description="The IP-Symcon software is the complete solution in the field of home automation. Combine products of all renowned manufacturers, realize your wishes, and allow your home to think for itself ..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Home_Automation/IP_Symcon/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Home_Automation/OpenHAB/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_OpenHAB.webp'
                    alt="OpenHAB"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="OpenHAB"
              description="Empowering the smart home - a vendor and technology agnostic open source automation software for your home..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Home_Automation/OpenHAB/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Home_Automation/FHEM/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_FHEM.webp'
                    alt="FHEM"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="FHEM"
              description="FHEM is a GPL'd perl server for house automation. It is used to automate some common tasks in the household like switching lamps, shutters, heating."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Home_Automation/FHEM/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Home_Automation/IOBroker/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_ioBroker.webp'
                    alt="ioBroker"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="ioBroker"
              description="Automate your Smarthome with ioBroker from your Raspberry Pi or Windows PC. Integrate your IP camera and receive push notifications."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Home_Automation/IOBroker/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Home_Automation/Home_Assistant/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_HomeAssistant.webp'
                    alt="Home Assistant"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Home Assistant"
              description="Open source home automation that puts local control and privacy first. Powered by a worldwide community of tinkerers and DIY enthusiasts. Perfect to run on a Raspberry Pi or a local server."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Home_Automation/Home_Assistant/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Home_Automation/Node-RED/MQTTv5/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Node-RED.webp'
                    alt="Node-RED"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Node-RED"
              description="Node-RED is a programming tool for wiring together hardware devices, APIs and online services in new and interesting ways..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Home_Automation/Node-RED/MQTTv5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Home_Automation/Logitech_Harmony/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_Harmony.webp'
                    alt="Logitech Harmony"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Logitech Harmony"
              description="Logitech Harmony is a universal remote control that can be programmed to communicate not only with your television, but also with your smarthome components."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Home_Automation/Logitech_Harmony/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Alexa_Echo5_and_Monocle_Gateway/">
                  <StaticImage
                    src='../../../images/Search/AU_SearchThumb_Alexa.webp'
                    alt="Alexa and Monocle Gateway"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alexa and Monocle Gateway for the Echo 5"
              description="To connect to the cameras (HD and FullHD series) e.g. With an Echo Show 5 it is necessary to use Monocle's Monocle Gateway. The gateway creates an interface so that Alexa devices can access the cameras."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Alexa_Echo5_and_Monocle_Gateway/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
