import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class VideoOverlayTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Video Overlay Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Camera Name', descriptionColumn: 'Set your camera´s name - the name will be displayed on camera´s live stream, video recordings as well as in our INSTAR Camera Tool' },
        { actionColumn: 'Display Camera Name', descriptionColumn: 'Decide if you want to display your camera´s name on its video stream.' },
        { actionColumn: 'Position (camera name)', descriptionColumn: 'Select the position where you want the camera name to be displayed.' },
        { actionColumn: 'Display Time Stamp', descriptionColumn: 'Decide if you want to display your camera´s time stamp on its video stream.' },
        { actionColumn: 'Position (timestamp)', descriptionColumn: 'Select the position where you want the timestamp to be displayed.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

VideoOverlayTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(VideoOverlayTable)