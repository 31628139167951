import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import UserTable from 'components/Quick_Installation/Creating_User_Accounts/userTable';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Creating_User_Accounts/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  UserTable,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};