import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="PTZ Tour">
        <EuiText>
          <p>
            Deactivate your camera´s calibration run at start-up (will also deactivate the saved positions). Let your camera go to a saved position after a restart or limit the range of the pan & tilt motion for privacy concerns.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

