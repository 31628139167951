import React from 'react'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'

import ivImage from '../../../../../images/Software/Windows/InstarVision/Camera_List/Image083.webp'


export default function ImageCard() {

  return (
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem style={{ maxWidth: 600 }}>
            <EuiImage
              hasShadow
              allowFullScreen
              alt="InstarVision v2 for Windows"
              src={ivImage}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
              <p>Use an architectural drawing of your property or a snapshot from Google Maps as a location map for your surveillance. Load the image (jpg, png, etc.) file by right-clicking "Map" in the Camera List and choose "Add Map".</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
  );
}
