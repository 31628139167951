import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Series_CGI_List/Network_Menu/IP_Configuration/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Network_Menu/1440pCGIs-WebUI_Network_IP-Config.png'
                    alt="1440p Serie CGI List - Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP Configuration"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Series_CGI_List/Network_Menu/ONVIF/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Network_Menu/1440pCGIs-WebUI_Network_ONVIF.png'
                    alt="1440p Serie CGI List - Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="ONVIF"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Series_CGI_List/Network_Menu/Remote_Access/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Network_Menu/1440pCGIs-WebUI_Network_Remote.png'
                    alt="1440p Serie CGI List - Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Remote Access"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Series_CGI_List/Network_Menu/WiFi/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Network_Menu/1440pCGIs-WebUI_Network_WiFi.png'
                    alt="1440p Serie CGI List - Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="WiFi"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Series_CGI_List/Network_Menu/SSL_Certificates/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Network_Menu/1440pCGIs-WebUI_Network_SSL.png'
                    alt="1440p Serie CGI List - Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="SSL Certificates"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Series_CGI_List/Network_Menu/SSL_Certificates/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Network_Menu/1440pCGIs-WebUI_Network_SSL.png'
                    alt="1440p Serie CGI List - Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="CA Store"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
