import React from "react";

import { EuiText, EuiCallOut } from "@elastic/eui";

function PrimaryBox() {
  return (
    <EuiCallOut
      size="m"
      iconType="gear"
      color="success"
      title="Position Schedule"
    >
      <EuiText>
        <p>
          Set a schedule for for your cameras pan, tilt and zoom position for
          days of the week and time of the day. Save camera positions from the
          menu above the live video and use the schedule to automate them.
        </p>
      </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox;
