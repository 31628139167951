import React from 'react'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'

import ivImage from '../../../../../images/Software/Windows/InstarVision/Webserver/Image039_cut.webp'


export default function ImageCard() {

  return (
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem grow={false}>
            <EuiImage
              hasShadow
              allowFullScreen
              alt="InstarVision v2 for Windows"
              src={ivImage}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
              <p>Use the InstarVision Surveillance Center to publish your cameras on your local network or the internet. The software offers a web service that you can reach by accessing the local server IP address on another PC on the same network. In the given example, the server address is <strong>192.168.1.18</strong>. Just access a different workstation on your network, open the default web browser and type in <code>http://192.168.1.18</code> to open the InstarVision web user interface. In case you want to check the webUI from the server itself, open your browser and type in <code>http://localhost</code> or <code>http://127.0.0.1</code>.</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
  );
}
