import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class SheduleTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Alarm Shedule" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Select All', descriptionColumn: 'Click here to activate the alarm actions 24/7. Every active alarm function (Motion Detection, Audio Alarm and External Alarm Input) will be able to trigger the set alarm actions.' },
        { actionColumn: 'Deselect All', descriptionColumn: 'Click here to deactivate the alarm actions 24/7. Every active alarm function (Motion Detection, Audio Alarm and External Alarm Input) will be able to trigger the set alarm actions.' },
        { actionColumn: 'Work Time', descriptionColumn: 'Click here to activate the alarm actions during normal working time. All active alarm functions will set to trigger alarm actions during this time window.' },
        { actionColumn: 'Night Time', descriptionColumn: 'Click here to activate the alarm actions at night. All active alarm functions will set to trigger alarm actions during this time window.' },
        { actionColumn: 'Invert All', descriptionColumn: 'This button inverts the selected time window.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

SheduleTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(SheduleTable)