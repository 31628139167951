import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import ForumBox from 'components/Software/Other_Platforms/Loxone_Miniserver/ForumBox';
import PushHostAlarm from 'components/Software/Home_Automation/CGI_Galleries/PushHostAlarm';
import GoToPreset from 'components/Software/Home_Automation/CGI_Galleries/GoToPreset';
import SnapshotPath from 'components/Software/Home_Automation/CGI_Galleries/SnapshotPath';
import VideoLoxone from 'components/Software/Other_Platforms/Loxone_Miniserver/VideoLoxone';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  NavButtons,
  ForumBox,
  PushHostAlarm,
  GoToPreset,
  SnapshotPath,
  VideoLoxone,
  BreadCrumbs,
  SEOHelmet,
  React
};