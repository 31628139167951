import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="User Management">
        <EuiText>
          <p>
            Your camera will be set up with 3 user classes - administrator, user and visitor - and one password for all of them - instar. Please change this as soon as you want to forward your camera to the internet. And don´t forget to change all three of them. You can use the RTSP Stream if you want to allow users to access your camera without the hassle of a password.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

