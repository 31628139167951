import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="IP Configuration">
        <EuiText>
          <p>
            Change your cameras IP address, gateway and DNS server - all these settings are usually set up by the <OutboundLink href="https://install.instar.com/" target="_blank" rel="noopener noreferrer">Installation Wizard</OutboundLink> and don´t need to be changed. The camera´s http port might have to be changed to access your cameras webUI <Link to="/Internet_Access/The_DDNS_Service/">over the internet</Link>.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

