import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../images/Indoor_Cameras/IN-6014_HD/Point2Point/WPS-P2P_01.webp"
import qiSelect02 from "../../../../images/Indoor_Cameras/IN-6014_HD/Point2Point/WPS-P2P_02.webp"
import qiSelect03 from "../../../../images/Indoor_Cameras/IN-6014_HD/Point2Point/WPS-P2P_03.webp"
import qiSelect04 from "../../../../images/Indoor_Cameras/IN-6014_HD/Point2Point/WPS-P2P_04.webp"
import qiSelect05 from "../../../../images/Indoor_Cameras/IN-6014_HD/Point2Point/WPS-P2P_05.webp"
import qiSelect06 from "../../../../images/Indoor_Cameras/IN-6014_HD/Point2Point/WPS-P2P_06.webp"
import qiSelect07 from "../../../../images/Indoor_Cameras/IN-6014_HD/Point2Point/WPS-P2P_07.webp"
import qiSelect08 from "../../../../images/Indoor_Cameras/IN-6014_HD/Point2Point/WPS-P2P_08.webp"
import qiSelect09 from "../../../../images/Indoor_Cameras/IN-6014_HD/Point2Point/WPS-P2P_09.webp"



export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect01}
                    alt="Attach the WiFi antenna to the cameras antenna connector as shown here (#11). (only for the non-PoE Version!)"
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Attach the WiFi antenna to the cameras antenna connector as shown here (#11). (only for the non-PoE Version!)
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect02}
                    alt="Plug in the power supply (#7). to start the camera. In case you are installing the PoE model, plug in the LAN cable (#12), connect it to your router and skip the WiFi section below."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Plug in the power supply (#7). to start the camera. In case you are installing the PoE model, plug in the LAN cable (#12), connect it to your router and skip the WiFi section below.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect03}
                    alt="Plug in the power supply (#7) to start the camera."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Plug in the power supply (#7) to start the camera.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect04}
                    alt="If your router supports the WPS (Wireless Protected Setup) function, please press the WPS button on the router, which looks similar to the example above. (only for the non-PoE Version!)"
                  />
              }
              footer={
                <EuiText>
                    <p>
                    If your router supports the WPS (Wireless Protected Setup) function, please press the WPS button on the router, which looks similar to the example above. (only for the non-PoE Version!)
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect05}
                    alt="Press the cameras reset button for 3 seconds to activate the cameras WPS service (#9). Wait for max. 5min for the camera to connect to your WiFi network. If you press it for 10s, the camera will be reset to its factory defaults!"
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Press the cameras reset button for 3 seconds to activate the cameras WPS service (#9). Wait for max. 5min for the camera to connect to your WiFi network. If you press it for 10s, the camera will be reset to its factory defaults!
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect06}
                    alt="Start our Android, iOS or Windows Phone app Instar Vision. Choose to add a new p2p camera and start the QR code scanner. Your QR code is located on a label on the camera case (s. above)."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Start our Android, iOS or Windows Phone app Instar Vision. Choose to add a new p2p camera and start the QR code scanner. Your QR code is located on a label on the camera case (s. above).
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect07}
                    alt="The QR code contains all the necessary information to add your camera. Please be aware that the default login credentials will be used. If you want to change the default password, please check the Quick Installation Guide. The password can only be changed in the web user interface."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    The QR code contains all the necessary information to add your camera. Please be aware that the default login credentials will be used. If you want to change the default password, please check the Quick Installation Guide. The password can only be changed in the web user interface.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect08}
                    alt="The app will now connect to your cameras live stream and you will be able to pan & tilt the device, as well as to activate the audio stream of the internal microphone and to take snapshots."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    The app will now connect to your cameras live stream and you will be able to pan & tilt the device, as well as to activate the audio stream of the internal microphone and to take snapshots.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect09}
                    alt="The WPS / P2P method allows you to quickly connect your camera and check the cameras live stream. Please refer to the Quick Installation Guide for instruction on how to access additional camera features."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    The WPS / P2P method allows you to quickly connect your camera and check the cameras live stream. Please refer to the Quick Installation Guide for instruction on how to access additional camera features.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
