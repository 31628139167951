import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Alarm/Areas/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/AlarmLivestreamPerm';
import UpdateBox from 'components/Web_User_Interface/1440p_Series/Alarm/Areas/PrimaryBoxUpdates';
import InfoBox from 'components/Web_User_Interface/1440p_Series/Alarm/Areas/PrimaryBoxInfo';
import YoutubeVideoCard from 'components/Web_User_Interface/1440p_Series/Alarm/Areas/YoutubeVideoCard';
import AreasTable from 'components/Web_User_Interface/1440p_Series/Alarm/Areas/areasTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  SuccessBox,
  PermissionBox,
  UpdateBox,
  InfoBox,
  YoutubeVideoCard,
  AreasTable,
  BreadCrumbs,
  SEOHelmet,
  React
};