import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "numberColumn", title: "#" },
        { name: "componentColumn", title: "Component" },
        { name: "functionColumn", title: "Function" },
      ],
      rows: [
        { numberColumn: '01', componentColumn: 'Photosensor', functionColumn: 'Twilight switch for IR-LEDs' },
        { numberColumn: '02', componentColumn: 'Lense', functionColumn: 'Wide-angle (focal length: 4.3mm / field of view: ~ 90º dia.)' },
        { numberColumn: '03', componentColumn: 'Infrared LEDs', functionColumn: '10 IR LEDs (nightvision @ 850nm)' },
        { numberColumn: '04', componentColumn: 'Status LEDs', functionColumn: 'Red: power; blue: network status' },
        { numberColumn: '05', componentColumn: 'PIR-Sensor', functionColumn: 'Integrated passive infrared (PIR) motion detector' },
        { numberColumn: '06', componentColumn: 'Loudspeaker', functionColumn: 'Integrated loudspeaker' },
        { numberColumn: '07', componentColumn: 'MicroSD Card Slot', functionColumn: 'Slot for MicroSD/SDXC cards (max. 256GB) - 32GB included inside the camera' },
        { numberColumn: '08', componentColumn: 'Microphone', functionColumn: 'For noise detection / audio rec. / two-way audio intercom' },
        { numberColumn: '09', componentColumn: 'Power Connector', functionColumn: '5V / 2A DC - Plug Ø : 1.35mm (in) / 3.5mm (out)' },
        { numberColumn: '10', componentColumn: 'WPS / Reset', functionColumn: 'Three seconds for WPS WiFi setup / 20 seconds for reset' },
        { numberColumn: '11', componentColumn: 'Alarm In/Out', functionColumn: 'Input for potential free external alarm sensors Output (relay) can support max. 24V/1Ara' },
        { numberColumn: '12', componentColumn: 'Antenna Connector', functionColumn: 'RP-SMA WiFi antenna connector (not for PoE version)' },
        { numberColumn: '13', componentColumn: 'Network Port', functionColumn: 'RJ45 LAN port / connector for Cat5e LAN cable or higher' },
        { numberColumn: '14', componentColumn: 'Audio Output', functionColumn: '3.5mm jack for earphone / active speaker' },
        { numberColumn: '15', componentColumn: 'Mount', functionColumn: 'Standard camera thread for included stand (1/4”-20 UNC)' },
        { numberColumn: '16', componentColumn: 'Camera Label', functionColumn: 'P2P ID, LAN MAC address and serial number' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)