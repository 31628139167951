import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import qsQuickInstall01 from "../../../../images/Indoor_Cameras/IN-8003_HD/Quick_Installation/IN-8003_Quick_Installation_01.webp"
import qsQuickInstall02 from "../../../../images/Indoor_Cameras/IN-8003_HD/Quick_Installation/IN-8003_Quick_Installation_02.webp"
import qsQuickInstall03 from "../../../../images/Indoor_Cameras/IN-8003_HD/Quick_Installation/IN-8003_Quick_Installation_03.webp"
import qsQuickInstall04 from "../../../../images/Indoor_Cameras/IN-8003_HD/Quick_Installation/IN-8003_Quick_Installation_04.webp"




export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              paddingSize="l"
              image={
                  <img
                    src={qsQuickInstall01}
                    alt="IN-8003 Full HD Quick Installation"
                  />
              }
              title='Step 1'
              description={
                <p>In the delivery content, you will find 3 tools for loosening the camera bracket, opening the camera dome from the back to access the cables, and opening the camera from the glass front to adjust the lens.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              paddingSize="l"
              image={
                  <img
                    src={qsQuickInstall02}
                    alt="IN-8003 Full HD Quick Installation"
                  />
              }
              title='Step 2'
              description={
                <p>The wrench tool is to be used for opening the cable nut at the back of the dome casing. You can use this tool to access the cable inside the casing. In case you want to place the cable through a wall, you can unplug the cables from the board and take them out through the cable hole. Please do it with special caution in order not to damage any wires.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              paddingSize="l"
              image={
                  <img
                    src={qsQuickInstall03}
                    alt="IN-8003 Full HD Quick Installation"
                  />
              }
              title='Step 3'
              description={
                <p>When installing, ensure that the connection cable is routed straight out of the camera and that no kinking occurs in the cable.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              paddingSize="l"
              image={
                  <img
                    src={qsQuickInstall04}
                    alt="IN-8003 Full HD Quick Installation"
                  />
              }
              title='Step 4'
              description={
                <p>The camera can then be mounted both vertically and horizontally on the wall. In rare cases, you may need to adjust <Link to="/Indoor_Cameras/IN-8003_HD/Lense_Adjustment/">adjust the lens focal point</Link>. We provide also tools for you to open the the glass front.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
