import {
    EuiPinnableListGroupItemProps,
  } from '@elastic/eui'

export const TopLinks: EuiPinnableListGroupItemProps[] = [
    {
      label: 'Contact',
      iconType: 'email',
      isActive: true,
      'aria-current': true,
      href: 'https://www.instar.com/contact',
      pinnable: false,
    },
  ];

export const SupportLinks: EuiPinnableListGroupItemProps[] = [
    { label: 'FAQs', href: '/en/FAQs/'},
    { label: 'Advanced Search', href: '/en/Search/'},
    { label: 'User Manuals', href: '/en/Products/Usermanuals/' },
    { label: 'Downloads', href: '/en/Downloads/' },
    { label: 'Testimonies', href: '/en/Assistants/Review_Wall/'},
    { label: 'Privacy', href: 'https://www.instar.com/information/privacy' },
  ];

export const ProductsLinks: EuiPinnableListGroupItemProps[] = [
    { label: 'Compare Cameras', href: '/en/Products/Compare/'},
    { label: 'Indoor Cameras', href: '/en/Indoor_Cameras/' },
    { label: 'Outdoor Cameras', href: '/en/Outdoor_Cameras/' },
    { label: 'Power over Ethernet', href: '/en/Products/Power_over_Ethernet/' },
    { label: 'Lenses', href: '/en/Products/Lenses/'},
    { label: 'Software', href: '/en/Software/' },
    { label: 'Web User Interface', href: '/en/Web_User_Interface/' },
    { label: 'Other Products', href: '/en/Products/Others/' }
  ];


export const InstallationLinks: EuiPinnableListGroupItemProps[] = [
    { label: 'Getting Started', href: '/en/Quick_Installation/How_Does_An_IP_Camera_Work/' },
    { label: 'After Unpacking', href: '/en/Quick_Installation/After_Unpacking/' },
    { label: 'First Steps', href: '/en/Quick_Installation/First_Steps/' },
    { label: 'Wireless Connection', href: '/en/Quick_Installation/Set_Up_A_Wireless_Connection/' },
    { label: 'Power over Ethernet', href: '/en/Quick_Installation/Power_over_Ethernet/' },
    { label: 'Powerline', href: '/en/Quick_Installation/Powerline/' },
    { label: 'Direct LAN Connection', href: '/en/Quick_Installation/Direct_LAN_Connection/' },
    { label: 'Finding your Camera', href: '/en/Quick_Installation/Alternative_IP_Scanner/'},
    { label: 'Language Selection', href: '/en/Quick_Installation/Language_Selection/' },
    { label: 'Live Video', href: '/en/Quick_Installation/Live_Video/' },
    { label: 'User Management', href: '/en/Quick_Installation/Creating_User_Accounts/'},
    { label: 'Software Update', href: '/en/Quick_Installation/WebUI_And_Firmware_Upgrade/' },
    { label: 'Browser History', href: '/en/Quick_Installation/How_To_Clear_Your_Browsing_History/' },
    { label: 'ONVIF', href: '/en/Quick_Installation/ONVIF/' }
  ];


export const MotionLinks: EuiPinnableListGroupItemProps[] = [
    { label: 'Setup', href: '/en/Motion_Detection/Setup/' },
    { label: 'PIR Motion Sensor', href: '/en/Motion_Detection/Passive_Infrared_Detection/' },
    { label: 'Alarm Notification', href: '/en/Motion_Detection/Alarm_Notification/' },
    { label: 'SD Card Access', href: '/en/Motion_Detection/SD_Card_Access/' },
    { label: 'Alarm FTP Upload', href: '/en/Motion_Detection/Alarm_FTP_Upload/' },
    { label: 'Router as FTP Server', href: '/en/Motion_Detection/Router_as_a_FTP_Server/' },
    { label: 'FTP Server', href: '/en/Motion_Detection/FTP_Server_Setup/' },
    { label: 'Alarm Server', href: '/en/Motion_Detection/Alarm_Server/'},
    { label: 'INSTAR Cloud', href: '/en/Motion_Detection/INSTAR_Cloud/' }
  ];


export const InternetLinks: EuiPinnableListGroupItemProps[] = [
    { label: 'Point to Point', href: '/en/Internet_Access/Point_to_Point/' },
    { label: 'DDNS Service', href: '/en/Internet_Access/The_DDNS_Service/' },
    { label: 'Port Forwarding', href: '/en/Internet_Access/Port_Forwarding/' },
    { label: 'Mobile Access', href: '/en/Internet_Access/Mobile_Access/' },
    { label: 'Thirdparty DDNS', href: '/en/Internet_Access/DDNS_Provider/' }
  ];


export const AdvancedLinks: EuiPinnableListGroupItemProps[] = [
    { label: 'CGI Commands', href: '/en/Advanced_User/CGI_Commands/' },
    { label: 'INSTAR MQTT Broker', href: '/en/Advanced_User/INSTAR_MQTT_Broker/' },
    { label: 'VPN AVM Fritzbox', href: '/en/Advanced_User/VPN_AVM_Fritzbox/' },
    { label: 'Website Integration', href: '/en/Advanced_User/Website_Integration/' },
    { label: 'All Home Automation Tutorials', href: '/en/Advanced_User/All_Home_Automation_Tutorials/' },
    { label: 'Youtube Videostreaming', href: '/en/Advanced_User/Youtube_Videostreaming_from_your_Camera/' },
    { label: 'Tasker Automation', href: '/en/Advanced_User/Tasker/' },
    { label: 'Restore WebUI', href: '/en/Advanced_User/Restore_WebUI/' },
    { label: 'Restore Firmware', href: '/en/Advanced_User/Restore_Firmware/' },
    { label: 'Analogue vs Digital', href: '/en/Advanced_User/CCTV_vs_IP/' },
  ];
