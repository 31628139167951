import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Accessories',
      href: '/en/Downloads/Accessories/',
    },
    {
      text: 'Desktop Software',
      href: '/en/Downloads/Desktop_Software/',
    },
    {
      text: 'Indoor Cameras',
      href: '/en/Downloads/Indoor_Cameras/',
    },
    {
      text: 'Mobile Apps',
      href: '/en/Downloads/Mobile_Apps/',
    },
    {
      text: 'Network Accessories',
      href: '/en/Downloads/Network_Accessories/',
    },
    {
      text: 'Outdoor Cameras',
      href: '/en/Downloads/Outdoor_Cameras/',
    },
    {
      text: '◄ Downloads',
      href: '/en/Downloads/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Downloadbereich for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}