import { Link } from 'gatsby';
import WebUI1440Articles from 'components/Web_User_Interface/1440p_Series/Network/IntroWebUINetwork1440';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  WebUI1440Articles,
  BreadCrumbs,
  SEOHelmet,
  React
};