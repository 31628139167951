import React from 'react'

import { Player } from 'video-react'

import {
    EuiTitle,
    EuiText,
    EuiIcon,
    EuiPanel
  } from '@elastic/eui'

import { OutboundLink } from 'gatsby-plugin-gtag'

require('../../Layout/chrome/assets/video.css')

function VideoCard() {
  return (
    <EuiPanel paddingSize="m">
        <EuiTitle size="s"><h4>Motion Detection Setup</h4></EuiTitle>
        <Player>
            <source src="https://wiki.instar.com/videos/VPN_Verbindung_Tunnel_mit_einer_AVM_FritzBox_herstellen.mp4" />
        </Player>
        <EuiText>
            <p>Wir erklären in diesem Video wie man eine VPN Verbindung, also einen Tunnel aufbauen kann in sein eigenes Netzwerk.</p>
            <p>Auf diese Weise haben Sie z.B. die Möglichkeit so auf Ihre INSTAR Kamera zuzugreifen, als seien Sie zu Hause im gleichen Netzwerk. Sie verbinden sich so z.B. mit Ihrem Smartphone mit Ihrer Fritzbox per VPN und können dann die Kamera über die lokale IP Adresse erreichen. Die Verbindung ist dabei komplett verschlüsselt und sicher. Auf <OutboundLink href="https://www.youtube.com/watch?v=jq4s9EsyADI" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
        </EuiText>
    </EuiPanel>
  );
}

export default VideoCard