import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import cloudSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud.webp'
import cloudAlexaSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Alexa.webp'
import cloudIFTTTSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_IFTTT.webp'


export default function CloudArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/">
                  <img
                    src={cloudAlexaSearchIcon}
                    alt="Alexa Skill"
                  />
                </Link>
              }
              title="Alexa Skill"
              description="The INSTAR Cloud Skill connects to the INSTAR Cloud platform. Users must have an INSTAR Cloud account. Once you have linked your Cloud account to this Skill, you can view and play alarm recordings from your INSTAR IP camera(s)."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/IFTTT/">
                  <img
                    src={cloudIFTTTSearchIcon}
                    alt="IFTTT Applets"
                  />
                </Link>
              }
              title="IFTTT Applets"
              description="The name IFTTT is derived from the programming conditional statement 'if this, then that'. What the company offers is a software platform that connects apps, devices and services from different developers to trigger one or more automations that affect those apps, devices and services."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/IFTTT/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/Facial_Recognition/">
                  <img
                    src={cloudSearchIcon}
                    alt="Facial Recognition"
                  />
                </Link>
              }
              title="Facial Recognition"
              description="Here you can see all the people captured in your recorded videos. Each person is displayed with their name and a thumbnail of their face."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/Facial_Recognition/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/License_Plate_Recognition/">
                  <img
                    src={cloudSearchIcon}
                    alt="License Plate Recognition"
                  />
                </Link>
              }
              title="License Plate Recognition"
              description="Here you can see all recognised number plates in your recorded videos. Each number plate is displayed with the recognised lettering and a preview image."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/License_Plate_Recognition/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/Webhook/">
                  <img
                    src={cloudSearchIcon}
                    alt="Webhook"
                  />
                </Link>
              }
              title="Webhook"
              description="Smarthomes and automation system often provide HTTP Webhooks to interconnect with third-party systems. Configure your INSTAR Cloud to contact such a webhook when an alarm is being recorded."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/Webhook/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/Google_Home/">
                  <img
                    src={cloudSearchIcon}
                    alt="Google Home"
                  />
                </Link>
              }
              title="Google Home"
              description="The Google Home app Google Home app helps you set up and control Google Nest or Home speakers and displays, and Chromecast. You can control thousands of compatible lights, cameras, speakers, and more. You can also check your reminders and recent notifications, all from a single app. The Google Home app is available on Android phones and tablets, and iPhones and iPads."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/Google_Home/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
