import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/CGI_Commands/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_CGICommands.webp'
                    alt="CGI Commands"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="CGI Commands"
              description="Control your INSTAR IP camera from your default web browser CGI: Common Gateway Interface Common Gateway Interface (CGI) is a standard method used to generate dynamic content on web pages. CGI provid…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/CGI_Commands/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/INSTAR_MQTT_Broker/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_MQTT.webp'
                    alt="INSTAR MQTT Interface"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="INSTAR MQTT Interface"
              description="Smarthome and Internet-of-Things (IoT) realm there is another protocol that is widely in use and that offers a few advantages over the classic HTTP - this protocol is called MQTT."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/INSTAR_MQTT_Broker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/VPN_AVM_Fritzbox/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_VPN.webp'
                    alt="VPN Tunnel"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="VPN Tunnel"
              description="General information for the Fritz.box VPN function In order for the Fritz! Box to become the VPN server, first check System / Update, if the latest firmware version is installed. Then, make sure that…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/VPN_AVM_Fritzbox/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Website_Integration/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_WebsiteIntegration.webp'
                    alt="Website Integration"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Website Integration"
              description="Add your Cameras Image to your personal Homepage"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Website_Integration/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Youtube_Videostreaming_from_your_Camera/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_Youtube.webp'
                    alt="Youtube Videostreaming mit Ihrer Kamera"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Youtube Videostreaming from your Camera"
              description="Installing OBS Adding your Cameras Connecting to your Youtube Account VLC Video Source You can use the free, open source OBS software to broadcast your cameras live stream to video streaming platform…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Youtube_Videostreaming_from_your_Camera/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/All_Home_Automation_Tutorials/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Smarthome.webp'
                    alt="Smarthome"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Smarthome"
              description="Overview over compatible Home Automation system that are compatible to your INSTAR IP camera."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/All_Home_Automation_Tutorials/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Tasker/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_Tasker.webp'
                    alt="Tasker Android App"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Tasker Android App"
              description="Use CGI commands to automate functions of your camera Tasker :: Android Macro Tool To automate your IP camera with your Android phone you need a macro tool, like the app Tasker. The current (paid) ve…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Tasker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Restore_WebUI/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_ResetAfterWebUIError.webp'
                    alt="Wiederherstellung der Kamera nach einem fehlerhaften WebUI-Upgrade"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Restore your Camera after a faulty WebUI Upgrade"
              description="You updated your camera´s web user interface - but now you are unable to log into your camera with your web browser. You still see the camera inside your network and might even be able to access th…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Restore_WebUI/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Restore_Firmware/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_ResetAfterFirmwareError.webp'
                    alt="Wiederherstellung Ihrer HD-Kamera nach einem fehlerhaften Firmware-Upgrade"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Restore your HD Camera after a faulty Firmware Upgrade"
              description="VGA Cameras VGA Models 720p CAMERAS Set Up a Serial Connection Restore the Firmware."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Restore_Firmware/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/CCTV_vs_IP/">
                  <StaticImage
                    src='../../images/Search/AU_SearchThumb_IPvsCCTV.webp'
                    alt="IP vs CCTV"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP vs CCTV"
              description="What’s The Difference Between Analog and IP Cameras? One of the most significant technological changes in CCTV market is the advent of so called IP cameras. When choosing security cameras for your ho…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/CCTV_vs_IP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Kerberos_with_Docker/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Lupus_XT2.webp'
                    alt="Kerberos.io video surveillance and INSTAR cameras"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Kerberos.io video surveillance and INSTAR cameras"
              description="Kerberos is a video surveillance solution, that uses computer vision algorithms to detect changes, and that can trigger actions (notifications, api, etc)."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Advanced_User/Kerberos_with_Docker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
