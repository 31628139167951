import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class InfoTable01 extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Camera Overview" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Camera ID', descriptionColumn: 'Model number of your camera.' },
        { actionColumn: 'Firmware Version', descriptionColumn: 'Version of your camera´s operating system. You can always find the newest version of the Firmware/WebUI in our Download Area. The software is uploaded to your camera through the Update Menu.' },
        { actionColumn: 'WebUI Version', descriptionColumn: 'Version of your camera´s web user interface. You can always find the newest version of the Firmware/WebUI in our Download Area. The software is uploaded to your camera through the Update Menu.' },
        { actionColumn: 'Night vision', descriptionColumn: 'Operating mode of your night vision LEDs - either switched on automatically (when it is getting dark) or permanently deactivated.' },
        { actionColumn: 'SD State', descriptionColumn: 'Displays if your camera´s SD card is ready for operation.' },
        { actionColumn: 'SD Capacity', descriptionColumn: 'Full capacity of your camera´s SD card.' },
        { actionColumn: 'Free SD Storage', descriptionColumn: 'The remaining capacity (the SD card is used as a ring buffer - the oldest folder is deleted once the capacity is reached).' },
        { actionColumn: 'MAC Address', descriptionColumn: 'Hardware identification of the LAN network interface.' },
        { actionColumn: 'WLAN Module', descriptionColumn: 'Hardware identification of the WiFi network interface.' },
        { actionColumn: 'Camera active since', descriptionColumn: 'Up-time of your camera since the last reboot.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

InfoTable01.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(InfoTable01)