import P2PCamCards01 from 'components/Software/iOS/P2P_Cam_Live/p2pCamsCard01';
import P2PCamCards02 from 'components/Software/iOS/P2P_Cam_Live/p2pCamsCard02';
import NavButtons from 'components/Software/iOS/NavButtons';
import ForumBox from 'components/Software/iOS/P2P_Cam_Live/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  P2PCamCards01,
  P2PCamCards02,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};