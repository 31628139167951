import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class SMTPTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "SMTP Server" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'SMTP Presets', descriptionColumn: 'Your INSTAR camera can work with almost all email provider. The preset menu just gives you an overview over the most common outgoing email server. If you cannot find yours, simply type in your information manually below. If you don´t know your provider`s server address, simply choose the INSTAR SMTP Server. This preset allows you to send your emails through an INSTAR server directly to your personal email address. All you have to do next, is to type in your personal email address as receiver in the EMAIL MENU.' },
        { actionColumn: 'SMTP Server', descriptionColumn: 'Type in the server address of your email provider. If you don´t know the address, simply choose the INSTAR preset (above). Afterwards you just have to type in your personal email address as receiver in the EMAIL MENU.' },
        { actionColumn: 'SMTP Port', descriptionColumn: 'Choose the port that your SMTP server uses to receive your emails. The default value for an unencrypted mail transfer (SMTP SSL: none) is 25, for SSL choose port 465 and TLS/STARTTLS uses the port 587.' },
        { actionColumn: 'SMTP SSL', descriptionColumn: 'Choose the type of encryption that your SMTP server uses. Be aware that most servers offer all types of encryption - but they all use different ports.' },
        { actionColumn: 'SMTP Username', descriptionColumn: 'Type in your email username - this is, in most cases, simply the email address that you want to use.' },
        { actionColumn: 'SMTP Password', descriptionColumn: 'Type in the password for your email account and confirm it.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

SMTPTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(SMTPTable)