import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ActionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Alarm Action" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Send Alarm Email', descriptionColumn: 'All alarm actions can be triggered by the Motion Detection Areas, the alarm input as well as the Audio Alarm. Here you can activate the alarm notification by email. Please be aware, that you first have to set your Outgoing Mail Server (SMTP) and Email Receiver.' },
        { actionColumn: 'Send Alarm Video to SD Card', descriptionColumn: 'Activate to save a video on the internal SD Card in case of an alarm. An alarm video file name always starts with an A and continuous with a time stamp. Your camera always records 3 seconds before the alarm event and 15 seconds after the trigger.' },
        { actionColumn: 'Send Alarm Video to FTP Server', descriptionColumn: 'Activate sending a video to your FTP server after an alarm trigger. Please be aware, that you first have to set your FTP Server Information.' },
        { actionColumn: 'Send Alarm Snapshot to SD Card', descriptionColumn: 'Activate saving snapshots to the internal SD CARD in case of an alarm.' },
        { actionColumn: 'Send Alarm Snapshot to FTP Server', descriptionColumn: 'Activate sending snapshots to your FTP server in case of an alarm. Please be aware, that you first have to set your FTP information in the FTP Menu.' },
        { actionColumn: 'Snapshot Resolution', descriptionColumn: 'Set the resolution used for the snapshots uploaded to FTP, Email or saved to SD card in case of an alarm.' },
        { actionColumn: 'Photos in Email', descriptionColumn: 'Set how many snapshots you want to be attached to an alarm email. Please be aware, that the email might be delayed if you attach too many images. If you want to be notified without a delay, please use the Push Service.' },
        { actionColumn: 'Photos on SD Card', descriptionColumn: 'Set how many images you want to be saved to SD Card in case of an alarm.' },
        { actionColumn: 'Photos per FTP Upload', descriptionColumn: 'Set how many images you want your camera to send to your FTP Server in case of an alarm.' },
        { actionColumn: 'Activate External Output', descriptionColumn: 'Activate your cameras alarm output (not all camera models), and let your camera close the relay in case of an alarm.' },
        { actionColumn: 'Relay closing time in seconds', descriptionColumn: 'Set for how long the alarm output relay should be closed in case of an alarm.' },
        { actionColumn: 'Activate external alarm input', descriptionColumn: 'Activate your camera´s alarm input (not all camera models). Information about connecting an external sensor can be found here. You can connect every sensor to your camera that is operated via a potential free contact.' },
        { actionColumn: 'Relay closing time in seconds', descriptionColumn: 'Set for how long the alarm output relay should be closed in case of an alarm.' },
        { actionColumn: 'Activate external alarm input', descriptionColumn: 'Activate your camera´s alarm input (not all camera models). Information about connecting an external sensor can be found here. You can connect every sensor to your camera that is operated via a potential free contact.' },
        { actionColumn: 'Electric circuit during alarm / Alarm device set to', descriptionColumn: 'Set if your external sensor is configured to close the circuit in case of a detection or if it will open the contact (see also IN-Motion 500). Please be aware that your camera will be in a constant state of alarm, if you set the alarm input to N.C. and you don´t connect an external sensor.' },
        { actionColumn: 'Link alarm input and alarm areas', descriptionColumn: 'It is recommended to couple the Internal Motion Detection with the external sensor. Please be aware, that you have to have at least one detection area active, when using the coupled state.' },
        { actionColumn: 'Activate internal PIR sensor', descriptionColumn: 'Only IN-6014 HD - Activate the internal passive infrared sensor (PIR Motion Detector) to trigger an alert once a person enters the surveillance area. You can deactivate the ALARM AREAS once the PIR sensor is active.' },
        { actionColumn: 'Link PIR and motion detect areas', descriptionColumn: 'Only IN-6014 HD - You can also couple the ALARM AREAS with the internal PIR sensor. Use this function to effectively screen out false alerts. Please be aware, that you have to at least activate one of the alarm areas to use this function! You also have to deactivate both the Eye Symbol as well as the Burglar Symbol in our smartphone app to deactivate the alarm.' },
        { actionColumn: 'Activate acoustic signal', descriptionColumn: 'Only IN-6014 HD - Set if you want your camera to play an alarm signal in case of an alarm. For all other camera models have this function in the Audio Menu' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ActionTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ActionTable)