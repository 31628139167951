import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Software-based Object Detection">
        <EuiText>
          <p>
          To adjust the software-based object detection used by your camera first activate it in the <Link to='/Web_User_Interface/1440p_Series/Alarm/Actions/'>Alarm Actions</Link> menu. Use the <strong>Object Detection</strong> menu to place two bounding boxes your video image. The outer box represents the maximum size a detected object is allowed to have to trigger an alert. And the inner box defines the minimum size that is required for a detection to be treated as an alarm.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

