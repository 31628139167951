import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Hinweis:">
        <EuiText>
          <p>Die Kamera bieten Ihnen 3 Videostreams mit unterschiedlicher Bitrate an. Der zweite bzw. dritte Stream ist dann vorzuziehen, wenn Sie nur eine limitierte Bandbreite zur Verfügung haben und der Stream in der Weboberfläche Ihnen kein flüssiges Bild liefert. Der Zugriff über das Internet ist hiervon leider häufig betroffen, da die Upload-Raten eines DSL Anschlusses (am Installationsort der Kamera) in der Regel weit unterhalb der dort verfügbaren Download-Rate liegt.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

