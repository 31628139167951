import React from 'react'
import { Link } from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="macOS Software">
        <EuiText>
          <h2>Access your SD Card</h2>
        <ul>
          <li><Link to="/Motion_Detection/SD_Card_Access/Interachy/">Interachy</Link></li>
          <li><Link to="/Motion_Detection/SD_Card_Access/DeepVacuum/">DeepVacuum</Link></li>
          <li><Link to="/Motion_Detection/SD_Card_Access/SiteSucker/">SiteSucker</Link></li>
          <li><Link to="/Motion_Detection/SD_Card_Access/SimpleWget/">SimpleWget</Link></li>
          <li><Link to="/Motion_Detection/SD_Card_Access/DownThemAll/">DownThemAll! (Firefox Addon)</Link></li>
        </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox