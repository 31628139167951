import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiSpacer,
  EuiCard,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import lenseSearchIcon from '../../../../../images/Products/Lenses/8015-lense-box2.webp'


export default function LenseArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <OutboundLink href="https://www.instar.com/Products/accessories/lenses/2-8mm-super-wide-angle-lense-for-in-8015-full-hd.html">
                  <img
                    src={lenseSearchIcon}
                    alt="2,8mm Objektiv für IN-8015 Full HD"
                  />
                </OutboundLink>
              }
              title="Super Wideangle Lense"
              footer={
                <EuiText>
                  <strong>2,8mm Objektiv für IN-8015 Full HD</strong>
                  <p>Durch einfaches Auswechseln des Objektivs erhalten Sie einen Blickwinkel von ca. 120 °.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <OutboundLink href="https://www.instar.com/Products/accessories/lenses/8mm-lense-for-in-8015-full-hd.html">
                  <img
                    src={lenseSearchIcon}
                    alt="8mm Objektiv für IN-8015 Full HD"
                  />
                </OutboundLink>
              }
              title="8mm Lense"
              footer={
                <EuiText>
                  <strong>8mm Objektiv für IN-8015 Full HD</strong>
                  <p>Durch einfaches Auswechseln des Objektivs erhalten Sie einen Blickwinkel von ca. 45°.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <OutboundLink href="https://www.instar.com/Products/accessories/lenses/16mm-lense-for-in-8015-full-hd.html">
                  <img
                    src={lenseSearchIcon}
                    alt="16mm Objektiv für IN-8015 Full HD"
                  />
                </OutboundLink>
              }
              title="16mm Lense"
              footer={
                <EuiText>
                  <strong>16mm Objektiv für IN-8015 Full HD</strong>
                  <p>Durch einfaches Auswechseln des Objektivs erhalten Sie einen Blickwinkel von ca. 25°.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
