
import React, { useState } from 'react'
import {Link} from 'gatsby'

import {
  EuiCallOut,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiButton,
  EuiTitle,
  EuiSpacer
} from '@elastic/eui'

import ProductTable from 'components/Products/Compare/indoor-compare-table'
import './App.css'

const FlyOut = () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

  const closeFlyout = () => setIsFlyoutVisible(false);

  const showFlyout = () => setIsFlyoutVisible(true);

  let flyout;

  const callOut = (
    <EuiCallOut iconType="help">
      <p>
        This is an overview of the technical specifications of all <Link to="/Indoor_Cameras/">INSTAR indoor IP cameras</Link>. For an overview of <Link to="/Outdoor_Cameras/">outdoor cameras</Link> please follow the <Link to="/Outdoor_Cameras/">link</Link>.
      </p>
    </EuiCallOut>
  );

  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={closeFlyout}
        size="l"
        aria-labelledby="flyoutWithBannerTitle">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="flyoutWithBannerTitle">Compare Indoor Cameras</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody banner={callOut}>
          <ProductTable/>
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }
  return (
    <div>
      <EuiSpacer />
      <EuiButton onClick={showFlyout} color="text">Compare Indoor Cameras</EuiButton>
      {flyout}
    </div>
  );
};

export default FlyOut;