import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class WiFiTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "WiFi Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Search network', descriptionColumn: 'Please click on Search, in the top left, to let your camera search for wireless networks. Select your WiFi from the list below, add your WiFi password and click the Test button to test and apply your settings. After confirmation, unplug the Ethernet cable and your camera will connect to the WiFi network automatically. See our Installation Wizard for further information for the WPS function, if you cannot connect your camera via an Ethernet cable.' },
        { actionColumn: 'SSID', descriptionColumn: 'Name of your WiFi network. If you use more than one access points (APs) in your network, it is recommended to assign unique SSIDs to each of them. This way you can bind your camera to the AP that offers the best connection.' },
        { actionColumn: 'Network Type', descriptionColumn: 'Choose if you want to connect your camera directly to another network device (ad hoc). Or if you want to add it to the local WiFi network of your wireless router (infra).' },
        { actionColumn: 'Encryption', descriptionColumn: 'Set the type of encryption to the same encryption that your router is using. We recommend to set your WiFi router to use the WPA2 with AES standard. In case of an AVM Fritzbox, select WPA2 with CCMP and couple it with AES type (see below) inside your camera.' },
        { actionColumn: 'Type', descriptionColumn: 'Choose the encryption standard that your router is using. We recommend that you set your router to use pure WPA2 (AES or CCMP) and not WPA+WPA2 (TKIP). If your camera detects your encryption type to be TKIP, there is no need panic. Just try to connect your camera - there is a good chance that it will work. But if you run into troubles, change the encryption type inside your router, as recommended, and re-scan for the WiFi network with your camera. Your camera will detect the change. Click Test to test and apply them.' },
        { actionColumn: 'Password', descriptionColumn: 'Here you need to add the WiFi key that you use to connect devices to your local wireless network. The WiFi key can sometimes be found on a sticker directly on the router itself or be copied from your router´s web user interface.' },
        { actionColumn: 'Preferred Wireless Access Point', descriptionColumn: 'If your network has more than one access points using the same network name (SSID) you can now lock the access points MAC address (BSSID) you want your camera to connect to. This avoids that your camera connects to an access point with a weaker signal.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

WiFiTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(WiFiTable)