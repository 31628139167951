import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="ckKuHQFU-Pc"
                    title="IN-5907 HD SD Karte"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="IN-5907 HD SD Karte"
              footer={
                <EuiText>
                  <p>So tauschen Sie die MicroSD Speicherkarte bei Ihrer IN-5907HD Überwachungskamera aus.</p>
                  <ul>
                    <li><strong>Schritt 1)</strong> Öffnen Sie den hinteren Gehäusedeckel</li>
                    <li><strong>Schritt 2)</strong> Tauschen Sie die MicroSD Speicherkarte auf (max. 32GB)</li>
                    <li><strong>Schritt 3)</strong> Verschließen Sie die hintere Abdeckung wieder</li>
                  </ul>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
