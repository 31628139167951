import { EuiSpacer } from '@elastic/eui';
import ConformityCards from 'components/Outdoor_Cameras/IN-9008_HD/Safety_Warnings/ConformityCards';
import NavButtons from 'components/Outdoor_Cameras/IN-9008_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  ConformityCards,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};