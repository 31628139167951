import React from 'react'

import { EuiAvatar, EuiText, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import {
  EuiCommentList,
} from '@elastic/eui'

const wqhdSeriesAvatar = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>
      <EuiAvatar
        imageUrl="/en/images/Assistants/INSTAR-Ico.webp"
        size="s"
        name="WQHD 1440p 2k+ Camera Series"
      />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>WQHD 1440p 2k+ Camera Series</EuiFlexItem>
  </EuiFlexGroup>
);

const wqhdSeriesBody = (
    <EuiText>
        <ul>
            <li><code>/param.cgi?cmd=getptzpreset&act=goto&index=0&user=<em>USERNAME</em>&pwd=<em>PASSWORD</em></code></li>
            <li><code>/param.cgi?cmd=getptzpreset&act=goto&index=1&user=<em>USERNAME</em>&pwd=<em>PASSWORD</em></code></li>
            <li><code>/param.cgi?cmd=getptzpreset&act=goto&index=2&user=<em>USERNAME</em>&pwd=<em>PASSWORD</em></code></li>
        </ul>
    </EuiText>
);

const fullhdSeriesAvatar = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>
      <EuiAvatar
        imageUrl="/en/images/Assistants/INSTAR-Ico.webp"
        size="s"
        name="Full HD 1080p Camera Series"
      />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>Full HD 1080p Camera Series</EuiFlexItem>
  </EuiFlexGroup>
);

const fullhdSeriesBody = (
    <EuiText>
      <ul>
         <li><code>/param.cgi?cmd=preset&-act=goto&-number=0&-usr=<em>USERNAME</em>&-pwd=<em>PASSWORD</em></code></li>
         <li><code>/param.cgi?cmd=preset&-act=goto&-number=1&-usr=<em>USERNAME</em>&-pwd=<em>PASSWORD</em></code></li>
         <li><code>/param.cgi?cmd=preset&-act=goto&-number=2&-usr=<em>USERNAME</em>&-pwd=<em>PASSWORD</em></code></li>
      </ul>
    </EuiText>
);

const comments = [
  {
    username: wqhdSeriesAvatar,
    children: wqhdSeriesBody,
    timelineIcon: 'tag',
  },
  {
    username: fullhdSeriesAvatar,
    children: fullhdSeriesBody,
    timelineIcon: 'tag',
  }
];

const GoToPreset = () => <EuiCommentList comments={comments} />;

export default GoToPreset