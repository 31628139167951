import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/IFTTT/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import TriggersAccording from 'components/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/trigger_accordion';
import ActionsAccording from 'components/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/actions_accordion';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  NavButtons,
  ForumBox,
  TriggersAccording,
  ActionsAccording,
  BreadCrumbs,
  SEOHelmet,
  React
};