import React from 'react'

import { Player } from 'video-react'

import {
    EuiText,
    EuiIcon,
    EuiPanel
  } from '@elastic/eui'

import { OutboundLink } from 'gatsby-plugin-gtag'

require('../../Layout/chrome/assets/video.css')

function VideoCard() {
  return (
    <EuiPanel paddingSize="m">
        <Player>
            <source src="https://wiki.instar.com/videos/IN_LAN_500_Powerline_Adapter.mp4" />
        </Player>
        <EuiText>
            <p>Der IN-Lan 500 Powerline Adapter ist ideal zur Erstellung eines Netzwerkes über das Stromnetz, einfach einstecken und fertig. Ihre Vorteile:</p>
            <ul>
              <li>Nutzen Sie bestehende Stromleitungen um mit IN-LAN ein Netzwerk zu realisieren</li>
              <li>Sehr einfache Plug n Play Technologie. Einfach in die Steckdose stecken und fertig</li>
              <li>Super schneller Datentransfer bis zu 500Mbps</li>
              <li>Erweitern Sie Ihr Netzwerk um so z.B. Ihre IP Kamera oder andere Netzwerkgeräte    zu verkabeln ohne Netzwerkkabel zu verlegen</li>
              <li>Eine sehr detallierte Anleitung mach Ihnen die Installation sehr leicht</li>
            </ul>
            <p>Auf <OutboundLink href="https://www.youtube.com/watch?v=q3yBixX9UmI" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
        </EuiText>
    </EuiPanel>
  );
}

export default VideoCard