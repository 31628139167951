import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
};

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
);

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0,
    }}
    {...restProps}
  />
);

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ONVIFTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "ONVIF Settings" },
        { name: "descriptionColumn", title: "Description" },
      ],
      rows: [
        {
          actionColumn: "Activate ONVIF Protocol",
          descriptionColumn:
            "Activate or deactivate the ONVIF Service of your camera.",
        },
        {
          actionColumn: "ONVIF Port",
          descriptionColumn:
            "This is the port that external devices have to use to communicate with your camera over the ONVIF Protokoll. It is recommended to leave it at its default 8080.",
        },
        {
          actionColumn: "ONVIF Port (SSL)",
          descriptionColumn:
            "This is the port that external devices have to use to communicate securely with your camera over the ONVIF Protokoll. It is recommended to leave it at its default 8090.",
        },
        {
          actionColumn: "ONVIF WS-Security",
          descriptionColumn:
            "By enabling WS-Security support, this account`s user password will be stored encrypted and can be decrypted by this device. To comply with WS-Security specifications set by ONVIF, the camera must know the password for WS-Security. WS-Security support is by default not enabled because the camera only stores a cryptographic hash by default and thus does not directly retain your password. For this reason, it is strongly recommended to create a new user with only the desired ONVIF settings.",
        },
      ],
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{ display: "inline-block", maxWidth: "915px" }}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ONVIFTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ONVIFTable);
