import React from 'react'
import {Link} from 'gatsby'

import { EuiAvatar, EuiButtonIcon, EuiText, EuiBadge, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import {
  EuiCommentList,
} from '@elastic/eui'

import iftttImage from 'images/Products/IFTTT/INSTAR_IFTTT.webp'
import mqttImage from 'images/Products/MQTT/INSTAR_MQTT.webp'

const iftttRelease = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>IFTTT Applets</EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="primary">Firmware Update</EuiBadge>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="warning">Full HD Series</EuiBadge>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="default">INSTAR Cloud</EuiBadge>
    </EuiFlexItem>
  </EuiFlexGroup>
);

const annotation = (
  <EuiButtonIcon
    title="Annotation"
    aria-label="Annotation"
    color="subdued"
    iconType="annotation"
  />
);

const timeLine = (
  <EuiButtonIcon
    title="Git Commit"
    aria-label="Git Commit"
    color="subdued"
    iconType="timeline"
  />
);

const iftttAd = (
  <EuiText size="s">
    <h2>Control your camera via the IFTTT online service</h2>
    <img src={iftttImage} alt="INSTAR IFTTT" style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}} />
    <p>
    On the IFTTT platform, we provided a <Link to="/Motion_Detection/INSTAR_Cloud/IFTTT/">service called INSTAR</Link>. The <Link to="/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Applets_Introduction/">INSTAR applets</Link> provide you with the ability to control some settings of your <Link to="/Motion_detection/INSTAR_Cloud/IFTTT/User_Guide/How_to_use_INSTAR_Applets/">INSTAR camera or INSTAR Cloud with IFTTT</Link>. You can connect INSTAR with triggers for location (Geo Location) and date & time, send notifications and much more. For example, you can connect INSTAR applets to the IFTTT location service and automatically disable the alarm when you arrive home. You can also use the INSTAR applets to create your own automation and connect them to <Link to="/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/">other third-party applets</Link>.
    </p>
  </EuiText>
);

const mPol = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>
      <EuiAvatar
        imageUrl="/en/images/Assistants/agentsmith.webp"
        size="m"
        name="Mike Polinowski"
      />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>Mike Polinowski</EuiFlexItem>
  </EuiFlexGroup>
);

const mqttRelease = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>INSTAR MQTT</EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="primary">Firmware Update</EuiBadge>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiBadge color="warning">Full HD Series</EuiBadge>
    </EuiFlexItem>
  </EuiFlexGroup>
);

const mqttAd = (
  <EuiText size="s">
    <h2>Make your camera the heart of your smart home</h2>
    <img src={mqttImage} alt="INSTAR MQTT" style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}} />
    <p>
      Using the <Link to="/Advanced_User/INSTAR_MQTT_Broker/">MQTT interface</Link> of your <Link to="/products/">INSTAR Full HD cameras</Link> you can connect them to an existing smarthome system (<Link to="/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/">Home Assistant</Link>, <Link to="/Advanced/INSTAR_MQTT_Broker/OpenHAB2/">OpenHAB</Link>, <Link to="/Advanced/INSTAR_MQTT_Broker/Node-RED/">Node-RED</Link>, <Link to="/Advanced_User/INSTAR_MQTT_Broker/Athom_Homey_MQTT_Client/">Athom Homey</Link>, <Link to="/Advanced_User/INSTAR_MQTT_Broker/Homematic_CCU3_and_RedMatic/">Homematic</Link>, <Link to="/Advanced/INSTAR_MQTT_Broker/ioBroker/">ioBroker</Link>, <Link to="/Advanced_User/INSTAR_MQTT_Broker/Loxone/">Loxone</Link>, <Link to="/Advanced_User/INSTAR_MQTT_Broker/homee/">homee</Link>) can be added. Or even make it the main broker for your MQTT sensors. MQTT allows you to automate <strong>ALL</strong> <Link to="/Software/Home_Automation/">functions of your camera</Link> and link them to other services in your smarthome.
    </p>
  </EuiText>
);



const instarAvatar = (
  <EuiAvatar
    imageUrl="/en/images/Assistants/INSTAR-Ico.webp"
    size="l"
    name="INSTAR Deutschland GmbH"
  />
);

const comments = [
  {
    username: mPol,
    type: 'update',
    actions: timeLine,
    event: mqttRelease,
    timestamp: 'May 10th, 2021',
    timelineIcon: 'tag',
  },
  {
    username: 'INSTAR Deutschland GmbH',
    event: 'INSTAR MQTT',
    children: mqttAd,
    actions: annotation,
    timestamp: 'December 31st, 2019',
    timelineIcon: instarAvatar,
  },
  {
    username: mPol,
    type: 'update',
    actions: timeLine,
    event: iftttRelease,
    timestamp: 'September 9th, 2020',
    timelineIcon: 'tag',
  },
  {
    username: 'INSTAR Deutschland GmbH',
    event: 'INSTAR IFTTT Applets',
    children: iftttAd,
    actions: annotation,
    timestamp: 'October 19th, 2020',
    timelineIcon: instarAvatar,
  },
];

const CommentList = () => <EuiCommentList comments={comments} />;

export default CommentList;