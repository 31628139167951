import { EuiButton, EuiSpacer } from '@elastic/eui';
import { Link } from 'gatsby';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemAlarmPerm';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiButton,
  EuiSpacer,
  Link,
  BreadCrumbs,
  PermissionBox,
  SEOHelmet,
  React
};