import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'


import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/Point_to_Point/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_P2P.webp'
                    alt="P2P Access"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="The P2P Remote Access"
              description="Setup a Point2Point connection with your camera InstarVision 2 for Windows Check out our User Manual for how to add your INSTAR P2P camera to our Windows Software InstarVision 2. InstarVision Mobile …"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/Point_to_Point/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/The_DDNS_Service/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_DDNSService.webp'
                    alt="INSTAR DDNS Service"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="DDNS Provider"
              description="Use a Third Party DDNS Provider with your INSTAR IP Camera What is this DDNS all about? Why do we hide the real addresses of our websites behind domain names? Firstly, the IP addresses are a bit diff…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/The_DDNS_Service/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/Port_Forwarding/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_Portforwarding.webp'
                    alt="Port Porwarding"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Port Forwarding"
              description="Connect to your Camera through the Internet The steps to forward a port are almost identical for every internet router. In the following we will give step-by-step instructions for the most common hom…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/Port_Forwarding/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/Mobile_Access/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_Mobile.webp'
                    alt="Mobile Access"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Mobile Access"
              description="Access your Camera through its DDNS Address Please read Step I on how to configure your camera and Step II for the necessary router configuration first. In the following we will use the web brow…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/Mobile_Access/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/DDNS_Provider/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_ThirdPartyDDNS.webp'
                    alt="Thirdparty DDNS Provider"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="DDNS Provider"
              description="Use a Third Party DDNS Provider with your INSTAR IP Camera What is this DDNS all about? Why do we hide the real addresses of our websites behind domain names? Firstly, the IP addresses are a bit diff…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/DDNS_Provider/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
