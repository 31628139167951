import { EuiSpacer } from '@elastic/eui';
import DangerBox from 'components/Web_User_Interface/720p_Series/System/User/DangerBox';
import UserTable from 'components/Web_User_Interface/720p_Series/System/User/userTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  DangerBox,
  UserTable,
  BreadCrumbs,
  SEOHelmet,
  React
};