import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Series_CGI_List/System_Menu/User/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/System_Menu/WebUI-System_User_square.webp'
                    alt="720p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="User"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Series_CGI_List/System_Menu/Info/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/System_Menu/WebUI-System_Info_square.webp'
                    alt="720p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Info"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Series_CGI_List/System_Menu/IR_LEDs/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/System_Menu/WebUI-System_IR_square.webp'
                    alt="720p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IR LEDs"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Series_CGI_List/System_Menu/Log/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/System_Menu/WebUI-System_Log_square.webp'
                    alt="720p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Log"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Series_CGI_List/System_Menu/ONVIF/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/System_Menu/WebUI-System_ONVIF_square.webp'
                    alt="720p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="ONVIF"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Series_CGI_List/System_Menu/PTZ/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/System_Menu/WebUI-System_PTZ_square.webp'
                    alt="720p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="PTZ"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />


      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Series_CGI_List/System_Menu/PTZ_Tour/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/System_Menu/WebUI-System_PTZ-Tour_square.webp'
                    alt="720p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="PTZ Tour"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Series_CGI_List/System_Menu/Status_LED/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/System_Menu/WebUI-System_StatusLED_square.webp'
                    alt="720p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Status LED"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/720p_Series_CGI_List/System_Menu/Date-Time/">
                  <StaticImage
                    src='../../../images/720p_Series_CGI_List/System_Menu/WebUI-System_Date_square.webp'
                    alt="720p Serie CGI List - System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Datum / Uhrzeit"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
