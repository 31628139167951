import { Link } from 'gatsby';
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import ManualIndoorArticles from 'components/Products/Usermanuals/IntroIndoorManual';
import ManualOutdoorArticles from 'components/Products/Usermanuals/IntroOutdoorManual';
import * as React from 'react';
export default {
  Link,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  ManualIndoorArticles,
  ManualOutdoorArticles,
  React
};