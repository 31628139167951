import { EuiSpacer } from '@elastic/eui';
import BitrateGraphs from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/Graphs/BandwidthGraphsFlyout';
import CompressionGraphs from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/Graphs/CompressionGraphsFlyout';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/MultimediaPerm';
import UpdateBox from 'components/Web_User_Interface/1440p_Series/Multimedia/Image/PrimaryBoxUpdates';
import VideoTable from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/videoTable';
import PrimaryBox from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  BitrateGraphs,
  CompressionGraphs,
  SuccessBox,
  PermissionBox,
  UpdateBox,
  VideoTable,
  PrimaryBox,
  BreadCrumbs,
  SEOHelmet,
  React
};