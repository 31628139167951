import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
    <EuiFlexItem>
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <Link to="/Downloads/Outdoor_Cameras/IN-9420_WQHD/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-9420WQHD_Downloads.webp'
                    alt="IN-9420 WQHD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
            }
            title="IN-9420 WQHD"
            description="The IN-9420 WQHD with a SONY STARVIS Images Sensor and a 5 megapixel video resolution."
            footer={
              <EuiFlexGroup justifyContent="flexEnd">
                <Link to="/Downloads/Outdoor_Cameras/IN-9420_WQHD/">
                  <EuiFlexItem grow={false}>
                    <EuiButton size="s" fill>
                      Read
                    </EuiButton>
                    <EuiSpacer size="s" />
                  </EuiFlexItem>
                </Link>
              </EuiFlexGroup>
            }
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <Link to="/Downloads/Outdoor_Cameras/IN-9408_WQHD/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-9408WQHD_Downloads.webp'
                    alt="IN-9408 WQHD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
            }
            title="IN-9408 WQHD"
            description="The IN-9408 WQHD with a SONY STARVIS Images Sensor and a 5 megapixel video resolution."
            footer={
              <EuiFlexGroup justifyContent="flexEnd">
                <Link to="/Downloads/Outdoor_Cameras/IN-9408_WQHD/">
                  <EuiFlexItem grow={false}>
                    <EuiButton size="s" fill>
                      Read
                    </EuiButton>
                    <EuiSpacer size="s" />
                  </EuiFlexItem>
                </Link>
              </EuiFlexGroup>
            }
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <Link to="/Downloads/Outdoor_Cameras/IN-9020_HD/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-9020HD.webp'
                    alt="IN-9020 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
            }
            title="IN-9020 Full HD"
            description="The IN-9020 HD is the first INSTAR Outdoor IP Surveillance PTZ camera, utilizing our new Panasonic 1080p / fullHD image sensor and an Panasonic PIR sensor."
            footer={
              <EuiFlexGroup justifyContent="flexEnd">
                <Link to="/Downloads/Outdoor_Cameras/IN-9020_HD/">
                  <EuiFlexItem grow={false}>
                    <EuiButton size="s" fill>
                      Read
                    </EuiButton>
                    <EuiSpacer size="s" />
                  </EuiFlexItem>
                </Link>
              </EuiFlexGroup>
            }
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>

    <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <Link to="/Downloads/Outdoor_Cameras/IN-9010_HD/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-9010HD.webp'
                    alt="IN-9010 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
            }
            title="IN-9010 Full HD"
            description="The IN-9010 HD is the first INSTAR Outdoor IP Surveillance PTZ camera, utilizing our new Panasonic 1080p / fullHD image sensor."
            footer={
              <EuiFlexGroup justifyContent="flexEnd">
                <Link to="/Downloads/Outdoor_Cameras/IN-9010_HD/">
                  <EuiFlexItem grow={false}>
                    <EuiButton size="s" fill>
                      Read
                    </EuiButton>
                    <EuiSpacer size="s" />
                  </EuiFlexItem>
                </Link>
              </EuiFlexGroup>
            }
          />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Outdoor_Cameras/IN-9008_HD/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-9008HD_Downloads.webp'
                    alt="IN-9008 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-9008 Full HD"
              description="The IN-9008 HD is the first INSTAR Outdoor IP Surveillance camera, utilizing our new Panasonic 1080p / fullHD image sensor."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Outdoor_Cameras/IN-9008_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Outdoor_Cameras/IN-7011_HD/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-7011HD_Downloads.webp'
                    alt="IN-7011 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-7011 HD"
              description="The IN-7011 HD is a weather proof dome camera which can be used inside and outside. A motor allows for change of the lenses viewing direction."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Outdoor_Cameras/IN-7011_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Outdoor_Cameras/IN-5907_HD/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-5907HD_Downloads.webp'
                    alt="IN-5907 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-5907 HD"
              description="The IN-5907 HD is weather proof and wireless outdoor camera. Thanks to the built-in high-powered infrared diodes you can see deep into the night."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Outdoor_Cameras/IN-5907_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Outdoor_Cameras/IN-5905_HD/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-5905HD_Downloads.webp'
                    alt="IN-5905 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-5905 HD"
              description="Looking for a small outdoor camera? The all new IN-5905HD, basically a drastically smaller version of our flagship model IN-5907HD is what you are looking for!"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Outdoor_Cameras/IN-5905_HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Outdoor_Cameras/IN-4011/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-4011_Downloads.webp'
                    alt="IN-4011"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-4011"
              description="The IN-4011 is the successor of the IN-4010v2 dome camera and offers advanced Pan & Tilt features due to its new powerful PTZ control board."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Outdoor_Cameras/IN-4011/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Outdoor_Cameras/IN-4010_V2/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-4010_Downloads.webp'
                    alt="IN-4010"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-4010"
              description="The IN-4010 v2 is the successor of the IN-4010 dome camera and offers advanced Pan & Tilt features due to its new powerful PTZ control board."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Outdoor_Cameras/IN-4010_V2/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Outdoor_Cameras/IN-4009/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-4010_Downloads.webp'
                    alt="IN-4009"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-4009"
              description="The IN-4009 is our IN-4010 dome camera´s small brother and offers advanced Pan & Tilt features due to its new powerful PTZ control board."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Outdoor_Cameras/IN-4009/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Outdoor_Cameras/IN-2908/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-2908_Downloads.webp'
                    alt="IN-2908"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-2908"
              description="The IN-2905v2´s big brother - stronger Infrared nightvision and a three times optical zoom make this camera modell a clear winner."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Outdoor_Cameras/IN-2908/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Outdoor_Cameras/IN-2905_V2/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-2905_Downloads.webp'
                    alt="IN-2905"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-2905"
              description="The IN-2905 v2 is the successor of the popular IN-2905. Dozens of Upgrades makes this camera on par with the more high-end line of INSTAR cameras."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Outdoor_Cameras/IN-2905_V2/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/Outdoor_Cameras/IN-2904/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-2905_Downloads.webp'
                    alt="IN-2904"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-2904"
              description="The IN-2904 is an compact solution for outdoor surveillance. The camera head is equipped with IR diodes for infrared nightvision."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/Outdoor_Cameras/IN-2904/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
