import React from 'react'

import {Link} from 'gatsby'

import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Note:">
        <EuiText>
          <p>Home Assistant comes with an <OutboundLink to="https://www.home-assistant.io/docs/mqtt/broker#embedded-broker">Embedded MQTT broker</OutboundLink>. Since we already installed <Link to="/Software/Linux/Node-RED#installing-mosquitto/">the Mosquitto MQTT Broker with Node-RED</Link> we deactivated this secondary Broker by adding the configuration above - as well as making sure that the <a href="#run-home-assistant-as-a-service">Mosquitto Service is run before Home Assistant</a>.</p><br/>
          <p>If you don't plan on using Mosquitto skip this step and Embedded MQTT Broker will be started instead.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

