import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Network/UPnP/SuccessBox';
import UPnPTable from 'components/Web_User_Interface/1080p_Series/Network/UPnP/upnpTable';
import DangerBox1 from 'components/Web_User_Interface/1080p_Series/Network/UPnP/DangerBox1';
import DangerBox2 from 'components/Web_User_Interface/1080p_Series/Network/UPnP/DangerBox2';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  SuccessBox,
  UPnPTable,
  DangerBox1,
  DangerBox2,
  BreadCrumbs,
  SEOHelmet,
  React
};