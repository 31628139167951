import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Alarm/Actions/SuccessBox';
import ActionTable from 'components/Web_User_Interface/1080p_Series/Alarm/Actions/actionTable';
import DangerBox from 'components/Web_User_Interface/1080p_Series/Alarm/Actions/DangerBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  SuccessBox,
  ActionTable,
  DangerBox,
  BreadCrumbs,
  SEOHelmet,
  React
};