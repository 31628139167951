import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import avmImage01 from "../../../../images/Software/Other_Platforms/AVM_Fritzphone/FritzphoneC4_01.webp"
import avmImage02 from "../../../../images/Software/Other_Platforms/AVM_Fritzphone/FritzphoneC4_02.webp"
import avmImage03 from "../../../../images/Software/Other_Platforms/AVM_Fritzphone/FritzphoneC4_03.webp"
import avmImage04 from "../../../../images/Software/Other_Platforms/AVM_Fritzphone/FritzphoneC4_04.webp"
import avmImage05 from "../../../../images/Software/Other_Platforms/AVM_Fritzphone/FritzphoneC4_05.webp"
import avmImage06 from "../../../../images/Software/Other_Platforms/AVM_Fritzphone/FritzphoneC4_06.webp"
import avmImage07 from "../../../../images/Software/Other_Platforms/AVM_Fritzphone/FritzphoneC4_07.webp"
import avmImage08 from "../../../../images/Software/Other_Platforms/AVM_Fritzphone/FritzphoneC4_08.webp"


export default function PoECards() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={avmImage01}
                    alt="AVM Fritzphone C4 for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig1.</strong> Press Menu on the keyboard of the phone.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={avmImage02}
                    alt="AVM Fritzphone C4 for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig2.</strong> Please go to the Home menu and press OK.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={avmImage03}
                    alt="AVM Fritzphone C4 for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig3.</strong> Then select Live Image and press OK.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={avmImage04}
                    alt="AVM Fritzphone C4 for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig4.</strong> Next, select New.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={avmImage05}
                    alt="AVM Fritzphone C4 for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig5.</strong> In the Name box, type any name for the IP camera.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={avmImage06}
                    alt="AVM Fritzphone C4 for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig6.</strong> Enter the JPG path of your camera in the URL entry field.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={avmImage07}
                    alt="AVM Fritzphone C4 for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig7.</strong> After setting the time interval and saving, the display shows the name of your IP camera.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={avmImage08}
                    alt="AVM Fritzphone C4 for your INSTAR IP Camera"
                  />
              }
              description={
                <p><strong>Fig8.</strong> Then press "OK" and the live image will be displayed on the FRITZ! Fon display.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
