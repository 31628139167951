import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="INSTAR MQTT Broker">
        <EuiText>
          <p>
            All camera functions of your INSTAR Full HD camera can be controlled through the <Link to="/1080p_Series_CGI_List/">HTTP/S CGI interface</Link>. This is the interface that is used by the web user interface and all INSTAR smartphone apps. In the Smarthome and Internet-of-Things (IoT) realm there is another protocol that is widely in use and that offers a few advantages over the classical HTTP - the protocol is called MQTT. Adding an MQTT interface* to our Full HD cameras makes adding those cameras to an existing Smarthome - almost - too easy :)
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

