import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Network/IP_Configuration/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Network_IP_Configuration.webp'
                    alt="INSTAR 1440p Web User Interface Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP Configuration"
              description="Change your cameras IP address, gateway and DNS server - all these settings are usually set up by the Installation Wizard and don´t need to be changed..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1440p_Series/Network/IP_Configuration/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Network/SSL_Certificate/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Network_IP_Configuration.webp'
                    alt="INSTAR 1440p Web User Interface Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="SSL Certificate"
              description="Your camera uses a valid CA SSL certificate that allows you to access the SSL web interface encrypted via your personal DDNS address. If you need your own SSL certificate, you can upload this to the camera here."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1440p_Series/Network/SSL_Certificate/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Network/CA_Certificate/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Network_IP_Configuration.webp'
                    alt="INSTAR 1440p Web User Interface Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="CA Certificate"
              description="Your camera uses a valid CA SSL certificate that allows you to access the SSL web interface encrypted via your personal DDNS address. If you need your own SSL certificate, you can upload this to the camera here."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1440p_Series/Network/CA_Certificate/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Network/WiFi/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Network_WiFi.webp'
                    alt="INSTAR 1440p Web User Interface Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="WiFi"
              description="Your WiFi connection should already be set up by WPS or by the Installation Wizard. Use the WiFi scanner to connect your camera to a different WiFi network..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1440p_Series/Network/WiFi/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Network/Remote_Access/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Network_Remote_Access.webp'
                    alt="INSTAR 1440p Web User Interface Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Remote Access"
              description="You have two ways to access your camera over the internet - the easy way is called. The only way to get complete access to all your camera´s functions is called DDNS..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1440p_Series/Network/Remote_Access/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Network/ONVIF/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Network_ONVIF.webp'
                    alt="INSTAR 1440p Web User Interface Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="ONVIF Service"
              description="The surveillance industry has created an open standard protocol called ONVIF that allows cameras to communicate with each other and with network recording devices..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1440p_Series/Network/ONVIF/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Network/RTSP/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Network_IP_Configuration.webp'
                    alt="INSTAR 1440p Web User Interface Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="RTSP"
              description="Enable/Disable the RTSP video stream. However in order for ONVIF to access the live video, RTSP needs to be enabled..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1440p_Series/Network/RTSP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
