import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="eRCrlHGhurc"
                    title="AVM Fritz!fon"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="AVM Fritz!fon"
              footer={
                <EuiText>
                  <p>Wir zeigen Ihnen in diesem Video wie Sie eine INSTAR HD IP Kamera in einem Fritz!Fon hinzufügen können um sich so auf dem Telefon das LiveBild der Kamera abzeigen zu lassen.</p>
                  <p>!!ACHTUNG!!: Beim Videopfad muss noch ein <strong>/tmpfs/</strong> vor dem auto.jpg stehen. Also komplett z.B. <em>http://192.168.1.249:80/tmpfs/auto.jpg</em> Wir entschuldigen uns für den Fehler.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}



// import React from 'react'

// import { Player } from 'video-react'

// import {
//     EuiText,
//     EuiTitle,
//     EuiIcon,
//     EuiPanel
//   } from '@elastic/eui'

// import { OutboundLink } from 'gatsby-plugin-gtag'

// require('../../../Layout/chrome/assets/video.css')

// function VideoCard() {
//   return (
//     <EuiPanel paddingSize="m">
//         <EuiTitle size="m"><h2>INSTAR Cloud</h2></EuiTitle>
//         <Player>
//         <source src="https://wiki.instar.com/videos/INSTAR_IP_Kamera_in_AVM_FritzFon_hinzufügen_für_LiveBild.mp4" />
//         </Player>
//         <EuiText>
//             <p>Wir zeigen Ihnen in diesem Video wie Sie eine INSTAR HD IP Kamera in einem Fritz!Fon hinzufügen können um sich so auf dem Telefon das LiveBild der Kamera abzeigen zu lassen. Auf <OutboundLink href="https://www.youtube.com/watch?v=eRCrlHGhurc" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
//             <p>!!ACHTUNG!!: Beim Videopfad muss noch ein <strong>/tmpfs/</strong> vor dem auto.jpg stehen. Also komplett z.B. <em>http://192.168.1.249:80/tmpfs/auto.jpg</em> Wir entschuldigen uns für den Fehler.</p>
//         </EuiText>
//     </EuiPanel>
//   );
// }

// export default VideoCard