import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TranslateIcon from '@material-ui/icons/Translate';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

export default function PopoverPopupState(props) {
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div className='language__toggle'>
          <Button variant='contained' startIcon={<TranslateIcon />} style={{backgroundColor: 'rgb(0, 119, 204)', color: 'rgb(255, 255, 255)'}} {...bindTrigger(popupState)}>
            EN
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box p={2}>
                <Typography>Available Languages</Typography>
                <hr/>
                <br/>
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="stretch"
                    >
                    <a rel="alternate" hreflang="en" href={props.locationEN}>
                      <Grid item>
                          <Button variant="contained" style={{backgroundColor: 'rgb(0, 119, 204)', color: 'rgb(255, 255, 255)', width: '100%', marginBottom: 10}}>
                              English
                          </Button>
                      </Grid>
                    </a>
                    <a rel="alternate" hreflang="de" href={props.locationDE}>
                      <Grid item>
                          <Button variant="contained" style={{backgroundColor: 'rgb(0, 119, 204)', color: 'rgb(255, 255, 255)', width: '100%', marginBottom: 10}}>
                              Deutsch
                          </Button>
                      </Grid>
                    </a>
                    <a rel="alternate" hreflang="fr" href={props.locationFR}>
                      <Grid item>
                          <Button variant="contained" style={{backgroundColor: 'rgb(0, 119, 204)', color: 'rgb(255, 255, 255)', width: '100%', marginBottom: 10}}>
                              Francais
                          </Button>
                      </Grid>
                    </a>
                </Grid>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}