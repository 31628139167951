import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiSpacer
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="dOYGMS6R838"
                    title="IN-7011 HD Heizung"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="IN-7011 HD Heizung"
              footer={
                <EuiText>
                  <p>In diesem Video erklären wir Ihnen wie Sie bei Ihrer Überwachungskamera IN-7011HD manuell das Objektiv scharfstellen können sofern Sie einen Bereich überwachen möchten der sich sehr nahe bzw. sehr weit weg von der Kamera befindet.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="qTNFJKNBgRo"
                    title="IN-5907 HD Heizung"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="IN-5907 HD Heizung"
              footer={
                <EuiText>
                  <p>Wir zeigen Ihnen in diesem Video wie Sie die zusätzliche Heizung bei Ihrer IN-5907HD einbauen.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />
        
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="dOYGMS6R838"
                    title="IN-5905 HD Heizung"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="IN-5905 HD Heizung"
              footer={
                <EuiText>
                  <p>Wir erklären Ihnen in diesem Video wie Sie die optional erhältliche Heizung in Ihre IP Kamera IN-5905HD einbauen können.</p>
                  <p>Hinweis: Die Heizung ist optional, die Kamera wird nicht kaputt gehen wenn Sie keine Heizung eingebaut haben. Mit der Heizung garantiert INSTAR jedoch das die Kamera auch bei -25 Grad noch problemlos funktionieren wird..</p>
                  <p>Sofern Sie keine Heizung einbauen, kann es sein das die Kamera eventuell aussetzer hat wenn es zu kalt wird bzw. im Netzwerk nicht erreichbar ist. Man kann die Heizung in diesem Fall natürlich jederzeit nachrüsten.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
