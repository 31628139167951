import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Unboxing',
      href: '/en/Quick_Installation/After_Unpacking/',
    },
    {
      text: 'First Steps',
      href: '/en/Quick_Installation/First_Steps/',
    },
    {
      text: 'WiFi',
      href: '/en/Quick_Installation/Set_Up_A_Wireless_Connection/',
    },
    {
      text: 'PoE',
      href: '/en/Quick_Installation/Power_over_Ethernet/',
    },
    {
      text: 'Powerline',
      href: '/en/Quick_Installation/Powerline/',
    },
    {
      text: 'Scanner',
      href: '/en/Quick_Installation/Alternative_IP_Scanner/',
    },
    {
      text: 'Update',
      href: '/en/Quick_Installation/WebUI_And_Firmware_Upgrade/',
    },
    {
      text: '◄ Installation',
      href: '/en/Quick_Installation/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Cloud Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}