import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Video Settings">
        <EuiText>
          <p>
            The video settings allow you to adjust the quality of all 3 video streams of your camera. Please be advised that high quality settings come with high requirements for your computer hardware and network bandwidth.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

