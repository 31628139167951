import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import InstarVisionExplorerCard from 'components/Software/macOS/InstarVision/Advanced/Explorer/instarVisionCard01';
import NavButtons from 'components/Software/macOS/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  InstarVisionExplorerCard,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};