import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "cameraColumn", title: "Camera Model" },
        { name: "antennaColumn", title: "Antenna Connector" }
      ],
      rows: [
        { cameraColumn: 'IN-2904', antennaColumn: 'RP-SMA ' },
        { cameraColumn: 'IN-2905', antennaColumn: 'RP-SMA ' },
        { cameraColumn: 'IN-2905 v2', antennaColumn: 'RP-SMA ' },
        { cameraColumn: 'IN-2907', antennaColumn: 'RP-SMA ' },
        { cameraColumn: 'IN-2908', antennaColumn: 'RP-SMA ' },
        { cameraColumn: 'IN-3001', antennaColumn: 'integrated Antenna' },
        { cameraColumn: 'IN-3003', antennaColumn: 'integrated Antenna' },
        { cameraColumn: 'IN-3005', antennaColumn: 'RP-SMA' },
        { cameraColumn: 'IN-3010', antennaColumn: 'RP-SMA' },
        { cameraColumn: 'IN-3011', antennaColumn: 'RP-SMA' },
        { cameraColumn: 'IN-4009', antennaColumn: 'SMA' },
        { cameraColumn: 'IN-4010', antennaColumn: 'RP-SMA' },
        { cameraColumn: 'IN-4010 v2', antennaColumn: 'RP-SMA' },
        { cameraColumn: 'IN-4011', antennaColumn: 'RP-SMA' },
        { cameraColumn: 'IN-5905 HD', antennaColumn: 'SMA' },
        { cameraColumn: 'IN-5907 HD', antennaColumn: 'SMA' },
        { cameraColumn: 'IN-6012 HD', antennaColumn: 'SMA' },
        { cameraColumn: 'IN-6014 HD', antennaColumn: 'SMA' },
        { cameraColumn: 'IN-7011 HD', antennaColumn: 'SMA' },
        { cameraColumn: 'IN-3003', antennaColumn: 'integrated Antenna' },
        { cameraColumn: 'IN-8015 Full HD', antennaColumn: 'RP-SMA' },
        { cameraColumn: 'IN-8401 2k+ WQHD', antennaColumn: 'integrated Antenna' },
        { cameraColumn: 'IN-8403 2k+ WQHD', antennaColumn: 'integrated Antenna' },
        { cameraColumn: 'IN-9008 Full HD', antennaColumn: 'RP-SMA' },
        { cameraColumn: 'IN-9010 Full HD', antennaColumn: 'RP-SMA' },
        { cameraColumn: 'IN-9020 Full HD', antennaColumn: 'RP-SMA' },
        { cameraColumn: 'IN-9408 2k+ WQHD', antennaColumn: 'RP-SMA' },
        { cameraColumn: 'IN-9420 2k+ WQHD', antennaColumn: 'RP-SMA' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)


// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../assets/styles/data_tables/fixed-data-table.css'

// const cameraRow = [
//   'IN-2904',
//   'IN-2905',
//   'IN-2905 v2',
//   'IN-2907',
//   'IN-2908',
//   'IN-3001',
//   'IN-3003',
//   'IN-3005',
//   'IN-3010',
//   'IN-3011',
//   'IN-4009',
//   'IN-4010',
//   'IN-4010 v2',
//   'IN-4011',
//   'IN-5905 HD',
//   'IN-5907 HD',
//   'IN-6012 HD',
//   'IN-6014 HD',
//   'IN-7011 HD',
//   'IN-8015 Full HD',
//   'IN-9008 Full HD',
//   'IN-9010 Full HD',
//   'IN-9020 Full HD',
// ]


// const antennaRow = [
//   'SMA',
//   'SMA',
//   'SMA',
//   'SMA',
//   'SMA',
//   'integrated Antenna',
//   'integrated Antenna',
//   'RP-SMA',
//   'RP-SMA',
//   'RP-SMA',
//   'SMA',
//   'RP-SMA',
//   'v2	SMA',
//   'RP-SMA',
//   'RP-SMA',
//   'RP-SMA',
//   'RP-SMA',
//   'RP-SMA',
//   'RP-SMA',
//   'RP-SMA',
//   'RP-SMA',
//   'RP-SMA',
//   'RP-SMA'
// ]

// // Render your table
// const ConnectTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={cameraRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Camera Model</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {cameraRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={250}
//         />
//         <Column
//           header={<Cell>Antenna Connector</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {antennaRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={595}
//         />
//       </Table>
//     )
// }

// export default ConnectTable