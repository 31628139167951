import { EuiSpacer } from '@elastic/eui';
import IosCard from 'components/Software/iOS/InstarVision/iOSCard';
import VideoCard from 'components/Software/iOS/InstarVision/YoutubeVideoCard';
import YoutubeCards from 'components/Software/iOS/InstarVision/YoutubeVideoCards';
import NavButtons from 'components/Software/iOS/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  IosCard,
  VideoCard,
  YoutubeCards,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};