import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Homey',
      href: '/en/Software/Home_Automation/Athom_Homey/',
    },
    {
      text: 'FHEM',
      href: '/en/Software/Home_Automation/FHEM/',
    },
    {
      text: 'Home Assistant',
      href: '/en/Software/Home_Automation/Home_Assistant/',
    },
    {
      text: 'Homematic',
      href: '/en/Software/Home_Automation/Homematic/',
    },
    {
      text: 'homee',
      href: '/en/Software/Home_Automation/Homee/',
    },
    {
      text: 'IOBroker',
      href: '/en/Software/Home_Automation/IOBroker/',
    },
    {
      text: 'Loxone',
      href: '/en/Software/Home_Automation/Loxone/',
    },
    {
      text: 'Node-RED',
      href: '/en/Software/Home_Automation/Node-RED/',
    },
    {
      text: 'OpenHAB',
      href: '/en/Software/Home_Automation/OpenHAB/',
    },
    {
      text: '◄ Smarthome',
      href: '/en/Software/Home_Automation/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Software Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}