import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Appended Alarm Trigger">
        <EuiText>
          <ul>
              <li>Alarm Area 1 triggered: <code>&active=1</code></li>
              <li>Alarm Area 2 triggered: <code>&active=2</code></li>
              <li>Alarm Area 3 triggered: <code>&active=3</code></li>
              <li>Alarm Area 4 triggered: <code>&active=4</code></li>
              <li>Alarm-In or PIR triggered: <code>&active=5</code></li>
              <li>Audio Alarm triggered: <code>&active=6</code></li>
              <li>PIR & Area1 triggered (coupled): <code>&active=7</code></li>
              <li>PIR & Area2 triggered (coupled): <code>&active=8</code></li>
              <li>PIR & Area3 triggered (coupled): <code>&active=9</code></li>
              <li>PIR & Area4 triggered (coupled): <code>&active=10</code></li>
              <li>Manual Trigger (<em>cmd=pushhostalarm</em>): <code>&active=99</code></li>
          </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

