import { Link } from 'gatsby';
import CloudExtensions from 'components/Motion_Detection/INSTAR_Cloud/IntroCloudExtensions';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  CloudExtensions,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};