import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/Ethernet/Quickinstallation_Tile_9008_Step_17.webp"
import qiSelect02 from "../../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/Ethernet/Quickinstallation_Tile_9008_Step_18.webp"
import qiSelect03 from "../../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/Ethernet/Quickinstallation_Tile_9008_Step_19.webp"
import qiSelect04 from "../../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/Ethernet/Quickinstallation_Tile_9008_Step_20.webp"




export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='II-d-7 WiFi Connection'
              image={
                  <img
                    src={qiSelect01}
                    alt="Access your camera´s WiFi Settings and enter your WiFi information. Save your credentials, unplug the network cable and restart your camera, to connect it to your WiFi network."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Access your camera´s WiFi Settings and enter your <Link to="/Web_User_Interface/1080p_Series/Network/WiFi/">WiFi information</Link>. Save your credentials, unplug the network cable and restart your camera, to connect it to your WiFi network.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='II-d-8 Internet Access'
              image={
                  <img
                    src={qiSelect02}
                    alt="You need to Forward a Port inside your router, if you want to access your camera over it´s DDNS Address from the internet. Alternatively, you can use the P2P User ID to access your camera with our smartphone app (see Step 13 below)."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">You need to <Link to="/Internet_Access/Port_Forwarding/">Forward a Port</Link> inside your router, if you want to access your camera over it´s <Link to="/Internet_Access/The_DDNS_Service/">DDNS Address</Link> from the internet. Alternatively, you can use the <Link to="/Web_User_Interface/1080p_Series/Network/Internet_Access/">P2P User ID</Link> to access your camera with our smartphone app (see Step 13 below).</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='II-d-9 DDNS Access'
              image={
                  <img
                    src={qiSelect03}
                    alt="Now you can enter your camera´s DDNS information to our free Windows Phone, Windows Metro, Android, iPhone, iPad or Blackberry App."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Now you can enter your camera´s DDNS information to our free <Link to="/Software/Windows/InstarVision/Windows_Phone/DDNS/">Windows Phone</Link>, <Link to="/Software/Windows/InstarVision/Metro/DDNS/">Windows Metro</Link>, <Link to="/Software/Android/InstarVision/DDNS/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/DDNS/">iPhone</Link>, <Link to="/Software/iOS/InstarVision/iPad/DDNS/">iPad</Link> or <Link to="/Software/Other_Platforms/InstarVision_Blackberry/">Blackberry</Link> App.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='II-d-10 P2P Access'
              image={
                  <img
                    src={qiSelect04}
                    alt="If you cannot open a port for camera´s DDNS service, you can still use the POINT-2-POINT SERVICE instead with our Windows Phone, Windows Metro, Android, iPhone, iPad or Blackberry App."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">If you cannot open a port for camera´s DDNS service, you can still use the POINT-2-POINT SERVICE instead with our <Link to="/Software/Windows/InstarVision/Windows_Phone/P2P/">Windows Phone</Link>, <Link to="/Software/Windows/InstarVision/Metro/P2P/">Windows Metro</Link>, <Link to="/Software/Android/InstarVision/P2P/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/P2P/">iPhone</Link>, <Link to="/Software/iOS/InstarVision/iPad/P2P/">iPad</Link> or <Link to="/Software/Other_Platforms/InstarVision_Blackberry/">Blackberry</Link> App.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
