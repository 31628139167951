import SuccessBox from 'components/Web_User_Interface/1080p_Series/System/Backup/SuccessBox';
import BackupTable from 'components/Web_User_Interface/1080p_Series/System/Backup/backupTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  SuccessBox,
  BackupTable,
  BreadCrumbs,
  SEOHelmet,
  React
};