import React, { useState, Fragment } from 'react';

import {
  EuiIcon,
  EuiHeaderAlert,
  EuiHeaderSectionItemButton,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiTitle,
  EuiLink,
  EuiFlyoutFooter,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiText,
  EuiBadge,
  EuiShowFor,
} from '@elastic/eui';

export default () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const [showBadge, setShowBadge] = useState(true);

  const alerts = [
    {
      title: '2k+ WQHD Update 3.0',
      text: 'Use Custom Alarmserver Header:',
      action: (
        <>
          <EuiLink
            href="https://forum.instar.com/t/firmware-update-3-0-0-733/29825">
            Changelog
          </EuiLink>,{" "} 
          <EuiLink
            href="https://wiki.instar.com/en/Frequently_Asked_Question/WQHD_Alarmserver_Custom_Headers/">
            Working with alarm server headers
          </EuiLink>,{" "} 
          <EuiLink
            href="https://wiki.instar.com/en/Frequently_Asked_Question/Debugging_Alarmserver_Headers/">
            Debugging the HTTP alarm server in Node-RED
          </EuiLink>
        </>
      ),
      date: '8 Mar 2024',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: '2k+ WQHD Update 3.0',
      text: 'MQTT Anpassungen an neu integrierte Funktionen:',
      action: (
        <>
          <EuiLink
            href="https://forum.instar.com/t/firmware-update-3-0-0-733/29825">
            Changelog
          </EuiLink>,{" "} 
          <EuiLink
            href="https://forum.instar.com/t/firmware-update-3-0-0-733/29825/6">
            List of new MQTT topics
          </EuiLink>,{" "} 
          <EuiLink
            href="https://wiki.instar.com/en/Advanced_User/INSTAR_MQTT_Broker/">
            INSTAR MQTT Service
          </EuiLink>
        </>
      ),
      date: '8 Mar 2024',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: '2k+ WQHD Update 3.0',
      text: 'Extended User Management:',
      action: (
        <>
          <EuiLink
            href="https://forum.instar.com/t/firmware-update-3-0-0-733/29825">
            Changelog
          </EuiLink>,{" "} 
          <EuiLink
            href="https://wiki.instar.com/en/Frequently_Asked_Question/WQHD_User_Permissions/">
            FAQ // User authorization system
          </EuiLink>,{" "} 
          <EuiLink
            href="https://wiki.instar.com/en/Web_User_Interface/1440p_Series/System/User/">
            System Menu // User Management
          </EuiLink>
        </>
      ),
      date: '8 Mar 2024',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: '2k+ WQHD Update 2.2',
      text: 'New ad-hoc WLAN mode, better ONVIF support and new polygon alarm areas:',
      action: (
        <EuiLink
          href="https://wiki.instar.com/en/Web_User_Interface/1440p_Series/Alarm/Areas/">
          New - Alarm areas with more than 4 corners!
        </EuiLink>
      ),
      date: '19 Jan 2024',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: 'API Search',
      text: 'INSTAR offers you two fully documented programming interfaces with which you can integrate your camera into your third-party software either via HTTP or MQTT. The overview list of available functions is summarized in a searchable list.',
      action: (
        <EuiLink
          href="https://wiki.instar.com/de/1440p_Serie_CGI_Befehle/Komplette_CGI_Liste/">
          API search for HTTP CGI commands and MQTT topics
        </EuiLink>
      ),
      date: '08 Dez 2023',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: '2k+ WQHD WebUI Update 2.0',
      text: 'Massive revision of the firmware and web interface with many new functions - such as separate day/night image settings, improved WDR function, support of WPA2 / WPA3 Enterprise (PEAP/TTLS) for the WLAN connection, integration of a contact form directly in the web interface, optimization of noise detection. You can view the complete changelog for your camera model in our download area - e.g. here:',
      action: (
        <EuiLink
          href="https://wiki.instar.com/de/Downloadbereich/Innenkameras/IN-8415_WQHD/">
          IN-8415 2K+ WQHD Download Area
        </EuiLink>
      ),
      date: '02 Dez 2023',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: 'InstarVision Android / iOS App Update',
      text: 'Redesign of the multiple view and integration of new camera models. The apps are now available free of charge from the Google Playstore and Apple Store.',
      action: (
        <EuiLink
          href="https://wiki.instar.com/de/Downloadbereich/Mobile_Apps/Android_BlackBerry/">
          Android APK Download
        </EuiLink>
      ),
      date: '01 Dez 2023',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: 'IN-9420 2K+ WQHD PTZ Camera',
      text: 'The IN-9420 - the direct successor to the IN-9020 - will initially be available in limited quantities on 11.12.2023. Regular sales will begin in early next year.',
      action: (
        <EuiLink
          href="https://wiki.instar.com/de/Aussenkameras/IN-9420_WQHD/">
          IN-9420 2K+ WQHD Overview
        </EuiLink>
      ),
      date: '28 Nov 2023',
      badge: <EuiBadge color="hollow">Release</EuiBadge>,
    },
    {
      title: 'FHD WebUI Update 3.2 (362)',
      text: 'HomeKit Support - Add your camera to Apple`s HomeKit app now! Enable / disable number plate or face detection from the cloud. Both the backup alarm schedule and the regular alarm schedule are now switchable via MQTT.',
      action: (
        <EuiLink
          href="https://wiki.instar.com/en/Quick_Installation/WebUI_And_Firmware_Upgrade/">
          Download via the Auto Updater
        </EuiLink>
      ),
      date: '04 Jun 2021',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: '#InstarMoments Program',
      text: 'Until 1 April: Share your best INSTAR shots and win great prizes!',
      action: (
        <EuiLink
          target="_blank"
          external
          href="https://moments.instar.de">
          Join in now!
        </EuiLink>
      ),
      date: '08 Feb 2021',
      badge: <EuiBadge>Campaign</EuiBadge>,
    },
    {
      title: 'FHD WebUI Update 3.1 (347)',
      text: 'MQTT adapter has been revised - freely selectable MQTT prefix and camera ID, automatic reset of the MQTT alarm server and new last-will-topic for monitoring the camera.',
      action: (
        <EuiLink
          href="https://wiki.instar.com/en/Quick_Installation/WebUI_And_Firmware_Upgrade/">
          Download via the Auto Updater
        </EuiLink>
      ),
      date: '20 Dec 2020',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: 'INSTAR products for Prime Day 2020: Security cameras on offer',
      text:
        'Bargain hunters beware: Amazon Prime Day starts now until 23:59 on 14 October. The annual shopping event includes lots of bargains from all categories, from technology to fashion to household items. The manufacturer INSTAR, which develops security cameras and network technology in this country, is also taking part in the two-day special promotion with discounts of up to 60%.',
      action: (
        <EuiLink
          target="_blank"
          external
          href="https://www.instar.de/news/index/view/?id=6">
          Read the news
        </EuiLink>
      ),
      date: '19 Oct 2020',
      badge: <EuiBadge color="hollow">Campaign</EuiBadge>,
    },
    {
      title: 'InstarVision Android Beta Update v1.5.6',
      text:
        'Fixed a bug in the Advanced/Email Settings menu. The data for the alarm email notification on the camera can now be changed via the app. When displaying the alarm status in the Multi view (see last update), the loading process has been optimised for a larger number of integrated cameras.',
      action: (
        <EuiLink
          target="_blank"
          external
          href="http://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.5.6b2.zip">
          Download APK
        </EuiLink>
      ),
      date: '20 Sept 2020',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: 'FHD WebUI Update 3.0 (321)',
      text:
        'Audio support for the HTML5 video stream in the Safari browser, The timed IR switch has been linked to the IR cut switch, The WDR mode can now be set permanently according to schedule, the wiki search has been reworked - and more',
      action: (
        <EuiLink
          href="https://wiki.instar.com/en/Quick_Installation/WebUI_And_Firmware_Upgrade/">
          Download via the Auto Updater
        </EuiLink>
      ),
      date: '20 Aug 2020',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
    {
      title: 'InstarVision Android & iOS Update',
      text: 'Updates are now available via the App Stores: New setup wizard for installing and adding cameras, Improved manual recording performance, Option to share/send exported camera data, Option to save alarm schedule templates, Integration of new camera models...',
      action: (
        <EuiLink
          target="_blank"
          external
          href="https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.5.4.zip">
          Download APK
        </EuiLink>
      ),
      date: '20 Jul 2020',
      badge: <EuiBadge color="hollow">Update</EuiBadge>,
    },
  ];

  const closeFlyout = () => {
    setIsFlyoutVisible(false);
  };

  const showFlyout = () => {
    setShowBadge(false);
    setIsFlyoutVisible(!isFlyoutVisible);
  };

  const searchButton = (
    <EuiShowFor sizes={['l', 'xl']}>
      <EuiButtonEmpty
        iconType="search"
        color="text"
        size="m"
        iconSide="right"
        href="/en/Search/">
        Search
      </EuiButtonEmpty>
    </EuiShowFor>
  );

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls="headerNewsFeed"
      aria-expanded={isFlyoutVisible}
      aria-haspopup="true"
      aria-label={`News feed: ${
        { showBadge } ? 'Updates available' : 'No updates'
      }`}
      onClick={() => showFlyout()}
      notification={showBadge && '1'}>
      <EuiIcon type="boxesVertical" size="m" />
    </EuiHeaderSectionItemButton>
  );

  let flyout;
  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout
        onClose={() => closeFlyout()}
        size="s"
        id="headerNewsFeed"
        aria-labelledby="flyoutSmallTitle">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="s">
            <h2 id="flyoutSmallTitle">What&apos;s new</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          {alerts.map((alert, i) => (
            <EuiHeaderAlert
              key={`alert-${i}`}
              title={alert.title}
              action={alert.action}
              text={alert.text}
              date={alert.date}
              badge={alert.badge}
            />
          ))}
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                iconType="cross"
                onClick={() => closeFlyout()}
                flush="left">
                Close
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText color="subdued" size="s">
                <p>INSTAR News Feed</p>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    );
  }

  return (
    <Fragment>
      {searchButton}
      {button}
      {flyout}
    </Fragment>
  );
};
