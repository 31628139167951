import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Account Settings">
        <EuiText>
          <p>
            Configure your user token expiration, your preferred UI language and add two-factor authentication for your account. The email address added here can be used with the "Forgot Password" function as well.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

