import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Can I connect my WiFi camera over PoE?">
        <EuiText>
          <p><strong>Yes</strong>! None-PoE cameras, like the models <Link to="/Outdoor_Cameras/IN-5905_HD/">IN-5905 HD</Link> or <Link to="/Indoor_Cameras/IN-6001_HD/">IN-6001 HD</Link> can be connected to your network and powered by PoE using a <strong>PoE Splitter</strong>.</p>
          <p>Make sure to use a <OutboundLink href="https://www.instar.de/accessories/poe-accessories/poe-splitter-5v.html" target="_blank" rel="noopener noreferrer">5V Splitter</OutboundLink> or a 5V camera model, like the IN-6001 HD, while models like the IN-5905 HD need a <OutboundLink href="https://www.instar.de/accessories/poe-accessories/poe-splitter-12v.html" target="_blank" rel="noopener noreferrer">12V Splitter</OutboundLink>.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

