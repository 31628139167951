import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ConfigTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "parameterColumn", title: "Parameter" },
        { name: "valueColumn", title: "Description" }
      ],
      rows: [
        { parameterColumn: 'Application', valueColumn: 'There are a lot of presets which you can choose from. Such as _HTTP Server_, _Webserver_ or others. We recommend to make a custom rule each time instead of using a preset rule.' },
        { parameterColumn: 'Name', valueColumn: 'This is only important if you are using more than one camera so that you can differentiate later between the different cameras. You can name it for example with the camera name or the camera location.' },
        { parameterColumn: 'Protocol', valueColumn: 'The camera only needs the TCP protocol. You don`t need to forward UDP traffic.' },
        { parameterColumn: 'From (external) Port', valueColumn: 'This is the external (WAN) Port with which you can access the camera later through the internet. If you are only using one camera it is best to set it as 80. If you are using more than one camera you have to increase the port for each camera. So the second camera would use the Port 8081 for example.' },
        { parameterColumn: 'To (external) Port', valueColumn: 'Some routers might use Port 80 as the Service Port. If so you have to use Port 8081 for the first camera.' },
        { parameterColumn: 'IP Address', valueColumn: 'Here you have to fill in the IP address from the camera.' },
        { parameterColumn: 'To (internal) Port', valueColumn: 'This is the internal Port of the camera which we have set up earlier. If you are using a different Port, then 80 inside your network then you have to set it here accordingly. We recommend to use Port 80 at this moment.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ConfigTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ConfigTable)



// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'

// const parameterRow = [
//   'Application',
//   'Name',
//   'Protocol',
//   'From (external) Port',
//   'To (external) Port',
//   'IP Address',
//   'To (internal) Port'
// ]



// const descriptionRow = [
//   'There are a lot of presets which you can choose from. Such as _HTTP Server_, _Webserver_ or others. We recommend to make a custom rule each time instead of using a preset rule.',
//   'This is only important if you are using more than one camera so that you can differentiate later between the different cameras. You can name it for example with the camera name or the camera location.',
//   'The camera only needs the TCP protocol. You don`t need to forward UDP traffic.',
//   'This is the external (WAN) Port with which you can access the camera later through the internet. If you are only using one camera it is best to set it as 80. If you are using more than one camera you have to increase the port for each camera. So the second camera would use the Port 8081 for example.',
//   'Some routers might use Port 80 as the Service Port. If so you have to use Port 8081 for the first camera.',
//   'Here you have to fill in the IP address from the camera.',
//   'This is the internal Port of the camera which we have set up earlier. If you are using a different Port, then 80 inside your network then you have to set it here accordingly. We recommend to use Port 80 at this moment.'
// ]



// // Render your table
// const ConfigTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={parameterRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Parameter</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {parameterRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={150}
//         />
//         <Column
//           header={<Cell>Description</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {descriptionRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={3}
//           width={700}
//         />
//       </Table>
//     )
// }

// export default ConfigTable