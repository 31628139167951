import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Hinweis:">
        <EuiText>
          <p>Before forwarding a port to your home automation system always make sure that access to it is password protected! In case of e.g. <OutboundLink href="https://nodered.org/docs/user-guide/runtime/securing-node-red">Node-RED</OutboundLink> you both have to protect your Node-Red user interface by setting the <em>adminAuth</em>and also the <em>httpNodeAuth</em> variable to protect the webhook itself. Both can be set inside the  <OutboundLink href="https://nodered.org/docs/user-guide/runtime/securing-node-red">setting.js</OutboundLink> file.</p>
        </EuiText >
    </EuiCallOut>
  );
}

export default DangerBox

