import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import avAvast from "../../../images/Quick_Installation/Live_Video/Live-Video_AVAST.webp"
import avKis2013 from "../../../images/Quick_Installation/Live_Video/Live-Video_Kaspersky2013.webp"
import avKis2014 from "../../../images/Quick_Installation/Live_Video/Live-Video_Kaspersky2014.webp"
import avKis2017 from "../../../images/Quick_Installation/Live_Video/Live-Video_Kaspersky2017.webp"
import avAVG from "../../../images/Quick_Installation/Live_Video/Live-Video_AVG.webp"
import avAVIRA from "../../../images/Quick_Installation/Live_Video/Live-Video_AVIRA.webp"
import avFSecure from "../../../images/Quick_Installation/Live_Video/Live-Video_F-Secure.webp"


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Live_Video/Troubleshooting/AVAST/">
                  <img
                    src={avAvast}
                    alt="Avast Security"
                  />
                </Link>
              }
              title='Avast Security'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Live_Video/Troubleshooting/AVAST/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Live_Video/Troubleshooting/Kaspersky_2013/">
                  <img
                    src={avKis2013}
                    alt="Kaspersky 2013"
                  />
                </Link>
              }
              title='Kaspersky 2013'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Live_Video/Troubleshooting/Kaspersky_2013/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Live_Video/Troubleshooting/Kaspersky_2014/">
                  <img
                    src={avKis2014}
                    alt="Kaspersky 2014"
                  />
                </Link>
              }
              title='Kaspersky 2014'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Live_Video/Troubleshooting/Kaspersky_2014/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Live_Video/Troubleshooting/Kaspersky_2017/">
                  <img
                    src={avKis2017}
                    alt="Kaspersky 2017"
                  />
                </Link>
              }
              title='Kaspersky 2017'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Live_Video/Troubleshooting/Kaspersky_2017/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Live_Video/Troubleshooting/AVG/">
                  <img
                    src={avAVG}
                    alt="AVG Internet Security"
                  />
                </Link>
              }
              title='AVG Internet Security'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Live_Video/Troubleshooting/AVG/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Live_Video/Troubleshooting/AVIRA/">
                  <img
                    src={avAVIRA}
                    alt="AVIRA Antivirus Pro"
                  />
                </Link>
              }
              title='AVIRA Antivirus Pro'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Live_Video/Troubleshooting/AVIRA/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Live_Video/Troubleshooting/F-Secure/">
                  <img
                    src={avFSecure}
                    alt="F-Secure Security"
                  />
                </Link>
              }
              title='F-Secure Security'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Live_Video/Troubleshooting/F-Secure/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Live_Video/Troubleshooting/GDATA/">
                  <img
                    src={avAVG}
                    alt="GDATA Security"
                  />
                </Link>
              }
              title='GDATA Security'
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Live_Video/Troubleshooting/GDATA/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
