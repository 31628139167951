import React from "react";

import { EuiText, EuiCallOut } from "@elastic/eui";

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Relay Schedule">
      <EuiText>
        <p>
          Set a schedule for for your cameras alarm output relay for days of the
          week and time of the day. Use the camera relay to switch external
          devices.
        </p>
      </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox;
