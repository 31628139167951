import React from 'react'

import { Player } from 'video-react'

import {
    EuiText,
    EuiIcon,
    EuiPanel
  } from '@elastic/eui'

import { OutboundLink } from 'gatsby-plugin-gtag'

require('../../Layout/chrome/assets/video.css')

function VideoCard() {
  return (
    <EuiPanel paddingSize="m">
        <Player>
            <source src="https://wiki.instar.com/videos/Das_Prinzip_von_DDNS_Portweiterleitung_und_P2P_in_einfachen_Worten_erklaert.mp4" />
        </Player>
        <EuiText>
            <p>In diesem Video erklären wir Ihnen &quot;hoffentlich&quot; auf verständliche Weise wie DDNS funktioniert, was es ist und wie das Prinzip der Portweiterleitung funktioniert. Zudem zeigen wir auch kurz auf wie P2P funktioniert und aufgebaut ist.</p>
            <p>Folgen Sie uns jetzt im INSTARTV YouTube Kanal, abbonieren Sie uns und erhalten Sie automatisch Informationen sobald neue Videos verfügbar sind damit Sie Ihre INSTAR IP Kamera noch besser nutzen können. Auf <OutboundLink href="https://www.youtube.com/watch?v=FjE95bL7_p4" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
        </EuiText>
    </EuiPanel>
  );
}

export default VideoCard