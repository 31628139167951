import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Changing the WebUI Port:">
        <EuiText>
          <p>By default the Shinobi WebUI uses the port <code>8080</code>. Due to a port conflict we were forced to change that port to <code>8888</code>. The following tutorial will keep refering to the default port - but all screenshots will show the changed port.</p>
          <p><strong>OPTIONAL</strong>: In case you need to change the port as well, this can be done in the software installation directory (default: <code>/home/Shinobi</code>) by editing both the <code>conf.json</code> and <code>conf.sample.json</code> file:</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

