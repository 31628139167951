import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class UserTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Permission" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Basic Rights', descriptionColumn: 'Required minimal set of right needed to be able to interact with the web user interface.' },
        { actionColumn: 'User Management', descriptionColumn: 'In connection with the system right, this is the authorization to create users and adjust user permissions. If the system right is missing, you can only customize your own account.' },
        { actionColumn: 'Basic Authentication', descriptionColumn: 'Permission to access camera services using the basic authentication. Already created access tokens remain valid of basicAuthentication is removed but cannot be extended. A valid access token is required to access the camera`s webUI. The P2P access, if allowed, is possible without basicAuthentication.' },
        { actionColumn: 'Live Stream', descriptionColumn: 'Permission to access the camera`s live video stream.' },
        { actionColumn: 'RTSP Stream', descriptionColumn: 'Permission to access your camera`s RTSP stream.' },
        { actionColumn: 'SD Viewer', descriptionColumn: 'Permission to access the SD Card Viewer and access alarm recordings. The Viewer is only displayed when an SD card is mounted.' },
        { actionColumn: 'Audio Features', descriptionColumn: 'Permission to use your camera`s two-way audio intercom function.' },
        { actionColumn: 'Multimedia Settings', descriptionColumn: 'Permission to access the Multimedia menu and make changes to the Audio, Video and Image settings of your camera.' },
        { actionColumn: 'Alarm Settings', descriptionColumn: 'Permission to access the Alarm menu, configure the alarm actions, alarm motion detection areas, object detection, alarm schedule and push notification.' },
        { actionColumn: 'Alarm Relay', descriptionColumn: 'Permission to control the camera alarm relay through the corresponding button tile.' },
        { actionColumn: 'Whitelight LEDs', descriptionColumn: 'Permission to control the white light LEDs of your camera through the corresponding button tile.' },
        { actionColumn: 'System Settings', descriptionColumn: 'Permission to access the System, Network, Features and Smarthome menu.' },
        { actionColumn: 'PTZ Settings', descriptionColumn: 'Permission to save/override pan, tilt and zoom preset positions.' },
        { actionColumn: 'PTZ Control', descriptionColumn: 'Permission to control your camera`s pan, tilt and zoom.' },
        { actionColumn: 'ONVIF (Admin)', descriptionColumn: 'Permission to access the ONVIF menu and administer the ONVIF service. The permission "System Settings" includes the permission "ONVIF Administrator".' },
        { actionColumn: 'ONVIF (User)', descriptionColumn: 'Permission to use your camera`s ONVIF service to control the camera.' },
        { actionColumn: 'ONVIF (Guest)', descriptionColumn: 'Permission to use your camera`s ONVIF service to access the live video stream.' },
        { actionColumn: 'P2P Connection', descriptionColumn: 'Permission to access the camera using the InstarVision smartphone app and the point-2-point service.' },
        { actionColumn: 'Push Notifications', descriptionColumn: 'Permission to receive alarm push notifications through the InstarVision app.' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

UserTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(UserTable)