import { Link } from 'gatsby';
import VideoCard from 'components/Products/IN-LAN/YoutubeVideoCardINLAN';
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  VideoCard,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};