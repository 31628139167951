import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Overview',
      href: '/en/Indoor_Cameras/IN-6001_HD/',
    },
    {
      text: 'Spezifikationen',
      href: '/en/Indoor_Cameras/IN-6001_HD/Technical_Specifications/',
    },
    {
      text: 'Features',
      href: '/en/Indoor_Cameras/IN-6001_HD/Product_Features/',
    },
    {
      text: 'Installation',
      href: '/en/Indoor_Cameras/IN-6001_HD/Quick_Installation/',
    },
    {
      text: 'P2P',
      href: '/en/Indoor_Cameras/IN-6001_HD/Point2Point/',
    },
    {
      text: 'Objektivjustage',
      href: '/en/Indoor_Cameras/IN-6001_HD/Lense_Adjustment/',
    },
    {
      text: 'Handbuch',
      href: '/en/Indoor_Cameras/IN-6001_HD/Usermanual/',
    },
    {
      text: '◄ Innenkameras',
      href: '/en/Indoor_Cameras/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={10}
        aria-label="INSTAR IN-6001 HD Online Usermanual Chapters"
      />
      <EuiSpacer />
    </Fragment>
  );
}