import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/How_does_an_IP_Camera_Work/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_HowDoesAnIPCameraWork.webp'
                    alt="How does an IP Camera Work?"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Wie funktioniert eine IP Kamera"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/How_does_an_IP_Camera_Work/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/After_Unpacking/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_AfterUnpackingYourIPCamera.webp'
                    alt="After Unpacking"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Nach dem Auspacken"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/After_Unpacking/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/First_Steps/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_FirstStepsWithYourIPCamera.webp'
                    alt="First Steps"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Erste Schritte"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/First_Steps/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Power_over_Ethernet/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_PowerOverEthernet.webp'
                    alt="Power over Ethernet"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Power over Ethernet"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Power_over_Ethernet/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Powerline/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_Powerline.webp'
                    alt="IN-LAN Powerline"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-LAN Powerline"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Powerline/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Direct_LAN_Connection/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_DirectConnectionWithYourPC.webp'
                    alt="Direct LAN Connection"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Direkte LAN Verbindung"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Direct_LAN_Connection/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Alternative_IP_Scanner/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_AlternativeIPScanner_eng.webp'
                    alt="IP Camera Search"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP Kamera Suche"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Alternative_IP_Scanner/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Language_Selection/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_LanguageSettings.webp'
                    alt="Language Selection"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Sprachauswahl"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Language_Selection/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Live_Video/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_LiveVideo.webp'
                    alt="Zeigen Sie das Video Ihrer Kameras in Ihrem Browser an"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Zeigen Sie das Video Ihrer Kameras in Ihrem Browser an"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Live_Video/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Creating_User_Accounts/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_CreatingUserAccounts.webp'
                    alt="Creating User Accounts"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Benutzerverwaltung"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Creating_User_Accounts/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/WebUI_And_Firmware_Upgrade/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_WebUIFirmwareUpgrade.webp'
                    alt="Software Update"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Software Update"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/WebUI_And_Firmware_Upgrade/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_ClearBrowsingHistory.webp'
                    alt="How To Clear Your Browsing History"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Löschen des Browserverlaufs"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Internet_Protocol_IPv6/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_DirectConnectionWithYourPC.webp'
                    alt="Internet Protokoll Version 6 (IPv6)"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Internet Protokoll Version 6 (IPv6)"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Internet_Protocol_IPv6/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_WLAN.webp'
                    alt="Wireless Protected Setup"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Wireless Protected Setup"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

<EuiSpacer />

<EuiFlexItem>
  <EuiFlexGroup gutterSize="l" direction="row" >
    {/* <EuiFlexItem>
      <EuiCard
        textAlign="left"
        image={
          <Link to="/Quick_Installation/ONVIF/">
            <StaticImage
              src='../../images/Search/QI_SearchThumb_ONVIF.webp'
              alt="ONVIF"
              placeholder="dominantColor"
              formats={["auto", "webp", "avif"]}
              loading="lazy"
              />
          </Link>
        }
        title="ONVIF"
        footer={
          <EuiFlexGroup justifyContent="flexEnd">
            <Link to="/Quick_Installation/ONVIF/">
              <EuiFlexItem grow={false}>
                <EuiButton size="s" fill>
                  More
                </EuiButton>
                <EuiSpacer size="s" />
              </EuiFlexItem>
            </Link>
          </EuiFlexGroup>
        }
      />
    </EuiFlexItem> */}
    <EuiFlexItem>
    </EuiFlexItem>
  </EuiFlexGroup>
</EuiFlexItem>
    </React.Fragment>
  );
}