import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_BlueIris_Tutorials/PrimaryBox';
import SettingsTable from "../../../../src/components/Frequently_Asked_Question/BlueIris_v5_http_alarmserver/Table";
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  PrimaryBoxInfo,
  SettingsTable,
  BreadCrumbs,
  SEOHelmet,
  React
};