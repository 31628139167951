import React, { useState } from 'react'

import {EuiButton} from "@elastic/eui"

import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel"

import Wizard01 from '../../../../../images/Web_User_Interface/1440p_Series/Wizard/Wizard_01.webp'
import Wizard02 from '../../../../../images/Web_User_Interface/1440p_Series/Wizard/Wizard_02.webp'
import Wizard03 from '../../../../../images/Web_User_Interface/1440p_Series/Wizard/Wizard_03.webp'
import Wizard04 from '../../../../../images/Web_User_Interface/1440p_Series/Wizard/Wizard_04.webp'
import Wizard05 from '../../../../../images/Web_User_Interface/1440p_Series/Wizard/Wizard_05.webp'
import Wizard06 from '../../../../../images/Web_User_Interface/1440p_Series/Wizard/Wizard_06.webp'
import Wizard07 from '../../../../../images/Web_User_Interface/1440p_Series/Wizard/Wizard_07.webp'
import Wizard08 from '../../../../../images/Web_User_Interface/1440p_Series/Wizard/Wizard_08.webp'
import Wizard09 from '../../../../../images/Web_User_Interface/1440p_Series/Wizard/Wizard_09.webp'

const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen }) => {
  return (
    <div>
      <AutoRotatingCarousel
        label="Return"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        mobile
        autoplay={true}
        style={{ position: "absolute" }}
      >
        <Slide
          media={
            <img alt="INSTAR WQHD 2k+ IP Camera Installation Wizard" src={Wizard01} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Installation Wizard"
          subtitle="When the camera is started for the first time, it runs a wizard that guides you through the initial installation. You can also start this wizard manually again in the web interface under Features / Wizard."
        />
        <Slide
          media={
            <img alt="INSTAR WQHD 2k+ IP Camera Installation Wizard" src={Wizard02} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Login"
          subtitle="Enter a login for the administrator account here. This is the login with which you can subsequently log in to the web interface of the camera and make further settings."
        />
        <Slide
          media={
            <img alt="INSTAR WQHD 2k+ IP Camera Installation Wizard" src={Wizard03} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="IP Configuration"
          subtitle="If you do not configure the network interface of the camera and skip this step, the camera will obtain an available IP address from your router in the so-called DHCP mode. Enter a static IP here to always be able to reach the camera via the same address."
        />
        <Slide
          media={
            <img alt="INSTAR WQHD 2k+ IP Camera Installation Wizard" src={Wizard04} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#454649' }}
          style={{ backgroundColor: '#07d' }}
          title="WLAN Integration"
          subtitle="If you want to connect your camera to the WLAN, you can search for available WLANs here. When you have found your WLAN network, select it and enter your WLAN key. With the Test button you can check the connection and with Confirm you can save the data."
        />
        <Slide
          media={
            <img alt="INSTAR WQHD 2k+ IP Camera Installation Wizard" src={Wizard05} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Video Overlays"
          subtitle="Set the date, time and camera name here. And determine whether you want to see them as overlays in the camera's video image."
        />
        <Slide
          media={
            <img alt="INSTAR WQHD 2k+ IP Camera Installation Wizard" src={Wizard06} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="INSTAR Cloud"
          subtitle="Do you already have an INSTAR Cloud Account? Then you can enter your data here and add your new camera to your cloud account. The use of the INSTAR Cloud is purely optional and has no influence on the remaining functions of the camera."
        />
        <Slide
          media={
            <img alt="INSTAR WQHD 2k+ IP Camera Installation Wizard" src={Wizard07} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="InstarVision App"
          subtitle="INSTAR offer free apps for Windows (Metro&Phone), iPhone, iPad and Android. You can download and install them on your device here and add your camera by scanning the QR code."
        />
        <Slide
          media={
            <img alt="INSTAR WQHD 2k+ IP Camera Installation Wizard" src={Wizard08} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Motion Detection"
          subtitle="Your camera can detect motion by means of software-based motion detection (alarm areas) and by means of the heat sensor (PIR). Activate them here along with the alarm email and push notification - the latter only works in conjunction with the free InstarVision app installed in the last step."
        />
        <Slide
          media={
            <img alt="INSTAR WQHD 2k+ IP Camera Installation Wizard" src={Wizard09} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Conclusion"
          subtitle="After completing the installation wizard, the web interface (webUI) of the camera is loaded. There you can configure all functions of the camera. Also adjust the already made once again."
        />
      </AutoRotatingCarousel>
    </div>
  );
}

function SettingGallery() {
  const [handleOpen, setHandleOpen] = useState({ open: false });
  const handleClick = () => {
    setHandleOpen({ open: true });
  };
  return (
    <>
      <EuiButton fill onClick={handleClick}>
        Wizard
      </EuiButton>
      <AutoRotatingCarouselModal
        handleOpen={handleOpen}
        setHandleOpen={setHandleOpen}
      />
    </>
  );
}


export default SettingGallery