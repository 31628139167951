import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
};

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
);

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0,
    }}
    {...restProps}
  />
);

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ActionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "triggerColumn", title: "Trigger" },
        { name: "httpColumn", title: "HTTP Query" },
        {
          name: "mqttColumn",
          title: "MQTT Payload status/alarm/triggered/triggers",
        },
      ],
      rows: [
        {
          triggerColumn: "Alarm Area 1",
          httpColumn: "&trigger=1",
          mqttColumn: '{ "val" : "1" }',
        },
        {
          triggerColumn: "Alarm Area 2",
          httpColumn: "&trigger=2",
          mqttColumn: '{ "val" : "2" }',
        },
        {
          triggerColumn: "Alarm Area 3",
          httpColumn: "&trigger=4",
          mqttColumn: '{ "val" : "4" }',
        },
        {
          triggerColumn: "Alarm Area 4",
          httpColumn: "&trigger=8",
          mqttColumn: '{ "val" : "8" }',
        },
        {
          triggerColumn: "Alarm Area 5",
          httpColumn: "&trigger=256",
          mqttColumn: '{ "val" : "256" }',
        },
        {
          triggerColumn: "Alarm Area 6",
          httpColumn: "&trigger=512",
          mqttColumn: '{ "val" : "512" }',
        },
        {
          triggerColumn: "Alarm Area 7",
          httpColumn: "&trigger=1024",
          mqttColumn: '{ "val" : "1024" }',
        },
        {
          triggerColumn: "Alarm Area 8",
          httpColumn: "&trigger=2048",
          mqttColumn: '{ "val" : "2048" }',
        },
        {
          triggerColumn: "PIR Sensor",
          httpColumn: "&trigger=16",
          mqttColumn: '{ "val" : "16" }',
        },
        {
          triggerColumn: "Alarm Input",
          httpColumn: "&trigger=32",
          mqttColumn: '{ "val" : "32" }',
        },
        {
          triggerColumn: "Audio Detection",
          httpColumn: "&trigger=64",
          mqttColumn: '{ "val" : "64" }',
        },
        {
          triggerColumn: "Manual trigger",
          httpColumn: "&trigger=128",
          mqttColumn: '{ "val" : "128" }',
        },
      ],
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{ display: "inline-block", maxWidth: "915px" }}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ActionTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActionTable);
