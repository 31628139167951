import React from 'react'

import {Link} from 'gatsby'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiSpacer
} from '@elastic/eui'

import ivImage01 from "../../../../../images/Software/Windows/InstarVision/Camera_List/Image068a.webp"
import ivImage02 from "../../../../../images/Software/Windows/InstarVision/Camera_List/Image068.webp"
import ivImage03 from "../../../../../images/Software/Windows/InstarVision/Camera_List/Image069.webp"
import ivImage04 from "../../../../../images/Software/Windows/InstarVision/Camera_List/Image071.webp"
import ivImage05 from "../../../../../images/Software/Windows/InstarVision/Camera_List/Image070.webp"
import ivImage06 from "../../../../../images/Software/Windows/InstarVision/Camera_List/Image075.webp"
import ivImage07 from "../../../../../images/Software/Windows/InstarVision/Camera_List/Image074.webp"


export default function ImageCard() {

  return (
    <React.Fragment>
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage01}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>IR LEDs:</strong> Switch your camera´s nightvision LED´s on and off. The "On" function refers to the automatic mode, in which the LEDs are automatically switched on when it is getting dark.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage02}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Maximize Viewport:</strong> This button maximizes viewport for the selected camera or restores the video to normal. The function is can also be triggered by double-clicking the video area with left mouse.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage03}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Microphone:</strong> This button activates the microphone on your PC/Laptop. Use this function to send an audio stream to your camera´s internal or external loudspeaker - if available.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage04}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Audio:</strong> The audio button enables or disables this channels audio stream. When activated, the audio is of the channel is played when the cameras viewport is selected.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage05}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Snapshot:</strong> The button takes a snapshot of the current video stream and saves it as a JPG file. The file is placed in the current record directory inside the "Snapshot" folder.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage06}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Recording:</strong> This button starts or stops a manual recording of the camera´s video stream. The colours green, blue and red display the current recording status of this channel.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      <EuiText>
        <p>
          Once the recording function is started, this icon will flash every second. The colour green signals a manual recording, which can be stopped by clicking the icon again. The blue stands for a scheduled recording that can be configured in the recording menu under <Link to="/Software/Windows/InstarVision/Record/Continuous_Recording/">Permanent Recording</Link>. A red symbol means that the motion detection for this channel is active and triggered an alarm recording. The Alarm recording is configured in the Recording menu under Alarm.
        </p>
      </EuiText>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage07}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Disconnect:</strong> This button stops or starts the video stream of the selected camera. Use this function to deactivate streams and reduce network traffic.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  );
}
