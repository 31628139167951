import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link  to="/Web_User_Interface/720p_Series/System/Info/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_System_Info.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Info"
              description="Info is simply a collection of a lot of system internals that are interesting if you have to start troubleshooting your set up. You DDNS access doesn´t work?"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/System/Info/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link  to="/Web_User_Interface/720p_Series/System/Date_Time/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_System_Date_Time.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              } 
              title="Date & Time"
              description="Your camera will automatically contact a so-called NTP Server to set the internal clock. It is recommended to leave this feature on at all times..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/System/Date_Time/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link  to="/Web_User_Interface/720p_Series/System/User/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_System_User.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="User Management"
              description="Your camera will be set up with 3 user classes - administrator, user and visitor - and one password for all of them - instar. Please change this as soon..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/System/User/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link  to="/Web_User_Interface/720p_Series/System/Status_LED/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_System_Status_LED.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Status LED"
              description="Did you notice the blue and blinking LED on the camera? It can be very annoying in a dark room. But it can be used to troubleshoot your network connection..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/System/Status_LED/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link  to="/Web_User_Interface/720p_Series/System/IR_LEDs/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_System_IR_LEDs.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IR LEDs"
              description="Your camera´s IR LEDs are switched on and off automatically by the internal light sensor. If you want to use your camera behind a highly reflective window..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/System/IR_LEDs/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/720p_Series/System/ONVIF/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_System_ONVIF.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="ONVIF"
              description="The surveillance industry has created an open standard protocol called ONVIF that allows cameras to communicate with each other and with network recording devices..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/System/ONVIF/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link  to="/Web_User_Interface/720p_Series/System/PTZ/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_System_PTZ.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Pan & Tilt"
              description="Deactivate your camera´s calibration run at start-up (will also deactivate the saved positions). Let your camera go to a saved position after a restart or limit..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/System/PTZ/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link  to="/Web_User_Interface/720p_Series/System/PTZ_Tour/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_System_PTZ_Tour.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Pan & Tilt Tour"
              description="Deactivate your camera´s calibration run at start-up (will also deactivate the saved positions). Let your camera go to a saved position after a restart or limit..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/System/PTZ_Tour/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link  to="/Web_User_Interface/720p_Series/System/System_Log/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_System_Log.webp'
                    alt="System Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="System Log"
              description="Something doesn´t work anymore? Check the system log file for information. You don´t receive Alarm Emails anymore but your SMTP settings look fine?"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/System/System_Log/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
