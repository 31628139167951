import React from 'react'

import TableRow from './TableRow'

export default function CompareTable(props) {

    let names = []
    for(let i = 0; i < props.compareItems.length; i++){
        names.push(<th className="table-item-names">{props.compareItems[i].name}</th>)
    }
    console.log(names)
    return names.length >= 2 ? (
        <table className="compare-table">
            <thead className="thread">
                <tr>
                    <th className="table-item-names"></th>
                    {names.length >= 2 ? names: null}
                </tr>
            </thead>
            <tbody>

                <tr style={{backgroundColor: "#2f78f9"}}>
                    <th scope="rowgroup" colSpan="7" style={{color: "white"}}>Optics and Image</th>
                </tr>
                <TableRow {...props} name="Standard Lense"/>
                <TableRow {...props} name="CMOS Sensor"/>
                <TableRow {...props} name="MegaPixel"/>
                <TableRow {...props} name="Light Sensitivity"/>
                <TableRow {...props} name="Angle of View"/>
                <TableRow {...props} name="optical Zoom"/>
                <TableRow {...props} name="Video Encoding"/>
                <TableRow {...props} name="Resolution"/>
                <TableRow {...props} name="Framerate"/>
                <TableRow {...props} name="Image"/>

                <tr style={{backgroundColor: "#2f78f9"}}>
                    <th scope="rowgroup" colSpan="7" style={{color: "white"}}>Network</th>
                </tr>
                <TableRow {...props} name="LAN"/>
                <TableRow {...props} name="WLAN/PoE"/>
                <TableRow {...props} name="WLAN Frequency Bands"/>
                <TableRow {...props} name="WLAN Range"/>
                <TableRow {...props} name="WLAN Encryption"/>

                <tr style={{backgroundColor: "#2f78f9"}}>
                    <th scope="rowgroup" colSpan="7" style={{color: "white"}}>Nightvision</th>
                </tr>
                <TableRow {...props} name="Infrared LEDs"/>
                <TableRow {...props} name="IR Wavelength"/>
                <TableRow {...props} name="Nightvision"/>
                <TableRow {...props} name="IR Cut Filter"/>

                <tr style={{backgroundColor: "#2f78f9"}}>
                    <th scope="rowgroup" colSpan="7" style={{color: "white"}}>Features</th>
                </tr>
                <TableRow {...props} name="Motion Detection"/>
                <TableRow {...props} name="PIR Motion Detector"/>
                <TableRow {...props} name="Alarm In/Output"/>
                <TableRow {...props} name="Alarm by Email"/>
                <TableRow {...props} name="MQTT Broker/Client"/>
                <TableRow {...props} name="HTTPS Encryption"/>
                <TableRow {...props} name="DDNS Address"/>
                <TableRow {...props} name="Thirdparty DDNS"/>
                <TableRow {...props} name="Point2Point UID"/>
                <TableRow {...props} name="uPnP"/>
                <TableRow {...props} name="INSTAR Cloud"/>
                <TableRow {...props} name="FTPS Encryption"/>
                <TableRow {...props} name="Alarm by FTP Upload"/>
                <TableRow {...props} name="FTP Upload Interval"/>
                <TableRow {...props} name="Alarm Schedule"/>
                <TableRow {...props} name="Video Recording"/>
                <TableRow {...props} name="Alarm Pre-Recording"/>
                <TableRow {...props} name="integrated SD Card"/>
                <TableRow {...props} name="Snapshot Function"/>
                <TableRow {...props} name="Photoseries"/>
                <TableRow {...props} name="Multiview"/>
                {/* <TableRow {...props} name="ONVIF Protocol"/> */}
                <TableRow {...props} name="integrated Microphone"/>
                <TableRow {...props} name="integrated Loudspeaker"/>
                <TableRow {...props} name="Audio Input"/>
                <TableRow {...props} name="Audio Output"/>
                <TableRow {...props} name="Pan & Tilt Control"/>
                <TableRow {...props} name="Preset Positions"/>

                <tr style={{backgroundColor: "#2f78f9"}}>
                    <th scope="rowgroup" colSpan="7" style={{color: "white"}}>System</th>
                </tr>
                <TableRow {...props} name="User Rights Management"/>
                <TableRow {...props} name="Password Protection"/>
                <TableRow {...props} name="Operating Systems"/>
                <TableRow {...props} name="Mobile Systems"/>

                <tr style={{backgroundColor: "#2f78f9"}}>
                    <th scope="rowgroup" colSpan="7" style={{color: "white"}}>Details</th>
                </tr>
                <TableRow {...props} name="Installation"/>
                <TableRow {...props} name="Operating Temperatures"/>
                <TableRow {...props} name="Heater"/>
                <TableRow {...props} name="Power Connector"/>
                <TableRow {...props} name="Power Cable"/>
                <TableRow {...props} name="Power Supply"/>
                <TableRow {...props} name="Power Consumption"/>
                <TableRow {...props} name="Weight"/>
                <TableRow {...props} name="Measurements"/>
            </tbody>
        </table>
    ): null
}
