import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Updates:">
        <EuiText>
          <ul>
            <li><a href="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver/">Verwenden Sie den INSTAR MQTT Alarmserver mit Node-RED</a></li>
            <li><a href="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_Motion_Tracking/">Verwenden Sie den INSTAR MQTT Alarmserver zum Erstellen einer einfachen Objektverfolgung</a></li>
            <li><a href="/Frequently_Asked_Question/INSTAR_MQTT_Self_Signed_SSL_Certificate/">Selbstsigniertes SSL-Zertifikat für den INSTAR MQTT-Broker</a></li>
            <li><a href="/Frequently_Asked_Question/INSTAR_MQTT_Node-RED_Self-Signed_Certificate/">Selbstsigniertes SSL-Zertifikat für den INSTAR MQTT-Broker und Node-RED</a></li>
            <li><a href="/Frequently_Asked_Question/INSTAR_MQTT_CA_SSL_Certificate/">CA SSL-Zertifikat für den INSTAR MQTT-Broker</a></li>
          </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

