import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-gtag";
import { EuiButton, EuiSpacer } from "@elastic/eui";
import ChangelogSearch from "components/Search/IVAndroidclSearch";
import NavButtons from "components/Downloads/Mobile_Apps/NavButtons";
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
import * as React from 'react';
export default {
  Link,
  OutboundLink,
  EuiButton,
  EuiSpacer,
  ChangelogSearch,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};