import { Link } from 'gatsby';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Multimedia/Image/SuccessBox';
import ImageTable from 'components/Web_User_Interface/1080p_Series/Multimedia/Image/imageTable';
import ImageAdvancedTable from 'components/Web_User_Interface/1080p_Series/Multimedia/Image/imageAdvancedTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  EuiButton,
  SuccessBox,
  ImageTable,
  ImageAdvancedTable,
  BreadCrumbs,
  SEOHelmet,
  React
};