import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Video Einstellungen">
        <EuiText>
          <p>
          The video settings allow you to set the quality of all 3 video streams from your camera. Please note that high quality in the video settings also means high hardware and bandwidth requirements. Set up video settings to get smooth video. Please see our <Link to="/Outdoor_Cameras/IN-9408_WQHD/Video_Streaming/">RTSP Streaming Guide</Link> to access camera streams 2 and 3.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox


