import React from 'react'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import inPoESplitter from "../../../images/Quick_Installation/Power_over_Ethernet/IN-PoE_Splitter.webp"
import inPoE1000 from "../../../images/Quick_Installation/Power_over_Ethernet/IN-PoE_1000_web.webp"
import inPoE4000 from "../../../images/Quick_Installation/Power_over_Ethernet/IN-PoE_4000_web.webp"
import inPoE8000 from "../../../images/Quick_Installation/Power_over_Ethernet/IN-PoE_8000_web.webp"

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <img
                  src={inPoESplitter}
                  alt="IN-PoE Splitter"
                />
              }
              title='IN-PoE Splitter'
              description={
                <p>Use a PoE Splitter to power your WiFi camera. Available for <a href="https://www.instar.de/accessories/poe-accessories/poe-splitter-5v.html" target="_blank" rel="noopener noreferrer">5V Camera Models</a> and <a href="https://www.instar.de/accessories/poe-accessories/poe-splitter-12v.html" target="_blank" rel="noopener noreferrer">12V Camera Models</a>.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <img
                  src={inPoE1000}
                  alt="IN-PoE 1000"
                />
              }
              title='IN-PoE 1000'
              description={
                <p>Our IN-PoE 1000 Power-over-Ethernet Injector powers a single PoE device and is available in <a href="http://www.instar.de/accessories/poe-accessories/in-poe1000-poe-injector-black.html" target="_blank" rel="noopener noreferrer">Black</a> and <a href="http://www.instar.de/accessories/poe-accessories/in-poe1000-poe-injector-white.html" target="_blank" rel="noopener noreferrer">White</a>.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <img
                  src={inPoE4000}
                  alt="IN-PoE 4000"
                />
              }
              title='IN-PoE 4000'
              description={
                <p>The <a href="http://www.instar.de/accessories/poe-accessories/in-poe-4000-poe-switch.html" target="_blank" rel="noopener noreferrer">IN-POE 4000</a> Power-over-Ethernet Switch for up to 4 INSTAR PoE Cameras.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <img
                  src={inPoE8000}
                  alt="IN-PoE 8000"
                />
              }
              title='IN-PoE 8000'
              description={
                <p>The <a href="http://www.instar.de/accessories/poe-accessories/in-poe-8000-poe-switch.html" target="_blank" rel="noopener noreferrer">IN-PoE 8000</a> Power-over-Ethernet Switch for up to 7 INSTAR PoE Cameras.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
