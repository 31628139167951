import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Android',
      href: '/en/Software/Android/'
    },
    {
      text: 'Smarthome',
      href: '/en/Software/Home_Automation/',
    },
    {
      text: 'iOS',
      href: '/en/Software/iOS/',
    },
    {
      text: 'Linux',
      href: '/en/Software/Linux/',
    },
    {
      text: 'macOS',
      href: '/en/Software/macOS/',
    },
    {
      text: 'Other Platforms',
      href: '/en/Software/Other_Platforms/',
    },
    {
      text: 'Windows',
      href: '/en/Software/Windows/',
    },
    {
      text: '◄ Software',
      href: '/en/Software/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Software Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}