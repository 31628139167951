import React, { Component, Fragment } from "react"
import {
  EuiListGroup,
  EuiListGroupItem
} from '@elastic/eui'

import {
  ReactiveBase,
  DataSearch,
  ReactiveList,
  ResultList,
} from "@appbaseio/reactivesearch"

import { _connectionString, _index_fw_changelog } from './ElasticParam'

const { ResultListWrapper } = ReactiveList

class ReactiveSearch extends Component {
  render() {
    return (
      <Fragment>
        <ReactiveBase
          url={_connectionString}
          app={_index_fw_changelog}
          theme={{
            colors: {
              primaryColor: "#bebebe",
              textColor: "#7e7e7e"
            },
          }}
        >
          <DataSearch
            componentId="q"
            placeholder="INSTAR Search Assistant"
            dataField={["version", "title.en", "changes.en", "series"]}
            fieldWeights={[8, 10, 5, 3]}
            searchOperators={false}
            autosuggest={true}
            highlight={true}
            fuzziness={0}
            debounce={100}
            URLParams={false}
            defaultValue="1440p"
          />
          
          <div style={{ display: 'flex', marginTop: 20 }}>
            <ReactiveList
              from={0}
              react={{
                and: [
                  "q"
                ],
              }}
              sortOptions={[
                {
                  label: "Version",
                  dataField: "version.keyword",
                  sortBy: "desc"
                },
                {
                  label: "Series",
                  dataField: "series.keyword",
                  sortBy: "desc"
                },
                {
                  label: "Best Match",
                  dataField: "_score",
                  sortBy: "desc"
                }
              ]}
              componentId="SearchResult"
              dataField="series.raw"
              stream={true}
              pagination={true}
              paginationAt="bottom"
              size={11}
              loader="Loading Results.."
              showResultStats={true}
              scrollOnChange={true}
            >

              {({ data }) => (
                <div style={{ marginLeft: 20, padding: 10, width: '65vw' }}>
                  <ResultListWrapper>
                    {data.map((item) => (
                      <div style={{ background: 'white', margin: '10px 0', boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)' }} key={item._id}>
                        <ResultList>
                            <ResultList.Image src={item.imagesquare} style={{ minWidth: 125 }} />
                            <ResultList.Content>
                              <h2
                                dangerouslySetInnerHTML={{
                                  __html: item.version,
                                }}
                              />
                              <ResultList.Description>
                                <h4 dangerouslySetInnerHTML={{
                                    __html: item.title.en
                                  }}
                                />
                              </ResultList.Description>
                            </ResultList.Content>
                          </ResultList>
                            <EuiListGroup flush={true} maxWidth={750}>

                              { item.changes.map((changes, index) => (
                                <div key={index}>

                                  <EuiListGroupItem
                                    id="Id__1"
                                    iconType="check"
                                    iconProps={{ color: 'default' }}
                                    label={ changes.en }
                                    wrapText
                                  />

                                </div>
                              ))}

                          </EuiListGroup>
                      </div>
                    ))}
                  </ResultListWrapper>
                </div>
              )}
            </ReactiveList>
          </div>
        </ReactiveBase>
      </Fragment>
    );
  }
}


export default ReactiveSearch
