import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Camera Commands',
      href: '/en/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Camera_Commands/',
    },
    {
      text: 'Cloud Commands',
      href: '/en/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/',
    },
    {
      text: 'Troubleshooting',
      href: '/en/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/Troubleshooting/',
    },
    {
      text: 'Add Cameras',
      href: '/en/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/How_to_add_your_camera/',
    },
    {
      text: '◄ Alexa Cloud Skills',
      href: '/en/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={6}
        aria-label="Alexa Cloud Skills"
      />
      <EuiSpacer />
    </Fragment>
  );
}