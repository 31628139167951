
import React, { useState } from 'react'
import {Link} from 'gatsby'

import {
  EuiCallOut,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiButton,
  EuiTitle,
  EuiText,
  EuiSpacer
} from '@elastic/eui'

import PowerIndoorCard from './power-indoor-card'
import AovCard from './aov-card'
import NightvisionCard from './nightvision-min-max-card'

const FlyOut = () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

  const closeFlyout = () => setIsFlyoutVisible(false);

  const showFlyout = () => setIsFlyoutVisible(true);

  let flyout;

  const callOut = (
    <EuiCallOut iconType="help">
      <p>
      This is an overview of the power consumption, night vision and diagonal / horizontal and vertical viewing angle of the standard lenses of all <Link to="/Indoor_Cameras/">INSTAR indoor IP cameras</Link>. For an overview of the <Link to="/Outdoor_Cameras/">outdoor cameras</Link> please follow the <Link to="/Outdoor_Cameras/">Link</Link>.
      </p>
    </EuiCallOut>
  );

  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={closeFlyout}
        size="l"
        aria-labelledby="flyoutWithBannerTitle">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="flyoutWithBannerTitle">Compare Indoor Cameras</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody banner={callOut}>
          <EuiText>
            <h4>Power Consumption</h4>
            <p>This is an overview of the maximum power your camera draws during the following operating modes: <strong>Boot</strong> <em>(minimum)</em>, <strong>Boot</strong> <em>(peak power)</em>, <strong>Boot</strong> <em>(with IR LEDs active)</em>, <strong>Camera Idle</strong>, <strong>Camera Idle</strong> <em>(with IR LEDs active)</em>, <strong>Camera Idle</strong> <em>(with IR LEDs & WLAN active)</em>, <strong>Camera idle</strong> <em>(with IR LEDs & camera pan (PTZ))</em>, <strong>Camera idle</strong> <em>(with camera pan (PTZ))</em>, <strong>Camera idle</strong> <em>(with video recording)</em>, <strong>Camera idle</strong> <em>(with video recording & IR LEDs active)</em>.</p>
          </EuiText>
          <EuiSpacer/>
          <PowerIndoorCard/>
          <EuiSpacer/>
          <EuiText>
            <h4>Standard lens Aperture angle</h4>
            <p>This is an overview of the diagonal, vertical and horizontal viewing angle that you cover with your INSTAR camera. Please note that at large aperture angles a fisheye effect occurs, which covers a somewhat larger area than the calculated values show here. If you adjust the fisheye correction in the image settings of your camera so that all lines in the image - which should be straight - are also straight, you will arrive at the angle of view given below.</p>
          </EuiText>
          <AovCard/>
          <EuiText>
            <h4>Infrared Nightvision</h4>
            <p>This is an overview of the maximum and minimum night vision range of your INSTAR IP camera. Please note that the more IR light is reflected back to the camera, the better the night vision. I.e. indoors you usually have better night vision than outdoors and green areas can diffuse a large part of the IR light and thus severely limit the night vision.</p>
          </EuiText>
          <NightvisionCard/>
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }
  return (
    <div>
      <EuiSpacer />
      <EuiButton onClick={showFlyout} color="text">Technical data</EuiButton>
      {flyout}
    </div>
  );
};

export default FlyOut;