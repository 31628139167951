import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ActionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Alarm Server" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Contact Alarm Server', descriptionColumn: 'Activate the Alarm Server Function' },
        { actionColumn: 'Cooldown Interval', descriptionColumn: 'How long should the server wait to re-notify you if the alarm persists (1s - 60s)' },
        { actionColumn: 'Listen to Trigger', descriptionColumn: 'Select what sensor can trigger the alarm server: Motion Detection Areas, Passive IR Sensor, Audio Detection, Areas + PIR (coupled).' },
        { actionColumn: 'Alarm Server Address', descriptionColumn: 'Type in the IP Address of your Alarm Server - e.g. your FHEM Server.' },
        { actionColumn: 'Server Port', descriptionColumn: 'Type in the TCP Port that your Alarm Server is listening on.' },
        { actionColumn: 'Server Path', descriptionColumn: 'Type in the URL that your Server expects to see.' },
        { actionColumn: 'Encryption', descriptionColumn: 'Activate HTTPS SSL encryption for the web request' },
        { actionColumn: 'POST Request', descriptionColumn: 'Change the default GET to a POST request' },
        { actionColumn: 'Parameter', descriptionColumn: 'You can add optional parameter to the URL.' },
        { actionColumn: 'Append Alarm Trigger', descriptionColumn: 'The alarm trigger (e.g. "motion detection area 1" or "audio detection") will be added to the HTTP request in form of a query. *' },
        { actionColumn: 'Authentication', descriptionColumn: 'Add your Server Login to the URL.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ActionTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ActionTable)