import React from 'react'
import { Link } from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="ioBroker">
        <EuiText>
         <h2>Alle ioBroker Tutorials</h2>
         <ul>
            <li><Link to="/Software/Linux/ioBroker/">ioBroker with Docker (Updated 08.04.2024)</Link></li>
            <li><Link to="/Advanced_User/IOBroker_on_Raspberry_Pi/">ioBroker on a Raspberry Pi (deprecated)</Link></li>
            <li><Link to="/Advanced_User/INSTAR_MQTT_Broker/ioBroker/">INSTAR MQTT - ioBroker</Link></li>
         </ul>
         
         <h2>All ioBroker FAQs</h2>
         <ul>
           <li><Link to="/Frequently_Asked_Question/ioBroker_INSTAR_WQHD_MQTTv5_CLient/">ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_as_Broker_INSTAR_WQHD_MQTT/">ioBroker as MQTT Broker for your WQHD Camera</Link></li>
           <li><Link to="/Frequently_Asked_Question/WQHD_with_ioBroker_MQTT/">ioBroker MQTT Alarmserver for your WQHD Camera</Link></li>
           <li><Link to="/Frequently_Asked_Question/WQHD_with_ioBroker_REST_API/">ioBroker REST API with your WQHD Camera</Link></li>
           <li><Link to="/Frequently_Asked_Question/WQHD_with_ioBroker/">Using ioBroker with your WQHD Camera</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_INSTAR_WQHD_MQTTv5_Camera_Control/">ioBroker v7 with your WQHD INSTAR MQTTv5 Broker</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_INSTAR_Camera_Vis_with_Node-RED/">Camera Vis with Node-RED</Link></li>
           <li><Link to="/Frequently_Asked_Question/Live_Video_and_ioBroker_VIS/">Live Video and ioBroker VIS</Link></li>
           <li><Link to="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_ioBroker/">INSTAR MQTT Alarmserver ioBroker</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_and_INSTAR_Alarmserver/">ioBroker and INSTAR Alarmserver</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_MQTT_Broker_vs_Client/">ioBroker MQTT Broker vs Client</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_as_MQTT_Broker_for_your_Camera/">ioBroker as MQTT Broker for your Kamera</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_MQTT_Overwrites_Camera_Settings/">ioBroker MQTT Overwrites Camera Settings</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_and_MotionEye_Webhook/">ioBroker and MotionEye Webhooks</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_without_MQTT/">ioBroker w/o MQTT</Link></li>
         </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox