import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import VersionTable from 'components/Software/Windows/InstarVision/About/versionTable';
import NavButtons from 'components/Software/Windows/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  VersionTable,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};