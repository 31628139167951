import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="SD Card">
        <EuiText>
          <p>
            Please click the corresponding button to either format or unmount your SD card. It is recommended to always unmount your SD card before removing it from your camera! Your SD card might be unavailable after an unexpected restart. Please wait for the operating system to check the file system for errors. The SD card will show up again once it was successfully mounted by the system.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

