import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-gtag";
import { EuiSpacer } from "@elastic/eui";
import CompanyCards from "components/Outdoor_Cameras/IN-9408_WQHD/Warranty/CompanyCards";
import WeeSymbol from "components/Outdoor_Cameras/IN-9408_WQHD/Warranty/faq01Image";
import NavButtons from "components/Outdoor_Cameras/IN-9408_WQHD/NavButtons";
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
import * as React from 'react';
export default {
  Link,
  OutboundLink,
  EuiSpacer,
  CompanyCards,
  WeeSymbol,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};