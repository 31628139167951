import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ConfigTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "parameterColumn", title: "Parameter" },
        { name: "valueColumn", title: "Value" }
      ],
      rows: [
        { parameterColumn: 'Chanel', valueColumn: '1' },
        { parameterColumn: 'Bitrate', valueColumn: '1024' },
        { parameterColumn: 'Frames', valueColumn: '25' },
        { parameterColumn: 'Variable Bitrate', valueColumn: 'Yes' },
        { parameterColumn: 'Quality', valueColumn: ' ' },
        { parameterColumn: 'Keyframe', valueColumn: '40' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ConfigTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ConfigTable)


// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'

// const parameterRow = [
//   'Chanel',
//   'Bitrate',
//   'Frames',
//   'Variable Bitrate',
//   'Quality',
//   'Keyframe'
// ]



// const valueRow = [
//   '1',
//   '1024',
//   '25',
//   'Yes',
//   ' ',
//   '40',
// ]



// // Render your table
// const ConfigTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={parameterRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Parameter</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {parameterRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={425}
//         />
//         <Column
//           header={<Cell>Value</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {valueRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={420}
//         />
//       </Table>
//     )
// }

// export default ConfigTable