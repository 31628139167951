import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Indoor_Cameras/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Indoor_Cameras.webp'
                    alt="Indoor Cameras"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Innenkameras"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Indoor_Cameras/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Outdoor_Cameras/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Outdoor_Cameras.webp'
                    alt="Outdoor Cameras"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Aussenkameras"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Outdoor_Cameras/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Products/Usermanuals/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Products.webp'
                    alt="Usermanuals"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Gebrauchsanleitungen"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Products/Usermanuals/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Products.webp'
                    alt="Software"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Kamera Software"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/">
                  <StaticImage
                    src='../../images/Search/QI_SearchThumb_WebUI_1080p.webp'
                    alt="Web User Interface"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Web User Interface"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Downloads/">
                  <StaticImage
                    src='../../images/Search/D_Downloads_SearchThumb.webp'
                    alt="Downloads"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Downloads"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Downloads/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Products/Lenses/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Products.webp'
                    alt="Lenses"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Kamera Objektive"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Products/Lenses/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Products/USB-Webcams/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-W1.webp'
                    alt="IN-Wx USB Webcams"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-Wx USB Webcams"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Products/USB-Webcams/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Products/Others/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_Products.webp'
                    alt="Others Products"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Andere Produkte"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Products/Others/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}