import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Network/IP_Configuration/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Network_IP_Configuration.webp'
                    alt="INSTAR 1440p Web User Interface Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IP Configuration"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Series/Network/IP_Configuration/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Network/SSL_Certificate/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Network_IP_Configuration.webp'
                    alt="INSTAR 1440p Web User Interface Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="SSL Certificate"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Series/Network/SSL_Certificate/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Network/CA_Certificate/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Network_IP_Configuration.webp'
                    alt="INSTAR 1440p Web User Interface Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="CA Certificate"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Series/Network/CA_Certificate/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Network/WiFi/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Network_WiFi.webp'
                    alt="INSTAR 1440p Web User Interface Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="WiFi"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Series/Network/WiFi/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Network/Remote_Access/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Network_Remote_Access.webp'
                    alt="INSTAR 1440p Web User Interface Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Remote Access"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Series/Network/Remote_Access/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Network/ONVIF/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Network_ONVIF.webp'
                    alt="INSTAR 1440p Web User Interface Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="ONVIF Service"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Series/Network/ONVIF/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Network/RTSP/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Network_IP_Configuration.webp'
                    alt="INSTAR 1440p Web User Interface Network Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="RTSP"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1440p_Series/Network/RTSP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
