import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Deactivate your Camera´s Status LEDs">
        <EuiText>
          <p>
            Did you notice the blue and blinking LED on the camera? It can be very annoying in a dark room. But it can be used to troubleshoot your network connection or to make people aware that they are being recorded. For all other purposes come here to deactivate it.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

