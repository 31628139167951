import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiCard,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import lenseSearchIcon from '../../../../../images/Products/Lenses/9008-lense-box2.webp'


export default function LenseArticles() {

  return (
    <React.Fragment>
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <OutboundLink href="https://www.instar.com/Products/accessories/lenses/8mm-lense-for-in-9008-full-hd.html">
                <img
                  src={lenseSearchIcon}
                  alt="8mm Lense for IN-9008 Full HD"
                />
              </OutboundLink>
            }
            title="8mm Lense"
            footer={
              <EuiText>
                <strong>8mm Lense for IN-9008 Full HD</strong>
                <p>By simple changing the lense you will get an angle of view of about 45°.</p>
              </EuiText>
            }
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <OutboundLink href="https://www.instar.com/Products/accessories/lenses/16mm-lense-for-in-9008-full-hd.html">
                <img
                  src={lenseSearchIcon}
                  alt="16mm Lense for IN-9008 Full HD"
                />
              </OutboundLink>
            }
            title="16mm Lense"
            footer={
              <EuiText>
                <strong>16mm Lense for IN-9008 Full HD</strong>
                <p>Changing the lense, you will get an angle of view of about 25°.</p>
              </EuiText>
            }
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  );
}
