import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_IN-5905_01.webp"
import qiSelect02 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_IN-5905_02.webp"
import qiSelect03 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_03.webp"
import qiSelect04 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_IN-5905_04.webp"
import qiSelect05 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_IN-5905_05.webp"
import qiSelect06 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_06.webp"
import qiSelect07 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_07.webp"
import qiSelect08 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_08.webp"
import qiSelect09 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_09.webp"
import qiSelect10 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_12.webp"
import qiSelect11 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_13.webp"
import qiSelect12 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Quick_Installation/Quickinstallation_14.webp"



export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect01}
                    alt="Connect the WiFi antenna with the SMA connector of your camera."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Connect the WiFi antenna with the SMA connector of your camera.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect02}
                    alt="Plug in the power adapter connect it with the power connector on your camera´s cable tree."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Plug in the power adapter connect it with the power connector on your camera´s cable tree.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect03}
                    alt="It is recommended to set your routers WiFi channel to an unoccupied number, and set the encryption to WPA2/AES (or CCMP)."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    It is recommended to set your routers WiFi channel to an unoccupied number, and set the encryption to WPA2/AES (or CCMP).
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect04}
                    alt="A Press the WPS button (s. above) on your router. B Press the camera´s reset button for 3s. Be aware that holding it for longer than 10s will result in a camera reset. C The camera will now automatically connect to your WiFi network. Please skip to step 7 (for PC or Mac) or 14 (for smartphone)."
                  />
              }
              footer={
                <EuiText>
                    <ul>
                      <li>A Press the WPS button (s. above) on your router.</li>
                      <li>B Press the camera´s reset button for 3s (image on the left). Be aware that holding it for longer than 10s will result in a camera reset.</li>
                      <li>C The camera will now automatically connect to your WiFi network. Please skip to step 7 (for PC or Mac) or 14 (for smartphone).</li>
                    </ul>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect05}
                    alt="To establish a LAN connection, plug in the Ethernet cable to the RJ-45 on your cameras cable tree."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    To establish a LAN connection, plug in the Ethernet cable to the RJ-45 on your cameras cable tree.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect06}
                    alt="Plug the other end of the Ethernet cable into a free LAN port of your router or switch."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Plug the other end of the Ethernet cable into a free LAN port of your router or switch.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect07}
                    alt="Start our IP Camera Tool from the Software CD and double click on the name of the camera."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Start our IP Camera Tool from the Software CD and double click on the name of the camera.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect08}
                    alt="Default Logins User/Password: admin / instar for the administrator, user / instar for normal users and guest / instar for restricted users. Our INSTALLATION WIZARD will lead you through the rest of the installation process."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Default Logins User/Password: admin / instar for the administrator, user / instar for normal users and guest / instar for restricted users. Our INSTALLATION WIZARD will lead you through the rest of the installation process.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect09}
                    alt="Activate the WiFi card of your camera inside the webUI and start searching for WiFi signals. Select your WiFi, add your key and safe the settings. Restart your camera and unplug the LAN cable."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Activate the WiFi card of your camera inside the webUI and start searching for WiFi signals. Select your WiFi, add your key and safe the settings. Restart your camera and unplug the LAN cable.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect10}
                    alt="To access your camera over the internet, you have to forward the HTTP Port inside your router (default “80”) To access the Adobe Flash Plugin inside the WebUI, you also have to forward the RTMP Port (Standard “1935”)."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    To access your camera over the internet, you have to forward the HTTP Port inside your router (default “80”) To access the Adobe Flash Plugin inside the WebUI, you also have to forward the RTMP Port (Standard “1935”).
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect11}
                    alt="Now you can enter your camera´s DDNS information to our free Windows Phone, Windows Metro, Android, iPhone, iPad or Blackberry App."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Now you can enter your camera´s DDNS information to our free Windows Phone, Windows Metro, Android, iPhone, iPad or Blackberry App.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect12}
                    alt="If you cannot open a port for camera´s DDNS service, you can still use the Point-2-Point Service instead with our Windows Phone, Windows Metro, Android, iPhone, iPad or Blackberry App."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    If you cannot open a port for camera´s DDNS service, you can still use the Point-2-Point Service instead with our Windows Phone, Windows Metro, Android, iPhone, iPad or Blackberry App.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
