import React from 'react'

import {Link} from 'gatsby'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiSpacer
} from '@elastic/eui'

import ivImage01 from "../../../../../images/Software/Windows/InstarVision/Webserver/Image039_ActiveX.webp"
import ivImage02 from "../../../../../images/Software/Windows/InstarVision/Webserver/Image039_WMP.webp"
import ivImage03 from "../../../../../images/Software/Windows/InstarVision/Webserver/Image039_QT.webp"
import ivImage04 from "../../../../../images/Software/Windows/InstarVision/Webserver/Image039_Flash.webp"
import ivImage05 from "../../../../../images/Software/Windows/InstarVision/Webserver/Image039_JPG.webp"


export default function ImageCard() {

  return (
    <React.Fragment>
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage01}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>ActiveX:</strong> The ActiveX viewer mode can display up to 32 video channels at once on your webpage - please click on Setup to configure the plugin to your needs. Your web site user is required to install the ActiveX plugin, when he enters the web site for the first time. The ActiveX plugin is only supported by Microsoft´s Internet Explorer. If you want to use Microsoft Edge, Firefox, Safari or Chrome, please select a different plugin - or go plugin-free using the Simple JPEG mode.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage02}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Windows Player:</strong> This view mode will use Windows Media Player to display your camera´s video - that means, it will only work under Microsoft Windows with the Windows Media Player installed! Also the recording video file format of the Surveillance Center has to be set to ASF or WMV for the channel that you want to broadcast. To change the file format, go to the <Link to="/Software/Windows/InstarVision/Record/General/">General Recording Settings</Link>, select the desired channel and choose the format from the drop down menu.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage03}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>QuickTime:</strong> This view mode will use the Quicktime Player web plugin to display your camera´s video. Please install and setup the <Link to="/Quick_Installation/Live_Video/QuickTime/">Quicktime Player</Link> on your PC or MAC to be able to receive a video stream from an IP camera (set the streaming protocol to HTTP/Port 80). Also the recording video file format of the Surveillance Center has to be set to MP4 or MOV for the channel that you want to broadcast. To change the file format, go to the <Link to="/Software/Windows/InstarVision/Record/General/">General Recording Settings</Link>, select the desired channel and choose the format from the drop down menu.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage04}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Flash:</strong> This viewer will use Flash player to display your camera's video stream. You must install the <a href="https://get.adobe.com/flashplayer/" target="_blank" rel="nofollow" rel="noreferrer">Adobe Flash plugin</a> in your browser. Also the recording video file format of the Surveillance Center has to be set to FLV for the channel that you want to broadcast. To change the file format, go to the <Link to="/Software/Windows/InstarVision/Record/General/">General Recording Settings</Link>, select the desired channel and choose the format from the drop down menu.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision v2 for Windows"
            src={ivImage05}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>JPG Mode:</strong> This viewer will simply get single JPEG frames from your camera´s live stream and display them in a continuous fashion to create a video. Due to the nature of this process, there will be no audio signal from your camera. But <strong>you won´t need any Plugins to display the video!</strong>
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  );
}
