import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import menuBarAudio from "../../../../images/Web_User_Interface/720p_Series/Video_Menu_Bar/WebUI-MenuVideoBar_Audio.webp"
import menuBarMic from "../../../../images/Web_User_Interface/720p_Series/Video_Menu_Bar/WebUI-MenuVideoBar_Microphone.webp"
import menuBarZoom from "../../../../images/Web_User_Interface/720p_Series/Video_Menu_Bar/WebUI-MenuVideoBar_Zoom.webp"
import menuBarRec from "../../../../images/Web_User_Interface/720p_Series/Video_Menu_Bar/WebUI-MenuVideoBar_RecPath.webp"

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={menuBarAudio}
                    alt="Camera Microphone"
                  />
              }
              title='Camera Microphone'
              description={
                <EuiText>
                    <p>
                      Click here to activate the cameras audio stream. Adjust the volume in the <Link to="/Web_User_Interface/720p_Series/Multimedia/Audio/">Audio Menu</Link>.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={menuBarMic}
                    alt="Camera Loudspeaker"
                  />
              }
              title='Camera Loudspeaker'
              footer={
                <EuiText>
                    <p>
                      In case your PC is equipped with a microphone click here to activate the audio-out of your camera.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={menuBarZoom}
                    alt="Digital Zoom"
                  />
              }
              title='Digital Zoom'
              footer={
                <p>
                  Click here to adjust the cameras 3 x digital zoom - use the 3rd button to bring it back to normal.
                </p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={menuBarRec}
                    alt="Record Path"
                  />
              }
              title='Record Path'
              footer={
                <p>
                  Here you can set the record path for your <Link to="/Web_User_Interface/720p_Series/Video_Menu_Bar/">Manually Recorded</Link> videos and snapshots. The set path is used by all your INSTAR HD Cameras!
                </p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
