import React from 'react'

import 'react-vis/dist/style.css'

import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
  Crosshair
} from 'react-vis'

const DATA = [
  [
    // Bitrate: 5120 kbps
    { x: 'Full HD', y: 0.612489916667 },
    { x: 'WXGA', y: 0.0508341666667 },
  ],
  [
    // Bitrate: 2560 kbps
    { x: 'Full HD', y: 0.509404344828 },
    { x: 'WXGA', y: 0.0586933333333 },
  ],
  [
    // Bitrate: 2240 kbps
    { x: 'Full HD', y: 0.33387168 },
    { x: 'WXGA', y: 0.0643104074074 },
  ],
  [
    // Bitrate: 1920 kbps
    { x: 'Full HD', y: 0.25368668 },
    { x: 'WXGA', y: 0.05089775 },
  ],
  [
    // Bitrate: 1600 kbps
    { x: 'Full HD', y: 0.208030964286 },
    { x: 'WXGA', y: 0.06215272 },
  ],
  [
    // Bitrate: 1280 kbps
    { x: 'Full HD', y: 0.177894625 },
    { x: 'WXGA', y: 0.0536692727273 },
  ],
  [
    // Bitrate: 640 kbps
    { x: 'Full HD', y: 0.0904092 },
    { x: 'WXGA', y: 0.0653323666667 },
  ],
  [
    // Bitrate: 320 kbps
    { x: 'Full HD', y: 0.051157 },
    { x: 'WXGA', y: 0.04796104 },
  ]
];

export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: []
    };
  }
  render() {
    return (
        <React.Fragment>

          <div className="chart">
            <FlexibleWidthXYPlot
              onMouseLeave={() => this.setState({ crosshairValues: [] })}
              height={300}
              xType="ordinal"
              animation={true}
              stackBy="y">
              
              <VerticalGridLines />
              <HorizontalGridLines />
              <XAxis />
              <YAxis tickFormat={v => `${v}`} />

              <Crosshair values={this.state.crosshairValues} />

              <VerticalBarSeries
                onNearestX={(value, { index }) =>
                  this.setState({ crosshairValues: DATA.map(d => d[index]) })}
                cluster="Bitrate: 5120 kbps"
                color="#FF0000"
                data={DATA[0]}
              />

              <VerticalBarSeries
                cluster="Bitrate: 2560 kbps"
                color="#FF6600"
                data={DATA[1]}
              />

              <VerticalBarSeries
                cluster="Bitrate: 2240 kbps"
                color="#FF9900"
                data={DATA[2]}
              />

              <VerticalBarSeries
                cluster="Bitrate: 1920 kbps"
                color="#FFFF00"
                data={DATA[3]}
              />

              <VerticalBarSeries
                cluster="Bitrate: 1600 kbps"
                color="#66FF00"
                data={DATA[4]}
              />

              <VerticalBarSeries
                cluster="Bitrate: 1280 kbps"
                color="#00FFCC"
                data={DATA[5]}
              />

              <VerticalBarSeries
                cluster="Bitrate: 640 kbps"
                color="#0099FF"
                data={DATA[6]}
              />

              <VerticalBarSeries
                cluster="Bitrate: 320 kbps"
                color="#0033FF"
                data={DATA[7]}
              />

            </FlexibleWidthXYPlot>
          </div>

          <div className="legend">
            <DiscreteColorLegend
              orientation="horizontal"
              items={[
                {
                  title: '[0] 5120 kbps',
                  color: '#FF0000'
                },
                {
                  title: '[1] 2560 kbps',
                  color: '#FF6600'
                },
                {
                  title: '[2] 2240 kbps',
                  color: '#FF9900'
                },
                {
                  title: '[3] 1920 kbps',
                  color: '#FFFF00'
                },
                {
                  title: '[4] 1600 kbps',
                  color: '#66FF00'
                },
                {
                  title: '[5] 1280 kbps',
                  color: '#00FFCC'
                },
                {
                  title: '[6] 640 kbps',
                  color: '#0099FF'
                },
                {
                  title: '[7] 320 kbps',
                  color: '#0033FF'
                }
              ]}
            />
          </div>

        </React.Fragment>
    );
  }
}