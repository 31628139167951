import React from 'react'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import fourMMSearchIcon from '../../../../../images/Products/Lenses/IN-9008_Lenses_4,3mm_auto_15m_tag_zoom.webp'
import eightMMSearchIcon from '../../../../../images/Products/Lenses/IN-9008_Lenses_8mm_auto_15m_tag_zoom.webp'
import sixteenMMSearchIcon from '../../../../../images/Products/Lenses/IN-9008_Lenses_16mm_auto_15m_tag_zoom.webp'


export default function LenseArticles() {

  return (
    <React.Fragment>
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={fourMMSearchIcon}
                alt="INSTAR Exchangeable Lenses for your IP Camera"
              />
            }
            title="4.2mm Objektiv"
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={eightMMSearchIcon}
                alt="INSTAR Exchangeable Lenses for your IP Camera"
              />
            }
            title="8mm Objektiv"
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={sixteenMMSearchIcon}
                alt="INSTAR Exchangeable Lenses for your IP Camera"
              />
            }
            title="16mm Objektiv"
          />
        </EuiFlexItem>
        <EuiFlexItem>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  );
}