import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import CardINFra01 from 'components/Products/IR_Lighting/inFraCard01.jsx';
import CardINFra02 from 'components/Products/IR_Lighting/inFraMenuCards01.jsx';
import LED903Table from 'components/Products/IR_Lighting/903LEDTable';
import LED905Table from 'components/Products/IR_Lighting/905LEDTable';
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  CardINFra01,
  CardINFra02,
  LED903Table,
  LED905Table,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};