import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/styles'
import {
  SortingState, SelectionState, FilteringState, GroupingState,
  IntegratedFiltering, IntegratedGrouping, IntegratedSelection,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table, TableHeaderRow, TableFilterRow, TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class TechSpecGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
        columns: [
          { name: "category", title: "Category" },
          { name: "feature", title: "Feature" },
          { name: "property", title: "Property" }
        ],
        rows: [
          { category: 'Optics & Imaging', feature: 'Standard Lense', property: '4.0 (Manual Focus) f/2' },
          { category: 'Optics & Imaging', feature: 'Viewing Angle', property: '78.1 ° dia.' },
          { category: 'Optics & Imaging', feature: 'Optical Zoom', property: 'No' },
          { category: 'Optics & Imaging', feature: 'Digital Zoom', property: '3 x (only ActiveX)' },
          { category: 'Optics & Imaging', feature: 'CMOS Sensor', property: 'OV10633 720p 1/3" CMOS Wide Dynamic Range [2]' },
          { category: 'Optics & Imaging', feature: 'Sensor Sensitivity', property: '3650 mV/lux*s' },
          { category: 'Optics & Imaging', feature: 'Signal/Noise', property: '115 dB' },
          { category: 'Optics & Imaging', feature: 'Dynamic Range', property: '39 dB' },
          { category: 'Optics & Imaging', feature: 'Active Pixel Area', property: '5510.4 x 3418.8 µm' },
          { category: 'Optics & Imaging', feature: 'Resolution', property: '1.0 Megapixel' },
          { category: 'Optics & Imaging', feature: 'Supported Resolutions', property: '1280 x 720, 640 x 352, 320 x 176' },
          { category: 'Optics & Imaging', feature: 'Illumination', property: '0.05 Lux @ f/2.0' },
          { category: 'Optics & Imaging', feature: 'Video Compression', property: 'h.264, MJPEG, JPG' },
          { category: 'Optics & Imaging', feature: 'h.264 Bitrate', property: '90 kbps - 6.144 kbps' },
          { category: 'Optics & Imaging', feature: 'Framerate', property: 'up to 25fps' },
          { category: 'Optics & Imaging', feature: '# of Parallel Streams', property: '3' },
          { category: 'Optics & Imaging', feature: 'Video Overlay', property: 'Date, Time, Camera Name' },
          { category: 'Optics & Imaging', feature: 'Video Parameter', property: 'Brightness, Contrast, Saturation' },
          { category: 'Optics & Imaging', feature: 'Day / Night Switch', property: 'Yes' },
          { category: 'Optics & Imaging', feature: 'White balance', property: 'Automatic' },
          { category: 'Optics & Imaging', feature: 'Electronic Shutter', property: 'Automatic' },
          { category: 'Optics & Imaging', feature: 'Flip / Mirror Video', property: 'Yes / Yes' },
          { category: 'NightVision', feature: 'Infrared LEDs', property: '5 Laser LED @ 850 nm' },
          { category: 'NightVision', feature: 'IR Modes', property: 'off/automatic' },
          { category: 'NightVision', feature: 'max. Range', property: '12-20 m' },
          { category: 'NightVision', feature: 'intgr. IRcut Filter', property: 'Yes (automatic)' },
          { category: 'Connection', feature: 'Network Interface', property: '10Base-T/100Base-TX Ethernet Port / WLAN IEEE 802.11b/g/n' },
          { category: 'Connection', feature: 'Network Protocols', property: 'TCP/IP, DHCP, PPPoE, ARP, FTP, SMTP, DNS, NTP, UPnP, HTTP, TCP, UDP' },
          { category: 'Connection', feature: 'Transfer Encryption', property: 'none' },
          { category: 'Connection', feature: 'WiFi Encryption', property: 'WEP (64/128bit), WPA/WPA2-PSK' },
          { category: 'Connection', feature: 'WiFi Frequency Band', property: '2.4GHz 802.11b/g/n' },
          { category: 'Connection', feature: 'WiFi Antenna', property: 'dBi WLAN SMA Antenna [2]' },
          { category: 'Connection', feature: 'WiFi Distance', property: 'approx. 8-12m' },
          { category: 'Connection', feature: 'Free DDNS Address', property: 'Yes' },
          { category: 'Connection', feature: 'Free P2P UID', property: 'Yes' },
          { category: 'Features', feature: 'Access Protection', property: 'Username/Password - 3 Level Authorization' },
          { category: 'Features', feature: 'Motion Detection', property: 'Yes (software Analysis)' },
          { category: 'Features', feature: 'Alarm I/O', property: 'Yes (potential-free alarm-out / max. 30VDC/125VAC @ 1A) [3]' },
          { category: 'Features', feature: 'Alarm Notifications', property: 'Email/FTP/HTTP (Alarmserver)/Push' },
          { category: 'Features', feature: 'Alarm Pre-Recording', property: 'Yes (3s)' },
          { category: 'Features', feature: 'Photoseries', property: 'Yes (to SD Card and FTP server)' },
          { category: 'Features', feature: 'SD Card', property: 'Removable microSDHC card up to 32GB (8GB included) [4]' },
          { category: 'Features', feature: 'Cloud Storage', property: 'Yes (optional)' },
          { category: 'Features', feature: 'Pan & Tilt', property: 'No' },
          { category: 'Features', feature: 'Audio', property: 'Audio-Out (Cinch)' },
          { category: 'Features', feature: 'Microphone', property: 'Audio-In for external Microphones (e.g. IN-MIKRO 380) [5]' },
          { category: 'Features', feature: 'Scheduled & Alarm Recording', property: 'Yes' },
          { category: 'Physical Details', feature: 'Casing', property: 'IP65 Aluminium (silver/black)' },
          { category: 'Physical Details', feature: 'Mount', property: 'Wall & Ceiling (optional) Mount' },
          { category: 'Physical Details', feature: 'Operating Temperature', property: '-5°C to 55°C' },
          { category: 'Physical Details', feature: 'Power Socket', property: '110V - 230V' },
          { category: 'Physical Details', feature: 'Network Cable', property: '1.5m Cat5e' },
          { category: 'Physical Details', feature: 'Power Supply', property: '110-240V ac @ 50-60Hz to 5V dc and 1500mA - cable length: 3m' },
          { category: 'Physical Details', feature: 'Power Plug', property: '2.1mm In / 5.5mm Out' },
          { category: 'Physical Details', feature: 'Power Consumption', property: 'max. 8 W' },
          { category: 'Physical Details', feature: 'Net Weight', property: '930g' },
          { category: 'Physical Details', feature: 'Height/Width/Depth', property: '8.7cm/8cm/26.3cm' },
          { category: 'Physical Details', feature: 'Package', property: '11cm/16.5cm/30cm' },
          { category: 'Physical Details', feature: 'Certification', property: 'CE, FCC, RoHS' },
        ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns} >

          <FilteringState />

          <SortingState
            defaultSorting={[
              { columnName: 'category', direction: 'asc' },
            ]}
          />
          <GroupingState
            defaultGrouping={[{ columnName: 'category' }]}
            defaultExpandedGroups={['Optics & Imaging']}
          />
          <SelectionState />

          <IntegratedFiltering />
          <IntegratedGrouping />
          <IntegratedSelection />
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
          <TableFilterRow />
          <TableGroupRow />
        </Grid>
      </Paper>
    );
  }
}

TechSpecGrid.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(TechSpecGrid)















































































