import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ConfigTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "parameterColumn", title: "Parameter" },
        { name: "valueColumn", title: "Description" }
      ],
      rows: [
        { parameterColumn: 'FTP Server', valueColumn: 'Please enter the address of your FTP server. In the example above we have used a AVM Fritzbox (Router) as a FTP server. This router has a USB Stick connected which provides the storage for the pictures. In our case we have to type in the IP address of the router. If you want to set up your own FTP server on your local computer, you will find a step by step manual here. You can also use a web address as the FTP Server. This can either be an online host service or a DynDns address which connects to a FTP server at another location.' },
        { parameterColumn: 'FTP Port', valueColumn: 'Please type in the FTP Servers port which is usually 21.' },
        { parameterColumn: 'FTP Username', valueColumn: 'Please fill in the username of your FTP account. The default username for the AVM Fritzbox is "ftpuser".' },
        { parameterColumn: 'FTP Password', valueColumn: 'Please fill in the password of your FTP account.' },
        { parameterColumn: 'FTP Upload Folder', valueColumn: 'Fill in the folder you want to upload the pictures inside. By default the folder will be "./" which means that the camera will save the uploads in the root folder of the server. In our above sample the pictures will be saved in the root folder.' },
        { parameterColumn: 'FTP Mode', valueColumn: 'Here you can choose between (PASV) and active Mode (PORT). The default setting is the mode PORT with which the camera will tell the server its IP and Port. Therefor this mode will also work with passive FTP servers. If the client is behind a router which will use NAT or if a firewall is blocking the clients network from accessing the network, then you should choose the PASV mode.' },
        { parameterColumn: 'Upload picture with time interval', valueColumn: 'Please activate only if you want your camera to upload a picture in a set time internal. This function is separate from the alarm notification.' },
        { parameterColumn: 'Image Name', valueColumn: 'Please select a name for the snapshot which will be saved and replaced every time the camera uploads a picture. This is needed if you want to integrate the picture on your website. This way you will always have the picture replaced with the newest one. If you leave this field blank, then the camera will safe a picture with the time and date included in the picture name which will look like this: CameraID(CameraName)_0_yyyymmddhhmmss_Nr.jpg.' },
        { parameterColumn: 'Interval (in seconds)', valueColumn: 'Select the time interval in which you want the camera to upload a picture to the server. The interval has to be set in seconds. The shortest time will be 1 second which means that every second the camera will upload on picture.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ConfigTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ConfigTable)


// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'

// const parameterRow = [
//   'FTP Server',
//   'FTP Port',
//   'FTP Username',
//   'FTP Password',
//   'FTP Upload Folder',
//   'FTP Mode',
//   'Upload picture with time interval',
//   'Image Name',
//   'Interval (in seconds)'
// ]



// const descriptionRow = [
//   'Please enter the address of your FTP server. In the example above we have used a AVM Fritzbox (Router) as a FTP server. This router has a USB Stick connected which provides the storage for the pictures. In our case we have to type in the IP address of the router. If you want to set up your own FTP server on your local computer, you will find a step by step manual here. You can also use a web address as the FTP Server. This can either be an online host service or a DynDns address which connects to a FTP server at another location.',
//   'Please type in the FTP Servers port which is usually 21.',
//   'Please fill in the username of your FTP account. The default username for the AVM Fritzbox is "ftpuser".',
//   'Please fill in the password of your FTP account.',
//   'Fill in the folder you want to upload the pictures inside. By default the folder will be "./" which means that the camera will save the uploads in the root folder of the server. In our above sample the pictures will be saved in the root folder.',
//   'Here you can choose between (PASV) and active Mode (PORT). The default setting is the mode PORT with which the camera will tell the server its IP and Port. Therefor this mode will also work with passive FTP servers. If the client is behind a router which will use NAT or if a firewall is blocking the clients network from accessing the network, then you should choose the PASV mode.',
//   'Please activate only if you want your camera to upload a picture in a set time internal. This function is separate from the alarm notification.',
//   'Please select a name for the snapshot which will be saved and replaced every time the camera uploads a picture. This is needed if you want to integrate the picture on your website. This way you will always have the picture replaced with the newest one. If you leave this field blank, then the camera will safe a picture with the time and date included in the picture name which will look like this: CameraID(CameraName)_0_yyyymmddhhmmss_Nr.jpg.',
//   'Select the time interval in which you want the camera to upload a picture to the server. The interval has to be set in seconds. The shortest time will be 1 second which means that every second the camera will upload on picture.',
// ]



// // Render your table
// const ConfigTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={parameterRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Parameter</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {parameterRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={150}
//         />
//         <Column
//           header={<Cell>Description</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {descriptionRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={3}
//           width={700}
//         />
//       </Table>
//     )
// }

// export default ConfigTable