import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/styles'
import {
  SortingState, SelectionState, FilteringState, GroupingState,
  IntegratedFiltering, IntegratedGrouping, IntegratedSelection,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table, TableHeaderRow, TableFilterRow, TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class CGIGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'category', title: 'Category' },
        { name: 'command', title: 'Command' }
      ],
      rows: [
        {
          category: 'Network', command: '/param.cgi?cmd=getnetattr', method: 'GET', menu: 'Network-DDNS'
        },
        { category: 'Network', command: '/param.cgi?cmd=setnetattr&-dhcp=off&-ipaddr=192.168.1.88&-netmask=255.255.255.0&-gateway=192.168.1.1&-dnsstat=0&-fdnsip=192.168.1.1&-sdnsip=', method: 'SET', menu: 'Network-DDNS' },
        { category: 'Network', command: '/param.cgi?cmd=gethttpport', method: 'GET', menu: 'Network-DDNS' },
        { category: 'Network', command: '/param.cgi?cmd=sethttpport&-httpport=80', method: 'SET', menu: 'Network-DDNS' },
        { category: 'Network', command: '/param.cgi?cmd=getrtspport', method: 'GET', menu: 'Network-DDNS' },
        { category: 'Network', command: '/param.cgi?cmd=setrtspport&-rtspport=554', method: 'SET', menu: 'Network-DDNS' },
        { category: 'Network', command: '/param.cgi?cmd=getinterip', method: 'GET', menu: 'Network-DDNS' },
        { category: 'Network', command: '/param.cgi?cmd=getupnpattr', method: 'GET', menu: 'Network-DDNS' },
        { category: 'Network', command: '/param.cgi?cmd=setupnpattr&-upm_enable=1', method: 'SET', menu: 'Network-DDNS' },
        { category: 'Network', command: '/param.cgi?cmd=getonvifattr', method: 'GET', menu: 'Network-DDNS' },
        { category: 'Network', command: '/param.cgi?cmd=setonvifattr&-ov_enable=1&-ov_port=8080&ov_authflag=0', method: 'SET', menu: 'Network-DDNS' },
        { category: 'Network', command: '/tmpfs/ifconfig.tmp', method: 'GET', menu: 'Network-DDNS' },
        { category: 'Network', command: '/tmpfs/tutkflag', method: 'GET', menu: 'Network-DDNS' },
        { category: 'Network', command: '/tmpfs/upnpmap.dat', method: 'GET', menu: 'Network-DDNS' },
        { category: 'Video and Audio', command: '/param.cgi?cmd=getvideoattr', method: 'GET', menu: 'Multimedia-Video' },
        { category: 'Video and Audio', command: '/param.cgi?cmd=setvideoattr&-vinorm=N', method: 'SET', menu: 'Multimedia-Video' },
        { category: 'Video and Audio', command: '/getvencattr.cgi?-chn=11', method: 'GET', menu: 'Multimedia-Video' },
        { category: 'Video and Audio', command: '/param.cgi?cmd=setvencattr&-chn=11&-bps=1536&-fps=25&-brmode=1&-imagegrade=1&-gop=50', method: 'SET', menu: 'Multimedia-Video' },
        { category: 'Video and Audio', command: '/param.cgi?cmd=getimageattr', method: 'GET', menu: 'Multimedia-Video' },
        { category: 'Video and Audio', command: '/param.cgi?cmd=setimageattr&-brightness=1&-saturation=1&-contrast=1&-hue=1&-flip=on&-mirror=on&-scene=indoor', method: 'SET', menu: 'Multimedia-Video' },
        { category: 'Video and Audio', command: '/param.cgi?cmd=getoverlayattr&-region=0', method: 'GET', menu: 'Multimedia-Video' },
        { category: 'Video and Audio', command: '/param.cgi?cmd=setoverlayattr&-region=1&-show=1&-name=IPCam', method: 'SET', menu: 'Multimedia-Video' },
        { category: 'Video and Audio', command: '/param.cgi?cmd=getmobilesnapattr', method: 'GET', menu: 'Multimedia-Video' },
        { category: 'Video and Audio', command: '/param.cgi?cmd=setmobilesnapattr&-msize=1', method: 'SET', menu: 'Multimedia-Video' },
        { category: 'Video and Audio', command: '/param.cgi?cmd=getaencattr&-chn=11', method: 'GET', menu: 'Multimedia-Video' },
        { category: 'Video and Audio', command: '/param.cgi?cmd=setaencattr&-chn=12&-aeswitch=1&-aeformat=g726', method: 'SET', menu: 'Multimedia-Video' },
        { category: 'Video and Audio', command: '/param.cgi?cmd=getaudioinvolume', method: 'GET', menu: 'Multimedia-Video' },
        { category: 'Video and Audio', command: '/param.cgi?cmd=setaudioinvolume&-volume=80', method: 'SET', menu: 'Multimedia-Video' },
        { category: 'Video and Audio', command: '/param.cgi?cmd=getaudiooutvolume', method: 'GET', menu: 'Multimedia-Video' },
        { category: 'Video and Audio', command: '/param.cgi?cmd=setaudiooutvolume&-volume=80', method: 'SET', menu: 'Multimedia-Video' },
        { category: 'Video and Audio', command: '/param.cgi?cmd=requestifrm&-chn=11', method: 'GET', menu: 'Multimedia-Video' },
        { category: 'WiFi', command: '/param.cgi?cmd=getwirelessattr', method: 'GET', menu: 'WiFi' },
        { category: 'WiFi', command: '/param.cgi?cmd=setwirelessattr&-wf_ssid=linksys&-wf_enable=1&-wf_auth=2&-wf_key=1234567890&-wf_enc=0&-wf_mode=0', method: 'SET', menu: 'WiFi' },
        { category: 'WiFi', command: '/param.cgi?cmd=searchwireless', method: 'GET', menu: 'WiFi' },
        { category: 'WiFi', command: '/param.cgi?cmd=chkwirelessattr&-wf_ssid=linksys&-wf_auth=3&-wf_key=1234567890&-wf_enc=0&-wf_mode=0', method: 'GET', menu: 'WiFi' },
        { category: 'WiFi', command: '/param.cgi?cmd=getchkwireless', method: 'GET', menu: 'WiFi' },
        { category: 'WiFi', command: '/tmpfs/wifi.mac', method: 'GET', menu: 'WiFi' },
        { category: 'DDNS', command: '/param.cgi?cmd=get3thddnsattr', method: 'GET', menu: 'Network-DDNS' },
        { category: 'DDNS', command: '/param.cgi?cmd=set3thddnsattr&-d3th_enable=1&-d3th_service=1&-d3th_uname=sample&-d3th_passwd=1234&-d3th_domain=sample.dyndns.org', method: 'SET', menu: 'Network-DDNS' },
        { category: 'DDNS', command: '/tmpfs/fddns.dat', method: 'GET', menu: 'Network-DDNS' },
        { category: 'DDNS', command: '/tmpfs/th3ddns.dat', method: 'GET', menu: 'Network-DDNS' },
        { category: 'PTZ Control', command: '/param.cgi?cmd=ptzctrl&-step=0&-act=left&-speed=45', method: 'SET', menu: 'PTZ' },
        { category: 'PTZ Control', command: '/param.cgi?cmd=preset&-act=goto&-number=1', method: 'SET', menu: 'PTZ' },
        { category: 'PTZ Control', command: '/param.cgi?cmd=preset&-act=set&-status=1&-number=1', method: 'SET', menu: 'PTZ' },
        { category: 'PTZ Control', command: '/ptzup.cgi', method: 'SET', menu: 'PTZ' },
        { category: 'PTZ Control', command: '/ptzdown.cgi', method: 'SET', menu: 'PTZ' },
        { category: 'PTZ Control', command: '/ptzleft.cgi', method: 'SET', menu: 'PTZ' },
        { category: 'PTZ Control', command: '/ptzright.cgi', method: 'SET', menu: 'PTZ' },
        { category: 'PTZ Control', command: '/ptzzoomin.cgi', method: 'SET', menu: 'PTZ' },
        { category: 'PTZ Control', command: '/ptzzoomout.cgi', method: 'SET', menu: 'PTZ' },
        { category: 'Motion Detection', command: '/param.cgi?cmd=getmdattr', method: 'GET', menu: 'Motion Detection' },
        { category: 'Motion Detection', command: '/param.cgi?cmd=setmdattr&-enable=1&-s=50&-name=1&-x=0&-y=0&-w=60&-h=60', method: 'SET', menu: 'Motion Detection' },
        { category: 'Motion Detection', command: '/param.cgi?cmd=getioattr', method: 'GET', menu: 'Motion Detection' },
        { category: 'Motion Detection', command: '/param.cgi?cmd=setioattr&-io_enable=0&-io_flag=1', method: 'SET', menu: 'Motion Detection' },
        { category: 'Motion Detection', command: '/param.cgi[?cmd=getmdalarm&-aname=]', method: 'GET', menu: 'Motion Detection' },
        { category: 'Motion Detection', command: '/param.cgi?cmd=setmdalarm&-aname=type&-switch=on&cmd=setmdalarm&-aname=emailsnap&-switch=on&cmd=setmdalarm&-aname=snap&-switch=on&cmd=setmdalarm&-aname=record&-switch=on&cmd=setmdalarm&-aname=ftprec&-switch=on&cmd=setmdalarm&-aname=relay&-switch=on&cmd=setmdalarm&-aname=ftpsnap&-switch=on', method: 'SET', menu: 'Motion Detection' },
        { category: 'Motion Detection', command: '/param.cgi?cmd=getrelayattr', method: 'GET', menu: 'Motion Detection' },
        { category: 'Motion Detection', command: '/param.cgi?cmd=setrelayattr&-time=10', method: 'SET', menu: 'Motion Detection' },
        { category: 'Motion Detection', command: '/param.cgi?cmd=getalarmsnapattr', method: 'GET', menu: 'Motion Detection' },
        { category: 'Motion Detection', command: '/param.cgi?cmd=setalarmsnapattr&-snap_count=2', method: 'SET', menu: 'Motion Detection' },
        { category: 'Motion Detection', command: '/param.cgi?cmd=getscheduleex&-ename=md', method: 'GET', menu: 'Motion Detection' },
        { category: 'Motion Detection', command: '/param.cgi?cmd=setscheduleex&-ename=md&-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week1=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week2=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week3=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week4=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week5=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week6=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&', method: 'SET', menu: 'Motion Detection' },
        { category: 'Motion Detection', command: '/param.cgi?cmd=getplanrecattr', method: 'GET', menu: 'Motion Detection' },
        { category: 'Motion Detection', command: '/param.cgi?cmd=setplanrecattr&-planrec_enable=1&-planrec_time=30&-planrec_chn=12', method: 'SET', menu: 'Motion Detection' },
        { category: 'Motion Detection', command: '/param.cgi?cmd=getalarmserverattr', method: 'GET', menu: 'Motion Detection' },
        { category: 'Motion Detection', command: '/param.cgi?cmd=setalarmserverattr&-as_server=alarm.net&-as_port=8990&-as_username=asdf&-as_password=1234', method: 'SET', menu: 'Motion Detection' },
        { category: 'User Management', command: '/param.cgi?cmd=getuserattr', method: 'GET', menu: 'System-User' },
        { category: 'User Management', command: '/param.cgi?cmd=setuserattr&-at_username=admin&-at_password=instar', method: 'SET', menu: 'System-User' },
        { category: 'User Management', command: '/param.cgi?cmd=getuserinfo', method: 'GET', menu: 'System-User' },
        { category: 'Snapshot', command: '/param.cgi?cmd=getsnaptimerattrex', method: 'GET', menu: 'Snapshot' },
        { category: 'Snapshot', command: '/param.cgi?cmd=setsnaptimerattrex&-as_enable=1&-as_interval=60&-as_type=snap', method: 'SET', menu: 'Snapshot' },
        { category: 'Snapshot', command: '/tmpfs/snap.jpg', method: 'GET', menu: 'Snapshot' },
        { category: 'Snapshot', command: '/tmpfs/auto.jpg', method: 'GET', menu: 'Snapshot' },
        { category: 'Snapshot', command: '/tmpfs/auto2.jpg', method: 'GET', menu: 'Snapshot' },
        { category: 'FTP', command: '/param.cgi?cmd=getftpattr', method: 'GET', menu: 'Network-FTP' },
        { category: 'FTP', command: '/param.cgi?cmd=setsmtpattr&-ma_server=stmp.example.com&-ma_from=123@example.com&-ma_to=456@example.com&-ma_subject=demosubject&-ma_text=demotext&-ma_logintype=1&-ma_username=demo&-ma_password=123456&-ma_port=25&-ma_ssl=0', method: 'SET', menu: 'Network-FTP' },
        { category: 'FTP', command: '/param.cgi?cmd=testftp', method: 'GET', menu: 'Network-FTP' },
        { category: 'SMTP', command: '/param.cgi?cmd=getsmtpattr', method: 'GET', menu: 'Alarm-SMTP' },
        { category: 'SMTP', command: '/param.cgi?cmd=setsmtpattr&-ma_server=stmp.example.com&-ma_from=123@example.com&-ma_to=456@example.com&-ma_subject=demosubject&-ma_text=demotext&-ma_logintype=1&-ma_username=demo&-ma_password=123456&-ma_port=25&-ma_ssl=0', method: 'SET', menu: 'Alarm-SMTP' },
        { category: 'SMTP', command: '/param.cgi?cmd=testsmtp', method: 'GET', menu: 'Alarm-SMTP' },
        { category: 'SD Card', command: '/param.cgi?cmd=sdfrmt', method: 'SET', menu: 'Alarm-SD Card' },
        { category: 'SD Card', command: '/param.cgi?cmd=sdstop', method: 'SET', menu: 'Alarm-SD Card' },
        { category: 'System Settings', command: '/param.cgi?cmd=getserverinfo', method: 'GET', menu: 'System-Info' },
        { category: 'System Settings', command: '/param.cgi?cmd=getdevtype', method: 'GET', menu: 'System-Info' },
        { category: 'System Settings', command: '/param.cgi?cmd=getstreamnum', method: 'GET', menu: 'System-Info' },
        { category: 'System Settings', command: '/param.cgi?cmd=getservertime', method: 'GET', menu: 'System-Info' },
        { category: 'System Settings', command: '/param.cgi?cmd=setservertime&-time=2011.08.23.10.35.08&-timezone=Asia%2FHong_Kong&-dstmode=off', method: 'SET', menu: 'System-Info' },
        { category: 'System Settings', command: '/param.cgi?cmd=getntpattr', method: 'GET', menu: 'System-Info' },
        { category: 'System Settings', command: '/param.cgi?cmd=setntpattr&-ntpenable=1&-ntpinterval=2&-ntpserver=time.nist.gov', method: 'SET', menu: 'System-Info' },
        { category: 'System Settings', command: '/param.cgi?cmd=getdevices', method: 'GET', menu: 'System-Info' },
        { category: 'System Settings', command: '/param.cgi?cmd=setdevices&-d_number=0&-d_alias=test1&-d_host=192.168.1.7&-d_port=80&-d_username=admin&-d_password=admin', method: 'SET', menu: 'System-Info' },
        { category: 'System Settings', command: '/mjpegstream.cgi?-chn=12', method: 'GET', menu: 'System-Info' },
        { category: 'System Settings', command: ' /param.cgi?cmd=snapimage&-chn=011', method: 'GET', menu: 'System-Info' },
        { category: 'System Settings', command: '/sysreset.cgi', method: 'GET', menu: 'System-Info' },
        { category: 'System Settings', command: '/sysreboot.cgi', method: 'GET', menu: 'System-Info' },
        { category: 'System Settings', command: '/backup.cgi', method: 'GET', menu: 'System-Info' },
        { category: 'System Settings', command: '/restore.cgi[?-setting_file=]', method: 'GET', menu: 'System-Info' },
        { category: 'System Settings', command: '/upgrade.cgi[?-filename=]', method: 'GET', menu: 'System-Info' },
        { category: 'System Settings', command: '/param.cgi?cmd=get_instar_admin', method: 'GET', menu: 'System-Info' },
        { category: 'System Settings', command: '/param.cgi?cmd=set_instar_admin&-index=11&-value=2&cmd=set_instar_admin&-index=50&-value=off', method: 'GET', menu: 'System-Info' },
        { category: 'System Log', command: '/param.cgi?cmd=logsync', method: 'SET', menu: 'System-Info' },
        { category: 'System Log', command: '/log/syslog.txt', method: 'SET', menu: 'System-Info' },
        { category: 'System Log', command: '/param.cgi?cmd=cleanlog&-name=sys', method: 'SET', menu: 'System-Info' },
        { category: 'IR Light Control', command: '/param.cgi?cmd=getinfrared', method: 'GET', menu: 'System-IR LEDs' },
        { category: 'IR Light Control', command: '/param.cgi?cmd=setinfrared&-infraredstat=auto', method: 'SET', menu: 'System-IR LEDs' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns} >

          <FilteringState />

          <SortingState
            defaultSorting={[
              { columnName: 'category', direction: 'asc' },
            ]}
          />
          <GroupingState
            defaultGrouping={[{ columnName: 'category' }]}
            defaultExpandedGroups={['Network']}
          />
          <SelectionState />

          <IntegratedFiltering />
          <IntegratedGrouping />
          <IntegratedSelection />
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
          <TableFilterRow />
          <TableGroupRow />
        </Grid>
      </Paper>
    );
  }
}

CGIGrid.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(CGIGrid)