import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import InstarVisionMenuCards01 from 'components/Software/Windows/InstarVision/Camera_List/instarVisionMenuCards01';
import InstarVisionMenuCards02 from 'components/Software/Windows/InstarVision/Camera_List/instarVisionMenuCards02';
import InstarVisionCard01 from 'components/Software/Windows/InstarVision/Camera_List/instarVisionCard01';
import InstarVisionCards01 from 'components/Software/Windows/InstarVision/Camera_List/instarVisionCards01';
import NavButtons from 'components/Software/Windows/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  InstarVisionMenuCards01,
  InstarVisionMenuCards02,
  InstarVisionCard01,
  InstarVisionCards01,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};