import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Info">
        <EuiText>
          <p>
            Info is simply a collection of a lot of system internals that are interesting if you have to start troubleshooting your set up. You DDNS access doesn´t work? Check the DDNS status (has to be successful) and the UPnP Status (most likely has to be deactivated). You can also try accessing your camera via it´s WAN IP (instead of DDNS address) - to check whether it is the DDNS address or your port forwarding that is the culprit.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

