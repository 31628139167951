import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'IN-Route P52',
      href: '/en/Downloads/Network_Accessories/IN-Route_P52/',
    },
    {
      text: 'IN-PoE 1000',
      href: '/en/Downloads/Network_Accessories/IN-PoE_1000/',
    },
    {
      text: 'IN-LAN 500',
      href: '/en/Downloads/Network_Accessories/IN-LAN_500/',
    },
    {
      text: 'Network Accessories',
      href: '/en/Downloads/Network_Accessories/',
    },
    {
      text: '◄ Downloads',
      href: '/en/Downloads/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Downloadbereich for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}