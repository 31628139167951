import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ConfigTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "sensorColumn", title: "Sensor Model" },
        { name: "modelColumn", title: "IN-Motion 300 PIR Motion Detector" }
      ],
      rows: [
        { sensorColumn: 'Operating Voltage', modelColumn: 'D.C. 9V - 16V' },
        { sensorColumn: 'Current Consumption', modelColumn: '18mA @ DC12V' },
        { sensorColumn: 'Detection Distance', modelColumn: 'Diameter - 8m (at a height of 3.6m)' },
        { sensorColumn: 'Detection Angle', modelColumn: '360 ° @ an opening angle of ~ 90°' },
        { sensorColumn: 'Start-up Time', modelColumn: '60 seconds (warm-up time up to 10 minutes in temperatures below -10°C !)' },
        { sensorColumn: 'RF Interference Compensation', modelColumn: '10MHz 1GHz 20V/m' },
        { sensorColumn: 'Alarm Indicator', modelColumn: 'Red LED' },
        { sensorColumn: 'Alarm Output', modelColumn: 'N.C. or N.O., DC 28V/100mA' },
        { sensorColumn: 'Tamper Output', modelColumn: 'N.C., DC 28V / 100mA' },
        { sensorColumn: 'Sensor', modelColumn: 'Dual element pyroelectric sensor' },
        { sensorColumn: 'Environment Humidity', modelColumn: '95% RH (no congelation)' },
        { sensorColumn: 'Working Temperature', modelColumn: '-10°C to +50°C' },
        { sensorColumn: 'Installation Mode', modelColumn: 'Ceiling- or Wallmount' },
        { sensorColumn: 'Installation Height', modelColumn: '2.5m to 6m' },
        { sensorColumn: 'Size', modelColumn: '79 mm diameter / 23mm depth' },
        { sensorColumn: 'Compatible with', modelColumn: 'IN-2905 V2, IN-2907, IN-2908, IN-3001, IN-3005, IN-3010, IN-3010 PoE, IN-3011, IN-4009, IN-4010, IN-4011, IN-5907HD, IN-5907HD PoE, IN-6011HD, IN-6012HD, IN-6012HD PoE, IN-7011HD, IN-7011HD PoE' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ConfigTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ConfigTable)

