
import React, { useState } from 'react'
import {Link} from 'gatsby'

import {
  EuiCallOut,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiButton,
  EuiTitle,
  EuiSpacer
} from '@elastic/eui'

import ProcessorCard from "components/Software/Windows/InstarVision/System_Requirements/processor-load-card";
import MemoryCard from "components/Software/Windows/InstarVision/System_Requirements/memory-load-card";
import NetworkCard from "components/Software/Windows/InstarVision/System_Requirements/network-load-card"

const FlyOut = () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

  const closeFlyout = () => setIsFlyoutVisible(false);

  const showFlyout = () => setIsFlyoutVisible(true);

  let flyout;

  const callOut = (
    <EuiCallOut iconType="help">
      <p>
        Dies is eine Übersicht über die technischen Spezifikationen aller <Link to="/Innenkameras/">INSTAR Innen IP Kameras</Link>. Für eine Übersicht der <Link to="/Aussenkameras/">Aussenkameras</Link> folgen Sie bitte dem <Link to="/Aussenkameras/">Link</Link>.
      </p>
    </EuiCallOut>
  );

  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={closeFlyout}
        size="l"
        aria-labelledby="flyoutWithBannerTitle">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="flyoutWithBannerTitle">InstarVision v2</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody banner={callOut}>
          <h2>System Requirements</h2>

          <u>Server Setup:</u>

          <ul>
            <li>CPU: Intel Core i5-2520M 2.50GHz</li>
            <li>RAM: 4 GB</li>
            <li>DISK: 120 GB SSD</li>
            <li>OS: Windows 10 64-Bit</li>
          </ul>

          <p><small>All cameras were connected by Ethernet cable.</small></p>

          <u>As bare minimum requirements for 4 HD cameras we recommend:</u>

          <ul>
            <li>CPU: DualCore ASUS eeePC (Older Generation)</li>
            <li>RAM: 2 GB</li>
            <li>DISK: 250 GB HDD</li>
            <li>OS: Windows 7 32-Bit</li>
          </ul>
          
          <ProcessorCard />
          <br />
          <MemoryCard />
          <br />
          <NetworkCard />
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }
  return (
    <div>
      <EuiSpacer />
      <EuiButton onClick={showFlyout} color="text">Systemanforderungen</EuiButton>
      {flyout}
    </div>
  );
};

export default FlyOut;