import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Data Protection">
        <EuiText>
          <p>
            With the toggle below you give your permission to the INSTAR Camera Skill to control your camera through the P2P protocol. The necessary access information will be stored inside your INSTAR Cloud Account and not be forwarded to third-parties.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

