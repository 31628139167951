import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Software/Home_Automation/PrimaryBox';
import PushHostAlarm from 'components/Software/Home_Automation/CGI_Galleries/PushHostAlarm';
import SetInfrared from 'components/Software/Home_Automation/CGI_Galleries/SetInfrared';
import GoToPreset from 'components/Software/Home_Automation/CGI_Galleries/GoToPreset';
import SetMdAttr from 'components/Software/Home_Automation/CGI_Galleries/SetMdAttr';
import SetMdAttr2 from 'components/Software/Home_Automation/CGI_Galleries/SetMdAttr2';
import SetMdAttr3 from 'components/Software/Home_Automation/CGI_Galleries/SetMdAttr3';
import GoToPreset2 from 'components/Software/Home_Automation/CGI_Galleries/GoToPreset2';
import ManualRec from 'components/Software/Home_Automation/CGI_Galleries/ManualRec';
import GoToPreset3 from 'components/Software/Home_Automation/CGI_Galleries/GoToPreset3';
import PtzCtrl from 'components/Software/Home_Automation/CGI_Galleries/PtzCtrl';
import PtzCtrlTour from 'components/Software/Home_Automation/CGI_Galleries/PtzCtrlTour';
import SetSchedule from 'components/Software/Home_Automation/CGI_Galleries/SetSchedule';
import SetScheduleBak from 'components/Software/Home_Automation/CGI_Galleries/SetScheduleBak';
import SetMdAlarm from 'components/Software/Home_Automation/CGI_Galleries/SetMdAlarm';
import SetMdAttr4 from 'components/Software/Home_Automation/CGI_Galleries/SetMdAttr4';
import SetPirAttr from 'components/Software/Home_Automation/CGI_Galleries/SetPirAttr';
import SetIoAttr from 'components/Software/Home_Automation/CGI_Galleries/SetIoAttr';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
import NavButtons from 'components/Software/Home_Automation/NavButtons';
import ForumBox from 'components/Software/Home_Automation/Home_Assistant/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  PrimaryBox,
  PushHostAlarm,
  SetInfrared,
  GoToPreset,
  SetMdAttr,
  SetMdAttr2,
  SetMdAttr3,
  GoToPreset2,
  ManualRec,
  GoToPreset3,
  PtzCtrl,
  PtzCtrlTour,
  SetSchedule,
  SetScheduleBak,
  SetMdAlarm,
  SetMdAttr4,
  SetPirAttr,
  SetIoAttr,
  TimeLine,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};