import React from 'react'

import {EuiFlexGroup, EuiFlexItem, EuiText} from '@elastic/eui'

import IN9008RadarIN5907 from "./in9008-radargraph-5907"
import IN9008RadarIN2905 from "./in9008-radargraph-2905"

function IN9008RadarGrid() {

  return (
    <div style={{ padding: '15px',maxWidth: '1115px', margin: '2.5rem 0 2.5rem 0', boxShadow: '0 2px 3px 0 rgb(0 0 0 / 36%' }}>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiText size="s">
            IN-9008FHD vs IN-5907HD
          </EuiText>
          <div style={{height: 275}}>
            <IN9008RadarIN5907 />
          </div>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText size="s">
            IN-9008FHD vs IN-2905
          </EuiText>
          <div style={{height: 275}}>
            <IN9008RadarIN2905 />
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}

export default IN9008RadarGrid