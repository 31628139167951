import React from 'react'

import {
    EuiText,
    EuiCallOut,
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Caution">
        <EuiText>
           <p>It is possible to access your camera over its DDNS address from inside the local network with most common routers - e.g. a AVM FritzBox. But there are routers such as the <em>Telekom Speedport</em> which will block your request - you are only able to access your camera over its local IP address (e.g. 192.168.1.201:8081). This means you would have to use a computer in another network, or alternatively test the DDNS Access by using the mobile network on your smartphone.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

