import PoECardsPoECards from 'components/Products/Power_over_Ethernet/PoECardsPoECards';
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  PoECardsPoECards,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};