import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Overview',
      href: '/en/Outdoor_Cameras/IN-5905_HD/',
    },
    {
      text: 'Specifications',
      href: '/en/Outdoor_Cameras/IN-5905_HD/Technical_Specifications/',
    },
    {
      text: 'Features',
      href: '/en/Outdoor_Cameras/IN-5905_HD/Product_Features/',
    },
    {
      text: 'Installation',
      href: '/en/Outdoor_Cameras/IN-5905_HD/Quick_Installation/',
    },
    {
      text: 'P2P',
      href: '/en/Outdoor_Cameras/IN-5905_HD/Point2Point/',
    },
    {
      text: 'Lense Adjustment',
      href: '/en/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/',
    },
    {
      text: 'Usermanual',
      href: '/en/Outdoor_Cameras/IN-5905_HD/Usermanual/',
    },
    {
      text: '◄ Outdoor Cameras',
      href: '/en/Outdoor_Cameras/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={10}
        aria-label="INSTAR IN-5905 HD Online Usermanual Chapters"
      />
      <EuiSpacer />
    </Fragment>
  );
}