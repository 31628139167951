import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import ftpWebUI01 from "../../../../images/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/FTP-Fritzbox-7490de_06.webp"
import ftpWebUI02 from "../../../../images/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/FTP-Fritzbox-7490de_07.webp"
import ftpWebUI03 from "../../../../images/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/FTP-Fritzbox-7490de_08.webp"
import ftpWebUI04 from "../../../../images/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/FTP-Fritzbox-7490de_09.webp"



export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ftpWebUI01}
                    alt="Web User Interface"
                  />
              }
              title='Web User Interface'
              description={
                <p>Open your camera's web user interface and go to Alarm/FTP to input your FTP server information you set up earlier.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ftpWebUI02}
                    alt="FTP Settings"
                  />
              }
              title='FTP Settings'
              description={
                <p>ype in your FTP login credentials and server information like described above. Submit before testing the upload.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ftpWebUI03}
                    alt="Testing"
                  />
              }
              title='Testing'
              description={
                <p>You will find a single snapshot in your upload directory, after successfully testing the FTP connection.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ftpWebUI04}
                    alt="Alarm Uploads"
                  />
              }
              title='Alarm Uploads'
              description={
                <p>Now you can activate the FTP upload functions for alarm trigger events.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
