import React from 'react'

import { Player } from 'video-react'

import {
    EuiText,
    EuiIcon,
    EuiPanel
  } from '@elastic/eui'

import { OutboundLink } from 'gatsby-plugin-gtag'

require('../../../Layout/chrome/assets/video.css')

function VideoCard() {
  return (
    <EuiPanel paddingSize="m">
        <Player>
            <source src="https://wiki.instar.com/videos/Unboxing_der_neuen_INSTAR_IN9008_Full_HD_IP_Kamera.mp4" />
        </Player>
        <EuiText>
            <p>In diesem Video zeigen wir Ihnen die neue INSTAR FUll HD Kamera (Modell IN-9008). Die einzigartige neue Kamera kommt ohne Anschlusskabel und man hat so die Möglichkeit nur die Kabel ins innere der Kamera zu führen die man auch wirklich benötigt. Zudem kommt die Kamera mit einem sehr sehr hochwertigen Panasonic WDR Bildsensor als auch einem eingebauten Panasonic PIR Wärmesensor. Es ist zudem ein Mikrofon bereits eingebaut. Diese hardwareseitigen Features machen die IN-9008 Full HD zu einer der besten Überwachungskameras die es aktuell auf dem Markt gibt.</p>
            <p>Überzeugen Sie sich jetzt selbst und bestellen Sie Ihre IN-9008 Full HD direkt bei INSTAR. Auf <OutboundLink href="https://www.youtube.com/watch?v=ckKuHQFU-Pc" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
        </EuiText>
    </EuiPanel>
  );
}

export default VideoCard