import { Link } from 'gatsby';
import InstallationArticles from 'components/Quick_Installation/IntroInstall';
import NavButtons from 'components/Quick_Installation/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  InstallationArticles,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};