import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Portforwarding & DDNS">
        <EuiText>
          <p>All these broadcasting modes need you to forward the software HTTP (or RTSP) port to the server that runs the Surveillance Center. Please refer to our forwarding instruction for further information - note: in these instructions, we forward a port directly to each camera you want to access. In case of the Surveillance Center broadcast we only need to forward one port (HTTP or RTSP) directly to the server that runs the software and are able to access all camera streams maintained by the Surveillance Center! Remember that you can still use your camera´s DDNS address to access the software - just type in the DDNS address of your camera (that is operated on the same network as the Surveillance Center) and add the port that you forwarded to your server. E.g. if your camera is accessible from the internet via the address abcd12.ddns-instar.de:8081 and you forwarded port 8082 to the server, use type in <em>http://abcd12.ddns-instar.de:8082</em> to access the software web user interface!</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

