import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="jq4s9EsyADI"
                    title="VPN Verbindung / Tunnel mit einer AVM FritzBox herstellen"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="VPN Verbindung mit einer AVM FritzBox"
              footer={
                <EuiText>
                  <p>Wir erklären in diesem Video wie man eine VPN Verbindung, also einen Tunnel aufbauen kann in sein eigenes Netzwerk.</p>
                  <p>Auf diese Weise haben Sie z.B. die Möglichkeit so auf Ihre INSTAR Kamera zuzugreifen, als seien Sie zu Hause im gleichen Netzwerk. Sie verbinden sich so z.B. mit Ihrem Smartphone mit Ihrer Fritzbox per VPN und können dann die Kamera über die lokale IP Adresse erreichen. Die Verbindung ist dabei komplett verschlüsselt und sicher. </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}