import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Web_User_Interface/720p_Series/Alarm/Actions/PrimaryBox';
import ActionTable from 'components/Web_User_Interface/720p_Series/Alarm/Actions/actionTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  PrimaryBox,
  ActionTable,
  BreadCrumbs,
  SEOHelmet,
  React
};