import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ImageTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Image Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Hue', descriptionColumn: 'Use the hue slider to change the colour tint to your video image. The hue parameter enables you to make colour adjustments, when the automatic white balance is not to your liking.' },
        { actionColumn: 'Brightness', descriptionColumn: 'Adjust the brightness of the video image.' },
        { actionColumn: 'Saturation', descriptionColumn: 'Adjust the saturation of the video image.' },
        { actionColumn: 'Contrast', descriptionColumn: 'Adjust the contrast of the video image.' },
        { actionColumn: 'Sharpness', descriptionColumn: 'Adjust the sharpness of the video image.' },
        { actionColumn: 'Gamma', descriptionColumn: 'Use the Gamma slider to adjust the luminance of your video image. Larger values make the shadows darker, while smaller values make dark regions lighter.' },
        { actionColumn: 'Exposure', descriptionColumn: 'Adjust the exposure time (shutter) of the image sensor.' },
        { actionColumn: 'Default', descriptionColumn: 'This button resets the image settings to their default values.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ImageTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ImageTable)