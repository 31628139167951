import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class DownloadTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "changeColumn", title: "Changes" }
      ],
      rows: [
        { changeColumn: 'A new layout has been added, showing 2 channels separated horizontally.' },
        { changeColumn: 'Maps now adapt to the window size.' },
        { changeColumn: 'The overview of the result of a camera search has been improved. The results are now sorted and better positionized.' },
        { changeColumn: 'A map can now also be deleted with the minus button.' },
        { changeColumn: 'A rotation button has been added for each channel, allowing the video image to be rotated 90 degrees.' },
        { changeColumn: 'If a camera is unreachable, an error message will now be displayed in the middle of the video, indicating why the connection can not be established.' },
        { changeColumn: 'If the "program is locked" (click on lock icon) and no software login was previously activated or you are logged in with a user with the name "admin", then the standard login data ("admin", "instar") will be pre-filled, so that you can immediately unlock the software by clicking on "Ok", if you accidentally clicked on it.' },
        { changeColumn: 'The configuration of a schedule has been completely redesigned. So you can configure these now, like the schedules of an instar camera. For the "Continuous recording", the "Alarm recording" and the "Photoseries" there is now a table in which for each day of the week an arbitrary time range can be selected by 15 min sections. It is now easy to determine at a glance when recordings are taken and when not. Whether recordings are made for the currently selected channel can be set with the check mark "Activate recording".' },
        { changeColumn: 'Added a validation for port inputs.' },
        { changeColumn: 'The sensitivity slider has been improved so that the entire range can now be configured fine grained. Please note that although the value is adjusted during a software update to this version, it can not be 100% converted. The sensitivity and the upper and lower limits may need to be adjusted manually.' },
        { changeColumn: 'In a photo series, the FTP upload worked so far only if it was also locally saved. This coupling has now been lifted.' },
        { changeColumn: 'Added the new camera models "IN-9020 Full HD" and "IN-8003 Full HD" to the list of selectable cameras. In addition, the entry for the models "IN-8015 Full HD" and "IN-9008 Full HD" was split into two separate entries.' },
        { changeColumn: 'If a video channel whose camera has no PTZ function is selected, "PTZ Camera" will now "No PTZ available." displayed.' },
        { changeColumn: 'Under "Camera PTZ" buttons for manual focusing have been added for the "IN-9020 Full HD". Although the focus is automatically readjusted after zooming in or out, it can now be readjusted using the "+" and "-" buttons as in the camera web interface. In addition, two new menu items for "in-focus" and "out-focus" will now also be added to the global "Pan & Tilt" menu (Citizen icon) if "IN-9020 Full HD" is selected in the video display.' },
        { changeColumn: 'The CPU load with activated alarm detection was significantly reduced per channel, so that it only needs a few percent more compared to the load without alarm detection.' },
        { changeColumn: 'The CPU load when previewing the alarm detection has been reduced.' },
        { changeColumn: 'The preview of the alarm detection has been improved so that now, with overlapping areas, the motion pixels are displayed in the color of the area at which a motion was detected at last. The order is R1, R2, R3 and then R4. This allows you to see which parts in the overlap have been detected by which setting. Previously, the motion pixels were always displayed in the color of the first area, even if the movement was detected by the setting of another overlapping area.' },
        { changeColumn: 'InstarPlayer is now also available in German. Depending on which language is set in the InstarVision this changes between German and English.' },
        { changeColumn: 'A security question has been added, indicating that exported configurations can only be reimported with the same version.' },
        { changeColumn: 'Fixed a display problem after changing a layout through the burger menu.' },
        { changeColumn: 'Added the possibility to change the user name.' },
        { changeColumn: 'Added Focus and Zoom functionality for Instar P2P (only possible with focus and zoom camera such as the IN-9020 FullHD).' },
        { changeColumn: 'The ip address is now protected against incorrect entry.' },
        { changeColumn: 'An error message will be displayed if the InstarVision can not be registered.' },
        { changeColumn: 'For a better overview the dialog for the camera selection has been revised, so that depending on the camera type only the relevant input fields are displayed.' },
        { changeColumn: 'A new menu item has been added to the camera list to start or stop all channels with one click.' },
        { changeColumn: 'After a channel has been maximized, the maximize button will now be displayed as a minimize button.' },
        { changeColumn: 'The name of the camera is now transferred when selecting a camera search result.' },
        { changeColumn: 'The expired certificate in the signature of the software has been renewed.' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

DownloadTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(DownloadTable)





// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'


// const cameraRow = [
//   'A new layout has been added, showing 2 channels separated horizontally.',
//   'Maps now adapt to the window size.',
//   'The overview of the result of a camera search has been improved. The results are now sorted and better positionized.',
//   'A map can now also be deleted with the minus button.',
//   'A rotation button has been added for each channel, allowing the video image to be rotated 90 degrees.',
//   'If a camera is unreachable, an error message will now be displayed in the middle of the video, indicating why the connection can not be established.',
//   'If the "program is locked" (click on lock icon) and no software login was previously activated or you are logged in with a user with the name "admin", then the standard login data ("admin", "instar") will be pre-filled, so that you can immediately unlock the software by clicking on "Ok", if you accidentally clicked on it.',
//   'The configuration of a schedule has been completely redesigned. So you can configure these now, like the schedules of an instar camera. For the "Continuous recording", the "Alarm recording" and the "Photoseries" there is now a table in which for each day of the week an arbitrary time range can be selected by 15 min sections. It is now easy to determine at a glance when recordings are taken and when not. Whether recordings are made for the currently selected channel can be set with the check mark "Activate recording".',
//   'Added a validation for port inputs.',
//   'The sensitivity slider has been improved so that the entire range can now be configured fine grained. Please note that although the value is adjusted during a software update to this version, it can not be 100% converted. The sensitivity and the upper and lower limits may need to be adjusted manually.',
//   'In a photo series, the FTP upload worked so far only if it was also locally saved. This coupling has now been lifted.',
//   'Added the new camera models "IN-9020 Full HD" and "IN-8003 Full HD" to the list of selectable cameras. In addition, the entry for the models "IN-8015 Full HD" and "IN-9008 Full HD" was split into two separate entries.',
//   'If a video channel whose camera has no PTZ function is selected, "PTZ Camera" will now "No PTZ available." displayed.',
//   'Under "Camera PTZ" buttons for manual focusing have been added for the "IN-9020 Full HD". Although the focus is automatically readjusted after zooming in or out, it can now be readjusted using the "+" and "-" buttons as in the camera web interface. In addition, two new menu items for "in-focus" and "out-focus" will now also be added to the global "Pan & Tilt" menu (Citizen icon) if "IN-9020 Full HD" is selected in the video display.',
//   'The CPU load with activated alarm detection was significantly reduced per channel, so that it only needs a few percent more compared to the load without alarm detection.',
//   'The CPU load when previewing the alarm detection has been reduced.',
//   'The preview of the alarm detection has been improved so that now, with overlapping areas, the motion pixels are displayed in the color of the area at which a motion was detected at last. The order is R1, R2, R3 and then R4. This allows you to see which parts in the overlap have been detected by which setting. Previously, the motion pixels were always displayed in the color of the first area, even if the movement was detected by the setting of another overlapping area.',
//   'InstarPlayer is now also available in German. Depending on which language is set in the InstarVision this changes between German and English.',
//   'A security question has been added, indicating that exported configurations can only be reimported with the same version.',
//   'Fixed a display problem after changing a layout through the burger menu.',
//   'Added the possibility to change the user name.',
//   'Added Focus and Zoom functionality for Instar P2P (only possible with focus and zoom camera such as the IN-9020 FullHD).',
//   'The ip address is now protected against incorrect entry.',
//   'An error message will be displayed if the InstarVision can not be registered.',
//   'For a better overview the dialog for the camera selection has been revised, so that depending on the camera type only the relevant input fields are displayed.',
//   'A new menu item has been added to the camera list to start or stop all channels with one click.',
//   'After a channel has been maximized, the maximize button will now be displayed as a minimize button.',
//   'The name of the camera is now transferred when selecting a camera search result.',
//   'The expired certificate in the signature of the software has been renewed.'
// ]

// // Render your table
// const ConnectTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={cameraRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Release Notes</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {cameraRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={850}
//         />
//       </Table>
//     )
// }

// export default ConnectTable