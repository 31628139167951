import React from 'react'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import compareImage18AoV from '../../../../../images/Products/Lenses/S-Mount/IN-0180/Wideangle_Lense_18mm_Angle_of_View.webp'
import compareImage18 from '../../../../../images/Products/Lenses/S-Mount/IN-0180/Wideangle_1.8mm_with_IRcut.webp'
import compareImage22AoV from '../../../../../images/Products/Lenses/S-Mount/IN-0220/Wideangle_Lense_2.2mm_Angle_of_View.webp'
import compareImage22 from '../../../../../images/Products/Lenses/S-Mount/IN-0220/Wideangle_2.2mm_IRcut.webp'
import compareImage29AoV from '../../../../../images/Products/Lenses/S-Mount/IN-0290/Wideangle_Lense_2.9mm_Angle_of_View.webp'
import compareImage36AoV from '../../../../../images/Products/Lenses/S-Mount/IN-0360/Lense_3.6mm_Angle_of_View.webp'
import compareImage36 from '../../../../../images/Products/Lenses/S-Mount/IN-0360/09_3.6mm_IRcut.webp'
import compareImage43AoV from '../../../../../images/Products/Lenses/S-Mount/IN-0430/Lense_4.3mm_-_Angle_of_View.webp'
import compareImage43 from '../../../../../images/Products/Lenses/S-Mount/IN-0430/4.3mm_IRcut.webp'
import compareImage60AoV from '../../../../../images/Products/Lenses/S-Mount/IN-0600/Angle_of_View_6mm_Lense.webp'
import compareImage60 from '../../../../../images/Products/Lenses/S-Mount/IN-0600/6mm_IRcut.webp'
import compareImage120AoV from '../../../../../images/Products/Lenses/S-Mount/IN-1200/Angle_of_View_12mm-Lense.webp'
import compareImage120 from '../../../../../images/Products/Lenses/S-Mount/IN-1200/Tele_Lense_12mm_IRcut.webp'
import compareImage160AoV from '../../../../../images/Products/Lenses/S-Mount/IN-1600/Lense_16mm_Angle_of_View.webp'
import compareImage160 from '../../../../../images/Products/Lenses/S-Mount/IN-1600/Tele_Lense_16mm_IRcut.webp'

export default function LenseArticles() {

  return (
    <React.Fragment>
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={compareImage18AoV}
                alt="IN-0180"
              />
            }
            title="IN-0180"
            description="The angle of view of the 1.8mm wideangle lense. Field Angle (D x H x V): 109.4° x 87.7° x 67.6°."
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={compareImage18}
                alt="IN-0180"
              />
            }
            title="IN-0180"
            description="Test image of the 1.8mm wideangle lense on a 1/5 inch CMOS sensor with IRcut filter."
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={compareImage22AoV}
                alt="IN-0220"
              />
            }
            title="IN-0220"
            description="The angle of view of the <strong>2.2mm wideangle lense</strong>. Field Angle (D x H x V): 98.2° x 78.7° x 60.7°."
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={compareImage22}
                alt="IN-0220"
              />
            }
            title="IN-0220"
            description="Test image of the 2.2mm wideangle lense on a 1/5 inch CMOS sensor with IRcut filter."
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={compareImage29AoV}
                alt="IN-0290"
              />
            }
            title="IN-0290"
            description="The angle of view of the <strong>2.9mm wideangle lense</strong>. Field Angle (D x H x V): 82.4° x 66.1° x 50.9°."
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={compareImage22}
                alt="IN-0290"
              />
            }
            title="IN-0290"
            description="Test image of the 2.9mm wideangle lense on a 1/5 inch CMOS sensor with IRcut filter."
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={compareImage36AoV}
                alt="IN-0360"
              />
            }
            title="IN-0360"
            description="The angle of view of the 3.6mm lense. Field Angle (D x H x V): 70.4° x 56.4° x 43.5°."
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={compareImage36}
                alt="IN-0360"
              />
            }
            title="IN-0360"
            description="Test image of the 3.6mm lense on a 1/5 inch CMOS wideangle sensor with IRcut filter."
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={compareImage43AoV}
                alt="IN-0430"
              />
            }
            title="IN-0430"
            description="The angle of view of the 4.3mm lense. Field Angle (D x H x V): 61.1° x 49.0° x 37.8°."
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={compareImage43}
                alt="IN-0430"
              />
            }
            title="IN-0430"
            description="Test image of the 4.3mm lense on a 1/5 inch CMOS sensor with IRcut filter."
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={compareImage60AoV}
                alt="IN-0600"
              />
            }
            title="IN-0600"
            description="The angle of view of the 6.0mm default lense. Field Angle (D x H x V): 45.9° x 36.8° x 28.4°."
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={compareImage60}
                alt="IN-0600"
              />
            }
            title="IN-0600"
            description="Test image of the 6mm default lense on a 1/5 inch CMOS sensor with IRcut filter."
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={compareImage120AoV}
                alt="IN-1200"
              />
            }
            title="IN-1200"
            description="The angle of view of the 12.0mm lense. Field Angle (D x H x V): 23.9° x 19.2° x 14.8°."
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={compareImage120}
                alt="IN-1200"
              />
            }
            title="IN-1200"
            description="Test image of the 12.0mm default lense on a 1/5 inch CMOS sensor with IRcut filter."
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={compareImage160AoV}
                alt="IN-1600"
              />
            }
            title="IN-1600"
            description="The angle of view of the 16.0mm lense. Field Angle (D x H x V): 18.0° x 14.5° x 11.2°."
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <img
                src={compareImage160}
                alt="IN-1600"
              />
            }
            title="IN-1600"
            description="Test image of the 16.0mm default lense on a 1/5 inch CMOS sensor with IRcut filter."
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  );
}