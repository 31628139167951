import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'IN-6001 HD',
      href: '/en/Downloads/Indoor_Cameras/IN-6001_HD/',
    },
    {
      text: 'IN-6012 HD',
      href: '/en/Downloads/Indoor_Cameras/IN-6012_HD/',
    },
    {
      text: 'IN-6014 HD',
      href: '/en/Downloads/Indoor_Cameras/IN-6014_HD/',
    },
    {
      text: 'IN-8001 FHD',
      href: '/en/Downloads/Indoor_Cameras/IN-8001_HD/',
    },
    {
      text: 'IN-8003 FHD',
      href: '/en/Downloads/Indoor_Cameras/IN-8003_HD/',
    },
    {
      text: 'IN-8015 FHD',
      href: '/en/Downloads/Indoor_Cameras/IN-8015_HD/',
    },
    {
      text: 'Indoor Cameras',
      href: '/en/Downloads/Indoor_Cameras/',
    },
    {
      text: '◄ Downloads',
      href: '/en/Downloads/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Downloadbereich for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}