import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/LiveStreamPerm';
import SEOHelmet from 'components/Layout/SEOHelmet';
import DangerBox from 'components/Web_User_Interface/1440p_Series/Live_Video/DangerBox';
import * as React from 'react';
export default {
  EuiSpacer,
  BreadCrumbs,
  PermissionBox,
  SEOHelmet,
  DangerBox,
  React
};