import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FTPTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "FTP Service" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'FTP Server', descriptionColumn: 'Type in your FTP server address here. You can also use devices inside your LAN for this function, e.g. a Local Server or maybe your Router or NAS.' },
        { actionColumn: 'FTP Port', descriptionColumn: 'The default port for the FTP service is Port 21. Adjust this port here, if your configured your server differently.' },
        { actionColumn: 'Modus', descriptionColumn: 'You can use the passive mode (PASV) in most cases. Only choose PORT mode if PASV fails. This might happen if your FTP server is by an NAT firewall.' },
        { actionColumn: 'Storage path', descriptionColumn: 'The FTP directory - the folder that your camera will use to upload images and videos to - is always relative to the root directory of your FTP User! The root directory for each user is defined on your FTP server - read our AVM Fritzbox manual for an example.' },
        { actionColumn: 'Directory structure', descriptionColumn: 'If you want to upload a large number of files to the FTP server, it is recommended to create a new folder for those uploads each day. With some systems you might run into some problems with this setting. In this case try to upload all files to one folder instead.' },
        { actionColumn: 'FTP Username', descriptionColumn: 'The username - and it´s access rights - are defined on your FTP server. Type in the username you want your camera to use here.' },
        { actionColumn: 'FTP Password', descriptionColumn: 'Add the corresponding password here.' },
        { actionColumn: 'Test', descriptionColumn: 'The test function will try to upload a file to your FTP server - the name of the file consists of the capital letter T and a Timestamp. Don´t forget to submit your settings first before using the Test Function!' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FTPTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FTPTable)