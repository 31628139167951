import React from 'react'

import {EuiText, EuiSpacer} from '@elastic/eui'

import NightvisionGraph from "./compression-h264-5-graph"

function NightvisionCard() {
  return (
    <div style={{ padding: '15px',maxWidth: '1115px', margin: '2.5rem 0 2.5rem 0', boxShadow: '0 2px 3px 0 rgb(0 0 0 / 36%' }}>
      <div style={{paddingTop: 20, paddingBottom: 10, paddingLeft: 10, paddingRight: 35}}>
        <EuiText grow={false} size="m" color="default" >
          Dateigröße als Funktion der gewählten Videokomprimierung und Bitrate <strong>[MB/s]</strong> 
        </EuiText>
            <em>(WQHD 2560x1440 Stream)</em>
        <EuiSpacer />
        <NightvisionGraph />
        <EuiSpacer />
      </div>
    </div>
  );
}

export default NightvisionCard