import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Updates:">
        <EuiText>
          <h3>Related Articles:</h3>
          <ul>
              <li><Link to="/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/">INSTAR MQTT Server with Home Assistant</Link></li>
              <li><Link to="/Software/Linux/Home_Assistant/">Home Assistant with Debian</Link></li>
          </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

