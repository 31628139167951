import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ImageAdvancedTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Advanced Image Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Turn picture by 180°', descriptionColumn: 'If you installed your camera over-head, simply flip and mirror the video to see it upright again.' },
        { actionColumn: 'Distortion Correction', descriptionColumn: 'Especially wide-angle lenses can distort your cameras image. Use this slider to add a correction.' },
        { actionColumn: 'Wide Dynamic Range', descriptionColumn: 'Is your camera exposed to strong back lighting? The powerful Hardware Wide Dynamic Range mode will allow you to handle the situation. But it will add a softening /blooming effect around light sources to the video image. The software WDR mode is less powerful and can add noise to your video image (depending on the WDR strength, see below). But the image will always stay "sharp". Use the Hardware WDR mode for surveillance areas that challenge the Image Sensor with high contrast values. The Software WDR usually offers the best image quality in evenly lit areas.' },
        { actionColumn: 'Fixed WDR schedule', descriptionColumn: 'The Hardware WDR helps you to balance out the brightness of your video during the day. It might cause your image to become blurry when it gets dark (see screenshots on top of this page). Use this automation switch your camera to the Software WDR at night and back to Hardware WDR when the sun rises.' },
        { actionColumn: 'Hardware WDR from', descriptionColumn: 'Set a time when your camera should activate the Hardware WDR mode.' },
        { actionColumn: 'Software WDR from', descriptionColumn: 'Set a time when your camera should activate the Software WDR mode.' },
        { actionColumn: 'Auto WDR', descriptionColumn: 'The Auto WDR mode is only available, when you activated the Hardware WDR mode above. It can be configured with the slider below.' },
        { actionColumn: 'WDR Strength', descriptionColumn: 'This slider adjust the strength of the Auto WDR mode (which can only be activated in Hardware WDR mode). The higher the value, the more the sensor tries to compensate high contrast areas in your video image. High values can compensate the blinding effect of strong light sources pointing towards the camera. Security cameras also are often pointed towards dark buildings in front of bright sky - a high value for the WDR strength will brighten up the shadows, while lowering the brightness of the sky. Be aware that a high value of WDR will cause a blooming effect around light sources, making the image appear less sharp. Lower the WDR strength to reduce this effect. Or switch to the Software WDR mode.' },
        { actionColumn: 'Fixed WDR Strength', descriptionColumn: 'This slider allows you to manually adjust the strength of the Hardware or Software WDR mode to a fixed value. Be aware that a high value will raise the WDR effect but will also raise the image brightness and add noise in dark areas of your videos. The WDR effect will raise the brightness in dark areas and reduce brightness in bright areas of your video frame.' },
        { actionColumn: 'Auto Denoise', descriptionColumn: 'Remove digital noise in your camera´s video image. The automatic denoise mode will give you the best results in most cases.' },
        { actionColumn: 'Denoise', descriptionColumn: 'If your camera should use a fixed value for the Denoise algorithm. The higher the value, the more aggressive the denoise algorithm will work - reducing digital noise (as well as image details).' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ImageAdvancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ImageAdvancedTable)