import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Password Guidelines">
        <EuiText>
          <p>The WiFi password is limited to max. 63 characters and may not contain the following special characters: {' _& = " ` + \\ '}</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

