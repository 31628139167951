import React from 'react'

import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Communicate with your Camera">
        <EuiText>
          <p>The examples below use <strong><Link to="/Advanced_User/CGI_Commands/">CGI Commands</Link></strong> to have your Smarthome automate camera functions. To use these commands you have to add your camera's <Link to="/Web_User_Interface/1440p_Series/Network/IP_Configuration/">IP Address, HTTP Port</Link> and <Link to="/Web_User_Interface/1440p_Series/System/User/"> Admin Login</Link> to it - e.g. if your cameras IP address is <strong>192.168.178.121</strong>, the camera LAN port is <strong>8081</strong>, the login is <strong>admin/instar</strong> and you want to send the command that triggers an alarm on your camera <strong>param.cgi?cmd=pushhostalarm</strong> this will look like this:</p>
          <ul>
            <li><strong>WQHD 2k+ Series:</strong></li>
            <ul>
              <li><em>http://192.168.178.121:8081/param.cgi?cmd=pushhostalarm&-user=admin&-pwd=instar</em></li>
            </ul>
            <li><strong>HD & Full HD Series:</strong></li>
            <ul>
              <li><em>http://192.168.178.121:8081/param.cgi?cmd=pushhostalarm&-usr=admin&-pwd=instar</em></li>
            </ul>
          </ul>
          <p>Before you enter this command into your Smarthome configuration, you can first copy&paste it into the address bar of your web browser and press enter. If you receive an <strong>OK</strong> from your camera and see the effect you wanted - in the case above you should see an alarm in your camera's <Link to="/Web_User_Interface/1440p_Series/System/Log/">System Log</Link> and all active <Link to="/Web_User_Interface/1440p_Series/Alarm/Actions/">Alarm Actions</Link> should be triggered - you are good to proceed configuring your Smarthome.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

