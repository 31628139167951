import React from 'react'

import Image01Quality from '../../../images/Indoor_Cameras/IN-8015_HD/IN-8015v6012_resTestBig.webp'
import Image02Quality from '../../../images/Indoor_Cameras/IN-8015_HD/IN-8015v6014_resTestBig.webp'
import Image03Quality from '../../../images/Indoor_Cameras/IN-8015_HD/IN-8015v6014_resTestBig2.webp'
import Image04Quality from '../../../images/Indoor_Cameras/IN-8015_HD/IN-8015v6014_resTestBig4.webp'
import Image05Quality from '../../../images/Indoor_Cameras/IN-8015_HD/IN-8015HD-vs-IN-6014HD_nightBig.webp'
import Image06Quality from '../../../images/Indoor_Cameras/IN-8015_HD/IN-8015v6014_resTestBig3.webp'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

const Cards = () => (
    <React.Fragment>
        <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
                <EuiImage
                    size="fullWidth"
                    hasShadow
                    allowFullScreen
                    caption="Click me"
                    alt="IN-8015FHD vs IN-6012HD"
                    fullScreenIconColor="light"
                    src={Image01Quality}
                />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <EuiImage
                    size="fullWidth"
                    hasShadow
                    allowFullScreen
                    caption="Click me"
                    alt="IN-8015FHD vs IN-6014HD"
                    fullScreenIconColor="light"
                    src={Image02Quality}
                />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <EuiImage
                    size="fullWidth"
                    hasShadow
                    allowFullScreen
                    caption="Click me"
                    alt="IN-8015FHD vs IN-6014HD"
                    fullScreenIconColor="light"
                    src={Image03Quality}
                />
            </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer />

        <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
                <EuiImage
                    size="fullWidth"
                    hasShadow
                    allowFullScreen
                    caption="Click me"
                    alt="IN-8015FHD vs IN-6014HD"
                    fullScreenIconColor="light"
                    src={Image04Quality}
                />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <EuiImage
                    size="fullWidth"
                    hasShadow
                    allowFullScreen
                    caption="Click me"
                    alt="IN-8015FHD vs IN-6014HD"
                    fullScreenIconColor="light"
                    src={Image05Quality}
                />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <EuiImage
                    size="fullWidth"
                    hasShadow
                    allowFullScreen
                    caption="Click me"
                    alt="IN-8015FHD vs IN-6014HD"
                    fullScreenIconColor="light"
                    src={Image06Quality}
                />
            </EuiFlexItem>
        </EuiFlexGroup>
  </React.Fragment>
)

export default Cards