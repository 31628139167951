import React from "react"

import ImageGallery from "react-image-gallery"

import galleryImage01 from "../../../images/Outdoor_Cameras/IN-9008_HD/IN-9008HD-vs-IN-5905HD_day.webp"
import galleryImage01Thumb from "../../../images/Outdoor_Cameras/IN-9008_HD/IN-9008HD-vs-IN-5905HD_day_thumb.webp"
import galleryImage02 from "../../../images/Outdoor_Cameras/IN-9008_HD/IN-9008HD-vs-IN-5905HD_day2.webp"
import galleryImage02Thumb from "../../../images/Outdoor_Cameras/IN-9008_HD/IN-9008HD-vs-IN-5905HD_day2_thumb.webp"
import galleryImage03 from "../../../images/Outdoor_Cameras/IN-9008_HD/IN-9008HD-vs-IN-5905HD_night.webp"
import galleryImage03Thumb from "../../../images/Outdoor_Cameras/IN-9008_HD/IN-9008HD-vs-IN-5905HD_night_thumb.webp"
import galleryImage04 from "../../../images/Outdoor_Cameras/IN-9008_HD/IN-9008HD-vs-IN-5907HD_day.webp"
import galleryImage04Thumb from "../../../images/Outdoor_Cameras/IN-9008_HD/IN-9008HD-vs-IN-5907HD_day_thumb.webp"
import galleryImage05 from "../../../images/Outdoor_Cameras/IN-9008_HD/IN-9008HD-vs-IN-5907HD.webp"
import galleryImage05Thumb from "../../../images/Outdoor_Cameras/IN-9008_HD/IN-9008HD-vs-IN-5907HD_thumb.webp"

require("react-image-gallery/styles/css/image-gallery.css")

export class IN9008ImageQGallery extends React.Component {
  render() {
    const images = [{ original: galleryImage01, thumbnail: galleryImage01Thumb }, { original: galleryImage02, thumbnail: galleryImage02Thumb }, { original: galleryImage03, thumbnail: galleryImage03Thumb }, { original: galleryImage04, thumbnail: galleryImage04Thumb }, { original: galleryImage05, thumbnail: galleryImage05Thumb }];

    return (
      <div style={{ padding: '15px',maxWidth: '1115px', margin: '2.5rem 0 2.5rem 0', boxShadow: '0 2px 3px 0 rgb(0 0 0 / 36%' }}>
        <ImageGallery items={images} lazyLoad={true} showPlayButton={false} />
      </div>
    );
  }
}

export default IN9008ImageQGallery
