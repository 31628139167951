import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="UPnP Automatic Port Forwarding">
        <EuiText>
          <p>
            The UPnP standard is supposed to make your life easy. Every port that is needed publicly by a device on your network will automatically be forwarded. This would make the DDNS ACCESS to your camera very easy...unfortunately it often does not work and might be considered a security hazard by your network administrator.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

