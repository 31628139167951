import { EuiSpacer } from '@elastic/eui';
import MoveTable from 'components/1440p_Series_CGI_List/Features_Menu/MoveTable';
import RelPosTable from 'components/1440p_Series_CGI_List/Features_Menu/RelPosTable';
import NavButtons from 'components/1440p_Series_CGI_List/NavButtons';
import * as React from 'react';
export default {
  EuiSpacer,
  MoveTable,
  RelPosTable,
  NavButtons,
  React
};