import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../images/Indoor_Cameras/IN-6012_HD/Quick_Installation/Quickinstallation_01.webp"
import qiSelect02 from "../../../../images/Indoor_Cameras/IN-6012_HD/Quick_Installation/Quickinstallation_02.webp"
import qiSelect03 from "../../../../images/Indoor_Cameras/IN-6012_HD/Quick_Installation/Quickinstallation_03.webp"
import qiSelect04 from "../../../../images/Indoor_Cameras/IN-6012_HD/Quick_Installation/Quickinstallation_04.webp"
import qiSelect05 from "../../../../images/Indoor_Cameras/IN-6012_HD/Quick_Installation/Quickinstallation_05.webp"
import qiSelect06 from "../../../../images/Indoor_Cameras/IN-6012_HD/Quick_Installation/Quickinstallation_06.webp"
import qiSelect07 from "../../../../images/Indoor_Cameras/IN-6012_HD/Quick_Installation/Quickinstallation_07.webp"
import qiSelect08 from "../../../../images/Indoor_Cameras/IN-6012_HD/Quick_Installation/Quickinstallation_08.webp"
import qiSelect09 from "../../../../images/Indoor_Cameras/IN-6012_HD/Quick_Installation/Quickinstallation_09.webp"
import qiSelect10 from "../../../../images/Indoor_Cameras/IN-6012_HD/Quick_Installation/Quickinstallation_10.webp"
import qiSelect11 from "../../../../images/Indoor_Cameras/IN-6012_HD/Quick_Installation/Quickinstallation_11.webp"
import qiSelect12 from "../../../../images/Indoor_Cameras/IN-6012_HD/Quick_Installation/Quickinstallation_12.webp"
import qiSelect13 from "../../../../images/Indoor_Cameras/IN-6012_HD/Quick_Installation/Quickinstallation_13.webp"
import qiSelect14 from "../../../../images/Indoor_Cameras/IN-6012_HD/Quick_Installation/Quickinstallation_14.webp"



export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect01}
                    alt="Attach the WiFi antenna to the cameras antenna connector as shown here (#11). (only for the non-PoE Version!)"
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Schrauben Sie die WLAN Antenne auf den Anschluss im hinteren Teil der Kamera (<Link to="/Indoor_Cameras/IN-6012_HD/Product_Features/">#11</Link>) um später eine WLAN Verbindung aufzubauen zu können. (nur nicht-PoE Version!)
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect02}
                    alt="Plug in the power supply (#7) to start the camera."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Stecken Sie das Kabel des Netzteils in den Anschluss im hinteren Teil der Kamera (<Link to="/Indoor_Cameras/IN-6012_HD/Product_Features/">#7</Link>), um diese mit Strom zu versorgen.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect03}
                    alt="It is recommended to set your WiFi router to a band in the range from 1-6 (802.11bgn). Also WPA2/AES (CCMP) encryption should be used."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    In Ihrem WLAN Router sollte ein Kanal im Bereich von 1-6 (802.11bgn) eingestellt sein, sowie die WPA2/AES (CCMP) Verschlüsselung.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect04}
                    alt="A Press the WPS button (s. above) on your router. B Press the camera´s reset button for 3s. Be aware that holding it for longer than 10s will result in a camera reset. C The camera will now automatically connect to your WiFi network. Please skip to step 7 (for PC or Mac) or 14 (for smartphone)."
                  />
              }
              footer={
                <EuiText>
                    <ul>
                      <li><strong>A</strong> Drücken Sie den WPS Button auf Ihrem Router (s. o.).</li>
                      <li><strong>B</strong> Drücken Sie den Reset Taster der Kamera für 3s (länger als 10s führt zu einem Werksreset!).</li>
                      <li><strong>C</strong> Die Kamera wird sich danach automatisch mit Ihrem WLAN verbinden. Springen Sie zu Schritt 7 (PC oder Mac) oder Schritt 14 (Smartphone).</li>
                    </ul>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect05}
                    alt="Connect the network cable to the back of the camera (s. #12) to setup a LAN connection (also necessary for WiFi if WPS is not available s. #4 above)."
                  />
              }
              footer={
                <EuiText>
                    <p>
                      Stecken Sie das Netzwerkkabel in den Anschluss im hinteren Teil der Kamera (s. <Link to="/Indoor_Cameras/IN-6012_HD/Product_Features/">#12</Link>) um eine LAN Verbindung aufzubauen (auch für WLAN benötigt, wenn kein WPS vorhanden - s. #4 oben).
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect06}
                    alt="Connect the cameras network cable to a free LAN port on your internet router. You can skip this step if you just need a WiFi connection."
                  />
              }
              footer={
                <EuiText>
                    <p>
                      Stecken Sie das andere Ende des Netzwerkkabels in einen freien LAN Port Ihres Internet Routers.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect07}
                    alt="Start our IP Camera Tool from the Software CD and double click on the name of the camera."
                  />
              }
              footer={
                <EuiText>
                    <p>
                      Starten Sie das <a href="https://install.instar.com/en/optional_tool_en.html" target="_blank" rel="noopener noreferrer">IP Camera Tool</a> von der <a href="https://install.instar.com" target="_blank" rel="noopener noreferrer">Software CD</a> und führen Sie einen Doppelklick auf den Namen der Kamera aus.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect08}
                    alt="Default Logins User/Password: admin / instar for the administrator, user / instar for normal users and guest / instar for restricted users."
                  />
              }
              footer={
                <EuiText>
                    <p>
                      Standard Logins Benutzer/Kennwort: <strong>admin / instar</strong> für Administratoren, <strong>user / instar</strong> für normale Nutzer und <strong>guest / instar</strong> für Gäste.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect09}
                    alt="Activate the cameras WiFi by using the WiFi scanner in the camera user interface. After typing in your WiFi password and submitting - please unplug the LAN cable and restart the camera."
                  />
              }
              footer={
                <EuiText>
                    <p>
                      Aktivieren Sie das WLAN der Kamera in der Weboberfläche mittels des WLAN- Scanners. Nach der Eingabe und Speicherung des WLAN Passwortes muss das LAN Kabel gezogen und die Kamera neugestartet werden.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect10}
                    alt="In case you are not able to see a video when accessing the camera via Firefox, Chrome, etc. under Windows, open the Quicktime Player preferences and set the protocol to HTTP and the port to 80."
                  />
              }
              footer={
                <EuiText>
                    <p>
                      Wenn Sie unter Windows mit dem QuickTime Plugin kein Video im Firefox, Chrome oder Safari unter Windows sehen, gehen Sie bitte in die Einstellungen Ihres QuickTime Players und stellen Sie das Protokoll auf HTTP und den Port auf 80.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect11}
                    alt="To set the Quicktime HTTP port in MacOSX please open the Terminal in Applications/Utilities and type in the command qtdefaults write TransportSettings HTTP 80 to set the port to 80."
                  />
              }
              footer={
                <EuiText>
                    <p>
                      Unter macOSX öffnen Sie bitte das Terminal im Ordner Programme/Dienstprogramme. Geben Sie den Befehl <code>qtdefaults write TransportSettings "HTTP" "80"</code> ein um den HTTP Port auf 80 zu setzen.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect12}
                    alt="To access your camera over the internet, you have to forward the HTTP Port inside your router (default “80”). To access the Flash Videostream inside the WebUI, you also have to forward the RTMP Port (default 1935) -> see also 14 below"
                  />
              }
              footer={
                <EuiText>
                    <p>
                      Für den Zugriff über das Internet ist eine Portweiterleitung des HTTP Ports (Standard “80”) in Ihrem Router notwendig. Für den Zugriff auf den <Link to="/Web_User_Interface/720p_Series/Multimedia/Video/">Flash Videostream</Link> (z.B. über das Flash Plugin im Webbrowser) benötigen Sie noch die Weiterleitung des <Link to="/Web_User_Interface/720p_Series/Network/IP_Config/">RTMP Ports</Link> (Standard “1935”). siehe auch 14 unten
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect13}
                    alt="Now you can enter your camera´s DDNS information to our free Windows Phone, Windows Metro, Android, iPhone, iPad or Blackberry App."
                  />
              }
              footer={
                <EuiText>
                    <p>
                      Jetzt können Sie die DDNS-Informationen Ihrer Kamera in unsere <Link to="/Software/Windows/InstarVision/Windows_Phone/DDNS/">Windows Phone</Link>, <Link to="/Software/Windows/InstarVision/Metro/DDNS/">Windows Metro</Link>, <Link to="/Software/Android/InstarVision/DDNS/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/DDNS/">iPhone</Link>, <Link to="/Software/iOS/InstarVision/iPad/DDNS/">iPad</Link> oder <Link to="/Software/Other_Platforms/InstarVision_Blackberry/">Blackberry</Link> App eingeben.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect14}
                    alt="If you cannot open a port for camera´s DDNS service, you can still use the Point-2-Point Service instead with our Windows Phone, Windows Metro, Android, iPhone, iPad or Blackberry App."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Wenn Sie keinen Port für den DDNS-Dienst der Kamera öffnen können, können Sie weiterhin den <Link to="/Web_User_Interface/1080p_Series/Network/Internet_Access/">Point-2-Point Dienst</Link> mit unserer <Link to="/Software/Windows/InstarVision/Windows_Phone/P2P/">Windows Phone</Link>, <Link to="/Software/Windows/InstarVision/Metro/P2P/">Windows Metro</Link>, <Link to="/Software/Android/InstarVision/P2P/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/P2P/">iPhone</Link>, <Link to="/Software/iOS/InstarVision/iPad/P2P/">iPad</Link> oder <Link to="/Software/Other_Platforms/InstarVision_Blackberry/">Blackberry</Link> App nutzen.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
