import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBoxInfo() {
  return (
    <EuiCallOut size="m" iconType="pin" title="How to adjust the motion detection areas?">
        <EuiText>
          <h3>Desktop View</h3>
          <ul>
              <li><strong>Moving Nodes:</strong> Click on an existing node, hold and move your mouse to change it's position.</li>
              <li><strong>Adding Nodes:</strong> Hold the <strong>SHIFT</strong> key and click on the connecting line between two nodes to add up to a <strong>total of 32 nodes</strong> in-between.</li>
              <li><strong>Removing Nodes:</strong> Hold the <strong>SHIFT</strong> key and click on an existing node to remove it.</li>
          </ul>
          <h3>Mobile View</h3>
          <ul>
              <li><strong>Moving Nodes:</strong> Touch an existing node and move it to change it's position. If you activated the <em>+</em> or <em>-</em> button (<em>see below</em>) you first have to toggle it back into normal mode.</li>
              <li><strong>Adding Nodes:</strong> Activate the `+` button underneath the snapshot and swipe into the active area crossing the connecting line between two nodes to add another node in-between.</li>
              <li><strong>Removing Nodes:</strong> Activate the <em>-</em> button underneath the snapshot, touch an existing node and drag it to the edge of the canvas to remove it.</li>
          </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBoxInfo

