import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/Alarm_FTP_Upload/NavButtons';
import ForumBox from 'components/Motion_Detection/Alarm_FTP_Upload/ForumBox';
import ConfigTable from 'components/Motion_Detection/Alarm_FTP_Upload/720p_Series/ConfigTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiButton,
  EuiSpacer,
  NavButtons,
  ForumBox,
  ConfigTable,
  BreadCrumbs,
  SEOHelmet,
  React
};