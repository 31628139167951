import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Beachten Sie bitte:">
        <EuiText>
          <p>Zu diesem Zeitpunkt werden nur die Geräte <u>Alexa Echo</u> und <u>Alexa Echo Dot Gen 1 & 2</u> unterstützt. Die Einbindung eines Echo Gen 2, Echo Dot Gen 3 & Echo Plus ist z.Z. noch nicht möglich. Die Geräte Echo Show, Echo Spot und Sonos One können keine lokalen Geräte erkennen.</p>
          <p><u>Für dieses Tutorial wurde ein Echo Dot der Generation 2 verwendet</u>.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

