import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import mqttHTML5CardIcon from '../../../images/Advanced_User/Website_Integration/Website_Integration_HTML.webp'
import mqttJavaCardIcon from '../../../images/Advanced_User/Website_Integration/Website_Integration_Java.webp'
import mqttPhpCardIcon from '../../../images/Advanced_User/Website_Integration/Website_Integration_PHP.webp'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Website_Integration/WS_Stream_WQHD_Cameras/">
                  <img
                    src={mqttHTML5CardIcon}
                    alt="Websocket Videostream (WQHD 2k+ Cameras)"
                  />
                </Link>
              }
              title="Websocket Videostream (WQHD 2k+ Cameras)"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Website_Integration/HTML5_Stream_Full_HD_Cameras/">
                  <img
                    src={mqttHTML5CardIcon}
                    alt="HTML5 Videostream (Full HD Cameras)"
                  />
                </Link>
              }
              title="HTML5 Videostream (Full HD Cameras)"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Website_Integration/MJPEG_Stream_WQHD_Cameras/">
                  <img
                    src={mqttHTML5CardIcon}
                    alt="MJPEG Videostream (WQHD 2k+ Cameras)"
                  />
                </Link>
              }
              title="MJPEG Videostream (WQHD 2k+ Cameras)"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Website_Integration/HD_Camera_Integration/#adding-the-mjpeg-videostream">
                  <img
                    src={mqttHTML5CardIcon}
                    alt="MJPEG Videostream (Full HD & HD Cameras)"
                  />
                </Link>
              }
              title="MJPEG Videostream (Full HD & HD Cameras)"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Website_Integration/Cambozola/">
                  <img
                    src={mqttJavaCardIcon}
                    alt="Cambozola"
                  />
                </Link>
              }
              title="Cambozola Java Plugin (VGA Cameras)"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/Website_Integration/Open_IP_Camera_Manager/">
                  <img
                    src={mqttPhpCardIcon}
                    alt="Open IP Camera Manager (VGA Cameras)"
                  />
                </Link>
              }
              title="PHP Skripte"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
