import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import Table01 from 'components/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/Troubleshooting/Table01';
import Table02 from 'components/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/Troubleshooting/Table02';
import Table03 from 'components/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/Troubleshooting/Table03';
import Table04 from 'components/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/Troubleshooting/Table04';
import Table05 from 'components/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/Troubleshooting/Table05';
import Table06 from 'components/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/Troubleshooting/Table06';
import Table07 from 'components/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/Troubleshooting/Table07';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  NavButtons,
  ForumBox,
  Table01,
  Table02,
  Table03,
  Table04,
  Table05,
  Table06,
  Table07,
  BreadCrumbs,
  SEOHelmet,
  React
};