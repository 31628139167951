import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class LEDTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "featureColumn", title: "IR Spotlight Model" },
        { name: "value850Column", title: "INSTAR IN-905" },
        { name: "value940Column", title: "INSTAR IN-905 invisible" }
      ],
      rows: [
        { featureColumn: 'Available Colours', value850Column: 'Black / White', value940Column: 'Black / White' },
        { featureColumn: 'Illumination Angle', value850Column: '60 °', value940Column: '60 °' },
        { featureColumn: 'Range', value850Column: '20 - 30 m', value940Column: '5 - 10 m' },
        { featureColumn: 'Number of LEDs', value850Column: '60 GaAlAs LEDs', value940Column: '60 GaAs LEDs' },
        { featureColumn: 'Wavelength', value850Column: '850 nm', value940Column: '940 nm' },
        { featureColumn: 'Output /LED', value850Column: '0.2 Watt', value940Column: '0.2 Watt' },
        { featureColumn: 'Power Supply', value850Column: '110 V - 220 V (50 Hz - 60 Hz)', value940Column: '110 V - 220 V (50 Hz - 60 Hz)' },
        { featureColumn: 'Power Consumption', value850Column: '10 Watt (max.)', value940Column: '10 Watt (max.)' },
        { featureColumn: 'Weight', value850Column: '400 g', value940Column: '400 g' },
        { featureColumn: 'Measurements H/W/D', value850Column: '17 cm / 15.5 cm / 12 cm', value940Column: '17 cm / 15.5 cm / 12 cm' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

LEDTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(LEDTable)