import React from 'react'

import {
  EuiCard,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import ipvImage08 from "../../../../images/Software/iOS/IP_Vision_Pro/IP_Vision_Pro_08.webp"
import ipvImage09 from "../../../../images/Software/iOS/IP_Vision_Pro/IP_Vision_Pro_09.webp"
import ipvImage10 from "../../../../images/Software/iOS/IP_Vision_Pro/IP_Vision_Pro_10.webp"




export default function softwareArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ipvImage08}
                    alt="IP Vision Pro for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p><strong>Fig8.</strong> Click on your camera´s live video to access advanced functions.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ipvImage09}
                    alt="IP Vision Pro for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p><strong>Fig9.</strong> Activate a video or snapshot recording to your local storage by pressing the corresponding buttons.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={ipvImage10}
                    alt="IP Vision Pro for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p><strong>Fig10.</strong> The red dot signals that a video recording is in progress terminate it by clicking the recording button a second time.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
