import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="* Eclipse Mosquitto">
        <EuiText>
          <p>
            Die INSTAR MQTT-Schnittstelle baut auf dem <OutboundLink href="https://mosquitto.org" target="_blank" rel="noreferrer">Eclipse Mosquitto</OutboundLink> Open Source MQTT Broker auf. Der Quellcode kann <OutboundLink href="https://github.com/eclipse/mosquitto" target="_blank" rel="noreferrer">von Github heruntergeladen werden</OutboundLink> und ist unter der <OutboundLink href="http://www.eclipse.org/legal/epl-v20.html" target="_blank" rel="noreferrer">Eclipse Public License 2.0</OutboundLink> verfügbar.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

