import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "ledColumn", title: "Mode" },
        { name: "blueColumn", title: "Blue LED" },
        { name: "redColumn", title: "Red LED" },
      ],
      rows: [
        { ledColumn: 'Constant on', blueColumn: 'Connected to WiFi', redColumn: 'Connected to WiFi' },
        { ledColumn: 'Slow Flashing', blueColumn: 'Connecting via WPS', redColumn: 'Connecting via WPS' },
        { ledColumn: 'Fast Flashing', blueColumn: 'N/A', redColumn: 'N/A' },
        { ledColumn: 'Off', blueColumn: 'LED is disabled or Camera has no Power', redColumn: 'LED is disabled or Camera has no Power' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)



// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'

// const ledRow = [
//   "Constant on",
//   "Slow Flashing",
//   "Fast Flashing",
//   "Off"
// ];

// const blueRow = [
//   "Connected to WiFi",
//   "Connecting via WPS",
//   "N/A",
//   "LED is disabled or Camera has no Power"
// ];

// const redRow = [
//   "N/A",
//   "Camera Operational",
//   "Upgrading Firmware",
//   "Integrated passive infrared (PIR) motion detector"
// ];

// // Render your table
// const StatusTable = () => {
//     return (
//       <div style={{ padding: '15px',maxWidth: '1115px', margin: '2.5rem 0 2.5rem 0', boxShadow: '0 2px 3px 0 rgb(0 0 0 / 36%' }}>
//         <Table
//           rowHeight={56}
//           rowsCount={ledRow.length}
//           touchScrollEnabled={true}
//           width={850}
//           height={275}
//           headerHeight={50}>
//           <Column
//             header={<Cell>Status</Cell>}
//             cell={({rowIndex, ...props}) => (
//               <Cell {...props}>
//                 {ledRow[rowIndex]}
//               </Cell>
//             )}
//             width={200}
//           />
//           <Column
//             header={<Cell>Blue LED</Cell>}
//             cell={({rowIndex, ...props}) => (
//               <Cell {...props}>
//                 {blueRow[rowIndex]}
//               </Cell>
//             )}
//             width={320}
//           />
//           <Column
//             header={<Cell>Red LED</Cell>}
//             cell={({rowIndex, ...props}) => (
//               <Cell {...props}>
//                 {redRow[rowIndex]}
//               </Cell>
//             )}
//             width={325}
//           />
//         </Table>
//       </div>
//     )
// }

// export default StatusTable