import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/WPS/QI-P2P_Tile_9008_Step_06.webp"
import qiSelect02 from "../../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/WPS/Quickinstallation_Tile_9008_Step_10.webp"
import qiSelect03 from "../../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/WPS/Quickinstallation_Tile_9008_Step_11.webp"
import qiSelect04 from "../../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/WPS/Quickinstallation_Tile_9008_Step_12.webp"




export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='II-e-2 Router WPS'
              image={
                  <img
                    src={qiSelect01}
                    alt="Activate your routers WPS mode at the same time you activated it on your camera. Please refer to your routers user manual about it WPS function."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Activate your routers WPS mode at the same time you activated it on your camera. Please refer to your routers user manual about it WPS function.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='II-e-3 Camera Tool'
              image={
                  <img
                    src={qiSelect02}
                    alt="Start our IP Camera Tool from the Software CD and double click on the name of the camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Start our <OutboundLink href="https://install.instar.com/WQHDv2/IN-9408_WQHD_Installation/01_LAN_Integration/03_Find_your_Camera">IP Camera Tool</OutboundLink> from the Software CD and double click on the name of the camera</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='II-e-4 User Login'
              image={
                  <img
                    src={qiSelect03}
                    alt="The default administrator login is User/Password: admin / instar. Our Installation Wizard will lead you through the rest of the installation process."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">The default administrator login is User/Password: <strong>admin / instar</strong>. Our <OutboundLink href="http://install.instar.de/">Installation Wizard</OutboundLink> will lead you through the rest of the installation process.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='II-e-5 Video Plugin'
              image={
                  <img
                    src={qiSelect04}
                    alt="You need to install the Adobe Flash Plugin to display your cameras h.264 video stream. You can use to HTML5 Stream without the need to install a Browser plugins on your machine."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">You <strong>do not</strong> need to install the <Link to="/Quick_Installation/Live_Video/">Adobe Flash Plugin</Link> to display your cameras h.264 video stream. And you can also use to HTML5 Stream without the need to install a Browser plugins on your machine.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
