import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Important note!">
        <EuiText>
            <p>It is important that the Private RSA Key or the Private Key you uploaded inside the <Link to="/Web_User_Interface/1440p_Series/Network/SSL_Certificate/">SSL Certificates Menu</Link> matches the <code>X.509</code> certificate. If the HASH values do not match, the camera will not be able to verify the public key from connecting clients and services like the <Link to="/Advanced_User/INSTAR_MQTT_Broker/">INSTAR MQTT Broker</Link> will have to be operated in <code>insecure</code> mode.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox
