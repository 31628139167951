import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Troubleshooting',
      href: '/en/Motion_Detection/Alarm_Notification/Troubleshooting/',
    },
    {
      text: 'SMTP Servers',
      href: '/en/Motion_Detection/Alarm_Notification/SMTP_Servers/',
    },
    {
      text: 'VGA Series',
      href: '/en/Motion_Detection/Alarm_Notification/VGA_Series/',
    },
    {
      text: '720p Series',
      href: '/en/Motion_Detection/Alarm_Notification/720p_Series/',
    },
    {
      text: '1080p Series',
      href: '/en/Motion_Detection/Alarm_Notification/',
    },
    {
      text: '◄ Motion Detection',
      href: '/en/Motion_Detection/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={6}
        aria-label="Alarm Notification"
      />
      <EuiSpacer />
    </Fragment>
  );
}