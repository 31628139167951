import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Clear your Browser Cache after an Update">
        <EuiText>
          <p>Sometimes your camera´s WebUI might not behave the way you want. You updated your camera´s software, but it still shows you the old version ? An update should bring new functions to the user interface - but they are nowhere to be seen? Or you simply cannot change a setting? Your Browser might have cached old parts of the user interface and refuses to reload the new or updated files. <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/">Here</Link> is how you can get rid of your browsers browsing history in several common browsers.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

