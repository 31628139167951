import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class AudioAlarmTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Alarm Areas" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Audio alarm by acoustic signal', descriptionColumn: 'If your camera has an internal microphone - or you have connected an external microphone to your cameras audio-in - you can use it to trigger alarm actions in case of a noise in front of the camera. This might be useful, if you want to use your camera as a baby phone. The audio alarm can trigger all functions that you activated in the Alarm Actions Menu as well as the Push Notification.' },
        { actionColumn: 'Audio alarm sensitivity', descriptionColumn: 'Adjust the sensitivity of the audio detection here. Please be aware that the sensitivity also depends on the signal amplification of the Audio Input.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

AudioAlarmTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(AudioAlarmTable)