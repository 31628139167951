import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Better go Manual">
        <EuiText>
          <p>In case you are using a manual port forwarding rule in your router to access your camera from the internet, make sure the cameras UPnP service is deactivated! We recommend setting up a manual port forwarding.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

