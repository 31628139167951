import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'IN-5905 HD',
      href: '/en/Downloads/Outdoor_Cameras/IN-5905_HD/',
    },
    {
      text: 'IN-5907 HD',
      href: '/en/Downloads/Outdoor_Cameras/IN-5907_HD/',
    },
    {
      text: 'IN-7011 HD',
      href: '/en/Downloads/Outdoor_Cameras/IN-7011_HD/',
    },
    {
      text: 'IN-9008 FHD',
      href: '/en/Downloads/Outdoor_Cameras/IN-9008_HD/',
    },
    {
      text: 'IN-9010 FHD',
      href: '/en/Downloads/Outdoor_Cameras/IN-9010_HD/',
    },
    {
      text: 'IN-9020 FHD',
      href: '/en/Downloads/Outdoor_Cameras/IN-9020_HD/',
    },
    {
      text: 'IN-9408 WQHD',
      href: '/en/Downloads/Outdoor_Cameras/IN-9408_WQHD/',
    },
    {
      text: 'Outdoor Cameras',
      href: '/en/Downloads/Outdoor_Cameras/',
    },
    {
      text: '◄ Downloads',
      href: '/en/Downloads/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Downloadbereich for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}