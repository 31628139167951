import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Updates:">
        <EuiText>
          <ul>
              <li><Link to="/Advanced_User/OpenHAB_3_in_Docker/">OpenHAB 3 Installation in Docker</Link></li>
              <li><Link to="/Advanced_User/OpenHAB_3_Camera_Binding/">OpenHAB 3 Camera Binding</Link></li>
              <li><Link to="/Advanced_User/OpenHAB_3_Camera_Widget/">OpenHAB 3 Camera Widget</Link></li>
              <li><Link to="/Advanced_User/OpenHAB_3_MQTT_Binding/">OpenHAB 3 MQTT Binding</Link></li>
          </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

