import React from "react";
import { Link } from "gatsby";

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
} from "@elastic/eui";

import softwareMacOSEvoCamSearchIcon from "../../../images/Search/P_SearchThumb_Evocam.webp";
import softwareMacOSSecuritySpySearchIcon from "../../../images/Search/P_SearchThumb_SecuritySpy.webp";
import softwareMacOSSighthoundSearchIcon from "../../../images/Search/P_SearchThumb_Sighthound.webp";
import softwareMacOSVLCSearchIcon from "../../../images/Search/P_SearchThumb_VLC.webp";
import softwareMacOSInstarVisionSearchIcon from "../../../images/Search/P_SearchThumb_InstarVision_Windows.webp";

export default function WebUIArticles() {
  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row">
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/macOS/Sighthound/">
                  <img
                    src={softwareMacOSSighthoundSearchIcon}
                    alt="Sighthound"
                  />
                </Link>
              }
              title="Sighthound"
              description="The macOS app Sighthound for your iMac or macBook. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/macOS/Sighthound/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/macOS/VLC_Player/">
                  <img src={softwareMacOSVLCSearchIcon} alt="VLC Player" />
                </Link>
              }
              title="VLC Player"
              description="The macOS app VLC Player for your iMac or macBook. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/macOS/VLC_Player/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/macOS/SecuritySpy/">
                  <img
                    src={softwareMacOSSecuritySpySearchIcon}
                    alt="SecuritySpy"
                  />
                </Link>
              }
              title="SecuritySpy"
              description="The macOS app SecuritySpy for your iMac or macBook. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/macOS/SecuritySpy/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row">
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/macOS/EvoCam/">
                  <img src={softwareMacOSEvoCamSearchIcon} alt="EvoCam" />
                </Link>
              }
              title="EvoCam"
              description="The macOS app EvoCam for your iMac or macBook. Control your camera from your computer and harness the computing power for advanced features."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/macOS/EvoCam/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/macOS/InstarVision/">
                  <img
                    src={softwareMacOSInstarVisionSearchIcon}
                    alt="InstarVision 3.0"
                  />
                </Link>
              }
              title="InstarVision 3.0"
              description="Die Windows App InstarVision für Ihren PC oder Laptop. Steuern Sie Ihre Kamera von Ihrem Computer aus und nutzen Sie die Rechenleistung für erweiterte..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/macOS/InstarVision/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem></EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
