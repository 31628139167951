import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Panasonic PIR Installation">
        <EuiText>
            <p>PaPIRs are pyroelectric infrared sensors that detect variations in infrared rays. However, detection may not be successful in the following cases: lack of movement or no temperature change in the heat source. They could also detect the presence of heat sources other than a human body. Efficiency and reliability of the system may vary depending on the actual operating conditions:</p>
            <ul>
              <li>Detecting heat sources other than the human body, such as:</li>
              <ul>
                <li>small animals entering the detection area</li>
                <li>When a heat source, for example sun light, incandescent lamp, car headlights etc, or strong light beam hit the sensor regardless whether the detection area is inside or outside</li>
                <li>Sudden temperature change inside or around the detection area caused by hot or cold wind from HVAC, or vapor from a humidifier, etc.</li>
              </ul>
              <li>Difficulty in sensing the heat source</li>
              <ul>
                <li>Glass, acrylic or similar materials standing between the target and the sensor may not allow a correct transmission of infrared rays</li>
                <li>Non-movement or quick movements of the heat source inside the detection area</li>
              </ul>
              <li>Expansion of the detection area: In case of a considerable difference in the ambient temperature and the human body temperature, the detection area may be larger than the configured detection area</li>
              <li>Detection distance: Panasonic's PIR Motion sensors state the detection distance in the specifications because they are usually provided with the lense. The PIR Motion sensor could detect variations in infrared rays however such variations are decided by following three factors</li>
              <ul>
                <li>The temperature difference between the target and the surroundings: The larger the temperature difference, the easier it is to detect targets</li>
                <li>Movement speed: If the target is moving at a slower or faster speed than specified in the tables, the detection ability may be lower</li>
                <li>Target size: The human body is the standard. If the target is smaller or larger than specified in the table, the detection ability may be lower</li>
              </ul>
              <li>The detection distance explained in our data sheet is defined by the three factors mentioned above. Panasonic's standard for the temperature difference between the target and the surrounding is defined as 4°C. The larger the temperature difference, the longer the detection distance. If the temperature difference is 8°C, which is twice as much as standard, the detection distance will be approx. 1.4 times longer than the distance at 4°C. For example, if targets at a distance of 5m can be detected at 4°C, then the sensor can detect targets at a distance of 7m at 8°C. (This is based on the theory that the detection sensitivity will vary inversely with the square of the distance.)</li>
              <li>Lense material and the plate setting in front of the lense: Typically, the only material that can be passed by infrared rays is Polyethylene. (The lense material of Panasonic's PIR Motion sensors is "High density polyethylene, HDPE".) When you need to set a plate in front of the lense, please choose one made from the Polyethylene. Please note the thickness or color of the plate will affect the detection ability, e.g. it may make the detection distance shorter. Therefore, please confirm by testing the sensor with the plate under realistic conditions.</li>
            </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

