import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import YoutubeVideoAVM from 'components/Quick_Installation/Set_Up_A_Wireless_Connection/WPS_Plug_and_Play/VideoWPSAVM';
import YoutubeVideoAsus from 'components/Quick_Installation/Set_Up_A_Wireless_Connection/WPS_Plug_and_Play/VideoWPSAsus';
import YoutubeVideoNetgear from 'components/Quick_Installation/Set_Up_A_Wireless_Connection/WPS_Plug_and_Play/VideoWPSNetgear';
import YoutubeVideoLinksys from 'components/Quick_Installation/Set_Up_A_Wireless_Connection/WPS_Plug_and_Play/VideoWPSLinksys';
import YoutubeVideoDLink from 'components/Quick_Installation/Set_Up_A_Wireless_Connection/WPS_Plug_and_Play/VideoWPSDLink';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Set_Up_A_Wireless_Connection/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  YoutubeVideoAVM,
  YoutubeVideoAsus,
  YoutubeVideoNetgear,
  YoutubeVideoLinksys,
  YoutubeVideoDLink,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};