import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class MQTTTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "MQTT Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Activate MQTT', descriptionColumn: 'The MQTT service is deactivated by default. Use this switch to start the broker.' },
        { actionColumn: 'Enable MQTT over Websocket (WS)', descriptionColumn: 'Enable tunneling the MQTT service through a webbrowser-conform websocket connection.' },
        { actionColumn: 'Use external MQTT Broker', descriptionColumn: 'By default the internal `127.0.0.1` broker is used. If you want to connect your camera to a different broker activate the option here and type in it`s address below.' },
        { actionColumn: 'MQTT Server address', descriptionColumn: 'Leave this value at `127.0.0.1` to use the internal broker. When you activated the option to use an external broker type in it`s address here.' },
        { actionColumn: 'MQTT Server Port', descriptionColumn: 'This is the port a MQTT client can use to connect to your broker without TLS encryption. The default value is `1883`' },
        { actionColumn: 'WS Server Port', descriptionColumn: 'This is the port a MQTT client can use to connect to your broker without TLS encryption through a websocket tunnel. The default value is `1885`.' },
        { actionColumn: 'MQTT Server Port (TLS)', descriptionColumn: 'This is the port a MQTT client can use to connect to your broker with TLS encryption. The default value is `8883`' },
        { actionColumn: 'WSS Server Port (TLS)', descriptionColumn: 'This is the port a MQTT client can use to connect to your broker with TLS encryption through a websocket tunnel. The default value is `8885`' },
        { actionColumn: 'Verify Client TLS Certificate', descriptionColumn: 'Verify that all connecting clients use a valid CA TLS certificate. Don`t allow connection if verification fails. If you want to use your cameras INSTAR TLS certificate make sure to use the INSTAR DDNS address to connect instead of your clients local IP address.' },
        { actionColumn: 'Quality-of-Service (QoS)', descriptionColumn: 'Messages are send fire-and-forget (QoS=0), send and re-send until they were received (QoS=1, default) or send until received and ensured that they are only received once (QoS=2, only for time series data)' },
        { actionColumn: 'Authentication', descriptionColumn: 'Authentication has to be active, if you are using your camera as broker. If you already use an external broker set up without user authentication you can deactivate it here.' },
        { actionColumn: 'Username', descriptionColumn: 'Type in the username you want to use to access the MQTT broker.' },
        { actionColumn: 'Password', descriptionColumn: 'Add the corresponding password here.' },
        { actionColumn: 'Test', descriptionColumn: 'Test the MQTT Alarmserver. The alarmserver, e.g. your smarthome system, will have to have a subscription for "alarm/triggered" and "alarm/triggered/object".' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

MQTTTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(MQTTTable)