import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Cloud',
      href: '/en/Motion_Detection/INSTAR_Cloud/',
    },
    {
      text: 'FTP Server',
      href: '/en/Motion_Detection/Router_as_a_FTP_Server/',
    },
    {
      text: 'Alarmserver',
      href: '/en/Motion_Detection/Alarm_Server/',
    },
    {
      text: 'FTP Upload',
      href: '/en/Motion_Detection/Alarm_FTP_Upload/',
    },
    {
      text: 'SD Card',
      href: '/en/Motion_Detection/SD_Card_Access/',
    },
    {
      text: 'Notification',
      href: '/en/Motion_Detection/Alarm_Notification/',
    },
    {
      text: 'Setup',
      href: '/en/Motion_Detection/Setup/',
    },
    {
      text: '◄ Motion Detection',
      href: '/en/Motion_Detection/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Cloud Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}