import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../images/Indoor_Cameras/IN-6001_HD/Point2Point/WPS-P2P_IN-6001_01.webp"
import qiSelect02 from "../../../../images/Indoor_Cameras/IN-6001_HD/Point2Point/WPS-P2P_IN-6001_02.webp"
import qiSelect03 from "../../../../images/Indoor_Cameras/IN-6001_HD/Point2Point/WPS-P2P_IN-6001_03.webp"
import qiSelect04 from "../../../../images/Indoor_Cameras/IN-6001_HD/Point2Point/WPS-P2P_IN-6001_04.webp"
import qiSelect05 from "../../../../images/Indoor_Cameras/IN-6001_HD/Point2Point/WPS-P2P_IN-6001_05.webp"
import qiSelect06 from "../../../../images/Indoor_Cameras/IN-6001_HD/Point2Point/WPS-P2P_IN-6001_06.webp"
import qiSelect07 from "../../../../images/Indoor_Cameras/IN-6001_HD/Point2Point/WPS-P2P_IN-6001_07.webp"
import qiSelect08 from "../../../../images/Indoor_Cameras/IN-6001_HD/Point2Point/WPS-P2P_08.webp"
import qiSelect09 from "../../../../images/Indoor_Cameras/IN-6001_HD/Point2Point/WPS-P2P_IN-6001_09.webp"



export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect01}
                    alt="Verbinden Sie das Netzteil mit dem Anschluss am Kabelbaum der Kamera um Ihre Kamera zu starten."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Verbinden Sie das Netzteil mit dem Anschluss am Kabelbaum der Kamera um Ihre Kamera zu starten.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect02}
                    alt="Wenn Ihr Router eine WPS (Wireless Protected Setup) Funktion hat, können Sie auch die WPS Taste am Router drücken (siehe Beispiele oben)."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Wenn Ihr Router eine WPS (Wireless Protected Setup) Funktion hat, können Sie auch die WPS Taste am Router drücken (siehe Beispiele oben).
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect03}
                    alt="Drücken Sie die Reset-Taste der Kamera 3 Sekunden lang, um den WPS-Dienst der Kamera zu aktivieren (#13). Warte auf max. 5 Minuten, damit sich die Kamera mit Ihrem WLAN-Netzwerk verbinden kann. Wenn Sie 10 Sekunden drücken, wird die Kamera auf die Werkseinstellungen zurückgesetzt!"
                  />
              }
              footer={
                <EuiText>
                    <p>
                      Drücken Sie die Reset-Taste der Kamera 3 Sekunden lang, um den WPS-Dienst der Kamera zu aktivieren (<Link to="/Indoor_Cameras/IN-6001_HD/Product_Features/">#13</Link>). Warte auf max. 5 Minuten, damit sich die Kamera mit Ihrem WLAN-Netzwerk verbinden kann. Wenn Sie 10 Sekunden drücken, wird die Kamera auf die Werkseinstellungen zurückgesetzt!
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect04}
                    alt="Start our Android, iOS or Windows Phone app Instar Vision. Choose to add a new p2p camera and start the QR code scanner. Your QR code is located on a label on the camera case (s. above)."
                  />
              }
              footer={
                <EuiText>
                    <p>
                      Starten Sie unsere <Link to="/Software/Android/InstarVision/P2P/">Android</Link>, <Link to="/Software/iOS/InstarVision/iPhone/P2P/">iOS</Link> oder <Link to="/Software/Windows/InstarVision/Windows_Phone/P2P/">Windows Phone</Link> app Instar Vision. Wählen Sie das Hinzufügen einer neuen p2p Kamera und starten Sie den QR Code Scanner. Lokalisieren Sie den QR Code auf dem Kameragehäuse.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect05}
                    alt="Der QR Code beinhaltet alle Informationen zum Einbinden der Kamera. Bitte beachten Sie, dass hierbei die Standard Login Daten verwendet werden. Es ist besser, diese zu ändern und den QR CODE in der Weboberfläche zu scannen."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Der QR Code beinhaltet alle Informationen zum Einbinden der Kamera. Bitte beachten Sie, dass hierbei die <Link to="/Web_User_Interface/720p_Series/System/User/">Standard Login Daten</Link> verwendet werden. Es ist besser, diese zu ändern und den <Link to="/Web_User_Interface/720p_Series/Network/P2P/">QR CODE</Link> in der Weboberfläche zu scannen.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect06}
                    alt="Das Standard Kennwort kann nur über die Weboberfläche der Kamera geändert werden. Der QR Code in der Weboberfläche beinhaltet immer die aktuellen Daten."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Das Standard Kennwort kann nur über die Weboberfläche der Kamera geändert werden. Der QR Code in der Weboberfläche beinhaltet immer die aktuellen Daten.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect07}
                    alt="Es wird empfohlen, die Standardanmeldung für alle 3 Benutzerebenen zu ändern. Um dies zu tun, öffnen Sie das Benutzer Menü mit Ihrem Web Browser. Nutzen Sie unser INSTAR Camera Tool um Ihre Kamera in Ihrem Netzwerk zu finde."
                  />
              }
              footer={
                <EuiText>
                    <p>
                      Es wird empfohlen, die Standardanmeldung für alle 3 Benutzerebenen zu ändern. Um dies zu tun, öffnen Sie das <Link to="/Web_User_Interface/720p_Series/System/User/">Benutzer Menü</Link> mit Ihrem Web Browser. Nutzen Sie unser <a href="https://install.instar.com/WQHDv2/IN-9408_WQHD_Installation/01_LAN_Integration/03_Find_your_Camera" target="_blank" rel="noopener noreferrer">INSTAR Camera Tool</a> um Ihre Kamera in Ihrem Netzwerk zu finde.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect08}
                    alt="Die App wird jetzt den Live Stream der Kamera abgreifen können und Ihnen die Möglichkeit geben die Kamera zu schwenken, den Audiostream zu aktivieren und einen Schnappschuss zu speichern."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Die App wird jetzt den Live Stream der Kamera abgreifen können und Ihnen die Möglichkeit geben die Kamera zu schwenken, den Audiostream zu aktivieren und einen Schnappschuss zu speichern.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect09}
                    alt="Die p2p Funktion ist ein einfacher und schneller Weg sich mit der Kamera zu verbinden. Wenn Sie weitere Funktionen der Kamera nutzen möchten, lesen Sie bitte in der Schnellinstallation Anleitung weiter."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Die p2p Funktion ist ein einfacher und schneller Weg sich mit der Kamera zu verbinden. Wenn Sie weitere Funktionen der Kamera nutzen möchten, lesen Sie bitte in der <Link to="Innenkameras/IN-6001_HD/Quick_Installation/">Schnellinstallation Anleitung</Link> weiter.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
