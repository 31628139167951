import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/Point_to_Point/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_P2P.webp'
                    alt="P2P Access"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="P2P Fernzugriff"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/Point_to_Point/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/The_DDNS_Service/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_DDNSService.webp'
                    alt="INSTAR DDNS Service"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="DDNS Anbieter"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/The_DDNS_Service/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/Port_Forwarding/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_Portforwarding.webp'
                    alt="Port Porwarding"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Port Weiterleitung"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/Port_Forwarding/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/Mobile_Access/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_Mobile.webp'
                    alt="Mobile Access"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Mobiler Zugriff"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/Mobile_Access/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Internet_Access/DDNS_Provider/">
                  <StaticImage
                    src='../../images/Search/IA_SearchThumb_ThirdPartyDDNS.webp'
                    alt="Thirdparty DDNS Provider"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="DDNS Anbieter"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Internet_Access/DDNS_Provider/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
