import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Smarthome_Alarmserver.webp'
                    alt="INSTAR 1440p Web User Interface Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Server"
              description="Use your camera to notify your home automation system in case of a motion detection."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Smarthome/MQTT/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Smarthome_MQTT.webp'
                    alt="INSTAR 1440p Web User Interface Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="MQTT Broker"
              description="Configure the INSTAR MQTT broker - activate the server, or switch to an external broker. You can also configure the authentication and add your personal SSL certificate..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1440p_Series/Smarthome/MQTT/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Smarthome/Alexa_Skill/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Smarthome_Alexa.webp'
                    alt="INSTAR 1440p Web User Interface Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alexa Skill"
              description="We provide both an INSTAR Cloud Skill and an INSTAR Camera Skill for you in the Alexa Skill Store. For INSTAR Cloud Skill you only need an INSTAR Cloud account and an Alexa device with a display. Add the skill on your Alexa device and use it directly."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1440p_Series/Smarthome/Alexa_Skill/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Smarthome/IFTTT_Applets/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Smarthome_IFTTT.webp'
                    alt="INSTAR 1440p Web User Interface Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IFTTT Applets"
              description="On the IFTTT Platform, we provided a service named INSTAR. The INSTAR Applets provides a way for you to control some settings of your INSTAR Camera or INSTAR Cloud with IFTTT. You can connect INSTAR to Location and Date & Time triggers and receive Notifications and more. For example, you can connected the INSTAR Applets with the IFTTT Location service and to turn off the alarm when you arrive at home. You can also use the INSTAR Applets to build your own automation connecting them to other third-party Applets."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1440p_Series/Smarthome/IFTTT_Applets/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1440p_Series/Smarthome/Homekit/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1440p_SearchThumb_Smarthome_Homekit.webp'
                    alt="INSTAR 1440p Web User Interface Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="INSTAR Homekit"
              description="Use your INSTAR Full HD IP Camera in Apple Homekit. HomeKit is a software framework by Apple, made available in iOS/iPadOS that lets you communicate with, and control your camera using Apple devices. It provides you with a way to automatically discover your camera and access it`s live video."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1440p_Series/Smarthome/Homekit/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
