import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Indoor_Cameras/IN-8415_WQHD/Usermanual/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-8415WQHD.webp'
                    alt="IN-8415 WQHD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8415 WQHD"
              description="The IN-8415 2k+ WQHD is the new INSTAR indoor camera with the new 1440p chipset. And the first INSTAR indoor pan&tilt camera."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Indoor_Cameras/IN-8415_WQHD/Usermanual/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Indoor_Cameras/IN-8403_WQHD/Usermanual/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-8403WQHD.webp'
                    alt="IN-8403 WQHD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8403 WQHD"
              description="The IN-8403 2k+ WQHD is the new INSTAR indoor camera with the new 1440p chipset. And the first camera offering both 850nm IR and visible white light night vision."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Indoor_Cameras/IN-8403_WQHD/Usermanual/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Indoor_Cameras/IN-8401_WQHD/Usermanual/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-8401WQHD.webp'
                    alt="IN-8401 WQHD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8401 WQHD"
              description="The IN-8401 2k+ WQHD is the new INSTAR indoor camera with the new 1440p chipset. And the first camera offering both 850nm and 'invisible' 940nm IR night vision."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Indoor_Cameras/IN-8401_WQHD/Usermanual/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Indoor_Cameras/IN-8015_HD/Usermanual/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-8015HD.webp'
                    alt="IN-8015 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              } 
              title="IN-8015 Full HD"
              description="Die IN-8015 FHD ist die erste INSTAR Kamera mit dem neuen 1080p-Chipsatz. Sie bietet alle Vorteile der IN-6014 HD mit einer überarbeiteten Software und vieles mehr."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Indoor_Cameras/IN-8015_HD/Usermanual/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Indoor_Cameras/IN-8003_HD/Usermanual/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-8003HD.webp'
                    alt="IN-8003 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8003 Full HD"
              description="Die IN-8003 FHD ist die erste INSTAR-Kamera mit dem neuen 1080p-Chipsatz. Mit dem wetterfesten Gehäuse können Sie sie sowohl im Innen- als auch im Außenbereich installieren."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Indoor_Cameras/IN-8003_HD/Usermanual/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Indoor_Cameras/IN-8001_HD/Usermanual/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-8001HD.webp'
                    alt="IN-8001 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-8001 Full HD"
              description="Das IN-8001 FHD ist der Nachfolger des IN-6001 HD mit dem neuen 1080p-Chipsatz. Mit seinen unsichtbaren IR-LEDs ist er ideal für den Einsatz als Babyphone geeignet."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Indoor_Cameras/IN-8001_HD/Usermanual/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Indoor_Cameras/IN-6014_HD/Usermanual/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-6014HD.webp'
                    alt="IN-6014 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-6014 HD"
              description="Die IN-6014 HD bietet alle Vorteile der IN-6012 HD. Hat aber zusätzlich ein 80 ° Weitwinkelobjektiv und einen integrierten PIR-Bewegungsmelder."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Indoor_Cameras/IN-6014_HD/Usermanual/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Indoor_Cameras/IN-6012_HD/Usermanual/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-6012HD.webp'
                    alt="IN-6012 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-6012 HD"
              description="Die IN-6012HD ist die Standard-HD-PTZ-Kamera für die Innenraumüberwachung. Sie verfügt über ein Standardobjektiv mit 40 ° Betrachtungswinkel und IR-Nachtsicht."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Indoor_Cameras/IN-6012_HD/Usermanual/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Indoor_Cameras/IN-6001_HD/Usermanual/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-6001HD.webp'
                    alt="IN-6001 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-6001 HD"
              description="Der Nachfolger der VGA-Kamera IN-3001 - kompakt, unauffällig, IR-Nachtsicht-fähig mit einer Videoauflösung von 720p HD und integrierter SD-Karte."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Indoor_Cameras/IN-6001_HD/Usermanual/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                      More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Indoor_Cameras/IN-3011/Usermanual/">
                  <StaticImage
                    src='../../../images/Search/P_SearchThumb_IN-3011.webp'
                    alt="IN-3011"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-3011"
              description="Die IN-3011 ist die Standard-Innenkamera für die VGA-Überwachung. Sie verfügt über IR-Nachtsicht und einen, über unsere App, fernsteuerbaren Kamerakopf."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Indoor_Cameras/IN-3011/Usermanual/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
