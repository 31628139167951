import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Alarm Server">
        <EuiText>
          <p>
            The Google Home app Google Home app helps you set up and control Google Nest or Home speakers and displays, and Chromecast. You can control thousands of compatible lights, cameras, speakers, and more. You can also check your reminders and recent notifications, all from a single app. The Google Home app is available on Android phones and tablets, and iPhones and iPads.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

