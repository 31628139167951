import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import connectionPlan from "../../../images/Quick_Installation/Power_over_Ethernet/anschlussplan-poe8000-switch.webp"
import splitterPlan from "../../../images/Quick_Installation/Power_over_Ethernet/poe-splitter-12v.webp"

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <img
                  src={connectionPlan}
                  alt="Anschlussplan INSTAR IN-PoE8000 Switch"
                />
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <img
                  src={splitterPlan}
                  alt="Anschlussplan INSTAR 12V PoE Splitter Switch"
                />
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
