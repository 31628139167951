import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Alarm Schedule">
        <EuiText>
          <p>
            Here you can define a time schedule in which the motion detection should be active for the active detection areas. Every blue square represents 30 minutes of armed motion detection. Please make sure to set the correct time zone as it will be used by the camera.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

