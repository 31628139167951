import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Privacy Mask">
        <EuiText>
          <p>
            The Privacy Mask Menu allows you to black out areas from your surveillance. E.g. you can still see a part of the public street in front of your house on your surveillance videos. But you cannot adjust the camera angle any more. Now you can setup up to 4 masks to prevent the street from showing up in your surveillance footage.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

