import React from 'react'

import {
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  EuiImage
} from '@elastic/eui'

import lenseSpecsImage from 'images/Indoor_Cameras/IN-8015_HD/Technical_Specifications/4-2_Lense_Specifications.webp'

export default function FooterCards() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiPanel paddingSize="m">
              <EuiTitle size="m">
                <h3>[2] Lense Specifications</h3>
              </EuiTitle>
              <EuiImage
                allowFullScreen
                alt='Lense Specifications'
                src={lenseSpecsImage}
              />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
