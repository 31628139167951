import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="INSTAR MQTT Broker">
        <EuiText>
          <p>
            If you are using an INSTAR Full HD (1080p) camera <strong>you don not need to install</strong> an separate MQTT broker as your camera already comes with a <Link to="/Advanced_User/INSTAR_MQTT_Broker/">preinstalled INSTAR MQTT broker</Link>.<br/><br/>    
            If you want to use an INSTAR HD (720p) or VGA camera you can use the broker that can be installed with Home Assistant - as described below. To connect your HD or VGA camera to this broker you can use a tool like Node-RED - for which we have a <Link to="/Software/Linux/Node-RED/">detailed tutorial here</Link>.<br/><br/>
            Once you can access your camera's API through the MQTT Interface you are good to go to connect the MQTT side of it to Home Assistant. How this looks like is <Link to="/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/">described here for the INSTAR MQTT broker</Link> and works the same way, when you are using your own MQTT broker + Node-RED.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

