import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ActionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Alarm Action" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Enable Alarm', descriptionColumn: 'Enable or disable all alarm actions.' },
        { actionColumn: 'Activate internal PIR sensor', descriptionColumn: 'Activate the internal passive infrared sensor (PIR Motion Detector) to trigger an alert once a person enters the surveillance area (not available in all models). You can deactivate the Alarm Areas once the PIR sensor is active. But in most cases it is recommended to couple the Internal Motion Detection with the PIR sensor.' },
        { actionColumn: 'Link PIR and motion detect areas', descriptionColumn: 'You can also couple the Alarm Areas with the internal PIR sensor. Use this function to effectively screen out false alerts. Please be aware, that you have to at least activate one of the alarm areas to use this function! You also have to press both the Eye Symbol as well as the Burglar Symbol in our smartphone app to activate/deactivate the alarm.' },
        { actionColumn: 'Linked Areas', descriptionColumn: 'Select what areas should be coupled with the internal PIR sensor. Those areas will only be triggering alert when the PIR heat sensor is triggered as well. Areas that are not selected will continue to trigger independently.' },
        { actionColumn: 'Activate Alarm Input', descriptionColumn: 'Activate your camera´s alarm input (not all camera models). Information about connecting an external sensor can be found here. You can connect every sensor to your camera that is operated via a potential free contact.' },
        { actionColumn: 'Alarm Input Mode', descriptionColumn: 'Specify whether the alarm input expects an open (N.O.) or closed (N.C.) circuit in the normal state.' },
        { actionColumn: 'Sound trigger', descriptionColumn: 'Let your camera listen to noises. This is the ideal function to use your camera as a baby phone.' },
        { actionColumn: 'Detection sensitivity', descriptionColumn: 'Set how sensitively the audio detection should react.' },
        { actionColumn: 'Sound duration for alarm', descriptionColumn: 'Specify how long a noise must last at a sufficient volume for an audio alarm to be triggered.' },
        { actionColumn: 'Sensitivity', descriptionColumn: 'Adjust how sensitive the audio detection should be.' },
        { actionColumn: 'Enable Object Detection', descriptionColumn: 'Your camera uses an advanced machine-learning algorithm to detect 3 different categories of object in the camera live stream - Persons, Vehicles and Animals. You can activate this feature to display a bounding box around detected object in your cameras live video (only in WebUI). And you can suppress alarm events that have no detected object in them (see below).' },
        { actionColumn: 'Object threshold', descriptionColumn: 'Threshold is the detection certainty required to trigger an alarm. The higher the threshold the higher the algorithms certainty has to be.' },
        { actionColumn: 'Require a Person', descriptionColumn: 'When an alarm is triggered, suppress it, if there is no Person detected in the video frame.' },
        { actionColumn: 'Require a Vehicle', descriptionColumn: 'When an alarm is triggered, suppress it, if there is no Vehicle detected in the video frame.' },
        { actionColumn: 'Require an Animal', descriptionColumn: 'When an alarm is triggered, suppress it, if there is no Animal detected in the video frame.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ActionTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ActionTable)