import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import cloudSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud.webp'
import cloudAccountSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Create.webp'
import cloudStorageSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Storage.webp'
import cloudManagementSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Management.webp'
import cloudAdministrationSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Administration.webp'
import cloudRecordingsSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Recordings.webp'
import cloudSetsSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Sets.webp'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/Create_User_Account/">
                  <img
                    src={cloudAccountSearchIcon}
                    alt="User Account"
                  />
                </Link>
              }
              title="User Account"
              description="To log in to the INSTAR Cloud, you must first register with your email address and a password of your choice."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/Create_User_Account/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/Storage/">
                  <img
                    src={cloudStorageSearchIcon}
                    alt="Storage"
                  />
                </Link>
              }
              title="Storage"
              description="Each cloud account receives 10 GB of storage space for 30 days to test the cloud. You can expand your storage space with a voucher."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/Storage/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/User/">
                  <img
                    src={cloudManagementSearchIcon}
                    alt="Management"
                  />
                </Link>
              }
              title="User Management"
              description="Manage user accounts with different access rights in three user levels on your cloud server."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/User/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/Administration/">
                  <img
                    src={cloudAdministrationSearchIcon}
                    alt="Administration"
                  />
                </Link>
              }
              title="Administration"
              description="The following video gives an overview of all the functions available to the cloud administrator."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/Administration/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/Alarm_Recordings/">
                  <img
                    src={cloudRecordingsSearchIcon}
                    alt="Alarm Recordings"
                  />
                </Link>
              }
              title="Alarm Recordings"
              description="View your alarm recordings, delete unimportant videos or mark important recordings so they are not deleted."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/Alarm_Recordings/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/Camera_Sets/">
                  <img
                    src={cloudSetsSearchIcon}
                    alt="Camera Sets"
                  />
                </Link>
              }
              title="Camera Sets"
              description="Organise your integrated cameras into sets and assign different access rights for users."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/Camera_Sets/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/Cloud_Extensions/">
                  <img
                    src={cloudSearchIcon}
                    alt="Cloud Erweiterungen"
                  />
                </Link>
              }
              title="Cloud Extensions"
              description="A list of available feature extensions for your INSTAR Cloud account. Alexa, IFTTT, face and number plate recognition."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/Cloud_Extensions/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/Delete_Account/">
                  <img
                    src={cloudAdministrationSearchIcon}
                    alt="Delete Account"
                  />
                </Link>
              }
              title="Delete Account"
              description="To delete your account and all associated recordings, please follow the following instructions."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/Delete_Account/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
