import { EuiSpacer } from '@elastic/eui';
import Table01 from 'components/Software/Other_Platforms/Lupus_XT2_Plus/Table01';
import Table02 from 'components/Software/Other_Platforms/Lupus_XT2_Plus/Table02';
import Table03 from 'components/Software/Other_Platforms/Lupus_XT2_Plus/Table03';
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import ForumBox from 'components/Software/Other_Platforms/Lupus_XT2_Plus/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  Table01,
  Table02,
  Table03,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};