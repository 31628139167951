import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import YoutubeVideo from "components/Internet_Access/Point_to_Point/YoutubeVideoCardRemote";
import NavButtons from 'components/Internet_Access/NavButtons';
import ForumBox from 'components/Internet_Access/Point_to_Point/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiButton,
  EuiSpacer,
  YoutubeVideo,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};