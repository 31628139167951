import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Password Guidelines">
        <EuiText>
            <p>The WiFi password is limited, by the WPA standard, to max. 63 characters and can contain all <OutboundLink href="https://en.wikipedia.org/wiki/ASCII#Character_set" target="_blank" rel="noopener noreferrer">ASCII characters</OutboundLink>. This does not include the <OutboundLink href="https://en.wikipedia.org/wiki/Extended_ASCII" target="_blank" rel="noopener noreferrer">Extended ASCII</OutboundLink>. Note that "Umlauts", for example, are not part of this supported character set!</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

