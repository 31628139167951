import React from 'react'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'

import ivImage from "../../../images/Products/IR_Lighting/IN-90X_00.webp"

export default function ImageCard() {

  return (
    <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
      <EuiFlexItem style={{ maxWidth: 256 }}>
        <EuiImage
          hasShadow
          allowFullScreen
          alt="InstarVision v2 for Windows"
          src={ivImage}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText>
            <p>All INSTAR night vision cameras use infrared LEDs that have an emission maximum around 850nm. Photons with a wavelength higher than ~750nm are invisible to the human eye, but still clearly visible to a CMOS sensor. Especially if used indoors, night vision cameras can easily illuminate a small office space. Indoor environments are ideal as walls, furniture, the floor and ceiling reflect huge amounts of IR light back to the camera, allowing you to see every detail of the room clear-as-day. The situation can look very different once you are outside of the building. Green areas like lawns, bushes and trees reflect the incoming light in such a diffuse matter, that only a fracture of the light intensity ever returns to the camera. The result is, that the camera image stays very dark at night.</p>
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
