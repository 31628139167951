import NavButtons from 'components/1080p_Series_CGI_List/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import IntroCards from 'components/1080p_Series_CGI_List/System_Menu/systemCGI';
import * as React from 'react';
export default {
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  IntroCards,
  React
};