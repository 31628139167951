import React from 'react'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'

import ivImage from '../../../../../images/Software/Windows/InstarVision/Users/Image047_cut.webp'


export default function ImageCard() {

  return (
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem grow={false}>
            <EuiImage
              hasShadow
              allowFullScreen
              alt="InstarVision v2 for Windows"
              src={ivImage}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
              <p><strong>InstarVision offers you a highly customizable multi-level user management.</strong></p>
              <p>When starting the software for the first time, the default user is added - but the login is not active. You do not need to log-in to access the user interface. Please go to the System Menu if you want to activate the password protection.</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
  );
}
