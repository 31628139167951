import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


import ivImage01 from '../../../../../images/Software/Windows/InstarVision/Camera_List/Image013.webp'
import ivImage02 from '../../../../../images/Software/Windows/InstarVision/Camera_List/Image014.webp'

export default function ImageCard() {

  return (
    <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage01}
                alt="INSTAR InstarVision v2 for Windows"
              />
            </div>
          }
          title="Edit"
          footer={
            <EuiText>
              <p>Right-click a camera to edit your camera settings and setup. Or delete / duplicate your camera.</p>
            </EuiText>
          }
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage02}
                alt="INSTAR InstarVision v2 for Windows"
              />
            </div>
          }
          title="Rename"
          footer={
            <EuiText>
              <p>Left-click a camera twice to rename it. Or right-click it and change the name inside the Properties menu.</p>
            </EuiText>
          }
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
