import React from 'react'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import twoMMSearchIcon from '../../../../../images/Products/Lenses/IN-8015_2-8mm_lense_day_zoom.webp'
import fourMMSearchIcon from '../../../../../images/Products/Lenses/IN-8015_4-2mm_lense_day_zoom.webp'
import eightMMSearchIcon from '../../../../../images/Products/Lenses/IN-8015_8mm_lense_day_zoom.webp'
import sixteenMMSearchIcon from '../../../../../images/Products/Lenses/IN-8015_16mm_lense_day_zoom.webp'


export default function LenseArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <img
                  src={twoMMSearchIcon}
                  alt="2,8mm Objektiv für IN-8015 Full HD"
                />
              }
              title="2.8mm Lense"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <img
                  src={fourMMSearchIcon}
                  alt="4.2mm Objektiv für IN-8015 Full HD"
                />
              }
              title="4.2mm Lense"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <img
                  src={eightMMSearchIcon}
                  alt="8mm Objektiv für IN-8015 Full HD"
                />
              }
              title="8mm Lense"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <img
                  src={sixteenMMSearchIcon}
                  alt="16mm Objektiv für IN-8015 Full HD"
                />
              }
              title="16mm Lense"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}