import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import IntroLenses from "components/Products/Lenses/IntroLenses";
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  IntroLenses,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};