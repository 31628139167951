import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class SDTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "SD Card" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Open SD folder', descriptionColumn: 'A click on the folder button will lead you to the SD card directory where all your snapshots and videos will be stored. Use your camera admin login to access the directory. But there are also more convenient ways to access your recordings via Third-Party Software) or our mobile phone app InstarVision for iPhone, iPad, Android, Windows Phone and Windows Metro.' },
        { actionColumn: 'Ring Storage / Storage Management', descriptionColumn: 'The SD card inserted in the camera functions as a ring buffer. Images are always taken when this is also set. As soon as the memory runs out, the folder with the oldest recordings is automatically deleted to make room for new recordings. Decisive for the deletion is the creation date of the recording folder, but not the creation time of the individual recordings. Deleted recordings cannot be restored.' },
        { actionColumn: 'Maximum retention period', descriptionColumn: 'Set how long you want to keep your recordings.' },
        { actionColumn: 'Mount SD card', descriptionColumn: 'Unmount your SD card before you unplug it from your camera. Your camera´s operating system will try to re-mount the SD card in regular intervals. Click on mount to manually try to re-mount the SD card' },
        { actionColumn: 'Unmount SD card', descriptionColumn: 'Unmount your SD card before you unplug it from your camera. Your camera´s operating system will try to re-mount the SD card in regular intervals.' },
        { actionColumn: 'Format SD card', descriptionColumn: 'Your camera will delete the oldest folder automatically once the card capacity is reached. So there is always enough space for new recordings! Use the format command if you want to remove all recordings, or if your SD card´s file system is damaged. The latter might happen, if you restart/unplug your camera while your camera is recording a video. The filesystem will be formatted to FAT32.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

SDTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(SDTable)