import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import mqttAndroidCardIcon from '../../../images/Advanced_User/INSTAR_MQTT_Broker/toc_Card_android.webp'
import mqttHassCardIcon from '../../../images/Advanced_User/INSTAR_MQTT_Broker/toc_Card_homeassistant.webp'
import mqttOpenHABCardIcon from '../../../images/Advanced_User/INSTAR_MQTT_Broker/toc_Card_openhab.webp'
import mqttNoderedCardIcon from '../../../images/Advanced_User/INSTAR_MQTT_Broker/toc_Card_node-red.webp'
import mqttIOBrokerCardIcon from '../../../images/Advanced_User/INSTAR_MQTT_Broker/toc_Card_iobroker.webp'
import mqttLoxoneCardIcon from '../../../images/Advanced_User/INSTAR_MQTT_Broker/toc_Card_loxone.webp'
import mqttHomematicCardIcon from '../../../images/Advanced_User/INSTAR_MQTT_Broker/toc_Card_homematic.webp'
import mqttHomeyCardIcon from '../../../images/Advanced_User/INSTAR_MQTT_Broker/toc_Card_homey.webp'
import mqttHomeeCardIcon from '../../../images/Advanced_User/INSTAR_MQTT_Broker/toc_Card_homee.webp'
import mqttFHEMCardIcon from '../../../images/Advanced_User/INSTAR_MQTT_Broker/toc_Card_fhem.webp'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/INSTAR_MQTT_Broker/Android_Apps/">
                  <img
                    src={mqttAndroidCardIcon}
                    alt="INSTAR MQTT - Android Apps"
                  />
                </Link>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/">
                  <img
                    src={mqttHassCardIcon}
                    alt="INSTAR MQTT - Home Assistant"
                  />
                </Link>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/INSTAR_MQTT_Broker/OpenHAB2/">
                  <img
                    src={mqttOpenHABCardIcon}
                    alt="INSTAR MQTT - OpenHAB 2"
                  />
                </Link>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/INSTAR_MQTT_Broker/Node-RED/">
                  <img
                    src={mqttNoderedCardIcon}
                    alt="INSTAR MQTT - Node-RED"
                  />
                </Link>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/INSTAR_MQTT_Broker/Athom_Homey_MQTT_Client/">
                  <img
                    src={mqttHomeyCardIcon}
                    alt="INSTAR MQTT - Athom Homey"
                  />
                </Link>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/INSTAR_MQTT_Broker/Homematic_CCU3_und_RedMatic/">
                  <img
                    src={mqttHomematicCardIcon}
                    alt="INSTAR MQTT - RedMatic"
                  />
                </Link>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/INSTAR_MQTT_Broker/ioBroker/">
                  <img
                    src={mqttIOBrokerCardIcon}
                    alt="INSTAR MQTT - ioBroker"
                  />
                </Link>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/INSTAR_MQTT_Broker/Loxone/">
                  <img
                    src={mqttLoxoneCardIcon}
                    alt="INSTAR MQTT - Loxone"
                  />
                </Link>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/INSTAR_MQTT_Broker/homee/">
                  <img
                    src={mqttHomeeCardIcon}
                    alt="INSTAR MQTT - homee"
                  />
                </Link>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/INSTAR_MQTT_Broker/FHEM_House_Automation/">
                  <img
                    src={mqttFHEMCardIcon}
                    alt="INSTAR MQTT - FHEM House Automation"
                  />
                </Link>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
