import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'IN-90x',
      href: '/en/Downloads/Accessories/IN-90x/',
    },
    {
      text: 'IN-DV1215',
      href: '/en/Downloads/Accessories/IN-DV1215/',
    },
    {
      text: 'IN-Mikro 380',
      href: '/en/Downloads/Accessories/IN-Mikro_380/',
    },
    {
      text: 'IN-Motion 300',
      href: '/en/Downloads/Accessories/IN-Motion_300/',
    },
    {
      text: 'IN-Motion 500',
      href: '/en/Downloads/Accessories/IN-Motion_500/',
    },
    {
      text: 'Accessories',
      href: '/en/Downloads/Accessories/',
    },
    {
      text: '◄ Downloads',
      href: '/en/Downloads/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Downloadbereich for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}