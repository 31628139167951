import { EuiSpacer } from '@elastic/eui';
import DangerBox from 'components/Web_User_Interface/720p_Series/System/IR_LEDs/DangerBox';
import IRTable from 'components/Web_User_Interface/720p_Series/System/IR_LEDs/irTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  DangerBox,
  IRTable,
  BreadCrumbs,
  SEOHelmet,
  React
};