import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class WebUITable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "WebUI Einstellungen" },
        { name: "descriptionColumn", title: "Beschreibung" }
      ],
      rows: [
        { actionColumn: 'Snapshot', descriptionColumn: 'Use the Snapshot tile to take a fresh snapshot from your cameras live video and save it to your download folder.' },
        { actionColumn: 'Recording', descriptionColumn: 'Start and manually stop a video recording to your cameras internal SD card.' },
        { actionColumn: 'IR-LEDs', descriptionColumn: 'Manually activate or deactivate your cameras IR LEDs. The IR LEDs will only turn on when it is dark.' },
        { actionColumn: 'Alarm Emails', descriptionColumn: 'Activate or deactivate the alarm notification by email.' },
        { actionColumn: 'Messages', descriptionColumn: 'Review the latest notifications that the webUI displayed in the top right corner of the browser window.' },
        { actionColumn: 'Update', descriptionColumn: 'Use the auto-updater to update your cameras firmware when an update becomes available.' },
        { actionColumn: 'Web Shop', descriptionColumn: 'Add a link to the INSTAR web shop.' },
        { actionColumn: 'Tour', descriptionColumn: 'When you programmed a pan&tilt tour for your camera you can use the Tour tile to trigger it.' },
        { actionColumn: 'Language', descriptionColumn: 'Set the language the webUI is displayed in.' },
        { actionColumn: 'WebUI Mode', descriptionColumn: 'Manually toggle between the dark and bright webUI display mode.' },
        { actionColumn: 'INSTAR Cloud', descriptionColumn: 'Add a link to your optional INSTAR Cloud account.' },
        { actionColumn: 'Fullscreen', descriptionColumn: 'Toggle the webUIs full-screen mode.' },
        { actionColumn: 'Object Tracking', descriptionColumn: 'If you have the Object Detection active you can use this tile to display detected object in your cameras live video stream.' },
        { actionColumn: 'My Account', descriptionColumn: 'Access your User Account configuration menu.' },
        { actionColumn: 'Play', descriptionColumn: 'Click this tile to pause or resume your cameras live video.' },
        { actionColumn: 'Mute', descriptionColumn: 'Click this tile to mute or un-mute your cameras microphone.' },
        { actionColumn: 'Digital Zoom', descriptionColumn: 'If your camera does not have an optical zoom lense you can use this digital zoom to enlarge areas in your cameras live video.' },
        { actionColumn: 'Alarm', descriptionColumn: 'Use this tile to pause all alarm detections - motion, noise, PIR, etc. If the alarm is paused a red bell icon will be displayed on to of your cameras live video.' },
        { actionColumn: 'Relay', descriptionColumn: 'If your camera model has an alarm output relay you can use this tile to manually open or close this relay.' },
        { actionColumn: 'Alarm Areas', descriptionColumn: 'Just like the object tracking tile that enables an overlay of detected objects in your cameras live video you can use this tile to display the alarm area that triggered the last motion detection. Use this function to effectively fine-tune your alarm areas setup.' }
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

WebUITable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(WebUITable)