import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Indoor_Cameras/IN-8403_WQHD/NavButtons';
import QICards from 'components/Indoor_Cameras/IN-8403_WQHD/Quick_Installation/QICards';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  NavButtons,
  QICards,
  BreadCrumbs,
  SEOHelmet,
  React
};