import ICCAMCards01 from 'components/Software/iOS/ICCAM/iccamCard01';
import ICCAMCards02 from 'components/Software/iOS/ICCAM/iccamCard02';
import NavButtons from 'components/Software/iOS/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  ICCAMCards01,
  ICCAMCards02,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};