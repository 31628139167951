import React from 'react'
import { Link } from 'gatsby'
import { EuiImage, EuiText } from '@elastic/eui'

import ivImage from "../../../../images/Software/Windows/InstarVision/Image_Language.webp"




export default () => (
  <EuiText>
    <EuiImage
      size="l"
      float="left"
      margin="l"
      hasShadow
      caption="InstarVision v3 for Windows"
      allowFullScreen
      alt="InstarVision v3 for Windows"
      src={ivImage}
    />
    <p>You do not have to login to the software by default. But there is actually a default admin login and you might activate it by clicking the <strong>LOCK ICON</strong> in the top right of the user interface (above the INSTAR logo). Once the software is locked, you are no longer able to access any of the Side Navigation menus without logging in with the default username and password! Please be aware that these default values can be changed in the Side Navigation <Link to="/Software/Windows/InstarVision/Users/">Users Menu</Link>. You can also activate the user lock by default - so that nobody but you can access the software. Consider writing down your login somewhere - there will be no way to access the software, if you forget your password!</p>
  </EuiText>
);
