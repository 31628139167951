import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import lenseAdjust01 from "../../../../images/Outdoor_Cameras/IN-5907_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5907_01.webp"
import lenseAdjust02 from "../../../../images/Outdoor_Cameras/IN-5907_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5907_02.webp"
import lenseAdjust03 from "../../../../images/Outdoor_Cameras/IN-5907_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5907_03.webp"
import lenseAdjust04 from "../../../../images/Outdoor_Cameras/IN-5907_HD/Lense_Adjustment/Quickinstallation_Lense-Adjust_5907_04.webp"



export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust01}
                    alt="I. Please remove the top cover of your IN-5905 HD. Make sure not to lose the plastic ring under the white screw which is for waterproof purpose!"
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>I</strong>. Please remove the top cover of your IN-5905 HD. Make sure not to lose the plastic ring under the white screw which is for waterproof purpose!
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust02}
                    alt="II. Loosen two screws of the back casing and open the casing carefully and make sure not to damage any cables."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>II.</strong> Loosen two screws of the back casing and open the casing carefully and make sure not to damage any cables.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust03}
                    alt="III. Remove the three screws (marked in red) which fixes the electronics to the front casing. Attention! Don’t touch the screws with a hexagon mark underneath."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>III.</strong> Remove the three screws (marked in red) which fixes the electronics to the front casing. Attention! Don’t touch the screws with a hexagon mark underneath.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={lenseAdjust04}
                    alt="IV. Carefully take out the inner electronics. This part is still wired to the LED board on the front. Please be careful not to damage the cables or the connectors."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    <strong>IV.</strong> Carefully take out the inner electronics. This part is still wired to the LED board on the front. Please be careful not to damage the cables or the connectors.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
