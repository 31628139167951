import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" iconType="user" color="danger" title="Permissions">
        <EuiText>
          <p>
            Your camera provides a fine-grained access rights system that allows you to restrict access to certain menus of the web user interface and its underlying <Link to="/1440p_Series_CGI_List/Complete_CGI_List/">CGI commands</Link>. The minimum permission to access this menu is:
          </p>
          <ul>
            <li><strong>User Permissions</strong>: <code>System Settings</code>, <code>Alarm Settings</code></li>
          </ul>
          <p>
            User permissions can be modified in the <Link to="/Web_User_Interface/1440p_Series/System/User/">user menu</Link>.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

