import React from 'react'

import { Player } from 'video-react'

import {
    EuiText,
    EuiIcon,
    EuiPanel
  } from '@elastic/eui'

import { OutboundLink } from 'gatsby-plugin-gtag'

require('../../Layout/chrome/assets/video.css')

function VideoCard() {
  return (
    <EuiPanel paddingSize="m">
        <Player>
            <source src="https://wiki.instar.com/videos/INSTAR_Cloud_Kamera_Sets_erstellen_und_verwalten.mp4" />
        </Player>
        <EuiText>
            <p>In diesem Video zeigen wir Ihnen wie Sie Ihre Überwachungskameras zu Sets zusammenfügen um so schnell und einfach die Aufnahmen eines kompletten Sets einsehen zu können. Dies macht vor allem dann Sinn, wenn Sie viele Kameras in Ihrem INSTAR Cloud Account hinzugefügt haben und verwalten. Auf <OutboundLink href="https://www.youtube.com/watch?v=dPYGU8b_B7g" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
        </EuiText>
    </EuiPanel>
  );
}

export default VideoCard