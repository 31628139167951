import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class SDTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Manual Recording" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Recording resolution', descriptionColumn: 'Choose which video stream you want to record when you triggered the video recording. Your camera offers 3 videostreams that differ in resolution - 1440p, 1080p, 720p. Note that you can configure each of those streams separately in the Multimedia / Video menu.' },
        { actionColumn: 'Recording duration', descriptionColumn: 'Set the total duration and resolution for manual recordings here, which can be initiated via the quick access bar. Note that the preset lead time is included in the total recording duration.' },
        { actionColumn: 'Pre-recording length', descriptionColumn: 'Your camera can be configured to continuously record and discard the video if no recording command is triggered within `10s`. This `0s` to `10s` of pre-recording can be added to your manually recorded video.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

SDTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(SDTable)