import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Attention">
        <EuiText>
          <p>The username and password may not contain more than 30 characters. The following special characters are currently supported: <code>@ $ * - _ . / ?</code>. The following special characters are not supported: <code>& § = ä ö ü + # ß ´ ! " ( ) % ' : ; , SPACE</code></p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

