import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import ConnectionPlan from "components/Quick_Installation/Power_over_Ethernet/IntroPoEConnection";
import IntroPoE from "components/Quick_Installation/Power_over_Ethernet/IntroINPoE";
import PrimaryBox from "components/Quick_Installation/Power_over_Ethernet/PrimaryBox";
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Power_over_Ethernet/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  ConnectionPlan,
  IntroPoE,
  PrimaryBox,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};