import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Link to a Camera inside your InstarVision App">
        <EuiText>
          <p>Nehmen wir an, Sie verwenden Ihr Blockly-Skript, um jedes Mal, wenn der Alarm einer Kamera ausgelöst wird, eine Push-Benachrichtigung zu senden. Sie können der Nachricht eine URL hinzufügen, die die besagte Kamera direkt in unserer Android-App InstarVision öffnet! Geben Sie einfach <strong>iv://Name der Kamera</strong> in das URL-Feld ein. Als Namen der Kamera müssen Sie den Namen wählen, den Sie ihr innerhalb der InstarVision App gegeben haben (stellen Sie sicher, dass der Name keine Leerzeichen oder Sonderzeichen enthält).</p>
          <p>Wenn die Benachrichtigung eintrifft, verwenden Sie diesen Link, um die Live-Ansicht der Kamera direkt in der Android-App zu öffnen (Die App muss im Hintergrund laufen, sonst wird das Multiview-Fenster angezeigt).</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

