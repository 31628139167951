import React from 'react'

import 'react-vis/dist/style.css'

import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
  Crosshair
} from 'react-vis'

const DATA = [
  [
    { x: 'IN-2905', y: 4.10 },
    { x: 'IN-5905HD', y: 3.85 },
    { x: 'IN-5907HD', y: 2.79 },
    { x: 'IN-9008FHD', y: 2.15 },
    { x: 'IN-9020FHD', y: 11.93 }
  ],
  [
    { x: 'IN-2905', y: 2.20 },
    { x: 'IN-5905HD', y: 2.60 },
    { x: 'IN-5907HD', y: 12.28 },
    { x: 'IN-9008FHD', y: 7.15 },
    { x: 'IN-9020FHD', y: 12.28 }
  ],
  [
    { x: 'IN-2905', y: 6.15 },
    { x: 'IN-5905HD', y: 7.80 },
    { x: 'IN-5907HD', y: 4.20 },
    { x: 'IN-9008FHD', y: 8.71 },
    { x: 'IN-9020FHD', y: 16.28 }
  ],
  [
    { x: 'IN-2905', y: 3.20 },
    { x: 'IN-5905HD', y: 3.78 },
    { x: 'IN-5907HD', y: 4.11 },
    { x: 'IN-9008FHD', y: 3.34 },
    { x: 'IN-9020FHD', y: 3.34 }
  ],
  [
    { x: 'IN-2905', y: 5.20 },
    { x: 'IN-5905HD', y: 8.84 },
    { x: 'IN-5907HD', y: 11.93 },
    { x: 'IN-9008FHD', y: 8.65 },
    { x: 'IN-9020FHD', y: 14.45 }
  ],
  [
    { x: 'IN-2905', y: 5.80 },
    { x: 'IN-5905HD', y: 9.10 },
    { x: 'IN-5907HD', y: 12.15 },
    { x: 'IN-9008FHD', y: 8.93 },
    { x: 'IN-9020FHD', y: 15.75 }
  ],
  [
    { x: 'IN-2905', y: 3.25 },
    { x: 'IN-5905HD', y: 3.85 },
    { x: 'IN-5907HD', y: 4.12 },
    { x: 'IN-9008FHD', y: 3.41 },
    { x: 'IN-9020FHD', y: 5.41 }
  ],
  [
    { x: 'IN-2905', y: 5.85 },
    { x: 'IN-5905HD', y: 8.98 },
    { x: 'IN-5907HD', y: 12.02 },
    { x: 'IN-9008FHD', y: 8.69 },
    { x: 'IN-9020FHD', y: 16.05 }
  ],
];


export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: []
    };
  }
  render() {
    return (
        <React.Fragment>

          <div className="chart">
            <FlexibleWidthXYPlot
              onMouseLeave={() => this.setState({ crosshairValues: [] })}
              height={300}
              xType="ordinal"
              animation={true}
              stackBy="y">
              
              <VerticalGridLines />
              <HorizontalGridLines />
              <XAxis />
              <YAxis tickFormat={v => `${v} W`} />

              <Crosshair values={this.state.crosshairValues} />

              <VerticalBarSeries
                onNearestX={(value, { index }) =>
                  this.setState({ crosshairValues: DATA.map(d => d[index]) })}
                cluster="Booting min [W]"
                color="#673AB7"
                data={DATA[0]}
              />

              <VerticalBarSeries
                cluster="Booting max [W]"
                color="#0077cc"
                data={DATA[1]}
              />

              <VerticalBarSeries
                cluster="Booting with IR active [W]"
                color="#03A9F4"
                data={DATA[2]}
              />

              <VerticalBarSeries
                cluster="Idle [W]"
                color="#009688"
                data={DATA[3]}
              />

              <VerticalBarSeries
                cluster="Idle with IR active [W]"
                color="#388E3C"
                data={DATA[4]}
              />

              <VerticalBarSeries
                cluster="Idle with IR & WLAN active [W]"
                color="#8BC34A"
                data={DATA[5]}
              />

              <VerticalBarSeries
                cluster="Video Recording active [W]"
                color="#FF9800"
                data={DATA[6]}
              />

              <VerticalBarSeries
                cluster="Video Recording with IR active [W]"
                color="#F44336"
                data={DATA[7]}
              />

            </FlexibleWidthXYPlot>
          </div>

          <div className="legend">
            <DiscreteColorLegend
              orientation="horizontal"
              items={[
                {
                  title: 'Dataset[0] Booting min [W]',
                  color: '#673AB7'
                },
                {
                  title: 'Dataset[1] Booting max [W]',
                  color: '#0077cc'
                },
                {
                  title: 'Dataset[2] Booting with IR active [W]',
                  color: '#03A9F4'
                },
                {
                  title: 'Dataset[3] Idle [W]',
                  color: '#009688'
                },
                {
                  title: 'Dataset[4] Idle with IR active [W]',
                  color: '#388E3C'
                },
                {
                  title: 'Dataset[5] Idle with IR & WLAN active [W]',
                  color: '#8BC34A'
                },
                {
                  title: 'Dataset[6] Video Recording active [W]',
                  color: '#FF9800'
                },
                {
                  title: 'Dataset[7] Video Recording with IR active [W]',
                  color: '#F44336'
                }
              ]}
            />
          </div>

        </React.Fragment>
    );
  }
}