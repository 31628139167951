import { EuiSpacer } from '@elastic/eui';
import InstarVisionCard from 'components/Software/macOS/InstarVision/Layout/instarVisionCard01';
import NavButtons from 'components/Software/macOS/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  InstarVisionCard,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};