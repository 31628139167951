import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class settingsColumn extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "settingColumn", title: "Setting" },
        { name: "selectColumn", title: "Select" },
      ],
      rows: [
        { settingColumn: 'Port Mapping aktiviert', selectColumn: 'Ja' },
        { settingColumn: 'Hairpinning aktivieren', selectColumn: 'Ja' },
        { settingColumn: 'Beschreibung', selectColumn: 'choose a name for your camera, for example _Camera Entrance' },
        { settingColumn: 'Schnittstelle Typ', selectColumn: 'Unspezifizierter' },
        { settingColumn: 'Schnittstelle', selectColumn: 'PPP - PPP over ATM' },
        { settingColumn: 'Jeden Host', selectColumn: 'Ja' },
        { settingColumn: 'Jeden Port', selectColumn: 'Nein' },
        { settingColumn: 'Externer Port', selectColumn: 'TCP - 8081 - 8081 (For your second camera choose 8082, etc.)' },
        { settingColumn: 'Local Host', selectColumn: 'Enter the local IP-address of your camera here' },
        { settingColumn: 'denselben Port', selectColumn: 'Ja' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

settingsColumn.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(settingsColumn)



// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'


// const settingsRow = [
//   'Port Mapping aktiviert',
//   'Hairpinning aktivieren',
//   'Beschreibung',
//   'Schnittstelle Typ',
//   'Schnittstelle',
//   'Jeden Host',
//   'Jeden Port',
//   'Externer Port',
//   'Local Host',
//   'denselben Port',
// ]


// const functionRow = [
//   'Ja',
//   'Ja',
//   'choose a name for your camera, for example _Camera Entrance',
//   'Unspezifizierter',
//   'PPP - PPP over ATM',
//   'Ja',
//   'Nein',
//   'TCP - 8081 - 8081 (For your second camera choose 8082, etc.)',
//   'Enter the local IP-address of your camera here',
//   'Ja',
// ]

// // Render your table
// const ConnectTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={settingsRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Setting</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {settingsRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={225}
//         />
//         <Column
//           header={<Cell>Select</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {functionRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={2}
//           width={625}
//         />
//       </Table>
//     )
// }

// export default ConnectTable