import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'


import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="SSL Certificate">
        <EuiText>
          <p>
            You want to use your own SSL certificate for your own DDNS domain? Not a problem! Generate a self signed certificate and upload the certificate (<code>X.509 certificate</code>) and key (<code>Private RSA Key</code>) - combined in a single <code>*.pem</code> file. This can be done in the <Link to="/Web_User_Interface/1440p_Series/Network/SSL_Certificate/">SSL Certificate Menu</Link>. If you want to use a certificate that is signed by a <bold>Certificate Authority</bold> - e.g. a certificate generated by <OutboundLink href='https://letsencrypt.org/'>Let's Encrypt</OutboundLink> you need to upload the public CA certificate from your provider here. Please note that if you <bold>don't</bold> upload a certificate you camera will use the CA certificate that comes with your personal <Link to='/Web_User_Interface/1440p_Series/Network/Remote_Access/'>INSTAR DDNS Address</Link> - either way, your encrypted communication will be secure!
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

