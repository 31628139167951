import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Attention">
        <EuiText>
          <p>Your WiFi modules MAC address is <em>not identical</em> with the LAN MAC address of your camera! In case you are using a MAC filtering rule for your WiFi network please deactivate the filter and add your camera to the list of trusted devices before reactivating it.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

