import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import YoutubeVideoICT from "components/Motion_Detection/SD_Card_Access/YoutubeVideoCard";
import IVCards from 'components/Motion_Detection/SD_Card_Access/IVCards';
import WebUICards from 'components/Motion_Detection/SD_Card_Access/WebUICards';
import PrimaryBox1 from 'components/Motion_Detection/SD_Card_Access/PrimaryBox1';
import PrimaryBox2 from 'components/Motion_Detection/SD_Card_Access/PrimaryBox2';
import NavButtons from 'components/Motion_Detection/NavButtons';
import ForumBox from 'components/Motion_Detection/SD_Card_Access/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  OutboundLink,
  EuiSpacer,
  EuiButton,
  YoutubeVideoICT,
  IVCards,
  WebUICards,
  PrimaryBox1,
  PrimaryBox2,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};