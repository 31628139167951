import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Features/PTZ/SuccessBox';
import PTZTable01 from 'components/Web_User_Interface/1080p_Series/Features/PTZ/ptz01Table';
import PTZTable02 from 'components/Web_User_Interface/1080p_Series/Features/PTZ/ptz02Table';
import PTZTable03 from 'components/Web_User_Interface/1080p_Series/Features/PTZ/ptz03Table';
import PTZTable04 from 'components/Web_User_Interface/1080p_Series/Features/PTZ/ptz04Table';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  SuccessBox,
  PTZTable01,
  PTZTable02,
  PTZTable03,
  PTZTable04,
  BreadCrumbs,
  SEOHelmet,
  React
};