import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "wifiColumn", title: "WiFi" },
        { name: "poeColumn", title: "PoE" },
        { name: "fovColumn", title: "FoV" },
        { name: "ptColumn", title: "PT" },
        { name: "zoomColumn", title: "Zoom" }
      ],
      rows: [
        { wifiColumn: 'Wireless Variant', poeColumn: 'Power-over-Ethernet Variant', fovColumn: 'Field of View', ptColumn: 'Pan & Tilt', zoomColumn: 'Optical Zoom'},
        { wifiColumn: 'White / Black', poeColumn: 'White / Black', fovColumn: 'approx. 90°', ptColumn: 'not available', zoomColumn: 'only digital'}
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: 920}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)


