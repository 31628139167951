import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="WebUI Settings">
        <EuiText>
          <p>
          UI Tiles for quick setting for your INSTAR 1440p FullHD IP camera. Set here which tiles should be displayed to the right of the live video.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

