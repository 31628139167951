import React from "react"

import ImageGallery from "react-image-gallery"

import galleryImage00 from "../../../images/Outdoor_Cameras/IN-9408_WQHD/IN-9408WQHD-vs-IN-9008FHD_day2.webp"
import galleryImage00Thumb from "../../../images/Outdoor_Cameras/IN-9408_WQHD/IN-9408WQHD-vs-IN-9008FHD_day2_thumb.webp"
import galleryImage01 from "../../../images/Outdoor_Cameras/IN-9408_WQHD/SONY_IMX335_Day.webp"
import galleryImage01Thumb from "../../../images/Outdoor_Cameras/IN-9408_WQHD/SONY_IMX335_Day_thumb.webp"
import galleryImage02 from "../../../images/Outdoor_Cameras/IN-9408_WQHD/Panasonic_MN34229_Day.webp"
import galleryImage02Thumb from "../../../images/Outdoor_Cameras/IN-9408_WQHD/Panasonic_MN34229_Day_thumb.webp"
import galleryImage03 from "../../../images/Outdoor_Cameras/IN-9408_WQHD/SONY_IMX335_Night.webp"
import galleryImage03Thumb from "../../../images/Outdoor_Cameras/IN-9408_WQHD/SONY_IMX335_Night_thumb.webp"
import galleryImage04 from "../../../images/Outdoor_Cameras/IN-9408_WQHD/Panasonic_MN34229_Night.webp"
import galleryImage04Thumb from "../../../images/Outdoor_Cameras/IN-9408_WQHD/Panasonic_MN34229_Night_thumb.webp"
import galleryImage05 from "../../../images/Outdoor_Cameras/IN-9408_WQHD/SONY_IMX335_Indoor.webp"
import galleryImage05Thumb from "../../../images/Outdoor_Cameras/IN-9408_WQHD/SONY_IMX335_Indoor_thumb.webp"
import galleryImage06 from "../../../images/Outdoor_Cameras/IN-9408_WQHD/SONY_WDRMODE_0_ALL_AUTO_NIGHT.webp"
import galleryImage06Thumb from "../../../images/Outdoor_Cameras/IN-9408_WQHD/SONY_WDRMODE_0_ALL_AUTO_NIGHT_thumb.webp"
import galleryImage07 from "../../../images/Outdoor_Cameras/IN-9408_WQHD/SONY_WDRMODE_1_ALL_AUTO_NIGHT.webp"
import galleryImage07Thumb from "../../../images/Outdoor_Cameras/IN-9408_WQHD/SONY_WDRMODE_1_ALL_AUTO_NIGHT_thumb.webp"
import galleryImage08 from "../../../images/Outdoor_Cameras/IN-9408_WQHD/SONY_WDR_STRENGTH_5.webp"
import galleryImage08Thumb from "../../../images/Outdoor_Cameras/IN-9408_WQHD/SONY_WDR_STRENGTH_5_thumb.webp"

require("react-image-gallery/styles/css/image-gallery.css")

export class IN9408ImageQGallery extends React.Component {
  render() {
    const images = [{ original: galleryImage00, thumbnail: galleryImage00Thumb }, { original: galleryImage01, thumbnail: galleryImage01Thumb }, { original: galleryImage02, thumbnail: galleryImage02Thumb }, { original: galleryImage03, thumbnail: galleryImage03Thumb }, { original: galleryImage04, thumbnail: galleryImage04Thumb }, { original: galleryImage05, thumbnail: galleryImage05Thumb }, { original: galleryImage06, thumbnail: galleryImage06Thumb }, { original: galleryImage07, thumbnail: galleryImage07Thumb }, { original: galleryImage08, thumbnail: galleryImage08Thumb }];

    return (
      <div style={{ padding: '15px',maxWidth: '1115px', margin: '2.5rem 0 2.5rem 0', boxShadow: '0 2px 3px 0 rgb(0 0 0 / 36%' }}>
        <ImageGallery items={images} lazyLoad={true} showPlayButton={false} />
      </div>
    );
  }
}

export default IN9408ImageQGallery
