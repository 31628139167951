import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Network/IP_Configuration/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import IPConfigTable from 'components/Web_User_Interface/1440p_Series/Network/IP_Configuration/ipConfigTable';
import IPConfigTableIPv6 from 'components/Web_User_Interface/1440p_Series/Network/IP_Configuration/ipConfigTableIPv6';
import CertCards from 'components/Web_User_Interface/1440p_Series/Network/IP_Configuration/CertCards';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  SuccessBox,
  PermissionBox,
  IPConfigTable,
  IPConfigTableIPv6,
  CertCards,
  BreadCrumbs,
  SEOHelmet,
  React
};