import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import YoutubeVideo from 'components/Motion_Detection/INSTAR_Cloud/Storage/YoutubeVideoCardCloud';
import ConfigTable from 'components/Motion_Detection/INSTAR_Cloud/Storage/ConfigTable';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  OutboundLink,
  EuiSpacer,
  YoutubeVideo,
  ConfigTable,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};