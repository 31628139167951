import React from "react";

import { EuiText, EuiCallOut } from "@elastic/eui";

function PrimaryBox() {
  return (
    <EuiCallOut
      size="m"
      iconType="gear"
      color="success"
      title="Snapshot Schedule"
    >
      <EuiText>
        <p>
          Set a schedule for snapshot recording for days of the week and time of
          the day. Record those snapshots to the internal SD card, send them by
          email or upload them to an FTP server.
        </p>
      </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox;
