import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiSpacer
} from '@elastic/eui'


import ivImage01 from '../../../../../images/Software/Windows/InstarVision/Camera_List/Image079.webp'
import ivImage02 from '../../../../../images/Software/Windows/InstarVision/Camera_List/Image080.webp'
import ivImage03 from '../../../../../images/Software/Windows/InstarVision/Camera_List/Image081.webp'
import ivImage04 from '../../../../../images/Software/Windows/InstarVision/Camera_List/Image084.webp'

export default function ImageCard() {

  return (
    <React.Fragment>
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <div>
                <img
                  src={ivImage01}
                  alt="INSTAR InstarVision v2 for Windows"
                />
              </div>
            }
            title="Add"
            footer={
              <EuiText>
                <p>Add a map by right-clicking and choose Add Map.</p>
              </EuiText>
            }
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <div>
                <img
                  src={ivImage02}
                  alt="INSTAR InstarVision v2 for Windows"
                />
              </div>
            }
            title="Delete"
            footer={
              <EuiText>
                <p>Delete a map by right-clicking and choose Delete.</p>
              </EuiText>
            }
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <div>
                <img
                  src={ivImage03}
                  alt="INSTAR InstarVision v2 for Windows"
                />
              </div>
            }
            title="Rename"
            footer={
              <EuiText>
                <p>Click twice to rename - Double-click it to open the Map View.</p>
              </EuiText>
            }
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <div>
                <img
                  src={ivImage04}
                  alt="INSTAR InstarVision v2 for Windows"
                />
              </div>
            }
            title="Edit"
            footer={
              <EuiText>
                <p>Drop cameras on your map from the Camera List.</p>
              </EuiText>
            }
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  );
}
