import { EuiSpacer } from '@elastic/eui';
import TinyCards from 'components/Software/Android/TinyCam_Monitor/tinyCards';
import NavButtons from 'components/Software/Android/NavButtons';
import ForumBox from 'components/Software/Android/TinyCam_Monitor/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  TinyCards,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};