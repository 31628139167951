import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import ChangelogSearch from "components/Search/IVWindowsclSearch";
import QRDownloads from "components/Downloads/Mobile_Apps/QRDownloads";
import NavButtons from 'components/Downloads/Mobile_Apps/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiButton,
  EuiSpacer,
  ChangelogSearch,
  QRDownloads,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};