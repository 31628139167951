import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'CGI Commands',
      href: '/en/Advanced_User/CGI_Commands/',
    },
    {
      text: 'INSTAR MQTT',
      href: '/en/Advanced_User/INSTAR_MQTT_Broker/',
    },
    {
      text: 'Website',
      href: '/en/Advanced_User/Website_Integration/',
    },
    {
      text: 'Videostreaming',
      href: '/en/Advanced_User/Youtube_Videostreaming_from_your_Camera/',
    },
    {
      text: 'Smarthome',
      href: '/en/Advanced_User/All_Home_Automation_Tutorials/',
    },
    {
      text: 'WebUI',
      href: '/en/Advanced_User/Restore_WebUI/',
    },
    {
      text: 'Firmware',
      href: '/en/Advanced_User/Restore_Firmware/',
    },
    {
      text: 'VPN',
      href: '/en/Advanced_User/VPN_AVM_Fritzbox/',
    },
    {
      text: '◄ Developer',
      href: '/en/Advanced_User/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Topics for Developers"
      />
      <EuiSpacer />
    </Fragment>
  );
}