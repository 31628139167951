import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Please note">
        <EuiText>
        <p>The camera will not be able to dial into your Wi-Fi network anymore as it lacks the WiFi key. Also, you will probably no longer have the old IP address, but automatically obtain a new one from your router as soon as you connect the camera via LAN cable. After that a new first installation is necessary. Details can be found here: <strong><OutboundLink href="https://install.instar.com/" target="_blank" rel="noopener noreferrer">Installation Wizard</OutboundLink></strong>.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

