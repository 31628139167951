import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class DDNSTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Video Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Activate INSTAR DDNS', descriptionColumn: 'To access your camera´s WebUI over the internet you have to activate your INSTAR DDNS Address. Please be aware that your camera might still be available over the DDNS address for a while, after you deactivated it. The access will be blocked once your internet service provider changes your WAN IP address - this usually happens once every 24hrs (and in some cases once you restarted your internet router).' },
        { actionColumn: 'INSTAR DDNS Address', descriptionColumn: 'This is your personal Internet Access. You will be able to access your camera over this address once you forward your camera`s HTTP port.' },
        { actionColumn: 'QR Code', descriptionColumn: 'To add your camera to our iPhone or Android app, you simply have to scan the QR code.' },
        { actionColumn: 'Activate Third Party DDNS (optional)', descriptionColumn: 'If you don´t want to use your INSTAR DDNS Address, just add a Third-party DDNS Address. Please contact your Third-party provider for details.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

DDNSTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(DDNSTable)