import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-gtag";
import { EuiSpacer } from "@elastic/eui";
import NavButtons from "components/Outdoor_Cameras/IN-9408_WQHD/NavButtons";
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
import * as React from 'react';
export default {
  Link,
  OutboundLink,
  EuiSpacer,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};