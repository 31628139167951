import React from 'react'
import {Link} from 'gatsby'

import {
  EuiCard,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import privacyError1 from "../../../../../images/Web_User_Interface/1080p_Series/Network/IP_Configuration/https_privacy_error_01.webp"
import privacyError2 from "../../../../../images/Web_User_Interface/1080p_Series/Network/IP_Configuration/https_privacy_error_02.webp"

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={privacyError1}
                    alt="Using HTTPS inside your Local Network"
                  />
              }
              title='Using HTTPS inside your Local Network'
              description={
                <EuiText>
                    <p>
                      The INSTAR SSL certificate is only valid, if you access your camera over its <Link to="/Web_User_Interface/1080p_Series/Network/Internet_Access/">DDNS Address</Link>. When you try to use the HTTPS encryption, when accessing your camera inside your local network (over its local IP address), you will see a security warning.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={privacyError2}
                    alt="Accept the Certificate for the local IP Address"
                  />
              }
              title='Accept the Certificate for the local IP Address'
              footer={
                <EuiText>
                    <p>
                      Click on advanced (the naming of this button will vary, depending on your browser) and click that you want to proceed anyway. The web browsers Safari and Firefox will additionally ask you to add an exception for the certificate - click on Ok to proceed.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
