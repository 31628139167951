import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Remark">
        <EuiText>
    <p>Be aware that maximum length for the email password is 39 characters. The following special characters are not supported: <code>& = " ` + \</code></p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

