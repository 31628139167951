import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Point2Point/WPS-P2P_IN-5905_01.webp"
import qiSelect02 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Point2Point/WPS-P2P_IN-5905_02.webp"
import qiSelect03 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Point2Point/WPS-P2P_IN-5905_03.webp"
import qiSelect04 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Point2Point/WPS-P2P_IN-5905_04.webp"
import qiSelect05 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Point2Point/WPS-P2P_IN-5905_05.webp"
import qiSelect06 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Point2Point/WPS-P2P_IN-5905_06.webp"
import qiSelect07 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Point2Point/WPS-P2P_IN-5905_07.webp"
import qiSelect08 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Point2Point/WPS-P2P_IN-5905_08.webp"
import qiSelect09 from "../../../../images/Outdoor_Cameras/IN-5905_HD/Point2Point/WPS-P2P_IN-5907_09.webp"



export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect01}
                    alt="Connect the WiFi antenna with the SMA connector on the wall mount of your camera."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Connect the WiFi antenna with the SMA connector on the wall mount of your camera.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect02}
                    alt="Connect the power adaptor with the cable to boot up your camera."
                  />
              }
              footer={
                <EuiText>
                    <p>
                      Connect the power adaptor with the cable to boot up your camera.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect03}
                    alt="If your camera supports WPS (Wireless Protected Setup), simply press the WPS button (examples above)."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    If your camera supports WPS (Wireless Protected Setup), simply press the WPS button (examples above).
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect04}
                    alt="Now press the reset button on your camera´s cable tree for 3s. Then please wait for up to 5 minutes for your camera to connect to your routers WiFi."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Now press the reset button on your camera´s cable tree for 3s. Then please wait for up to 5 minutes for your camera to connect to your routers WiFi.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect05}
                    alt="Start our Android, iOS or Windows Phone app Instar Vision. Choose to add a new p2p camera and start the QR code scanner. Your QR code is located on a label on the camera case (s. above)."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    Start our Android, iOS or Windows Phone app Instar Vision. Choose to add a new p2p camera and start the QR code scanner. Your QR code is located on a label on the camera case (s. above).
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect06}
                    alt="The QR code contains all the necessary information to add your camera. Please be aware that the default login credentials will be used. If you want to change the default password, please check the Quick Installation Guide. The password can only be changed in the web user interface."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    The QR code contains all the necessary information to add your camera. Please be aware that the default login credentials will be used. If you want to change the default password, please check the Quick Installation Guide. The password can only be changed in the web user interface.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect07}
                    alt="The Login can only be Changed inside the webUI of your camera. The QR Code inside user interface always contains the actual information."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    The Login can only be Changed inside the webUI of your camera. The QR Code inside user interface always contains the actual information.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect08}
                    alt="You can now use the app to access your camera´s Live Stream, pan & tilt it, listen to its audio stream and record videos."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    You can now use the app to access your camera´s Live Stream, pan & tilt it, listen to its audio stream and record videos.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={qiSelect09}
                    alt="The WPS / P2P method allows you to quickly connect your camera and check the cameras live stream. Please refer to the Quick Installation Guide for instruction on how to access additional camera features."
                  />
              }
              footer={
                <EuiText>
                    <p>
                    The WPS / P2P method allows you to quickly connect your camera and check the cameras live stream. Please refer to the Quick Installation Guide for instruction on how to access additional camera features.
                    </p>
                </EuiText>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
