import React from 'react'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'

import ivImage from '../../../../../images/Software/Windows/InstarVision/Users/Image047_cut.webp'


export default function ImageCard() {

  return (
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem grow={false}>
            <EuiImage
              hasShadow
              allowFullScreen
              alt="InstarVision v2 for Windows"
              src={ivImage}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
              <p><strong>InstarVision offers you a highly customizable multi-level user management</strong></p>
              <p>If you are logged in as an administrator, you can add, edit, delete users here, and grant the <a href="#user-rights">User Rights</a> for each user. Is the <a href="#activate-login">Login</a> is deactivated, you are automatically logged in as the administrator, via the <a href="#default-user">Default User</a>.</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
  );
}
