import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link  to="/Web_User_Interface/720p_Series/Multimedia/Video/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Multimedia_Video.webp'
                    alt="Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Video"
              description="The video settings allow you to adjust the quality of all 3 video streams of your camera. Please be advised that high quality settings come with high requirements..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/Multimedia/Video/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link  to="/Web_User_Interface/720p_Series/Multimedia/Image/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Multimedia_Image.webp'
                    alt="Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Image"
              description="Here you can adjust the image parameters brightness, saturation, contrast, exposure (shutter) and sharpness. You can flip and mirror the video image..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/Multimedia/Image/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link  to="/Web_User_Interface/720p_Series/Multimedia/Privacy_Mask/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Multimedia_Privat.webp'
                    alt="Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Privacy Mask"
              description="The Privacy Mask Menu allows you to black out areas from your surveillance. E.g. you can still see a part of the public street in front of your house on..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/Multimedia/Privacy_Mask/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link  to="/Web_User_Interface/720p_Series/Multimedia/Audio/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_720p_SearchThumb_Multimedia_Audio.webp'
                    alt="Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Audio"
              description="Here you can adjust the amplification of the cameras microphones/audio-in as well as the volume of the audio-out signal."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/Multimedia/Audio/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
