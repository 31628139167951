import React from 'react'

import {EuiPanel, EuiText, EuiSpacer} from '@elastic/eui'

import PowerBarGraph from "./power-outdoor-bar-graph"

function PowerCard() {
  return (
    <EuiPanel>
      <EuiText grow={false} size="m" color="default" >
        Power Consumption [W]
      </EuiText>
      <EuiSpacer />
      <PowerBarGraph />
    </EuiPanel>
  );
}


export default PowerCard