import React from 'react'

import { Player } from 'video-react'

import {
    EuiText,
    EuiIcon,
    EuiPanel
  } from '@elastic/eui'

import { OutboundLink } from 'gatsby-plugin-gtag'

require('../../Layout/chrome/assets/video.css')

function VideoCard() {
  return (
    <EuiPanel paddingSize="m">
        <Player>
            <source src="https://wiki.instar.com/videos/INSTAR_Cloud_Benutzer_erstellen_und_bearbeiten_von_Zugriffsrechten.mp4" />
        </Player>
        <EuiText>
            <p>In diesem Video zeigen wir Ihnen wie Sie zusätzliche Benutzer in Ihrem INSTAR Cloud Account hinzufügen können und diesen unterschiedliche Rechte zuweisen können.</p>
            <p>Auf diese Weise haben Sie die Möglichkeit manchen Benutzern alle Kameras anzeigen zu lassen und anderen wiederrum nur die relevanten.</p>
            <p>Gerade für Firmen ist dies interessant da Sie so unterschiedlichen Mitarbeitern Zugriff auf Ihren Account geben können ohne dabei Angst haben zu müssen, das der Benutzer irgendwelche Einstellungen verändern kann. Auf <OutboundLink href="https://www.youtube.com/watch?v=us9UcKaD1dw" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
        </EuiText>
    </EuiPanel>
  );
}

export default VideoCard