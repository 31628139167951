import { EuiSpacer } from '@elastic/eui';
import StatusTable01 from 'components/Web_User_Interface/720p_Series/System/Status_LED/statusTable01';
import StatusTable02 from 'components/Web_User_Interface/720p_Series/System/Status_LED/statusTable02';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  StatusTable01,
  StatusTable02,
  BreadCrumbs,
  SEOHelmet,
  React
};