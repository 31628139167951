import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import webUI108001 from "../../../images/Motion_Detection/SD_Card_Access/WebUI-SD_01_1080.webp"
import webUI108002 from "../../../images/Motion_Detection/SD_Card_Access/WebUI-SD_02_1080.webp"
import webUISD01 from "../../../images/Motion_Detection/SD_Card_Access/WebUI-SD_01.webp"
import webUISD02 from "../../../images/Motion_Detection/SD_Card_Access/WebUI-SD_02.webp"
import webUISD03 from "../../../images/Motion_Detection/SD_Card_Access/WebUI-SD_03.webp"
import webUISD04 from "../../../images/Motion_Detection/SD_Card_Access/WebUI-SD_04.webp"




export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={webUI108001}
                    alt="1080p WebUI"
                  />
              }
              title='1080p WebUI'
              description={
                <p>The HD camera models allow you to record videos and snapshots to the internal SD card. To quickly access your recordings, click on the Features / SD Card link inside your cameras web user interface.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={webUI108002}
                    alt="1080p WebUI"
                  />
              }
              title='1080p WebUI'
              description={
                <p>The SD cards file directory will open in a new tab. Please type in your camera login to access your recorded files. If the link is not displayed the SD is no longer mounted by the cameras operating system - please remove the SD card and push it back in.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={webUISD01}
                    alt="720p WebUI"
                  />
              }
              title='720p WebUI'
              description={
                <p>The HD camera models allow you to record videos and snapshots to the internal SD card. To quickly access your recordings, click on the SD Card link inside your cameras web user interface.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={webUISD02}
                    alt="720p WebUI"
                  />
              }
              title='720p WebUI'
              description={
                <p>The SD cards file directory will open in a new tab. Please type in your camera login to access your recorded files. If the link is not displayed the SD is no longer mounted by the cameras operating system - please remove the SD card and push it back in.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={webUISD03}
                    alt="720p WebUI"
                  />
              }
              title='720p WebUI'
              description={
                <p>Your camera generates a new directory for each new day and a new recordXXX directory for every 200 recordings of that day. Every alarm event creates an *.avi file that starts with the capital letter A - planned recordings with the letter P and so on - followed by a time code for the alarm trigger.</p>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={webUISD04}
                    alt="720p WebUI"
                  />
              }
              title='720p WebUI'
              description={
                <p>The default behaviour of most internet browsers is to download the video after you clicked on it. Browsers like the Mozilla Firefox off you support for the VLC plugin to display the video directly inside the browser window. Please make sure that you activate the Firefox plugin during the installation process of the VLC player and restart your browser after the installation. The VLC Web Plugin should now be listed inside the Addons window under Plugins.</p>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
