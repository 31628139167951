import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Language Settings">
        <EuiText>
          <p>
            Change the language of the web user interface to German, English, French or Chinese. The user interface automatically sets the language according to the language of your operating system. You can manually change it, if the auto-detection fails or you want the UI to be displayed in different languages on different computers. Your selection will be saved locally as a cookie and not globally on your camera.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

