import React from 'react'

import { Player } from 'video-react'

import {
    EuiText,
    EuiIcon,
    EuiPanel
  } from '@elastic/eui'

import { OutboundLink } from 'gatsby-plugin-gtag'

require('../../Layout/chrome/assets/video.css')

function VideoCard() {
  return (
    <EuiPanel paddingSize="m">
        <Player>
            <source src="https://wiki.instar.com/videos/So_funktioniert_die_Bewegungserkennung_einer_IP_Kamera_und_so_stellt_man_Sie_richtig_ein.mp4" />
        </Player>
        <EuiText>
            <p>So funktioniert die Bewegungserkennung einer IP Kamera und so stellt man Sie richtig ein. Auf <OutboundLink href="https://www.youtube.com/watch?v=ziksI3G3n_I" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
        </EuiText>
    </EuiPanel>
  );
}

export default VideoCard