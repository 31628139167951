import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Scheduled Recording">
        <EuiText>
          <p>
            Record videos at adjustable time intervals. The recording is made in the selected resolution on the camera's internal SD card. It is recommended to minimize the maximum length of the recording so that - within the active time window - a new recording is started every few minutes.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

