import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Free Cloud Account">
        <EuiText>
          <p>Creating a test account is free of charge and gives you access to the Alexa function even after the 30-day trial period has expired. Only the storage of alarm videos in the cloud would require a <strong>paid activation code</strong>.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

