import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class IRTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "IR LEDs" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'IR LED Control', descriptionColumn: 'Deactivate the IR LEDs or set them to automatic. The automatic mode will be controlled by the photo sensor and the IR LEDs will be automatically switched on once it gets dark.' },
        { actionColumn: 'Infrared Cut Filter', descriptionColumn: 'The IRCut filter filters the infrared light of the sun during daytime and is automatically removed when the camera switches to night mode - this allows using the IR LEDs to illuminate the surveillance area. You can deactivate this automatic feature here by permanently switching the camera to night or day mode.' },
        { actionColumn: 'Fixed Night vision Schedule', descriptionColumn: 'The automatic IR LED mode is controlled by a photo sensor. In case that this sensor tends to activate the night vision mode too early, you can set a time schedule instead. Be aware that this schedule can only switch your camera´s IR LEDs from "Disabled" to "Automatic" - there is no mode to activate the IR LEDs constantly.' },
        { actionColumn: 'Activate IR LEDs at', descriptionColumn: 'Here you can set the time, when the IR LEDs are set into automatic mode. Remember that this will only activate the IR LEDs if it is dark enough. The camera´s photo sensor will prevent the activation of the night vision mode during daylight.' },
        { actionColumn: 'Deactivate IR LEDs at', descriptionColumn: 'The time you set here will be the time where the IR LEDs will be deactivated.' },
        { actionColumn: 'PIR night trigger', descriptionColumn: 'Deactivate the nightvision LEDs by default and only activate them when the PIR sensor detects movement in front of your camera.' },
        { actionColumn: 'Duration of IR activity when triggered by PIR', descriptionColumn: 'Set the time the IR LED should remain on after a PIR detection.' },
        { actionColumn: 'Current Illumination Value', descriptionColumn: 'The current brightness value measured by the camera`s brightness sensor.' },
        { actionColumn: 'Thresholds', descriptionColumn: 'Change the thresholds for the camera switching between black-white (night) and color mode (day). The upper and lower threshold that is used by the automation to switch your camera from day to night mode and vice versa.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

IRTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(IRTable)