import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class AreasTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Alarm Areas" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Areas', descriptionColumn: 'Click on the radio button behind the area (1-4) you want to edit. Then, move your mouse pointer over the image of your camera, hold down the left mouse button, and drag a rectangle over the image. To pick up a second area, select the appropriate area and repeat the next steps. The movement detection of the camera will then search for movements only within these areas.' },
        { actionColumn: 'Active', descriptionColumn: 'The tick behind Active activates the corresponding area. That the camera starts to search within this range for movement and will trigger the Alarm Actions selected by you when a positive detection is detected. In the Menu Bar above the live video, you will find a shortcut to quickly activate / deactivate the individual sections without having to call the section menu. As soon as one of the numbers 1-4 is shown in red, the corresponding area is active.' },
        { actionColumn: 'Sensitivity', descriptionColumn: 'Specify how many pixels will need to change in the image to trigger a Alarm Actions. The higher the sensitivity, the less the picture content has to change in order to trigger an alarm. Please note that the sensitivity also depends on the size of the area. An object that takes 100 pixels in the video image will take a larger area in a small area as a percentage than in a large area. If you receive too many false alarms, try to draw the areas larger. If the detection is too insensitive, draw smaller areas in the image.' },
        { actionColumn: 'All', descriptionColumn: 'Click on this button to set the selected area over the whole picture.' },
        { actionColumn: 'Reset', descriptionColumn: 'Move the selected area back to its original position.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

AreasTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(AreasTable)