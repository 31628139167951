import React from 'react'
import { Link } from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Update:">
        <EuiText>
          <h2>New Alarmserver</h2>
          <p>The INSTAR Alarmserver for our Full HD cameras was updated and should no longer cause any of the problems described below. Please make sure that you <Link to="/Quick_Installation/WebUI_And_Firmware_Upgrade/">Update your Camera Software</Link> before using the Alarmserver!</p>
          <br/>
          <ul>
            <li><Link to="/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/">Update Alarmserver WebUI Menu</Link></li>
            <li><Link to="/Advanced_User/Alarmserver_Queries_for_your_Smarthome/">Working with Alarmserver Queries</Link></li>
            <li><Link to="/Frequently_Asked_Question/WQHD_Alarmserver_Custom_Headers/">Working with Alarmserver Headers</Link></li>
          </ul>
          <h3>Example Use-Cases:</h3>
          <ul>
            <li><Link to="/Frequently_Asked_Question/Debugging_Alarmserver_Headers/">HTTP-Alarmserver debugging in Node-RED</Link></li>
            <li><Link to="/Frequently_Asked_Question/ioBroker_and_INSTAR_Alarmserver/">ioBroker and INSTAR Alarmserver</Link></li>
            <li><Link to="/Software/Other_Platforms/Loxone_Miniserver/#use-the-loxone-miniserver-as-alarm-server">Loxone Miniserver</Link></li>
            <li><Link to="/Software/Other_Platforms/Homematic_IP/#using-the-homematic-ccu3-as-alarmserver-for-our-camera">Homematic CCU3</Link></li>
            <li><Link to="/Advanced_User/Homematic_CCU3_and_RedMatic/#instar-ip-camera---homematic">Homematic & RedMatic</Link></li>
            <li><Link to="/Software/Other_Platforms/Homee/#camera-alarmserver-and-homee-webhook">homee</Link></li>
            <li><Link to="/Software/Other_Platforms/Athom_Homey/#notifying-homey-when-the-camera-alarm-is-triggered-alarm-server">Homey</Link></li>
            <li><Link to="/Advanced_User/IFTTT_as_Alarmserver/">IFTTT</Link></li>
            <li><Link to="/Software/Linux/Home_Assistant/#using-home-assistant-as-alarmserver-for-your-instar-camera">Home Assistant</Link></li>
            <li><Link to="/Frequently_Asked_Question/AS_Alarm_Link_between_Cameras/">Alarmserver to link your Cameras Alarm</Link></li>
            <li><Link to="/Frequently_Asked_Question/MQTT_Alarmserver_PIR_Switch/">MQTT Alarmserver as a Lightswitch</Link></li>
            <li><Link to="/Frequently_Asked_Question/BlueIris_v5_http_alarmserver/">BlueIris v5 as HTTP Alarmserver</Link></li>
            <li><Link to="/Frequently_Asked_Question/MQTT_Alarmserver_Interval/">INSTAR MQTT Alarmserver Interval</Link></li>
            <li><Link to="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_Homey/">Using MQTT to inform Homey that an Alarm was Triggered</Link></li>
            <li><Link to="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_ioBroker/">Using MQTT to inform ioBroker that an Alarm was Triggered</Link></li>
            <li><Link to="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_Motion_Tracking/">Using the INSTAR MQTT Alarmserver to track Moving Objects</Link></li>
            <li><Link to="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver/">Using MQTT to inform your Smarthome that an Alarm was Triggered</Link></li>
          </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox