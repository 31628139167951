import { Link } from 'gatsby';
import RemoteArticles from 'components/Internet_Access/IntroInternet';
import NavButtons from 'components/Internet_Access/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  RemoteArticles,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};