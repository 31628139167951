import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="INSTAR IFTTT Applets">
        <EuiText>
          <p>
            IFTTT derives its name from the programming conditional statement “if this, then that.” What the company provides is a software platform that connects apps, devices and services from different developers in order to trigger one or more automation involving those apps, devices and services.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

