import { EuiSpacer } from '@elastic/eui';
import RouterCards from 'components/Frequently_Asked_Question/Port_Forwarding/RouterCards';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  RouterCards,
  BreadCrumbs,
  SEOHelmet,
  React
};