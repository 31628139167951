import React from 'react'
import 'react-vis/dist/style.css'
import {
  FlexibleWidthXYPlot,
  VerticalGridLines,
  HorizontalGridLines,
  AreaSeries,
  DiscreteColorLegend,
  Crosshair,
  XAxis
} from 'react-vis'

const DATA = [
  [
    { x: '4 Cameras', y: 447 },
    { x: '8 Cameras', y: 760 },
    { x: '16 Cameras', y: 1060 }
  ]
]

export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: []
    };
  }

  render() {
    return (
      <React.Fragment>

        <div className="chart">
          <FlexibleWidthXYPlot
            onMouseLeave={() => this.setState({ crosshairValues: [] })}
            height={300}
            xType="ordinal">

            <XAxis
              attr="x"
              attrAxis="y"
              orientation="bottom"
            />

            <VerticalGridLines />

            <HorizontalGridLines />

            <AreaSeries
              onNearestX={(value, { index }) =>
                this.setState({ crosshairValues: DATA.map(d => d[index]) })}
              cluster="ProcessorLoad"
              color="#dd706e"
              curve="curveNatural"
              data={DATA[0]} />

            <Crosshair values={this.state.crosshairValues} />

          </FlexibleWidthXYPlot>
        </div>

        <div className="legend">
          <DiscreteColorLegend
            orientation="horizontal"
            items={[
              {
                title: '[0] Multiple Camera Load',
                color: '#dd706e'
              }
            ]}
          />
        </div>

      </React.Fragment>
    );
  }
}