import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'P2P',
      href: '/en/Internet_Access/Point_to_Point/',
    },
    {
      text: 'DDNS',
      href: '/en/Internet_Access/The_DDNS_Service/',
    },
    {
      text: 'Port Forwarding',
      href: '/en/Internet_Access/Port_Forwarding/',
    },
    {
      text: 'Mobile Access',
      href: '/en/Internet_Access/Mobile_Access/',
    },
    {
      text: 'DDNS Provider',
      href: '/en/Internet_Access/DDNS_Provider/',
    },
    {
      text: '◄ Remote',
      href: '/en/Internet_Access/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Remote access your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}