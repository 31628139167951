import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Password Guidelines">
        <EuiText>
            <p>The WiFi password is limited, by the WPA standard, to max. 63 characters and can contain the following characters: {' 0-9, a-b A-B, -/: & = \\ @ ( ) ! ? . , $  < > ~ | % # { } [ ] + _ ^ * ; “ '} '. Be careful not to create a HTML tag, like &#60;b\&#62;, &#60;br\&#62;, &#60;u\&#62; etc. - as they will not work inside the web user interface!</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

