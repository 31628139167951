import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="9JCRp329I0I"
                    title="INSTAR IN-7011 HD Unboxing"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              footer={
                <EuiText>
                  <p>The IN-7011 HD is a weather proof dome camera which can either be used in in- or outdoor environments. A convenient motor allows for quick readjusting of the lenses viewing direction and can be controlled by any network device such as a tablet or a smartphone. The built-in infrared diodes allow you to see in pitch dark environments, while the integrated IR cut filter aids in the display of vibrant colours, as seen by the human eye. Through the use of an efficient h.264 compression codec it is able of recording video files on a MicroSD memory card with comparably small size but best picture results.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
