import React from 'react'

import {
  EuiAccordion,
  EuiText,
  EuiSpacer,
  EuiImage,
  EuiPanel
} from '@elastic/eui'

import Command01 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_06-EN.webp'
import Command02 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_07-EN.webp'
import Command03 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_08-EN.webp'
import Command04 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_09-EN.webp'
import Command05 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_10-EN.webp'
import Command06 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_11-EN.webp'
import Command07 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_12-EN.webp'
import Command08 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_13-EN.webp'
import Command09 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_14-EN.webp'
import Command10 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_15-EN.webp'
import Command11 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_16-EN.webp'
import Command12 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_17-EN.webp'
import Command13 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_18-EN.webp'
import Command14 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_19-EN.webp'
import Command15 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_20-EN.webp'
import Command16 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_21-EN.webp'
import Command17 from '../../../../../images/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/INSTAR_Cloud_Alexa_Skill_22-EN.webp'


const Accordion = () => (
  <React.Fragment>
    <EuiPanel hasBorder>
      <EuiAccordion
        id="accordion1"
        buttonContent="Start the INSTAR Cloud Skill"
        paddingSize="l"
        style={{height: "fit-content"}}>
          <EuiPanel color="subdued">
            <EuiText style={{height: 300}}>
              <ul>
                <li>Open INSTAR Cloud</li>
                <li>Start INSTAR Cloud</li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

    
    <EuiPanel hasBorder>
      <EuiAccordion
        id="accordion1"
        buttonContent="Play recent Alarm Videos"
        paddingSize="l"
        style={{height: "fit-content"}}>
          <EuiPanel color="subdued">
            <EuiText style={{height: 300}}>
              <ul>
                <li>Show me the latest alarms</li>
                <li>Play the latest videos</li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

    
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion1"
          buttonContent="Play the recent Alarm Videos from a specific Camera"
          paddingSize="l"
          style={{height: "fit-content"}}>
            <EuiPanel color="subdued">
              <EuiText style={{height: 280}}>
                <EuiImage
                  size="l"
                  float="left"
                  margin="l"
                  hasShadow
                  allowFullScreen
                  alt="Alexa Play the recent Alarm Videos from a specific Camera"
                  src={Command01}
                />
                <h5>Alexa Commands:</h5>
                <ul>
                  <li>Show recent alarms from camera <code>[cameraName]</code></li>
                  <li>Play the latest video from camera <code>[cameraName]</code></li>
                </ul>
                <p>
                  <code>[cameraName]</code> is the name you gave your camera inside the INSTAR Cloud system
                </p>
              </EuiText>
            </EuiPanel>
          </EuiAccordion>
        </EuiPanel>

        <EuiSpacer />

        <EuiPanel hasBorder>
          <EuiAccordion
            id="accordion1"
            buttonContent="Play all Alarm Videos from a specific Camera"
            paddingSize="l"
            style={{height: "fit-content"}}>
              <EuiPanel color="subdued">
                <EuiText style={{height: 280}}>
                  <EuiImage
                    size="l"
                    float="left"
                    margin="l"
                    hasShadow
                    allowFullScreen
                    alt="Alexa Play all Alarm Videos from a specific Camera"
                    src={Command02}
                  />
                  <h5>Alexa Commands:</h5>
                  <ul>
                    <li>Show all alarms of camera <code>[cameraName]</code></li>
                    <li>Play videos from camera <code>[cameraName]</code></li>
                  </ul>
                  <p>
                    <code>[cameraName]</code> is the name you gave your camera inside the INSTAR Cloud system.
                  </p>
                </EuiText>
              </EuiPanel>
            </EuiAccordion>
          </EuiPanel>

          <EuiSpacer />

          <EuiPanel hasBorder>
            <EuiAccordion
              id="accordion2"
              buttonContent="Play a specific number of the latest alarm videos"
              buttonContentClassName="eui-textTruncate"
              paddingSize="l">
              <EuiPanel color="subdued">
                <EuiText style={{height: 280}}>
                  <EuiImage
                    size="l"
                    float="left"
                    margin="l"
                    hasShadow
                    allowFullScreen
                    alt="Alexa Play a specific number of the latest alarm videos"
                    src={Command03}
                  />
                  <h5>Alexa Commands:</h5>
                  <ul>
                    <li>Show the latest  <code>[alarmsCount]</code> alarms</li>
                    <li>Play the latest <code>[alarmsCount]</code> videos</li>
                  </ul>
                  <p>
                    <code>[cameraName]</code> is the name you gave your camera inside the INSTAR Cloud system.
                  </p>
                </EuiText>
              </EuiPanel>
            </EuiAccordion>
          </EuiPanel>

          <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion3"
          buttonContent="Play alarm videos based on alarms status"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 280}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="Alexa Play alarm videos based on alarms status"
                src={Command04}
              />
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Show all <code>[status]</code>* alarms</li>
                <li>Play all <code>[status]</code>* alarms</li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Play alarm videos based on date"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 280}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="Alexa Play alarm videos based on date"
                src={Command05}
              />
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Show me all alarms from <code>[alarmsDate]</code></li>
                <li>Play videos from <code>[alarmsDate]</code></li>
              </ul>
              <p>
                <code>[alarmsDate]</code> like <em>today</em>, <em>yesterday</em>, <em>the day before yesterday</em>, or a specific date, like <em>First of April, 2020</em> - <strong>Note</strong> that you always have to add the year in this case.
              </p>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Play alarm videos based on detected objects"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 280}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="Alexa Play alarm videos based on detected objects"
                src={Command06}
              />
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Show all alarms with <code>[object]</code>*</li>
                <li>Play all videos with <code>[object]</code>*</li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Play a specific number alarm videos from a specific camera"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 280}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="Alexa Play a specific number alarm videos from a specific camera"
                src={Command07}
              />
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Show the latest <code>[alarmsCount]</code> alarms from camera <code>[cameraName]</code></li>
                <li>Play the <code>[alarmsCount]</code> latest video from camera <code>[cameraName]</code></li>
              </ul>
              <p>
                <code>[cameraName]</code> is the name you gave your camera inside the INSTAR Cloud system
              </p>
              <p>
                <code>[alarmsCount]</code> has to be a number: 1, 2, 3...100
              </p>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Play alarm videos based on camera name and date"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 280}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="Alexa Play alarm videos based on camera name and date"
                src={Command08}
              />
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Show all alarms from camera <code>[cameraName]</code> from <code>[alarmsDate]</code></li>
                <li>Play videos from <code>[alarmsDate]</code> from camera <code>[cameraName]</code></li>
              </ul>
              <p>
                <code>[cameraName]</code> is the name you gave your camera inside the INSTAR Cloud system
              </p>
              <p>
                <code>[alarmsDate]</code> like <em>today</em>, <em>yesterday</em>, <em>the day before yesterday</em>, or a specific date, like <em>First of April, 2020</em> - <strong>Note</strong> that you always have to add the year in this case.
              </p>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Play alarm videos based on detected objects and date"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 280}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="Alexa Play alarm videos based on detected objects and date"
                src={Command09}
              />
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Show all alarms from <code>[alarmsDate]</code> with <code>[object]</code>*</li>
                <li>Play all videos with <code>[object]</code>* from <code>[alarmsDate]</code></li>
              </ul>
              <p>
                <code>[alarmsDate]</code> like <em>today</em>, <em>yesterday</em>, <em>the day before yesterday</em>, or a specific date, like <em>First of April, 2020</em> - <strong>Note</strong> that you always have to add the year in this case.
              </p>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Play alarm videos based on camera name detected objects and date"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 280}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="Alexa Play alarm videos based on camera name detected objects and date"
                src={Command10}
              />
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Show all alarms from <code>[cameraName]</code> from <code>[alarmsDate]</code> with <code>[object]</code>*</li>
                <li>Play all videos from camera <code>[cameraName]</code> with <code>[object]</code>* from <code>[alarmsDate]</code></li>
              </ul>
              <p>
                <code>[cameraName]</code> is the name you gave your camera inside the INSTAR Cloud system
              </p>
              <p>
                <code>[alarmsDate]</code> like <em>today</em>, <em>yesterday</em>, <em>the day before yesterday</em>, or a specific date, like <em>First of April, 2020</em> - <strong>Note</strong> that you always have to add the year in this case.
              </p>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Play alarm videos based on camera name date and time"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 280}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="Alexa Play alarm videos based on camera name date and time"
                src={Command11}
              />
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Show all alarms from camera <code>[cameraName]</code> from <code>[alarmsDate]</code> between <code>[timeStart]</code> and <code>[timeEnd]</code> o&apos;clock</li>
                <li>Play videos from <code>[alarmsDate]</code> between <code>[timeStart]</code> and <code>[timeEnd]</code> clock of <code>[cameraName]</code></li>
              </ul>
              <p>
                <code>[cameraName]</code> is the name you gave your camera inside the INSTAR Cloud system
              </p>
              <p>
                <code>[alarmsDate]</code> like <em>today</em>, <em>yesterday</em>, <em>the day before yesterday</em>, or a specific date, like <em>First of April, 2020</em> - <strong>Note</strong> that you always have to add the year in this case.
              </p>
              <p>
                <code>[timeStart]</code> / <code>[timeEnd]</code> can be any time, like 7am, 7pm
              </p>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Play alarm videos from a specific camera set"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 280}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="Alexa Play alarm videos from a specific camera set"
                src={Command12}
              />
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Show me all alarms from camera set <code>[cameraSet]</code></li>
                <li>Play all videos from camera set <code>[cameraSet]</code></li>
              </ul>
              <p>
                <code>[cameraSet]</code> is the name of a camera set you created inside the INSTAR Cloud system
              </p>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Play a specific number of alarm videos from a specific camera set"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 280}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="Alexa Play a specific number of alarm videos from a specific camera set"
                src={Command13}
              />
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Show the latest <code>[alarmsCount]</code> alarms from camera set <code>[cameraSet]</code></li>
                <li>Play the latest <code>[alarmsCount]</code> videos from Camera Set <code>[cameraSet]</code></li>
              </ul>
              <p>
                <code>[alarmsCount]</code> should be any number:1, 2, 3...100
              </p>
              <p>
                <code>[cameraSet]</code> is the name of a camera set you created inside the INSTAR Cloud system
              </p>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Check number of alarms for specific cameras"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 280}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="Alexa Check number of alarms for specific cameras"
                src={Command14}
              />
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Tell me number of videos from <code>[cameraName]</code></li>
                <li>How many alarms does <code>[cameraName]</code> have</li>
              </ul>
              <p>
                <code>[cameraName]</code> is the name you gave your camera inside the INSTAR Cloud system
              </p>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Check number of alarms for specific camera sets"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 280}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="Alexa Check number of alarms for specific camera sets"
                src={Command15}
              />
              <h5>Alexa Commands:</h5>
              <ul>
                <li>How many alarms are in camera set <code>[cameraSet]</code></li>
                <li>What is the number of alarms in <code>[cameraSet]</code></li>
              </ul>
              <p>
                <code>[cameraSet]</code> is the name of your camera set inside the INSTAR Cloud system
              </p>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Change your alarm email settings"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 280}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="Alexa Change your alarm email settings"
                src={Command16}
              />
              <h5>Alexa Commands:</h5>
              <ul>
                <li><code>[switch]</code>* alarm email</li>
                <li><code>[switch]</code>* notifications by email</li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Change object recognition settings for your camera"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 280}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="Alexa Change object recognition settings for your camera"
                src={Command17}
              />
              <h5>Alexa Commands:</h5>
              <ul>
                <li><code>[switch]</code>* object recognition for <code>[cameraName]</code></li>
                <li>Turn object recognition <code>[switch]</code>* for <code>[cameraName]</code></li>
              </ul>
              <p>
                <code>[cameraName]</code> is the name you gave your camera inside the INSTAR Cloud system
              </p>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Number of Cameras"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 300}}>
              <h5>Alexa Commands:</h5>
              <ul>
                <li>How many cameras on my cloud</li>
                <li>Tell me number of cameras</li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Available storage"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 300}}>
              <h5>Alexa Commands:</h5>
              <ul>
                <li>How much space is left on my cloud</li>
                <li>How much memory is still available</li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Storage expiry date"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 300}}>
              <h5>Alexa Commands:</h5>
              <ul>
                <li>When does the storage space expire</li>
                <li>When does my voucher expire</li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Pause Video"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 300}}>
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Pause</li>
                <li>Pause that</li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Continue Video"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 300}}>
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Resume</li>
                <li>Continue</li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Next Video"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 300}}>
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Next</li>
                <li>Skip</li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Previous Video"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 300}}>
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Go Back</li>
                <li>Back Up</li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Check which INSTAR Cloud account is linked to Alexa"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 300}}>
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Do you know my account</li>
                <li>Which account I bind with this skill</li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Go back to the INSTAR Cloud homepage"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 300}}>
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Back to start page</li>
                <li>INSTAR Cloud</li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Ask for help"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 300}}>
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Ask for help</li>
                <li>How to use INSTAR Cloud</li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Quit the INSTAR Cloud Skill"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 300}}>
              <h5>Alexa Commands:</h5>
              <ul>
                <li>Exit</li>
                <li>Exit INSTAR Cloud</li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  </React.Fragment>
);

export default Accordion