import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/FAQs/IN-9420WQHD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-9420WQHD.webp'
                    alt="IN-9420 WQHD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-9420 WQHD"
              description="The IN-9420 WQHD is the first INSTAR Outdoor IP Surveillance camera, utilizing our new Sony 1440p / WQHD STARVIS sensor."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/FAQs/IN-9420WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/FAQs/IN-9408WQHD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-9408WQHD.webp'
                    alt="IN-9408 WQHD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-9408 WQHD"
              description="The IN-9408 WQHD is the first INSTAR Outdoor IP Surveillance camera, utilizing our new Sony 1440p / WQHD STARVIS sensor."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/FAQs/IN-9408WQHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/FAQs/IN-9020FHD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-9020HD.webp'
                    alt="IN-9020 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-9020 Full HD"
              description="The IN-9020 HD is the first INSTAR Outdoor IP Surveillance PTZ camera, utilizing our new Panasonic 1080p / fullHD image sensor and an Panasonic PIR sensor."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/FAQs/IN-9020FHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/FAQs/IN-9010FHD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-9010HD.webp'
                    alt="IN-9010 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-9010 Full HD"
              description="The IN-9010 HD is the first INSTAR Outdoor IP Surveillance PTZ camera, utilizing our new Panasonic 1080p / fullHD image sensor."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/FAQs/IN-9010FHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/FAQs/IN-9008FHD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-9008HD.webp'
                    alt="IN-9008 Full HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-9008 Full HD"
              description="The IN-9008 HD is the first INSTAR Outdoor IP Surveillance camera, utilizing our new Panasonic 1080p / fullHD image sensor."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/FAQs/IN-9008FHD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/FAQs/IN-7011HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-7011HD.webp'
                    alt="IN-7011 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-7011 HD"
              description="The IN-7011 HD is a weather proof dome camera which can be used inside and outside. A motor allows for change of the lenses viewing direction."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/FAQs/IN-7011HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/FAQs/IN-5907HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-5907HD.webp'
                    alt="IN-5907 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-5907 HD"
              description="Looking an outdoor camera with powerful nightvision? The all new IN-5907HD, the default 720p HD outdoor camera!"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/FAQs/IN-5907HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/FAQs/IN-5905HD/">
                  <StaticImage
                    src='../../images/Search/P_SearchThumb_IN-5905HD.webp'
                    alt="IN-5905 HD"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IN-5905 HD"
              description="Looking for a small outdoor camera? The all new IN-5905HD, basically a drastically smaller version of our flagship model IN-5907HD is what you are looking for!"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/FAQs/IN-5905HD/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
