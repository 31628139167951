import React from 'react'
import { Link } from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import mqttCardIcon from '../../../images/Advanced_User/INSTAR_MQTT_Broker/toc_Card_mqtt.webp'
import mqttDebugCardIcon from '../../../images/Advanced_User/INSTAR_MQTT_Broker/toc_Card_mqtt_debug.webp'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Series/Network/MQTT/">
                  <img
                    src={mqttCardIcon}
                    alt="INSTAR MQTT Interface"
                  />
                </Link>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_und_LINUX_Apps/">
                  <img
                    src={mqttDebugCardIcon}
                    alt="INSTAR MQTT - Windows macOS and LINUX Apps"
                  />
                </Link>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
