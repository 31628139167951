import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class DownloadTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "changeColumn", title: "Changes" }
      ],
      rows: [
        { changeColumn: 'Integration of the Instar Full HD camera models IN-8015 Full HD and IN-9008 Full HD' },
        { changeColumn: 'Integration of the in-house P2P solution' },
        { changeColumn: 'Revision of motion detection' },
        { changeColumn: 'Update process for new versions (through the installer)' },
        { changeColumn: 'Added link to user manual' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

DownloadTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(DownloadTable)



// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'


// const cameraRow = [
//   'Integration of the Instar Full HD camera models IN-8015 Full HD and IN-9008 Full HD',
//   'Integration of the in-house P2P solution',
//   'Revision of motion detection',
//   'Update process for new versions (through the installer)',
//   'Added link to user manual',
// ]

// // Render your table
// const ConnectTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={cameraRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Release Notes</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {cameraRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={850}
//         />
//       </Table>
//     )
// }

// export default ConnectTable