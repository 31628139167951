import { EuiSpacer } from '@elastic/eui';
import PTZTable from 'components/Web_User_Interface/720p_Series/System/PTZ/ptzTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  PTZTable,
  BreadCrumbs,
  SEOHelmet,
  React
};