import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiCard,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiTitle,
  EuiText
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='INSTAR Company'
              description={
                <React.Fragment>
                  <EuiTitle size='s'>
                    <h4>Kontakt Sie Uns</h4>
                  </EuiTitle>
                  <EuiText>
                    <p>
                      INSTAR Deutschland GmbH<br />
                      Raiffeisenstraße 12<br />
                      65510<br />
                      Hünstetten-Bechtheim, Germany
                    </p>
                  </EuiText>
                </React.Fragment>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <EuiFlexItem grow={false}>
                    <EuiButton size="s" fill href="https://www.instar.com/contact">
                      Kontakt
                    </EuiButton>
                    <EuiSpacer size="s" />
                  </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='Hotline'
              description={
                <React.Fragment>
                  <EuiTitle size='s'>
                    <h4>Kontakt Sie Uns</h4>
                  </EuiTitle>
                  <EuiText>
                    <p>
                      +49 (6438) 9198992<br />
                      (Mo - Fr, 8 – 14 o’clock GMT+1)<br />
                      Website: <OutboundLink href="https://www.instar.com" target="_blank" rel="noopener noreferrer">www.instar.com</OutboundLink><br />
                      Email: <OutboundLink href="https://www.instar.com/contact" target="_blank" rel="noopener noreferrer">support at instar dot com</OutboundLink>
                    </p>
                  </EuiText>
                </React.Fragment>                
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <EuiFlexItem grow={false}>
                    <EuiButton size="s" fill href="https://www.instar.com/contact">
                      Kontakt
                    </EuiButton>
                    <EuiSpacer size="s" />
                  </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
