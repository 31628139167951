import { Link } from 'gatsby';
import WebUI1080Articles from 'components/Web_User_Interface/1080p_Series/IntroWebUI1080';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  WebUI1080Articles,
  BreadCrumbs,
  SEOHelmet,
  React
};