import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'


export default function softwareVideos() {

  return (
    <React.Fragment>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <LiteYouTubeEmbed 
                    id="YXNnRFeySkk"
                    title="INSTAR Cloud False-Alarm Detection"
                    adNetwork={true}
                    poster="maxresdefault" //hqdefault
                    noCookie={true}
                />
              }
              title="INSTAR Cloud False-Alarm Detection"
              footer={
                <EuiText>
                  <p>In diesem Video zeigen wir Ihnen die neue Fehlalarmerkennung welche es exklusiv in der INSTAR Cloud Plattform gibt. Die INSTAR Cloud ist derzeit nutzbar mit allen INSTAR HD und Full HD Kameramodellen.</p>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
    </React.Fragment>
  );
}
