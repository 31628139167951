import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Indoor_Cameras/IN-6014_HD/NavButtons';
import CompareIndoor from 'components/Products/Compare/CompareIndoorFlyout';
import TableFeatures from 'components/Indoor_Cameras/IN-6014_HD/TableFeatures';
import IndoorData from 'components/Products/Flyouts/Graphs/IndoorGraphsFlyout';
import RadarGraphCard from 'components/Products/RadarGraphs/in6014-radargraph-grid';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  OutboundLink,
  EuiSpacer,
  NavButtons,
  CompareIndoor,
  TableFeatures,
  IndoorData,
  RadarGraphCard,
  BreadCrumbs,
  SEOHelmet,
  React
};