import React from 'react'

import {
  EuiCard,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import p2pCamsImage01 from "../../../../images/Software/iOS/P2P_Cam_Live/P2P_Cam_Live_01.webp"
import p2pCamsImage02 from "../../../../images/Software/iOS/P2P_Cam_Live/P2P_Cam_Live_02.webp"


export default function softwareArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={p2pCamsImage01}
                    alt="P2P Cam Live for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p><strong>Fig1.</strong> Let the software search for P2P cameras on your local area network and select the camera you want to add.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={p2pCamsImage02}
                    alt="P2P Cam Live for your INSTAR IP Camera"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">
                    <p><strong>Fig2.</strong> Type in a name and your admin password for the camera. Then click on Save to add your camera.</p>
                  </EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
