import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "serviceColumn", title: "Email Provider" },
        { name: "urlColumn", title: "Server Address" },
        { name: "authColumn", title: "Authentification" },
        { name: "portColumn", title: "Server Port" },
      ],
      rows: [
        { serviceColumn: 'T-Online', urlColumn: 'securesmtp.t-online.de', authColumn: 'StartTLS', portColumn: '587' },
        { serviceColumn: 'GMX', urlColumn: 'mail.gmx.de', authColumn: 'StartTLS', portColumn: '587' },
        { serviceColumn: 'Strato', urlColumn: 'smtp.strato.de', authColumn: 'StartTLS', portColumn: '587' },
        { serviceColumn: '1und1', urlColumn: 'smtp.1und1.de', authColumn: 'StartTLS', portColumn: '587' },
        { serviceColumn: 'O2', urlColumn: 'smtp.o2online.de', authColumn: 'StartTLS', portColumn: '587' },
        { serviceColumn: 'Vodafone', urlColumn: 'smtp.vodafone.de', authColumn: 'StartTLS', portColumn: '587' },
        { serviceColumn: 'Freenet', urlColumn: 'mx.freenet.de', authColumn: 'StartTLS', portColumn: '587' },
        { serviceColumn: 'Yahoo', urlColumn: 'smtp.mail.yahoo.com', authColumn: 'StartTLS', portColumn: '587' },
        { serviceColumn: 'me.com', urlColumn: 'smtp.me.com', authColumn: 'StartTLS', portColumn: '587' },
        { serviceColumn: 'outlook.com', urlColumn: 'smtp.live.com', authColumn: 'StartTLS', portColumn: '587' },
        { serviceColumn: 'NetCologne', urlColumn: 'smtp.netcologne.de', authColumn: 'StartTLS', portColumn: '587' },
        { serviceColumn: 'Bluewin.ch', urlColumn: 'smtpauth.bluewin.ch', authColumn: 'StartTLS', portColumn: '587' },
        { serviceColumn: 'Arcor', urlColumn: 'mail.arcor.de', authColumn: 'StartTLS', portColumn: '587' },
        { serviceColumn: 'Kabel BW', urlColumn: 'smtp.kabelbw.de', authColumn: 'StartTLS', portColumn: '587' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)



// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'

// const serviceRow = [
//   'T-Online',
//   'GMX',
//   'Strato',
//   '1und1',
//   'O2',
//   'Vodafone',
//   'Freenet',
//   'Yahoo',
//   'me.com',
//   'outlook.com',
//   'NetCologne',
//   'Bluewin.ch',
//   'Arcor',
//   'Kabel BW',
// ]

// const urlRow = [
//   'securesmtp.t-online.de',
//   'mail.gmx.de',
//   'smtp.strato.de',
//   'smtp.1und1.de',
//   'smtp.o2online.de',
//   'smtp.vodafone.de',
//   'mx.freenet.de',
//   'smtp.mail.yahoo.com',
//   'smtp.me.com',
//   'smtp.live.com',
//   'smtp.netcologne.de',
//   'smtpauth.bluewin.ch',
//   'mail.arcor.de',
//   'smtp.kabelbw.de',
// ]

// const authRow = [
//   'StartTLS',
//   'StartTLS',
//   'StartTLS',
//   'StartTLS',
//   'StartTLS',
//   'StartTLS',
//   'StartTLS',
//   'StartTLS',
//   'StartTLS',
//   'StartTLS',
//   'StartTLS',
//   'StartTLS',
//   'StartTLS',
//   'StartTLS',
// ]

// const portRow = [
//   '587',
//   '587',
//   '587',
//   '587',
//   '587',
//   '587',
//   '587',
//   '587',
//   '587',
//   '587',
//   '587',
//   '587',
//   '587',
//   '587',
// ]

// // Render your table
// const SMTPTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={serviceRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Email Provider</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {serviceRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={200}
//         />
//         <Column
//           header={<Cell>Server Address</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {urlRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={220}
//         />
//         <Column
//           header={<Cell>Authentification</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {authRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={230}
//         />
//         <Column
//           header={<Cell>Service Port</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {portRow[rowIndex]}
//             </Cell>
//           )}
//           width={200}
//         />
//       </Table>
//     )
// }

// export default SMTPTable

