import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'VGA Series',
      href: '/en/Quick_Installation/WebUI_And_Firmware_Upgrade/VGA_Kameras/',
    },
    {
      text: '720p Series',
      href: '/en/Quick_Installation/WebUI_And_Firmware_Upgrade/720p_Kameras/',
    },
    {
      text: '1080p Series',
      href: '/en/Quick_Installation/WebUI_And_Firmware_Upgrade/',
    },
    {
      text: '◄ Installation',
      href: '/en/Quick_Installation/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={6}
        aria-label="Software Update"
      />
      <EuiSpacer />
    </Fragment>
  );
}