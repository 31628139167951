import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "numberColumn", title: "#" },
        { name: "componentColumn", title: "Component" },
        { name: "functionColumn", title: "Function" },
      ],
      rows: [
        { numberColumn: '01', componentColumn: 'Bracket', functionColumn: 'Sealing bracket for the main camera body' },
        { numberColumn: '02', componentColumn: 'Photosensor', functionColumn: 'Twilight switch for IR LEDs' },
        { numberColumn: '03', componentColumn: 'Status LEDs', functionColumn: 'Red: Camera constant on after power on or blinking during firmware update. Blue: On with WiFi connection, blinking using WPS or reset' },
        { numberColumn: '04', componentColumn: 'Dual LEDs', functionColumn: '2 high-power IR LEDs ( @ 850nm). 2 high-power floodlight LEDs' },
        { numberColumn: '05', componentColumn: 'Lense', functionColumn: 'Wide-angle (focal length: 4.3mm / field of view: approx. 90º dia.)' },
        { numberColumn: '06', componentColumn: 'PIR Sensor', functionColumn: 'Integrated passive infrared (PIR) motion detector' },
        { numberColumn: '07', componentColumn: 'Microphone', functionColumn: 'For noise detection / audio rec.' },
        { numberColumn: '08', componentColumn: 'Micro SD Card', functionColumn: 'Slot for MicroSD/SDXC cards (max. 128GB - 32GB included) - inside the camera' },
        { numberColumn: '09',  componentColumn: 'Alarm Input', functionColumn: 'Input for potential free external alarm sensors' },
        { numberColumn: '10',  componentColumn: 'Alarm Output', functionColumn: 'Output relay can support max. 24V/1A' },
        { numberColumn: '11', componentColumn: 'Reset', functionColumn: 'Press 15-20 seconds for reset' },
        { numberColumn: '12', componentColumn: 'Network LED', functionColumn: 'Off: No Power / No signal, Flashing: Network Signal' },
        { numberColumn: '13', componentColumn: 'Network Port', functionColumn: 'RJ45 LAN port / connector for Cat5e LAN cable or higher' },
        { numberColumn: '14', componentColumn: 'Power Connector', functionColumn: 'For included 12V / 1A power supply' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)