import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import taskerMobileIcon from '../../../images/Advanced_User/Tasker/Tasker_Mobile.webp'
import taskerSceneIcon from '../../../images/Advanced_User/Tasker/Tasker_Scene.webp'
import taskerShortcutIcon from '../../../images/Advanced_User/Tasker/Tasker_Shortcut.webp'
import taskerTimerIcon from '../../../images/Advanced_User/Tasker/Tasker_Timer.webp'
import taskerWiFiIcon from '../../../images/Advanced_User/Tasker/Tasker_WiFi.webp'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={taskerMobileIcon}
                    alt="Tasker: Activate Alarm"
                  />
              }
              title="Automation"
              description="Disable motion detection on your camera when your smart phone is dialling into the cellular network."
              footer={
                <EuiFlexGroup justifyContent="spaceBetween">
                  <Link to="/Advanced_User/Tasker/Motion_Detection_Deactivate_Add_Task/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Task
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                  <Link to="/Advanced_User/Tasker/Motion_Detection_Deactivate_Add_Profile/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Profile
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={taskerSceneIcon}
                    alt="Tasker: Activate Alarm"
                  />
              }
              title="Automation"
              description="Create an expandable Tasker Scene for switching night vision."
              footer={
                <EuiFlexGroup justifyContent="spaceBetween">
                  <Link to="/Advanced_User/Tasker/Activate_Nightvision_Task/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Task I
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                  <Link to="/Advanced_User/Tasker/Deactivate_Nightvision_Task/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Task II
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                  <Link to="/Advanced_User/Tasker/Nightvision_Scene/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Scene
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={taskerShortcutIcon}
                    alt="Tasker: Activate Alarm"
                  />
              }
              title="Automation"
              description="Create a Tasker Shortcut to turn the IR LEDs of your camera on and off."
              footer={
                <EuiFlexGroup justifyContent="spaceBetween">
                  <Link to="/Advanced_User/Tasker/Activate_IR_Add_Shortcut/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Shortcut I
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                  <Link to="/Advanced_User/Tasker/Deactivate_IR_Add_Shortcut/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Shortcut II
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={taskerTimerIcon}
                    alt="Tasker: Activate Alarm"
                  />
              }
              title="Automation"
              description="Turn your camera to another position and adjust the sensitivity of the motion detection."
              footer={
                <EuiFlexGroup justifyContent="spaceBetween">
                  <Link to="/Advanced_User/Tasker/Schedule_PTZ_Task/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Task I
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                  <Link to="/Advanced_User/Tasker/Schedule_Alarm_Task/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Task II
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                  <Link to="/Advanced_User/Tasker/Schedule_Profile/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Scene
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <img
                  src={taskerWiFiIcon}
                  alt="Tasker: Activate Alarm"
                />
              }
              title="Automation"
              description="Enable motion detection on your camera when your smart phone connects to your Wi-Fi network."
              footer={
                <EuiFlexGroup justifyContent="spaceBetween">
                  <Link to="/Advanced_User/Tasker/Motion_Detection_Add_Task/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Task
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                  <Link to="/Advanced_User/Tasker/Motion_Detection_Add_Profile/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Profile
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}