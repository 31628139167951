import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ImageAdvancedTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Advanced Image Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Presets', descriptionColumn: 'All the picture settings can be a bit overwhelming. The presets give you a starting point for common lighting conditions. Select a preset and click Apply. Then carry out a few more fine adjustments. If the camera image is completely "distorted", you can reset it to the standard values here.' },
        { actionColumn: 'Activate Image Settings', descriptionColumn: 'Your camera uses different image settings for day and night. By default this is set to automatic and will allow your camera to load the correct settings based on whether it is running in day or night mode. You can force your camera to load either one of them by selecting day or night settings here.' },
        { actionColumn: 'Day/Night', descriptionColumn: 'Click here to toggle between the settings for day or night. Note that your camera DOES NOT switch into the corresponding mode by clicking this button. only changes made to the currently active settings will be visible in your camera`s live video. Use the drop-down menu above to load the settings for the currently not active mode to help you to adjust those settings.' },
        { actionColumn: 'Wide Dynamic Range', descriptionColumn: 'Is your camera blinded by a strong backlight? Or is half of the image in shadow and details in that area are barely discernible. With the powerful WDR mode, you can handle these situations. Optimize the image by raising or lowering the gamma level below to get the maximum detail out of the camera image.' },
        { actionColumn: 'Distortion correction', descriptionColumn: 'Equalize the video image to counteract the fish-eye effect of a wide-angle lense.' },
        { actionColumn: 'WDR Strength', descriptionColumn: 'This slider adjusts the strength of the WDR mode. The higher the value, the more the sensor tries to compensate for areas of high contrast in your video image. High values can compensate for the glare effect of strong light sources. A high value for the WDR strength will lighten the shadows while reducing the brightness of the light source. Note that a high value of WDR causes blooming around light sources, making the image less sharp. Lower the WDR Strength to reduce this effect.' },
        { actionColumn: 'Deflicker', descriptionColumn: 'Light sources that operate on alternating current fluctuate in their light intensity with the frequency of the power source. This can cause the camera image to start flickering. Enable suppression to reduce this effect. Note that sometimes it is necessary to disable WDR mode if the flickering becomes too severe. Especially with LED light sources you can sometimes see dark bars wandering through the picture when WDR is activated.' },
        { actionColumn: 'Frequency', descriptionColumn: 'In Germany, the alternating current frequency is 50Hz. In the US, however, it is 60Hz. Set the country-specific frequency for flicker suppression here.' },
        { actionColumn: 'Max ISO', descriptionColumn: 'The ISO value corresponds to the maximum amplification of the sensor signal that the camera is allowed to use to brighten the image. The scale is divided into 32 steps, which corresponds to a range from 400 - 12800 ISO. The camera uses the maximum allowed setting only when it needs it. I.e. settings here will only have a visible effect when the camera is in the dark! Too high a value will result in excessive image noise.' },
        { actionColumn: 'Auto Denoise', descriptionColumn: 'Remove digital noise from your camera image.' },
        { actionColumn: 'Denoise Strength', descriptionColumn: 'Disable automatic denoising above to set a manual value. If you get too much noise with the image settings you have chosen, you can increase this value by over 15 levels. Please note, however, that a high value will cause you to lose detail in the video. A slightly noisy image is often easier to evaluate for surveillance purposes (e.g. to recognize number plates or faces).' },
        { actionColumn: 'Auto Gamma', descriptionColumn: 'Automatic gamma correction. Adjust the gamma of the image. Gamma correction raises (or lowers) the midtones in the image while leaving very dark and very light areas untouched. This means that while the Brightness value boosts underexposed images across the spectrum and the result is a very washed-out looking image, the Gamma value allows you to brighten (or darken) the image without losing contrast.' },
        { actionColumn: 'Gamma Strength', descriptionColumn: 'The image gets brighter as you increase the gamma value. This allows you to see more detail in the dark areas of the image without losing the information in the light areas. When the camera is glaring, e.g. from a strong light source at night, a very low gamma value can make the glare area visible in the image.' },
        { actionColumn: 'Auto Blacklevel', descriptionColumn: 'Automatic black level correction.' },
        { actionColumn: 'Blacklevel', descriptionColumn: 'Specify here when a brightness level is classified as black. Adjust the value until the image looks natural. Too high a value will give the image a green cast (in sunlight). If the value is too low, you will get a rose or pink colored live video.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ImageAdvancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ImageAdvancedTable)