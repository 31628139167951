import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class DownloadTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "changeColumn", title: "Changes" }
      ],
      rows: [
        { changeColumn: 'All executable files are now certified so they are no longer considered potentially dangerous under strict antivirus software settings.'},
        { changeColumn: 'The version is now also shown in the file details.'},
        { changeColumn: 'Under "About InstarVision" now the revision number is displayed and displayed whether it is a BETA version.'},
        { changeColumn: 'The revision number is now stored in the Windows registry and the configuration file.'},
        { changeColumn: 'The location where the configuration and log files are stored has been changed. As a result, logged-in Windows users can now use different InstarVision configurations (more details under "Fixed bugs").'},
        { changeColumn: 'The configuration file converter has been extended so that it is now possible to update directly from an older 2.x version. For example, an InstarVision 2.0 version can now be updated directly to this 2.3 version without going through the steps from 2.0 to 2.1, 2.1 to 2.2 and 2.2 to 2.3.'},
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

DownloadTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(DownloadTable)



// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'


// const cameraRow = [
//   'All executable files are now certified so they are no longer considered potentially dangerous under strict antivirus software settings.',
//   'The version is now also shown in the file details.',
//   'Under "About InstarVision" now the revision number is displayed and displayed whether it is a BETA version.',
//   'The revision number is now stored in the Windows registry and the configuration file.',
//   'The location where the configuration and log files are stored has been changed. As a result, logged-in Windows users can now use different InstarVision configurations (more details under "Fixed bugs").',
//   'The configuration file converter has been extended so that it is now possible to update directly from an older 2.x version. For example, an InstarVision 2.0 version can now be updated directly to this 2.3 version without going through the steps from 2.0 to 2.1, 2.1 to 2.2 and 2.2 to 2.3.',
// ]

// // Render your table
// const ConnectTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={cameraRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Release Notes</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {cameraRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={850}
//         />
//       </Table>
//     )
// }

// export default ConnectTable