import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase)
const CustomTable = withStyles(styles)(CustomTableBase)

class MotionTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "settingColumn", title: "Settings" },
        { name: "optionColumn", title: "Recommended" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { settingColumn: 'Use Built-In', optionColumn: 'Yes', descriptionColumn: 'Use Kevin Godell`s Motion Detector. This is built into Shinobi and requires no other configuration to activate.' },
        { settingColumn: 'Indifference', optionColumn: '1', descriptionColumn: 'When using Kevin`s Detector Indifference means "minimum percentage changed in region". This option varies based on camera, start with 1 and work your way up.' },
        { settingColumn: 'Max Indifference', optionColumn: ' ', descriptionColumn: 'As the name suggests it is a max value for Indifference.' },
        { settingColumn: 'Trigger Threshold', optionColumn: '1', descriptionColumn: 'Minimum number of detections to fire a motion event. Detections. Default is 1. Don`t adjust it until tuning begins.' },
        { settingColumn: 'Color Threshold', optionColumn: '9', descriptionColumn: 'The amount of difference allowed in a pixel before it is considered motion. Default is 9.' },
        { settingColumn: 'Full Frame Detection', optionColumn: 'No', descriptionColumn: 'Enabling Full Frame Detection will create a region that covers the entire frame. Only enable this if you aren`t using regions made in the Region Editor.' },
        { settingColumn: 'Noise Filter', optionColumn: 'No', descriptionColumn: 'Remove false detections due to noisy backgrounds' },
        { settingColumn: 'Noise Filter Range', optionColumn: '6', descriptionColumn: ' ' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}
  
  
MotionTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(MotionTable)

// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'

// const detectorRow = [
//   'Use Built-In',
//   'Indifference',
//   'Max Indifference',
//   'Trigger Threshold',
//   'Color Threshold',
//   'Full Frame Detection',
//   'Noise Filter',
//   'Noise Filter Range'
// ]




// const optionRow = [
//   'Yes',
//   '1',
//   ' ',
//   '1',
//   '9',
//   'No',
//   'No',
//   '6'
// ]



// const descriptionRow = [
//   'Use Kevin Godell`s Motion Detector. This is built into Shinobi and requires no other configuration to activate.',
//   'When using Kevin`s Detector Indifference means "minimum percentage changed in region". This option varies based on camera, start with 1 and work your way up.',
//   'As the name suggests it is a max value for Indifference.',
//   'Minimum number of detections to fire a motion event. Detections. Default is 1. Don`t adjust it until tuning begins.',
//   'The amount of difference allowed in a pixel before it is considered motion. Default is 9.',
//   'Enabling Full Frame Detection will create a region that covers the entire frame. Only enable this if you aren`t using regions made in the Region Editor.',
//   'Remove false detections due to noisy backgrounds',
//   ' '
// ]



// // Render your table
// const MotionTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={detectorRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Settings</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {detectorRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={150}
//         />
//         <Column
//           header={<Cell>Recommended</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {optionRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={100}
//         />
//         <Column
//           header={<Cell>Description</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {descriptionRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={2}
//           width={395}
//         />
//       </Table>
//     )
// }

// export default MotionTable