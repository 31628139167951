
import React, { useState } from 'react'
import {Link} from 'gatsby'

import {
  EuiCallOut,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiButton,
  EuiTitle,
  EuiText,
  EuiSpacer
} from '@elastic/eui'

import alexaBanner01 from "images/Products/Alexa/INSTAR_Cloud_Alexa_Skill_00.webp"
import alexaBanner02 from "images/Products/Alexa/INSTAR_Cloud_Alexa_Skill_15.webp"
import alexaBanner03 from "images/Products/Alexa/INSTAR_Cloud_Alexa_Skill_21.webp"

const FlyOut = () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

  const closeFlyout = () => setIsFlyoutVisible(false);

  const showFlyout = () => setIsFlyoutVisible(true);

  let flyout;

  const callOut = (
    <EuiCallOut iconType="help">
      <p>
      We offer both a <Link to="/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/">INSTAR Cloud Skill</Link> and a <Link to="/Web_User_Interface/1080p_Series/Smarthome/Alexa_Skill/">INSTAR Camera Skill</Link> in the Alexa Skill Store. For the INSTAR Cloud Skill, all you need is an INSTAR Cloud account and an Alexa device with a display. Add the Skill on your Alexa device and use it directly.
      </p>
    </EuiCallOut>
  );

  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={closeFlyout}
        size="l"
        aria-labelledby="flyoutWithBannerTitle">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="flyoutWithBannerTitle">INSTAR Alexa Camera Skills</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody banner={callOut}>
          <EuiText>
            <h4>Activate camera control through Alexa</h4>
          </EuiText>
          <img src={alexaBanner01} alt="INSTAR Amazon Alexa" style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}} />
          <EuiSpacer />
          <EuiText>
            <p>With the switch in the <Link to="/Web_User_Interface/1080p_Series/Smarthome/Alexa_Skill/">Smarthome menu</Link> in the WebUI of your camera, you give the INSTAR Camera Skill your permission to control your camera via the P2P protocol. The necessary access information is stored in your INSTAR Cloud account and will not be shared with third parties.</p>
          </EuiText>
          <EuiSpacer />
          <img src={alexaBanner02} alt="INSTAR Amazon Alexa" style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}} />
          <EuiSpacer />
          <EuiText>
            <p>In order for your camera to connect to the Amazon Cloud, you need to create a <Link to="/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/">INSTAR Cloud account for your camera</Link>. Creating a test account is <strong>free</strong> and gives you full access to all INSTAR Cloud features for 30 days. After this test period, you can no longer have alarm videos uploaded - but the account, and with it the possibility to use the Smart-Home Skill, remains with you.</p>.
            <p>The Cloud Skill is used to manage your alarm recordings in the INSTAR Cloud and can be used to the full extent during the test phase.</p>
            <EuiSpacer />
            <img src={alexaBanner03} alt="INSTAR Amazon Alexa" style={{ maxWidth: '100%', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'}} />
          </EuiText>
          <EuiSpacer />
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }
  return (
    <div>
      <EuiSpacer />
      <EuiButton onClick={showFlyout} color="text">Alexa</EuiButton>
      {flyout}
    </div>
  );
};

export default FlyOut;