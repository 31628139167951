import React from 'react'

import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButton
} from '@elastic/eui'


export default function ImageCard() {

  return (
    <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          title="Camera List"
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Camera_List/">
                  <EuiButton size="s" fill>
                    More
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }>
          <EuiText>
            <p>Add or edit your cameras and interactive site plans.</p>
          </EuiText>
        </EuiCard>
        </EuiFlexItem>
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          title="Layout"
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Layout/">
                  <EuiButton size="s" fill>
                    More
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }>
          <EuiText>
            <p>Arrange your cameras according to your needs.</p>
          </EuiText>
        </EuiCard>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          title="Camera PTZ"
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/PTZ/">
                  <EuiButton size="s" fill>
                    More
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }>
          <EuiText>
            <p>Control your camera´s Pan, Tilt and Zoom functions.</p>
          </EuiText>
        </EuiCard>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
