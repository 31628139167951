import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/1440p_Series_CGI_List/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import APISearch from 'components/Search/APISearch';
import APIGlossar from 'components/Search/APISearchGlossar';
import * as React from 'react';
export default {
  EuiSpacer,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  APISearch,
  APIGlossar,
  React
};