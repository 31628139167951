import { Link } from 'gatsby';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import PowerlineCards from 'components/Quick_Installation/Powerline/PowerlineCards';
import YoutubeVideo from 'components/Quick_Installation/Powerline/YoutubeVideoCardINLAN';
import PrimaryBox from 'components/Quick_Installation/Powerline/PrimaryBox';
import DangerBox from 'components/Quick_Installation/Powerline/DangerBox';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Powerline/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  EuiButton,
  PowerlineCards,
  YoutubeVideo,
  PrimaryBox,
  DangerBox,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};