import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import cloudSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud.webp'
import cloudAlexaSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_Alexa.webp'
import cloudIFTTTSearchIcon from '../../../images/Search/MD_SearchThumb_Cloud_IFTTT.webp'


export default function CloudArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/License_Plate_Recognition/">
                  <img
                    src={cloudSearchIcon}
                    alt="License Plate Recognition"
                  />
                </Link>
              }
              title="License Plate Recognition"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/License_Plate_Recognition/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/">
                  <img
                    src={cloudAlexaSearchIcon}
                    alt="Alexa Skill"
                  />
                </Link>
              }
              title="Alexa Skill"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/IFTTT/">
                  <img
                    src={cloudIFTTTSearchIcon}
                    alt="IFTTT Applets"
                  />
                </Link>
              }
              title="IFTTT Applets"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/IFTTT/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/Facial_Recognition/">
                  <img
                    src={cloudSearchIcon}
                    alt="Facial Recognition"
                  />
                </Link>
              }
              title="Facial Recognition"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/Facial_Recognition/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/Webhook/">
                  <img
                    src={cloudSearchIcon}
                    alt="Webhook"
                  />
                </Link>
              }
              title="Webhook"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/Webhook/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Motion_Detection/INSTAR_Cloud/Google_Home/">
                  <img
                    src={cloudSearchIcon}
                    alt="Google Home"
                  />
                </Link>
              }
              title="Google Home"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Motion_Detection/INSTAR_Cloud/Google_Home/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
