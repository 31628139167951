import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "numberColumn", title: "#" },
        { name: "componentColumn", title: "Component" },
        { name: "functionColumn", title: "Function" },
      ],
      rows: [
        { numberColumn: '01', componentColumn: 'Wall Bracket', functionColumn: 'Wall mounting' },
        { numberColumn: '02', componentColumn: 'Ceiling Bracket', functionColumn: 'Overhead installation' },
        { numberColumn: '03', componentColumn: 'Antenna Connector', functionColumn: 'RP-SMA WiFi antenna connector (not for PoE version)' },
        { numberColumn: '04', componentColumn: 'Dual LEDs', functionColumn: '6 high-power IR LEDs (@ 850nm), 6 high-power floodlight LEDs' },
        { numberColumn: '05', componentColumn: 'Autofocus Lense', functionColumn: '5x optical zoom lens with autofocus (2.7mm - 13.5mm)' },
        { numberColumn: '06', componentColumn: 'Photo Sensor', functionColumn: 'Twilight switch for IR LEDs' },
        { numberColumn: '07', componentColumn: 'PIR Sensor', functionColumn: 'Integrated Panasonic passive infrared (PIR) motion detector' },
        { numberColumn: '08', componentColumn: 'Microphone', functionColumn: 'For noise detection and audio recording' },
        { numberColumn: '09', componentColumn: 'Status LEDs ', functionColumn: 'Red: Camera on or blinking during firmware update, Blue: On during WiFi, blinking during WPS or reset' },
        { numberColumn: '10', componentColumn: 'MicroSD card slot', functionColumn: 'Slot for MicroSD/SDXC cards (max. 256GB) - 32GB included inside the camera' },
        { numberColumn: '11', componentColumn: 'Loudspeaker', functionColumn: 'Integrated loudspeaker' },
        { numberColumn: '12', componentColumn: 'Alarm input', functionColumn: 'Input for potential free external alarm sensors' },
        { numberColumn: '13', componentColumn: 'Alarm output', functionColumn: 'Output (relay) can support max. 24V/1A' },
        { numberColumn: '14', componentColumn: 'Reset', functionColumn: 'Press 20 seconds for reset' },
        { numberColumn: '15', componentColumn: 'Network LED', functionColumn: 'Indicator for network status, On: connected to network by network cable, Off: No power, Wifi connection or no network cable connected' },
        { numberColumn: '16', componentColumn: 'Network Port', functionColumn: 'RJ45 LAN port / connector for Cat5e LAN cable or higher' },
        { numberColumn: '17', componentColumn: 'Power Connector', functionColumn: 'For included 12V / 2A power supply' },
        { numberColumn: '18', componentColumn: 'Connection Cable', functionColumn: 'With LAN- and power connector as well as alarm input, as alarm output, reset' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)