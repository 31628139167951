import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import YoutubeVideoFTP from "components/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/YoutubeVideoCardFritzNAS";
import YoutubeVideoWin from "components/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/YoutubeVideoCardFritzStorageWin";
import YoutubeVideoMacOS from "components/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/YoutubeVideoCardFritzStorageMac";
import FritzCards from "components/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/FritzCards";
import NavButtons from 'components/Motion_Detection/NavButtons';
import ForumBox from 'components/Motion_Detection/Router_as_a_FTP_Server/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  OutboundLink,
  EuiSpacer,
  YoutubeVideoFTP,
  YoutubeVideoWin,
  YoutubeVideoMacOS,
  FritzCards,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};