import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Be aware:">
        <EuiText>
          <p>In newer versions of Debian the Ethernet interface will not be called <strong>eth0</strong> anymore. The file contains the line <code>iface eth0 inet dhcp</code> before you set it from <strong>dhcp</strong> to <strong>static</strong> - the identification between <strong>iface</strong> and <strong>inet</strong> is the id of your ethernet interface (don't change it to eth0!).</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default DangerBox

