import { EuiSpacer } from "@elastic/eui";
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Recording/Relay_Schedule/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/AlarmPerm';
import ScheduleTable from 'components/Web_User_Interface/1440p_Series/Recording/Relay_Schedule/relaySheduleTable';
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
import * as React from 'react';
export default {
  EuiSpacer,
  SuccessBox,
  PermissionBox,
  ScheduleTable,
  BreadCrumbs,
  SEOHelmet,
  React
};