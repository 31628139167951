import { EuiSpacer } from '@elastic/eui';
import P2PTable from 'components/Web_User_Interface/720p_Series/Network/P2P/p2pTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  P2PTable,
  BreadCrumbs,
  SEOHelmet,
  React
};