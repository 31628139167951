import React from 'react'
import { Link } from 'gatsby'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Node-RED">
        <EuiText>
         <h2>Alle Node-RED Tutorials</h2>
         <ul>
           <li><Link to="/Advanced_User/Alexa_Voice_Control_without_Cloud/">Alexa Voice Control w/o Cloud</Link></li>
           <li><Link to="/Advanced_User/Node-RED_Alarm_Event_Timeline/">Node-RED Alarm Ereigniszeitstrahl</Link></li>
           <li><Link to="/Advanced_User/Node-RED_and_IFTTT/">Node-RED and IFTTT</Link></li>
           <li><Link to="/Advanced_User/Node-RED_and_MQTT/">Node-RED and MQTT</Link></li>
           <li><Link to="/Advanced_User/Node-RED_and_ONVIF/">Node-RED and ONVIF</Link></li>
           <li><Link to="/Advanced_User/Alarmserver_Queries_for_your_Smarthome/">Node-RED and Alarmserver Queries</Link></li>
           <li><Link to="/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/">Node-RED and the INSTAR Cloud Webhook</Link></li>
           <li><Link to="/Advanced_User/Node-RED_and_SQL-Logging/">Node-RED and SQL-Logging</Link></li>
           <li><Link to="/Advanced_User/Node-RED_Dashboard_Live_Video/">Node-RED Dashboard Live Video</Link></li>
           <li><Link to="/Advanced_User/Node-RED_in_Docker/">Node-RED in Docker</Link></li>
           <li><Link to="/Advanced_User/Node-RED_on_Android/">Node-RED unter Android</Link></li>
           <li><Link to="/Advanced_User/INSTAR_MQTT_Broker/Node-RED/">INSTAR MQTT - Node-RED</Link></li>
         </ul>
         
         <h2>All Node-RED FAQs</h2>
         <ul>
           <li><Link to="/Frequently_Asked_Question/Node-RED_for_INSTAR_WQHD_MQTTv5_Cameras/">Node-RED with your WQHD INSTAR MQTTv5 Broker</Link></li>
           <li><Link to="/Frequently_Asked_Question/Mosquitto_2.0_with_Node-RED/">Mosquitto 2.0 with Node-RED</Link></li>
           <li><Link to="/Frequently_Asked_Question/HTML5_Video_mit_Node-RED_Dashboard/">HTML5 Video with the Node-RED Dashboard</Link></li>
           <li><Link to="/Frequently_Asked_Question/ioBroker_INSTAR_Camera_Vis_with_Node-RED/">ioBroker INSTAR Camera Vis with Node-RED</Link></li>
           <li><Link to="/Frequently_Asked_Question/Homebridge_in_Node/">Homebridge in Node-RED</Link></li>
           <li><Link to="/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_Motion_Tracking/">INSTAR MQTT Alarmserver Motion Tracking</Link></li>
           <li><Link to="/Frequently_Asked_Question/INSTAR_MQTT_Node-RED_Self-Signed_Certificate/">INSTAR MQTT Node-RED selfsigned Certificate</Link></li>
         </ul>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox
