import { Link } from "gatsby";
import { EuiSpacer } from "@elastic/eui";
import DangerBox from "components/Outdoor_Cameras/IN-9408_WQHD/Video_Streaming/DangerBox";
import PrimaryBox from "components/Outdoor_Cameras/IN-9408_WQHD/Video_Streaming/PrimaryBox";
import NavButtons from "components/Outdoor_Cameras/IN-9408_WQHD/NavButtons";
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
import VideoFormats from 'components/Products/Usermanuals/YoutubeVideoStreamingFormats';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  DangerBox,
  PrimaryBox,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  VideoFormats,
  React
};