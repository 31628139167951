import React from 'react'

import { EuiAvatar, EuiText, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import {
  EuiCommentList,
} from '@elastic/eui'

const wqhdSeriesAvatar = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>
      <EuiAvatar
        imageUrl="/en/images/Assistants/INSTAR-Ico.webp"
        size="s"
        name="WQHD 1440p 2k+ Camera Series"
      />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>WQHD 1440p 2k+ Camera Series</EuiFlexItem>
  </EuiFlexGroup>
);

const wqhdSeriesBody = (
    <EuiText>
        <ul>
            <li><code>/param.cgi?cmd=setmdattr&area=1&enable=0&cmd=setmdattr&area=2&enable==0&cmd=setmdattr&area=3&enable==0&cmd=setmdattr&area=4&enable=0?cmd=setpirattr&enable=0&user=<em>USERNAME</em>&pwd=<em>PASSWORD</em></code></li>
            <li><code>/param.cgi?cmd=setmdattr&area=1&enable=1&cmd=setmdattr&area=2&enable=1&cmd=setmdattr&area=3&enable=1&cmd=setmdattr&area=4&enable=1?cmd=setpirattr&enable=1&user=<em>USERNAME</em>&pwd=<em>PASSWORD</em></code></li>
        </ul>
    </EuiText>
);

const fullhdSeriesAvatar = (
  <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s">
    <EuiFlexItem grow={false}>
      <EuiAvatar
        imageUrl="/en/images/Assistants/INSTAR-Ico.webp"
        size="s"
        name="Full HD 1080p Camera Series"
      />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>Full HD 1080p Camera Series</EuiFlexItem>
  </EuiFlexGroup>
);

const fullhdSeriesBody = (
    <EuiText>
        <ul>
        <li><code>/param.cgi?cmd=setmdattr&-name=1&-enable=0&cmd=setmdattr&-name=2&-enable=0&cmd=setmdattr&-name=3&-enable=0&cmd=setmdattr&-name=4&-enable=0?cmd=setpirattr&-pir_enable=0&-usr=<em>USERNAME</em>&-pwd=<em>PASSWORD</em></code></li>
        <li><code>/param.cgi?cmd=setmdattr&-name=1&-enable=1&cmd=setmdattr&-name=2&-enable=1&cmd=setmdattr&-name=3&-enable=1&cmd=setmdattr&-name=4&-enable=1?cmd=setpirattr&-pir_enable=1&-usr=<em>USERNAME</em>&-pwd=<em>PASSWORD</em></code></li>
        </ul>
    </EuiText>
);

const comments = [
  {
    username: wqhdSeriesAvatar,
    children: wqhdSeriesBody,
    timelineIcon: 'tag',
  },
  {
    username: fullhdSeriesAvatar,
    children: fullhdSeriesBody,
    timelineIcon: 'tag',
  }
];

const SetMdAttr = () => <EuiCommentList comments={comments} />;

export default SetMdAttr