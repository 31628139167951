import { Link } from 'gatsby';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import YoutubeWindowsCard from 'components/Advanced_User/Restore_Firmware/YoutubeWindowsCard';
import YoutubeMacOSCard from 'components/Advanced_User/Restore_Firmware/YoutubeMacOSCard';
import YoutubeWinXPCard from 'components/Advanced_User/Restore_Firmware/YoutubeWinXPCard';
import YoutubeRestoreCard from 'components/Advanced_User/Restore_Firmware/YoutubeRestoreCard';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  EuiSpacer,
  EuiButton,
  YoutubeWindowsCard,
  YoutubeMacOSCard,
  YoutubeWinXPCard,
  YoutubeRestoreCard,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};