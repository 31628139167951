import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Multimedia/Video/SuccessBox';
import VideoTable from 'components/Web_User_Interface/1080p_Series/Multimedia/Video/videoTable';
import PrimaryBox from 'components/Web_User_Interface/1080p_Series/Multimedia/Video/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  SuccessBox,
  VideoTable,
  PrimaryBox,
  BreadCrumbs,
  SEOHelmet,
  React
};