import React from 'react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiCard
} from '@elastic/eui'


export default function QRDownloads() {

  return (
    <EuiFlexGroup gutterSize="l">
    <EuiFlexItem>
      <EuiCard
        textAlign="left"
        href="https://apps.apple.com/us/app/instarvision/id413109553"
        image="/en/images/Downloads/Qrcode_iOS-AppStore_iPad.webp"
        title={'InstarVision for iPhone'}
        description="Download the InstarVision iPhone App from the Apple Store."
      />
    </EuiFlexItem>
    <EuiFlexItem>
      <EuiCard
        textAlign="left"
        href="https://apps.apple.com/us/app/instarvision-for-ipad/id767539403"
        image="/en/images/Downloads/Qrcode_iOS-AppStore_iPad.webp"
        title={'InstarVision for iPad'}
        description="Download the InstarVision iPad App from the Apple Store."
      />
    </EuiFlexItem>
  </EuiFlexGroup>
  );
}