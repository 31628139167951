import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ImageTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Image Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Reset', descriptionColumn: 'This button resets the image settings to their default values.' },
        { actionColumn: 'Brightness', descriptionColumn: 'Adjust the brightness of the video image.' },
        { actionColumn: 'Saturation', descriptionColumn: 'Adjust the saturation of the video image.' },
        { actionColumn: 'Contrast', descriptionColumn: 'Adjust the contrast of the video image.' },
        { actionColumn: 'Exposure', descriptionColumn: 'Adjust the exposure time (shutter) of the image sensor.' },
        { actionColumn: 'Sharpness', descriptionColumn: 'Adjust the sharpness of the video image.' },
        { actionColumn: 'Flip & Mirror', descriptionColumn: 'If you installed your camera over-head, simply flip and mirror the video to see it upright again.' },
        { actionColumn: 'Show Timestamp', descriptionColumn: 'Set if you want your camera time to be displayed inside the video frame. The timestamp will also be visible in video recordings.' },
        { actionColumn: 'Show Camera Name', descriptionColumn: 'Set if you want your camera name to be displayed inside the video frame. The camera name will also be visible in video recordings. You can type in a camera name in the input field below. Please be aware, that you cannot leave the camera name empty. Some Third-party Application (QNAP) determine the camera model by their camera name and won´t allow _"unknown models"_ to be used with the INSTAR profile. In this case, you will have to reset your camera name back to its default - e.g. IN-6012HD.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ImageTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ImageTable)