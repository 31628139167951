import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'FAQs',
      href: '/en/FAQs/'
    },
    {
      text: 'Lenses',
      href: '/en/Products/Lenses/',
    },
    {
      text: 'Cloud Services',
      href: '/en/Motion_Detection/INSTAR_Cloud/',
    },
    {
      text: 'Software',
      href: '/en/Software/',
    },
    {
      text: 'Others Products',
      href: '/en/Products/Others/',
    },
    {
      text: 'WebUI',
      href: '/en/Products/Web_User_Interface/',
    },
    {
      text: 'Usermanuals',
      href: '/en/Products/Usermanuals/',
    },
    {
      text: '◄ Products',
      href: '/en/Products/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Cloud Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}