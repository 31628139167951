import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Series/Alarm/Actions/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Alarm_Alarm_Actions.webp'
                    alt="Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Actions"
              description="Adjust the cameras behaviour in case of a motion alert trigger event. You can set the camera to send you an email and to save snapshots or videos on your SD card..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1080p_Series/Alarm/Actions/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Series/Alarm/Areas/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Alarm_Alarm_Areas.webp'
                    alt="Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Motion Detection Areas"
              description="The alarm areas detect motion by a picture analysis that is sensitive to every change inside the video frame instead of employing an infrared or microwave sensor..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1080p_Series/Alarm/Areas/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Series/Alarm/Schedule/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Alarm_Schedule.webp'
                    alt="Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Schedule"
              description="Here you can define a time schedule in which the motion detection should be active for the active detection areas. Every blue square represents 30 minutes of armed motion..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1080p_Series/Alarm/Schedule/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Series/Alarm/Push_Service/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Alarm_Push_Service.webp'
                    alt="Features Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Push Service"
              description="Your camera can notify you by Email every time a motion detection is triggered. A push notification serves the same purpose but is a lot faster! Activate Push if you want..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1080p_Series/Alarm/Push_Service/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
