import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText
} from '@elastic/eui'

import qiSelect01 from "../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/Ethernet/Quickinstallation_Tile_9008_Step_09.webp"
import qiSelect02 from "../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/Ethernet/Quickinstallation_Tile_9008_Step_10.webp"
import qiSelect03 from "../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/Ethernet/Quickinstallation_Tile_9008_Step_11.webp"
import qiSelect04 from "../../../../../images/Outdoor_Cameras/IN-9008_HD/Quick_Installation/Ethernet/Quickinstallation_Tile_9008_Step_12.webp"




export default function QIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='III-d Router Connection'
              image={
                  <img
                    src={qiSelect01}
                    alt="Connect your camera´s Ethernet cable with the Ethernet Port to connect your camera to a free LAN port on your internet router. (Recommended)"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Connect your camera´s Ethernet cable with the Ethernet Port to connect your camera to a free LAN port on your internet router. (Recommended)</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='III-e Camera Tool'
              image={
                  <img
                    src={qiSelect02}
                    alt="2-hole Sealing Ring: to connect the Power Cable and LAN Cable"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">Start our <OutboundLink href="https://install.instar.com/WQHDv2/IN-9408_WQHD_Installation/01_LAN_Integration/03_Find_your_Camera">IP Camera Tool</OutboundLink> from the Software CD and double click on the name of the camera.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />
      
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='III-f User Login'
              image={
                  <img
                    src={qiSelect03}
                    alt="The default administrator login is User/Password: admin / instar. Our Installation Wizard will lead you through the rest of the installation process."
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">The default administrator login is User/Password: <strong>admin / instar</strong>. Our <OutboundLink href="http://install.instar.de/">Installation Wizard</OutboundLink> will lead you through the rest of the installation process.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              title='III-g Video Plugin'
              image={
                  <img
                    src={qiSelect04}
                    alt="2-hole Sealing Ring: to connect the Power Cable and LAN Cable"
                  />
              }
              footer={
                <React.Fragment>
                  <EuiText size="s">You <strong>do not</strong> need to install the Adobe Flash Plugin to display your cameras h.264 video stream. You can use the <Link to="https://wiki.instar.com/en/Quick_Installation/Live_Video/">HTML5 Stream</Link> without the need to install a Browser plugins on your machine.</EuiText>
                  <EuiSpacer size="s" />
                </React.Fragment>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
