import { EuiSpacer } from '@elastic/eui';
import InstarVisionCard from 'components/Software/macOS/InstarVision/Webserver/instarVisionCard01';
import InstarVisionCards from 'components/Software/macOS/InstarVision/Webserver/instarVisionCards01';
import PrimaryBox from 'components/Software/macOS/InstarVision/Webserver/PrimaryBox';
import UserTable from 'components/Software/macOS/InstarVision/Webserver/userTable';
import NavButtons from 'components/Software/macOS/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  InstarVisionCard,
  InstarVisionCards,
  PrimaryBox,
  UserTable,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};