import React from 'react'
import {Link} from 'gatsby'

import {
  EuiAccordion,
  EuiText,
  EuiSpacer,
  EuiImage,
  EuiPanel
} from '@elastic/eui'

import Trigger01 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/trigger-01.webp'
import Trigger01Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/trigger-01-set-1.webp'
import Trigger02 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/trigger-02.webp'
import Trigger03 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/trigger-03.webp'
import Trigger04 from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/trigger-04.webp'
import Trigger04Set from '../../../../../images/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/trigger-04-set-1.webp'


const Accordion = () => (
  <React.Fragment>
    <EuiPanel hasBorder>
      <EuiAccordion
        id="accordion1"
        buttonContent="Cloud storage will expire"
        paddingSize="l"
        style={{height: "fit-content"}}>
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Cloud storage will expire"
                src={Trigger01}
              />
              <p>This trigger fires when your INSTAR Cloud storage plan is coming close to it's expiration. You need to choose the number of days:</p>
              <Link to="/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
              <EuiSpacer size="xl" />
              <EuiImage
                size="l"
                float="right"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Cloud storage will expire"
                src={Trigger01Set}
              />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

    
      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion2"
          buttonContent="Cloud storage is full"
          buttonContentClassName="eui-textTruncate"
          paddingSize="l">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Cloud storage is full"
                src={Trigger02}
              />
              <p>This trigger fires when your Cloud storage reaches it's maximum capacity:</p>
              <Link to="/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion3"
          buttonContent="Too many false alarms"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Too many false alarms"
                src={Trigger03}
              />
              <p>This trigger fires when you have more than 500 alarm videos of which more than 80% are recognized as false alarms:</p>
              <Link to="/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel hasBorder>
        <EuiAccordion
          id="accordion4"
          buttonContent="Too many alarm uploads"
          paddingSize="m">
          <EuiPanel color="subdued">
            <EuiText style={{height: 500}}>
              <EuiImage
                size="l"
                float="left"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Too many alarm uploads"
                src={Trigger04}
              />
              <p>This trigger fires when the amount of alarm uploads per day exceeds a specific number. You need to set the quantity:</p>
              <Link to="/Motion_Detection/INSTAR_Cloud/IFTTT/INSTAR_IFTTT_Triggers_and_Actions/#secondary-heading-and-columns">Learn more</Link>
              <EuiSpacer size="xl" />
              <EuiImage
                size="l"
                float="right"
                margin="l"
                hasShadow
                allowFullScreen
                alt="IFTTT Too many alarm uploads"
                src={Trigger04Set}
              />
            </EuiText>
          </EuiPanel>
        </EuiAccordion>
      </EuiPanel>
  </React.Fragment>
);

export default Accordion