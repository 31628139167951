import React from 'react'
import {Link} from 'gatsby'

import {
    EuiText,
    EuiCallOut,
    EuiSpacer
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Camera Reset & WPS">
        <EuiText>
          <b>If you are no longer able to access your cameras web user interface you can press the reset button on your camera. You will see the Status LEDs turn off and...</b>
          <ol>
            <EuiSpacer />
            <li><b>After 3s</b>: Turn the Status LEDs on and start the <Link to="/Web_User_Interface/1440p_Serie/Netzwerk/WLAN/">Wireless-Protected-Setup</Link> or <b>WPS</b> mode that allows you to add your camera to your local WiFi.</li>
            <li><b>After 15s</b>: Let the Status LEDs flash slowly and after releasing the button reset all your camera settings <b>except the network configuration</b>.</li>
            <li><b>After 20s</b>: Let the Status LEDs flash fast and after releasing the button reset <b>ALL</b> your camera settings.</li>
            <li><b>After 30s</b>: Turn the Status LEDs off and roll back your camera to the state it had before the last Firmware update and restart your camera.</li>
          </ol>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox