import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Homey',
      href: '/en/Software/Other_Platforms/Athom_Homey/'
    },
    {
      text: 'Fritzphone',
      href: '/en/Software/Other_Platforms/AVM_Fritzphone/'
    },
    {
      text: 'homee',
      href: '/en/Software/Other_Platforms/Homee/',
    },
    {
      text: 'Homematic',
      href: '/en/Software/Other_Platforms/Homematic_IP/',
    },
    {
      text: 'Symcon',
      href: '/en/Software/Other_Platforms/IP_Symcon',
    },
    {
      text: 'Loxone',
      href: '/en/Software/Other_Platforms/Loxone_Miniserver/',
    },
    {
      text: 'Lupus',
      href: '/en/Software/Other_Platforms/Lupus_XT2_Plus/',
    },
    {
      text: 'QNAP',
      href: '/en/Software/Other_Platforms/QNAP/',
    },
    {
      text: 'Synology',
      href: '/en/Software/Other_Platforms/Synology/',
    },
    {
      text: '◄ Other',
      href: '/en/Software/Other_Platforms/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Software Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}