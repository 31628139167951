import React from 'react'

import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButton
} from '@elastic/eui'


import ivImage04 from '../../../../../images/Software/Windows/InstarVision/Record/Image044_small.webp'
import ivImage05 from '../../../../../images/Software/Windows/InstarVision/Record/Image045-1_small.webp'
import ivImage06 from '../../../../../images/Software/Windows/InstarVision/Record/Image045-2_small.webp'

export default function ImageCard() {

  return (
    <EuiFlexGroup gutterSize="l" direction="row" >
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage04}
                alt="Set up an automated snapshot recording."
              />
            </div>
          }
          title="Photoseries"
          description="Set up an automated snapshot recording."
          content={
            <EuiText>
              <p>Set up an automated snapshot recording.</p>
            </EuiText>
          }
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Record/Photoseries/">
                  <EuiButton size="s" fill>
                    More
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
        </EuiFlexItem>
        <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage05}
                alt="Let InstarVision notify you with alarm emails."
              />
            </div>
          }
          title="Email"
          description="Let InstarVision notify you with alarm emails."
          content={
            <EuiText>
              <p>Let InstarVision notify you with alarm emails.</p>
            </EuiText>
          }
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Record/Email/">
                  <EuiButton size="s" fill>
                    More
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
        </EuiFlexItem>
        <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage06}
                alt="Backup your alarm recordings to a secure location."
              />
            </div>
          }
          title="FTP Server"
          description="Backup your alarm recordings to a secure location."
          content={
            <EuiText>
              <p>Backup your alarm recordings to a secure location.</p>
            </EuiText>
          }
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Record/FTP_Server/">
                  <EuiButton size="s" fill>
                    More
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
        </EuiFlexItem>
    </EuiFlexGroup>
  );
}
