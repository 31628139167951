import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function DangerBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Achtung:">
        <EuiText>
          <p>Wir hatten keine Benutzerauthentifizierung beim MQTT Server hinterlegt. D.h. jedes Gerät, das in Ihr lokales Netzwerk eingebunden ist, kann über den Server MQTT Meldungen empfangen und auch welche versenden!</p>
          <p>Sie sollten unter keinen Umständen eine <strong>Portweiterleitung auf dem Port 1883</strong> in Ihrem Router anlegen!</p>
          <p>Wenn außer Ihnen noch andere Personen Zugriff auf das Netzwerk haben, sollten Sie per SSH Zugriff die Konfigurationsdateien des MQTT Servers (s.o.) mit einem Admin Login versehen!</p>
        </EuiText >
    </EuiCallOut>
  );
}

export default DangerBox