import React from 'react'

import {EuiFlexGroup, EuiFlexItem, EuiText} from '@elastic/eui'

import IN6012RadarIN8015 from "./in6012-radargraph-8015"
import IN6012RadarIN3011 from "./in6012-radargraph-3011"

function IN6012RadarGrid() {
  return (
    <div style={{ padding: '15px',maxWidth: '1115px', margin: '2.5rem 0 2.5rem 0', boxShadow: '0 2px 3px 0 rgb(0 0 0 / 36%' }}>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiText size="s">
            IN-6012HD vs IN-8015FHD
          </EuiText>
          <div style={{height: 275}}>
            <IN6012RadarIN8015 />
          </div>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText size="s">
            IN-6012HD vs IN-3011
          </EuiText>
          <div style={{height: 275}}>
            <IN6012RadarIN3011 />
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}

export default IN6012RadarGrid
