import { EuiSpacer } from "@elastic/eui";
import NavButtons from "components/Software/Linux/NavButtons";
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
import * as React from 'react';
export default {
  EuiSpacer,
  NavButtons,
  BreadCrumbs,
  SEOHelmet,
  React
};