import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Series/Multimedia/Video/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Multimedia_Video.webp'
                    alt="Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Video"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Series/Multimedia/Video/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Series/Multimedia/Image/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Multimedia_Image.webp'
                    alt="Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Image"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Series/Multimedia/Image/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Series/Multimedia/Privacy_Mask/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Multimedia_Privat.webp'
                    alt="Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Privacy Mask"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Series/Multimedia/Privacy_Mask/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Series/Multimedia/Audio/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Multimedia_Audio.webp'
                    alt="Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Audio"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Series/Multimedia/Audio/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Web_User_Interface/1080p_Series/Multimedia/Video_Overlays/">
                  <StaticImage
                    src='../../../../images/Search/WebUI_1080p_SearchThumb_Multimedia_Overlays.webp'
                    alt="Multimedia Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Video Overlays"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Series/Multimedia/Video_Overlays/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        More
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
