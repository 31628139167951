import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ImageTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Image Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Presets', descriptionColumn: 'All the picture settings can be a bit overwhelming. The presets give you a starting point for common lighting conditions. Select a preset and click Apply. Then carry out a few more fine adjustments. If the camera image is completely "distorted", you can reset it to the standard values here.' },
        { actionColumn: 'Activate Image Settings', descriptionColumn: 'Your camera uses different image settings for day and night. By default this is set to automatic and will allow your camera to load the correct settings based on whether it is running in day or night mode. You can force your camera to load either one of them by selecting day or night settings here.' },
        { actionColumn: 'Day/Night', descriptionColumn: 'Click here to toggle between the settings for day or night. Note that your camera DOES NOT switch into the corresponding mode by clicking this button. only changes made to the currently active settings will be visible in your camera`s live video. Use the drop-down menu above to load the settings for the currently not active mode to help you to adjust those settings.' },
        { actionColumn: 'Hue', descriptionColumn: 'Use the Hue slider to change the hue of your video image. The Hue parameter allows you to make color adjustments if the automatic white balance doesn`t meet your expectations.' },
        { actionColumn: 'Saturation', descriptionColumn: 'Adjust the saturation of the video image. To get stronger colors in the video, you can set a value greater than 50 here. A value below 50 desaturates the image. This variable is only available in the camera`s tag mode.' },
        { actionColumn: 'Contrast', descriptionColumn: 'Adjust the contrast of the video image. A high contrast makes the image appear sharper. While too low a value gives the picture a "washed out" impression. However, too high a value can cause you to lose detail in the very light or very dark areas of the image. When increasing the contrast, it often helps to increase the brightness a little (and vice versa) in order to get a natural picture.' },
        { actionColumn: 'Sharpness', descriptionColumn: 'Adjust the sharpness of the video image. This value does not adjust the lens to get better focus, but rather traces high-contrast edges in the video to get a higher sharpening of the image. You can leave the value at around 70. Please note, however, that sharpening in detailed scenes, e.g. green spaces, can lead to unsightly digital artefacts in the image. In this case you should reduce the value.' },
        { actionColumn: 'Brightness', descriptionColumn: 'Adjust the brightness of the video image. To get a brighter picture, you can enter the value 75 here. As described above, you should adjust the contrast in the same direction to get a natural picture.' },
        { actionColumn: 'Vibrance', descriptionColumn: 'Vibrance describes the liveliness of the color representation. Unlike image saturation, this only boosts tones that aren`t already highly saturated. Vibrance thus enables you to specifically raise areas with weak color saturation.' },
        { actionColumn: 'Rotate (vertically)', descriptionColumn: 'Here, mirror the image around its vertical centroid axis.' },
        { actionColumn: 'Rotate (horizontally)', descriptionColumn: 'Here, mirror the image around its horizontal centroid axis.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ImageTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ImageTable)