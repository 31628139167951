import { Link } from 'gatsby';
import PrimaryBox from 'components/Motion_Detection/Setup/VGA_Series/PrimaryBox';
import YoutubeVideo from 'components/Motion_Detection/Setup/YoutubeVideoCardSetup';
import NavButtons from 'components/Motion_Detection/Setup/NavButtons';
import ForumBox from 'components/Motion_Detection/Setup/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  Link,
  PrimaryBox,
  YoutubeVideo,
  NavButtons,
  ForumBox,
  BreadCrumbs,
  SEOHelmet,
  React
};