import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class ConfigTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "parameterColumn", title: "Parameter" },
        { name: "valueColumn", title: "Description" }
      ],
      rows: [
        { parameterColumn: 'Auswahl', valueColumn: 'Leave this space empty' },
        { parameterColumn: 'Bezeichnung', valueColumn: 'Choose a name for your camera, for example "Camera Entrance"' },
        { parameterColumn: 'Gültig für Gerät', valueColumn: 'Here choose the IP-address of your camera' },
        { parameterColumn: 'Protokoll', valueColumn: 'Here you choose TCP' },
        { parameterColumn: 'Umgeleitete Ports - Öffentlich', valueColumn: 'Here choose the same port as set in step 1: 8081 for Camera 1, 8082 for camera 2, etc.' },
        { parameterColumn: 'Umgeleitete Ports - Private Client', valueColumn: 'Here choose as well the same port as set in step 1: 8081 for Camera 1, 8082 for camera 2, etc.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

ConfigTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(ConfigTable)