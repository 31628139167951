import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="alert" title="Additional Remark">
        <EuiText>
          <p>Your camera supports a range of encryption standards - we recommend for your safety and reliability of your connection, that you set your WiFi network to WPA2 (PSK) with AES (or CCMP). With older routers choose WPA (PSK) / AES. Avoid using a mixed mode like WPA/WPA2 - TKIP! If your WiFi stays unstable please choose a fixed WiFi channel for your router - we recommend the channels 1 - 6.</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

