import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Series_CGI_List/Smarthome_Menu/Alarm_Server/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Smarthome_Menu/1440pCGIs-WebUI_Alarm_Server.png'
                    alt="1440p Series CGI List - Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alarm Server"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Series_CGI_List/Smarthome_Menu/MQTT/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Smarthome_Menu/1440pCGIs-WebUI_MQTT.png'
                    alt="1440p Series CGI List - Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="MQTT"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Series_CGI_List/Smarthome_Menu/IFTTT/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Smarthome_Menu/1440pCGIs-WebUI_IFTTT.png'
                    alt="1440p Series CGI List - Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="IFTTT"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Series_CGI_List/Smarthome_Menu/Homekit/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Smarthome_Menu/1440pCGIs-WebUI_Homekit.png'
                    alt="1440p Series CGI List - Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Homekit"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/1440p_Series_CGI_List/Smarthome_Menu/Alexa/">
                  <StaticImage
                    src='../../../images/1440p_Series_CGI_List/Smarthome_Menu/1440pCGIs-WebUI_Alexa.png'
                    alt="1440p Series CGI List - Smarthome Menu"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Alexa"
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
