import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="Manual Video Recording">
        <EuiText>
          <p>
          Set the total duration and resolution for manual recordings here, which can be initiated via the quick access bar. Note that the preset lead time is included in the total recording duration.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

