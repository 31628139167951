import React from 'react'

import {Link} from 'gatsby'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButton
} from '@elastic/eui'


import ivImage04 from '../../../../../images/Software/Windows/InstarVision/Advanced/Image050-Player_small.webp'
import ivImage05 from '../../../../../images/Software/Windows/InstarVision/Advanced/Image049_Export_small.webp'


export default function ImageCard() {

  return (
    <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
      <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage04}
                alt="INSTAR Video Player"
              />
            </div>
          }
          title="Player"
          description="INSTAR Video Player"
          content={
            <EuiText>
              <p>INSTAR Video Player.</p>
            </EuiText>
          }
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Advanced/Player/">
                  <EuiButton size="s" fill>
                    More
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
        </EuiFlexItem>
        <EuiFlexItem>
        <EuiCard
          textAlign="left"
          image={
            <div>
              <img
                src={ivImage05}
                alt="Open in Windows Explorer"
              />
            </div>
          }
          title="Explorer"
          description="Open in Windows Explorer"
          content={
            <EuiText>
              <p>Open in Windows Explorer.</p>
            </EuiText>
          }
          footer={
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <Link to="/Software/Windows/InstarVision/Advanced/Explorer/">
                  <EuiButton size="s" fill>
                    More
                  </EuiButton>
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        />
        </EuiFlexItem>
        <EuiFlexItem>
        </EuiFlexItem>
    </EuiFlexGroup>
  );
}
