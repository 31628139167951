import React from 'react'

import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" iconType="gear" color="success" title="INSTAR Homekit">
        <EuiText>
          <p>
            Use your INSTAR Full HD IP Camera in Apple Homekit. HomeKit is a software framework by Apple, made available in iOS/iPadOS that lets you communicate with, and control your camera using Apple devices. It provides you with a way to automatically discover your camera and access it`s live video.
          </p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

