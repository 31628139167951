import React from 'react'

import {EuiFlexGroup, EuiFlexItem, EuiText} from '@elastic/eui'

import IN7011RadarIN9008 from "./in7011-radargraph-9008"
import IN7011RadarIN2905 from "./in7011-radargraph-2905"

function IN7011RadarGrid() {

  return (
    <div style={{ padding: '15px',maxWidth: '1115px', margin: '2.5rem 0 2.5rem 0', boxShadow: '0 2px 3px 0 rgb(0 0 0 / 36%' }}>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiText size="s">
            IN-7011HD vs IN-9008FHD
          </EuiText>
          <div style={{height: 275}}>
            <IN7011RadarIN9008 />
          </div>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText size="s">
            IN-7011HD vs IN-2905
          </EuiText>
          <div style={{height: 275}}>
            <IN7011RadarIN2905 />
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}

export default IN7011RadarGrid