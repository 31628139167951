import React from 'react'

import { Player } from 'video-react'

import {
    EuiText,
    EuiIcon,
    EuiPanel
  } from '@elastic/eui'

import { OutboundLink } from 'gatsby-plugin-gtag'

require('../../../Layout/chrome/assets/video.css')

function VideoCard() {
  return (
    <EuiPanel paddingSize="m">
        <Player>
            <source src="https://wiki.instar.com/videos/Verbindung_der_INSTAR_IN9008_Full_HD_per_LAN_bzw_WLAN_WPS.mp4" />
        </Player>
        <EuiText>
            <p>In diesem Video erklären wir, wie Sie die IN-9008 Full HD mit dem Netzwerk verbinden. Dabei gibt es zwei Mögliche Vorgehensweisen.</p>
            <ul>
              <li>1. Per LAN Kabel (um dann z.B. später die WLAN Einstellungen vorzunehmen)</li>
              <li>2. Per WPS um die Kamera ohne das Anschließen eines LAN Kabels mit dem WLAN zu verbinden.</li>
            </ul>
            <p>Auf <OutboundLink href="https://www.youtube.com/watch?v=m66PEKpBMwo" target="_blank" rel="noopener noreferrer">Youtube ansehen <EuiIcon type="popout" /></OutboundLink></p>
        </EuiText>
    </EuiPanel>
  );
}

export default VideoCard