import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'


import {
    EuiText,
    EuiCallOut
  } from '@elastic/eui'

function PrimaryBox() {
  return (
    <EuiCallOut size="m" color="danger" iconType="alert" title="Forbidden Characters">
        <EuiText>
          <h3>URL Encoding</h3>
          <p>All CGI commands as well as video and snapshot URLs can be used by adding your user login to the URL to automatically authenticate yourself. This method is called <strong>BasicAuth</strong> or basic authentication. Note that the group of characters allowed in this method is limited and adding special characters to your login can break this method. For example adding a <strong>?</strong> or <strong>@</strong> to your password like in the following example does not work:</p>
          <ul>
            <li>/snap.cgi?chn=11&user=myuser&pwd=P@ssword?123</li>
          </ul>
          <p>Adding this URL to your cameras IP inside the browser address bar will return an error message because the authentication will be rejected. But you can escape those forbidden characters with URL encoding. You can find a list of all forbidden characters and their encoding on the <OutboundLink href='https://www.w3schools.com/tags/ref_urlencode.ASP' target="_blank" rel="noreferrer">W3 Website</OutboundLink>. So the <strong>@</strong> can be substituted with <strong>%40</strong> and <strong>?</strong> with <strong>%3F</strong> like this:</p>
          <ul>
            <li>/snap.cgi?chn=11&user=myuser&pwd=P%40ssword%3F123</li>
          </ul>
          <p>Check the <OutboundLink href='https://www.w3schools.com/tags/ref_urlencode.ASP' target="_blank" rel="noreferrer">W3 Website</OutboundLink> for a list of all encodings!</p>
        </EuiText>
    </EuiCallOut>
  );
}

export default PrimaryBox

