import { EuiSpacer } from '@elastic/eui';
import LogTable from 'components/Web_User_Interface/720p_Series/System/System_Log/logTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import * as React from 'react';
export default {
  EuiSpacer,
  LogTable,
  BreadCrumbs,
  SEOHelmet,
  React
};