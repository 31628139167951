import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class FeatureTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "catColumn", title: "Category" },
        { name: "connectionColumn", title: "Connection" },
      ],
      rows: [
        { catColumn: 'FTP Server', connectionColumn: 'Please enter the address of your FTP server. In the example above we have used a AVM Fritzbox (Router) as a FTP server. This router has a USB Stick connected which provides the storage for the pictures. In our case we have to type in the IP address of the router. If you want to set up your own FTP server on your local computer, you will find a step by step manual here. You can also use a web address as the FTP Server. This can either be an online host service or a DynDns address which connects to a FTP server at another location.' },
        { catColumn: 'FTP Port', connectionColumn: 'Please type in the FTP Servers port which is usually 21.' },
        { catColumn: 'FTP Folder', connectionColumn: 'Fill in the folder you want to upload the pictures inside. By default the folder will be "./" which means that the camera will save the uploads in the root folder of the server. In our above sample the pictures will be saved in the root folder.' },
        { catColumn: 'FTP Username', connectionColumn: 'Please fill in the username of your FTP account. The default username for the AVM Fritzbox is "ftpuser".' },
        { catColumn: 'FTP Password	', connectionColumn: 'Please fill in the password of your FTP account.' },
        { catColumn: 'FTP Mode', connectionColumn: 'Here you can choose between (PASV) and active Mode (PORT). The default setting is the mode PORT with which the camera will tell the server its IP and Port. Therefor this mode will also work with passive FTP servers. If the client is behind a router which will use NAT or if a firewall is blocking the clients network from accessing the network, then you should choose the PASV mode.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

FeatureTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(FeatureTable)


// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../assets/styles/data_tables/fixed-data-table.css'

// const featureRow = [
//   'FTP Server',
//   'FTP Port',
//   'FTP Folder',
//   'FTP Username',
//   'FTP Password	',
//   'FTP Mode',
// ]

// const propertyRow = [
//   'Please enter the address of your FTP server. In the example above we have used a AVM Fritzbox (Router) as a FTP server. This router has a USB Stick connected which provides the storage for the pictures. In our case we have to type in the IP address of the router. If you want to set up your own FTP server on your local computer, you will find a step by step manual here. You can also use a web address as the FTP Server. This can either be an online host service or a DynDns address which connects to a FTP server at another location.',
//   'Please type in the FTP Servers port which is usually 21.',
//   'Fill in the folder you want to upload the pictures inside. By default the folder will be "./" which means that the camera will save the uploads in the root folder of the server. In our above sample the pictures will be saved in the root folder.',
//   'Please fill in the username of your FTP account. The default username for the AVM Fritzbox is "ftpuser".',
//   'Please fill in the password of your FTP account.',
//   'Here you can choose between (PASV) and active Mode (PORT). The default setting is the mode PORT with which the camera will tell the server its IP and Port. Therefor this mode will also work with passive FTP servers. If the client is behind a router which will use NAT or if a firewall is blocking the clients network from accessing the network, then you should choose the PASV mode.',
// ]

// // Render your table
// const ConnectTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={featureRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Category</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {featureRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={150}
//         />
//         <Column
//           header={<Cell>Connection</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {propertyRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={3}
//           width={695}
//         />
//       </Table>
//     )
// }

// export default ConnectTable