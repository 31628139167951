import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class settingsColumn extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "settingColumn", title: "Setting" },
        { name: "selectColumn", title: "Select" },
      ],
      rows: [
        { settingColumn: 'Anwendung', selectColumn: 'Choose Andere Anwendung_.' },
        { settingColumn: 'Bezeichnung', selectColumn: 'Choose a name for your camera, for example Camera Entrance' },
        { settingColumn: 'Protokoll', selectColumn: 'Choose TCP' },
        { settingColumn: 'Port an Gerät', selectColumn: 'Here you set the port for your camera, which you already set in step 1. So for camera 1 it´s 8081, for your 2nd camera it´s 8082 etc.' },
        { settingColumn: 'Bis Port', selectColumn: 'Here you set the same port as in the array before (8081 etc.)' },
        { settingColumn: 'Port extern gewünscht', selectColumn: 'Here you set the same port as in the arrays before (8081 etc.)' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

settingsColumn.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(settingsColumn)


// import React from 'react'
// import {Table, Column, Cell} from 'fixed-data-table-2'
// import '../../../../assets/styles/data_tables/fixed-data-table.css'


// const settingsRow = [
//   'Anwendung',
//   'Bezeichnung',
//   'Protokoll',
//   'Port an Gerät',
//   'Bis Port',
//   'Port extern gewünscht',
// ]


// const functionRow = [
//   'Choose Andere Anwendung_.',
//   'Choose a name for your camera, for example Camera _Entrance_',
//   'Choose TCP',
//   'Here you set the port for your camera, which you already set in step 1. So for camera 1 it´s _8081_, for your 2nd camera it´s _8082_ etc.',
//   'Here you set the same port as in the array before (8081 etc.)',
//   'Here you set the same port as in the arrays before (8081 etc.)',
// ]

// // Render your table
// const ConnectTable = () => {
//     return (
//       <Table
//         rowHeight={55}
//         rowsCount={settingsRow.length}
//         touchScrollEnabled={true}
//         width={850}
//         maxHeight={1000}
//         headerHeight={50}>
//         <Column
//           header={<Cell>Setting</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {settingsRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={1}
//           width={225}
//         />
//         <Column
//           header={<Cell>Select</Cell>}
//           cell={({rowIndex, ...props}) => (
//             <Cell {...props}>
//               {functionRow[rowIndex]}
//             </Cell>
//           )}
//           flexGrow={2}
//           width={625}
//         />
//       </Table>
//     )
// }

// export default ConnectTable