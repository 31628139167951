import React from 'react'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'

import alexaCardImage from "images/Products/Alexa/instar-alexa-8001.webp"
import homekitCardImage from "images/Products/Homekit/instar-homekit-8001.webp"

import HomekitFlyout from './HomekitFlyout'
import AlexaFlyout from './AlexaFlyout'

export default function WebUIArticles() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={alexaCardImage}
                    alt="INSTAR Alexa Camera Skill"
                  />
              }
              title='Alexa Camera Skill'
              description={
                <p>Activate camera control through Alexa using your free INSTAR Cloud account.</p>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <AlexaFlyout />
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <img
                    src={homekitCardImage}
                    alt="INSTAR Camera Homekit Support"
                  />
              }
              title='Apple Homekit'
              description={
                <p>Control your INSTAR Full HD camera with the Apple Homekit app from your iPhone or iPad.</p>
              }
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <HomekitFlyout />
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
